import { memo, useCallback, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ExaminationItem } from './ExaminationItem';

import EmptyListImg from 'assets/images/empty-list.svg';
import Button from 'components/Button';
import { addExaminationHandler } from 'components/Modal/helpers';
import PictureWithText from 'components/PictureWithText';
import Text from 'components/Text';
import { useDashboardContext } from 'contexts/Dashboard';
import useDynamicViewportSize from 'hooks/useDynamicViewportSize';
import { useIsDemo } from 'hooks/useIsDemo';
import useLocalStorage from 'hooks/useLocalStorage';
import { userProfileSelector } from 'redux/selectors/userProfile';
import { IPatientExam } from 'types/patients';
import { MOBILE_RESOLUTION } from 'utils/constants';

import styles from './ExaminationList.module.scss';

interface ExaminationListProps {
  examinations: IPatientExam[];
}

const ExaminationList = memo(({ examinations }: ExaminationListProps) => {
  const { activePatient } = useDashboardContext();
  const navigate = useNavigate();
  const { data: userData } = useSelector(userProfileSelector);
  const { localStorageKey: onboardingStep } = useLocalStorage('onboarding');
  const [activeCard, setActiveCard] = useState<string | null>(null);

  const isDemo = useIsDemo();

  const { t } = useTranslation(undefined, {
    keyPrefix: 'components.examination_list',
  });

  const contextMenuExaminationHandler = useCallback((id: string) => {
    setActiveCard(id);
  }, []);

  const { vw } = useDynamicViewportSize();
  const openExaminationDetailHandler = useCallback((id: string) => {
    if (vw < MOBILE_RESOLUTION && isDemo) navigate(`/examination/${id}`);
    else if (vw > MOBILE_RESOLUTION && isDemo)
      navigate(`/examination/${id}?tab=2`);
    else navigate(`/examination/${id}`);
  }, []);

  const examinationProps = useMemo(
    () => ({
      contextMenuExaminationHandler,
      openExaminationDetailHandler,
    }),
    []
  );

  if (!examinations.length) {
    return (
      <div className={styles.empty}>
        <PictureWithText
          src={EmptyListImg}
          description={'Empty list'}
          className={styles.picture}
        >
          <Text color='gray' align='center' className={styles.title}>
            {t('empty.title')}
          </Text>
          <Text color='gray' align='center' className={styles.subtitle}>
            {t('empty.subtitle')}{' '}
            <Button
              appearance={'simple'}
              onClick={() =>
                addExaminationHandler(
                  userData.examinations_left,
                  userData,
                  onboardingStep,
                  activePatient
                )
              }
              className={styles['add-examination']}
              disabled={isDemo}
            >
              “+ {t('empty.add_new_examination')}”
            </Button>
          </Text>
        </PictureWithText>
      </div>
    );
  }

  return (
    <>
      <div className={styles.header}>
        <Text color='dark' className={styles.heading}>
          {t('header.examinations')}
        </Text>
        <Text color='dark' className={styles.format}>
          {t('header.uploaded')}
        </Text>
        <Text color='dark' className={styles.date}>
          {t('header.date')}
        </Text>

        {!isDemo && <div className={styles['edit-icon-slug']} />}
      </div>
      <div className={styles.list}>
        {examinations.map((examination) => (
          <ExaminationItem
            key={examination.id}
            examination={examination}
            isActive={examination.id === activeCard}
            {...examinationProps}
          />
        ))}
      </div>
    </>
  );
});

ExaminationList.displayName = 'ExaminationList';

export default ExaminationList;
