import { MOCK_EXAM_ID } from './mockData';

import { ExaminationStateSchema } from 'types/examination';

export const mockSingleLayers = (): ExaminationStateSchema['singleLayers'] => ({
  requesting: false,
  data: {
    id: '6414e199bccabd48850b7b19',
    examination_id: MOCK_EXAM_ID,
    scan_id: '6414e18e37dd936f800a77a9',
    instances: [
      {
        type: 'polygon',
        classId: 11,
        className: 'rnfl',
        probability: 0.7702463269233704,
        points: [
          460.3076923076923, 259.6923076923077, 457.84615384615387,
          260.9230769230769, 455.38461538461536, 260.9230769230769,
          452.9230769230769, 262.15384615384613, 450.46153846153845,
          262.15384615384613, 448, 263.38461538461536, 445.53846153846155,
          263.38461538461536, 443.0769230769231, 264.61538461538464,
          440.61538461538464, 264.61538461538464, 438.15384615384613,
          265.84615384615387, 433.2307692307692, 265.84615384615387,
          430.7692307692308, 267.0769230769231, 423.38461538461536,
          267.0769230769231, 420.9230769230769, 268.3076923076923, 416,
          268.3076923076923, 411.0769230769231, 270.7692307692308,
          411.0769230769231, 272, 416, 272, 418.46153846153845,
          273.2307692307692, 420.9230769230769, 273.2307692307692,
          423.38461538461536, 274.46153846153845, 435.6923076923077,
          274.46153846153845, 438.15384615384613, 273.2307692307692,
          440.61538461538464, 273.2307692307692, 443.0769230769231, 272, 448,
          272, 450.46153846153845, 270.7692307692308, 452.9230769230769,
          270.7692307692308, 455.38461538461536, 269.53846153846155,
          465.2307692307692, 269.53846153846155, 467.6923076923077,
          268.3076923076923, 477.53846153846155, 268.3076923076923, 480,
          269.53846153846155, 487.38461538461536, 269.53846153846155,
          489.84615384615387, 270.7692307692308, 494.7692307692308,
          270.7692307692308, 497.2307692307692, 272, 502.15384615384613, 272,
          502.15384615384613, 270.7692307692308, 499.6923076923077,
          269.53846153846155, 499.6923076923077, 268.3076923076923,
          502.15384615384613, 267.0769230769231, 502.15384615384613,
          264.61538461538464, 484.9230769230769, 264.61538461538464,
          475.0769230769231, 259.6923076923077,
        ],
        attributes: [],
        color: '#F3C72B',
        name: 'Retinal nerve fiber layer',
        order: 1,
      },
      {
        type: 'polygon',
        classId: 11,
        className: 'rnfl',
        probability: 0.7831677198410034,
        points: [
          726.1538461538462, 244.92307692307693, 723.6923076923077,
          246.15384615384616, 711.3846153846154, 246.15384615384616,
          708.9230769230769, 247.3846153846154, 704, 247.3846153846154,
          701.5384615384615, 248.6153846153846, 696.6153846153846,
          248.6153846153846, 694.1538461538462, 249.84615384615384,
          664.6153846153846, 249.84615384615384, 662.1538461538462,
          248.6153846153846, 637.5384615384615, 248.6153846153846,
          635.0769230769231, 249.84615384615384, 622.7692307692307,
          249.84615384615384, 620.3076923076923, 251.07692307692307,
          595.6923076923077, 251.07692307692307, 593.2307692307693,
          249.84615384615384, 583.3846153846154, 249.84615384615384,
          580.9230769230769, 251.07692307692307, 576, 251.07692307692307, 576,
          253.53846153846155, 583.3846153846154, 257.2307692307692,
          583.3846153846154, 259.6923076923077, 580.9230769230769,
          260.9230769230769, 580.9230769230769, 262.15384615384613,
          583.3846153846154, 263.38461538461536, 583.3846153846154,
          265.84615384615387, 580.9230769230769, 267.0769230769231,
          580.9230769230769, 269.53846153846155, 576, 272, 576,
          273.2307692307692, 608, 273.2307692307692, 610.4615384615385, 272,
          635.0769230769231, 272, 637.5384615384615, 270.7692307692308,
          644.9230769230769, 270.7692307692308, 647.3846153846154,
          269.53846153846155, 649.8461538461538, 269.53846153846155,
          652.3076923076923, 268.3076923076923, 654.7692307692307,
          268.3076923076923, 657.2307692307693, 267.0769230769231,
          659.6923076923077, 267.0769230769231, 667.0769230769231,
          263.38461538461536, 669.5384615384615, 263.38461538461536,
          674.4615384615385, 260.9230769230769, 679.3846153846154,
          260.9230769230769, 681.8461538461538, 259.6923076923077,
          691.6923076923077, 259.6923076923077, 694.1538461538462,
          258.46153846153845, 733.5384615384615, 258.46153846153845, 736,
          259.6923076923077, 738.4615384615385, 259.6923076923077,
          740.9230769230769, 260.9230769230769, 748.3076923076923,
          260.9230769230769, 750.7692307692307, 262.15384615384613,
          753.2307692307693, 262.15384615384613, 763.0769230769231,
          267.0769230769231, 772.9230769230769, 267.0769230769231,
          770.4615384615385, 265.84615384615387, 770.4615384615385,
          264.61538461538464, 763.0769230769231, 260.9230769230769,
          763.0769230769231, 257.2307692307692, 760.6153846153846, 256,
          760.6153846153846, 254.76923076923077, 758.1538461538462,
          254.76923076923077, 753.2307692307693, 252.30769230769232,
          750.7692307692307, 252.30769230769232, 748.3076923076923,
          251.07692307692307, 748.3076923076923, 249.84615384615384,
          745.8461538461538, 249.84615384615384, 740.9230769230769,
          247.3846153846154, 740.9230769230769, 246.15384615384616,
          738.4615384615385, 244.92307692307693,
        ],
        attributes: [],
        color: '#F3C72B',
        name: 'Retinal nerve fiber layer',
        order: 1,
      },
      {
        type: 'polygon',
        classId: 11,
        className: 'rnfl',
        probability: 0.7467876672744751,
        points: [
          738.4615384615385, 206.76923076923077, 736, 208, 733.5384615384615,
          208, 728.6153846153846, 210.46153846153845, 726.1538461538462,
          210.46153846153845, 731.0769230769231, 210.46153846153845,
          733.5384615384615, 209.23076923076923, 736, 209.23076923076923,
          738.4615384615385, 208, 740.9230769230769, 208, 740.9230769230769,
          206.76923076923077,
        ],
        attributes: [],
        color: '#F3C72B',
        name: 'Retinal nerve fiber layer',
        order: 1,
      },
      {
        type: 'polygon',
        classId: 11,
        className: 'rnfl',
        probability: 0.804332971572876,
        points: [
          44.30769230769231, 182.15384615384616, 41.84615384615385,
          183.3846153846154, 32, 183.3846153846154, 29.53846153846154,
          184.6153846153846, 22.153846153846153, 184.6153846153846,
          19.692307692307693, 185.84615384615384, 12.307692307692308,
          185.84615384615384, 9.846153846153847, 187.07692307692307,
          2.4615384615384617, 187.07692307692307, 0, 188.30769230769232, 0,
          201.84615384615384, 2.4615384615384617, 203.07692307692307,
          2.4615384615384617, 204.30769230769232, 36.92307692307692,
          204.30769230769232, 39.38461538461539, 203.07692307692307,
          61.53846153846154, 203.07692307692307, 64, 201.84615384615384,
          132.92307692307693, 201.84615384615384, 135.3846153846154,
          203.07692307692307, 150.15384615384616, 203.07692307692307,
          152.6153846153846, 204.30769230769232, 167.3846153846154,
          204.30769230769232, 169.84615384615384, 205.53846153846155,
          179.69230769230768, 205.53846153846155, 182.15384615384616,
          206.76923076923077, 196.92307692307693, 206.76923076923077,
          199.3846153846154, 208, 231.3846153846154, 208, 233.84615384615384,
          209.23076923076923, 243.69230769230768, 209.23076923076923,
          246.15384615384616, 210.46153846153845, 258.46153846153845,
          210.46153846153845, 260.9230769230769, 211.69230769230768,
          270.7692307692308, 211.69230769230768, 273.2307692307692,
          212.92307692307693, 280.61538461538464, 212.92307692307693,
          283.0769230769231, 214.15384615384616, 295.38461538461536,
          214.15384615384616, 297.84615384615387, 215.3846153846154,
          342.15384615384613, 215.3846153846154, 344.61538461538464,
          214.15384615384616, 359.38461538461536, 214.15384615384616,
          361.84615384615387, 212.92307692307693, 384, 212.92307692307693,
          386.46153846153845, 211.69230769230768, 396.3076923076923,
          211.69230769230768, 398.7692307692308, 210.46153846153845,
          408.61538461538464, 210.46153846153845, 411.0769230769231,
          209.23076923076923, 413.53846153846155, 209.23076923076923, 416, 208,
          428.3076923076923, 208, 430.7692307692308, 206.76923076923077,
          440.61538461538464, 206.76923076923077, 443.0769230769231,
          205.53846153846155, 484.9230769230769, 205.53846153846155,
          487.38461538461536, 206.76923076923077, 489.84615384615387,
          206.76923076923077, 492.3076923076923, 208, 494.7692307692308, 208,
          502.15384615384613, 211.69230769230768, 504.61538461538464,
          211.69230769230768, 509.53846153846155, 214.15384615384616, 512,
          214.15384615384616, 519.3846153846154, 217.84615384615384,
          521.8461538461538, 217.84615384615384, 524.3076923076923,
          219.07692307692307, 526.7692307692307, 219.07692307692307,
          529.2307692307693, 220.30769230769232, 534.1538461538462,
          220.30769230769232, 536.6153846153846, 221.53846153846155,
          536.6153846153846, 222.76923076923077, 539.0769230769231,
          222.76923076923077, 541.5384615384615, 224, 546.4615384615385, 224,
          548.9230769230769, 225.23076923076923, 551.3846153846154,
          225.23076923076923, 553.8461538461538, 226.46153846153845,
          556.3076923076923, 226.46153846153845, 561.2307692307693,
          228.92307692307693, 566.1538461538462, 228.92307692307693,
          563.6923076923077, 227.69230769230768, 561.2307692307693,
          227.69230769230768, 556.3076923076923, 225.23076923076923,
          551.3846153846154, 225.23076923076923, 546.4615384615385,
          222.76923076923077, 541.5384615384615, 222.76923076923077,
          539.0769230769231, 221.53846153846155, 536.6153846153846,
          221.53846153846155, 534.1538461538462, 220.30769230769232,
          531.6923076923077, 220.30769230769232, 529.2307692307693,
          219.07692307692307, 526.7692307692307, 219.07692307692307,
          524.3076923076923, 217.84615384615384, 519.3846153846154,
          217.84615384615384, 509.53846153846155, 212.92307692307693,
          507.0769230769231, 212.92307692307693, 497.2307692307692, 208,
          494.7692307692308, 208, 492.3076923076923, 206.76923076923077,
          489.84615384615387, 206.76923076923077, 487.38461538461536,
          205.53846153846155, 482.46153846153845, 205.53846153846155, 480,
          204.30769230769232, 470.15384615384613, 204.30769230769232,
          467.6923076923077, 203.07692307692307, 440.61538461538464,
          203.07692307692307, 438.15384615384613, 204.30769230769232,
          425.84615384615387, 204.30769230769232, 423.38461538461536,
          205.53846153846155, 416, 205.53846153846155, 413.53846153846155,
          206.76923076923077, 406.15384615384613, 206.76923076923077,
          403.6923076923077, 208, 364.3076923076923, 208, 361.84615384615387,
          209.23076923076923, 310.15384615384613, 209.23076923076923,
          307.6923076923077, 208, 295.38461538461536, 208, 292.9230769230769,
          206.76923076923077, 288, 206.76923076923077, 285.53846153846155,
          205.53846153846155, 278.15384615384613, 205.53846153846155,
          275.6923076923077, 204.30769230769232, 265.84615384615387,
          204.30769230769232, 263.38461538461536, 203.07692307692307,
          253.53846153846155, 203.07692307692307, 251.07692307692307,
          201.84615384615384, 243.69230769230768, 201.84615384615384,
          241.23076923076923, 200.6153846153846, 236.30769230769232,
          200.6153846153846, 233.84615384615384, 199.3846153846154, 224,
          199.3846153846154, 221.53846153846155, 198.15384615384616,
          209.23076923076923, 198.15384615384616, 206.76923076923077,
          196.92307692307693, 196.92307692307693, 196.92307692307693,
          194.46153846153845, 195.69230769230768, 187.07692307692307,
          195.69230769230768, 184.6153846153846, 194.46153846153845,
          177.23076923076923, 194.46153846153845, 174.76923076923077,
          193.23076923076923, 164.92307692307693, 193.23076923076923,
          162.46153846153845, 192, 150.15384615384616, 192, 147.69230769230768,
          190.76923076923077, 137.84615384615384, 190.76923076923077,
          135.3846153846154, 189.53846153846155, 125.53846153846153,
          189.53846153846155, 123.07692307692308, 188.30769230769232,
          118.15384615384616, 188.30769230769232, 115.6923076923077,
          187.07692307692307, 100.92307692307692, 187.07692307692307,
          98.46153846153847, 185.84615384615384, 91.07692307692308,
          185.84615384615384, 88.61538461538461, 184.6153846153846,
          78.76923076923077, 184.6153846153846, 76.3076923076923,
          183.3846153846154, 61.53846153846154, 183.3846153846154,
          59.07692307692308, 182.15384615384616,
        ],
        attributes: [],
        color: '#F3C72B',
        name: 'Retinal nerve fiber layer',
        order: 1,
      },
      {
        type: 'polygon',
        classId: 11,
        className: 'rnfl',
        probability: 0.8043014407157898,
        points: [
          1009.2307692307693, 177.23076923076923, 1006.7692307692307,
          178.46153846153845, 1001.8461538461538, 178.46153846153845,
          999.3846153846154, 179.69230769230768, 994.4615384615385,
          179.69230769230768, 992, 180.92307692307693, 987.0769230769231,
          180.92307692307693, 984.6153846153846, 182.15384615384616,
          979.6923076923077, 182.15384615384616, 977.2307692307693,
          183.3846153846154, 967.3846153846154, 183.3846153846154,
          964.9230769230769, 184.6153846153846, 957.5384615384615,
          184.6153846153846, 955.0769230769231, 185.84615384615384,
          947.6923076923077, 185.84615384615384, 945.2307692307693,
          187.07692307692307, 935.3846153846154, 187.07692307692307,
          932.9230769230769, 188.30769230769232, 918.1538461538462,
          188.30769230769232, 915.6923076923077, 189.53846153846155,
          898.4615384615385, 189.53846153846155, 896, 190.76923076923077,
          861.5384615384615, 190.76923076923077, 859.0769230769231,
          189.53846153846155, 836.9230769230769, 189.53846153846155,
          834.4615384615385, 190.76923076923077, 814.7692307692307,
          190.76923076923077, 812.3076923076923, 192, 804.9230769230769, 192,
          802.4615384615385, 193.23076923076923, 792.6153846153846,
          193.23076923076923, 790.1538461538462, 194.46153846153845,
          782.7692307692307, 194.46153846153845, 780.3076923076923,
          195.69230769230768, 777.8461538461538, 195.69230769230768,
          775.3846153846154, 196.92307692307693, 770.4615384615385,
          196.92307692307693, 768, 198.15384615384616, 765.5384615384615,
          198.15384615384616, 760.6153846153846, 200.6153846153846,
          758.1538461538462, 200.6153846153846, 755.6923076923077,
          201.84615384615384, 753.2307692307693, 201.84615384615384,
          750.7692307692307, 203.07692307692307, 750.7692307692307,
          204.30769230769232, 753.2307692307693, 203.07692307692307,
          755.6923076923077, 203.07692307692307, 760.6153846153846,
          200.6153846153846, 763.0769230769231, 200.6153846153846,
          765.5384615384615, 199.3846153846154, 770.4615384615385,
          199.3846153846154, 772.9230769230769, 198.15384615384616,
          775.3846153846154, 198.15384615384616, 777.8461538461538,
          196.92307692307693, 787.6923076923077, 196.92307692307693,
          790.1538461538462, 195.69230769230768, 795.0769230769231,
          195.69230769230768, 797.5384615384615, 194.46153846153845,
          827.0769230769231, 194.46153846153845, 829.5384615384615,
          193.23076923076923, 851.6923076923077, 193.23076923076923,
          854.1538461538462, 192, 864, 192, 866.4615384615385,
          193.23076923076923, 903.3846153846154, 193.23076923076923,
          905.8461538461538, 192, 923.0769230769231, 192, 925.5384615384615,
          190.76923076923077, 940.3076923076923, 190.76923076923077,
          942.7692307692307, 189.53846153846155, 950.1538461538462,
          189.53846153846155, 952.6153846153846, 188.30769230769232,
          957.5384615384615, 188.30769230769232, 960, 187.07692307692307,
          967.3846153846154, 187.07692307692307, 969.8461538461538,
          185.84615384615384, 979.6923076923077, 185.84615384615384,
          982.1538461538462, 184.6153846153846, 989.5384615384615,
          184.6153846153846, 992, 183.3846153846154, 996.9230769230769,
          183.3846153846154, 999.3846153846154, 182.15384615384616,
          1004.3076923076923, 182.15384615384616, 1006.7692307692307,
          180.92307692307693, 1019.0769230769231, 180.92307692307693,
          1019.0769230769231, 177.23076923076923,
        ],
        attributes: [],
        color: '#F3C72B',
        name: 'Retinal nerve fiber layer',
        order: 1,
      },
      {
        type: 'polygon',
        classId: 3,
        className: 'gcl',
        probability: 0.7257741689682007,
        points: [
          467.6923076923077, 270.7692307692308, 465.2307692307692, 272,
          462.7692307692308, 272, 460.3076923076923, 273.2307692307692,
          462.7692307692308, 274.46153846153845, 467.6923076923077,
          274.46153846153845, 470.15384615384613, 273.2307692307692,
          477.53846153846155, 273.2307692307692, 480, 272, 482.46153846153845,
          272, 480, 272, 477.53846153846155, 270.7692307692308,
        ],
        attributes: [],
        color: '#F68431',
        name: 'Ganglion cell layer',
        order: 2,
      },
      {
        type: 'polygon',
        classId: 3,
        className: 'gcl',
        probability: 0.7593317627906799,
        points: [
          696.6153846153846, 259.6923076923077, 694.1538461538462,
          260.9230769230769, 684.3076923076923, 260.9230769230769,
          681.8461538461538, 262.15384615384613, 676.9230769230769,
          262.15384615384613, 674.4615384615385, 263.38461538461536, 672,
          263.38461538461536, 667.0769230769231, 265.84615384615387,
          664.6153846153846, 265.84615384615387, 659.6923076923077,
          268.3076923076923, 657.2307692307693, 268.3076923076923,
          654.7692307692307, 269.53846153846155, 652.3076923076923,
          269.53846153846155, 649.8461538461538, 270.7692307692308,
          647.3846153846154, 270.7692307692308, 644.9230769230769, 272, 640,
          272, 637.5384615384615, 273.2307692307692, 610.4615384615385,
          273.2307692307692, 608, 274.46153846153845, 580.9230769230769,
          274.46153846153845, 578.4615384615385, 275.6923076923077,
          573.5384615384615, 275.6923076923077, 563.6923076923077,
          280.61538461538464, 563.6923076923077, 283.0769230769231,
          632.6153846153846, 283.0769230769231, 635.0769230769231,
          281.84615384615387, 640, 281.84615384615387, 642.4615384615385,
          280.61538461538464, 644.9230769230769, 280.61538461538464,
          647.3846153846154, 279.38461538461536, 649.8461538461538,
          279.38461538461536, 652.3076923076923, 278.15384615384613,
          654.7692307692307, 278.15384615384613, 657.2307692307693,
          276.9230769230769, 662.1538461538462, 276.9230769230769,
          667.0769230769231, 274.46153846153845, 674.4615384615385,
          274.46153846153845, 676.9230769230769, 273.2307692307692,
          681.8461538461538, 273.2307692307692, 684.3076923076923,
          274.46153846153845, 686.7692307692307, 273.2307692307692, 704,
          273.2307692307692, 706.4615384615385, 272, 718.7692307692307, 272,
          721.2307692307693, 273.2307692307692, 726.1538461538462,
          273.2307692307692, 728.6153846153846, 272, 743.3846153846154, 272,
          745.8461538461538, 273.2307692307692, 758.1538461538462,
          273.2307692307692, 760.6153846153846, 274.46153846153845,
          772.9230769230769, 274.46153846153845, 775.3846153846154,
          275.6923076923077, 780.3076923076923, 275.6923076923077,
          780.3076923076923, 274.46153846153845, 775.3846153846154, 272,
          775.3846153846154, 270.7692307692308, 772.9230769230769,
          270.7692307692308, 770.4615384615385, 269.53846153846155,
          765.5384615384615, 269.53846153846155, 763.0769230769231,
          268.3076923076923, 760.6153846153846, 268.3076923076923,
          750.7692307692307, 263.38461538461536, 745.8461538461538,
          263.38461538461536, 743.3846153846154, 262.15384615384613,
          738.4615384615385, 262.15384615384613, 736, 260.9230769230769,
          733.5384615384615, 260.9230769230769, 731.0769230769231,
          259.6923076923077,
        ],
        attributes: [],
        color: '#F68431',
        name: 'Ganglion cell layer',
        order: 2,
      },
      {
        type: 'polygon',
        classId: 3,
        className: 'gcl',
        probability: 0.8004765510559082,
        points: [
          66.46153846153847, 203.07692307692307, 64, 204.30769230769232,
          39.38461538461539, 204.30769230769232, 36.92307692307692,
          205.53846153846155, 2.4615384615384617, 205.53846153846155, 0,
          206.76923076923077, 4.923076923076923, 209.23076923076923,
          9.846153846153847, 209.23076923076923, 12.307692307692308,
          210.46153846153845, 41.84615384615385, 210.46153846153845,
          44.30769230769231, 209.23076923076923, 100.92307692307692,
          209.23076923076923, 103.38461538461539, 210.46153846153845,
          120.61538461538461, 210.46153846153845, 123.07692307692308,
          211.69230769230768, 137.84615384615384, 211.69230769230768,
          140.30769230769232, 212.92307692307693, 152.6153846153846,
          212.92307692307693, 155.07692307692307, 214.15384615384616,
          162.46153846153845, 214.15384615384616, 164.92307692307693,
          215.3846153846154, 174.76923076923077, 215.3846153846154,
          177.23076923076923, 216.6153846153846, 189.53846153846155,
          216.6153846153846, 192, 217.84615384615384, 209.23076923076923,
          217.84615384615384, 211.69230769230768, 219.07692307692307, 224,
          219.07692307692307, 226.46153846153845, 220.30769230769232,
          236.30769230769232, 220.30769230769232, 238.76923076923077,
          221.53846153846155, 246.15384615384616, 221.53846153846155,
          248.6153846153846, 222.76923076923077, 256, 222.76923076923077,
          258.46153846153845, 224, 268.3076923076923, 224, 270.7692307692308,
          225.23076923076923, 278.15384615384613, 225.23076923076923,
          280.61538461538464, 226.46153846153845, 290.46153846153845,
          226.46153846153845, 292.9230769230769, 227.69230769230768,
          305.2307692307692, 227.69230769230768, 307.6923076923077,
          228.92307692307693, 332.3076923076923, 228.92307692307693,
          334.7692307692308, 227.69230769230768, 344.61538461538464,
          227.69230769230768, 347.0769230769231, 226.46153846153845,
          374.15384615384613, 226.46153846153845, 376.61538461538464,
          225.23076923076923, 396.3076923076923, 225.23076923076923,
          398.7692307692308, 224, 406.15384615384613, 224, 408.61538461538464,
          222.76923076923077, 418.46153846153845, 222.76923076923077,
          420.9230769230769, 221.53846153846155, 428.3076923076923,
          221.53846153846155, 430.7692307692308, 220.30769230769232,
          438.15384615384613, 220.30769230769232, 440.61538461538464,
          219.07692307692307, 448, 219.07692307692307, 450.46153846153845,
          217.84615384615384, 467.6923076923077, 217.84615384615384,
          470.15384615384613, 216.6153846153846, 472.61538461538464,
          216.6153846153846, 475.0769230769231, 217.84615384615384,
          487.38461538461536, 217.84615384615384, 489.84615384615387,
          219.07692307692307, 494.7692307692308, 219.07692307692307,
          497.2307692307692, 220.30769230769232, 502.15384615384613,
          220.30769230769232, 504.61538461538464, 221.53846153846155,
          509.53846153846155, 221.53846153846155, 512, 222.76923076923077,
          514.4615384615385, 222.76923076923077, 516.9230769230769, 224,
          521.8461538461538, 224, 524.3076923076923, 225.23076923076923,
          531.6923076923077, 225.23076923076923, 534.1538461538462,
          226.46153846153845, 539.0769230769231, 226.46153846153845,
          541.5384615384615, 227.69230769230768, 548.9230769230769,
          227.69230769230768, 546.4615384615385, 226.46153846153845, 544,
          226.46153846153845, 541.5384615384615, 225.23076923076923,
          536.6153846153846, 225.23076923076923, 531.6923076923077,
          222.76923076923077, 529.2307692307693, 222.76923076923077,
          524.3076923076923, 220.30769230769232, 519.3846153846154,
          220.30769230769232, 514.4615384615385, 217.84615384615384, 512,
          217.84615384615384, 502.15384615384613, 212.92307692307693,
          499.6923076923077, 212.92307692307693, 492.3076923076923,
          209.23076923076923, 489.84615384615387, 209.23076923076923,
          487.38461538461536, 208, 484.9230769230769, 208, 482.46153846153845,
          206.76923076923077, 448, 206.76923076923077, 445.53846153846155, 208,
          433.2307692307692, 208, 430.7692307692308, 209.23076923076923,
          418.46153846153845, 209.23076923076923, 416, 210.46153846153845,
          411.0769230769231, 210.46153846153845, 408.61538461538464,
          211.69230769230768, 401.2307692307692, 211.69230769230768,
          398.7692307692308, 212.92307692307693, 386.46153846153845,
          212.92307692307693, 384, 214.15384615384616, 364.3076923076923,
          214.15384615384616, 361.84615384615387, 215.3846153846154,
          347.0769230769231, 215.3846153846154, 344.61538461538464,
          216.6153846153846, 297.84615384615387, 216.6153846153846,
          295.38461538461536, 215.3846153846154, 283.0769230769231,
          215.3846153846154, 280.61538461538464, 214.15384615384616,
          270.7692307692308, 214.15384615384616, 268.3076923076923,
          212.92307692307693, 258.46153846153845, 212.92307692307693, 256,
          211.69230769230768, 246.15384615384616, 211.69230769230768,
          243.69230769230768, 210.46153846153845, 233.84615384615384,
          210.46153846153845, 231.3846153846154, 209.23076923076923,
          196.92307692307693, 209.23076923076923, 194.46153846153845, 208,
          179.69230769230768, 208, 177.23076923076923, 206.76923076923077,
          167.3846153846154, 206.76923076923077, 164.92307692307693,
          205.53846153846155, 152.6153846153846, 205.53846153846155,
          150.15384615384616, 204.30769230769232, 135.3846153846154,
          204.30769230769232, 132.92307692307693, 203.07692307692307,
        ],
        attributes: [],
        color: '#F68431',
        name: 'Ganglion cell layer',
        order: 2,
      },
      {
        type: 'polygon',
        classId: 3,
        className: 'gcl',
        probability: 0.7904313802719116,
        points: [
          1009.2307692307693, 182.15384615384616, 1006.7692307692307,
          183.3846153846154, 999.3846153846154, 183.3846153846154,
          996.9230769230769, 184.6153846153846, 992, 184.6153846153846,
          989.5384615384615, 185.84615384615384, 982.1538461538462,
          185.84615384615384, 979.6923076923077, 187.07692307692307,
          972.3076923076923, 187.07692307692307, 969.8461538461538,
          188.30769230769232, 962.4615384615385, 188.30769230769232, 960,
          189.53846153846155, 952.6153846153846, 189.53846153846155,
          950.1538461538462, 190.76923076923077, 942.7692307692307,
          190.76923076923077, 940.3076923076923, 192, 925.5384615384615, 192,
          923.0769230769231, 193.23076923076923, 908.3076923076923,
          193.23076923076923, 905.8461538461538, 194.46153846153845, 832,
          194.46153846153845, 829.5384615384615, 195.69230769230768,
          804.9230769230769, 195.69230769230768, 802.4615384615385,
          196.92307692307693, 792.6153846153846, 196.92307692307693,
          790.1538461538462, 198.15384615384616, 782.7692307692307,
          198.15384615384616, 780.3076923076923, 199.3846153846154,
          775.3846153846154, 199.3846153846154, 772.9230769230769,
          200.6153846153846, 768, 200.6153846153846, 765.5384615384615,
          201.84615384615384, 763.0769230769231, 201.84615384615384,
          755.6923076923077, 205.53846153846155, 753.2307692307693,
          205.53846153846155, 750.7692307692307, 206.76923076923077,
          748.3076923076923, 206.76923076923077, 743.3846153846154,
          209.23076923076923, 740.9230769230769, 209.23076923076923,
          738.4615384615385, 210.46153846153845, 736, 210.46153846153845,
          733.5384615384615, 211.69230769230768, 731.0769230769231,
          211.69230769230768, 728.6153846153846, 212.92307692307693,
          728.6153846153846, 215.3846153846154, 740.9230769230769,
          215.3846153846154, 743.3846153846154, 214.15384615384616,
          750.7692307692307, 214.15384615384616, 753.2307692307693,
          212.92307692307693, 760.6153846153846, 212.92307692307693,
          763.0769230769231, 211.69230769230768, 772.9230769230769,
          211.69230769230768, 775.3846153846154, 210.46153846153845,
          795.0769230769231, 210.46153846153845, 797.5384615384615,
          209.23076923076923, 854.1538461538462, 209.23076923076923,
          856.6153846153846, 208, 876.3076923076923, 208, 878.7692307692307,
          206.76923076923077, 883.6923076923077, 206.76923076923077,
          886.1538461538462, 205.53846153846155, 893.5384615384615,
          205.53846153846155, 896, 204.30769230769232, 898.4615384615385,
          204.30769230769232, 900.9230769230769, 203.07692307692307,
          905.8461538461538, 203.07692307692307, 908.3076923076923,
          201.84615384615384, 910.7692307692307, 201.84615384615384,
          913.2307692307693, 200.6153846153846, 918.1538461538462,
          200.6153846153846, 920.6153846153846, 199.3846153846154,
          923.0769230769231, 199.3846153846154, 925.5384615384615,
          198.15384615384616, 932.9230769230769, 198.15384615384616,
          935.3846153846154, 196.92307692307693, 942.7692307692307,
          196.92307692307693, 945.2307692307693, 195.69230769230768,
          950.1538461538462, 195.69230769230768, 952.6153846153846,
          194.46153846153845, 957.5384615384615, 194.46153846153845, 960,
          193.23076923076923, 969.8461538461538, 193.23076923076923,
          972.3076923076923, 192, 984.6153846153846, 192, 987.0769230769231,
          190.76923076923077, 994.4615384615385, 190.76923076923077,
          996.9230769230769, 189.53846153846155, 1009.2307692307693,
          189.53846153846155, 1011.6923076923077, 188.30769230769232,
          1019.0769230769231, 188.30769230769232, 1019.0769230769231,
          182.15384615384616,
        ],
        attributes: [],
        color: '#F68431',
        name: 'Ganglion cell layer',
        order: 2,
      },
      {
        type: 'polygon',
        classId: 5,
        className: 'ipl',
        probability: 0.7422570586204529,
        points: [
          711.3846153846154, 273.2307692307692, 708.9230769230769,
          274.46153846153845, 694.1538461538462, 274.46153846153845,
          691.6923076923077, 275.6923076923077, 669.5384615384615,
          275.6923076923077, 664.6153846153846, 278.15384615384613,
          662.1538461538462, 278.15384615384613, 659.6923076923077,
          279.38461538461536, 657.2307692307693, 279.38461538461536,
          654.7692307692307, 280.61538461538464, 649.8461538461538,
          280.61538461538464, 644.9230769230769, 283.0769230769231, 640,
          283.0769230769231, 637.5384615384615, 284.3076923076923,
          632.6153846153846, 284.3076923076923, 630.1538461538462,
          285.53846153846155, 620.3076923076923, 285.53846153846155,
          617.8461538461538, 284.3076923076923, 566.1538461538462,
          284.3076923076923, 563.6923076923077, 285.53846153846155,
          556.3076923076923, 285.53846153846155, 551.3846153846154, 288,
          548.9230769230769, 288, 546.4615384615385, 289.2307692307692,
          551.3846153846154, 289.2307692307692, 553.8461538461538,
          290.46153846153845, 578.4615384615385, 290.46153846153845,
          580.9230769230769, 291.6923076923077, 585.8461538461538,
          291.6923076923077, 588.3076923076923, 292.9230769230769,
          590.7692307692307, 292.9230769230769, 600.6153846153846,
          297.84615384615387, 610.4615384615385, 297.84615384615387,
          612.9230769230769, 299.0769230769231, 627.6923076923077,
          299.0769230769231, 630.1538461538462, 297.84615384615387,
          632.6153846153846, 297.84615384615387, 635.0769230769231,
          296.61538461538464, 635.0769230769231, 295.38461538461536,
          637.5384615384615, 294.15384615384613, 637.5384615384615,
          292.9230769230769, 640, 291.6923076923077, 640, 290.46153846153845,
          647.3846153846154, 286.7692307692308, 662.1538461538462,
          286.7692307692308, 664.6153846153846, 285.53846153846155,
          679.3846153846154, 285.53846153846155, 681.8461538461538,
          284.3076923076923, 686.7692307692307, 284.3076923076923,
          689.2307692307693, 285.53846153846155, 691.6923076923077,
          285.53846153846155, 694.1538461538462, 284.3076923076923,
          726.1538461538462, 284.3076923076923, 728.6153846153846,
          283.0769230769231, 748.3076923076923, 283.0769230769231,
          750.7692307692307, 281.84615384615387, 760.6153846153846,
          281.84615384615387, 763.0769230769231, 280.61538461538464, 768,
          280.61538461538464, 770.4615384615385, 281.84615384615387,
          777.8461538461538, 281.84615384615387, 777.8461538461538,
          279.38461538461536, 772.9230769230769, 276.9230769230769, 768,
          276.9230769230769, 765.5384615384615, 275.6923076923077,
          755.6923076923077, 275.6923076923077, 753.2307692307693,
          276.9230769230769, 748.3076923076923, 276.9230769230769,
          745.8461538461538, 275.6923076923077, 743.3846153846154,
          275.6923076923077, 740.9230769230769, 274.46153846153845,
          716.3076923076923, 274.46153846153845, 713.8461538461538,
          273.2307692307692,
        ],
        attributes: [],
        color: '#D94E4E',
        name: 'Inner plexiform layer',
        order: 3,
      },
      {
        type: 'polygon',
        classId: 5,
        className: 'ipl',
        probability: 0.8037118315696716,
        points: [
          2.4615384615384617, 210.46153846153845, 2.4615384615384617,
          215.3846153846154, 14.76923076923077, 215.3846153846154,
          17.23076923076923, 216.6153846153846, 103.38461538461539,
          216.6153846153846, 105.84615384615384, 217.84615384615384,
          123.07692307692308, 217.84615384615384, 125.53846153846153,
          219.07692307692307, 142.76923076923077, 219.07692307692307,
          145.23076923076923, 220.30769230769232, 152.6153846153846,
          220.30769230769232, 155.07692307692307, 221.53846153846155,
          162.46153846153845, 221.53846153846155, 164.92307692307693,
          222.76923076923077, 172.30769230769232, 222.76923076923077,
          174.76923076923077, 224, 187.07692307692307, 224, 189.53846153846155,
          225.23076923076923, 204.30769230769232, 225.23076923076923,
          206.76923076923077, 226.46153846153845, 219.07692307692307,
          226.46153846153845, 221.53846153846155, 227.69230769230768,
          231.3846153846154, 227.69230769230768, 233.84615384615384,
          228.92307692307693, 243.69230769230768, 228.92307692307693,
          246.15384615384616, 230.15384615384616, 253.53846153846155,
          230.15384615384616, 256, 231.3846153846154, 263.38461538461536,
          231.3846153846154, 265.84615384615387, 232.6153846153846,
          273.2307692307692, 232.6153846153846, 275.6923076923077,
          233.84615384615384, 285.53846153846155, 233.84615384615384, 288,
          235.07692307692307, 297.84615384615387, 235.07692307692307,
          300.3076923076923, 236.30769230769232, 312.61538461538464,
          236.30769230769232, 315.0769230769231, 237.53846153846155,
          337.2307692307692, 237.53846153846155, 339.6923076923077,
          236.30769230769232, 359.38461538461536, 236.30769230769232,
          361.84615384615387, 235.07692307692307, 381.53846153846155,
          235.07692307692307, 384, 233.84615384615384, 398.7692307692308,
          233.84615384615384, 401.2307692307692, 232.6153846153846,
          411.0769230769231, 232.6153846153846, 413.53846153846155,
          231.3846153846154, 418.46153846153845, 231.3846153846154,
          420.9230769230769, 230.15384615384616, 430.7692307692308,
          230.15384615384616, 433.2307692307692, 228.92307692307693,
          438.15384615384613, 228.92307692307693, 440.61538461538464,
          227.69230769230768, 448, 227.69230769230768, 450.46153846153845,
          226.46153846153845, 457.84615384615387, 226.46153846153845,
          460.3076923076923, 225.23076923076923, 484.9230769230769,
          225.23076923076923, 487.38461538461536, 226.46153846153845,
          502.15384615384613, 226.46153846153845, 504.61538461538464,
          227.69230769230768, 514.4615384615385, 227.69230769230768,
          516.9230769230769, 228.92307692307693, 531.6923076923077,
          228.92307692307693, 534.1538461538462, 230.15384615384616,
          534.1538461538462, 228.92307692307693, 531.6923076923077,
          228.92307692307693, 529.2307692307693, 227.69230769230768,
          526.7692307692307, 227.69230769230768, 524.3076923076923,
          226.46153846153845, 519.3846153846154, 226.46153846153845,
          516.9230769230769, 225.23076923076923, 514.4615384615385,
          225.23076923076923, 512, 224, 509.53846153846155, 224,
          507.0769230769231, 222.76923076923077, 502.15384615384613,
          222.76923076923077, 499.6923076923077, 221.53846153846155,
          494.7692307692308, 221.53846153846155, 492.3076923076923,
          220.30769230769232, 487.38461538461536, 220.30769230769232,
          484.9230769230769, 219.07692307692307, 452.9230769230769,
          219.07692307692307, 450.46153846153845, 220.30769230769232,
          440.61538461538464, 220.30769230769232, 438.15384615384613,
          221.53846153846155, 433.2307692307692, 221.53846153846155,
          430.7692307692308, 222.76923076923077, 423.38461538461536,
          222.76923076923077, 420.9230769230769, 224, 411.0769230769231, 224,
          408.61538461538464, 225.23076923076923, 398.7692307692308,
          225.23076923076923, 396.3076923076923, 226.46153846153845,
          381.53846153846155, 226.46153846153845, 379.0769230769231,
          227.69230769230768, 349.53846153846155, 227.69230769230768,
          347.0769230769231, 228.92307692307693, 334.7692307692308,
          228.92307692307693, 332.3076923076923, 230.15384615384616,
          305.2307692307692, 230.15384615384616, 302.7692307692308,
          228.92307692307693, 292.9230769230769, 228.92307692307693,
          290.46153846153845, 227.69230769230768, 280.61538461538464,
          227.69230769230768, 278.15384615384613, 226.46153846153845,
          268.3076923076923, 226.46153846153845, 265.84615384615387,
          225.23076923076923, 258.46153846153845, 225.23076923076923, 256, 224,
          248.6153846153846, 224, 246.15384615384616, 222.76923076923077,
          238.76923076923077, 222.76923076923077, 236.30769230769232,
          221.53846153846155, 226.46153846153845, 221.53846153846155, 224,
          220.30769230769232, 211.69230769230768, 220.30769230769232,
          209.23076923076923, 219.07692307692307, 192, 219.07692307692307,
          189.53846153846155, 217.84615384615384, 177.23076923076923,
          217.84615384615384, 174.76923076923077, 216.6153846153846,
          164.92307692307693, 216.6153846153846, 162.46153846153845,
          215.3846153846154, 155.07692307692307, 215.3846153846154,
          152.6153846153846, 214.15384615384616, 140.30769230769232,
          214.15384615384616, 137.84615384615384, 212.92307692307693,
          123.07692307692308, 212.92307692307693, 120.61538461538461,
          211.69230769230768, 100.92307692307692, 211.69230769230768,
          98.46153846153847, 210.46153846153845, 44.30769230769231,
          210.46153846153845, 41.84615384615385, 211.69230769230768,
          12.307692307692308, 211.69230769230768, 9.846153846153847,
          210.46153846153845,
        ],
        attributes: [],
        color: '#D94E4E',
        name: 'Inner plexiform layer',
        order: 3,
      },
      {
        type: 'polygon',
        classId: 5,
        className: 'ipl',
        probability: 0.7897545695304871,
        points: [
          1011.6923076923077, 189.53846153846155, 1009.2307692307693,
          190.76923076923077, 996.9230769230769, 190.76923076923077,
          994.4615384615385, 192, 987.0769230769231, 192, 984.6153846153846,
          193.23076923076923, 972.3076923076923, 193.23076923076923,
          969.8461538461538, 194.46153846153845, 960, 194.46153846153845,
          957.5384615384615, 195.69230769230768, 952.6153846153846,
          195.69230769230768, 950.1538461538462, 196.92307692307693,
          945.2307692307693, 196.92307692307693, 942.7692307692307,
          198.15384615384616, 937.8461538461538, 198.15384615384616,
          935.3846153846154, 199.3846153846154, 928, 199.3846153846154,
          925.5384615384615, 200.6153846153846, 920.6153846153846,
          200.6153846153846, 918.1538461538462, 201.84615384615384,
          913.2307692307693, 201.84615384615384, 910.7692307692307,
          203.07692307692307, 908.3076923076923, 203.07692307692307,
          905.8461538461538, 204.30769230769232, 900.9230769230769,
          204.30769230769232, 898.4615384615385, 205.53846153846155, 896,
          205.53846153846155, 893.5384615384615, 206.76923076923077,
          888.6153846153846, 206.76923076923077, 886.1538461538462, 208,
          878.7692307692307, 208, 876.3076923076923, 209.23076923076923,
          859.0769230769231, 209.23076923076923, 856.6153846153846,
          210.46153846153845, 797.5384615384615, 210.46153846153845,
          795.0769230769231, 211.69230769230768, 777.8461538461538,
          211.69230769230768, 775.3846153846154, 212.92307692307693,
          765.5384615384615, 212.92307692307693, 763.0769230769231,
          214.15384615384616, 758.1538461538462, 214.15384615384616,
          755.6923076923077, 215.3846153846154, 748.3076923076923,
          215.3846153846154, 745.8461538461538, 216.6153846153846,
          740.9230769230769, 216.6153846153846, 738.4615384615385,
          217.84615384615384, 736, 217.84615384615384, 736, 219.07692307692307,
          775.3846153846154, 219.07692307692307, 777.8461538461538,
          217.84615384615384, 864, 217.84615384615384, 866.4615384615385,
          216.6153846153846, 878.7692307692307, 216.6153846153846,
          881.2307692307693, 215.3846153846154, 886.1538461538462,
          215.3846153846154, 888.6153846153846, 214.15384615384616,
          891.0769230769231, 214.15384615384616, 893.5384615384615,
          212.92307692307693, 896, 212.92307692307693, 898.4615384615385,
          211.69230769230768, 900.9230769230769, 211.69230769230768,
          903.3846153846154, 210.46153846153845, 905.8461538461538,
          210.46153846153845, 908.3076923076923, 209.23076923076923,
          910.7692307692307, 209.23076923076923, 913.2307692307693, 208,
          915.6923076923077, 208, 918.1538461538462, 206.76923076923077,
          920.6153846153846, 206.76923076923077, 923.0769230769231,
          205.53846153846155, 925.5384615384615, 205.53846153846155, 928,
          204.30769230769232, 930.4615384615385, 204.30769230769232,
          932.9230769230769, 203.07692307692307, 940.3076923076923,
          203.07692307692307, 942.7692307692307, 201.84615384615384,
          945.2307692307693, 201.84615384615384, 947.6923076923077,
          200.6153846153846, 955.0769230769231, 200.6153846153846,
          957.5384615384615, 199.3846153846154, 962.4615384615385,
          199.3846153846154, 964.9230769230769, 198.15384615384616,
          984.6153846153846, 198.15384615384616, 987.0769230769231,
          196.92307692307693, 1001.8461538461538, 196.92307692307693,
          1004.3076923076923, 195.69230769230768, 1016.6153846153846,
          195.69230769230768, 1019.0769230769231, 194.46153846153845,
          1019.0769230769231, 190.76923076923077, 1016.6153846153846,
          190.76923076923077, 1014.1538461538462, 189.53846153846155,
        ],
        attributes: [],
        color: '#D94E4E',
        name: 'Inner plexiform layer',
        order: 3,
      },
      {
        type: 'polygon',
        classId: 4,
        className: 'inl',
        probability: 0.7485337257385254,
        points: [
          546.4615384615385, 291.6923076923077, 544, 292.9230769230769,
          539.0769230769231, 292.9230769230769, 536.6153846153846,
          294.15384615384613, 536.6153846153846, 295.38461538461536,
          539.0769230769231, 295.38461538461536, 541.5384615384615,
          296.61538461538464, 556.3076923076923, 296.61538461538464,
          558.7692307692307, 297.84615384615387, 573.5384615384615,
          297.84615384615387, 576, 299.0769230769231, 585.8461538461538,
          299.0769230769231, 588.3076923076923, 300.3076923076923,
          590.7692307692307, 300.3076923076923, 595.6923076923077,
          302.7692307692308, 598.1538461538462, 302.7692307692308,
          600.6153846153846, 304, 605.5384615384615, 304, 608,
          305.2307692307692, 610.4615384615385, 305.2307692307692,
          612.9230769230769, 306.46153846153845, 615.3846153846154,
          306.46153846153845, 617.8461538461538, 307.6923076923077,
          625.2307692307693, 307.6923076923077, 627.6923076923077,
          308.9230769230769, 640, 308.9230769230769, 642.4615384615385,
          307.6923076923077, 647.3846153846154, 307.6923076923077,
          647.3846153846154, 304, 644.9230769230769, 302.7692307692308,
          644.9230769230769, 301.53846153846155, 642.4615384615385,
          301.53846153846155, 640, 300.3076923076923, 637.5384615384615,
          301.53846153846155, 612.9230769230769, 301.53846153846155,
          610.4615384615385, 300.3076923076923, 605.5384615384615,
          300.3076923076923, 603.0769230769231, 299.0769230769231,
          600.6153846153846, 299.0769230769231, 598.1538461538462,
          297.84615384615387, 595.6923076923077, 297.84615384615387,
          588.3076923076923, 294.15384615384613, 585.8461538461538,
          294.15384615384613, 583.3846153846154, 292.9230769230769,
          568.6153846153846, 292.9230769230769, 566.1538461538462,
          291.6923076923077,
        ],
        attributes: [],
        color: '#1B6C9F',
        name: 'Inner nuclear layer',
        order: 4,
      },
      {
        type: 'polygon',
        classId: 4,
        className: 'inl',
        probability: 0.735023021697998,
        points: [
          681.8461538461538, 286.7692307692308, 679.3846153846154, 288, 672,
          288, 669.5384615384615, 289.2307692307692, 664.6153846153846,
          289.2307692307692, 664.6153846153846, 290.46153846153845,
          718.7692307692307, 290.46153846153845, 721.2307692307693,
          291.6923076923077, 723.6923076923077, 290.46153846153845,
          728.6153846153846, 290.46153846153845, 733.5384615384615, 288,
          733.5384615384615, 286.7692307692308,
        ],
        attributes: [],
        color: '#1B6C9F',
        name: 'Inner nuclear layer',
        order: 4,
      },
      {
        type: 'polygon',
        classId: 4,
        className: 'inl',
        probability: 0.8105590343475342,
        points: [
          4.923076923076923, 216.6153846153846, 4.923076923076923,
          220.30769230769232, 9.846153846153847, 220.30769230769232,
          12.307692307692308, 221.53846153846155, 19.692307692307693,
          221.53846153846155, 22.153846153846153, 222.76923076923077,
          51.69230769230769, 222.76923076923077, 54.15384615384615,
          221.53846153846155, 88.61538461538461, 221.53846153846155,
          91.07692307692308, 222.76923076923077, 108.3076923076923,
          222.76923076923077, 110.76923076923077, 224, 125.53846153846153, 224,
          128, 225.23076923076923, 140.30769230769232, 225.23076923076923,
          142.76923076923077, 226.46153846153845, 150.15384615384616,
          226.46153846153845, 152.6153846153846, 227.69230769230768, 160,
          227.69230769230768, 162.46153846153845, 228.92307692307693,
          174.76923076923077, 228.92307692307693, 177.23076923076923,
          230.15384615384616, 189.53846153846155, 230.15384615384616, 192,
          231.3846153846154, 201.84615384615384, 231.3846153846154,
          204.30769230769232, 232.6153846153846, 216.6153846153846,
          232.6153846153846, 219.07692307692307, 233.84615384615384,
          228.92307692307693, 233.84615384615384, 231.3846153846154,
          235.07692307692307, 241.23076923076923, 235.07692307692307,
          243.69230769230768, 236.30769230769232, 251.07692307692307,
          236.30769230769232, 253.53846153846155, 237.53846153846155,
          258.46153846153845, 237.53846153846155, 260.9230769230769,
          238.76923076923077, 268.3076923076923, 238.76923076923077,
          270.7692307692308, 240, 278.15384615384613, 240, 280.61538461538464,
          241.23076923076923, 288, 241.23076923076923, 290.46153846153845,
          242.46153846153845, 300.3076923076923, 242.46153846153845,
          302.7692307692308, 243.69230769230768, 310.15384615384613,
          243.69230769230768, 312.61538461538464, 244.92307692307693,
          354.46153846153845, 244.92307692307693, 356.9230769230769,
          243.69230769230768, 374.15384615384613, 243.69230769230768,
          376.61538461538464, 242.46153846153845, 393.84615384615387,
          242.46153846153845, 396.3076923076923, 241.23076923076923,
          403.6923076923077, 241.23076923076923, 406.15384615384613, 240,
          411.0769230769231, 240, 413.53846153846155, 238.76923076923077,
          418.46153846153845, 238.76923076923077, 420.9230769230769,
          237.53846153846155, 430.7692307692308, 237.53846153846155,
          433.2307692307692, 236.30769230769232, 438.15384615384613,
          236.30769230769232, 440.61538461538464, 235.07692307692307,
          445.53846153846155, 235.07692307692307, 448, 233.84615384615384,
          452.9230769230769, 233.84615384615384, 455.38461538461536,
          232.6153846153846, 462.7692307692308, 232.6153846153846,
          465.2307692307692, 231.3846153846154, 507.0769230769231,
          231.3846153846154, 509.53846153846155, 232.6153846153846,
          516.9230769230769, 232.6153846153846, 519.3846153846154,
          231.3846153846154, 521.8461538461538, 231.3846153846154,
          519.3846153846154, 231.3846153846154, 516.9230769230769,
          230.15384615384616, 509.53846153846155, 230.15384615384616,
          507.0769230769231, 228.92307692307693, 499.6923076923077,
          228.92307692307693, 497.2307692307692, 227.69230769230768,
          484.9230769230769, 227.69230769230768, 482.46153846153845,
          226.46153846153845, 475.0769230769231, 226.46153846153845,
          472.61538461538464, 227.69230769230768, 455.38461538461536,
          227.69230769230768, 452.9230769230769, 228.92307692307693,
          445.53846153846155, 228.92307692307693, 443.0769230769231,
          230.15384615384616, 435.6923076923077, 230.15384615384616,
          433.2307692307692, 231.3846153846154, 423.38461538461536,
          231.3846153846154, 420.9230769230769, 232.6153846153846,
          413.53846153846155, 232.6153846153846, 411.0769230769231,
          233.84615384615384, 403.6923076923077, 233.84615384615384,
          401.2307692307692, 235.07692307692307, 386.46153846153845,
          235.07692307692307, 384, 236.30769230769232, 364.3076923076923,
          236.30769230769232, 361.84615384615387, 237.53846153846155,
          342.15384615384613, 237.53846153846155, 339.6923076923077,
          238.76923076923077, 312.61538461538464, 238.76923076923077,
          310.15384615384613, 237.53846153846155, 300.3076923076923,
          237.53846153846155, 297.84615384615387, 236.30769230769232, 288,
          236.30769230769232, 285.53846153846155, 235.07692307692307,
          275.6923076923077, 235.07692307692307, 273.2307692307692,
          233.84615384615384, 265.84615384615387, 233.84615384615384,
          263.38461538461536, 232.6153846153846, 256, 232.6153846153846,
          253.53846153846155, 231.3846153846154, 246.15384615384616,
          231.3846153846154, 243.69230769230768, 230.15384615384616,
          233.84615384615384, 230.15384615384616, 231.3846153846154,
          228.92307692307693, 221.53846153846155, 228.92307692307693,
          219.07692307692307, 227.69230769230768, 206.76923076923077,
          227.69230769230768, 204.30769230769232, 226.46153846153845,
          189.53846153846155, 226.46153846153845, 187.07692307692307,
          225.23076923076923, 174.76923076923077, 225.23076923076923,
          172.30769230769232, 224, 164.92307692307693, 224, 162.46153846153845,
          222.76923076923077, 155.07692307692307, 222.76923076923077,
          152.6153846153846, 221.53846153846155, 145.23076923076923,
          221.53846153846155, 142.76923076923077, 220.30769230769232,
          125.53846153846153, 220.30769230769232, 123.07692307692308,
          219.07692307692307, 105.84615384615384, 219.07692307692307,
          103.38461538461539, 217.84615384615384, 14.76923076923077,
          217.84615384615384, 12.307692307692308, 216.6153846153846,
        ],
        attributes: [],
        color: '#1B6C9F',
        name: 'Inner nuclear layer',
        order: 4,
      },
      {
        type: 'polygon',
        classId: 4,
        className: 'inl',
        probability: 0.7840369343757629,
        points: [
          1004.3076923076923, 196.92307692307693, 1001.8461538461538,
          198.15384615384616, 987.0769230769231, 198.15384615384616,
          984.6153846153846, 199.3846153846154, 964.9230769230769,
          199.3846153846154, 962.4615384615385, 200.6153846153846,
          957.5384615384615, 200.6153846153846, 955.0769230769231,
          201.84615384615384, 950.1538461538462, 201.84615384615384,
          947.6923076923077, 203.07692307692307, 942.7692307692307,
          203.07692307692307, 940.3076923076923, 204.30769230769232,
          935.3846153846154, 204.30769230769232, 932.9230769230769,
          205.53846153846155, 928, 205.53846153846155, 925.5384615384615,
          206.76923076923077, 923.0769230769231, 206.76923076923077,
          920.6153846153846, 208, 918.1538461538462, 208, 915.6923076923077,
          209.23076923076923, 913.2307692307693, 209.23076923076923,
          910.7692307692307, 210.46153846153845, 908.3076923076923,
          210.46153846153845, 905.8461538461538, 211.69230769230768,
          903.3846153846154, 211.69230769230768, 898.4615384615385,
          214.15384615384616, 896, 214.15384615384616, 893.5384615384615,
          215.3846153846154, 891.0769230769231, 215.3846153846154,
          888.6153846153846, 216.6153846153846, 883.6923076923077,
          216.6153846153846, 881.2307692307693, 217.84615384615384,
          868.9230769230769, 217.84615384615384, 866.4615384615385,
          219.07692307692307, 785.2307692307693, 219.07692307692307,
          782.7692307692307, 220.30769230769232, 760.6153846153846,
          220.30769230769232, 758.1538461538462, 221.53846153846155,
          750.7692307692307, 221.53846153846155, 750.7692307692307,
          222.76923076923077, 753.2307692307693, 222.76923076923077,
          755.6923076923077, 224, 763.0769230769231, 224, 765.5384615384615,
          225.23076923076923, 864, 225.23076923076923, 866.4615384615385, 224,
          876.3076923076923, 224, 878.7692307692307, 222.76923076923077,
          883.6923076923077, 222.76923076923077, 888.6153846153846,
          220.30769230769232, 891.0769230769231, 220.30769230769232,
          891.0769230769231, 219.07692307692307, 900.9230769230769,
          214.15384615384616, 903.3846153846154, 214.15384615384616,
          905.8461538461538, 212.92307692307693, 910.7692307692307,
          212.92307692307693, 913.2307692307693, 211.69230769230768,
          915.6923076923077, 211.69230769230768, 918.1538461538462,
          210.46153846153845, 923.0769230769231, 210.46153846153845,
          925.5384615384615, 209.23076923076923, 928, 209.23076923076923,
          930.4615384615385, 208, 932.9230769230769, 208, 935.3846153846154,
          206.76923076923077, 940.3076923076923, 206.76923076923077,
          942.7692307692307, 205.53846153846155, 945.2307692307693,
          205.53846153846155, 947.6923076923077, 204.30769230769232,
          957.5384615384615, 204.30769230769232, 960, 203.07692307692307,
          994.4615384615385, 203.07692307692307, 996.9230769230769,
          201.84615384615384, 1014.1538461538462, 201.84615384615384,
          1016.6153846153846, 200.6153846153846, 1019.0769230769231,
          200.6153846153846, 1019.0769230769231, 196.92307692307693,
        ],
        attributes: [],
        color: '#1B6C9F',
        name: 'Inner nuclear layer',
        order: 4,
      },
      {
        type: 'polygon',
        classId: 8,
        className: 'opl',
        probability: 0.7603781819343567,
        points: [
          541.5384615384615, 299.0769230769231, 529.2307692307693,
          305.2307692307692, 558.7692307692307, 305.2307692307692,
          561.2307692307693, 304, 566.1538461538462, 304, 568.6153846153846,
          305.2307692307692, 576, 305.2307692307692, 578.4615384615385,
          306.46153846153845, 585.8461538461538, 306.46153846153845,
          588.3076923076923, 307.6923076923077, 590.7692307692307,
          307.6923076923077, 593.2307692307693, 308.9230769230769,
          595.6923076923077, 308.9230769230769, 598.1538461538462,
          310.15384615384613, 600.6153846153846, 310.15384615384613,
          603.0769230769231, 311.38461538461536, 605.5384615384615,
          311.38461538461536, 608, 312.61538461538464, 610.4615384615385,
          312.61538461538464, 612.9230769230769, 313.84615384615387,
          647.3846153846154, 313.84615384615387, 649.8461538461538,
          312.61538461538464, 654.7692307692307, 312.61538461538464,
          654.7692307692307, 311.38461538461536, 649.8461538461538,
          311.38461538461536, 647.3846153846154, 310.15384615384613,
          644.9230769230769, 310.15384615384613, 642.4615384615385,
          311.38461538461536, 635.0769230769231, 311.38461538461536,
          632.6153846153846, 310.15384615384613, 617.8461538461538,
          310.15384615384613, 615.3846153846154, 308.9230769230769,
          612.9230769230769, 308.9230769230769, 610.4615384615385,
          307.6923076923077, 608, 307.6923076923077, 605.5384615384615,
          306.46153846153845, 600.6153846153846, 306.46153846153845,
          598.1538461538462, 305.2307692307692, 595.6923076923077,
          305.2307692307692, 590.7692307692307, 302.7692307692308,
          588.3076923076923, 302.7692307692308, 585.8461538461538,
          301.53846153846155, 583.3846153846154, 301.53846153846155,
          580.9230769230769, 300.3076923076923, 571.0769230769231,
          300.3076923076923, 568.6153846153846, 299.0769230769231,
        ],
        attributes: [],
        color: '#A162F1',
        name: 'Outer plexiform layer',
        order: 5,
      },
      {
        type: 'polygon',
        classId: 8,
        className: 'opl',
        probability: 0.7418476343154907,
        points: [
          770.4615384615385, 286.7692307692308, 768, 288, 763.0769230769231,
          288, 760.6153846153846, 289.2307692307692, 758.1538461538462,
          289.2307692307692, 753.2307692307693, 291.6923076923077,
          748.3076923076923, 291.6923076923077, 745.8461538461538,
          292.9230769230769, 743.3846153846154, 292.9230769230769,
          740.9230769230769, 294.15384615384613, 716.3076923076923,
          294.15384615384613, 713.8461538461538, 292.9230769230769,
          676.9230769230769, 292.9230769230769, 674.4615384615385,
          294.15384615384613, 667.0769230769231, 294.15384615384613,
          664.6153846153846, 295.38461538461536, 706.4615384615385,
          295.38461538461536, 711.3846153846154, 297.84615384615387,
          713.8461538461538, 297.84615384615387, 716.3076923076923,
          299.0769230769231, 728.6153846153846, 299.0769230769231,
          731.0769230769231, 297.84615384615387, 740.9230769230769,
          297.84615384615387, 743.3846153846154, 296.61538461538464,
          745.8461538461538, 296.61538461538464, 750.7692307692307,
          294.15384615384613, 750.7692307692307, 292.9230769230769,
          753.2307692307693, 291.6923076923077, 755.6923076923077,
          291.6923076923077, 758.1538461538462, 290.46153846153845,
          760.6153846153846, 290.46153846153845, 763.0769230769231,
          289.2307692307692, 770.4615384615385, 289.2307692307692,
          772.9230769230769, 288, 777.8461538461538, 288, 780.3076923076923,
          286.7692307692308, 782.7692307692307, 286.7692307692308,
        ],
        attributes: [],
        color: '#A162F1',
        name: 'Outer plexiform layer',
        order: 5,
      },
      {
        type: 'polygon',
        classId: 8,
        className: 'opl',
        probability: 0.8181317448616028,
        points: [
          2.4615384615384617, 221.53846153846155, 4.923076923076923,
          222.76923076923077, 9.846153846153847, 222.76923076923077,
          12.307692307692308, 224, 14.76923076923077, 224, 17.23076923076923,
          225.23076923076923, 22.153846153846153, 225.23076923076923,
          24.615384615384617, 226.46153846153845, 29.53846153846154,
          226.46153846153845, 32, 227.69230769230768, 93.53846153846153,
          227.69230769230768, 96, 228.92307692307693, 110.76923076923077,
          228.92307692307693, 113.23076923076923, 230.15384615384616,
          125.53846153846153, 230.15384615384616, 128, 231.3846153846154,
          140.30769230769232, 231.3846153846154, 142.76923076923077,
          232.6153846153846, 152.6153846153846, 232.6153846153846,
          155.07692307692307, 233.84615384615384, 162.46153846153845,
          233.84615384615384, 164.92307692307693, 235.07692307692307,
          174.76923076923077, 235.07692307692307, 177.23076923076923,
          236.30769230769232, 189.53846153846155, 236.30769230769232, 192,
          237.53846153846155, 201.84615384615384, 237.53846153846155,
          204.30769230769232, 238.76923076923077, 216.6153846153846,
          238.76923076923077, 219.07692307692307, 240, 231.3846153846154, 240,
          233.84615384615384, 241.23076923076923, 243.69230769230768,
          241.23076923076923, 246.15384615384616, 242.46153846153845,
          253.53846153846155, 242.46153846153845, 256, 243.69230769230768,
          263.38461538461536, 243.69230769230768, 265.84615384615387,
          244.92307692307693, 270.7692307692308, 244.92307692307693,
          273.2307692307692, 246.15384615384616, 280.61538461538464,
          246.15384615384616, 283.0769230769231, 247.3846153846154,
          290.46153846153845, 247.3846153846154, 292.9230769230769,
          248.6153846153846, 300.3076923076923, 248.6153846153846,
          302.7692307692308, 249.84615384615384, 312.61538461538464,
          249.84615384615384, 315.0769230769231, 251.07692307692307,
          364.3076923076923, 251.07692307692307, 366.7692307692308,
          249.84615384615384, 388.9230769230769, 249.84615384615384,
          391.38461538461536, 248.6153846153846, 403.6923076923077,
          248.6153846153846, 406.15384615384613, 247.3846153846154,
          411.0769230769231, 247.3846153846154, 413.53846153846155,
          246.15384615384616, 416, 246.15384615384616, 418.46153846153845,
          244.92307692307693, 425.84615384615387, 244.92307692307693,
          428.3076923076923, 243.69230769230768, 433.2307692307692,
          243.69230769230768, 435.6923076923077, 242.46153846153845,
          440.61538461538464, 242.46153846153845, 443.0769230769231,
          241.23076923076923, 448, 241.23076923076923, 450.46153846153845, 240,
          452.9230769230769, 240, 455.38461538461536, 238.76923076923077,
          462.7692307692308, 238.76923076923077, 465.2307692307692,
          237.53846153846155, 472.61538461538464, 237.53846153846155,
          475.0769230769231, 236.30769230769232, 526.7692307692307,
          236.30769230769232, 529.2307692307693, 235.07692307692307,
          541.5384615384615, 235.07692307692307, 526.7692307692307,
          235.07692307692307, 524.3076923076923, 233.84615384615384,
          504.61538461538464, 233.84615384615384, 502.15384615384613,
          232.6153846153846, 467.6923076923077, 232.6153846153846,
          465.2307692307692, 233.84615384615384, 457.84615384615387,
          233.84615384615384, 455.38461538461536, 235.07692307692307, 448,
          235.07692307692307, 445.53846153846155, 236.30769230769232,
          443.0769230769231, 236.30769230769232, 440.61538461538464,
          237.53846153846155, 433.2307692307692, 237.53846153846155,
          430.7692307692308, 238.76923076923077, 423.38461538461536,
          238.76923076923077, 420.9230769230769, 240, 413.53846153846155, 240,
          411.0769230769231, 241.23076923076923, 406.15384615384613,
          241.23076923076923, 403.6923076923077, 242.46153846153845,
          396.3076923076923, 242.46153846153845, 393.84615384615387,
          243.69230769230768, 379.0769230769231, 243.69230769230768,
          376.61538461538464, 244.92307692307693, 359.38461538461536,
          244.92307692307693, 356.9230769230769, 246.15384615384616,
          312.61538461538464, 246.15384615384616, 310.15384615384613,
          244.92307692307693, 302.7692307692308, 244.92307692307693,
          300.3076923076923, 243.69230769230768, 290.46153846153845,
          243.69230769230768, 288, 242.46153846153845, 280.61538461538464,
          242.46153846153845, 278.15384615384613, 241.23076923076923,
          270.7692307692308, 241.23076923076923, 268.3076923076923, 240,
          260.9230769230769, 240, 258.46153846153845, 238.76923076923077,
          253.53846153846155, 238.76923076923077, 251.07692307692307,
          237.53846153846155, 243.69230769230768, 237.53846153846155,
          241.23076923076923, 236.30769230769232, 231.3846153846154,
          236.30769230769232, 228.92307692307693, 235.07692307692307,
          219.07692307692307, 235.07692307692307, 216.6153846153846,
          233.84615384615384, 204.30769230769232, 233.84615384615384,
          201.84615384615384, 232.6153846153846, 192, 232.6153846153846,
          189.53846153846155, 231.3846153846154, 174.76923076923077,
          231.3846153846154, 172.30769230769232, 230.15384615384616,
          162.46153846153845, 230.15384615384616, 160, 228.92307692307693,
          152.6153846153846, 228.92307692307693, 150.15384615384616,
          227.69230769230768, 142.76923076923077, 227.69230769230768,
          140.30769230769232, 226.46153846153845, 125.53846153846153,
          226.46153846153845, 123.07692307692308, 225.23076923076923,
          110.76923076923077, 225.23076923076923, 108.3076923076923, 224,
          91.07692307692308, 224, 88.61538461538461, 222.76923076923077,
          54.15384615384615, 222.76923076923077, 51.69230769230769, 224,
          22.153846153846153, 224, 19.692307692307693, 222.76923076923077,
          12.307692307692308, 222.76923076923077, 9.846153846153847,
          221.53846153846155,
        ],
        attributes: [],
        color: '#A162F1',
        name: 'Outer plexiform layer',
        order: 5,
      },
      {
        type: 'polygon',
        classId: 8,
        className: 'opl',
        probability: 0.793718159198761,
        points: [
          1016.6153846153846, 201.84615384615384, 1014.1538461538462,
          203.07692307692307, 999.3846153846154, 203.07692307692307,
          996.9230769230769, 204.30769230769232, 960, 204.30769230769232,
          957.5384615384615, 205.53846153846155, 950.1538461538462,
          205.53846153846155, 947.6923076923077, 206.76923076923077,
          942.7692307692307, 206.76923076923077, 940.3076923076923, 208,
          935.3846153846154, 208, 932.9230769230769, 209.23076923076923,
          930.4615384615385, 209.23076923076923, 928, 210.46153846153845,
          925.5384615384615, 210.46153846153845, 923.0769230769231,
          211.69230769230768, 920.6153846153846, 211.69230769230768,
          918.1538461538462, 212.92307692307693, 913.2307692307693,
          212.92307692307693, 910.7692307692307, 214.15384615384616,
          908.3076923076923, 214.15384615384616, 905.8461538461538,
          215.3846153846154, 903.3846153846154, 215.3846153846154,
          900.9230769230769, 216.6153846153846, 898.4615384615385,
          216.6153846153846, 898.4615384615385, 217.84615384615384, 896,
          219.07692307692307, 893.5384615384615, 219.07692307692307,
          893.5384615384615, 220.30769230769232, 891.0769230769231,
          221.53846153846155, 888.6153846153846, 221.53846153846155,
          883.6923076923077, 224, 878.7692307692307, 224, 876.3076923076923,
          225.23076923076923, 868.9230769230769, 225.23076923076923,
          866.4615384615385, 226.46153846153845, 758.1538461538462,
          226.46153846153845, 755.6923076923077, 225.23076923076923,
          743.3846153846154, 225.23076923076923, 748.3076923076923,
          225.23076923076923, 750.7692307692307, 226.46153846153845,
          758.1538461538462, 226.46153846153845, 760.6153846153846,
          227.69230769230768, 765.5384615384615, 227.69230769230768, 768,
          228.92307692307693, 772.9230769230769, 228.92307692307693,
          775.3846153846154, 230.15384615384616, 785.2307692307693,
          230.15384615384616, 787.6923076923077, 231.3846153846154,
          873.8461538461538, 231.3846153846154, 876.3076923076923,
          230.15384615384616, 883.6923076923077, 230.15384615384616,
          886.1538461538462, 228.92307692307693, 891.0769230769231,
          228.92307692307693, 893.5384615384615, 227.69230769230768,
          893.5384615384615, 226.46153846153845, 896, 225.23076923076923, 896,
          224, 898.4615384615385, 222.76923076923077, 900.9230769230769,
          222.76923076923077, 905.8461538461538, 220.30769230769232,
          908.3076923076923, 220.30769230769232, 910.7692307692307,
          219.07692307692307, 913.2307692307693, 219.07692307692307,
          915.6923076923077, 217.84615384615384, 918.1538461538462,
          217.84615384615384, 920.6153846153846, 216.6153846153846,
          923.0769230769231, 216.6153846153846, 925.5384615384615,
          215.3846153846154, 928, 215.3846153846154, 930.4615384615385,
          214.15384615384616, 932.9230769230769, 214.15384615384616,
          935.3846153846154, 212.92307692307693, 937.8461538461538,
          212.92307692307693, 940.3076923076923, 211.69230769230768,
          945.2307692307693, 211.69230769230768, 947.6923076923077,
          210.46153846153845, 955.0769230769231, 210.46153846153845,
          957.5384615384615, 209.23076923076923, 967.3846153846154,
          209.23076923076923, 969.8461538461538, 208, 994.4615384615385, 208,
          996.9230769230769, 206.76923076923077, 1011.6923076923077,
          206.76923076923077, 1014.1538461538462, 205.53846153846155,
          1021.5384615384615, 205.53846153846155, 1021.5384615384615,
          203.07692307692307, 1019.0769230769231, 201.84615384615384,
        ],
        attributes: [],
        color: '#A162F1',
        name: 'Outer plexiform layer',
        order: 5,
      },
      {
        type: 'polygon',
        classId: 7,
        className: 'onl',
        probability: 0.7126632332801819,
        points: [
          593.2307692307693, 342.15384615384613, 593.2307692307693,
          344.61538461538464, 595.6923076923077, 345.84615384615387,
          598.1538461538462, 344.61538461538464, 598.1538461538462,
          343.38461538461536, 595.6923076923077, 343.38461538461536,
        ],
        attributes: [],
        color: '#F76CE0',
        name: 'Outer nuclear layer + Henle`s nerve fiber layer',
        order: 6,
      },
      {
        type: 'polygon',
        classId: 7,
        className: 'onl',
        probability: 0.7731673717498779,
        points: [
          775.3846153846154, 289.2307692307692, 772.9230769230769,
          290.46153846153845, 768, 290.46153846153845, 765.5384615384615,
          291.6923076923077, 760.6153846153846, 291.6923076923077,
          748.3076923076923, 297.84615384615387, 745.8461538461538,
          297.84615384615387, 743.3846153846154, 299.0769230769231,
          733.5384615384615, 299.0769230769231, 731.0769230769231,
          300.3076923076923, 713.8461538461538, 300.3076923076923,
          711.3846153846154, 299.0769230769231, 708.9230769230769,
          299.0769230769231, 706.4615384615385, 297.84615384615387, 704,
          297.84615384615387, 701.5384615384615, 296.61538461538464,
          674.4615384615385, 296.61538461538464, 672, 297.84615384615387,
          669.5384615384615, 297.84615384615387, 667.0769230769231,
          299.0769230769231, 667.0769230769231, 305.2307692307692,
          664.6153846153846, 306.46153846153845, 664.6153846153846,
          307.6923076923077, 662.1538461538462, 308.9230769230769,
          662.1538461538462, 311.38461538461536, 657.2307692307693,
          313.84615384615387, 652.3076923076923, 313.84615384615387,
          649.8461538461538, 315.0769230769231, 632.6153846153846,
          315.0769230769231, 630.1538461538462, 316.3076923076923,
          625.2307692307693, 316.3076923076923, 622.7692307692307,
          315.0769230769231, 612.9230769230769, 315.0769230769231,
          610.4615384615385, 313.84615384615387, 608, 313.84615384615387,
          605.5384615384615, 312.61538461538464, 600.6153846153846,
          312.61538461538464, 598.1538461538462, 311.38461538461536,
          595.6923076923077, 311.38461538461536, 590.7692307692307,
          308.9230769230769, 585.8461538461538, 308.9230769230769,
          583.3846153846154, 307.6923076923077, 578.4615384615385,
          307.6923076923077, 576, 306.46153846153845, 541.5384615384615,
          306.46153846153845, 539.0769230769231, 307.6923076923077,
          526.7692307692307, 307.6923076923077, 521.8461538461538,
          310.15384615384613, 521.8461538461538, 312.61538461538464,
          524.3076923076923, 313.84615384615387, 524.3076923076923,
          315.0769230769231, 526.7692307692307, 316.3076923076923,
          529.2307692307693, 316.3076923076923, 531.6923076923077,
          317.53846153846155, 534.1538461538462, 317.53846153846155,
          536.6153846153846, 318.7692307692308, 563.6923076923077,
          318.7692307692308, 566.1538461538462, 320, 568.6153846153846, 320,
          571.0769230769231, 321.2307692307692, 573.5384615384615,
          321.2307692307692, 576, 322.46153846153845, 578.4615384615385,
          322.46153846153845, 580.9230769230769, 323.6923076923077,
          603.0769230769231, 323.6923076923077, 605.5384615384615,
          324.9230769230769, 612.9230769230769, 324.9230769230769,
          615.3846153846154, 326.15384615384613, 632.6153846153846,
          326.15384615384613, 635.0769230769231, 324.9230769230769,
          644.9230769230769, 324.9230769230769, 647.3846153846154,
          323.6923076923077, 657.2307692307693, 323.6923076923077,
          659.6923076923077, 322.46153846153845, 669.5384615384615,
          322.46153846153845, 674.4615384615385, 320, 684.3076923076923, 320,
          689.2307692307693, 317.53846153846155, 701.5384615384615,
          317.53846153846155, 704, 316.3076923076923, 721.2307692307693,
          316.3076923076923, 723.6923076923077, 315.0769230769231,
          726.1538461538462, 315.0769230769231, 728.6153846153846,
          313.84615384615387, 731.0769230769231, 313.84615384615387, 736,
          311.38461538461536, 738.4615384615385, 311.38461538461536,
          743.3846153846154, 308.9230769230769, 745.8461538461538,
          308.9230769230769, 750.7692307692307, 306.46153846153845,
          755.6923076923077, 306.46153846153845, 763.0769230769231,
          302.7692307692308, 765.5384615384615, 302.7692307692308,
          765.5384615384615, 301.53846153846155, 780.3076923076923,
          294.15384615384613, 782.7692307692307, 294.15384615384613,
          787.6923076923077, 291.6923076923077, 787.6923076923077,
          289.2307692307692,
        ],
        attributes: [],
        color: '#F76CE0',
        name: 'Outer nuclear layer + Henle`s nerve fiber layer',
        order: 6,
      },
      {
        type: 'polygon',
        classId: 7,
        className: 'onl',
        probability: 0.7669132947921753,
        points: [
          502.15384615384613, 280.61538461538464, 499.6923076923077,
          281.84615384615387, 482.46153846153845, 281.84615384615387,
          487.38461538461536, 281.84615384615387, 489.84615384615387,
          283.0769230769231, 497.2307692307692, 283.0769230769231,
          499.6923076923077, 284.3076923076923, 504.61538461538464,
          284.3076923076923, 507.0769230769231, 285.53846153846155,
          524.3076923076923, 285.53846153846155, 526.7692307692307,
          284.3076923076923, 529.2307692307693, 284.3076923076923,
          529.2307692307693, 283.0769230769231, 519.3846153846154,
          283.0769230769231, 516.9230769230769, 281.84615384615387,
          507.0769230769231, 281.84615384615387, 504.61538461538464,
          280.61538461538464,
        ],
        attributes: [],
        color: '#F76CE0',
        name: 'Outer nuclear layer + Henle`s nerve fiber layer',
        order: 6,
      },
      {
        type: 'polygon',
        classId: 7,
        className: 'onl',
        probability: 0.8179321885108948,
        points: [
          2.4615384615384617, 224, 2.4615384615384617, 227.69230769230768,
          7.384615384615385, 227.69230769230768, 9.846153846153847,
          228.92307692307693, 12.307692307692308, 228.92307692307693,
          14.76923076923077, 230.15384615384616, 17.23076923076923,
          230.15384615384616, 19.692307692307693, 231.3846153846154,
          22.153846153846153, 231.3846153846154, 32, 236.30769230769232,
          34.46153846153846, 236.30769230769232, 39.38461538461539,
          238.76923076923077, 41.84615384615385, 238.76923076923077,
          44.30769230769231, 240, 64, 240, 66.46153846153847,
          238.76923076923077, 96, 238.76923076923077, 98.46153846153847, 240,
          115.6923076923077, 240, 118.15384615384616, 241.23076923076923, 128,
          241.23076923076923, 130.46153846153845, 242.46153846153845,
          140.30769230769232, 242.46153846153845, 142.76923076923077,
          243.69230769230768, 150.15384615384616, 243.69230769230768,
          152.6153846153846, 244.92307692307693, 160, 244.92307692307693,
          162.46153846153845, 246.15384615384616, 169.84615384615384,
          246.15384615384616, 172.30769230769232, 247.3846153846154,
          179.69230769230768, 247.3846153846154, 182.15384615384616,
          248.6153846153846, 192, 248.6153846153846, 194.46153846153845,
          249.84615384615384, 204.30769230769232, 249.84615384615384,
          206.76923076923077, 251.07692307692307, 226.46153846153845,
          251.07692307692307, 228.92307692307693, 252.30769230769232,
          238.76923076923077, 252.30769230769232, 241.23076923076923,
          253.53846153846155, 251.07692307692307, 253.53846153846155,
          253.53846153846155, 254.76923076923077, 265.84615384615387,
          254.76923076923077, 268.3076923076923, 256, 278.15384615384613, 256,
          280.61538461538464, 257.2307692307692, 288, 257.2307692307692,
          290.46153846153845, 258.46153846153845, 300.3076923076923,
          258.46153846153845, 302.7692307692308, 259.6923076923077,
          310.15384615384613, 259.6923076923077, 312.61538461538464,
          260.9230769230769, 411.0769230769231, 260.9230769230769,
          413.53846153846155, 259.6923076923077, 423.38461538461536,
          259.6923076923077, 425.84615384615387, 258.46153846153845,
          430.7692307692308, 258.46153846153845, 433.2307692307692,
          257.2307692307692, 435.6923076923077, 257.2307692307692,
          438.15384615384613, 256, 443.0769230769231, 256, 445.53846153846155,
          254.76923076923077, 448, 254.76923076923077, 450.46153846153845,
          253.53846153846155, 455.38461538461536, 253.53846153846155,
          457.84615384615387, 252.30769230769232, 465.2307692307692,
          252.30769230769232, 467.6923076923077, 251.07692307692307,
          477.53846153846155, 251.07692307692307, 480, 252.30769230769232,
          494.7692307692308, 252.30769230769232, 497.2307692307692,
          251.07692307692307, 512, 251.07692307692307, 514.4615384615385,
          249.84615384615384, 541.5384615384615, 249.84615384615384, 544,
          248.6153846153846, 551.3846153846154, 248.6153846153846,
          553.8461538461538, 247.3846153846154, 558.7692307692307,
          247.3846153846154, 563.6923076923077, 244.92307692307693,
          561.2307692307693, 243.69230769230768, 561.2307692307693,
          242.46153846153845, 558.7692307692307, 241.23076923076923,
          558.7692307692307, 240, 556.3076923076923, 238.76923076923077,
          553.8461538461538, 238.76923076923077, 551.3846153846154,
          237.53846153846155, 475.0769230769231, 237.53846153846155,
          472.61538461538464, 238.76923076923077, 465.2307692307692,
          238.76923076923077, 462.7692307692308, 240, 455.38461538461536, 240,
          452.9230769230769, 241.23076923076923, 450.46153846153845,
          241.23076923076923, 448, 242.46153846153845, 445.53846153846155,
          242.46153846153845, 443.0769230769231, 243.69230769230768,
          435.6923076923077, 243.69230769230768, 433.2307692307692,
          244.92307692307693, 428.3076923076923, 244.92307692307693,
          425.84615384615387, 246.15384615384616, 418.46153846153845,
          246.15384615384616, 416, 247.3846153846154, 413.53846153846155,
          247.3846153846154, 411.0769230769231, 248.6153846153846,
          406.15384615384613, 248.6153846153846, 403.6923076923077,
          249.84615384615384, 391.38461538461536, 249.84615384615384,
          388.9230769230769, 251.07692307692307, 366.7692307692308,
          251.07692307692307, 364.3076923076923, 252.30769230769232,
          315.0769230769231, 252.30769230769232, 312.61538461538464,
          251.07692307692307, 300.3076923076923, 251.07692307692307,
          297.84615384615387, 249.84615384615384, 292.9230769230769,
          249.84615384615384, 290.46153846153845, 248.6153846153846,
          283.0769230769231, 248.6153846153846, 280.61538461538464,
          247.3846153846154, 273.2307692307692, 247.3846153846154,
          270.7692307692308, 246.15384615384616, 265.84615384615387,
          246.15384615384616, 263.38461538461536, 244.92307692307693, 256,
          244.92307692307693, 253.53846153846155, 243.69230769230768,
          246.15384615384616, 243.69230769230768, 243.69230769230768,
          242.46153846153845, 233.84615384615384, 242.46153846153845,
          231.3846153846154, 241.23076923076923, 219.07692307692307,
          241.23076923076923, 216.6153846153846, 240, 204.30769230769232, 240,
          201.84615384615384, 238.76923076923077, 192, 238.76923076923077,
          189.53846153846155, 237.53846153846155, 177.23076923076923,
          237.53846153846155, 174.76923076923077, 236.30769230769232,
          164.92307692307693, 236.30769230769232, 162.46153846153845,
          235.07692307692307, 155.07692307692307, 235.07692307692307,
          152.6153846153846, 233.84615384615384, 142.76923076923077,
          233.84615384615384, 140.30769230769232, 232.6153846153846, 128,
          232.6153846153846, 125.53846153846153, 231.3846153846154,
          113.23076923076923, 231.3846153846154, 110.76923076923077,
          230.15384615384616, 96, 230.15384615384616, 93.53846153846153,
          228.92307692307693, 32, 228.92307692307693, 29.53846153846154,
          227.69230769230768, 24.615384615384617, 227.69230769230768,
          22.153846153846153, 226.46153846153845, 17.23076923076923,
          226.46153846153845, 14.76923076923077, 225.23076923076923,
          9.846153846153847, 225.23076923076923, 7.384615384615385, 224,
        ],
        attributes: [],
        color: '#F76CE0',
        name: 'Outer nuclear layer + Henle`s nerve fiber layer',
        order: 6,
      },
      {
        type: 'polygon',
        classId: 7,
        className: 'onl',
        probability: 0.8076112270355225,
        points: [
          1014.1538461538462, 206.76923076923077, 1011.6923076923077, 208,
          996.9230769230769, 208, 994.4615384615385, 209.23076923076923,
          969.8461538461538, 209.23076923076923, 967.3846153846154,
          210.46153846153845, 957.5384615384615, 210.46153846153845,
          955.0769230769231, 211.69230769230768, 947.6923076923077,
          211.69230769230768, 945.2307692307693, 212.92307692307693,
          940.3076923076923, 212.92307692307693, 937.8461538461538,
          214.15384615384616, 935.3846153846154, 214.15384615384616,
          932.9230769230769, 215.3846153846154, 930.4615384615385,
          215.3846153846154, 928, 216.6153846153846, 925.5384615384615,
          216.6153846153846, 923.0769230769231, 217.84615384615384,
          920.6153846153846, 217.84615384615384, 918.1538461538462,
          219.07692307692307, 915.6923076923077, 219.07692307692307,
          913.2307692307693, 220.30769230769232, 910.7692307692307,
          220.30769230769232, 908.3076923076923, 221.53846153846155,
          905.8461538461538, 221.53846153846155, 896, 226.46153846153845, 896,
          227.69230769230768, 891.0769230769231, 230.15384615384616,
          886.1538461538462, 230.15384615384616, 883.6923076923077,
          231.3846153846154, 876.3076923076923, 231.3846153846154,
          873.8461538461538, 232.6153846153846, 787.6923076923077,
          232.6153846153846, 785.2307692307693, 231.3846153846154,
          775.3846153846154, 231.3846153846154, 772.9230769230769,
          230.15384615384616, 768, 230.15384615384616, 765.5384615384615,
          228.92307692307693, 760.6153846153846, 228.92307692307693,
          758.1538461538462, 227.69230769230768, 733.5384615384615,
          227.69230769230768, 731.0769230769231, 228.92307692307693,
          733.5384615384615, 230.15384615384616, 733.5384615384615,
          231.3846153846154, 736, 232.6153846153846, 736, 235.07692307692307,
          738.4615384615385, 236.30769230769232, 743.3846153846154,
          236.30769230769232, 745.8461538461538, 237.53846153846155,
          750.7692307692307, 237.53846153846155, 753.2307692307693,
          238.76923076923077, 760.6153846153846, 238.76923076923077,
          763.0769230769231, 240, 768, 240, 770.4615384615385,
          241.23076923076923, 777.8461538461538, 241.23076923076923,
          780.3076923076923, 242.46153846153845, 824.6153846153846,
          242.46153846153845, 827.0769230769231, 241.23076923076923,
          844.3076923076923, 241.23076923076923, 846.7692307692307,
          242.46153846153845, 883.6923076923077, 242.46153846153845,
          886.1538461538462, 241.23076923076923, 893.5384615384615,
          241.23076923076923, 896, 240, 903.3846153846154, 240,
          905.8461538461538, 238.76923076923077, 908.3076923076923,
          238.76923076923077, 913.2307692307693, 236.30769230769232,
          915.6923076923077, 236.30769230769232, 925.5384615384615,
          231.3846153846154, 928, 231.3846153846154, 930.4615384615385,
          230.15384615384616, 932.9230769230769, 230.15384615384616,
          935.3846153846154, 228.92307692307693, 937.8461538461538,
          228.92307692307693, 940.3076923076923, 227.69230769230768,
          945.2307692307693, 227.69230769230768, 947.6923076923077,
          226.46153846153845, 950.1538461538462, 226.46153846153845,
          952.6153846153846, 225.23076923076923, 955.0769230769231,
          225.23076923076923, 957.5384615384615, 224, 962.4615384615385, 224,
          964.9230769230769, 222.76923076923077, 967.3846153846154,
          222.76923076923077, 969.8461538461538, 221.53846153846155,
          974.7692307692307, 221.53846153846155, 977.2307692307693,
          220.30769230769232, 987.0769230769231, 220.30769230769232,
          989.5384615384615, 219.07692307692307, 996.9230769230769,
          219.07692307692307, 999.3846153846154, 217.84615384615384,
          1004.3076923076923, 217.84615384615384, 1006.7692307692307,
          216.6153846153846, 1009.2307692307693, 216.6153846153846,
          1011.6923076923077, 215.3846153846154, 1019.0769230769231,
          215.3846153846154, 1021.5384615384615, 214.15384615384616,
          1021.5384615384615, 208, 1019.0769230769231, 206.76923076923077,
        ],
        attributes: [],
        color: '#F76CE0',
        name: 'Outer nuclear layer + Henle`s nerve fiber layer',
        order: 6,
      },
      {
        type: 'polygon',
        classId: 1,
        className: 'elm',
        probability: 0.7372092604637146,
        points: [
          499.6923076923077, 252.30769230769232, 497.2307692307692,
          253.53846153846155, 487.38461538461536, 253.53846153846155,
          489.84615384615387, 254.76923076923077, 497.2307692307692,
          254.76923076923077, 499.6923076923077, 253.53846153846155,
          507.0769230769231, 253.53846153846155, 509.53846153846155,
          252.30769230769232, 512, 252.30769230769232,
        ],
        attributes: [],
        color: '#3EB2F3',
        name: 'External limiting membrane',
        order: 7,
      },
      {
        type: 'polygon',
        classId: 1,
        className: 'elm',
        probability: 0.7464771866798401,
        points: [
          71.38461538461539, 240, 68.92307692307692, 241.23076923076923,
          59.07692307692308, 241.23076923076923, 56.61538461538461,
          242.46153846153845, 54.15384615384615, 242.46153846153845,
          51.69230769230769, 241.23076923076923, 39.38461538461539,
          241.23076923076923, 39.38461538461539, 242.46153846153845,
          41.84615384615385, 242.46153846153845, 44.30769230769231,
          243.69230769230768, 59.07692307692308, 243.69230769230768,
          61.53846153846154, 242.46153846153845, 64, 242.46153846153845,
          66.46153846153847, 241.23076923076923, 105.84615384615384,
          241.23076923076923, 108.3076923076923, 242.46153846153845,
          123.07692307692308, 242.46153846153845, 125.53846153846153,
          243.69230769230768, 135.3846153846154, 243.69230769230768,
          137.84615384615384, 244.92307692307693, 145.23076923076923,
          244.92307692307693, 147.69230769230768, 246.15384615384616,
          155.07692307692307, 246.15384615384616, 157.53846153846155,
          247.3846153846154, 164.92307692307693, 247.3846153846154,
          167.3846153846154, 248.6153846153846, 174.76923076923077,
          248.6153846153846, 177.23076923076923, 249.84615384615384,
          184.6153846153846, 249.84615384615384, 187.07692307692307,
          251.07692307692307, 194.46153846153845, 251.07692307692307,
          196.92307692307693, 252.30769230769232, 209.23076923076923,
          252.30769230769232, 211.69230769230768, 253.53846153846155,
          231.3846153846154, 253.53846153846155, 233.84615384615384,
          254.76923076923077, 246.15384615384616, 254.76923076923077,
          248.6153846153846, 256, 260.9230769230769, 256, 263.38461538461536,
          257.2307692307692, 273.2307692307692, 257.2307692307692,
          275.6923076923077, 258.46153846153845, 285.53846153846155,
          258.46153846153845, 288, 259.6923076923077, 295.38461538461536,
          259.6923076923077, 297.84615384615387, 260.9230769230769,
          302.7692307692308, 260.9230769230769, 305.2307692307692,
          262.15384615384613, 322.46153846153845, 262.15384615384613,
          324.9230769230769, 263.38461538461536, 334.7692307692308,
          263.38461538461536, 337.2307692307692, 262.15384615384613,
          342.15384615384613, 262.15384615384613, 344.61538461538464,
          263.38461538461536, 416, 263.38461538461536, 418.46153846153845,
          262.15384615384613, 425.84615384615387, 262.15384615384613,
          428.3076923076923, 260.9230769230769, 433.2307692307692,
          260.9230769230769, 435.6923076923077, 259.6923076923077,
          438.15384615384613, 259.6923076923077, 443.0769230769231,
          257.2307692307692, 445.53846153846155, 257.2307692307692, 448, 256,
          450.46153846153845, 256, 448, 256, 445.53846153846155,
          257.2307692307692, 443.0769230769231, 257.2307692307692,
          440.61538461538464, 258.46153846153845, 435.6923076923077,
          258.46153846153845, 433.2307692307692, 259.6923076923077,
          430.7692307692308, 259.6923076923077, 428.3076923076923,
          260.9230769230769, 418.46153846153845, 260.9230769230769, 416,
          262.15384615384613, 374.15384615384613, 262.15384615384613,
          371.6923076923077, 263.38461538461536, 352, 263.38461538461536,
          349.53846153846155, 262.15384615384613, 310.15384615384613,
          262.15384615384613, 307.6923076923077, 260.9230769230769,
          300.3076923076923, 260.9230769230769, 297.84615384615387,
          259.6923076923077, 290.46153846153845, 259.6923076923077, 288,
          258.46153846153845, 275.6923076923077, 258.46153846153845,
          273.2307692307692, 257.2307692307692, 263.38461538461536,
          257.2307692307692, 260.9230769230769, 256, 251.07692307692307, 256,
          248.6153846153846, 254.76923076923077, 241.23076923076923,
          254.76923076923077, 238.76923076923077, 253.53846153846155,
          226.46153846153845, 253.53846153846155, 224, 252.30769230769232,
          206.76923076923077, 252.30769230769232, 204.30769230769232,
          251.07692307692307, 192, 251.07692307692307, 189.53846153846155,
          249.84615384615384, 179.69230769230768, 249.84615384615384,
          177.23076923076923, 248.6153846153846, 172.30769230769232,
          248.6153846153846, 169.84615384615384, 247.3846153846154, 160,
          247.3846153846154, 157.53846153846155, 246.15384615384616,
          150.15384615384616, 246.15384615384616, 147.69230769230768,
          244.92307692307693, 142.76923076923077, 244.92307692307693,
          140.30769230769232, 243.69230769230768, 128, 243.69230769230768,
          125.53846153846153, 242.46153846153845, 115.6923076923077,
          242.46153846153845, 113.23076923076923, 241.23076923076923,
          91.07692307692308, 241.23076923076923, 88.61538461538461, 240,
        ],
        attributes: [],
        color: '#3EB2F3',
        name: 'External limiting membrane',
        order: 7,
      },
      {
        type: 'polygon',
        classId: 1,
        className: 'elm',
        probability: 0.7397385239601135,
        points: [
          740.9230769230769, 237.53846153846155, 740.9230769230769,
          238.76923076923077, 748.3076923076923, 238.76923076923077,
          743.3846153846154, 238.76923076923077,
        ],
        attributes: [],
        color: '#3EB2F3',
        name: 'External limiting membrane',
        order: 7,
      },
      {
        type: 'polygon',
        classId: 1,
        className: 'elm',
        probability: 0.7089628577232361,
        points: [29.53846153846154, 236.30769230769232],
        attributes: [],
        color: '#3EB2F3',
        name: 'External limiting membrane',
        order: 7,
      },
      {
        type: 'polygon',
        classId: 1,
        className: 'elm',
        probability: 0.7348324060440063,
        points: [
          14.76923076923077, 231.3846153846154, 17.23076923076923,
          232.6153846153846, 19.692307692307693, 232.6153846153846,
          22.153846153846153, 233.84615384615384, 24.615384615384617,
          233.84615384615384, 22.153846153846153, 233.84615384615384,
          17.23076923076923, 231.3846153846154,
        ],
        attributes: [],
        color: '#3EB2F3',
        name: 'External limiting membrane',
        order: 7,
      },
      {
        type: 'polygon',
        classId: 1,
        className: 'elm',
        probability: 0.753867506980896,
        points: [
          1014.1538461538462, 216.6153846153846, 1011.6923076923077,
          217.84615384615384, 1006.7692307692307, 217.84615384615384,
          1004.3076923076923, 219.07692307692307, 1001.8461538461538,
          219.07692307692307, 999.3846153846154, 220.30769230769232, 992,
          220.30769230769232, 989.5384615384615, 221.53846153846155,
          979.6923076923077, 221.53846153846155, 977.2307692307693,
          222.76923076923077, 972.3076923076923, 222.76923076923077,
          969.8461538461538, 224, 967.3846153846154, 224, 964.9230769230769,
          225.23076923076923, 960, 225.23076923076923, 957.5384615384615,
          226.46153846153845, 955.0769230769231, 226.46153846153845,
          952.6153846153846, 227.69230769230768, 950.1538461538462,
          227.69230769230768, 947.6923076923077, 228.92307692307693,
          942.7692307692307, 228.92307692307693, 942.7692307692307,
          230.15384615384616, 940.3076923076923, 231.3846153846154,
          932.9230769230769, 231.3846153846154, 928, 233.84615384615384,
          925.5384615384615, 233.84615384615384, 923.0769230769231,
          235.07692307692307, 920.6153846153846, 235.07692307692307,
          913.2307692307693, 238.76923076923077, 910.7692307692307,
          238.76923076923077, 908.3076923076923, 240, 905.8461538461538, 240,
          903.3846153846154, 241.23076923076923, 898.4615384615385,
          241.23076923076923, 896, 242.46153846153845, 888.6153846153846,
          242.46153846153845, 886.1538461538462, 243.69230769230768,
          836.9230769230769, 243.69230769230768, 834.4615384615385,
          242.46153846153845, 829.5384615384615, 242.46153846153845,
          827.0769230769231, 243.69230769230768, 809.8461538461538,
          243.69230769230768, 807.3846153846154, 244.92307692307693, 800,
          244.92307692307693, 797.5384615384615, 243.69230769230768,
          777.8461538461538, 243.69230769230768, 775.3846153846154,
          242.46153846153845, 770.4615384615385, 242.46153846153845, 768,
          241.23076923076923, 760.6153846153846, 241.23076923076923,
          758.1538461538462, 240, 753.2307692307693, 240, 758.1538461538462,
          240, 760.6153846153846, 241.23076923076923, 768, 241.23076923076923,
          770.4615384615385, 242.46153846153845, 772.9230769230769,
          242.46153846153845, 775.3846153846154, 243.69230769230768,
          780.3076923076923, 243.69230769230768, 782.7692307692307,
          244.92307692307693, 795.0769230769231, 244.92307692307693,
          797.5384615384615, 246.15384615384616, 804.9230769230769,
          246.15384615384616, 807.3846153846154, 244.92307692307693,
          822.1538461538462, 244.92307692307693, 824.6153846153846,
          243.69230769230768, 849.2307692307693, 243.69230769230768,
          851.6923076923077, 244.92307692307693, 881.2307692307693,
          244.92307692307693, 883.6923076923077, 243.69230769230768,
          893.5384615384615, 243.69230769230768, 896, 242.46153846153845,
          900.9230769230769, 242.46153846153845, 903.3846153846154,
          241.23076923076923, 908.3076923076923, 241.23076923076923,
          910.7692307692307, 240, 913.2307692307693, 240, 915.6923076923077,
          238.76923076923077, 918.1538461538462, 238.76923076923077,
          920.6153846153846, 237.53846153846155, 923.0769230769231,
          237.53846153846155, 925.5384615384615, 236.30769230769232, 928,
          236.30769230769232, 932.9230769230769, 233.84615384615384,
          935.3846153846154, 233.84615384615384, 942.7692307692307,
          230.15384615384616, 947.6923076923077, 230.15384615384616,
          952.6153846153846, 227.69230769230768, 955.0769230769231,
          227.69230769230768, 957.5384615384615, 226.46153846153845,
          962.4615384615385, 226.46153846153845, 964.9230769230769,
          225.23076923076923, 967.3846153846154, 225.23076923076923,
          969.8461538461538, 224, 984.6153846153846, 224, 987.0769230769231,
          222.76923076923077, 994.4615384615385, 222.76923076923077,
          996.9230769230769, 221.53846153846155, 999.3846153846154,
          221.53846153846155, 1004.3076923076923, 219.07692307692307,
          1006.7692307692307, 219.07692307692307, 1009.2307692307693,
          217.84615384615384, 1011.6923076923077, 217.84615384615384,
          1014.1538461538462, 216.6153846153846, 1021.5384615384615,
          216.6153846153846,
        ],
        attributes: [],
        color: '#3EB2F3',
        name: 'External limiting membrane',
        order: 7,
      },
      {
        type: 'polygon',
        classId: 6,
        className: 'mz',
        probability: 0.7748588919639587,
        points: [
          507.0769230769231, 254.76923076923077, 504.61538461538464, 256,
          497.2307692307692, 256, 494.7692307692308, 257.2307692307692,
          489.84615384615387, 257.2307692307692, 489.84615384615387,
          258.46153846153845, 487.38461538461536, 259.6923076923077,
          484.9230769230769, 259.6923076923077, 494.7692307692308,
          259.6923076923077, 497.2307692307692, 258.46153846153845,
          504.61538461538464, 258.46153846153845, 507.0769230769231,
          257.2307692307692, 514.4615384615385, 257.2307692307692,
          516.9230769230769, 256, 519.3846153846154, 256, 521.8461538461538,
          254.76923076923077,
        ],
        attributes: [],
        color: '#95D708',
        name: 'Myoid zone of the photoreceptors',
        order: 8,
      },
      {
        type: 'polygon',
        classId: 6,
        className: 'mz',
        probability: 0.8407838940620422,
        points: [
          68.92307692307692, 242.46153846153845, 66.46153846153847,
          243.69230769230768, 64, 243.69230769230768, 61.53846153846154,
          244.92307692307693, 39.38461538461539, 244.92307692307693,
          41.84615384615385, 246.15384615384616, 66.46153846153847,
          246.15384615384616, 68.92307692307692, 244.92307692307693,
          76.3076923076923, 244.92307692307693, 78.76923076923077,
          243.69230769230768, 91.07692307692308, 243.69230769230768,
          93.53846153846153, 244.92307692307693, 115.6923076923077,
          244.92307692307693, 118.15384615384616, 246.15384615384616,
          130.46153846153845, 246.15384615384616, 132.92307692307693,
          247.3846153846154, 140.30769230769232, 247.3846153846154,
          142.76923076923077, 248.6153846153846, 150.15384615384616,
          248.6153846153846, 152.6153846153846, 249.84615384615384, 160,
          249.84615384615384, 162.46153846153845, 251.07692307692307,
          172.30769230769232, 251.07692307692307, 174.76923076923077,
          252.30769230769232, 177.23076923076923, 252.30769230769232,
          179.69230769230768, 253.53846153846155, 187.07692307692307,
          253.53846153846155, 189.53846153846155, 254.76923076923077,
          199.3846153846154, 254.76923076923077, 201.84615384615384, 256, 224,
          256, 226.46153846153845, 257.2307692307692, 236.30769230769232,
          257.2307692307692, 238.76923076923077, 258.46153846153845,
          246.15384615384616, 258.46153846153845, 248.6153846153846,
          259.6923076923077, 260.9230769230769, 259.6923076923077,
          263.38461538461536, 260.9230769230769, 275.6923076923077,
          260.9230769230769, 278.15384615384613, 262.15384615384613,
          290.46153846153845, 262.15384615384613, 292.9230769230769,
          263.38461538461536, 300.3076923076923, 263.38461538461536,
          302.7692307692308, 264.61538461538464, 310.15384615384613,
          264.61538461538464, 312.61538461538464, 265.84615384615387,
          354.46153846153845, 265.84615384615387, 356.9230769230769,
          267.0769230769231, 371.6923076923077, 267.0769230769231,
          374.15384615384613, 265.84615384615387, 408.61538461538464,
          265.84615384615387, 398.7692307692308, 265.84615384615387,
          396.3076923076923, 264.61538461538464, 393.84615384615387,
          265.84615384615387, 391.38461538461536, 264.61538461538464,
          381.53846153846155, 264.61538461538464, 379.0769230769231,
          265.84615384615387, 356.9230769230769, 265.84615384615387,
          354.46153846153845, 264.61538461538464, 315.0769230769231,
          264.61538461538464, 312.61538461538464, 263.38461538461536,
          302.7692307692308, 263.38461538461536, 300.3076923076923,
          262.15384615384613, 295.38461538461536, 262.15384615384613,
          292.9230769230769, 260.9230769230769, 283.0769230769231,
          260.9230769230769, 280.61538461538464, 259.6923076923077,
          270.7692307692308, 259.6923076923077, 268.3076923076923,
          258.46153846153845, 253.53846153846155, 258.46153846153845,
          251.07692307692307, 257.2307692307692, 243.69230769230768,
          257.2307692307692, 241.23076923076923, 256, 231.3846153846154, 256,
          228.92307692307693, 254.76923076923077, 206.76923076923077,
          254.76923076923077, 204.30769230769232, 253.53846153846155,
          194.46153846153845, 253.53846153846155, 192, 252.30769230769232,
          184.6153846153846, 252.30769230769232, 182.15384615384616,
          251.07692307692307, 174.76923076923077, 251.07692307692307,
          172.30769230769232, 249.84615384615384, 164.92307692307693,
          249.84615384615384, 162.46153846153845, 248.6153846153846,
          155.07692307692307, 248.6153846153846, 152.6153846153846,
          247.3846153846154, 145.23076923076923, 247.3846153846154,
          142.76923076923077, 246.15384615384616, 135.3846153846154,
          246.15384615384616, 132.92307692307693, 244.92307692307693,
          123.07692307692308, 244.92307692307693, 120.61538461538461,
          243.69230769230768, 105.84615384615384, 243.69230769230768,
          103.38461538461539, 242.46153846153845,
        ],
        attributes: [],
        color: '#95D708',
        name: 'Myoid zone of the photoreceptors',
        order: 8,
      },
      {
        type: 'polygon',
        classId: 6,
        className: 'mz',
        probability: 0.7961690425872803,
        points: [
          2.4615384615384617, 230.15384615384616, 2.4615384615384617,
          231.3846153846154, 4.923076923076923, 231.3846153846154,
          9.846153846153847, 233.84615384615384, 12.307692307692308,
          233.84615384615384, 17.23076923076923, 236.30769230769232,
          19.692307692307693, 236.30769230769232, 24.615384615384617,
          238.76923076923077, 27.076923076923077, 238.76923076923077, 32,
          241.23076923076923, 32, 240, 22.153846153846153, 235.07692307692307,
          19.692307692307693, 235.07692307692307, 14.76923076923077,
          232.6153846153846, 12.307692307692308, 232.6153846153846,
          9.846153846153847, 231.3846153846154, 7.384615384615385,
          231.3846153846154, 4.923076923076923, 230.15384615384616,
        ],
        attributes: [],
        color: '#95D708',
        name: 'Myoid zone of the photoreceptors',
        order: 8,
      },
      {
        type: 'polygon',
        classId: 6,
        className: 'mz',
        probability: 0.8537696599960327,
        points: [
          1016.6153846153846, 217.84615384615384, 1014.1538461538462,
          219.07692307692307, 1009.2307692307693, 219.07692307692307,
          1001.8461538461538, 222.76923076923077, 999.3846153846154,
          222.76923076923077, 996.9230769230769, 224, 992, 224,
          989.5384615384615, 225.23076923076923, 972.3076923076923,
          225.23076923076923, 969.8461538461538, 226.46153846153845,
          967.3846153846154, 226.46153846153845, 964.9230769230769,
          227.69230769230768, 960, 227.69230769230768, 957.5384615384615,
          228.92307692307693, 955.0769230769231, 228.92307692307693,
          950.1538461538462, 231.3846153846154, 947.6923076923077,
          231.3846153846154, 945.2307692307693, 232.6153846153846,
          942.7692307692307, 232.6153846153846, 937.8461538461538,
          235.07692307692307, 935.3846153846154, 235.07692307692307,
          930.4615384615385, 237.53846153846155, 925.5384615384615,
          237.53846153846155, 923.0769230769231, 238.76923076923077,
          920.6153846153846, 238.76923076923077, 918.1538461538462, 240,
          915.6923076923077, 240, 913.2307692307693, 241.23076923076923,
          910.7692307692307, 241.23076923076923, 908.3076923076923,
          242.46153846153845, 905.8461538461538, 242.46153846153845,
          903.3846153846154, 243.69230769230768, 898.4615384615385,
          243.69230769230768, 896, 244.92307692307693, 886.1538461538462,
          244.92307692307693, 883.6923076923077, 246.15384615384616,
          871.3846153846154, 246.15384615384616, 868.9230769230769,
          247.3846153846154, 864, 247.3846153846154, 861.5384615384615,
          246.15384615384616, 849.2307692307693, 246.15384615384616,
          846.7692307692307, 244.92307692307693, 827.0769230769231,
          244.92307692307693, 824.6153846153846, 246.15384615384616,
          814.7692307692307, 246.15384615384616, 812.3076923076923,
          247.3846153846154, 787.6923076923077, 247.3846153846154,
          785.2307692307693, 246.15384615384616, 777.8461538461538,
          246.15384615384616, 775.3846153846154, 244.92307692307693,
          772.9230769230769, 244.92307692307693, 770.4615384615385,
          243.69230769230768, 765.5384615384615, 243.69230769230768,
          763.0769230769231, 242.46153846153845, 753.2307692307693,
          242.46153846153845, 750.7692307692307, 241.23076923076923,
          743.3846153846154, 241.23076923076923, 743.3846153846154,
          242.46153846153845, 748.3076923076923, 242.46153846153845,
          750.7692307692307, 243.69230769230768, 758.1538461538462,
          243.69230769230768, 760.6153846153846, 244.92307692307693,
          770.4615384615385, 244.92307692307693, 772.9230769230769,
          246.15384615384616, 775.3846153846154, 246.15384615384616,
          777.8461538461538, 247.3846153846154, 780.3076923076923,
          247.3846153846154, 782.7692307692307, 248.6153846153846,
          814.7692307692307, 248.6153846153846, 817.2307692307693,
          247.3846153846154, 834.4615384615385, 247.3846153846154,
          836.9230769230769, 246.15384615384616, 841.8461538461538,
          246.15384615384616, 844.3076923076923, 247.3846153846154,
          859.0769230769231, 247.3846153846154, 861.5384615384615,
          248.6153846153846, 871.3846153846154, 248.6153846153846,
          873.8461538461538, 247.3846153846154, 888.6153846153846,
          247.3846153846154, 891.0769230769231, 246.15384615384616,
          898.4615384615385, 246.15384615384616, 900.9230769230769,
          244.92307692307693, 905.8461538461538, 244.92307692307693,
          908.3076923076923, 243.69230769230768, 913.2307692307693,
          243.69230769230768, 915.6923076923077, 242.46153846153845,
          918.1538461538462, 242.46153846153845, 920.6153846153846,
          241.23076923076923, 923.0769230769231, 241.23076923076923,
          925.5384615384615, 240, 928, 240, 930.4615384615385,
          238.76923076923077, 932.9230769230769, 238.76923076923077,
          935.3846153846154, 237.53846153846155, 940.3076923076923,
          237.53846153846155, 945.2307692307693, 235.07692307692307,
          947.6923076923077, 235.07692307692307, 952.6153846153846,
          232.6153846153846, 955.0769230769231, 232.6153846153846,
          957.5384615384615, 231.3846153846154, 960, 231.3846153846154,
          962.4615384615385, 230.15384615384616, 964.9230769230769,
          230.15384615384616, 967.3846153846154, 228.92307692307693,
          969.8461538461538, 228.92307692307693, 972.3076923076923,
          227.69230769230768, 974.7692307692307, 227.69230769230768,
          977.2307692307693, 226.46153846153845, 992, 226.46153846153845,
          994.4615384615385, 225.23076923076923, 999.3846153846154,
          225.23076923076923, 1001.8461538461538, 224, 1004.3076923076923, 224,
          1009.2307692307693, 221.53846153846155, 1011.6923076923077,
          221.53846153846155, 1014.1538461538462, 220.30769230769232,
          1016.6153846153846, 220.30769230769232, 1019.0769230769231,
          219.07692307692307, 1019.0769230769231, 217.84615384615384,
        ],
        attributes: [],
        color: '#95D708',
        name: 'Myoid zone of the photoreceptors',
        order: 8,
      },
      {
        type: 'polygon',
        classId: 2,
        className: 'ez',
        probability: 0.771297037601471,
        points: [
          516.9230769230769, 258.46153846153845, 514.4615384615385,
          259.6923076923077, 502.15384615384613, 259.6923076923077,
          499.6923076923077, 260.9230769230769, 489.84615384615387,
          260.9230769230769, 487.38461538461536, 262.15384615384613,
          484.9230769230769, 262.15384615384613, 502.15384615384613,
          262.15384615384613, 504.61538461538464, 260.9230769230769,
          516.9230769230769, 260.9230769230769, 519.3846153846154,
          259.6923076923077, 521.8461538461538, 259.6923076923077,
          521.8461538461538, 258.46153846153845,
        ],
        attributes: [],
        color: '#8094FF',
        name: 'Ellipsoid zone of the photoreceptors',
        order: 9,
      },
      {
        type: 'polygon',
        classId: 2,
        className: 'ez',
        probability: 0.8339828848838806,
        points: [
          78.76923076923077, 244.92307692307693, 76.3076923076923,
          246.15384615384616, 68.92307692307692, 246.15384615384616,
          66.46153846153847, 247.3846153846154, 64, 247.3846153846154,
          61.53846153846154, 248.6153846153846, 54.15384615384615,
          248.6153846153846, 64, 248.6153846153846, 66.46153846153847,
          247.3846153846154, 76.3076923076923, 247.3846153846154,
          78.76923076923077, 246.15384615384616, 86.15384615384616,
          246.15384615384616, 88.61538461538461, 247.3846153846154,
          113.23076923076923, 247.3846153846154, 115.6923076923077,
          248.6153846153846, 128, 248.6153846153846, 130.46153846153845,
          249.84615384615384, 137.84615384615384, 249.84615384615384,
          140.30769230769232, 251.07692307692307, 147.69230769230768,
          251.07692307692307, 150.15384615384616, 252.30769230769232,
          157.53846153846155, 252.30769230769232, 160, 253.53846153846155,
          167.3846153846154, 253.53846153846155, 169.84615384615384,
          254.76923076923077, 177.23076923076923, 254.76923076923077,
          179.69230769230768, 256, 184.6153846153846, 256, 187.07692307692307,
          257.2307692307692, 196.92307692307693, 257.2307692307692,
          199.3846153846154, 258.46153846153845, 221.53846153846155,
          258.46153846153845, 224, 259.6923076923077, 233.84615384615384,
          259.6923076923077, 236.30769230769232, 260.9230769230769,
          241.23076923076923, 260.9230769230769, 243.69230769230768,
          262.15384615384613, 253.53846153846155, 262.15384615384613, 256,
          263.38461538461536, 275.6923076923077, 263.38461538461536,
          278.15384615384613, 264.61538461538464, 290.46153846153845,
          264.61538461538464, 292.9230769230769, 265.84615384615387,
          302.7692307692308, 265.84615384615387, 305.2307692307692,
          267.0769230769231, 312.61538461538464, 267.0769230769231,
          315.0769230769231, 268.3076923076923, 329.84615384615387,
          268.3076923076923, 332.3076923076923, 267.0769230769231,
          344.61538461538464, 267.0769230769231, 347.0769230769231,
          268.3076923076923, 352, 268.3076923076923, 349.53846153846155,
          267.0769230769231, 312.61538461538464, 267.0769230769231,
          310.15384615384613, 265.84615384615387, 302.7692307692308,
          265.84615384615387, 300.3076923076923, 264.61538461538464,
          292.9230769230769, 264.61538461538464, 290.46153846153845,
          263.38461538461536, 278.15384615384613, 263.38461538461536,
          275.6923076923077, 262.15384615384613, 263.38461538461536,
          262.15384615384613, 260.9230769230769, 260.9230769230769,
          246.15384615384616, 260.9230769230769, 243.69230769230768,
          259.6923076923077, 238.76923076923077, 259.6923076923077,
          236.30769230769232, 258.46153846153845, 226.46153846153845,
          258.46153846153845, 224, 257.2307692307692, 201.84615384615384,
          257.2307692307692, 199.3846153846154, 256, 189.53846153846155, 256,
          187.07692307692307, 254.76923076923077, 179.69230769230768,
          254.76923076923077, 177.23076923076923, 253.53846153846155,
          172.30769230769232, 253.53846153846155, 169.84615384615384,
          252.30769230769232, 162.46153846153845, 252.30769230769232, 160,
          251.07692307692307, 150.15384615384616, 251.07692307692307,
          147.69230769230768, 249.84615384615384, 142.76923076923077,
          249.84615384615384, 140.30769230769232, 248.6153846153846,
          132.92307692307693, 248.6153846153846, 130.46153846153845,
          247.3846153846154, 118.15384615384616, 247.3846153846154,
          115.6923076923077, 246.15384615384616, 93.53846153846153,
          246.15384615384616, 91.07692307692308, 244.92307692307693,
        ],
        attributes: [],
        color: '#8094FF',
        name: 'Ellipsoid zone of the photoreceptors',
        order: 9,
      },
      {
        type: 'polygon',
        classId: 2,
        className: 'ez',
        probability: 0.8077226877212524,
        points: [
          2.4615384615384617, 233.84615384615384, 9.846153846153847,
          237.53846153846155, 12.307692307692308, 237.53846153846155,
          14.76923076923077, 238.76923076923077, 17.23076923076923,
          238.76923076923077, 19.692307692307693, 240, 24.615384615384617, 240,
          27.076923076923077, 241.23076923076923, 19.692307692307693,
          237.53846153846155, 17.23076923076923, 237.53846153846155,
          14.76923076923077, 236.30769230769232, 12.307692307692308,
          236.30769230769232, 9.846153846153847, 235.07692307692307,
          7.384615384615385, 235.07692307692307, 4.923076923076923,
          233.84615384615384,
        ],
        attributes: [],
        color: '#8094FF',
        name: 'Ellipsoid zone of the photoreceptors',
        order: 9,
      },
      {
        type: 'polygon',
        classId: 2,
        className: 'ez',
        probability: 0.8457667827606201,
        points: [
          1019.0769230769231, 220.30769230769232, 1016.6153846153846,
          221.53846153846155, 1014.1538461538462, 221.53846153846155,
          1011.6923076923077, 222.76923076923077, 1009.2307692307693,
          222.76923076923077, 1004.3076923076923, 225.23076923076923,
          1001.8461538461538, 225.23076923076923, 999.3846153846154,
          226.46153846153845, 996.9230769230769, 226.46153846153845,
          994.4615384615385, 227.69230769230768, 977.2307692307693,
          227.69230769230768, 974.7692307692307, 228.92307692307693,
          972.3076923076923, 228.92307692307693, 969.8461538461538,
          230.15384615384616, 967.3846153846154, 230.15384615384616,
          964.9230769230769, 231.3846153846154, 962.4615384615385,
          231.3846153846154, 960, 232.6153846153846, 957.5384615384615,
          232.6153846153846, 952.6153846153846, 235.07692307692307,
          950.1538461538462, 235.07692307692307, 947.6923076923077,
          236.30769230769232, 945.2307692307693, 236.30769230769232,
          940.3076923076923, 238.76923076923077, 937.8461538461538,
          238.76923076923077, 935.3846153846154, 240, 930.4615384615385, 240,
          928, 241.23076923076923, 925.5384615384615, 241.23076923076923,
          923.0769230769231, 242.46153846153845, 920.6153846153846,
          242.46153846153845, 918.1538461538462, 243.69230769230768,
          915.6923076923077, 243.69230769230768, 913.2307692307693,
          244.92307692307693, 908.3076923076923, 244.92307692307693,
          905.8461538461538, 246.15384615384616, 900.9230769230769,
          246.15384615384616, 898.4615384615385, 247.3846153846154,
          891.0769230769231, 247.3846153846154, 888.6153846153846,
          248.6153846153846, 873.8461538461538, 248.6153846153846,
          871.3846153846154, 249.84615384615384, 859.0769230769231,
          249.84615384615384, 856.6153846153846, 248.6153846153846,
          841.8461538461538, 248.6153846153846, 839.3846153846154,
          247.3846153846154, 836.9230769230769, 247.3846153846154,
          834.4615384615385, 248.6153846153846, 819.6923076923077,
          248.6153846153846, 817.2307692307693, 249.84615384615384,
          782.7692307692307, 249.84615384615384, 780.3076923076923,
          248.6153846153846, 775.3846153846154, 248.6153846153846,
          772.9230769230769, 247.3846153846154, 770.4615384615385,
          247.3846153846154, 768, 246.15384615384616, 758.1538461538462,
          246.15384615384616, 755.6923076923077, 244.92307692307693,
          745.8461538461538, 244.92307692307693, 748.3076923076923,
          246.15384615384616, 753.2307692307693, 246.15384615384616,
          755.6923076923077, 247.3846153846154, 768, 247.3846153846154,
          770.4615384615385, 248.6153846153846, 775.3846153846154,
          248.6153846153846, 777.8461538461538, 249.84615384615384,
          787.6923076923077, 249.84615384615384, 790.1538461538462,
          251.07692307692307, 802.4615384615385, 251.07692307692307,
          804.9230769230769, 249.84615384615384, 829.5384615384615,
          249.84615384615384, 832, 248.6153846153846, 844.3076923076923,
          248.6153846153846, 846.7692307692307, 249.84615384615384, 864,
          249.84615384615384, 866.4615384615385, 251.07692307692307,
          868.9230769230769, 251.07692307692307, 871.3846153846154,
          249.84615384615384, 888.6153846153846, 249.84615384615384,
          891.0769230769231, 248.6153846153846, 898.4615384615385,
          248.6153846153846, 900.9230769230769, 247.3846153846154,
          908.3076923076923, 247.3846153846154, 910.7692307692307,
          246.15384615384616, 913.2307692307693, 246.15384615384616,
          915.6923076923077, 244.92307692307693, 918.1538461538462,
          244.92307692307693, 920.6153846153846, 243.69230769230768,
          923.0769230769231, 243.69230769230768, 925.5384615384615,
          242.46153846153845, 930.4615384615385, 242.46153846153845,
          932.9230769230769, 241.23076923076923, 935.3846153846154,
          241.23076923076923, 937.8461538461538, 240, 940.3076923076923, 240,
          942.7692307692307, 238.76923076923077, 945.2307692307693,
          238.76923076923077, 947.6923076923077, 237.53846153846155,
          950.1538461538462, 237.53846153846155, 955.0769230769231,
          235.07692307692307, 957.5384615384615, 235.07692307692307, 960,
          233.84615384615384, 964.9230769230769, 233.84615384615384,
          967.3846153846154, 232.6153846153846, 969.8461538461538,
          232.6153846153846, 974.7692307692307, 230.15384615384616,
          977.2307692307693, 230.15384615384616, 979.6923076923077,
          228.92307692307693, 992, 228.92307692307693, 994.4615384615385,
          227.69230769230768, 999.3846153846154, 227.69230769230768,
          1001.8461538461538, 226.46153846153845, 1004.3076923076923,
          226.46153846153845, 1009.2307692307693, 224, 1011.6923076923077, 224,
          1014.1538461538462, 222.76923076923077, 1016.6153846153846,
          222.76923076923077, 1019.0769230769231, 221.53846153846155,
        ],
        attributes: [],
        color: '#8094FF',
        name: 'Ellipsoid zone of the photoreceptors',
        order: 9,
      },
      {
        type: 'polygon',
        classId: 9,
        className: 'os',
        probability: 0.7202149033546448,
        points: [
          507.0769230769231, 263.38461538461536, 514.4615384615385,
          263.38461538461536,
        ],
        attributes: [],
        color: '#5E35B1',
        name: 'Outer segments of the photoreceptors',
        order: 10,
      },
      {
        type: 'polygon',
        classId: 9,
        className: 'os',
        probability: 0.719786524772644,
        points: [
          836.9230769230769, 249.84615384615384, 841.8461538461538,
          249.84615384615384,
        ],
        attributes: [],
        color: '#5E35B1',
        name: 'Outer segments of the photoreceptors',
        order: 10,
      },
      {
        type: 'polygon',
        classId: 9,
        className: 'os',
        probability: 0.7495114207267761,
        points: [
          748.3076923076923, 248.6153846153846, 750.7692307692307,
          248.6153846153846, 753.2307692307693, 249.84615384615384, 768,
          249.84615384615384, 770.4615384615385, 251.07692307692307,
          777.8461538461538, 251.07692307692307, 775.3846153846154,
          251.07692307692307, 772.9230769230769, 249.84615384615384,
          765.5384615384615, 249.84615384615384, 763.0769230769231,
          248.6153846153846,
        ],
        attributes: [],
        color: '#5E35B1',
        name: 'Outer segments of the photoreceptors',
        order: 10,
      },
      {
        type: 'polygon',
        classId: 9,
        className: 'os',
        probability: 0.7988983988761902,
        points: [
          73.84615384615384, 248.6153846153846, 98.46153846153847,
          248.6153846153846, 100.92307692307692, 249.84615384615384,
          115.6923076923077, 249.84615384615384, 118.15384615384616,
          251.07692307692307, 130.46153846153845, 251.07692307692307,
          132.92307692307693, 252.30769230769232, 140.30769230769232,
          252.30769230769232, 142.76923076923077, 253.53846153846155,
          147.69230769230768, 253.53846153846155, 150.15384615384616,
          254.76923076923077, 160, 254.76923076923077, 162.46153846153845, 256,
          169.84615384615384, 256, 172.30769230769232, 257.2307692307692,
          177.23076923076923, 257.2307692307692, 179.69230769230768,
          258.46153846153845, 184.6153846153846, 258.46153846153845,
          187.07692307692307, 259.6923076923077, 194.46153846153845,
          259.6923076923077, 196.92307692307693, 260.9230769230769,
          221.53846153846155, 260.9230769230769, 224, 262.15384615384613,
          233.84615384615384, 262.15384615384613, 236.30769230769232,
          263.38461538461536, 241.23076923076923, 263.38461538461536,
          243.69230769230768, 264.61538461538464, 263.38461538461536,
          264.61538461538464, 265.84615384615387, 265.84615384615387,
          278.15384615384613, 265.84615384615387, 280.61538461538464,
          267.0769230769231, 297.84615384615387, 267.0769230769231,
          300.3076923076923, 268.3076923076923, 307.6923076923077,
          268.3076923076923, 310.15384615384613, 269.53846153846155,
          339.6923076923077, 269.53846153846155, 310.15384615384613,
          269.53846153846155, 307.6923076923077, 268.3076923076923,
          302.7692307692308, 268.3076923076923, 300.3076923076923,
          267.0769230769231, 292.9230769230769, 267.0769230769231,
          290.46153846153845, 265.84615384615387, 275.6923076923077,
          265.84615384615387, 273.2307692307692, 264.61538461538464,
          248.6153846153846, 264.61538461538464, 246.15384615384616,
          263.38461538461536, 241.23076923076923, 263.38461538461536,
          238.76923076923077, 262.15384615384613, 233.84615384615384,
          262.15384615384613, 231.3846153846154, 260.9230769230769,
          221.53846153846155, 260.9230769230769, 219.07692307692307,
          259.6923076923077, 196.92307692307693, 259.6923076923077,
          194.46153846153845, 258.46153846153845, 187.07692307692307,
          258.46153846153845, 184.6153846153846, 257.2307692307692,
          179.69230769230768, 257.2307692307692, 177.23076923076923, 256,
          169.84615384615384, 256, 167.3846153846154, 254.76923076923077, 160,
          254.76923076923077, 157.53846153846155, 253.53846153846155,
          147.69230769230768, 253.53846153846155, 145.23076923076923,
          252.30769230769232, 140.30769230769232, 252.30769230769232,
          137.84615384615384, 251.07692307692307, 130.46153846153845,
          251.07692307692307, 128, 249.84615384615384, 113.23076923076923,
          249.84615384615384, 110.76923076923077, 248.6153846153846,
        ],
        attributes: [],
        color: '#5E35B1',
        name: 'Outer segments of the photoreceptors',
        order: 10,
      },
      {
        type: 'polygon',
        classId: 9,
        className: 'os',
        probability: 0.7536161541938782,
        points: [
          12.307692307692308, 240, 14.76923076923077, 241.23076923076923,
          17.23076923076923, 241.23076923076923, 19.692307692307693,
          242.46153846153845, 24.615384615384617, 242.46153846153845,
          22.153846153846153, 241.23076923076923, 17.23076923076923,
          241.23076923076923, 14.76923076923077, 240,
        ],
        attributes: [],
        color: '#5E35B1',
        name: 'Outer segments of the photoreceptors',
        order: 10,
      },
      {
        type: 'polygon',
        classId: 9,
        className: 'os',
        probability: 0.7979838848114014,
        points: [
          996.9230769230769, 228.92307692307693, 994.4615384615385,
          230.15384615384616, 982.1538461538462, 230.15384615384616,
          979.6923076923077, 231.3846153846154, 974.7692307692307,
          231.3846153846154, 969.8461538461538, 233.84615384615384,
          967.3846153846154, 233.84615384615384, 964.9230769230769,
          235.07692307692307, 962.4615384615385, 235.07692307692307, 960,
          236.30769230769232, 955.0769230769231, 236.30769230769232,
          950.1538461538462, 238.76923076923077, 947.6923076923077,
          238.76923076923077, 945.2307692307693, 240, 942.7692307692307, 240,
          940.3076923076923, 241.23076923076923, 937.8461538461538,
          241.23076923076923, 935.3846153846154, 242.46153846153845,
          932.9230769230769, 242.46153846153845, 930.4615384615385,
          243.69230769230768, 925.5384615384615, 243.69230769230768,
          923.0769230769231, 244.92307692307693, 920.6153846153846,
          244.92307692307693, 918.1538461538462, 246.15384615384616,
          915.6923076923077, 246.15384615384616, 913.2307692307693,
          247.3846153846154, 910.7692307692307, 247.3846153846154,
          908.3076923076923, 248.6153846153846, 900.9230769230769,
          248.6153846153846, 898.4615384615385, 249.84615384615384,
          891.0769230769231, 249.84615384615384, 888.6153846153846,
          251.07692307692307, 873.8461538461538, 251.07692307692307,
          871.3846153846154, 252.30769230769232, 861.5384615384615,
          252.30769230769232, 859.0769230769231, 251.07692307692307,
          846.7692307692307, 251.07692307692307, 856.6153846153846,
          251.07692307692307, 859.0769230769231, 252.30769230769232,
          878.7692307692307, 252.30769230769232, 881.2307692307693,
          251.07692307692307, 891.0769230769231, 251.07692307692307,
          893.5384615384615, 249.84615384615384, 900.9230769230769,
          249.84615384615384, 903.3846153846154, 248.6153846153846,
          910.7692307692307, 248.6153846153846, 913.2307692307693,
          247.3846153846154, 915.6923076923077, 247.3846153846154,
          918.1538461538462, 246.15384615384616, 920.6153846153846,
          246.15384615384616, 923.0769230769231, 244.92307692307693,
          925.5384615384615, 244.92307692307693, 928, 243.69230769230768,
          932.9230769230769, 243.69230769230768, 935.3846153846154,
          242.46153846153845, 937.8461538461538, 242.46153846153845,
          940.3076923076923, 241.23076923076923, 942.7692307692307,
          241.23076923076923, 945.2307692307693, 240, 947.6923076923077, 240,
          950.1538461538462, 238.76923076923077, 952.6153846153846,
          238.76923076923077, 955.0769230769231, 237.53846153846155, 960,
          237.53846153846155, 962.4615384615385, 236.30769230769232,
          964.9230769230769, 236.30769230769232, 967.3846153846154,
          235.07692307692307, 969.8461538461538, 235.07692307692307,
          974.7692307692307, 232.6153846153846, 977.2307692307693,
          232.6153846153846, 979.6923076923077, 231.3846153846154,
          987.0769230769231, 231.3846153846154, 989.5384615384615,
          230.15384615384616, 994.4615384615385, 230.15384615384616,
          996.9230769230769, 228.92307692307693, 999.3846153846154,
          228.92307692307693,
        ],
        attributes: [],
        color: '#5E35B1',
        name: 'Outer segments of the photoreceptors',
        order: 10,
      },
      {
        type: 'polygon',
        classId: 9,
        className: 'os',
        probability: 0.7629897594451904,
        points: [
          1014.1538461538462, 224, 1009.2307692307693, 226.46153846153845,
          1011.6923076923077, 225.23076923076923, 1021.5384615384615,
          225.23076923076923, 1021.5384615384615, 224,
        ],
        attributes: [],
        color: '#5E35B1',
        name: 'Outer segments of the photoreceptors',
        order: 10,
      },
      {
        type: 'polygon',
        classId: 12,
        className: 'rpe',
        probability: 0.7623454928398132,
        points: [
          425.84615384615387, 288, 423.38461538461536, 289.2307692307692, 416,
          289.2307692307692, 413.53846153846155, 290.46153846153845,
          408.61538461538464, 290.46153846153845, 406.15384615384613,
          291.6923076923077, 403.6923076923077, 291.6923076923077,
          401.2307692307692, 292.9230769230769, 393.84615384615387,
          292.9230769230769, 391.38461538461536, 291.6923076923077,
          369.2307692307692, 291.6923076923077, 366.7692307692308,
          290.46153846153845, 361.84615384615387, 290.46153846153845,
          361.84615384615387, 291.6923076923077, 371.6923076923077,
          296.61538461538464, 374.15384615384613, 296.61538461538464,
          376.61538461538464, 297.84615384615387, 391.38461538461536,
          297.84615384615387, 393.84615384615387, 296.61538461538464,
          396.3076923076923, 296.61538461538464, 398.7692307692308,
          295.38461538461536, 408.61538461538464, 295.38461538461536,
          411.0769230769231, 294.15384615384613, 418.46153846153845,
          294.15384615384613, 420.9230769230769, 292.9230769230769,
          465.2307692307692, 292.9230769230769, 467.6923076923077,
          294.15384615384613, 470.15384615384613, 294.15384615384613,
          475.0769230769231, 296.61538461538464, 484.9230769230769,
          296.61538461538464, 487.38461538461536, 297.84615384615387,
          489.84615384615387, 297.84615384615387, 492.3076923076923,
          299.0769230769231, 497.2307692307692, 299.0769230769231,
          499.6923076923077, 300.3076923076923, 502.15384615384613,
          300.3076923076923, 504.61538461538464, 301.53846153846155,
          504.61538461538464, 302.7692307692308, 516.9230769230769,
          302.7692307692308, 519.3846153846154, 304, 524.3076923076923, 304,
          524.3076923076923, 299.0769230769231, 519.3846153846154,
          296.61538461538464, 507.0769230769231, 296.61538461538464,
          504.61538461538464, 295.38461538461536, 502.15384615384613,
          295.38461538461536, 499.6923076923077, 294.15384615384613,
          497.2307692307692, 294.15384615384613, 494.7692307692308,
          292.9230769230769, 484.9230769230769, 292.9230769230769,
          482.46153846153845, 291.6923076923077, 472.61538461538464,
          291.6923076923077, 470.15384615384613, 290.46153846153845,
          465.2307692307692, 290.46153846153845, 462.7692307692308,
          289.2307692307692, 450.46153846153845, 289.2307692307692, 448, 288,
        ],
        attributes: [],
        color: '#5EDAEB',
        name: 'Retinal pigment epithelium + Bruch`s membrane',
        order: 11,
      },
      {
        type: 'polygon',
        classId: 12,
        className: 'rpe',
        probability: 0.7171130776405334,
        points: [241.23076923076923, 285.53846153846155],
        attributes: [],
        color: '#5EDAEB',
        name: 'Retinal pigment epithelium + Bruch`s membrane',
        order: 11,
      },
      {
        type: 'polygon',
        classId: 12,
        className: 'rpe',
        probability: 0.7338308095932007,
        points: [
          7.384615384615385, 270.7692307692308, 4.923076923076923, 272,
          7.384615384615385, 272,
        ],
        attributes: [],
        color: '#5EDAEB',
        name: 'Retinal pigment epithelium + Bruch`s membrane',
        order: 11,
      },
      {
        type: 'polygon',
        classId: 12,
        className: 'rpe',
        probability: 0.7798863649368286,
        points: [
          561.2307692307693, 256, 558.7692307692307, 257.2307692307692, 544,
          257.2307692307692, 541.5384615384615, 258.46153846153845,
          534.1538461538462, 258.46153846153845, 534.1538461538462,
          259.6923076923077, 531.6923076923077, 260.9230769230769,
          529.2307692307693, 260.9230769230769, 526.7692307692307,
          262.15384615384613, 524.3076923076923, 262.15384615384613,
          521.8461538461538, 263.38461538461536, 519.3846153846154,
          263.38461538461536, 516.9230769230769, 264.61538461538464,
          514.4615384615385, 264.61538461538464, 509.53846153846155,
          267.0769230769231, 509.53846153846155, 268.3076923076923, 512,
          269.53846153846155, 512, 270.7692307692308, 516.9230769230769,
          270.7692307692308, 519.3846153846154, 272, 546.4615384615385, 272,
          548.9230769230769, 270.7692307692308, 556.3076923076923,
          270.7692307692308, 558.7692307692307, 269.53846153846155,
          563.6923076923077, 269.53846153846155, 566.1538461538462,
          268.3076923076923, 568.6153846153846, 268.3076923076923,
          571.0769230769231, 267.0769230769231, 573.5384615384615,
          267.0769230769231, 573.5384615384615, 263.38461538461536,
          571.0769230769231, 262.15384615384613, 571.0769230769231,
          257.2307692307692, 568.6153846153846, 256,
        ],
        attributes: [],
        color: '#5EDAEB',
        name: 'Retinal pigment epithelium + Bruch`s membrane',
        order: 11,
      },
      {
        type: 'polygon',
        classId: 12,
        className: 'rpe',
        probability: 0.7205615043640137,
        points: [
          551.3846153846154, 258.46153846153845, 553.8461538461538,
          257.2307692307692, 556.3076923076923, 257.2307692307692,
          558.7692307692307, 258.46153846153845, 556.3076923076923,
          259.6923076923077, 553.8461538461538, 259.6923076923077,
        ],
        attributes: [],
        color: '#5EDAEB',
        name: 'Retinal pigment epithelium + Bruch`s membrane',
        order: 11,
      },
      {
        type: 'polygon',
        classId: 12,
        className: 'rpe',
        probability: 0.8243312239646912,
        points: [
          2.4615384615384617, 238.76923076923077, 2.4615384615384617,
          241.23076923076923, 0, 242.46153846153845, 0, 249.84615384615384,
          7.384615384615385, 253.53846153846155, 9.846153846153847,
          253.53846153846155, 12.307692307692308, 252.30769230769232, 32,
          252.30769230769232, 34.46153846153846, 253.53846153846155,
          41.84615384615385, 253.53846153846155, 44.30769230769231,
          254.76923076923077, 49.23076923076923, 254.76923076923077,
          51.69230769230769, 256, 56.61538461538461, 256, 59.07692307692308,
          257.2307692307692, 88.61538461538461, 257.2307692307692,
          91.07692307692308, 258.46153846153845, 103.38461538461539,
          258.46153846153845, 105.84615384615384, 259.6923076923077,
          118.15384615384616, 259.6923076923077, 120.61538461538461,
          260.9230769230769, 132.92307692307693, 260.9230769230769,
          135.3846153846154, 262.15384615384613, 147.69230769230768,
          262.15384615384613, 150.15384615384616, 263.38461538461536,
          157.53846153846155, 263.38461538461536, 160, 264.61538461538464,
          164.92307692307693, 264.61538461538464, 167.3846153846154,
          265.84615384615387, 172.30769230769232, 265.84615384615387,
          174.76923076923077, 267.0769230769231, 182.15384615384616,
          267.0769230769231, 184.6153846153846, 268.3076923076923, 192,
          268.3076923076923, 194.46153846153845, 269.53846153846155,
          209.23076923076923, 269.53846153846155, 211.69230769230768,
          270.7692307692308, 221.53846153846155, 270.7692307692308, 224, 272,
          236.30769230769232, 272, 238.76923076923077, 273.2307692307692,
          241.23076923076923, 273.2307692307692, 243.69230769230768,
          274.46153846153845, 248.6153846153846, 274.46153846153845,
          251.07692307692307, 275.6923076923077, 253.53846153846155,
          275.6923076923077, 256, 274.46153846153845, 260.9230769230769,
          274.46153846153845, 263.38461538461536, 273.2307692307692,
          290.46153846153845, 273.2307692307692, 292.9230769230769,
          274.46153846153845, 322.46153846153845, 274.46153846153845,
          324.9230769230769, 275.6923076923077, 342.15384615384613,
          275.6923076923077, 344.61538461538464, 274.46153846153845, 352,
          274.46153846153845, 354.46153846153845, 273.2307692307692,
          374.15384615384613, 273.2307692307692, 376.61538461538464,
          274.46153846153845, 398.7692307692308, 274.46153846153845,
          401.2307692307692, 273.2307692307692, 398.7692307692308, 272,
          369.2307692307692, 272, 366.7692307692308, 273.2307692307692,
          364.3076923076923, 272, 359.38461538461536, 272, 356.9230769230769,
          270.7692307692308, 307.6923076923077, 270.7692307692308,
          305.2307692307692, 269.53846153846155, 297.84615384615387,
          269.53846153846155, 295.38461538461536, 268.3076923076923,
          280.61538461538464, 268.3076923076923, 278.15384615384613,
          267.0769230769231, 251.07692307692307, 267.0769230769231,
          248.6153846153846, 265.84615384615387, 241.23076923076923,
          265.84615384615387, 238.76923076923077, 264.61538461538464,
          233.84615384615384, 264.61538461538464, 231.3846153846154,
          263.38461538461536, 224, 263.38461538461536, 221.53846153846155,
          262.15384615384613, 196.92307692307693, 262.15384615384613,
          194.46153846153845, 260.9230769230769, 187.07692307692307,
          260.9230769230769, 184.6153846153846, 259.6923076923077,
          179.69230769230768, 259.6923076923077, 177.23076923076923,
          258.46153846153845, 169.84615384615384, 258.46153846153845,
          167.3846153846154, 257.2307692307692, 160, 257.2307692307692,
          157.53846153846155, 256, 150.15384615384616, 256, 147.69230769230768,
          254.76923076923077, 142.76923076923077, 254.76923076923077,
          140.30769230769232, 253.53846153846155, 132.92307692307693,
          253.53846153846155, 130.46153846153845, 252.30769230769232,
          118.15384615384616, 252.30769230769232, 115.6923076923077,
          251.07692307692307, 98.46153846153847, 251.07692307692307, 96,
          249.84615384615384, 71.38461538461539, 249.84615384615384,
          68.92307692307692, 251.07692307692307, 56.61538461538461,
          251.07692307692307, 54.15384615384615, 249.84615384615384,
          49.23076923076923, 249.84615384615384, 46.76923076923077,
          248.6153846153846, 41.84615384615385, 248.6153846153846,
          39.38461538461539, 247.3846153846154, 36.92307692307692,
          247.3846153846154, 34.46153846153846, 246.15384615384616, 32,
          246.15384615384616, 29.53846153846154, 244.92307692307693,
          27.076923076923077, 244.92307692307693, 24.615384615384617,
          243.69230769230768, 19.692307692307693, 243.69230769230768,
          17.23076923076923, 242.46153846153845, 14.76923076923077,
          242.46153846153845, 12.307692307692308, 241.23076923076923,
          9.846153846153847, 241.23076923076923, 4.923076923076923,
          238.76923076923077,
        ],
        attributes: [],
        color: '#5EDAEB',
        name: 'Retinal pigment epithelium + Bruch`s membrane',
        order: 11,
      },
      {
        type: 'polygon',
        classId: 12,
        className: 'rpe',
        probability: 0.8375011086463928,
        points: [
          1014.1538461538462, 226.46153846153845, 1011.6923076923077,
          227.69230769230768, 1006.7692307692307, 227.69230769230768,
          1004.3076923076923, 228.92307692307693, 1001.8461538461538,
          228.92307692307693, 999.3846153846154, 230.15384615384616,
          996.9230769230769, 230.15384615384616, 994.4615384615385,
          231.3846153846154, 989.5384615384615, 231.3846153846154,
          987.0769230769231, 232.6153846153846, 979.6923076923077,
          232.6153846153846, 977.2307692307693, 233.84615384615384,
          974.7692307692307, 233.84615384615384, 969.8461538461538,
          236.30769230769232, 967.3846153846154, 236.30769230769232,
          964.9230769230769, 237.53846153846155, 962.4615384615385,
          237.53846153846155, 960, 238.76923076923077, 955.0769230769231,
          238.76923076923077, 952.6153846153846, 240, 950.1538461538462, 240,
          947.6923076923077, 241.23076923076923, 945.2307692307693,
          241.23076923076923, 942.7692307692307, 242.46153846153845,
          940.3076923076923, 242.46153846153845, 937.8461538461538,
          243.69230769230768, 935.3846153846154, 243.69230769230768,
          932.9230769230769, 244.92307692307693, 928, 244.92307692307693,
          925.5384615384615, 246.15384615384616, 923.0769230769231,
          246.15384615384616, 920.6153846153846, 247.3846153846154,
          918.1538461538462, 247.3846153846154, 915.6923076923077,
          248.6153846153846, 913.2307692307693, 248.6153846153846,
          910.7692307692307, 249.84615384615384, 905.8461538461538,
          249.84615384615384, 903.3846153846154, 251.07692307692307,
          893.5384615384615, 251.07692307692307, 891.0769230769231,
          252.30769230769232, 881.2307692307693, 252.30769230769232,
          878.7692307692307, 253.53846153846155, 859.0769230769231,
          253.53846153846155, 856.6153846153846, 252.30769230769232,
          844.3076923076923, 252.30769230769232, 841.8461538461538,
          251.07692307692307, 832, 251.07692307692307, 829.5384615384615,
          252.30769230769232, 804.9230769230769, 252.30769230769232,
          802.4615384615385, 253.53846153846155, 787.6923076923077,
          253.53846153846155, 785.2307692307693, 252.30769230769232,
          765.5384615384615, 252.30769230769232, 763.0769230769231,
          251.07692307692307, 758.1538461538462, 251.07692307692307,
          758.1538461538462, 252.30769230769232, 760.6153846153846,
          252.30769230769232, 765.5384615384615, 254.76923076923077, 768,
          254.76923076923077, 770.4615384615385, 256, 775.3846153846154, 256,
          777.8461538461538, 257.2307692307692, 790.1538461538462,
          257.2307692307692, 792.6153846153846, 258.46153846153845,
          812.3076923076923, 258.46153846153845, 814.7692307692307,
          257.2307692307692, 839.3846153846154, 257.2307692307692,
          841.8461538461538, 258.46153846153845, 854.1538461538462,
          258.46153846153845, 856.6153846153846, 259.6923076923077,
          859.0769230769231, 259.6923076923077, 861.5384615384615,
          260.9230769230769, 873.8461538461538, 260.9230769230769,
          876.3076923076923, 259.6923076923077, 883.6923076923077,
          259.6923076923077, 886.1538461538462, 258.46153846153845,
          888.6153846153846, 258.46153846153845, 891.0769230769231,
          257.2307692307692, 898.4615384615385, 257.2307692307692,
          900.9230769230769, 256, 908.3076923076923, 256, 910.7692307692307,
          254.76923076923077, 915.6923076923077, 254.76923076923077,
          918.1538461538462, 253.53846153846155, 923.0769230769231,
          253.53846153846155, 925.5384615384615, 252.30769230769232, 928,
          252.30769230769232, 930.4615384615385, 251.07692307692307,
          935.3846153846154, 251.07692307692307, 937.8461538461538,
          249.84615384615384, 945.2307692307693, 249.84615384615384,
          947.6923076923077, 248.6153846153846, 952.6153846153846,
          248.6153846153846, 955.0769230769231, 247.3846153846154, 960,
          247.3846153846154, 962.4615384615385, 246.15384615384616,
          964.9230769230769, 246.15384615384616, 967.3846153846154,
          244.92307692307693, 972.3076923076923, 244.92307692307693,
          974.7692307692307, 243.69230769230768, 977.2307692307693,
          243.69230769230768, 979.6923076923077, 242.46153846153845,
          982.1538461538462, 242.46153846153845, 987.0769230769231, 240, 992,
          240, 994.4615384615385, 238.76923076923077, 996.9230769230769,
          238.76923076923077, 999.3846153846154, 237.53846153846155,
          1001.8461538461538, 237.53846153846155, 1009.2307692307693,
          233.84615384615384, 1014.1538461538462, 233.84615384615384,
          1016.6153846153846, 232.6153846153846, 1019.0769230769231,
          232.6153846153846, 1021.5384615384615, 231.3846153846154,
          1021.5384615384615, 227.69230769230768, 1019.0769230769231,
          226.46153846153845,
        ],
        attributes: [],
        color: '#5EDAEB',
        name: 'Retinal pigment epithelium + Bruch`s membrane',
        order: 11,
      },
    ],
  },
});
