import { t } from 'i18next';
import { fork, put, takeLatest } from 'redux-saga/effects';

import { getExamLayer } from 'api/examination';
import {
  getLayersReportAction,
  getLayersReportError,
  getLayersReportSuccess,
} from 'redux/actions/examination';
import { ExamActionTypes } from 'redux/constants/examination';
import { IWorkerProps } from 'redux/types/examination';
import notify from 'utils/toast';

function* getLayersWatcher() {
  yield takeLatest(ExamActionTypes.SCAN_LAYERS_REPORT_REQUEST, getLayersWorker);
}

interface ILayersWorkerProps extends Omit<IWorkerProps, 'action'> {
  type: ExamActionTypes;
  payload: {
    exam_id: string;
    scan_id: string;
  };
  action: getLayersReportAction;
}

function* getLayersWorker({ payload, action }: ILayersWorkerProps) {
  try {
    const { data: layersData } = yield getExamLayer(
      payload.exam_id,
      payload.scan_id
    );

    if (layersData.status === 'Error') {
      throw new Error(layersData.message);
    }
    yield put(getLayersReportSuccess());
    action?.success?.(layersData.data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    action?.error?.();
    console.error(error);
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    yield put(getLayersReportError());
    yield notify('error', errorMessage);
  }
}

export default function* getLayersReportSaga() {
  yield fork(getLayersWatcher);
}
