export enum patientConstants {
  CREATE_PATIENT_REQUEST = 'CREATE_PATIENT_REQUEST',
  CREATE_PATIENT_SUCCESS = 'CREATE_PATIENT_SUCCESS',
  CREATE_PATIENT_ERROR = 'CREATE_PATIENT_ERROR',

  GET_PATIENT_REQUEST = 'GET_PATIENT_REQUEST',
  GET_PATIENT_SUCCESS = 'GET_PATIENT_SUCCESS',
  GET_PATIENT_ERROR = 'GET_PATIENT_ERROR',

  UPDATE_PATIENT_REQUEST = 'UPDATE_PATIENT_REQUEST',
  UPDATE_PATIENT_SUCCESS = 'UPDATE_PATIENT_SUCCESS',
  UPDATE_PATIENT_ERROR = 'UPDATE_PATIENT_ERROR',

  DELETE_PATIENT_REQUEST = 'DELETE_PATIENT_REQUEST',
  DELETE_PATIENT_SUCCESS = 'DELETE_PATIENT_SUCCESS',
  DELETE_PATIENT_ERROR = 'DELETE_PATIENT_ERROR',

  // Examinations of current patient
  GET_PATIENT_EXAMINATIONS_REQUEST = 'GET_PATIENT_EXAMINATIONS_REQUEST',
  GET_PATIENT_EXAMINATIONS_SUCCESS = 'GET_PATIENT_EXAMINATIONS_SUCCESS',
  GET_PATIENT_EXAMINATIONS_ERROR = 'GET_PATIENT_EXAMINATIONS_ERROR',

  FILTER_PATIENT_REQUEST = 'FILTER_PATIENT_REQUEST',
  FILTER_PATIENT_SUCCESS = 'FILTER_PATIENT_SUCCESS',
  FILTER_PATIENT_ERROR = 'FILTER_PATIENT_ERROR',
}
