import { StateSchema } from 'redux/types';

export const subscriptionSelector = (state: StateSchema) => state.subscription;
export const subscriptionPlansSelector = (state: StateSchema) =>
  state.subscription.data.plans;
export const subscriptionDetailsSelector = (state: StateSchema) =>
  state.subscription.data.subscription_details;
export const selectSubscriptionServices = (state: StateSchema) =>
  state.subscription.data.subscription_services;
export const selectPaymentStatistics = (state: StateSchema) =>
  state.subscription.data.payment_statistics;
