import { memo } from 'react';

import { Form, Formik } from 'formik';

import customStyles from '../customSelectStyles';

import Button from 'components/Button';
import FormField from 'components/FormComponents';
import Text from 'components/Text';

import styles from './MainCard.module.scss';

const MainCardSkeleton = memo(() => {
  return (
    <>
      <Text tag='h3' className={styles.title}>
        Plan
      </Text>
      <div className={styles.cost}>
        <div className={styles.price}>
          {'$00.00'}
          <span>{'/month'}</span>
        </div>
      </div>
      <div className={styles['bold-content']}>
        <Formik initialValues={{ plan_id: 1 }} onSubmit={() => {}}>
          <Form className={styles.form}>
            <FormField
              options={[{ value: 1, label: '100 / Month' }]}
              customStyles={customStyles}
              component={'select'}
              name={'plan_id'}
              id={'plan_id'}
              className={styles.field}
            />
          </Form>
        </Formik>
      </div>
      <Button className={styles['subscribe-btn']}>Subscribe</Button>
      <Button appearance={'simple'} className={styles.description}>
        Try for free
      </Button>
    </>
  );
});

MainCardSkeleton.displayName = 'MainCardSkeleton';

export default MainCardSkeleton;
