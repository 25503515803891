import { memo } from 'react';

interface CheckboxInputProps extends React.HTMLProps<HTMLInputElement> {}

const CheckBox = memo(({ ...props }: CheckboxInputProps) => {
  const {
    name,
    value,
    disabled,
    id,
    onChange,
    onBlur,
    checked,
    readOnly,
    label,
  } = props;

  return (
    <>
      <input
        readOnly={readOnly}
        onChange={onChange}
        id={id}
        type={'checkbox'}
        value={value}
        checked={checked}
        name={name}
        disabled={disabled}
        onBlur={onBlur}
      />
      <label htmlFor={id}>{label && <span>{label}</span>}</label>
    </>
  );
});

CheckBox.displayName = 'CheckBox';

export default CheckBox;
