import { Language } from '../i18n';

const translation: Language = {
  dashboard: {
    error: {
      try_again: 'Réessayer',
    },
    banner: {
      title1: 'Veuillez contacter',
      link: "l'équipe commerciale d'Altris",
      title2: 'pour étendre votre abonnement',
    },
    empty_data: {
      title: "Aucun patient n'a été ajouté",
      subtitle: 'Vous pouvez ajouter un nouveau patient en cliquant sur',
      add_new_patient: 'ajouter un nouveau patient',
    },
    header: {
      close_filter: 'Fermer le filtre',
      open_filter: 'Filtrer',
      search_placeholder: 'Rechercher un patient',
      examination_filter: "Filtre d'examen",
      filter_form: {
        show_scan_by_date: 'Afficher le scan par date',
        all: 'Tous',
        today: "Aujourd'hui",
        range: 'Intervalle',
        from: 'De',
        to: 'À',
        created_by: 'Filtre de patients',
        doctor_select: 'Sélectionner un docteur',
        apply: 'Appliquer',
        clear: 'Effacer le filtre',
      },
      add_new_patient: 'Ajouter un nouveau patient',
      add_new_examination: 'Ajouter un nouvel examen',
      back: 'Retour',
    },
    patient_list: {
      header: {
        patient_id: 'ID patient',
        patient_name: 'Nom du patient',
        birth_date: 'Date de naissance',
        gender: 'Sexe',
        last_upload: 'Dernier envoi',
        severity_level: 'Niveau de sévérité',
        description: 'Description',
      },
      item: {
        patient_id: 'ID patient',
        patient_name: 'Nom du patient',
        birth_date: 'Date de naissance',
        gender: 'Sexe',
        last_upload: 'Dernier envoi',
        severity_level: 'Niveau de sévérité',
        description: 'Description',
        edit: 'Modifier',
        delete: 'Supprimer',
        back: 'Retour',
        show_examinations: 'Afficher les examens',
      },
    },
  },
  contact_us_page: {
    contact_us: 'Contactez-nous',
    fill_the_form: 'Veuillez remplir le formulaire ci-dessous',
    example_short: 'Ex',
    first_name: 'Prénom',
    email: 'Email',
    message: 'Votre message',
    submit_form: 'Envoyer le formulaire',
    attach_file: 'Joindre un fichier',
    attach_screenshot:
      "Vous pouvez joindre une capture d'écran à votre message pour montrer les difficultés que vous avez rencontrées. Cela pourrait aider notre équipe d'assistance à trouver plus rapidement la solution qui vous convient.",
  },
  demo_user_registration: {
    back: 'Retour',
    banner_text_1: 'Tout OCT',
    banner_text_2:
      "Découvrez comment l'IA analyse les OCT :\nles scans sont à l'intérieur",
    banner_text_3: 'Scan OCT avec Altris AI',
    first_step: {
      welcome: 'Bienvenue sur Altris AI',
      email_label: 'Saisissez votre email pour le valider :',
      email: 'Email',
      info: "En continuant, vous acceptez automatiquement qu'Altris AI conserve votre adresse e-mail afin de vous envoyer du matériel marketing.",
      submit_form: 'Envoyer la confirmation',
    },
    second_step: {
      enter_security_code: 'Saisissez le code de sécurité',
      send_to: 'Ce code de sécurité a été envoyé à :',
      submit: 'Confirmer',
      resend_code: 'Renvoyer le code',
      seconds_short: 'sec',
    },
    third_step: {
      add_some_info: 'Ajoutez quelques informations',
      full_name_label: 'Saisissez votre nom complet',
      full_name_placeholder: 'Nom complet',
      country_label: 'Pays',
      country_placeholder: 'Choisir le pays',
      oct_label: 'Système OCT',
      oct_placeholder: 'Sélectionnez votre système OCT',
      profession_label: 'Profession',
      profession_placeholder: 'Choisissez une profession',
      decision_maker_label:
        'Êtes-vous un décideur dans votre clinique/institution ?',
      promo_code_label: 'Code promo (facultatif)',
      promo_code_info:
        "Contactez le distributeur Altris ou l'équipe commerciale Altris",
      promo_code_placeholder: 'Entrer le code promo',
      submit: 'Terminer',
    },
  },
  education: {
    title: 'Rejoignez Altris Education',
    subtitle1:
      "Altris Education OCT deviendra votre guide dans l'apprentissage de l'interprétation OCT. Cette application mobile est interactive, gratuite et disponible à tout moment et en tout lieu. Les méthodes d'enseignement traditionnelles, telles que les webinaires, les conférences et les atlas, ne suffisent pas aux apprenants modernes : il est temps d'utiliser des applications mobiles gratuites.",
    education: 'Éducation',
    subtitle2:
      "Dans Altris Education OCT, vous aurez accès à des millions de scans OCT étiquetés et ferez en même temps partie d'une communauté de spécialistes des soins oculaires. Vous aurez l'occasion unique de télécharger des scans OCT controversés et d'en discuter avec des collègues du monde entier. Avec Altris Education OCT, nous établissons des normes de diagnostic plus élevées, ce qui se traduit par de meilleurs résultats pour les patients.",
    learn_more: 'Apprendre plus',
    download: "Télécharger l'application Altris Education",
    oct_scans: 'Scans OCT',
    experts: 'Experts',
    retina_experts: 'Des experts de la rétine étiquettent chaque scan',
    eye_care_specialists:
      'Spécialistes des soins oculaires au sein de la communauté',
    pathological_signs: 'Signes pathologiques',
    pathologies: 'Pathologies',
  },
  forgot_password_page: {
    first_step: {
      enter_email: 'Entrez votre adresse e-mail',
      email: 'Email',
      confirm: 'Confirmer',
      or_sign_in: 'ou se connecter',
    },
    second_step: {
      congratulations: 'Félicitations !',
      check_email: 'Veuillez vérifier votre e-mail',
      back_to_sign_in: 'Retour à la connexion',
    },
  },
  maintenance_mode: {
    title: 'Site Web en maintenance !',
    subtitle:
      "Ce site Web fait actuellement l'objet d'une maintenance programmée. Nous devrions être de retour sous peu.",
    refresh_page: 'Actualiser la page',
  },
  mobile_availability: {
    title: 'Bienvenue sur Altris AI',
    subtitle:
      "Altris AI n'est accessible qu'à partir d'un PC. Veuillez ouvrir la plateforme sur votre ordinateur personnel pour en profiter pleinement.",
    link: 'Lien',
    copy_link: 'Copier le lien',
    mobile_subscription: {
      title: 'Abonnements',
      subtitle:
        "Choisissez votre forfait d'abonnement pour commencer à utiliser Altris AI.",
      services: 'Services',
    },
  },
  offline: {
    title: 'Votre connexion est perdue !',
    subtitle: 'Veuillez vérifier votre connexion Internet...',
  },
  examination: {
    unavailable: {
      title: 'Pas assez de données',
      subtitle:
        "Les données DICOM sont insuffisantes pour examiner l'examen. Veuillez remplir le formulaire vide en haut de la page.",
    },
    header: {
      patient_id: 'ID patient',
      patient_name: 'Nom du patient',
      patient_name_placeholder: 'Prénom',
      patient_surname: 'Nom de famille du patient',
      patient_surname_placeholder: 'Nom',
      birth_date: 'Date de naissance',
      gender: 'Sexe',
      gender_placeholder: 'Choisir',
      examination_date: "Date d'examen",
      notes: 'Notes',
      report_unavailable:
        'Les rapports ne sont malheureusement pas disponibles.',
      select_plan: 'Sélectionner un forfait',
      to_use_this_feature: 'pour utiliser cette fonctionnalité.',
      report: 'Rapport',
      export_unavailable:
        "Malheureusement, l'exportation n'est pas disponible pour ce scan.",
      export: 'Exporter',
      save: 'Enregistrer',
      patient_info: 'Informations du patient',
      male: 'Homme',
      female: 'Femme',
      other: 'Autre',
      other_patient_examinations: "Examens d'autres patients",
      examination_label:
        '{{name}} / exam.: {{data}} / Oeil : {{eye}} / scan : {{scan}}',
    },
    tabs: {
      single_scan: 'SCAN UNIQUE',
      pathologies_detection: 'DÉTECTION DE PATHOLOGIES',
      measurements: 'MESURES',
      all_scans: 'VUE DE TOUS LES SCANS',
      both_eyes: 'VUE DES DEUX YEUX',
      comparison: "COMPARAISON D'ÉPAISSEUR",
      progression: 'PROGRESSION DE LA PATHOLOGIE',
      glaucoma: 'ANALYSE DU RISQUE DE GLAUCOME',
    },
    single_scan: {
      severity: 'Sévérité',
      off: 'Désactivé',
      thickness_map_off: "Carte d'épaisseur désactivée",
      on: 'Activé',
      thickness_map_on: "Carte d'épaisseur activée",
      average_thickness: '{{metrics}} mm épaisseur moyenne, μm',
      layers_failed: {
        title: "Une erreur s'est produite lors de l'analyse des couches.",
        subtitle: 'Veuillez signaler ce problème au support.',
      },
      retina_thickness_map: "Carte d'épaisseur de la rétine",
    },
    pathologies_detection: {
      classification: 'Classification',
      no_access:
        "Vous n'avez pas accès aux segmentations. Veuillez utiliser le formulaire de contact dans votre profil.",
    },
    measurements: {
      not_available:
        "Les mesures ne sont pas disponibles pour l'examen actuel.",
      failed: 'La mesure a échoué, veuillez signaler ce problème au support.',
      in_progress:
        'Mesures en cours, veuillez consulter cette page un peu plus tard.',
      no_plan:
        "Votre forfait n'inclut pas l'analyse des mesures. Veuillez mettre à niveau votre forfait.",
      scan: {
        measurements: 'Mesures',
        auto: 'Auto',
        manual: 'Manuel',
        segmentation: 'Segmentation',
        length: 'Longueur',
        add_measurements: 'Ajouter des mesures',
        no_lines: 'Aucune ligne pour le scan actuel',
        highlight: 'Surligner',
        horizontal: 'Horizontal',
        vertical: 'Vertical',
        reset_measurements: 'Réinitialiser les mesures',
        no_pathologies: 'Aucune pathologie pour le scan actuel',
      },
      table: {
        segmentation_area: 'Zone de segmentation',
        segmentation_volume: 'Volume de segmentation',
        combined: 'Combiné',
        separate: 'Séparé',
        area_headings: {
          all_segmentations: 'Toutes les segmentations',
          b_scan_area: 'Zone B-scan',
          b_scan_area_view: 'Vue de la zone B-scan',
          en_face_area_view:
            '<span>Vue de face (mm<sup>2</sup>  * 1000)</span>',
        },
        volume_headings: {
          all_segmentations: 'Toutes les segmentations',
          measured_volume_wrap: 'Volume mesuré<br />(ETDRS {{number}}mm)',
          measured_volume: 'Volume mesuré (ETDRS {{number}}mm)',
          en_face_area_view:
            '<span>Vue de face<br> (mm<sup>3</sup>  * 1000)</span>',
          en_face_area_view_nl: 'Vue de face ',
        },
        no_pathology: 'Aucune pathologie pour le scan actuel',
      },
    },
    all_scans: {
      no_scans: {
        title: 'Aucun scan avec ces paramètres.',
        subtitle: "Veuillez essayer d'autres filtres.",
      },
      severity: 'Sévérité',
    },
    both_eyes: {
      add_fellow_eye: "Ajouter l'œil controlatéral",
      no_plan:
        "Votre forfait n'inclut pas l'analyse des deux yeux. Veuillez mettre à niveau votre forfait.",
      severity: 'Sévérité',
      left_eye: 'Horizontal, Gauche (OS)',
      right_eye: 'Horizontal, Droit (OD)',
      average_thickness: '{{metrics}} mm épaisseur moyenne, μm',
      sync: 'Synchroniser',
      different_scan_number:
        'Deux examens ont un nombre de scans différent, donc le défilement ne peut pas être synchronisé.',
    },
    comparison: {
      add_examination: 'Ajouter un examen à comparer',
      no_plan:
        "Votre forfait n'inclut pas l'analyse comparative. Veuillez mettre à niveau votre forfait.",
      retina_thickness_difference: "Différence d'épaisseur rétinienne",
      retina_thickness_difference_tooltip:
        "Montre la différence d'épaisseur rétinienne entre les examens, du plus récent au plus ancien.",
      average_thickness: '{{metrics}} mm épaisseur moyenne, μm',
      sync: 'Synchroniser',
      different_scan_number:
        'Deux examens ont un nombre de scans différent, donc le défilement ne peut pas être synchronisé.',
      severity: 'Sévérité',
    },
    progression: {
      no_plan:
        "Votre forfait n'inclut pas l'analyse de la progression. Veuillez mettre à niveau votre forfait.",
      severity: 'Sévérité',
      exam_does_not_have_date: "L'un des examens n'a pas de date.",
      oldest_to_newest: 'Du plus ancien au plus récent',
      newest_to_oldest: 'Du plus récent au plus ancien',
      sort_by_date: 'Trier par date',
    },
    glaucoma: {
      unavailable: {
        title1: 'Analyse du risque de glaucome',
        title2: "n'est pas disponible pour cet examen.",
        title3: 'Cela pourrait être dû à:',
        first_item1:
          'Des pathologies présentes dans les scans affectant le complexe GCC,',
        first_item2: 'rendant un calcul précis impossible.',
        second_item: "D'autres facteurs empêchant une analyse précise.",
      },
      alert: {
        red: {
          title: 'Niveau élevé de risque de glaucome',
          description:
            "La différence notable d'épaisseur du GCC entre les hémisphères supérieur (S) et inférieur (I) indique des signes potentiels de glaucome",
        },
        yellow: {
          title: 'Niveau moyen de risque de glaucome',
          description:
            "Bien que les variations détectées dans l'épaisseur du GCC des hémisphères supérieur (S) et inférieur (I) ne soient pas suffisamment importantes pour être préoccupantes, un examen et une surveillance plus poussés sont recommandés pour une compréhension globale du risque",
        },
        green: {
          title: 'Aucun risque de glaucome',
          description:
            "La différence d'épaisseur calculée entre l'épaisseur des hémisphères supérieur (S) et inférieur (I) du GCC se situe dans les paramètres cliniquement acceptables.",
        },
      },
      target: {
        gcc_analysis: 'Analyse GCC',
        gcc_thickness: 'Épaisseur GCC',
        superior_inferior: 'Supérieur / Inférieur',
      },
      info: {
        gcc_asymmetry: 'Asymétrie GCC',
        tooltip: {
          title1: "L'analyse de l'asymétrie GCC",
          title2: 'évalue deux indicateurs:',
          first_item1:
            "Indice d'asymétrie : Ce paramètre mesure le déséquilibre entre les secteurs supérieur et inférieur et change de couleur en fonction de la proportion S/I :",
          first_item2: 'Vert',
          first_item3: " - Pas d'asymétrie",
          first_item4: 'Jaune',
          first_item5: ' - Asymétrie modérée',
          first_item6: 'Rouge',
          first_item7: ' - Asymétrie',
          second_item1: 'Le ',
          second_item2: 'épaisseur des secteurs supérieur et inférieur',
          second_item3: ' séparément.',
          description:
            "En fonction de l'écart de ces indicateurs par rapport aux plages attendues, nous calculons et affichons l'alerte de risque de glaucome.",
        },
        asymmetry_index: "Indice d'asymétrie",
        superior_thickness: 'Épaisseur supérieure',
        inferior_thickness: 'Épaisseur inférieure',
        normal: 'Normal',
        glaucoma_alert: 'Alerte glaucome',
      },
      hemisphere: {
        title: "Asymétrie de l'épaisseur GCC des hémisphères S-I",
        superior: 'Supérieur',
        inferior: 'Inférieur',
        description: {
          title: 'Description',
          item1: "Asymétrie de l'épaisseur GCC des hémisphères S-I",
          item2:
            "Illustre l'asymétrie de l'épaisseur du GCC (RNFL+GCL+IPL) entre les hémisphères supérieur et inférieur.",
          item3: 'Une grille 8x8',
          item4:
            "est appliquée sur une zone maculaire de 6x6 mm pour illustrer l'asymétrie de l'épaisseur du GCC entre les quadrants symétriques des hémisphères supérieur et inférieur.",
          item5:
            "La différence d'épaisseur du GCC est indiquée dans les carrés symétriques.",
          item6: 'NB !',
          item7:
            "Pour une évaluation qualitative de l'épaisseur du GCC, il est nécessaire de prendre en compte la présence de modifications exsudatives dans la zone maculaire de la rétine et d'évaluer les données d'épaisseur du GCC dans l'autre œil.",
        },
      },
    },
  },
  onboarding_page: {
    title: 'Intégration',
    all_scans: 'Tous les scans',
    both_eyes: 'Les deux yeux',
    comparison: 'Comparaison',
    create_examination: 'Créer un examen',
    create_patient: 'Créer un patient',
    header: 'En-tête',
    measurements: 'Mesures',
    pathologies_detection: 'Détection de pathologies',
    progression: 'Progression',
    report: 'Rapport',
    single_scan: 'Scan unique',
  },
  register_user_page: {
    step_one: {
      sign_up_with_google: "S'inscrire avec Google",
      or: 'ou',
      sing_up_with_email: 'Créer un compte avec email',
      email_label: 'Adresse e-mail professionnelle',
      website_label: "Site Web de l'institution",
      first_name_label: 'Prénom',
      last_name_label: 'Nom de famille',
      next: 'Suivant',
      or_sign_in: 'ou se connecter',
    },
    step_two: {
      create_an_account: 'Créer un compte',
      password_label: 'Créer un mot de passe',
      repeat_password_label: 'Répéter le mot de passe',
      next: 'Suivant',
      back: 'Retour',
    },
    step_three: {
      create_an_account: 'Créer un compte',
      profession_placeholder: 'Choisissez une profession',
      country_placeholder: 'Votre pays',
      i_agree_with: "J'accepte",
      privacy_policy: 'Politique de confidentialité',
      and: 'et',
      terms_and_conditions: 'Conditions générales',
      back: 'Retour',
    },
    step_thankyou: {
      title: 'Toutes nos félicitations !',
      subtitle: 'Votre compte a été créé avec succès !',
      button: 'Commençons',
    },
  },
  reset_password_page: {
    enter_new_password: 'Entrez votre nouveau mot de passe',
    password_label: 'Créer un mot de passe',
    repeat_password_label: 'Répéter le mot de passe',
    next: 'Suivant',
  },
  settings_page: {
    tabs: {
      profile: 'Profil',
      report_settings: 'Paramètres du rapport',
      progression_settings: 'Paramètres de progression',
      functions: 'Fonctions',
      change_password: 'Changer le mot de passe',
      accounts_data: 'Données du compte',
    },
    accounts_data: {
      title: 'Données du compte',
      subtitle:
        'Créez une copie du contenu de votre compte Altris AI pour le sauvegarder.',
      no_examinations:
        "Aucun examen n'a été créé pour effectuer une sauvegarde",
      request_data: 'Demander des données personnelles',
      in_progress:
        'Vos données sont en cours de création. Veuillez patienter un peu.',
      cancel_backup: "Annuler la création de la sauvegarde de l'utilisateur",
      download_data: 'Télécharger les données',
      download_info:
        "Voici vos données, vous pouvez les télécharger en cliquant sur le bouton ci-dessus. Cette archive zip sera enregistrée jusqu'au {{date}}. Après cette date, vous pourrez créer une sauvegarde une fois de plus.",
      delete_data: 'Supprimer les données personnelles',
      delete_info:
        'Vous pouvez également supprimer cette archive zip et créer une sauvegarde une fois de plus.',
      code_send: 'Un code de confirmation a été envoyé à votre adresse e-mail.',
      attempts_left: 'Tentatives restantes',
      example_short: 'Ex',
      confirm: 'Confirmer',
      cancel: 'Annuler',
    },
    change_password: {
      password_label: 'Votre mot de passe précédent',
      password_placeholder: 'Entrez votre mot de passe',
      new_password_label: 'Votre nouveau mot de passe',
      new_password_placeholder: 'Entrez votre mot de passe',
      repeat_new_password_label: 'Confirmez le mot de passe',
      repeat_new_password_placeholder: 'Entrez votre mot de passe',
      unable_to_change_password:
        'Désolé, vous ne pouvez pas changer votre mot de passe',
      submit: 'Mettre à jour mon mot de passe',
      code_send: 'Un code de confirmation a été envoyé à votre adresse e-mail.',
      attempts_left: 'Tentatives restantes',
      example_short: 'Ex',
      confirm: 'Confirmer',
      cancel: 'Annuler',
    },
    functions_settings: {
      title: 'Paramètres',
      subtitle: 'Modifier les paramètres à partir du formulaire ci-dessous',
      save: 'Enregistrer les paramètres',
      on: 'activé',
      off: 'désactivé',
    },
    profile: {
      example_short: 'Ex',
      first_name: 'Prénom',
      last_name: 'Nom',
      email: 'Email',
      save: 'Enregistrer les modifications',
      country: 'Pays',
      country_placeholder: 'Votre pays',
      profession: 'Profession / Spécialisation',
      profession_placeholder: 'Choisissez une profession',
      language: 'Langue',
    },
    progression_report: {
      title:
        'Sélectionner les pathologies à supprimer du suivi dans la progression de la pathologie',
      subtitle:
        'Si vous désactivez la pathologie, elle ne sera pas affichée dans la progression de la pathologie.',
      on: 'activé',
      off: 'désactivé',
      save_settings: 'Enregistrer les paramètres',
    },
    report_settings: {
      clinic_name: 'Nom de la clinique',
      clinic_email: 'Email de la clinique',
      save: 'Enregistrer les modifications',
    },
  },
  sign_in_page: {
    email_label: 'Adresse e-mail professionnelle',
    password_label: 'Mot de passe',
    forgot_password: 'Mot de passe oublié ?',
    sign_in: 'Se connecter',
    or: 'ou',
    sign_in_with_google: 'Se connecter avec Google',
  },
  subscription_page: {
    b2c: {
      subscription: 'Abonnement',
      billing: 'Facturation',
      subscription_expired:
        "L'abonnement que vous utilisez actuellement a expiré. Veuillez passer à un nouvel",
      subscription_page: "Page d'abonnement",
      unsubscribe: 'Vous pouvez vous désabonner.',
      already_unsubscribed:
        "Vous vous êtes déjà désabonné de votre ancien forfait d'abonnement. Votre forfait actuel sera actif jusqu'au {{date}}",
      choose_plan:
        'Choisissez votre forfait idéal. Pas de contrats. Pas de frais cachés.',
    },
    common: {
      retina_layers_detected: 'Affections de la rétine détectées par Altris AI',
    },
  },
  team_members_page: {
    title: "Membres de l'équipe",
    invite_doctor: 'Inviter un médecin',
    max_doctors:
      "Il y a un nombre maximum de médecins que vous pouvez inviter. Si vous souhaitez inviter quelqu'un de nouveau, vous devez d'abord en supprimer un de votre compte.",
  },
  components: {
    aside: {
      back: 'Retour',
    },
    context_modal: {
      smart_report: {
        create_examination_report: "Créer un rapport d'examen",
        standard: 'Standard',
        standard_plus: 'Standard+',
        advanced: 'Avancé',
        custom: 'Personnalisé',
        progression: 'Progression',
        creating_report: 'Création du rapport...',
        loading_resources_for_report:
          'Chargement des ressources pour le rapport {{percentage}}',
        cancel: 'Annuler',
        preview: 'Aperçu',
        components_loaded: 'Composants chargés:',
        images_loaded: 'Images chargées:',
        heatmaps_loaded: 'Cartes thermiques chargées:',
        progression_report: {
          description1:
            "Visualisation de l'évolution des pathologies au fil du temps en utilisant la zone de B-Scan, la zone En-face et les volumes. Veuillez choisir les pathologies spécifiques d'intérêt à inclure dans le rapport.",
          important: 'Note importante :',
          description2:
            "Sélectionnez uniquement les pathologies qui sont des points d'intérêt pour éviter que le rapport ne devienne trop volumineux.",
          select_pathologies: 'Sélectionner les pathologies :',
          all: 'Toutes',
          on: 'ACTIVÉ',
          off: 'DÉSACTIVÉ',
        },
        preset: {
          report_includes: 'Le rapport comprend:',
          severity_detection: 'Détection de la sévérité',
          severity_detection_description:
            "Indique le nombre de scans sévères dans l'examen et leur degré de sévérité",
          oct_description: "Description de l'analyse OCT par Altris AI",
          oct_description_description:
            "Inclut une description textuelle de l'analyse de l'examen",
          retina_layers: 'Segmentation des couches rétiniennes',
          retina_layers_description:
            'Représente toutes les couches rétiniennes, mises en évidence dans des couleurs différentes pour une utilisation pratique.',
          both_eyes: 'Vue des deux yeux',
          both_eyes_description:
            "Montre les deux yeux opposés pour comparer l'état clinique.",
          both_eyes_unavailable:
            'Vous devez ajouter un deuxième scan dans la section "Vue des deux yeux" pour activer cette option.',
          etdrs_calculations: 'Calculs ETDRS',
          etdrs_calculations_description:
            "Inclut l'épaisseur moyenne de 1, 3 et 6 mm ; l'épaisseur de plusieurs couches (si sélectionnée sur la plateforme) ; l'épaisseur de la couche sélectionnée (si sélectionnée sur la plateforme).",
          comparison: "Comparaison d'épaisseur",
          comparison_description:
            "Inclut deux examens du même œil et les variations d'épaisseur sur ETDRS.",
          comparison_unavailable:
            'Vous devez ajouter un deuxième scan dans la section "Comparaison" pour activer cette option.',
          auto_measurements: 'Mesures automatiques',
          auto_measurements_description:
            'Inclut des mesures linéaires des pathologies, calculées automatiquement.',
          manual_measurements: 'Mesures manuelles',
          manual_measurements_description:
            'Inclut des mesures linéaires des pathologies, calculées manuellement (si sélectionnées sur la plateforme).',
          segmentation_area: 'Zone de segmentation',
          segmentation_area_description:
            "Affiche la liste de toutes les pathologies de l'examen et leurs zones B-Scan et En face.",
          segmentation_volume: 'Volume de segmentation',
          segmentation_volume_description:
            "Affiche la liste de toutes les pathologies de l'examen et leur volume (en mm^3 ou nL).",
          pathology_detection:
            'Détection de pathologie (3 scans les plus pathologiques)',
          pathology_detection_description:
            'Inclut les trois scans les plus pathologiques, toutes les segmentations et la classification pour ces scans.',
          referral_urgency_score: "Score d'urgence de la recommandation",
          referral_urgency_score_description:
            "Indique l'urgence d'une nouvelle recommandation pour ce cas en fonction des classifications reconnues.",
          glaucoma: 'Analyse du risque de glaucome',
          glaucoma_description:
            "L'analyse des risques de glaucome n'est disponible que pour les examens où les signes pathologiques n'affectent pas le GCC",
        },
        custom_report: {
          analyze_other_scan: 'Analyser un autre scan',
          check_scan_that_you_want_to_analyze:
            'Cochez le scan que vous voulez analyser.',
          etdrs_calculations_description:
            "Autorisé uniquement pour les fichiers Dicom contenant des informations ETDRS. Veuillez attendre la fin de l'analyse ETDRS pour une visualisation correcte dans le rapport.",
          auto_measurements_description:
            'Votre analyse des mesures doit être terminée pour activer cette option.',
          auto_measurements_unavailable:
            "Ce service n'est pas disponible dans votre abonnement. Veuillez passer à Pro pour l'utiliser.",
          manual_measurements_description:
            'Votre analyse des mesures doit être terminée et vous devez tracer une ligne dans les mesures manuelles pour activer cette option.',
          manual_measurements_unavailable:
            "Ce service n'est pas disponible dans votre abonnement. Veuillez passer à Pro pour l'utiliser.",
          segmentation_area_description:
            'Votre analyse des mesures doit être terminée pour activer cette option.',
          segmentation_area_unavailable:
            "Ce service n'est pas disponible dans votre abonnement. Veuillez passer à Pro pour l'utiliser.",
          both_eyes: 'Les deux yeux',
          segmentation_volume_description:
            'Votre analyse des mesures doit être terminée pour activer cette option.',
          segmentation_volume_unavailable:
            "Ce service n'est pas disponible dans votre abonnement. Veuillez passer à Pro pour l'utiliser.",
          comparison: 'Comparaison',
          selected_scan: 'Scan sélectionné:',
          select_other: 'Sélectionner un autre',
          added_scans: 'Scans ajoutés:',
          add_other: 'Ajouter un autre',
          no_selected_layers: 'Aucun calque sélectionné.',
          select_layers: 'Sélectionner des calques',
        },
      },
    },
    countdown_timer: {
      days: 'Jours',
      hours: 'Heures',
      minutes: 'Minutes',
      seconds: 'Secondes',
    },
    create_examination: {
      upload_archived_file:
        'Téléverser un dossier archivé (.zip), exporté de votre OCT. Principalement applicable à Heidelberg Engineering OCT.',
      create_patient_from_dicom: "Créer un nouveau patient à partir d'un DICOM",
      create_patient_from_dicom_description:
        'Les informations sur le patient seront extraites du fichier DICOM',
      notes_label: 'Ex.: Ma note',
      notes_placeholder: "Notes pour l'examen",
      file_label: 'Téléverser un DICOM pour terminer',
      multiple_file_label:
        "Téléverser jusqu'à deux fichiers DICOM du même patient",
      multiple_file_description:
        "Assurez-vous qu'ils appartiennent à un seul patient et incluent les yeux droit et gauche",
      scan_width_label: 'Largeur du scan',
      scan_width_placeholder: 'Largeur',
      scan_width_message:
        "Assurez-vous d'avoir sélectionné la bonne largeur de scan car elle affecte les mesures de l'examen",
      eye_label: 'Oeil',
      eye_placeholder: 'Type',
      examination_date: "Date d'examen",
      upload_images_to_complete: 'Téléverser des images pour terminer',
      right: 'Droite',
      left: 'Gauche',
    },
    current_plan_info: {
      current_plan: 'Forfait actuel',
      examinations_available: 'Examens disponibles',
      examinations_per_month: '{{count}} / Mois',
      start_date: 'Date de début',
      expiration_date: "Date d'expiration",
      period: 'Période',
      amount: 'Montant',
      status: 'Statut',
    },
    detailed_scan: {
      scan_detail: 'Détails du scan',
    },
    drag_drop_list: {
      different_scan_count:
        'Deux examens ont un nombre de scans différent, le défilement ne peut donc pas être synchronisé',
      add: 'Ajouter',
      examination: 'examen',
      no_scans_with_such_severity: 'Aucun scan avec cette sévérité',
      average_thickness: "{{metrics}} mm d'épaisseur moyenne, μm",
    },
    error_fallback: {
      something_went_wrong: 'Une erreur est survenue',
      fix_problem: 'Résoudre le problème',
    },
    etdrs_target: {
      single_layer_thickness: "Épaisseur d'une seule couche",
      multiple_layers_thickness: 'Épaisseur de couches multiples',
      gcc_thickness: 'Épaisseur GCC',
    },
    examination_list: {
      empty: {
        title: 'Votre liste de projets est vide',
        subtitle: 'Vous pouvez ajouter un nouvel examen en appuyant sur',
        add_new_examination: 'Ajouter un nouvel examen',
      },
      header: {
        examinations: 'Examens',
        uploaded: 'Téléchargé',
        date: 'Date',
      },
      item: {
        eye: 'Oeil',
        examination_failed: 'Examen échoué',
        right_short: 'D',
        left_short: 'G',
        image: 'Image',
        edit: 'Modifier',
        delete: 'Supprimer',
      },
    },
    exam_stat_info: {
      no_severity: 'Aucune sévérité (Vert) :',
      moderate_severity: 'Sévérité modérée (Jaune) :',
      severe: 'Sévère (Rouge) :',
      scans: 'Scans',
      severity: 'Sévérité',
    },
    feedback: {
      title: 'Avis des clients',
    },
    first_subscription: {
      subscription: 'Abonnement',
      chose_plan:
        'Choisissez votre forfait idéal. Pas de contrats. Pas de frais cachés.',
    },
    form_components: {
      file_upload: {
        upload_your_date_here: 'Téléchargez vos données ici',
        add_more_examinations: "Ajouter plus d'examens",
        remove_file: 'Supprimer le fichier {{ending}}',
        upload_file: 'télécharger un fichier',
        you_added_files_one: 'Vous avez ajouté {{count}} fichier',
        you_added_files_other: 'Vous avez ajouté {{count}} fichiers',
      },
    },
    form_wrap: {
      help: 'Aide',
    },
    header: {
      view_my_account: 'Voir mon compte',
      sign_out: 'Se déconnecter',
      subscriptions: 'Abonnements',
      examinations_left: 'Examens restants',
      unlimited: 'Illimité',
      add_new_patient: 'Ajouter un nouveau patient',
      add_new_examination: 'Ajouter un nouvel examen',
      menuItems: {
        patients: 'Patients',
        settings: 'Paramètres',
        subscription: 'Abonnement',
        team_members: "Membres de l'équipe",
        contact_us: 'Contactez-nous',
        onboarding: 'Intégration',
      },
    },
    layers_filter: {
      hide: 'Masquer',
      show_more: 'Afficher plus',
      no_items: "L'IA n'a défini aucune zone à segmenter.",
      all: 'Tous',
      layers: 'Couches:',
      segmentations: 'Segmentations:',
      highlight: 'Surligner',
    },
    layout: {
      private_layout: {
        skip: 'Sauter',
        demo_banner_title:
          'Commencez un essai gratuit de 14 jours après la présentation vidéo',
        book_a_demo: 'Réservez un appel',
      },
      simple_layout: {
        sign_out: 'Se déconnecter',
      },
    },
    modal: {
      analyze_scans: {
        severity: 'Sévérité',
        analyze_all_scans: 'Analyser tous les scans',
        no_scans: 'Aucun scan avec ces paramètres.',
        try_another_filter: "Veuillez essayer d'autres filtres.",
        submit: 'Soumettre',
      },
      booking_meeting: {
        title:
          'Nous sommes sûrs de pouvoir trouver la solution parfaite pour vous !',
        book_a_demo: 'Réserver une démo',
      },
      buy_additional_items: {
        buy_additional_examinations:
          'Acheter {{number}} examens supplémentaires',
        will_be_cost: '{{description}} coûtera {{symbol}}{{price}}',
      },
      create_examination: {
        title1:
          'Remplissez le formulaire ci-dessous pour ajouter un nouvel examen. Télécharger',
        title2: 'UNIQUEMENT les B-Scans horizontaux de la zone maculaire',
        title3: " (Image du fond d'œil, Scans du disque optique ",
        title4: 'NE SONT PAS',
        title5: ' pris en charge)',
        select_exam_label: "Sélectionner l'examen",
        patient_id_label: 'Identifiant patient',
        patient_id_placeholder:
          'Le patient sera créé à partir du fichier DICOM',
        remaining_examinations: 'Examens restants : {{number}}',
        unlimited: 'Illimité',
        images: 'Images',
        folder: 'Dossier',
        add_examination: 'Ajouter un examen',
        right: 'Droite',
        left: 'Gauche',
        label: '{{name}} / examen : {{data}} / œil : {{eye}} / scan : {{scan}}',
      },
      create_patient: {
        no_exam_left1: 'Aucun examen restant. Vous pouvez',
        no_exam_left2: 'mettre à niveau votre forfait',
        no_exam_left3: " pour ajouter plus d'examens",
        create_new_patient_from_dicom:
          "Créer un nouveau patient à partir d'un DICOM",
        create_new_patient_from_dicom_description:
          'Les informations sur le patient seront extraites du fichier DICOM',
        patient_id_label: 'Identifiant patient',
        example_short: 'Ex',
        first_name_label: 'Prénom du patient',
        last_name_label: 'Nom du patient',
        birth_date_label: 'Date de naissance du patient',
        gender_label: 'Sexe',
        gender_placeholder: 'Choisir...',
        notes_label: 'Notes',
        add_examination: 'Ajouter un examen',
        add_patient: 'Ajouter un patient',
        add_patient_and_examination: 'Ajouter un patient et un examen',
        male: 'Homme',
        female: 'Femme',
        other: 'Autre',
      },
      current_plan: {
        annual: 'Annuel',
        monthly: 'Mensuel',
        days_left: 'Jours restants:',
        examinations_left: 'Examens restants:',
        unlimited: 'Illimité',
        invitations_left: 'Invitations restantes:',
        no_scheduled_payment:
          "Vous n'avez pas de prochain paiement programmé car vous n'êtes abonné à aucun forfait",
        next_payment_related_to_your_upcoming_plan:
          'Votre prochain paiement est lié à votre prochain forfait et est de',
        it_will_automatically_charge: 'Il sera automatiquement débité le',
        your_next_payment_is: 'Votre prochain paiement est',
        to_be_charged: ', à débiter le',
        payment_will_be_charged_automatically:
          'Le paiement sera automatiquement débité',
        you_successfully_unsubscribed:
          "Vous vous êtes désabonné avec succès d'un",
        plan: 'forfait',
        your_subscription_is_active: "Votre abonnement est actif jusqu'au",
        subscribe: "S'abonner",
        unsubscribe: 'Se désabonner',
        continue_subscriptions: 'Continuer les abonnements',
      },
      delete_exam: {
        cancel: 'Annuler',
        delete: 'Supprimer',
      },
      delete_patient: {
        cancel: 'Annuler',
        delete: 'Supprimer',
      },
      delete_team_member: {
        cancel: 'Annuler',
        delete: 'Supprimer',
      },
      download_dcm: {
        dont_have_dicom_file:
          "Vous n'avez pas de fichier DICOM pour le moment ?",
        use_this: 'Utilisez ceci !',
        download: 'Télécharger',
        i_have_dicom: "J'ai DICOM sur mon PC",
        download_dicom: 'Télécharger DICOM',
      },
      edit_scan_description: {
        oct_description_title: "Description de l'analyse OCT par Altris AI",
        save: 'Enregistrer les modifications',
      },
      export_json: {
        dicom_presentation_state: 'État de présentation DICOM',
        file: 'Fichier',
      },
      glaucoma_disclaimer: {
        title: 'Avertissement',
        i_acknowledged: "J'ai reconnu",
      },
      image_cropper: {
        cancel: 'Annuler',
        crop: 'Recadrer',
      },
      invite_team_member: {
        email_label: 'Email',
        first_name_label: 'Prénom du médecin',
        last_name_label: 'Nom du médecin',
        example_short: 'Ex',
        send_the_invitation: "Envoyer l'invitation",
      },
      no_objects_left: {
        examinations: {
          title: "Vous ne pouvez pas ajouter d'examens supplémentaires",
          subtitle:
            "Aucun nouvel examen ne peut être ajouté jusqu'à {{endData}} car la limite d'examens pour votre forfait a été dépassée pour ce mois.",
          subtext:
            'À partir de {{endData}}, vous pourrez à nouveau ajouter des examens.',
        },
        plan: 'forfait',
        annual: 'Annuel',
        monthly: 'Mensuel',
        days_left: 'Jours restants:',
        renewal_starts_at: ' (Renouvellement à partir de : {{endData}})',
        examinations_left: 'Examens restants:',
        invitations_left: 'Invitations restantes:',
        go_to_contact_page: 'Aller sur la page de contact',
        please_contact_account_holder1: 'Veuillez contacter votre',
        please_contact_account_holder2: 'Propriétaire du compte',
        please_contact_account_holder3: " pour obtenir de l'aide.",
        b2c: {
          buy_examinations_or_upgrade_plan1: 'Vous pouvez également',
          buy_examinations_or_upgrade_plan2:
            'acheter des examens supplémentaires',
          buy_examinations_or_upgrade_plan3: ' ou ',
          buy_examinations_or_upgrade_plan4: 'mettre à niveau votre forfait',
          upgrade_plan1: 'Vous pouvez',
          upgrade_plan2: 'mettre à niveau votre forfait',
          buy_plan: 'Acheter {{name}}',
          upgrade_to: 'Passer au forfait {{name}} avec {{count}} examens/mois',
          renew_subscription: "Renouveler l'abonnement",
          upgrade_to_custom_plan: 'Passer à un forfait personnalisé',
        },
      },
      onboarding_tour: {
        cancel: 'Annuler (Je connais déjà Altris AI)',
        submit: 'Faire une visite guidée',
      },
      renew_subscribe_reject: {
        please_contact_account_holder1: 'Veuillez contacter votre',
        please_contact_account_holder2: 'Propriétaire du compte',
        please_contact_account_holder3: " pour obtenir de l'aide.",
        not_renew_subscription1:
          "Si vous ne renouvelez pas l'abonnement, veuillez envoyer une demande pour",
        not_renew_subscription2: 'télécharger vos données',
        not_renew_subscription3:
          "afin de ne pas les perdre. Pour toute question, n'hésitez pas à nous contacter par email : ",
        not_renew_subscription4: 'support@altris.ai',
      },
      renew_subscribe_warning: {
        please_contact_account_holder1: 'Veuillez contacter votre',
        please_contact_account_holder2: 'Propriétaire du compte',
        please_contact_account_holder3: " pour obtenir de l'aide.",
        not_renew_subscription1:
          "Si vous ne renouvelez pas l'abonnement, veuillez envoyer une demande pour",
        not_renew_subscription2: 'télécharger vos données',
        not_renew_subscription3:
          "afin de ne pas les perdre. Pour toute question, n'hésitez pas à nous contacter par email : ",
        not_renew_subscription4: 'support@altris.ai',
      },
      resend_team_member: {
        cancel: 'Annuler',
        send_the_invitation: "Envoyer l'invitation",
      },
      select_layers: {
        layers_grayscale: 'Couches en nuances de gris',
        layers_grayscale_description:
          "Votre scan à l'intérieur du rapport sera gris.",
        confirm: 'Confirmer',
      },
      subscribe_plan: {
        subscription_update: "Mise à jour de l'abonnement",
        are_you_sure_subscribe_free_plan:
          'Êtes-vous sûr de vouloir vous abonner à un forfait gratuit ?',
        no: 'Non',
        yes: 'Oui',
        subscribe_with: "S'abonner",
        with_5_free_days: 'avec 5 jours gratuits',
        plan_charge:
          'Des frais {{interval}} vous seront facturés {{symbol}}{{price}}{{additional_item}}',
        monthly_plan_description:
          "*La remise n'est valable que pour les six premiers {{interval}} de l'abonnement",
        daily_plan_description:
          "*La remise n'est valable que pour le premier {{interval}} de l'abonnement",
      },
      survey_unsubscribe: {
        reason: 'Raison',
        leave_your_feedback: 'Laissez votre avis',
        submit: 'Soumettre',
      },
      change_single_scan_tab: {
        dont_show_hint:
          "Ne pas afficher d'indication à chaque fois que j'ouvre l'onglet Scan unique (cette option peut être modifiée dans les paramètres)",
        later: 'Plus tard',
        open_pathologies_detection_tab:
          "Ouvrir l'onglet Détection des pathologies",
      },
      trial_period_end: {
        title: "Période d'essai expirée",
        subtitle:
          "Votre période d'essai a expiré le <0>{{date}}</0>. Pour discuter de l'achat de la version complète du produit ou pour plus d'informations, veuillez contacter notre <1>équipe de vente</1>.",
        button: "J'ai pris connaissance",
      },
      update_exam: {
        patient_id: 'Identifiant patient',
        width: 'Largeur',
        scan_width: 'Largeur du scan',
        right: 'Droite',
        left: 'Gauche',
        scan_width_message:
          "Assurez-vous d'avoir sélectionné la bonne largeur de scan car elle affecte les mesures de l'examen",
        eye: 'Oeil',
        type: 'Type',
        examination_date: "Date d'examen",
        notes_label: 'Notes',
        example_short: 'Ex',
        update_examination: "Mettre à jour l'examen",
      },
      update_patient: {
        patient_id_label: 'Identifiant patient',
        example_short: 'Ex',
        first_name_label: 'Prénom du patient',
        last_name_label: 'Nom du patient',
        birth_date_label: 'Date de naissance du patient',
        gender_label: 'Sexe',
        gender_placeholder: 'Choisir...',
        notes_label: 'Notes',
        add_examination: 'Ajouter un examen',
        update_patient: 'Mettre à jour le patient',
        male: 'Homme',
        female: 'Femme',
        other: 'Autre',
      },
    },
    no_pathology: {
      title: 'Tout est bon !',
      subtitle: 'Aucune pathologie détectée',
    },
    opacity_changer: {
      opacity: 'Opacité',
    },
    open_exam_button: {
      text: 'Ouvrir cet examen',
    },
    pathologies_progression: {
      title: 'Progression des pathologies',
      add_another_examination:
        'Ajouter un autre examen pour examiner la progression des pathologies',
      no_pathologies:
        "Aucune option pour afficher la progression des pathologies car aucune pathologie n'a été détectée",
      selected_pathology: 'Pathologie sélectionnée',
      volume: 'Volume',
      units_in_etdrs: 'Unités en ETDRS',
      b_scan_area: 'Zone B-Scan',
      en_face_area: 'Zone En Face',
      total_change: 'Changement total',
      search_pathologies: 'Rechercher des pathologies',
      number_of_pathological: 'Nombre de zones pathologiques : {{number}}',
    },
    pay_by_stripe: {
      add_new_card: 'ou ajouter une nouvelle carte de crédit/débit',
      pay_via: 'Payer par carte de crédit/débit',
    },
    payment_info: {
      no_payments: "Il n'y a pas encore de paiements",
      you_can_subscribe:
        'Vous pouvez vous abonner au forfait en cliquant sur le bouton ci-dessous',
      subscribe_plan: 'Souscrire au forfait',
      description: 'Description',
      date: 'Date',
      time: 'Heure',
      charged: 'Facturé',
      status: 'Statut',
    },
    range_filter: {
      left: 'Gauche',
      right: 'Droite',
      horizontal: 'Horizontal',
    },
    referral_score: {
      title: "Score d'urgence de référence",
      good_message: 'Aucune recommandation requise',
      bad_message: 'Direction urgente nécessaire',
    },
    scan_description: {
      title: "Description de l'analyse OCT par Altris AI",
      left: 'Gauche',
      right: 'Droite',
      eye: 'Oeil',
      average_rnfl_rpe:
        'Épaisseur moyenne RNFL-RPE dans un anneau fovéal central de 1 mm de diamètre',
      following_pathological_signs:
        'Les signes pathologiques suivants ont été visualisés',
      changes_could_be_classified_as:
        'Ces modifications peuvent être classées comme',
      edit: 'Modifier',
      copy: 'Copier',
      show_more: 'Afficher plus',
      hide: 'Masquer',
    },
    scan_overlay: {
      length: 'Longueur',
    },
    segmentation_table: {
      low_confidence: 'Faible confiance',
      low_confidence_description:
        'Altris AI a détecté cette classification avec une faible confiance.',
      no_classification: 'Aucune classification pour ce scan.',
      banner_tooltip:
        'Seules les maladies couvertes par votre forfait sont affichées. Cependant, plusieurs autres pathologies ont été identifiées. Si vous voulez les mettre en évidence, veuillez mettre à niveau votre forfait.',
      more_pathologies_found:
        "Il existe d'autres pathologies que votre forfait ne couvre pas. Pour les examiner, mettez à niveau votre forfait.",
      upgrade_plan: 'Mettre à niveau mon forfait',
    },
    severity_level: {
      no_severity: "Aucune sévérité dans l'examen",
      no_severity_plural: 'Aucune sévérité dans les examens',
      yellow_severity_scan: "L'examen comprend un scan de sévérité jaune",
      yellow_severity_scan_plural: 'Scan de sévérité jaune dans les examens',
      red_severity_scan: "L'examen comprend un scan de sévérité rouge",
      red_severity_scan_plural: 'Scan de sévérité rouge dans les examens',
    },
    severity_select: {
      sort: 'Trier',
      sort_by: 'Trier par',
      high_low: 'Sévérité : Élevé - Faible',
      low_high: 'Sévérité : Faible - Élevé',
      in_order: "Dans l'ordre",
    },
    single_scan: {
      add_to_report: 'Ajouter au rapport',
      review_other_scans: "Examiner d'autres scans",
      scan_quality_tooltip1:
        'Notez que seuls les scans de qualité 4 ou supérieure peuvent être considérés comme des scans de qualité.',
      scan_quality_tooltip2:
        'Altris AI est toujours capable de les classifier et de les segmenter, mais les résultats peuvent être inexacts.',
      central_scan: 'Scan central',
      severity: 'Sévérité',
      severity_tooltip:
        'Les scans sont marqués en fonction de la gravité des signes pathologiques. Vert - rétine normale, jaune - sévérité modérée et rouge - sévère.',
      yellow: 'jaune',
      red: 'rouge',
      green: 'vert',
      draw_a_line: 'Tracer une ligne',
      draw_a_line_info:
        'Appuyez sur le bouton gauche de la souris et faites glisser',
      segmentations: 'Segmentations',
      highlight: 'Surligner',
    },
    smart_report: {
      average_thickness: 'Épaisseur moyenne 1/3/6 mm, μm',
      gcc_thickness: 'Épaisseur GCC',
      both_eyes_view: 'Vue des deux yeux',
      thickness_comparison: "Comparaison d'épaisseur",
      retina_thickness_difference: "Différence d'épaisseur de la rétine",
      etdrs_calculations: 'Calculs ETDRS',
      from: 'De',
      to: 'À',
      etdrs_titles: {
        average_thickness: 'Épaisseur moyenne',
        single_layer_thickness: 'Épaisseur monocouche',
        multiple_layer_thickness: 'Épaisseur multicouche',
        gcc_thickness: 'Épaisseur GCC',
      },
      glaucoma: 'Glaucome',
      auto: 'Auto',
      auto_measurements: 'Mesures automatiques',
      manual: 'Manuel',
      manual_measurements: 'Mesures manuelles',
      segmentation_area_title:
        'Zone de segmentation | Scan : {{number}}/{{total}}',
      segmentation_volume_title:
        'Volume de segmentation | Scan : {{number}}/{{total}}',
      retina_layers: 'Couches de la rétine',
      segmentation: 'Segmentation',
      classification: 'Classification',
      single_scan: 'Scan unique',
      right: 'Droite',
      left: 'Gauche',
      report_results: 'Résultats du rapport',
      clinic: 'Nom de la clinique',
      clinic_email: 'Email de la clinique',
      doctor: 'Médecin',
      patient_name: 'Nom du patient',
      birth_day: 'Date de naissance',
      gender: 'Sexe',
      eye: 'Oeil',
      exam_date: "Date d'examen",
      total_scans: 'Nombre total de scans',
      pathology_progression: 'Progression de la pathologie',
      selected_pathology: 'Pathologie sélectionnée :',
      en_face_area: 'Zone en face',
      b_scan_area: 'Zone de balayage B',
      volume: 'Volume',
      total_change: 'Changement total',
    },
    spinner_svg: {
      retry: 'Réessayer',
    },
    subscription_conditions: {
      back: 'Retour',
      detection: 'Détection de signes pathologiques avec IA (Segmentation)',
      detection_and_visualization:
        'Détection et visualisation des signes pathologiques avec IA (Segmentation)',
      view_all_pathological_signs: 'Voir tous les signes pathologiques',
    },
    subscription_plan_card: {
      need_better_solution:
        "Besoin d'une meilleure solution pour votre clinique ?",
      personal_assistant: 'Assistant personnel',
      unlimited_examinations: 'Examens illimités',
      unlimited_patients: 'Patients illimités',
      separate_accounts_for_doctors: 'Comptes séparés pour les médecins',
      onboarding_trainings_for_the_whole_team:
        "Formations d'intégration pour toute l'équipe",
      platform_customization:
        'Personnalisation de la plateforme selon vos besoins et plus encore.',
      contact: 'Contact',
      month: 'Mois',
      you_saving: 'Vous économisez',
      starting_from: 'À partir de',
      details: 'Détails',
      current_plan: 'Forfait actuel',
      active_till: "Actif jusqu'à",
      subscribe: "S'abonner",
      days_free_trial: "{{number}} jours d'essai gratuit",
      best_choice: 'Meilleur choix',
      monthly_description:
        "*La remise n'est valable que pour les six premiers {{pricingType}} de l'abonnement",
      daily_description:
        "*La remise n'est valable que pour le premier {{pricingType}} de l'abonnement",
      unlimited: 'Illimité',
      examinations_for_ai_screening:
        'Examens pour SCRÉENING IA, SEGMENTATION DES COUCHES IA',
      pathological_signs_detection:
        'Détection et visualisation des signes pathologiques avec IA',
      try_for_free: 'Essayer gratuitement',
      month_small: 'mois',
      year_small: 'an',
    },
    subscription_plans: {
      subscription: 'Abonnement',
      price: 'Prix',
      examinations_available: 'Examens disponibles',
      detect: 'Détecter',
      retina_conditions: 'Affections de la rétine',
      tooltip_text:
        "Cette fonctionnalité est actuellement en développement et apparaîtra sur la plateforme lorsqu'elle sera prête",
      services: 'Services',
      service: 'Service',
    },
    team_member_list: {
      doctors: 'Docteurs',
      email: 'Email',
      status: 'Statut',
      access_level: "Niveau d'accès",
      examination_created: 'Examen créé',
      actions: 'Actions',
      no_doctors: "Aucun médecin n'a encore été invité",
      you_can_add_team_member:
        "Vous pouvez ajouter un nouveau membre d'équipe en appuyant sur",
      invite_doctor: 'Inviter un médecin',
      me: 'MOI',
      no_actions: 'Aucune action',
      resend_invitation: "Renvoyer l'invitation",
      delete: 'Supprimer',
      active: 'ACTIF',
      pending: 'EN ATTENTE',
      invite_expired: 'INVITATION EXPIRÉE',
    },
    titled_switcher: {
      on: 'ON',
      off: 'OFF',
    },
    upload_image: {
      upload_photo: 'Téléverser une photo',
      info: 'Format : {{formats}}. La taille maximale du fichier est de {{size}}.',
    },
    welcome: {
      title: 'Interprétez facilement les OCT avec la plateforme Altris AI',
      subtitle1:
        'La plateforme Altris AI ne fonctionne que sur Windows ou Mac (PAS une application mobile).',
      subtitle2:
        "Cependant, l'inscription peut se faire via votre téléphone portable",
      list_item_title1:
        'Différenciation des b-scans pathologiques et non pathologiques en 1 minute',
      list_item_title2:
        'Détection de plus de 70 pathologies rétiniennes, y compris les pathologies rares',
      list_item_title3: "Analyse de l'épaisseur des couches rétiniennes",
      how_it_works: 'Comment ça fonctionne ?',
      description1:
        'Altris AI adhère pleinement au RGPD, garantissant la conformité. Toutes les données des utilisateurs sont cryptées et inaccessibles aux tiers. Nous détenons un certificat CE et une autorisation FDA 510, affirmant notre engagement envers les normes réglementaires.',
      description2:
        "La plateforme a été créée par une équipe d'experts de la rétine sous la supervision du directeur médical, docteur en ophtalmologie.",
      description3:
        'Altris AI est présenté dans Ophthalmology Times, Eyewire, Eyes on Eyecare, The Ophthalmologist et est soutenu par la communauté des soins oculaires.',
      features_item_title1: 'Format',
      features_item_title2: 'Communication',
      features_item_title3: 'Intégration',
      features_item_text1:
        'Le système fonctionne avec tous les formats de données, tels que DICOM, jpg et png, rendant le système de gestion des images ophtalmiques plus sûr et plus efficace.',
      features_item_text2:
        'Les spécialistes des soins oculaires peuvent partager des images au sein du système, en discuter en laissant des commentaires et travailler ensemble sur les mêmes images.',
      features_item_text3:
        "Le système peut également être intégré au système DME ou fonctionner de manière autonome en tant qu'application web.",
      disclaimer:
        "Avertissement : Altris AI est disponible uniquement en tant que logiciel de recherche aux États-Unis. L'autorisation FDA est en cours.",
      i_acknowledged: "J'ai reconnu",
      learn_more: 'En savoir plus',
      back: 'Retour',
      next: 'Suivant',
      slide_one: {
        title: 'Bienvenue sur Altris AI',
        subtitle:
          'Analysez les examens OCT avec Altris AI. Gagnez du temps et ne manquez jamais les pathologies rares, mineures ou précoces',
        desktop_available:
          'La plateforme Altris AI ne fonctionne que sur Windows ou Mac. Pas une application mobile.',
        registration_with_phone:
          "Cependant, l'inscription peut se faire via votre téléphone portable",
        description:
          "Entièrement conforme au RGPD, les données sont cryptées et inaccessibles aux tiers. Nous avons un certificat CE et l'autorisation FDA est en cours.",
      },
      slide_two: {
        title: 'Gravité des scans',
        subtitle:
          "Analysez la gravité de jusqu'à 512 scans pathologiques en une minute",
        low_severity: 'Faible sévérité',
        medium_severity: 'Sévérité moyenne',
        high_severity: 'Haute sévérité',
        green: 'vert',
        yellow: 'jaune',
        red: 'rouge',
        description:
          "Pas besoin de perdre du temps à revoir tous les scans pour trouver la zone d'intérêt",
      },
      slide_three: {
        title: 'Détection et visualisation des pathologies',
        subtitle:
          "Altria AI détecte plus de 70 affections rétiniennes pour chaque scan d'un examen",
        segmentation: 'Segmentation',
        classification: 'Classification',
      },
      slide_four: {
        title:
          'Et bien plus encore pour tirer le meilleur parti de la plateforme',
        item_header1: 'Segmentation des couches rétiniennes',
        item_header2: "Carte d'épaisseur EDTRS et rétine",
        item_header3: "Score d'urgence des références",
        item_header4: 'Mesures automatiques des pathologies',
        item_header5: 'Zone de segmentation',
        item_header6: 'Volume de segmentation',
        item_header7: 'Rapports intelligents',
        item_text2:
          "Épaisseur moyenne 1/3/6 mm et carte d'épaisseur de la rétine",
        item_text3:
          'Altris AI suggère un score d\'urgence des références en fonction des signes pathologiques découverts. Il va de "Pas de référence nécessaire" à "Référence urgente nécessaire"',
        item_text4:
          'Altris AI suggère un score d\'urgence des références en fonction des signes pathologiques découverts. Il va de "Pas de référence nécessaire" à "Référence urgente nécessaire"',
        item_text5:
          'Altris AI calcule automatiquement la surface de toutes les segmentations',
        item_text6:
          'Altris AI calcule automatiquement la surface de toutes les segmentations',
      },
    },
  },
  constants: {
    severity_filter_options: {
      all: 'tous',
      green: 'vert',
      yellow: 'jaune',
      red: 'rouge',
    },
  },
  modals: {
    glaucoma_disclaimer:
      "Veuillez noter que l'analyse du risque de glaucome ne fonctionne que sur les examens d'OCT où la pathologie ne modifie pas l'épaisseur du complexe des cellules ganglionnaires",
    sign_out: {
      title: 'Vous essayez de vous déconnecter',
      subtitle: 'Êtes-vous sûr ?',
      yes: 'Oui',
      cancel: 'Annuler',
    },
    add_patient: {
      title: 'Ajouter un nouveau patient',
      subtitle:
        'Remplissez le formulaire ci-dessous pour ajouter une nouvelle fiche patient à la liste',
    },
    add_examination: 'Ajouter un nouvel examen',
    add_eye_examination: {
      title: 'Ajouter un examen ophtalmologique',
      subtitle:
        'Remplissez le formulaire ci-dessous pour ajouter un nouvel examen à la liste',
    },
    invite_team_member: {
      title: 'Inviter un médecin',
      subtitle:
        'Remplissez le formulaire ci-dessous pour ajouter un nouveau médecin à la liste',
    },
    delete_team_member: {
      title: 'Supprimer',
      subtitle: 'Voulez-vous vraiment supprimer ce compte ?',
    },
    reinvite_team_member: {
      title: "Renvoyer l'invitation",
      subtitle: "Voulez-vous vraiment renvoyer l'invitation à ce compte ?",
    },
    delete_patient: {
      title: 'Supprimer',
      subtitle: 'Voulez-vous vraiment supprimer ce patient ?',
    },
    update_patient: {
      title: 'Modifier le patient',
      subtitle:
        'Remplissez le formulaire ci-dessous pour modifier la fiche patient dans la liste',
    },
    unsubscribe: {
      title: 'Vous essayez de vous désabonner',
      subtitle: 'Êtes-vous sûr ?',
      yes: 'Oui',
      cancel: 'Annuler',
    },
    subscribe_fake: {
      title: "Mise à jour de l'abonnement",
      subtitle: "Vous serez abonné à l'offre gratuite",
      yes: 'Oui',
      cancel: 'Annuler',
    },
    renew_subscribe_warning: {
      title1: "Renouveler l'abonnement",
      subtitle1:
        "Malheureusement, nous n'avons pas reçu le paiement. L'abonnement que vous utilisez actuellement est arrivé à expiration. Veuillez passer à un nouvel abonnement Altris AI ou votre accès à Altris AI sera supprimé",
      button_text1: "Renouveler l'abonnement",
      title2: "Renouveler l'abonnement",
      subtitle2:
        "Malheureusement, nous n'avons pas reçu le paiement et votre forfait a expiré. Veuillez renouveler votre abonnement ou votre accès à Altris AI sera supprimé",
      button_text2: 'Renouveler',
    },
    renew_subscribe_warning_b2b: {
      title: "Renouveler l'abonnement",
      subtitle:
        "Malheureusement, nous n'avons pas reçu le paiement et votre forfait a expiré. Veuillez renouveler votre abonnement ou votre accès à Altris AI sera supprimé",
      button_text: 'Aller à la page Contact',
    },
    renew_subscribe_reject: {
      title1: "Renouveler l'abonnement",
      subtitle1:
        "Malheureusement, nous n'avons pas reçu le paiement. L'abonnement que vous utilisez actuellement est arrivé à expiration. Veuillez passer à un nouvel abonnement. Nous allons stocker vos données jusqu'au {{date}}. Après cela, nous devrons les supprimer.",
      button_text1: "Renouveler l'abonnement",
      title2: "Renouveler l'abonnement",
      subtitle2:
        "Malheureusement, nous n'avons pas reçu le paiement et votre forfait a expiré. Veuillez renouveler votre abonnement. Nous allons stocker vos données jusqu'au {{date}}. Après cela, nous devrons les supprimer.",
      button_text2: 'Renouveler',
    },
    renew_subscribe_reject_b2b: {
      title: "Renouveler l'abonnement",
      subtitle:
        "Malheureusement, nous n'avons pas reçu le paiement et votre forfait a expiré. Veuillez renouveler votre abonnement. Nous allons stocker vos données jusqu'au {{date}}. Après cela, nous devrons les supprimer.",
      button_text: 'Aller sur la page Contactez-nous',
    },
    analyze_scans: {
      title: "Analyser d'autres scans",
      subtitle: 'Cochez les scans que vous souhaitez analyser.',
    },
    select_retina_layers:
      'Sélectionner les couches rétiniennes à ajouter au rapport',
    delete_examination: {
      title: 'Supprimer',
      subtitle: 'Voulez-vous vraiment supprimer cet examen ?',
    },
    update_examination: {
      title: 'Modifier',
      subtitle:
        "Remplissez le formulaire ci-dessous pour modifier la fiche d'examen dans la liste",
    },
    onboarding_tour: {
      title: 'Bienvenue sur Altris AI',
      subtitle: 'À quoi pouvez-vous vous attendre chez Altris AI ?',
    },
    json_exam_export: {
      title: 'Exporter les données de votre examen',
      subtitle: 'Choisissez les données que vous souhaitez exporter.',
    },
    unsubscribe_survey: {
      title: "Qu'est-ce qui s'est mal passé ?",
      subtitle:
        "Nous avons remarqué que vous vous êtes désabonné d'Altris Pro. Veuillez nous dire pourquoi ?",
    },
    booking_meeting: {
      title: 'Nous pouvons arranger les choses !',
      subtitle:
        "Réservez une courte réunion gratuite avec notre expert, qui possède 15 ans d'expérience en OCT et peut répondre à toutes vos questions concernant Altris AI.",
    },
    download_dicom: {
      title: 'Créez votre premier patient et votre premier examen !',
      subtitle:
        'Pour créer un examen, utilisez un fichier DICOM ou une image exportée de votre OCT.',
    },
    download_dicom_success: {
      title: 'Téléchargement DICOM lancé !',
      subtitle:
        "Patientez jusqu'à la fin... Une fois le téléchargement terminé, cliquez sur « Suivant » pour créer un nouveau patient. Ensuite, créez un nouvel examen et téléchargez le fichier DICOM pour qu'il soit analysé par Altris AI.",
    },
    change_single_scan_tab:
      "N'oubliez pas de consulter la détection des pathologies (alimentée par l'IA !)",
  },
  validation: {
    required: 'Requis',
    data_invalid: 'Les données saisies sont invalides',
    email_invalid: 'Adresse e-mail invalide',
    letters_numbers_spaces_hyphens:
      'Seuls les lettres (en, ua, ru), les chiffres, les espaces, les points et les tirets (« - ») sont autorisés dans ce champ',
    min_chars: 'Doit contenir au moins {{number}} caractères',
    max_chars: 'Doit contenir au maximum {{number}} caractères',
    password_latin_lowercase:
      'Le mot de passe doit contenir une lettre minuscule latine',
    password_latin_uppercase:
      'Le mot de passe doit contenir une lettre majuscule latine',
    password_number: 'Le mot de passe doit contenir un chiffre',
    password_match: 'Les mots de passe doivent correspondre',
  },
  notifications: {
    you_can_add_only_2_dicom: 'Vous pouvez ajouter seulement 2 fichiers DICOM',
    your_data_was_added_successfully:
      'Vos données ont été ajoutées avec succès',
    you_cant_add_file_of_this_format:
      'Vous ne pouvez pas ajouter un fichier de ce format',
    patient_was_added_successfully: 'Patient ajouté avec succès',
    characters_limit_exceeded: 'Limite de {{number}} caractères dépassée',
    download_will_started_automatically:
      'Le téléchargement démarrera automatiquement lorsque toutes les données seront prêtes.',
    please_enter_your_card: 'Veuillez entrer votre carte',
    image_size_changed_successfully: "Taille de l'image modifiée avec succès",
    description_copied: 'Description copiée',
    description_not_copied: 'Description non copiée',
    you_already_add_this_scan_form_segmentation_report:
      'Vous avez déjà ajouté ce scan à partir du rapport de segmentation',
    scan_was_successfully_added_to_the_report:
      'Scan ajouté avec succès au rapport',
    image_was_not_uploaded:
      "L'image n'a pas été téléchargée car sa taille est trop importante. Veuillez téléverser une image de moins de 3 Mo",
    resent_confirmation_email: 'E-mail de confirmation renvoyé',
    something_went_wrong: "Une erreur s'est produite",
    examination_etdrs_calculation_finished:
      'Examen : {{id}}. Calcul ETDRS terminé',
    examination_etdrs_calculation_failed:
      'Examen : {{id}}. Échec du calcul ETDRS',
    segmentation_layers_calculation_finished:
      'Examen : {{id}}. Calcul des couches de segmentation terminé',
    segmentation_layers_calculation_failed:
      'Examen : {{id}}. Échec du calcul des couches de segmentation',
    measurements_calculation_finished:
      'Examen : {{id}}. Calcul des mesures terminé',
    measurements_calculation_failed:
      'Examen : {{id}}. Échec du calcul des mesures',
    measurements_manual_updated: 'Mesures mises à jour manuellement',
    link_is_copied: 'Lien copié',
    link_not_copied: 'Lien non copié',
    your_request_sent_successfully: 'Votre demande a été envoyée avec succès',
    you_dont_have_available_pathology_classes:
      "Vous n'avez pas de classes de pathologie disponibles",
    examination_created_successfully: 'Examen créé avec succès',
    examination_deleted_successfully: 'Examen supprimé avec succès',
    examination_failed: 'Votre examen a échoué',
    examination_available: 'Votre examen est disponible',
    examination_updated_successfully: 'Examen mis à jour avec succès',
    scan_added_to_favorites: 'Scan ajouté aux favoris',
    scan_removed_from_favorites: 'Scan retiré des favoris',
    measurements_reset_successfully:
      'Les mesures de ce scan ont été réinitialisées',
    measurements_updated: 'Mesures mises à jour',
    you_tried_upload_same_eye: 'Vous avez essayé de téléverser le même œil',
    you_tried_upload_opposite_eye:
      "Vous avez essayé de téléverser l'œil opposé",
    limit_examination_is_reached: "La limite d'examens est atteinte",
    examinations_have_not_enough_data:
      'Votre examen ne dispose pas de données suffisantes',
    patient_deleted_successfully: 'Patient supprimé avec succès',
    patient_updated_successfully:
      'Les informations du patient ont été mises à jour avec succès',
    you_successfully_buy_additional_examination:
      'Vous avez acheté un examen supplémentaire avec succès',
    settings_saved: 'Vos paramètres ont été enregistrés',
    subscription_plan_updated: "Votre forfait d'abonnement a été mis à jour",
    subscription_canceled: 'Votre abonnement a été annulé',
    doctor_invited: 'Docteur invité',
    password_changed: 'Votre mot de passe a été changé',
    backup_request_created:
      'La demande a été créée, vous recevrez un email lorsque vos données seront prêtes',
    data_deleted: 'Vos données ont été supprimées',
    attempts_over: 'Vos tentatives sont épuisées, veuillez réessayer plus tard',
    company_profile_updated: "Votre profil d'entreprise a été mis à jour",
    profile_updated: 'Votre profil a été mis à jour avec succès',
    session_expired: 'Votre session a expiré, veuillez vous connecter',
    site_under_maintenance:
      'Veuillez noter que le site est actuellement en maintenance régulière, et que tous les services peuvent fonctionner temporairement de manière inexacte',
    progression_settings_updated:
      'Les paramètres de progression ont été mis à jour avec succès.',
  },
  user_popup_menu_links: {
    subscription: 'Abonnement',
    contact_support: 'Contacter le support',
  },
  profile_links: {
    subscriptions: 'Abonnements',
    team_members: "Membres de l'équipe",
    contact_us: 'Nous contacter',
    documentation: 'Documentation',
    join_altris_education: 'Rejoindre Altris Education',
    settings: 'Paramètres',
    onboarding: 'Onboarding',
    storybook: 'Storybook',
  },
  onboarding_links: {
    complete_onboarding_again: "Compléter à nouveau l'onboarding",
    download_test_dicom: 'Télécharger un DICOM de test',
    create_patient: 'Créer un patient',
    create_examintaion: 'Créer un examen',
    header: 'En-tête',
    single_scan: 'Scan unique',
    pathologies_detection: 'Détection de pathologies',
    measurements: 'Mesures',
    all_scans: 'Tous les scans',
    both_eyes: 'Les deux yeux',
    comparison: 'Comparaison',
    progression: 'Progression',
    report: 'Rapport',
  },
  onboarding: {
    create_examination: {
      add_new_examination: 'Ajouter un nouvel examen',
      next: 'Suivant',
      examination_list: 'Liste des examens',
      step_1_text_1: 'Cliquez',
      step_1_text_2: 'pour ajouter un nouvel examen OCT à votre patient',
      step_1_text_3: "Cliquez pour passer à l'étape suivante",
      step_2_text_1: 'La première étape serait de',
      step_2_text_2: 'Sélectionner le patient',
      step_3_text:
        'Ou sélectionnez pour créer un examen ET un patient en utilisant DICOM',
      step_4_text_1: "Qu'est-ce que le DICOM?",
      step_4_text_2: 'Je suis familier avec le DICOM',
      step_4_text_3: 'Vous pouvez créer un examen en téléchargeant',
      step_4_text_4: 'DICOM (préféré), un dossier DICOM',
      step_4_text_5: 'ou des images',
      step_4_text_6:
        "Important! Assurez-vous de savoir ce qu'est le DICOM et comment l'exporter depuis votre OCT",
      step_5_text_1:
        'Faites glisser le DICOM dans la fenêtre pour le sélectionner à télécharger',
      step_5_text_2: "Téléchargez le DICOM pour passer à l'étape suivante",
      step_6_text_1:
        'Après avoir ajouté le fichier DICOM/Images, vous pouvez cliquer sur',
      step_6_text_2: "Cliquez pour passer à l'étape suivante",
      step_7_text:
        "Et maintenant l'examen a été ajouté et sera accessible après que le système l'ait analysé. Vous pouvez passer en revue la liste de tous les examens du patient après avoir cliqué dessus.",
      step_8_text_1: "Voyons à quoi ressemble l'examen",
      step_8_text_2: "Cliquez pour passer à l'étape suivante",
      step_dicom_text_1: "J'ai pris connaissance de",
      step_dicom_text_2: "Qu'est-ce que le fichier DICOM/DCOM/.dcm?",
      step_dicom_text_3: 'DICOM/DCOM/.dcm',
      step_dicom_text_4:
        'est un format de fichier, que vous pouvez sélectionner',
      step_dicom_text_5: "pour exporter l'examen",
      step_dicom_text_6: 'depuis votre OCT.',
      step_dicom_text_7: 'Il inclut:',
      step_dicom_text_8: "Tous les scans de l'examen OCT",
      step_dicom_text_9:
        'Vous pouvez sélectionner combien de scans vous souhaitez exporter en DICOM sur votre OCT',
      step_dicom_text_10: "Données d'examen nécessaires",
      step_dicom_text_11:
        "Nous suggérons d'utiliser DICOM au lieu d'images. Comme les images ne contiennent pas toutes les données d'examen nécessaires, cela fait que plusieurs composants principaux du système ne pourront pas représenter des données pratiques",
      step_dicom_text_12:
        "Qu'est-ce qui ne sera pas disponible si j'utilise des images?",
      step_dicom_text_13: 'Données nécessaires sur le patient',
      step_dicom_text_14:
        "Si vous utilisez DICOM au lieu d'une image, nous pouvons extraire toutes les données nécessaires sur le patient et les créer automatiquement",
      step_dicom_text_15:
        'Comment puis-je créer un patient en utilisant DICOM?',
      step_dicom_text_16: 'Dossier DICOM',
      step_dicom_text_17:
        "Si votre OCT ne peut exporter les données que dans un dossier (par ex. Heidelberg Engineering OCT), vous pouvez archiver ce dossier à l'aide de votre archivage standard et télécharger un fichier .zip lors de la création d'un examen. NE changez PAS la structure du dossier ou n'archivez aucun dossier séparé à l'intérieur du dossier principal. Le dossier doit être archivé entièrement.",
      step_dicom_text_18:
        'Comment exporter un dossier DICOM depuis un OCT Heidelberg Engineering?',
      step_dicom_text_19:
        'Comment puis-je exporter un fichier DICOM depuis mon OCT?',
      step_dicom_text_20: 'Comment exporter un DICOM depuis un OCT Nidek?',
      step_dicom_text_21: 'Comment exporter un DICOM depuis un OCT Topcon?',
      step_dicom_text_22: 'Comment exporter un DICOM depuis un OCT Optopol?',
      step_scans_text_1: 'Seuls les scans de qualité!',
      step_scans_text_2:
        "Veuillez vous assurer que les scans que vous téléchargez sont de qualité suffisante. Sinon, le résultat de l'analyse ne sera pas satisfaisant.",
    },
    create_patient: {
      add_new_patient: 'Ajouter un nouveau patient',
      next: 'Suivant',
      step_1_text_1: 'Commençons par ajouter un nouveau patient!',
      step_1_text_2: 'pour créer un nouveau patient',
      step_1_text_3: "Cliquez ici pour passer à l'étape suivante",
      step_2_text_1:
        'Vous pouvez immédiatement créer un patient et un examen en téléchargeant un fichier DICOM',
      step_2_text_2: 'Sélectionnez simplement la case à cocher!',
      step_3_text_1:
        'Si vous avez choisi de ne pas télécharger de patient à partir de DICOM, vous devez remplir toutes les informations sur le patient.',
      step_3_text_2:
        'Après que toutes les données du patient ont été remplies, cliquez sur',
      step_4_text_1:
        'Après avoir téléchargé DICOM ou rempli toutes les informations sur le patient, vous pouvez cliquer sur',
      step_4_text_2: "pour l'enregistrer.",
      step_4_text_3: "Cliquez pour passer à l'étape suivante",
      step_5_text_1: 'Liste des patients',
      step_5_text_2:
        'Et maintenant votre patient a été ajouté, vous pouvez donc créer un examen',
      step_5_text_3:
        "Remarque: Si vous avez téléchargé un patient à l'aide de DICOM, cette étape sera automatiquement ignorée",
    },
    create_report: {
      create_report: 'Créer un rapport',
      next: 'Suivant',
      step_1_text_1:
        "Après la découverte de l'examen, le médecin peut créer un",
      step_1_text_2: 'Rapport',
      step_1_text_3: "Cliquez pour passer à l'étape suivante",
      step_2_text_1: 'Étape 1',
      step_2_text_2:
        'Sélectionnez le scan que vous souhaitez afficher comme principal (dernière révision par défaut)',
      step_2_text_3: 'Étape 2',
      step_2_text_4:
        'Sélectionnez les composants que vous souhaitez inclure dans le rapport',
      step_2_text_5: 'Étape 3',
      step_2_text_6:
        'Sélectionnez un ou plusieurs scans à afficher pour la détection de pathologies',
      step_3_text_1: 'Imprimer ou Enregistrer au format PDF',
      step_3_text_2: 'Étape 4',
      step_3_text_3: 'Cliquez',
      step_3_text_4: 'Aperçu',
      step_3_text_5:
        "pour voir l'aperçu du rapport généré et l'imprimer/le sauvegarder en PDF",
      step_3_text_6: "Cliquez pour passer à l'étape suivante",
      step_4_text_1: 'Félicitations!',
      step_4_text_2: "Vous avez terminé l'onboarding!",
      step_4_text_3: "N'hésitez pas à créer votre premier patient!",
      step_4_text_4: 'Vous pouvez toujours revoir cette instruction dans',
      step_4_text_5: 'Paramètres',
    },
    general: {
      skip_onboarding: "Passer l'onboarding",
      are_you_sure_want_to_skip:
        "Êtes-vous sûr de vouloir passer l'onboarding?",
      continue_onboarding: "Continuer l'onboarding",
    },
    show_examination: {
      tab_menu: 'Menu des onglets',
      next: 'Suivant',
      all_scans_text_1: 'Continuons avec',
      all_scans_text_2: 'Tous les scans',
      all_scans_text_3: "Cliquez pour passer à l'étape suivante",
      all_scans_text_4: 'Tous les scans',
      all_scans_text_5:
        'Vous pouvez passer en revue tous les scans et les trier par niveau de gravité.',
      all_scans_text_6:
        "Vous pouvez également choisir de n'afficher que les scans qui ont été catégorisés comme",
      all_scans_text_7: 'vert',
      all_scans_text_8: 'jaune',
      all_scans_text_9: 'ou',
      all_scans_text_10: 'rouge',
      all_scans_text_11: 'Niveau de gravité.',
      both_eyes_text_1: 'Continuons avec',
      both_eyes_text_2: 'Les deux yeux',
      both_eyes_text_3: "Cliquez pour passer à l'étape suivante",
      both_eyes_text_4: 'Les deux yeux',
      both_eyes_text_5:
        "Vous pouvez ajouter le deuxième œil pour examiner l'image complète",
      comparison_text_1: 'Continuons avec',
      comparison_text_2: 'Comparaison',
      comparison_text_3: "Cliquez pour passer à l'étape suivante",
      comparison_text_4: 'Comparaison',
      comparison_text_5:
        "Ajoutez le même œil, diagnostiqué précédemment, pour le comparer avec l'examen actuel",
      measurements_text_1: 'Continuons avec',
      measurements_text_2: 'Mesures',
      measurements_text_3: "Cliquez pour passer à l'étape suivante",
      measurements_text_4: 'Mesures',
      measurements_text_5: 'La section Mesures permet aux médecins de:',
      measurements_text_6: 'Examiner',
      measurements_text_7: 'la hauteur',
      measurements_text_8: 'et',
      measurements_text_9: 'la longueur',
      measurements_text_10: 'des segmentations, mesurées automatiquement',
      measurements_text_11: 'Dessiner',
      measurements_text_12: "Des lignes pour calculer la zone d'intérêt",
      measurements_text_13: 'Examiner les zones de segmentation',
      measurements_text_14: 'Examiner les volumes de segmentation',
      measurements_text_15:
        'Vous pouvez passer en revue la hauteur et le poids de la segmentation détectée',
      measurements_text_16: 'Mesures automatiques',
      measurements_text_17:
        'Commencer les mesures et passer en revue la hauteur et la longueur de la segmentation',
      measurements_text_18:
        'Mettre en surbrillance ou désélectionner sur les scans',
      measurements_text_19: 'Modifier la ligne pour recalculer',
      measurements_text_20: 'Supprimer',
      measurements_text_21: 'Continuons avec',
      measurements_text_22: 'Mesures automatiques',
      measurements_text_23: 'Continuons avec',
      measurements_text_24: 'Mesure manuelle',
      measurements_text_25: "Cliquez pour passer à l'étape suivante",
      measurements_text_26: 'Continuons avec',
      measurements_text_27: 'Zone de segmentation',
      measurements_text_28: "Cliquez pour passer à l'étape suivante",
      measurements_text_29: 'Mesures manuelles',
      measurements_text_30:
        'Vous pouvez également dessiner une ligne pour calculer la distance entre deux points quelconques sur le scan.',
      measurements_text_31: 'Zone de segmentation',
      measurements_text_32: 'Passer en revue les zones segmentées:',
      measurements_text_33: 'Balayage B',
      measurements_text_34: 'En-face',
      measurements_text_35: 'Continuons avec',
      measurements_text_36: 'Volume de segmentation',
      measurements_text_37: "Cliquez pour passer à l'étape suivante",
      measurements_text_38: 'Mesures',
      measurements_text_39: 'Passer en revue le volume de segmentation:',
      pathologies_detection_text_1: 'Continuons avec',
      pathologies_detection_text_2: 'Détection des pathologies',
      pathologies_detection_text_3: "Cliquez pour passer à l'étape suivante",
      pathologies_detection_text_4: 'Segmentation et classification',
      pathologies_detection_text_5:
        'Passez en revue le dernier scan sélectionné et mettez en surbrillance ou désélectionnez',
      pathologies_detection_text_6: 'la segmentation',
      pathologies_detection_text_7: 'Segmentation et classification',
      pathologies_detection_text_8:
        'En fonction du niveau de gravité, Altris AI vous suggère un',
      pathologies_detection_text_9: "Score d'urgence de recommandation",
      pathologies_detection_text_10: 'Cette option peut être désactivée',
      pathologies_detection_text_11: 'Segmentation',
      pathologies_detection_text_12:
        'Altris AI analyse le scan et suggère toutes les',
      pathologies_detection_text_13: 'Segmentations',
      pathologies_detection_text_14: 'détectées sur le scan sélectionné.',
      pathologies_detection_text_15:
        'Vous pouvez les passer en revue sur le scan et les mettre en surbrillance/désélectionner',
      pathologies_detection_text_16: 'Passez en revue les autres scans',
      pathologies_detection_text_17: 'Vous pouvez',
      pathologies_detection_text_18: "Sélectionner d'autres scans",
      pathologies_detection_text_19: 'à afficher et',
      pathologies_detection_text_20: 'Ajouter au rapport',
      pathologies_detection_text_21: 'celui actuel',
      pathologies_detection_text_22: 'Classification',
      pathologies_detection_text_23:
        'Altris AI analyse le scan et suggère toutes les',
      pathologies_detection_text_24: 'Classifications',
      pathologies_detection_text_25: 'détectées sur le scan sélectionné',
      progression_text_1: 'Continuons avec',
      progression_text_2: 'Progression',
      progression_text_3: "Cliquez pour passer à l'étape suivante",
      progression_text_4: 'Progression',
      progression_text_5:
        "Ajoutez jusqu'à 8 examens pour suivre l'évolution des segmentations",
      single_scan_text_1: "Informations sur le patient/l'examen",
      single_scan_text_2:
        "En haut de l'écran, vous pouvez consulter les informations générales sur le patient et l'examen",
      single_scan_text_3:
        'Ci-dessous, vous pouvez voir le menu des onglets avec tous les composants du système',
      single_scan_text_4: 'Scan unique',
      single_scan_text_5: 'Sur le côté droit, vous pouvez voir :',
      single_scan_text_6: 'Image du scan',
      single_scan_text_7: 'Qualité du scan',
      single_scan_text_8: 'Numéro du scan',
      single_scan_text_9: 'Niveau de gravité du scan',
      single_scan_text_10: "Bouton d'échelle",
      single_scan_text_11: 'Bouton pour sélectionner le scan central',
      single_scan_text_12: 'Bouton pour marquer le scan comme "Sélectionné"',
      single_scan_text_13: 'Scan unique',
      single_scan_text_14: 'Sur le côté gauche, vous pouvez voir :',
      single_scan_text_15: 'Type de scan',
      single_scan_text_16:
        'Altris ne prend en charge que les scans horizontaux en B',
      single_scan_text_17: 'Œil',
      single_scan_text_18: 'Niveaux de gravité et navigation',
      single_scan_text_19:
        'Cliquez sur la zone sélectionnée pour examiner le scan ou faites glisser la ligne',
      single_scan_text_20: 'Filtrer par gravité',
      single_scan_text_21: 'Nombre de scans de chaque niveau de gravité',
      single_scan_text_22: 'Bouton pour sélectionner le scan central',
      single_scan_text_23: 'Scan unique',
      single_scan_text_24: 'Vous pouvez voir les',
      single_scan_text_25: 'Couches de la rétine',
      single_scan_text_26: 'segmentation',
      single_scan_text_27: 'pour le scan sélectionné.',
      single_scan_text_28: 'Vous pouvez',
      single_scan_text_29: 'Mettre en surbrillance',
      single_scan_text_30: 'et Désélectionner tout',
      single_scan_text_31: "Carte d'épaisseur de la rétine ETDRS",
      single_scan_text_32:
        "Sélectionnez la vue de la carte d'épaisseur de la rétine ou la vue standard",
      single_scan_text_33: 'Épaisseur moyenne de 1/3/6 mm',
      single_scan_text_34: 'Scan unique',
      single_scan_text_35: 'Épaisseur du GCC',
      single_scan_text_36: 'Épaisseur DE / À de la couche',
      single_scan_text_37: 'Épaisseur de la couche unique',
      single_scan_text_38: 'Commençons par',
      single_scan_text_39: 'Scan unique',
    },
  },
};

export default translation;
