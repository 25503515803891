import { ExportExamActions } from 'redux/constants/exportExam';
import { ExportExamActionTypes } from 'redux/types/examExport/examExport';
import { ExportExamSchema } from 'types/exportExam/exportExam';

const initialState: ExportExamSchema = {
  requesting: false,
  errors: undefined,
  jsonData: {
    segmentation: undefined,
  },
};

const exportExamReducer = (
  state = initialState,
  action: ExportExamActionTypes
) => {
  switch (action.type) {
    case ExportExamActions.EXAM_EXPORT_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };

    case ExportExamActions.EXAM_EXPORT_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: undefined,
        jsonData: {
          ...state.jsonData,
          segmentation: action.payload,
        },
      };

    // GET EXAMINATION
    case ExportExamActions.EXAM_EXPORT_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };

    default:
      return state;
  }
};

export default exportExamReducer;
