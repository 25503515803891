import { PATIENT } from './endpoints';

import { ICreatePatient, IFilterPatient, IUpdatePatient } from 'types/patients';
import fetchData from 'utils/fetchData';

export const createPatient = async (data: ICreatePatient) =>
  fetchData({
    method: 'post',
    to: PATIENT.PATIENT,
    data: data,
  });

export const getSinglePatient = async (id: number) =>
  fetchData({
    method: 'get',
    to: PATIENT.PATIENT_ID(id),
    data: {},
  });

export const getAllPatients = async () =>
  fetchData({
    method: 'get',
    to: PATIENT.GET_ALL_PATIENTS,
    data: {},
  });

export const filterPatients = async ({
  medic_ids,
  examinations_dates_range,
}: IFilterPatient) => {
  return fetchData({
    method: 'get',
    to: PATIENT.FILTER_PATIENTS(medic_ids, examinations_dates_range),
    data: {},
  });
};

export const updatePatient = async (id: number, data: IUpdatePatient) =>
  fetchData({
    method: 'put',
    to: PATIENT.PATIENT_ID(id),
    data: data,
  });

export const deletePatient = async (id: number) =>
  fetchData({
    method: 'delete',
    to: PATIENT.PATIENT_ID(id),
    data: {},
  });
