import { appSettingsTypes } from 'redux/constants/appSettings';
import { appSettingsActions, IAppSettingsState } from 'types/appSettings';

const initialState: IAppSettingsState = {
  requesting: false,
  maintenance_mode: false,
};

const appSettingsReducer = (
  state = initialState,
  action: appSettingsActions
): IAppSettingsState => {
  switch (action.type) {
    case appSettingsTypes.ON_GLOBAL_LOADER:
      return {
        ...state,
        requesting: true,
      };
    case appSettingsTypes.OFF_GLOBAL_LOADER:
      return {
        ...state,
        requesting: false,
      };
    case appSettingsTypes.ACTIVE_MAINTENANCE_MODE:
      return {
        ...state,
        maintenance_mode: true,
      };
    default:
      return state;
  }
};

export default appSettingsReducer;
