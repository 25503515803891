import { purchaseConstants } from 'redux/constants/purchase';

const initialState = {
  requesting: false,
  errors: null,
  data: {
    types: [],
    current_type: [],
  },
};

const purchaseItemReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    /* GET ALL PURCHASE TYPE */
    case purchaseConstants.GET_ALL_PURCHASE_TYPE_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case purchaseConstants.GET_ALL_PURCHASE_TYPE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          types: payload,
        },
        requesting: false,
        errors: null,
      };
    case purchaseConstants.GET_ALL_PURCHASE_TYPE_ERROR:
      return {
        ...state,
        requesting: false,
        errors: payload,
      };

    /* GET PURCHASE TYPE BY SLUG */
    case purchaseConstants.GET_PURCHASE_TYPE_BY_SLUG_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case purchaseConstants.GET_PURCHASE_TYPE_BY_SLUG_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          current_type: payload,
        },
        requesting: false,
        errors: null,
      };
    case purchaseConstants.GET_PURCHASE_TYPE_BY_SLUG_ERROR:
      return {
        ...state,
        requesting: false,
        errors: payload,
      };

    /* PURCHASE FOR EXISTING CARD */
    case purchaseConstants.PURCHASE_FOR_EXISTING_CARD_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case purchaseConstants.PURCHASE_FOR_EXISTING_CARD_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
      };
    case purchaseConstants.PURCHASE_FOR_EXISTING_CARD_ERROR:
      return {
        ...state,
        requesting: false,
        errors: payload,
      };

    /* PURCHASE FOR NEW CARD */
    case purchaseConstants.PURCHASE_FOR_NEW_CARD_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case purchaseConstants.PURCHASE_FOR_NEW_CARD_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
      };
    case purchaseConstants.PURCHASE_FOR_NEW_CARD_ERROR:
      return {
        ...state,
        requesting: false,
        errors: payload,
      };

    /* CONFIRM PURCHASE */
    case purchaseConstants.CONFIRM_PURCHASE_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case purchaseConstants.CONFIRM_PURCHASE_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
      };
    case purchaseConstants.CONFIRM_PURCHASE_ERROR:
      return {
        ...state,
        requesting: false,
        errors: payload,
      };

    default:
      return state;
  }
};

export default purchaseItemReducer;
