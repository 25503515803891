import { memo } from 'react';

import cn from 'classnames';

import { ISimpleMessageProps } from './SimpleMessage.types';

import Text from 'components/Text';

import styles from './SimpleMessage.module.scss';

const SimpleMessage = memo((props: ISimpleMessageProps) => {
  const { text, Icon, className } = props;

  return (
    <div className={cn(styles.info, className)}>
      {Icon && (
        <div className={styles['icon-wrapper']}>
          <Icon className={styles.icon} />
        </div>
      )}
      <Text color='dark-gray'>{text}</Text>
    </div>
  );
});

SimpleMessage.displayName = 'SimpleMessage';

export default SimpleMessage;
