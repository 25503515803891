export enum surveyTypes {
  /** For future update, when client want to create several surveys */
  GET_SURVEY_REQUEST = 'GET_SURVEY_REQUEST',
  GET_SURVEY_SUCCESS = 'GET_SURVEY_SUCCESS',
  GET_SURVEY_ERROR = 'GET_SURVEY_ERROR',

  SEND_SURVEY_REQUEST = 'SEND_SURVEY_REQUEST',
  SEND_SURVEY_SUCCESS = 'SEND_SURVEY_SUCCESS',
  SEND_SURVEY_ERROR = 'SEND_SURVEY_ERROR',
}
