import { memo } from 'react';

import { useTranslation } from 'react-i18next';

import { IBookingMeeting } from './BookingMeeting.types';

import TellUsImg from 'assets/images/tell-us.svg';
import Button from 'components/Button';
import Picture from 'components/Picture';
import Text from 'components/Text';
import { BOOKING_URL } from 'utils/constants';

import styles from './BookingMeeting.module.scss';

const BookingMeeting = memo(({ title, subtitle }: IBookingMeeting) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'components.modal.booking_meeting',
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles['header-content']}>
        {title && (
          <Text className={styles.title} tag='h1' align='center'>
            {title}
          </Text>
        )}

        {subtitle && (
          <Text
            className={styles.subtitle}
            tag='h2'
            align='center'
            color={'gray'}
          >
            {subtitle}
          </Text>
        )}
      </div>

      <div className={styles.content}>
        <Picture src={TellUsImg} className={styles.img} />
        <Text className={styles.subtitle} align='center' color={'gray'}>
          {t('title')}
        </Text>

        <Button className={styles.button}>
          <a href={BOOKING_URL} target='_blank' rel='noreferrer'>
            {t('book_a_demo')}
          </a>
        </Button>
      </div>
    </div>
  );
});

BookingMeeting.displayName = 'BookingMeeting';

export default BookingMeeting;
