import { helperShowStep } from 'services/onboardingTour/tourOptions';
import i18n from 'translation/i18n';

const STEP_ID = 'pathologies-detection-examination-step-4';

export const stepFour = {
  id: STEP_ID,
  attachTo: {
    element: '.shepherd-segmentation-filter',
    on: 'right',
  },
  beforeShowPromise: () =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        resolve();
      }, 500);
    }),
  buttons: [
    {
      classes: 'shepherd-button-primary',
      text: i18n.t('onboarding.show_examination.next'),
      type: 'next',
    },
  ],
  canClickTarget: false,
  scrollTo: { behavior: 'smooth', block: 'center' },
  title: i18n.t('onboarding.show_examination.pathologies_detection_text_11'),
  text: [
    `${i18n.t(
      'onboarding.show_examination.pathologies_detection_text_12'
    )} <span class='highlight'>${i18n.t(
      'onboarding.show_examination.pathologies_detection_text_13'
    )}</span> ${i18n.t(
      'onboarding.show_examination.pathologies_detection_text_14'
    )}</br>
    ${i18n.t('onboarding.show_examination.pathologies_detection_text_15')}`,
  ],
  when: {
    show: () => {
      helperShowStep(STEP_ID);
    },
  },
};
