import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { sendOcuPlanForm } from 'api/auth';
import {
  sendOcuPlanFormError,
  sendOcuPlanFormSuccess,
} from 'redux/actions/userAuth';
import { userConstants } from 'redux/constants/userAuth';
import notify from 'utils/toast';

export interface ISendOcuPlanWorker {
  type: typeof userConstants.SEND_OCUPLAN_FORM_REQUEST;
  payload: { full_name: string; business_name: string; email: string };
  action: {
    setCurrentStep: (step: number) => void;
    currentStep: number;
    error: (error: Record<string, string> | unknown) => void;
  };
}

function* sendOcuPlanWatcher() {
  yield takeEvery(userConstants.SEND_OCUPLAN_FORM_REQUEST, sendOcuPlanWorker);
}

function* sendOcuPlanWorker({ payload, action }: ISendOcuPlanWorker) {
  const { full_name, business_name, email } = payload;
  try {
    const formData = new FormData();
    formData.append('full_name', full_name);
    formData.append('business_name', business_name);
    formData.append('email', email);

    const { data: sendPlanFrom } = yield sendOcuPlanForm(formData);
    yield put(sendOcuPlanFormSuccess());

    if (sendPlanFrom.status === 'Error') {
      throw new Error(sendPlanFrom.message);
    }

    /**
     * the action parameter will change the form
     * step after confirming form
     */
    yield action.setCurrentStep(action.currentStep + 1);
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    if (typeof error === 'string') {
      yield put(sendOcuPlanFormError(error));
      yield notify('error', errorMessage);

      return;
    }

    yield put(sendOcuPlanFormError(error));
    yield action.error(error);
  }
}

export default function* ocuPlanSaga() {
  yield fork(sendOcuPlanWatcher);
}
