export enum subscriptionConstants {
  GET_SUBSCRIPTION_PLAN_REQUEST = 'GET_SUBSCRIPTION_PLAN_REQUEST',
  GET_SUBSCRIPTION_PLAN_SUCCESS = 'GET_SUBSCRIPTION_PLAN_SUCCESS',
  GET_SUBSCRIPTION_PLAN_ERROR = 'GET_SUBSCRIPTION_PLAN_ERROR',

  SUBSCRIBE_PLAN_REQUEST = 'SUBSCRIBE_PLAN_REQUEST',
  SUBSCRIBE_PLAN_SUCCESS = 'SUBSCRIBE_PLAN_SUCCESS',
  SUBSCRIBE_PLAN_ERROR = 'SUBSCRIBE_PLAN_ERROR',

  UNSUBSCRIBE_PLAN_REQUEST = 'UNSUBSCRIBE_PLAN_REQUEST',
  UNSUBSCRIBE_PLAN_SUCCESS = 'UNSUBSCRIBE_PLAN_SUCCESS',
  UNSUBSCRIBE_PLAN_ERROR = 'UNSUBSCRIBE_PLAN_ERROR',

  GET_SUBSCRIPTION_DETAILS_REQUEST = 'GET_SUBSCRIPTION_DETAILS_REQUEST',
  GET_SUBSCRIPTION_DETAILS_SUCCESS = 'GET_SUBSCRIPTION_DETAILS_SUCCESS',
  GET_SUBSCRIPTION_DETAILS_ERROR = 'GET_SUBSCRIPTION_DETAILS_ERROR',

  GET_SUBSCRIPTION_SERVICES_REQUEST = 'GET_SUBSCRIPTION_SERVICES_REQUEST',
  GET_SUBSCRIPTION_SERVICES_SUCCESS = 'GET_SUBSCRIPTION_SERVICES_SUCCESS',
  GET_SUBSCRIPTION_SERVICES_ERROR = 'GET_SUBSCRIPTION_SERVICES_ERROR',

  GET_PAYMENT_STATISTICS_REQUEST = 'GET_PAYMENT_STATISTICS_REQUEST',
  GET_PAYMENT_STATISTICS_SUCCESS = 'GET_PAYMENT_STATISTICS_SUCCESS',
  GET_PAYMENT_STATISTICS_ERROR = 'GET_PAYMENT_STATISTICS_ERROR',
}
