import { memo } from 'react';

import cn from 'classnames';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import Text from 'components/Text';

import styles from './Banner.module.scss';

interface BannerProps {
  className?: string;
  appearance: 'info' | 'danger';
  text: JSX.Element | string;
  action?: {
    text: string;
    func: () => void;
  };
}

const Banner = memo(({ className, appearance, text, action }: BannerProps) => {
  return (
    <div className={cn(className, { [styles[appearance]]: appearance })}>
      <div className={cn(styles.item)}>
        <InfoIcon className={styles.icon} />
        <Text className={styles.title}>
          {text}{' '}
          {action && (
            <span onClick={action.func} className={styles.action}>
              <strong>{action.text}</strong>
            </span>
          )}
        </Text>
      </div>
    </div>
  );
});

Banner.displayName = 'Banner';

export default Banner;
