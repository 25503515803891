import { memo, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import SteepThankYou from './SteepThankYou';
import StepOne from './StepOne';

import MetaInfo from 'components/MetaInfo';
import { sendOcuPlanFormRequest } from 'redux/actions/userAuth';
import { ga } from 'utils/helpers';

const OcuPlan = memo(() => {
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    ga('open_page_ocu_plan');
  }, []);

  const nextHandler = (
    value: Record<string, string | boolean>,
    actions: any
  ) => {
    // the second parameter {} is needed to change the form
    // step after confirming the account registration, we
    // works with them in the saga
    dispatch(
      sendOcuPlanFormRequest(value, {
        currentStep,
        setCurrentStep,
        error(errors: any) {
          actions.setStatus(errors);
        },
      })
    );
  };

  const renderStep = (step: number) => {
    switch (step) {
      case 1:
        return <StepOne nextHandler={nextHandler} />;
      case 2:
        return <SteepThankYou />;
      default:
        return null;
    }
  };

  return (
    <>
      <MetaInfo title='Ocuplan ToS' />
      {renderStep(currentStep)}
    </>
  );
});

OcuPlan.displayName = 'OcuPlan';

export default OcuPlan;
