export enum teamMemberConstants {
  INVITE_TEAM_MEMBER_REQUEST = 'INVITE_TEAM_MEMBER_REQUEST',
  INVITE_TEAM_MEMBER_SUCCESS = 'INVITE_TEAM_MEMBER_SUCCESS',
  INVITE_TEAM_MEMBER_ERROR = 'INVITE_TEAM_MEMBER_ERROR',

  GET_ALL_TEAM_MEMBERS_REQUEST = 'GET_ALL_TEAM_MEMBERS_REQUEST',
  GET_ALL_TEAM_MEMBERS_SUCCESS = 'GET_ALL_TEAM_MEMBERS_SUCCESS',
  GET_ALL_TEAM_MEMBERS_ERROR = 'GET_ALL_TEAM_MEMBERS_ERROR',

  DELETE_TEAM_MEMBER_REQUEST = 'DELETE_TEAM_MEMBER_REQUEST',
  DELETE_TEAM_MEMBER_SUCCESS = 'DELETE_TEAM_MEMBER_SUCCESS',
  DELETE_TEAM_MEMBER_ERROR = 'DELETE_TEAM_MEMBER_ERROR',

  REINVITE_TEAM_MEMBER_REQUEST = 'REINVITE_TEAM_MEMBER_REQUEST',
  REINVITE_TEAM_MEMBER_SUCCESS = 'REINVITE_TEAM_MEMBER_SUCCESS',
  REINVITE_TEAM_MEMBER_ERROR = 'REINVITE_TEAM_MEMBER_ERROR',
}
