import { useCallback, useEffect, useMemo, useState } from 'react';

import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import customStyles from './customSelectStyles';

import { demoSignUp } from 'api/demo';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import Button from 'components/Button';
import CustomTooltip from 'components/CustomTooltip';
import FormField from 'components/FormComponents';
import SpinnerSVG from 'components/SpinnerSVG';
import Text from 'components/Text';
import { getProfessionsCountriesAndProducersRequest } from 'redux/actions/professionsCountriesAndProducers';
import { getAuthenticatedTrue } from 'redux/actions/userAuthenticated';
import { selectProfessionsAndCountries } from 'redux/selectors/professionAndCountry';
import {
  ICountry,
  IOCTProducers,
  IProfession,
} from 'types/professionAndCountry';
import { DEMO_ACCOUNT_GOOGLE_ANALYTICS } from 'utils/constants';
import { ga } from 'utils/helpers';
import notify from 'utils/toast';
import { validateDemoRegisterStepThree } from 'validation/index';

import styles from './ThirdStep.module.scss';

interface FormValues {
  fullName: string;
  country_id?: number;
  producer: string;
  profession_id?: number;
  isDecisionMaker: string;
  promo_code: string;
}

interface ThirdStepProps {
  email: string;
}

const ThirdStep = ({ email }: ThirdStepProps) => {
  const { t, i18n } = useTranslation(undefined, {
    keyPrefix: 'demo_user_registration.third_step',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const { professions, countries, producers, requesting } = useSelector(
    selectProfessionsAndCountries
  );

  const onFormSubmit = useCallback(
    async ({
      fullName,
      country_id,
      producer,
      profession_id,
      promo_code,
      isDecisionMaker,
    }: FormValues) => {
      if (profession_id == null || country_id == null) return;

      setIsSubmitting(true);

      try {
        const data = await demoSignUp({
          email,
          name: fullName,
          profession_id,
          country_id,
          oct_producer_id: producer,
          is_decision_maker: Number(isDecisionMaker === 'Yes'),
          promo_code,
        });

        // @ts-ignore
        localStorage.setItem('token', data.data.data[0]);

        dispatch(getAuthenticatedTrue('demo'));

        ga(DEMO_ACCOUNT_GOOGLE_ANALYTICS.REGISTRATION_S2);
      } catch (error) {
        if (error instanceof Object && Object.keys(error).length > 0) {
          Object.keys(error).forEach((key) => {
            // @ts-ignore
            notify('error', String(error[key][0]));
          });
        } else {
          notify('error', String(error));
        }
      } finally {
        setIsSubmitting(false);
      }
    },
    [email]
  );

  const radioGroupValues = useMemo(
    () => [
      { value: 'Yes', label: 'Yes' },
      { value: 'No', label: 'No' },
    ],
    []
  );

  const transformDataForSelect = useCallback(
    (data: ICountry[] | IProfession[] | IOCTProducers[]) => {
      return data?.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    },
    []
  );

  useEffect(() => {
    if (!(professions.length && countries.length && producers.length)) {
      dispatch(getProfessionsCountriesAndProducersRequest());
    }
  }, []);

  return (
    <>
      <Text className={styles.title} color='light' tag='h1' align='center'>
        {t('add_some_info')}
      </Text>

      <Formik
        initialValues={{
          fullName: '',
          country_id: undefined,
          producer: '',
          profession_id: undefined,
          isDecisionMaker: 'No',
          promo_code: '',
        }}
        onSubmit={onFormSubmit}
        validationSchema={() => validateDemoRegisterStepThree(i18n.t)}
      >
        {({ setFieldValue }) => (
          <Form className={styles.form}>
            <SpinnerSVG
              className={styles.spinner}
              active={isSubmitting || requesting}
              fillColor={'var(--theme-color)'}
            />

            <div className={styles['form-fields-group']}>
              <div className={styles['form-field']}>
                <Text
                  className={styles['field-label']}
                  color='light'
                  tag='span'
                >
                  {t('full_name_label')}
                </Text>

                <FormField
                  name={'fullName'}
                  label={t('full_name_placeholder')}
                  id={'fullName'}
                  errorType={'simple'}
                  className={styles.field}
                />
              </div>

              <div className={styles['form-field']}>
                <Text
                  className={styles['field-label']}
                  color='light'
                  tag='span'
                >
                  {t('country_label')}
                </Text>

                <FormField
                  customStyles={customStyles}
                  options={transformDataForSelect(countries)}
                  component={'select'}
                  name={'country_id'}
                  id={'country_id'}
                  placeholder={t('country_placeholder')}
                  className={styles.field}
                  isSearchable
                  onChange={(item: { value: number }) =>
                    setFieldValue('country_id', item.value)
                  }
                  errorType={'simple'}
                />
              </div>

              <div className={styles['form-field']}>
                <Text
                  className={styles['field-label']}
                  color='light'
                  tag='span'
                >
                  {t('oct_label')}
                </Text>

                <FormField
                  customStyles={customStyles}
                  options={transformDataForSelect(producers)}
                  component={'select'}
                  name={'producer'}
                  id={'producer'}
                  placeholder={t('oct_placeholder')}
                  className={styles.field}
                  isSearchable
                  onChange={(item: { value: string }) =>
                    setFieldValue('producer', item.value)
                  }
                  errorType={'simple'}
                />
              </div>

              <div className={styles['form-field']}>
                <Text
                  className={styles['field-label']}
                  color='light'
                  tag='span'
                >
                  {t('profession_label')}
                </Text>

                <FormField
                  customStyles={customStyles}
                  options={transformDataForSelect(professions)}
                  component={'select'}
                  name={'profession_id'}
                  id={'profession_id'}
                  placeholder={t('profession_placeholder')}
                  className={styles.field}
                  isSearchable
                  onChange={(item: { value: number }) =>
                    setFieldValue('profession_id', item.value)
                  }
                  errorType={'simple'}
                />
              </div>

              <div className={styles['form-field']}>
                <Text
                  className={styles['field-label']}
                  color='light'
                  tag='span'
                >
                  {t('decision_maker_label')}
                </Text>

                <div className={styles['radio-wrapper']}>
                  {radioGroupValues.map((item) => (
                    <FormField
                      key={item.value}
                      type={'radio'}
                      component={'radio'}
                      name={'isDecisionMaker'}
                      id={item.value}
                      label={item.label}
                      value={item.value}
                      className={styles.field}
                    />
                  ))}
                </div>
              </div>

              <div className={styles['form-field']}>
                <Text
                  className={styles['field-label']}
                  color='light'
                  tag='span'
                >
                  {t('promo_code_label')}{' '}
                  <CustomTooltip
                    position={'top'}
                    icon={<InfoIcon />}
                    tooltipText={t('promo_code_info')}
                  />
                </Text>

                <FormField
                  name={'promo_code'}
                  label={t('promo_code_placeholder')}
                  id={'promo_code'}
                  errorType={'simple'}
                  className={styles.field}
                />
              </div>
            </div>

            <Button type={'submit'} className={styles.button}>
              {t('submit')}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ThirdStep;
