import {
  memo,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import ReactQuill, { Quill } from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';

import { EXAMINATION } from 'api/endpoints';
import { ReactComponent as BoldIcon } from 'assets/icons/bold.svg';
import { ReactComponent as ItalicIcon } from 'assets/icons/italic.svg';
import { ReactComponent as UnderlineIcon } from 'assets/icons/underline.svg';
import Button from 'components/Button';
import { getExaminationSegmentationRequest } from 'redux/actions/examination';
import { closeModal } from 'redux/actions/modal';
import { getExamData } from 'redux/selectors/examination/getExamData/getExamData';
import fetchData from 'utils/fetchData';
import notify from 'utils/toast';

import './editor.scss';
import styles from './EditScanDescription.module.scss';

interface EditScanDescriptionProps {
  subtitle: string;
  content: string;
  scanId?: string;
}

const icons = Quill.import('ui/icons');

icons.bold = undefined;
icons.italic = undefined;
icons.underline = undefined;

const EditScanDescription = memo(
  ({ subtitle, content, scanId }: EditScanDescriptionProps) => {
    const [editorValue, setEditorValue] = useState(content);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { t, i18n } = useTranslation(undefined, {
      keyPrefix: 'components.modal.edit_scan_description',
    });

    const { data: examData } = useSelector(getExamData);

    const dispatch = useDispatch();

    const initialValue = useRef(content);
    const editorRef = useRef<ReactQuill | null>(null);

    const formats = useMemo(() => ['bold', 'italic', 'underline'], []);
    const modules = useMemo(
      () => ({
        toolbar: {
          container: '#toolbar',
        },
      }),
      []
    );

    const isTextChanged = useMemo(
      () => initialValue.current != editorValue,
      [editorValue]
    );

    const onSubmit = useCallback(async () => {
      if (!scanId) return;

      setIsSubmitting(true);

      await fetchData({
        method: 'put',
        to: EXAMINATION.EXAMINATION_SCAN_DESCRIPTION(examData.id),
        data: {
          scan_id: scanId,
          description: editorValue,
        },
      });

      dispatch(
        getExaminationSegmentationRequest(
          { exam_id: examData.id, scans_id: [scanId] },
          {
            success() {
              setIsSubmitting(false);
              dispatch(closeModal());
            },
          }
        )
      );
    }, [editorValue, scanId, examData.id]);

    useLayoutEffect(() => {
      if (editorRef.current) {
        const editorElement = editorRef.current;

        editorElement.editor?.clipboard.dangerouslyPasteHTML(content);

        editorElement.focus();

        if (editorElement.editor) {
          editorElement.setEditorSelection(editorElement.editor, {
            index: editorElement.editor.getLength(),
            length: 0,
          });
        }
      }
    }, []);

    return (
      <div className={styles['edit-description-modal']}>
        <p className={styles.title}>{t('oct_description_title')}</p>

        <p className={styles.subtitle}>{subtitle}</p>

        <div className={styles.editor}>
          <CustomEditorToolbar />
          <ReactQuill
            formats={formats}
            value={DOMPurify.sanitize(editorValue)}
            modules={modules}
            onKeyDown={(event) => {
              const editor = editorRef.current?.getEditor();

              if (!editor) return;

              if (editor.getLength() > 10_000 && event.key !== 'Backspace') {
                event.preventDefault();

                editor.deleteText(10_000, editor.getLength());

                notify(
                  'error',
                  i18n.t('notifications.characters_limit_exceeded', {
                    number: '10000',
                  })
                );
              }
            }}
            onChange={setEditorValue}
            preserveWhitespace
            ref={editorRef}
          />
        </div>

        <Button
          disabled={!isTextChanged || !scanId || isSubmitting}
          className={styles['save-button']}
          onClick={onSubmit}
        >
          {t('save')}
        </Button>
      </div>
    );
  }
);

const CustomEditorToolbar = memo(() => {
  return (
    <div id={'toolbar'} className={styles.toolbar}>
      <button className={'ql-bold'}>
        <BoldIcon />
      </button>
      <button className={'ql-italic'}>
        <ItalicIcon />
      </button>
      <button className={'ql-underline'}>
        <UnderlineIcon />
      </button>
    </div>
  );
});

CustomEditorToolbar.displayName = 'CustomEditorToolbar';
EditScanDescription.displayName = 'EditScanDescription';

export default EditScanDescription;
