import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { getProgressCommonHeatmapData } from 'api/examination';
import {
  getProgressionHeatmapDataAction,
  getProgressionHeatmapDataError,
  getProgressionHeatmapDataSuccess,
} from 'redux/actions/examination';
import { ExamActionTypes } from 'redux/constants/examination';
import notify from 'utils/toast';

function* getProgressionHeatmapDataWatcher() {
  yield takeEvery(
    ExamActionTypes.GET_PROGRESSION_HEATMAP_DATA_REQUEST,
    getProgressionHeatmapDataWorker
  );
}

interface IProgressionHeatmapData {
  type: ExamActionTypes;
  payload: {
    examination_ids: string[];
    pathology_id: string;
    withoutSave?: boolean;
  };
  action?: getProgressionHeatmapDataAction;
  abortSignal?: AbortSignal;
}

function* getProgressionHeatmapDataWorker({
  payload,
  action,
  abortSignal,
}: IProgressionHeatmapData) {
  action?.default?.();

  const { examination_ids, pathology_id, withoutSave } = payload;

  try {
    // @ts-ignore
    const data = yield getProgressCommonHeatmapData(
      { examination_ids, pathology_id },
      abortSignal
    );

    yield put(
      getProgressionHeatmapDataSuccess(
        !withoutSave ? data.data.data : undefined
      )
    );

    if (data.data.data.status === 'Error') {
      throw new Error(data.data.data.message);
    }
    action?.success?.(data.data.data);
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    if (error !== 'canceled') {
      notify('error', errorMessage);
    }

    action?.error?.();
    yield put(getProgressionHeatmapDataError());
  }
}

export default function* getProgressionHeatmapDataSaga() {
  yield fork(getProgressionHeatmapDataWatcher);
}
