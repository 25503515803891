import { Language } from '../i18n';

const translation: Language = {
  dashboard: {
    error: {
      try_again: 'Erneut versuchen',
    },
    banner: {
      title1: 'Bitte kontaktieren Sie',
      link: 'Altris-Vertriebsteam',
      title2: 'um Ihr Abonnement zu verlängern',
    },
    empty_data: {
      title: 'Es wurden keine Patienten hinzugefügt',
      subtitle: 'Sie können neue Patienten hinzufügen, indem Sie auf',
      add_new_patient: 'Neuen Patienten hinzufügen',
    },
    header: {
      close_filter: 'Filter schließen',
      open_filter: 'Filter',
      search_placeholder: 'Patient suchen',
      examination_filter: 'Untersuchungsfilter',
      filter_form: {
        show_scan_by_date: 'Scans nach Datum anzeigen',
        all: 'Alle',
        today: 'Heute',
        range: 'Zeitraum',
        from: 'Von',
        to: 'Bis',
        created_by: 'Patientenfilter',
        doctor_select: 'Arzt auswählen',
        apply: 'Anwenden',
        clear: 'Filter löschen',
      },
      add_new_patient: 'Neuen Patienten hinzufügen',
      add_new_examination: 'Neue Untersuchung hinzufügen',
      back: 'Zurück',
    },
    patient_list: {
      header: {
        patient_id: 'Patienten-ID',
        patient_name: 'Patientenname',
        birth_date: 'Geburtsdatum',
        gender: 'Geschlecht',
        last_upload: 'Letzter Upload',
        severity_level: 'Schweregrad',
        description: 'Beschreibung',
      },
      item: {
        patient_id: 'Patienten-ID',
        patient_name: 'Patientenname',
        birth_date: 'Geburtsdatum',
        gender: 'Geschlecht',
        last_upload: 'Letzter Upload',
        severity_level: 'Schweregrad',
        description: 'Beschreibung',
        edit: 'Bearbeiten',
        delete: 'Löschen',
        back: 'Zurück',
        show_examinations: 'Untersuchungen anzeigen',
      },
    },
  },
  contact_us_page: {
    contact_us: 'Kontaktieren Sie uns',
    fill_the_form: 'Bitte füllen Sie das folgende Formular aus',
    example_short: 'Bsp.',
    first_name: 'Vorname',
    email: 'E-Mail',
    message: 'Ihre Nachricht',
    submit_form: 'Formular absenden',
    attach_file: 'Datei anhängen',
    attach_screenshot:
      'Sie können Ihrer Nachricht einen Screenshot anhängen, um eventuelle Schwierigkeiten zu verdeutlichen. Dies kann unserem Support-Team helfen, schneller die richtige Lösung für Sie zu finden.',
  },
  demo_user_registration: {
    back: 'Zurück',
    banner_text_1: 'Jeder OCT',
    banner_text_2:
      'Erkunden Sie, wie AI OCT analysiert:\nDie Scans sind drinnen',
    banner_text_3: 'OCT-Scan mit Altris AI',
    first_step: {
      welcome: 'Willkommen bei Altris AI',
      email_label: 'Geben Sie Ihre E-Mail-Adresse zur Validierung ein:',
      email: 'E-Mail',
      info: 'Mit dem Fortfahren stimmen Sie automatisch zu, dass Altris AI Ihre E-Mail-Adresse zum Versenden von Marketingmaterialien speichert.',
      submit_form: 'Bestätigung senden',
    },
    second_step: {
      enter_security_code: 'Sicherheitscode eingeben',
      send_to: 'Dieser Sicherheitscode wurde gesendet an:',
      submit: 'Bestätigen',
      resend_code: 'Code erneut senden',
      seconds_short: 'Sek.',
    },
    third_step: {
      add_some_info: 'Ergänzen Sie einige Informationen',
      full_name_label: 'Geben Sie Ihren vollständigen Namen ein',
      full_name_placeholder: 'Vollständiger Name',
      country_label: 'Land',
      country_placeholder: 'Land auswählen',
      oct_label: 'OCT-System',
      oct_placeholder: 'OCT-System auswählen',
      profession_label: 'Beruf',
      profession_placeholder: 'Beruf auswählen',
      decision_maker_label:
        'Sind Sie Entscheidungsträger in Ihrer Klinik/Einrichtung?',
      promo_code_label: 'Promo-Code (optional)',
      promo_code_info:
        'Kontaktieren Sie den Altris-Distributor oder das Altris-Vertriebsteam',
      promo_code_placeholder: 'Promo-Code eingeben',
      submit: 'Abschließen',
    },
  },
  education: {
    title: 'Werden Sie Teil von Altris Education',
    subtitle1:
      'Altris Education OCT wird Ihr Wegweiser in der OCT-Befundungsausbildung sein. Diese mobile App ist interaktiv, kostenlos und jederzeit und überall verfügbar. Traditionelle Lernmethoden wie Webinare, Konferenzen und Atlanten reichen für moderne Lernende nicht mehr aus: Es ist Zeit für kostenlose mobile Apps.',
    education: 'Ausbildung',
    subtitle2:
      'In Altris Education OCT haben Sie Zugriff auf Millionen beschriftete OCT-Scans und werden gleichzeitig Teil einer Gemeinschaft von Augenärzten. Sie erhalten die einzigartige Möglichkeit, kontroverse OCT-Scans hochzuladen und mit Kollegen aus der ganzen Welt zu diskutieren. Mit Altris Education OCT setzen wir höhere diagnostische Standards, die zu besseren Ergebnissen für die Patienten führen.',
    learn_more: 'Mehr erfahren',
    download: 'Altris Education App herunterladen',
    oct_scans: 'OCT-Scans',
    experts: 'Experten',
    retina_experts: 'Netzhautexperten beschriften jeden Scan',
    eye_care_specialists: 'Augenärzte in der Community',
    pathological_signs: 'pathologische Zeichen',
    pathologies: 'Pathologien',
  },
  forgot_password_page: {
    first_step: {
      enter_email: 'Geben Sie Ihre E-Mail-Adresse ein',
      email: 'E-Mail',
      confirm: 'Bestätigen',
      or_sign_in: 'oder Anmelden',
    },
    second_step: {
      congratulations: 'Herzlichen Glückwunsch!',
      check_email: 'Bitte überprüfen Sie Ihre E-Mail',
      back_to_sign_in: 'Zurück zur Anmeldung',
    },
  },
  maintenance_mode: {
    title: 'Website wird gewartet!',
    subtitle:
      'Diese Website wird derzeit planmäßig gewartet. Wir sollten bald wieder da sein.',
    refresh_page: 'Seite aktualisieren',
  },
  mobile_availability: {
    title: 'Willkommen bei Altris AI',
    subtitle:
      'Altris AI ist derzeit nur auf dem PC verfügbar. Bitte öffnen Sie die Plattform auf Ihrem PC, um den vollen Funktionsumfang nutzen zu können.',
    link: 'Link',
    copy_link: 'Link kopieren',
    mobile_subscription: {
      title: 'Abonnements',
      subtitle: 'Wählen Sie Ihr Abonnement, um Altris AI zu nutzen.',
      services: 'Leistungen',
    },
  },
  offline: {
    title: 'Ihre Internetverbindung ist verloren!',
    subtitle: 'Bitte überprüfen Sie Ihre Internetverbindung...',
  },
  examination: {
    unavailable: {
      title: 'Nicht genügend Daten',
      subtitle:
        'Es sind nicht genügend Daten in DICOM vorhanden, um die Untersuchung zu überprüfen. Bitte füllen Sie das leere Formular oben auf der Seite aus.',
    },
    header: {
      patient_id: 'Patienten ID',
      patient_name: 'Patientenname',
      patient_name_placeholder: 'Vorname',
      patient_surname: 'Nachname',
      patient_surname_placeholder: 'Nachname',
      birth_date: 'Geburtsdatum',
      gender: 'Geschlecht',
      gender_placeholder: 'Auswählen',
      examination_date: 'Untersuchungsdatum',
      notes: 'Notizen',
      report_unavailable: 'Leider sind Berichte nicht verfügbar.',
      select_plan: 'Wählen Sie einen Plan',
      to_use_this_feature: 'um diese Funktion nutzen zu können.',
      report: 'Bericht',
      export_unavailable:
        'Leider ist der Export für diese Aufnahme nicht verfügbar.',
      export: 'Export',
      save: 'Speichern',
      patient_info: 'Patienteninfo',
      male: 'Männlich',
      female: 'Weiblich',
      other: 'Andere',
      other_patient_examinations: 'Untersuchungen anderer Patienten',
      examination_label:
        '{{name}} / Untersuchung: {{data}} / Auge: {{eye}} / Aufnahme: {{scan}}',
    },
    tabs: {
      single_scan: 'EINZELSCAN',
      pathologies_detection: 'PATHOLOGIENERKENNUNG',
      measurements: 'MESSUNGEN',
      all_scans: 'ALLE AUFNAHMEN',
      both_eyes: 'BEIDE AUGEN',
      comparison: 'DICKENVERGLEICH',
      progression: 'PATHOLOGIEENTWICKLUNG',
      glaucoma: 'GLAUKOM-RISIKOANALYSE',
    },
    single_scan: {
      severity: 'Schweregrad',
      off: 'Aus',
      thickness_map_off: 'Dickekarte aus',
      on: 'Ein',
      thickness_map_on: 'Dickekarte ein',
      average_thickness: '{{metrics}} mm durchschnittliche Dicke, μm',
      layers_failed: {
        title: 'Bei der Analyse der Schichten ist ein Fehler aufgetreten.',
        subtitle: 'Bitte wenden Sie sich mit diesem Problem an den Support.',
      },
      retina_thickness_map: 'Netzhautdickekarte',
    },
    pathologies_detection: {
      classification: 'Klassifikation',
      no_access:
        'Sie haben keinen Zugriff auf Segmentierungen. Bitte nutzen Sie das Kontaktformular in Ihrem Profil.',
    },
    measurements: {
      not_available:
        'Messungen sind für die aktuelle Untersuchung nicht verfügbar.',
      failed:
        'Messungen fehlgeschlagen. Bitte wenden Sie sich mit diesem Problem an den Support.',
      in_progress:
        'Messungen laufen gerade. Bitte überprüfen Sie diese Seite etwas später.',
      no_plan:
        'Ihr Plan beinhaltet keine Messanalyse. Bitte upgraden Sie Ihren Plan.',
      scan: {
        measurements: 'Messungen',
        auto: 'Automatisch',
        manual: 'Manuell',
        segmentation: 'Segmentierung',
        length: 'Länge',
        add_measurements: 'Messungen hinzufügen',
        no_lines: 'Keine Linien für die aktuelle Aufnahme vorhanden',
        highlight: 'Hervorheben',
        horizontal: 'Horizontal',
        vertical: 'Vertikal',
        reset_measurements: 'Messungen zurücksetzen',
        no_pathologies: 'Keine Pathologien für die aktuelle Aufnahme vorhanden',
      },
      table: {
        segmentation_area: 'Segmentierungsfläche',
        segmentation_volume: 'Segmentierungsvolumen',
        combined: 'Kombiniert',
        separate: 'Separat',
        area_headings: {
          all_segmentations: 'Alle Segmentierungen',
          b_scan_area: 'B-Scan-Bereich',
          b_scan_area_view: 'B-Scan-Bereichsansicht',
          en_face_area_view:
            '<span>En-Face-Ansicht (mm<sup>2</sup>  * 1000)</span>',
        },
        volume_headings: {
          all_segmentations: 'Alle Segmentierungen',
          measured_volume_wrap: 'Gemessenes Volumen<br />(ETDRS {{number}}mm)',
          measured_volume: 'Gemessenes Volumen (ETDRS {{number}}mm)',
          en_face_area_view:
            '<span>En-Face-Ansicht<br> (mm<sup>3</sup> * 1000)</span>',
          en_face_area_view_nl: 'En-Face-Ansicht',
        },
        no_pathology: 'Keine Pathologien für die aktuelle Aufnahme vorhanden',
      },
    },
    all_scans: {
      no_scans: {
        title: 'Keine Scans mit diesen Parametern gefunden.',
        subtitle: 'Bitte versuchen Sie es mit anderen Filtern.',
      },
      severity: 'Schweregrad',
    },
    both_eyes: {
      add_fellow_eye: 'Gesundes Auge hinzufügen',
      no_plan:
        'Ihr Plan beinhaltet keine Analyse beider Augen. Bitte upgraden Sie Ihren Plan.',
      severity: 'Schweregrad',
      left_eye: 'Horizontal, Linkes Auge (OS)',
      right_eye: 'Horizontal, Rechtes Auge (OD)',
      average_thickness: '{{metrics}} mm durchschnittliche Dicke, μm',
      sync: 'Sync.',
      different_scan_number:
        'Zwei Untersuchungen haben eine unterschiedliche Anzahl von Scans, daher kann der Scrollvorgang nicht synchronisiert werden.',
    },
    comparison: {
      add_examination: 'Untersuchung zum Vergleich hinzufügen',
      no_plan:
        'Ihr Plan beinhaltet keine Vergleichsanalyse. Bitte upgraden Sie Ihren Plan.',
      retina_thickness_difference: 'Netzhautdickendifferenz',
      retina_thickness_difference_tooltip:
        'Zeigt die Netzhautdickendifferenz zwischen Untersuchungen, von der neuesten zur ältesten Untersuchung.',
      average_thickness: '{{metrics}} mm durchschnittliche Dicke, μm',
      sync: 'Sync.',
      different_scan_number:
        'Zwei Untersuchungen haben eine unterschiedliche Anzahl von Scans, daher kann der Scrollvorgang nicht synchronisiert werden.',
      severity: 'Schweregrad',
    },
    progression: {
      no_plan:
        'Ihr Plan beinhaltet keine Verlaufsanalyse. Bitte upgraden Sie Ihren Plan.',
      severity: 'Schweregrad',
      exam_does_not_have_date: 'Eine der Untersuchungen hat kein Datum.',
      oldest_to_newest: 'Älteste - Neueste',
      newest_to_oldest: 'Neueste - Älteste',
      sort_by_date: 'Nach Datum sortieren',
    },
    glaucoma: {
      unavailable: {
        title1: 'Glaukom-Risikoanalyse ',
        title2: 'ist für diese Untersuchung nicht verfügbar.',
        title3: ' Dies könnte folgende Gründe haben:',
        first_item1:
          'In den Scans vorhandene Pathologien, die den GCC-Komplex beeinträchtigen und',
        first_item2: 'eine genaue Berechnung unmöglich machen.',
        second_item: 'Andere Faktoren, die eine genaue Analyse verhindern.',
      },
      alert: {
        red: {
          title: 'Hohes Glaukomrisiko',
          description:
            'Der merkliche Unterschied in der Dicke des GCC zwischen der oberen (S) und der unteren (I) Hemisphäre weist auf potenzielle Anzeichen eines Glaukoms hin.',
        },
        yellow: {
          title: 'Mittleres Glaukomrisiko',
          description:
            'Obwohl die festgestellten Abweichungen in der Dicke des GCC der oberen (S) und unteren (I) Hemisphäre nicht signifikant genug sind, um Anlass zur Sorge zu geben, werden weitere Untersuchungen und Überwachungen empfohlen, um das Risiko umfassend einschätzen zu können.',
        },
        green: {
          title: 'Kein Glaukomrisiko',
          description:
            'Die berechnete Dickedifferenz zwischen der Dicke der oberen (S) und unteren (I) Hemisphäre GCC liegt innerhalb der klinisch akzeptierten Parameter.',
        },
      },
      target: {
        gcc_analysis: 'GCC-Analyse',
        gcc_thickness: 'GCC-Dicke',
        superior_inferior: 'Superior / Inferior',
      },
      info: {
        gcc_asymmetry: 'GCC-Asymmetrie',
        tooltip: {
          title1: 'Die GCC-Asymmetrieanalyse',
          title2: '-Abschnitt bewertet zwei Indikatoren:',
          first_item1:
            'Asymmetrie-Index: Dieser Parameter misst das Ungleichgewicht zwischen den oberen und unteren Sektoren und ändert die Farbe je nach dem S/I-Verhältnis:',
          first_item2: 'Grün',
          first_item3: ' - Keine Asymmetrie',
          first_item4: 'Gelb',
          first_item5: ' - Moderate Asymmetrie',
          first_item6: 'Rot',
          first_item7: ' - Asymmetrie',
          second_item1: 'Die ',
          second_item2: 'Dicke der oberen und unteren',
          second_item3: ' Sektoren separat.',
          description:
            'Basierend auf einer Abweichung dieser Indikatoren von den erwarteten Bereichen berechnen und zeigen wir den Glaukom-Risikoalarm an.',
        },
        asymmetry_index: 'Asymmetrie-Index',
        superior_thickness: 'Obere Dicke',
        inferior_thickness: 'Untere Dicke',
        normal: 'Normal',
        glaucoma_alert: 'Glaukom-Alarm',
      },
      hemisphere: {
        title: 'S-I Hemisphäre GCC-Dickenasymmetrie',
        superior: 'Superior',
        inferior: 'Inferior',
        description: {
          title: 'Beschreibung',
          item1: 'S-I Hemisphäre GCC-Dickenasymmetrie',
          item2:
            'Veranschaulicht die Asymmetrie der GCC-Dicke (RNFL+GCL+IPL) zwischen der oberen und unteren Hemisphäre.',
          item3: 'Ein 8x8-Raster',
          item4:
            ' wird auf einen 6x6 mm großen Makulabereich gelegt, um die Asymmetrie der GCC-Dicke zwischen den symmetrischen Quadranten der oberen und unteren Hemisphäre darzustellen.',
          item5:
            'Die Differenz der GCC-Dicke wird in den symmetrischen Quadraten angegeben.',
          item6: 'NB!',
          item7:
            ' Für eine qualitative Beurteilung der GCC-Dicke ist es notwendig, das Vorhandensein von exsudativen Veränderungen in der Makulazone der Netzhaut zu berücksichtigen und die GCC-Dickendaten des kontralateralen Auges zu bewerten.',
        },
      },
    },
  },
  onboarding_page: {
    title: 'Einführung',
    all_scans: 'Alle Scans',
    both_eyes: 'Beide Augen',
    comparison: 'Vergleich',
    create_examination: 'Untersuchung erstellen',
    create_patient: 'Patienten anlegen',
    header: 'Kopfzeile',
    measurements: 'Messungen',
    pathologies_detection: 'Pathologieerkennung',
    progression: 'Verlauf',
    report: 'Bericht',
    single_scan: 'Einzelner Scan',
  },
  register_user_page: {
    step_one: {
      sign_up_with_google: 'Mit Google registrieren',
      or: 'oder',
      sing_up_with_email: 'Konto mit E-Mail erstellen',
      email_label: 'Unternehmens-E-Mail',
      website_label: 'Website der Einrichtung',
      first_name_label: 'Vorname',
      last_name_label: 'Nachname',
      next: 'Weiter',
      or_sign_in: 'oder Anmelden',
    },
    step_two: {
      create_an_account: 'Konto erstellen',
      password_label: 'Passwort erstellen',
      repeat_password_label: 'Passwort wiederholen',
      next: 'Weiter',
      back: 'Zurück',
    },
    step_three: {
      create_an_account: 'Konto erstellen',
      profession_placeholder: 'Beruf wählen',
      country_placeholder: 'Ihr Land',
      i_agree_with: 'Ich stimme den',
      privacy_policy: 'Datenschutzbestimmungen',
      and: 'und',
      terms_and_conditions: 'Allgemeinen Geschäftsbedingungen',
      back: 'Zurück',
    },
    step_thankyou: {
      title: 'Glückwunsch!',
      subtitle: 'Ihr Konto wurde erfolgreich erstellt!',
      button: "Los geht's",
    },
  },
  reset_password_page: {
    enter_new_password: 'Geben Sie Ihr neues Passwort ein',
    password_label: 'Passwort erstellen',
    repeat_password_label: 'Passwort wiederholen',
    next: 'Weiter',
  },
  settings_page: {
    tabs: {
      profile: 'Profil',
      report_settings: 'Berichtseinstellungen',
      progression_settings: 'Fortschrittseinstellungen',
      functions: 'Funktionen',
      change_password: 'Passwort ändern',
      accounts_data: 'Kontodaten',
    },
    accounts_data: {
      title: 'Kontodaten',
      subtitle:
        'Erstellen Sie eine Sicherungskopie Ihrer Inhalte im Altris AI-Konto.',
      no_examinations: 'Sie haben keine Untersuchungen zum Sichern erstellt',
      request_data: 'Persönliche Daten anfordern',
      in_progress: 'Ihre Daten werden erstellt. Bitte warten Sie einen Moment.',
      cancel_backup: 'Benutzer-Backup abbrechen',
      download_data: 'Daten herunterladen',
      download_info:
        'Hier sind Ihre Daten. Sie können sie durch Klicken auf die Schaltfläche oben herunterladen. Dieses ZIP-Archiv wird unter {{date}} gespeichert. Nach diesem Datum können Sie erneut ein Backup erstellen.',
      delete_data: 'Persönliche Daten löschen',
      delete_info:
        'Sie können dieses ZIP-Archiv auch löschen und erneut ein Backup erstellen.',
      code_send: 'Ein Bestätigungscode wurde an Ihre E-Mail-Adresse gesendet.',
      attempts_left: 'Verbleibende Versuche',
      example_short: 'Bsp.',
      confirm: 'Bestätigen',
      cancel: 'Abbrechen',
    },
    change_password: {
      password_label: 'Aktuelles Passwort',
      password_placeholder: 'Passwort eingeben',
      new_password_label: 'Neues Passwort',
      new_password_placeholder: 'Passwort eingeben',
      repeat_new_password_label: 'Passwort wiederholen',
      repeat_new_password_placeholder: 'Passwort eingeben',
      unable_to_change_password: 'Leider können Sie Ihr Passwort nicht ändern.',
      submit: 'Passwort aktualisieren',
      code_send: 'Ein Bestätigungscode wurde an Ihre E-Mail-Adresse gesendet.',
      attempts_left: 'Verbleibende Versuche',
      example_short: 'Bsp.',
      confirm: 'Bestätigen',
      cancel: 'Abbrechen',
    },
    functions_settings: {
      title: 'Einstellungen',
      subtitle: 'Ändern Sie die Einstellungen im Formular unten',
      save: 'Einstellungen speichern',
      on: 'Ein',
      off: 'Aus',
    },
    profile: {
      example_short: 'Bsp.',
      first_name: 'Vorname',
      last_name: 'Nachname',
      email: 'E-Mail',
      save: 'Änderungen speichern',
      country: 'Land',
      country_placeholder: 'Land auswählen',
      profession: 'Fachrichtung / Spezialisierung',
      profession_placeholder: 'Fachrichtung auswählen',
      language: 'Sprache',
    },
    progression_report: {
      title:
        'Wählen Sie Pathologien aus, die Sie aus der Verfolgung im Pathologieverlauf entfernen möchten',
      subtitle:
        'Wenn Sie die Pathologie deaktivieren, wird sie im Pathologieverlauf nicht angezeigt.',
      on: 'ein',
      off: 'aus',
      save_settings: 'Einstellungen speichern',
    },
    report_settings: {
      clinic_name: 'Klinikname',
      clinic_email: 'Klinik-E-Mail',
      save: 'Änderungen speichern',
    },
  },
  sign_in_page: {
    email_label: 'Unternehmens-E-Mail',
    password_label: 'Passwort',
    forgot_password: 'Passwort vergessen?',
    sign_in: 'Anmelden',
    or: 'oder',
    sign_in_with_google: 'Mit Google anmelden',
  },
  subscription_page: {
    b2c: {
      subscription: 'Abonnement',
      billing: 'Abrechnung',
      subscription_expired:
        'Das Abonnement, das Sie derzeit verwenden, ist abgelaufen. Bitte führen Sie ein Upgrade auf ein neues durch',
      subscription_page: 'Abonnementseite',
      unsubscribe: 'Sie können sich abmelden.',
      already_unsubscribed:
        'Sie haben Ihr altes Abonnement bereits gekündigt. Ihr aktueller Plan ist bis zum {{date}} aktiv.',
      choose_plan:
        'Wählen Sie Ihren perfekten Plan. Keine Verträge. Keine überraschenden Gebühren.',
    },
    common: {
      retina_layers_detected: 'Von Altris KI erkannte Netzhauterkrankungen',
    },
  },
  team_members_page: {
    title: 'Teammitglieder',
    invite_doctor: 'Arzt einladen',
    max_doctors:
      'Es gibt eine maximale Anzahl von Ärzten, die Sie einladen können. Wenn Sie jemanden neu einladen möchten, müssen Sie zuerst einen aus Ihrem Konto löschen.',
  },
  components: {
    aside: {
      back: 'Zurück',
    },
    context_modal: {
      smart_report: {
        create_examination_report: ' Untersuchungsbericht erstellen',
        standard: 'Standard',
        standard_plus: 'Standard+',
        advanced: 'Erweitert',
        custom: 'Benutzerdefiniert',
        progression: 'Verlauf',
        creating_report: 'Bericht wird erstellt...',
        loading_resources_for_report:
          'Lade Ressourcen für Bericht {{percentage}}',
        cancel: 'Abbrechen',
        preview: 'Vorschau',
        components_loaded: 'Geladene Komponenten:',
        images_loaded: 'Geladene Bilder:',
        heatmaps_loaded: 'Geladene Heatmaps:',
        progression_report: {
          description1:
            'Visualisierung der Entwicklung von Pathologien im Laufe der Zeit unter Verwendung von B-Scan-Bereich, En-face-Bereich und Volumina. Bitte wählen Sie die spezifischen interessierenden Pathologien aus, die im Bericht enthalten sein sollen.',
          important: 'Wichtiger Hinweis:',
          description2:
            'Wählen Sie nur Pathologien aus, die von Interesse sind, um zu verhindern, dass der Bericht übermäßig umfangreich wird.',
          select_pathologies: 'Pathologien auswählen:',
          all: 'Alle',
          on: 'AN',
          off: 'AUS',
        },
        preset: {
          report_includes: 'Bericht enthält:',
          severity_detection: 'Schweregraderkennung',
          severity_detection_description:
            'Zeigt an, wie viele schwere Scans in der Untersuchung vorhanden sind und wie schwer sie sind.',
          oct_description: 'Beschreibung der Altris KI OCT-Analyse',
          oct_description_description:
            'Beinhaltet eine Textbeschreibung der Untersuchungsanalyse.',
          retina_layers: 'Netzhaut-Schichten Segmentierung',
          retina_layers_description:
            'Stellt alle Netzhautschichten dar, die zur besseren Verwendung in verschiedenen Farben hervorgehoben sind.',
          both_eyes: 'Beide-Augen-Ansicht',
          both_eyes_description:
            'Zeigt zwei gegenüberliegende Augen zum Vergleich des klinischen Zustands.',
          both_eyes_unavailable:
            'Sie sollten einen zweiten Scan in der "Beide-Augen-Ansicht" hinzufügen, um dies zu aktivieren.',
          etdrs_calculations: 'ETDRS-Berechnungen',
          etdrs_calculations_description:
            'Beinhaltet die durchschnittliche Dicke von 1, 3 und 6 mm; Dicke mehrerer Schichten (falls auf der Plattform ausgewählt); Dicke der ausgewählten Schicht (falls auf der Plattform ausgewählt).',
          comparison: 'Dickenvergleich',
          comparison_description:
            'Beinhaltet zwei Untersuchungen desselben Auges und Dickenänderungen auf ETDRS.',
          comparison_unavailable:
            'Sie sollten einen zweiten Scan in "Vergleich" hinzufügen, um dies zu aktivieren.',
          auto_measurements: 'Automatische Messungen',
          auto_measurements_description:
            'Beinhaltet lineare Messungen von Pathologien, die automatisch berechnet werden.',
          manual_measurements: 'Manuelle Messungen',
          manual_measurements_description:
            'Beinhaltet lineare Messungen von Pathologien, die manuell berechnet werden (falls auf der Plattform ausgewählt).',
          segmentation_area: 'Segmentierungsbereich',
          segmentation_area_description:
            'Zeigt eine Liste aller Pathologien in der Untersuchung und deren B-Scan- und En-Face-Bereiche.',
          segmentation_volume: 'Segmentierungsvolumen',
          segmentation_volume_description:
            'Zeigt eine Liste aller Pathologien in der Untersuchung und deren Volumen (in mm^3 oder nL).',
          pathology_detection:
            'Pathologieerkennung (3 am meisten pathologische Scans)',
          pathology_detection_description:
            'Beinhaltet die drei pathologischsten Scans, alle Segmentierungen und Klassifizierungen für diese Scans.',
          referral_urgency_score: 'Empfehlungsdringlichkeitswert',
          referral_urgency_score_description:
            'Zeigt an, wie dringend eine weitere Überweisung für diesen Fall erforderlich ist, abhängig von den erkannten Klassifizierungen.',
          glaucoma: 'Glaukom-Risikoanalyse',
          glaucoma_description:
            'Die Glaukom-Risikoanalyse ist nur für Untersuchungen verfügbar, bei denen pathologische Zeichen den GCC nicht beeinträchtigen.',
        },
        custom_report: {
          analyze_other_scan: 'Analysieren Sie einen anderen Scan',
          check_scan_that_you_want_to_analyze:
            'Markieren Sie den Scan, den Sie analysieren möchten.',
          etdrs_calculations_description:
            'Nur für Dicom-Dateien zulässig, die ETDRS-Informationen enthalten. Bitte warten Sie, bis die ETDRS-Analyse erfolgreich abgeschlossen ist, um die korrekte Ansicht im Bericht zu erhalten.',
          auto_measurements_description:
            'Ihre Messanalyse muss abgeschlossen sein, um dies zu aktivieren.',
          auto_measurements_unavailable:
            'Dieser Service ist in Ihrem Abonnement nicht verfügbar. Bitte auf Pro upgraden, um ihn zu nutzen.',
          manual_measurements_description:
            'Ihre Messanalyse sollte abgeschlossen sein und Sie sollten eine Linie innerhalb der manuellen Messungen ziehen, um dies zu aktivieren.',
          manual_measurements_unavailable:
            'Dieser Service ist in Ihrem Abonnement nicht verfügbar. Bitte auf Pro upgraden, um ihn zu nutzen.',
          segmentation_area_description:
            'Ihre Messanalyse muss abgeschlossen sein, um dies zu aktivieren.',
          segmentation_area_unavailable:
            'Dieser Service ist in Ihrem Abonnement nicht verfügbar. Bitte auf Pro upgraden, um ihn zu nutzen.',
          both_eyes: 'Beide Augen',
          segmentation_volume_description:
            'Ihre Messanalyse muss abgeschlossen sein, um dies zu aktivieren.',
          segmentation_volume_unavailable:
            'Dieser Service ist in Ihrem Abonnement nicht verfügbar. Bitte auf Pro upgraden, um ihn zu nutzen.',
          comparison: 'Vergleich',
          selected_scan: 'Ausgewählter Scan:',
          select_other: 'Andere auswählen',
          added_scans: 'Hinzugefügte Scans:',
          add_other: 'Anderes hinzufügen',
          no_selected_layers: 'Keine ausgewählten Schichten.',
          select_layers: 'Schichten auswählen',
        },
      },
    },
    countdown_timer: {
      days: 'Tage',
      hours: 'Stunden',
      minutes: 'Minuten',
      seconds: 'Sekunden',
    },
    create_examination: {
      upload_archived_file:
        'Hochladen eines archivierten Ordners (.zip), der von Ihrem OCT exportiert wurde. In erster Linie relevant für Heidelberg Engineering OCT.',
      create_patient_from_dicom: 'Neuen Patienten aus einem DICOM erstellen',
      create_patient_from_dicom_description:
        'Informationen zum Patienten werden aus der DICOM-Datei abgerufen.',
      notes_label: 'z.B.: Meine Notiz',
      notes_placeholder: 'Notizen zur Untersuchung',
      file_label: 'DICOM hochladen, um fortzufahren',
      multiple_file_label:
        'Laden Sie bis zu zwei DICOM-Dateien desselben Patienten hoch',
      multiple_file_description:
        'Stellen Sie sicher, dass sie zu einem Patienten gehören und rechtes und linkes Auge umfassen.',
      scan_width_label: 'Scanbreite',
      scan_width_placeholder: 'Breite',
      scan_width_message:
        'Stellen Sie sicher, dass Sie die richtige Scanbreite ausgewählt haben, da diese die Messungen in der Untersuchung beeinflusst.',
      eye_label: 'Auge',
      eye_placeholder: 'Typ',
      examination_date: 'Untersuchungsdatum',
      upload_images_to_complete: 'Bilder hochladen, um fortzufahren',
      right: 'Rechts',
      left: 'Links',
    },
    current_plan_info: {
      current_plan: 'Aktueller Plan',
      examinations_available: 'Verfügbare Untersuchungen',
      examinations_per_month: '{{count}} / Monat',
      start_date: 'Startdatum',
      expiration_date: 'Ablaufdatum',
      period: 'Zeitraum',
      amount: 'Betrag',
      status: 'Status',
    },
    detailed_scan: {
      scan_detail: 'Scan-Details',
    },
    drag_drop_list: {
      different_scan_count:
        'Zwei Untersuchungen haben eine unterschiedliche Anzahl von Scans, sodass der Scrollvorgang nicht synchronisiert werden kann.',
      add: 'Hinzufügen',
      examination: 'Untersuchung',
      no_scans_with_such_severity: 'Keine Scans mit diesem Schweregrad',
      average_thickness: '{{metrics}} mm durchschnittliche Dicke, μm',
    },
    error_fallback: {
      something_went_wrong: 'Es ist ein Fehler aufgetreten',
      fix_problem: 'Problem beheben',
    },
    etdrs_target: {
      single_layer_thickness: 'Einzelne Schichtdicke',
      multiple_layers_thickness: 'Mehrschichtdicke',
      gcc_thickness: 'GCC-Dicke',
    },
    examination_list: {
      empty: {
        title: 'Ihre Projektliste ist leer',
        subtitle:
          'Sie können eine neue Untersuchung hinzufügen, indem Sie auf drücken',
        add_new_examination: 'Neue Untersuchung hinzufügen',
      },
      header: {
        examinations: 'Untersuchungen',
        uploaded: 'Hochgeladen',
        date: 'Datum',
      },
      item: {
        eye: 'Auge',
        examination_failed: 'Untersuchung fehlgeschlagen',
        right_short: 'R',
        left_short: 'L',
        image: 'Bild',
        edit: 'Bearbeiten',
        delete: 'Löschen',
      },
    },
    exam_stat_info: {
      no_severity: 'Keine Auffälligkeit (Grün):',
      moderate_severity: 'Moderate Auffälligkeit (Gelb):',
      severe: 'Stark ausgeprägt (Rot):',
      scans: 'Scans',
      severity: 'Auffälligkeit',
    },
    feedback: {
      title: 'Kundenfeedback',
    },
    first_subscription: {
      subscription: 'Abonnement',
      chose_plan:
        'Wählen Sie Ihr perfektes Paket. Keine Verträge. Keine versteckten Kosten.',
    },
    form_components: {
      file_upload: {
        upload_your_date_here: 'Laden Sie Ihre Daten hier hoch',
        add_more_examinations: 'Weitere Untersuchungen hinzufügen',
        remove_file: 'Datei entfernen{{ending}}',
        upload_file: 'datei hochladen',
        you_added_files_one: 'Sie haben {{count}} Datei hinzugefügt',
        you_added_files_other: 'Sie haben {{count}} Dateien hinzugefügt',
      },
    },
    form_wrap: {
      help: 'Hilfe',
    },
    header: {
      view_my_account: 'Mein Konto anzeigen',
      sign_out: 'Abmelden',
      subscriptions: 'Abonnements',
      examinations_left: 'Verbleibende Untersuchungen',
      unlimited: 'Unbegrenzt',
      add_new_patient: 'Neuen Patienten hinzufügen',
      add_new_examination: 'Neue Untersuchung hinzufügen',
      menuItems: {
        patients: 'Patienten',
        settings: 'Einstellungen',
        subscription: 'Abonnement',
        team_members: 'Teammitglieder',
        contact_us: 'Kontaktieren Sie uns',
        onboarding: 'Einarbeitung',
      },
    },
    layers_filter: {
      hide: 'Ausblenden',
      show_more: 'Mehr anzeigen',
      no_items: 'KI hat keine Segmentierungsbereiche definiert.',
      all: 'Alle',
      layers: 'Schichten:',
      segmentations: 'Segmentierungen:',
      highlight: 'Hervorheben',
    },
    layout: {
      private_layout: {
        skip: 'Überspringen',
        demo_banner_title:
          'Beginnen Sie nach der Video-Präsentation mit einer kostenlosen 14-tägigen Testversion',
        book_a_demo: 'Vereinbaren Sie einen Anruf',
      },
      simple_layout: {
        sign_out: 'Abmelden',
      },
    },
    modal: {
      analyze_scans: {
        severity: 'Auffälligkeit',
        analyze_all_scans: 'Alle Scans analysieren',
        no_scans: 'Keine Scans mit diesen Parametern.',
        try_another_filter: 'Bitte versuchen Sie es mit anderen Filtern.',
        submit: 'Einreichen',
      },
      booking_meeting: {
        title:
          'Wir sind sicher, dass wir die perfekte Lösung für Sie finden können!',
        book_a_demo: 'Demo buchen',
      },
      buy_additional_items: {
        buy_additional_examinations:
          'Kaufe {{number}} zusätzliche Untersuchungen',
        will_be_cost: '{{description}} kostet {{symbol}}{{price}}',
      },
      create_examination: {
        title1:
          'Füllen Sie das untenstehende Formular aus, um eine neue Untersuchung hinzuzufügen. Hochladen ',
        title2: 'NUR Makulazonen-Horizontale B-Scans',
        title3: ' (Fundusbild, Scans der Papille ',
        title4: 'werden NICHT',
        title5: ' unterstützt)',
        select_exam_label: 'Untersuchung auswählen',
        patient_id_label: 'Patienten-ID',
        patient_id_placeholder: 'Der Patient wird aus der DICOM-Datei erstellt',
        remaining_examinations: 'Verbleibende Untersuchungen: {{number}}',
        unlimited: 'Unbegrenzt',
        images: 'Bilder',
        folder: 'Ordner',
        add_examination: 'Untersuchung hinzufügen',
        right: 'Rechts',
        left: 'Links',
        label:
          '{{name}} / Untersuchung: {{data}} / Auge: {{eye}} / Scan: {{scan}}',
      },
      create_patient: {
        no_exam_left1: 'Keine Untersuchungen mehr vorhanden. Sie können ',
        no_exam_left2: 'Ihr Paket upgraden',
        no_exam_left3: ', um weitere Untersuchungen hinzuzufügen.',
        create_new_patient_from_dicom:
          'Neuen Patienten aus einem DICOM erstellen',
        create_new_patient_from_dicom_description:
          'Informationen zum Patienten werden aus der DICOM-Datei abgerufen.',
        patient_id_label: 'Patienten-ID',
        example_short: 'z.B.',
        first_name_label: 'Vorname des Patienten',
        last_name_label: 'Nachname des Patienten',
        birth_date_label: 'Geburtsdatum des Patienten',
        gender_label: 'Geschlecht',
        gender_placeholder: 'Auswählen...',
        notes_label: 'Notizen',
        add_examination: 'Untersuchung hinzufügen',
        add_patient: 'Patienten hinzufügen',
        add_patient_and_examination: 'Patienten und Untersuchung hinzufügen',
        male: 'Männlich',
        female: 'Weiblich',
        other: 'Andere',
      },
      current_plan: {
        annual: 'Jährlich',
        monthly: 'Monatlich',
        days_left: 'Verbleibende Tage:',
        examinations_left: 'Verbleibende Untersuchungen:',
        unlimited: 'Unbegrenzt',
        invitations_left: 'Verbleibende Einladungen:',
        no_scheduled_payment:
          'Sie haben keine nächste geplante Zahlung, da Sie kein Abonnement abgeschlossen haben.',
        next_payment_related_to_your_upcoming_plan:
          'Ihre nächste Zahlung bezieht sich auf Ihr kommendes Abonnement und beträgt ',
        it_will_automatically_charge: 'Sie wird automatisch am ',
        your_next_payment_is: 'Ihre nächste Zahlung ist ',
        to_be_charged: ', fällig am ',
        payment_will_be_charged_automatically:
          'Die Zahlung wird automatisch abgebucht.',
        you_successfully_unsubscribed: 'Sie haben sich erfolgreich von einem ',
        plan: 'Abonnement abgemeldet',
        your_subscription_is_active: 'Ihr Abonnement ist bis zum ',
        subscribe: 'Abonnieren',
        unsubscribe: 'Abmelden',
        continue_subscriptions: 'Abonnements verwalten',
      },
      delete_exam: {
        cancel: 'Abbrechen',
        delete: 'Löschen',
      },
      delete_patient: {
        cancel: 'Abbrechen',
        delete: 'Löschen',
      },
      delete_team_member: {
        cancel: 'Abbrechen',
        delete: 'Löschen',
      },
      download_dcm: {
        dont_have_dicom_file: 'Haben Sie gerade keine DICOM-Datei?',
        use_this: 'Verwenden Sie dies!',
        download: 'Herunterladen',
        i_have_dicom: 'Ich habe DICOM auf meinem PC',
        download_dicom: 'DICOM herunterladen',
      },
      edit_scan_description: {
        oct_description_title: 'Beschreibung der Altris AI OCT-Analyse',
        save: 'Änderungen speichern',
      },
      export_json: {
        dicom_presentation_state: 'DICOM-Präsentationsstatus',
        file: 'Datei',
      },
      glaucoma_disclaimer: {
        title: 'Haftungsausschluss',
        i_acknowledged: 'Ich bestätige',
      },
      image_cropper: {
        cancel: 'Abbrechen',
        crop: 'Zuschneiden',
      },
      invite_team_member: {
        email_label: 'Email',
        first_name_label: 'Vorname des Arztes',
        last_name_label: 'Nachname des Arztes',
        example_short: 'z.B.',
        send_the_invitation: 'Einladung senden',
      },
      no_objects_left: {
        examinations: {
          title: 'Sie können keine weiteren Untersuchungen hinzufügen',
          subtitle:
            'Neue Untersuchungen können bis {{endData}} nicht hinzugefügt werden, da das Untersuchungslimit für Ihr Abonnement in diesem Monat überschritten wurde.',
          subtext:
            'Ab {{endData}} können Sie wieder neue Untersuchungen hinzufügen.',
        },
        plan: 'Abonnement',
        annual: 'Jährlich',
        monthly: 'Monatlich',
        days_left: 'Verbleibende Tage:',
        renewal_starts_at: ' (Verlängerung beginnt am: {{endData}})',
        examinations_left: 'Verbleibende Untersuchungen:',
        invitations_left: 'Verbleibende Einladungen:',
        go_to_contact_page: 'Zur Kontaktseite gehen',
        please_contact_account_holder1: 'Bitte wenden Sie sich an Ihren ',
        please_contact_account_holder2: 'Kontoinhaber',
        please_contact_account_holder3: ' um Hilfe zu erhalten.',
        b2c: {
          buy_examinations_or_upgrade_plan1: 'Sie können auch ',
          buy_examinations_or_upgrade_plan2:
            'zusätzliche Untersuchungen kaufen',
          buy_examinations_or_upgrade_plan3: ' oder ',
          buy_examinations_or_upgrade_plan4: 'Ihr Abonnement upgraden',
          upgrade_plan1: 'Sie können ',
          upgrade_plan2: 'Ihr Abonnement upgraden',
          buy_plan: '{{name}} kaufen',
          upgrade_to:
            'Upgrade auf {{name}} mit {{count}} Untersuchungen / Monat',
          renew_subscription: 'Abonnement verlängern',
          upgrade_to_custom_plan: 'Upgrade auf individuelles Abonnement',
        },
      },
      onboarding_tour: {
        cancel: 'Abbrechen (Ich bin mit Altris AI vertraut)',
        submit: 'Kurze Einführung starten',
      },
      renew_subscribe_reject: {
        please_contact_account_holder1: 'Bitte wenden Sie sich an Ihren ',
        please_contact_account_holder2: 'Kontoinhaber',
        please_contact_account_holder3: ' um Hilfe zu erhalten.',
        not_renew_subscription1:
          'Wenn Sie das Abonnement nicht verlängern möchten, senden Sie bitte eine Anfrage zum ',
        not_renew_subscription2: 'Herunterladen Ihrer Daten',
        not_renew_subscription3:
          ' um sie nicht zu verlieren. Bei Fragen können Sie sich gerne per E-Mail an uns wenden: ',
        not_renew_subscription4: 'support@altris.ai',
      },
      renew_subscribe_warning: {
        please_contact_account_holder1: 'Bitte wenden Sie sich an Ihren ',
        please_contact_account_holder2: 'Kontoinhaber',
        please_contact_account_holder3: ' um Hilfe zu erhalten.',
        not_renew_subscription1:
          'Wenn Sie das Abonnement nicht verlängern möchten, senden Sie bitte eine Anfrage zum ',
        not_renew_subscription2: 'Herunterladen Ihrer Daten',
        not_renew_subscription3:
          ' um sie nicht zu verlieren. Bei Fragen können Sie sich gerne per E-Mail an uns wenden: ',
        not_renew_subscription4: 'support@altris.ai',
      },
      resend_team_member: {
        cancel: 'Abbrechen',
        send_the_invitation: 'Einladung senden',
      },
      select_layers: {
        layers_grayscale: 'Ebenen Graustufen',
        layers_grayscale_description: 'Ihr Scan im Bericht wird grau sein.',
        confirm: 'Bestätigen',
      },
      subscribe_plan: {
        subscription_update: 'Abonnement-Aktualisierung',
        are_you_sure_subscribe_free_plan:
          'Möchten Sie sicher ein kostenloses Abonnement abschließen?',
        no: 'Nein',
        yes: 'Ja',
        subscribe_with: 'Abonnieren',
        with_5_free_days: 'mit 5 freien Tagen',
        plan_charge:
          'Eine {{interval}}liche Gebühr von {{symbol}}{{price}}{{additional_item}} wird erhoben.',
        monthly_plan_description:
          '*Der Rabatt gilt nur für die sechs {{interval}} des Abonnements',
        daily_plan_description:
          '*Der Rabatt gilt nur für die ersten {{interval}} des Abonnements',
      },
      survey_unsubscribe: {
        reason: 'Grund',
        leave_your_feedback: 'Hinterlassen Sie Ihr Feedback',
        submit: 'Einreichen',
      },
      change_single_scan_tab: {
        dont_show_hint:
          'Diesen Hinweis nicht jedes Mal anzeigen, wenn ich den Single Scan-Tab öffne (diese Option kann in den Einstellungen geändert werden)',
        later: 'Später',
        open_pathologies_detection_tab:
          'Registerkarte Pathologie-Erkennung öffnen',
      },
      trial_period_end: {
        title: 'Probezeit abgelaufen',
        subtitle:
          'Ihre Probezeit ist am <0>{{date}}</0> abgelaufen. Um den vollen Umfang des Produkts zu erwerben oder weitere Informationen zu erhalten, kontaktieren Sie bitte unser <1>Vertriebsteam</1>.',
        button: 'Ich habe es zur Kenntnis genommen',
      },
      update_exam: {
        patient_id: 'Patienten-ID',
        width: 'Breite',
        scan_width: 'Scanbreite',
        right: 'Rechts',
        left: 'Links',
        scan_width_message:
          'Stellen Sie sicher, dass Sie die richtige Scanbreite ausgewählt haben, da diese die Messungen in der Untersuchung beeinflusst.',
        eye: 'Auge',
        type: 'Typ',
        examination_date: 'Untersuchungsdatum',
        notes_label: 'Notizen',
        example_short: 'z.B.',
        update_examination: 'Untersuchung aktualisieren',
      },
      update_patient: {
        patient_id_label: 'Patienten-ID',
        example_short: 'z.B.',
        first_name_label: 'Vorname des Patienten',
        last_name_label: 'Nachname des Patienten',
        birth_date_label: 'Geburtsdatum des Patienten',
        gender_label: 'Geschlecht',
        gender_placeholder: 'Auswählen...',
        notes_label: 'Notizen',
        add_examination: 'Untersuchung hinzufügen',
        update_patient: 'Patient aktualisieren',
        male: 'Männlich',
        female: 'Weiblich',
        other: 'Andere',
      },
    },
    no_pathology: {
      title: 'Alles gut!',
      subtitle: 'Keine Pathologie entdeckt',
    },
    opacity_changer: {
      opacity: 'Deckkraft',
    },
    open_exam_button: {
      text: 'Diese Untersuchung öffnen',
    },
    pathologies_progression: {
      title: 'Pathologienverlauf',
      add_another_examination:
        'Fügen Sie eine weitere Untersuchung hinzu, um den Verlauf der Pathologien zu überprüfen.',
      no_pathologies:
        'Keine Anzeigeoption für den Verlauf von Pathologien, da keine Pathologien erkannt wurden.',
      selected_pathology: 'Ausgewählte Pathologie',
      volume: 'Volumen',
      units_in_etdrs: 'Einheiten in ETDRS',
      b_scan_area: 'B-Scan Bereich',
      en_face_area: 'En Face Bereich',
      total_change: 'Gesamtänderung',
      search_pathologies: 'Pathologien suchen',
      number_of_pathological: 'Anzahl der pathologischen Bereiche: {{number}}',
    },
    pay_by_stripe: {
      add_new_card: 'oder neue Kredit-/Debitkarte hinzufügen',
      pay_via: 'Mit Kredit-/Debitkarte bezahlen',
    },
    payment_info: {
      no_payments: 'Es gibt noch keine Zahlungen',
      you_can_subscribe:
        'Sie können das Abonnement abschließen, indem Sie auf die Schaltfläche unten klicken.',
      subscribe_plan: 'Abonnement abschließen',
      description: 'Beschreibung',
      date: 'Datum',
      time: 'Uhrzeit',
      charged: 'Belastet',
      status: 'Status',
    },
    range_filter: {
      left: 'Links',
      right: 'Rechts',
      horizontal: 'Horizontal',
    },
    referral_score: {
      title: 'Dringlichkeit der Überweisung',
      good_message: 'Keine Überweisung erforderlich',
      bad_message: 'Dringende Überweisung erforderlich',
    },
    scan_description: {
      title: 'Beschreibung der Altris AI OCT-Analyse',
      left: 'Links',
      right: 'Rechts',
      eye: 'Auge',
      average_rnfl_rpe:
        'Durchschnittliche RNFL-RPE-Dicke in einem zentralen fovealen Ring mit 1 mm Durchmesser',
      following_pathological_signs:
        'Folgende pathologische Zeichen wurden visualisiert',
      changes_could_be_classified_as:
        'Diese Veränderungen können klassifiziert werden als',
      edit: 'Bearbeiten',
      copy: 'Kopieren',
      show_more: 'Mehr anzeigen',
      hide: 'Ausblenden',
    },
    scan_overlay: {
      length: 'Länge',
    },
    segmentation_table: {
      low_confidence: 'Geringe Zuversicht',
      low_confidence_description:
        'Altris AI hat diese Klassifizierung mit geringer Zuversicht erkannt.',
      no_classification: 'Keine Klassifizierung für diesen Scan.',
      banner_tooltip:
        'Es werden nur Krankheiten angezeigt, die von Ihrem Plan abgedeckt sind. Es wurden jedoch noch weitere Pathologien identifiziert. Wenn Sie diese hervorheben möchten, aktualisieren Sie bitte Ihren Plan.',
      more_pathologies_found:
        'Es wurden weitere Pathologien gefunden, die in Ihrem Plan fehlen. Um diese überprüfen zu können, aktualisieren Sie Ihren Plan.',
      upgrade_plan: 'Plan aktualisieren',
    },
    severity_level: {
      no_severity: 'Keine Dringlichkeit in der Untersuchung',
      no_severity_plural: 'Keine Dringlichkeit in den Untersuchungen',
      yellow_severity_scan:
        'Untersuchung enthält Scan mit gelber Dringlichkeit',
      yellow_severity_scan_plural:
        'Scan mit gelber Dringlichkeit in den Untersuchungen',
      red_severity_scan: 'Untersuchung enthält Scan mit roter Dringlichkeit',
      red_severity_scan_plural:
        'Scan mit roter Dringlichkeit in den Untersuchungen',
    },
    severity_select: {
      sort: 'Sortieren',
      sort_by: 'Sortieren nach',
      high_low: 'Dringlichkeit: Hoch - Niedrig',
      low_high: 'Dringlichkeit: Niedrig - Hoch',
      in_order: 'In Reihenfolge',
    },
    single_scan: {
      add_to_report: 'Zum Bericht hinzufügen',
      review_other_scans: 'Andere Scans überprüfen',
      scan_quality_tooltip1:
        'Beachten Sie, dass nur Scans mit einer Qualität von 4 oder höher als Qualitäts-Scans betrachtet werden können.',
      scan_quality_tooltip2:
        'Altris AI kann diese zwar immer noch klassifizieren und segmentieren, die Ergebnisse können jedoch ungenau sein.',
      central_scan: 'Zentraler Scan',
      severity: 'Dringlichkeit',
      severity_tooltip:
        'Die Scans werden entsprechend der Dringlichkeit der pathologischen Zeichen markiert. Grün - normale Netzhaut, Gelb - mäßige Dringlichkeit und Rot - schwere Dringlichkeit.',
      yellow: 'Gelb',
      red: 'Rot',
      green: 'Grün',
      draw_a_line: 'Linie zeichnen',
      draw_a_line_info: 'Linke Maustaste drücken und ziehen',
      segmentations: 'Segmentierungen',
      highlight: 'Hervorheben',
    },
    smart_report: {
      average_thickness: 'Durchschnittsdicke 1/3/6 mm, μm',
      gcc_thickness: 'GCC-Dicke',
      both_eyes_view: 'Beide-Augen-Ansicht',
      thickness_comparison: 'Dickenvergleich',
      retina_thickness_difference: 'Netzhautdickendifferenz',
      etdrs_calculations: 'ETDRS-Berechnungen',
      from: 'Von',
      to: 'Nach',
      etdrs_titles: {
        average_thickness: 'Durchschnittsdicke',
        single_layer_thickness: 'Einzel Schichtdicke',
        multiple_layer_thickness: 'Mehrfach Schichtdicke',
        gcc_thickness: 'GCC-Dicke',
      },
      glaucoma: 'Glaukom',
      auto: 'Automatisch',
      auto_measurements: 'Automatische Messungen',
      manual: 'Manuell',
      manual_measurements: 'Manuelle Messungen',
      segmentation_area_title:
        'Segmentierungsfläche | Scan: {{number}}/{{total}}',
      segmentation_volume_title:
        'Segmentierungsvolumen | Scan: {{number}}/{{total}}',
      retina_layers: 'Netzhaut Schichten',
      segmentation: 'Segmentierung',
      classification: 'Klassifikation',
      single_scan: 'Einzelner Scan',
      right: 'Rechts',
      left: 'Links',
      report_results: 'Befund Ergebnisse',
      clinic: 'Name der Klinik',
      clinic_email: 'E-Mail der Klinik',
      doctor: 'Arzt',
      patient_name: 'Patientenname',
      birth_day: 'Geburtstag',
      gender: 'Geschlecht',
      eye: 'Auge',
      exam_date: 'Untersuchungsdatum',
      total_scans: 'Gesamtanzahl Scans',
      pathology_progression: 'Pathologie-Entwicklung',
      selected_pathology: 'Ausgewählte Pathologie:',
      en_face_area: 'En-face-Bereich',
      b_scan_area: 'B-Scan-Bereich',
      volume: 'Volumen',
      total_change: 'Gesamtveränderung',
    },
    spinner_svg: {
      retry: 'Wiederholen',
    },
    subscription_conditions: {
      back: 'Zurück',
      detection: 'Erkennung pathologischer Zeichen mit KI (Segmentierung)',
      detection_and_visualization:
        'Erkennung und Visualisierung pathologischer Zeichen mit KI (Segmentierung)',
      view_all_pathological_signs: 'Alle pathologischen Zeichen anzeigen',
    },
    subscription_plan_card: {
      need_better_solution: 'Brauchen Sie eine bessere Lösung für Ihre Klinik?',
      personal_assistant: 'Persönlicher Assistent',
      unlimited_examinations: 'Unbegrenzte Untersuchungen',
      unlimited_patients: 'Unbegrenzte Patienten',
      separate_accounts_for_doctors: 'Separate Konten für Ärzte',
      onboarding_trainings_for_the_whole_team:
        'Onboarding-Schulungen für das gesamte Team',
      platform_customization:
        'Plattformanpassung nach Ihren Bedürfnissen und mehr.',
      contact: 'Kontakt',
      month: 'Monat',
      you_saving: 'Sie sparen',
      starting_from: 'Ab',
      details: 'Einzelheiten',
      current_plan: 'Aktueller Tarif',
      active_till: 'Aktiv bis',
      subscribe: 'Abonnieren',
      days_free_trial: '{{number}} Tage kostenlose Testversion',
      best_choice: 'Beste Wahl',
      monthly_description:
        '*Der Rabatt gilt nur für die ersten sechs {{pricingType}} des Abonnements',
      daily_description:
        '*Der Rabatt gilt nur für die ersten {{pricingType}} des Abonnements',
      unlimited: 'Unbegrenzt',
      examinations_for_ai_screening:
        'Untersuchungen für KI-Screening, KI-Schichten Segmentierung',
      pathological_signs_detection:
        'Erkennung und Visualisierung pathologischer Zeichen mit KI',
      try_for_free: 'Kostenlos testen',
      month_small: 'Monat',
      year_small: 'Jahr',
    },
    subscription_plans: {
      subscription: 'Abonnement',
      price: 'Preis',
      examinations_available: 'Verfügbare Untersuchungen',
      detect: 'Erkennen',
      retina_conditions: 'Netzhauterkrankungen',
      tooltip_text:
        'Diese Funktion befindet sich derzeit in der Entwicklung und wird auf der Plattform erscheinen, wenn sie fertig ist.',
      services: 'Dienstleistungen',
      service: 'Dienstleistung',
    },
    team_member_list: {
      doctors: 'Ärzte',
      email: 'E-Mail',
      status: 'Status',
      access_level: 'Zugriffsstufe',
      examination_created: 'Untersuchung erstellt',
      actions: 'Aktionen',
      no_doctors: 'Es wurden noch keine Ärzte eingeladen.',
      you_can_add_team_member:
        'Sie können ein neues Teammitglied hinzufügen, indem Sie auf',
      invite_doctor: 'Arzt einladen',
      me: 'ICH',
      no_actions: 'Keine Aktionen',
      resend_invitation: 'Einladung erneut senden',
      delete: 'Löschen',
      active: 'AKTIV',
      pending: 'AUSSTEHEND',
      invite_expired: 'EINGELADUNG ABGELAUFEN',
    },
    titled_switcher: {
      on: 'AN',
      off: 'AUS',
    },
    upload_image: {
      upload_photo: 'Foto hochladen',
      info: 'Format: {{formats}}. Die maximale Dateigröße ist {{size}}.',
    },
    welcome: {
      title:
        'Mit der Altris AI Plattform wird die Interpretation von OCT-Scans einfach',
      subtitle1:
        'Die Altris AI Plattform funktioniert nur auf Windows oder Mac (KEINE mobile App).',
      subtitle2:
        'Die Registrierung kann jedoch über Ihr Mobiltelefon erfolgen.',
      list_item_title1:
        'Differenzierung zwischen pathologischen und nicht-pathologischen B-Scans in 1 Minute',
      list_item_title2:
        'Erkennung von über 70 Netzhautpathologien, einschließlich seltener Pathologien',
      list_item_title3: 'Analyse der Dicke der Netzhautschichten',
      how_it_works: 'Wie funktioniert es?',
      description1:
        'Altris AI hält sich vollständig an die DSGVO und gewährleistet die Einhaltung. Alle Benutzerdaten sind verschlüsselt und für Dritte unzugänglich. Wir verfügen über ein CE-Zertifikat und eine FDA 510-Zulassung, die unser Engagement für regulatorische Standards bestätigen.',
      description2:
        'Die Plattform wurde von einem Team von Netzhautexperten unter der Aufsicht des medizinischen Leiters, einem Ph.D. in Augenheilkunde, entwickelt.',
      description3:
        'Altris AI wird in der Ophthalmology Times, Eyewire, Eyes on Eyecare, The Ophthalmologist vorgestellt und von der augenärztlichen Gemeinschaft unterstützt.',
      features_item_title1: 'Format.',
      features_item_title2: 'Kommunikation.',
      features_item_title3: 'Integration.',
      features_item_text1:
        'Das System funktioniert mit allen Datenformaten wie DICOM, JPG und PNG und macht das Bildverwaltungssystem in der Augenheilkunde sicherer und effizienter.',
      features_item_text2:
        'Augenärzte können Bilder innerhalb des Systems austauschen, sie diskutieren, Kommentare hinterlassen und gemeinsam an denselben Bildern arbeiten.',
      features_item_text3:
        'Das System kann auch in das EHR-System integriert werden oder als Webanwendung autonom arbeiten.',
      disclaimer:
        'Haftungsausschluss: Altris AI ist in den USA nur als Forschungssoftware verfügbar. Die FDA-Zulassung befindet sich in Bearbeitung.',
      i_acknowledged: 'Bestätigt',
      learn_more: 'Mehr erfahren',
      back: 'Zurück',
      next: 'Weiter',
      slide_one: {
        title: 'Willkommen bei Altris AI',
        subtitle:
          'Analysieren Sie OCT-Untersuchungen mit Altris AI. Sparen Sie Zeit und verpassen Sie nie wieder seltene, geringfügige oder frühe Pathologien',
        desktop_available:
          'Die Altris AI Plattform funktioniert nur auf Windows oder Mac. Keine mobile App.',
        registration_with_phone:
          'Die Registrierung kann jedoch über Ihr Mobiltelefon erfolgen',
        description:
          'Vollständig DSGVO-konform, Daten werden verschlüsselt und können von keinem Dritten eingesehen werden. Wir verfügen über ein CE-Zertifikat und die FDA-Zulassung befindet sich in Bearbeitung.',
      },
      slide_two: {
        title: 'Schweregrad Scans Schweregrad',
        subtitle:
          'Analyse des Schweregrads von bis zu 512 pathologischen Scans in einer Minute',
        low_severity: 'Geringer Schweregrad',
        medium_severity: 'Mittlerer Schweregrad',
        high_severity: 'Hoher Schweregrad',
        green: 'Grün',
        yellow: 'Gelb',
        red: 'Rot',
        description:
          'Sie müssen keine Zeit damit verschwenden, alle Scans zu überprüfen, um den Interessenbereich zu finden.',
      },
      slide_three: {
        title: 'Erkennung und Visualisierung von Pathologien',
        subtitle:
          'Altria AI erkennt bei jeder Untersuchung über 70 Netzhauterkrankungen pro Scan.',
        segmentation: 'Segmentierung',
        classification: 'Klassifikation',
      },
      slide_four: {
        title: 'Und vieles mehr, um das Beste aus der Plattform zu machen',
        item_header1: 'Segmentierung der Netzhautschichten',
        item_header2: 'EDTRS- und Netzhautdickenkarte',
        item_header3: 'Dringlichkeit der Überweisung',
        item_header4: 'Automatische Messung von Pathologien',
        item_header5: 'Segmentierungsfläche',
        item_header6: 'Segmentierungsvolumen',
        item_header7: 'Smarte Berichte',
        item_text2: 'Durchschnittsdicke 1/3/6 mm und Netzhautdickenkarte',
        item_text3:
          'Altris AI schlägt je nach den entdeckten pathologischen Befunden einen Dringlichkeitswert für die Überweisung vor. Dieser reicht von "Keine Überweisung erforderlich" bis " dringende Überweisung erforderlich".',
        item_text4:
          'Altris AI schlägt je nach den entdeckten pathologischen Befunden einen Dringlichkeitswert für die Überweisung vor. Dieser reicht von "Keine Überweisung erforderlich" bis " dringende Überweisung erforderlich".',
        item_text5:
          'Altris AI berechnet automatisch die Fläche aller Segmentierungen',
        item_text6:
          'Altris AI berechnet automatisch die Fläche aller Segmentierungen',
      },
    },
  },
  constants: {
    severity_filter_options: {
      all: 'Alle',
      green: 'Grün',
      yellow: 'Gelb',
      red: 'Rot',
    },
  },
  modals: {
    glaucoma_disclaimer:
      'Bitte beachten Sie, dass die Glaukom-Risikoanalyse nur bei OCT-Untersuchungen funktioniert, bei denen die Pathologie die Dicke des Ganglienzellkomplexes nicht verändert',
    sign_out: {
      title: 'Sie versuchen sich abzumelden',
      subtitle: 'Sind Sie sicher?',
      yes: 'Ja',
      cancel: 'Abbrechen',
    },
    add_patient: {
      title: 'Neuen Patienten hinzufügen',
      subtitle:
        'Füllen Sie das Formular unten aus, um die neue Patientenkarte zur Liste hinzuzufügen',
    },
    add_examination: 'Neue Untersuchung hinzufügen',
    add_eye_examination: {
      title: 'Augenuntersuchung hinzufügen',
      subtitle:
        'Füllen Sie das Formular unten aus, um die neue Untersuchung zur Liste hinzuzufügen',
    },
    invite_team_member: {
      title: 'Arzt einladen',
      subtitle:
        'Füllen Sie das Formular unten aus, um einen neuen Arzt zur Liste hinzuzufügen',
    },
    delete_team_member: {
      title: 'Löschen',
      subtitle: 'Möchten Sie dieses Konto wirklich löschen?',
    },
    reinvite_team_member: {
      title: 'Einladung erneut senden',
      subtitle:
        'Möchten Sie die Einladung an dieses Konto wirklich erneut senden?',
    },
    delete_patient: {
      title: 'Löschen',
      subtitle: 'Möchten Sie diesen Patienten wirklich löschen?',
    },
    update_patient: {
      title: 'Patient bearbeiten',
      subtitle:
        'Füllen Sie das Formular unten aus, um die Patientenkarte in der Liste zu bearbeiten',
    },
    unsubscribe: {
      title: 'Sie versuchen sich abzumelden',
      subtitle: 'Sind Sie sicher?',
      yes: 'Ja',
      cancel: 'Abbrechen',
    },
    subscribe_fake: {
      title: 'Abonnement-Aktualisierung',
      subtitle: 'Sie werden für den kostenlosen Tarif abonniert',
      yes: 'Ja',
      cancel: 'Abbrechen',
    },
    renew_subscribe_warning: {
      title1: 'Abonnement erneuern',
      subtitle1:
        'Leider haben wir die Zahlung nicht erhalten. Das Abonnement, das Sie derzeit verwenden, ist abgelaufen. Bitte aktualisieren Sie auf ein neues Abonnement für Altris AI, sonst wird Ihr Zugriff auf Altris AI entfernt.',
      button_text1: 'Abonnement erneuern',
      title2: 'Abonnement erneuern',
      subtitle2:
        'Leider haben wir die Zahlung nicht erhalten und Ihr Tarif ist abgelaufen. Bitte erneuern Sie Ihr Abonnement, sonst wird Ihr Zugriff auf Altris AI entfernt.',
      button_text2: 'Erneuern',
    },
    renew_subscribe_warning_b2b: {
      title: 'Abonnement erneuern',
      subtitle:
        'Leider haben wir die Zahlung nicht erhalten und Ihr Tarif ist abgelaufen. Bitte erneuern Sie Ihr Abonnement, sonst wird Ihr Zugriff auf Altris AI entfernt.',
      button_text: 'Zur Kontaktseite gehen',
    },
    renew_subscribe_reject: {
      title1: 'Abonnement erneuern',
      subtitle1:
        'Leider haben wir die Zahlung nicht erhalten. Das Abonnement, das Sie derzeit verwenden, ist abgelaufen. Bitte aktualisieren Sie auf einen neuen Abonnementplan. Wir werden Ihre Daten bis zum {{date}} speichern. Danach müssen wir sie löschen.',
      button_text1: 'Abonnement erneuern',
      title2: 'Abonnement erneuern',
      subtitle2:
        'Leider haben wir die Zahlung nicht erhalten und Ihr Tarif ist abgelaufen. Bitte erneuern Sie Ihr Abonnement. Wir werden Ihre Daten bis zum {{date}} speichern. Danach müssen wir sie löschen.',
      button_text2: 'Erneuern',
    },
    renew_subscribe_reject_b2b: {
      title: 'Abonnement erneuern',
      subtitle:
        'Leider haben wir die Zahlung nicht erhalten und Ihr Tarif ist abgelaufen. Bitte erneuern Sie Ihr Abonnement. Wir werden Ihre Daten bis zum {{date}} speichern. Danach müssen wir sie löschen.',
      button_text: 'Gehe zu Kontaktseite',
    },
    analyze_scans: {
      title: 'Andere Scans analysieren',
      subtitle: 'Wählen Sie die Scans aus, die Sie analysieren lassen möchten.',
    },
    select_retina_layers:
      'Netzhautschichten zum Hinzufügen zum Bericht auswählen',
    delete_examination: {
      title: 'Löschen',
      subtitle: 'Möchten Sie diese Untersuchung wirklich löschen?',
    },
    update_examination: {
      title: 'Bearbeiten',
      subtitle:
        'Füllen Sie das Formular unten aus, um die Untersuchungskarte in der Liste zu bearbeiten',
    },
    onboarding_tour: {
      title: 'Willkommen bei Altris AI',
      subtitle: 'Was erwartet Sie bei Altris AI?',
    },
    json_exam_export: {
      title: 'Ihre Untersuchungsdaten exportieren.',
      subtitle: 'Wählen Sie aus, welche Daten Sie exportieren möchten.',
    },
    unsubscribe_survey: {
      title: 'Was ist schief gelaufen?',
      subtitle:
        'Wir haben bemerkt, dass Sie Altris Pro abbestellt haben. Bitte teilen Sie uns mit, warum?',
    },
    booking_meeting: {
      title: 'Wir können es klären!',
      subtitle:
        'Vereinbaren Sie ein kostenloses kurzes Treffen mit unserem Experten, der über 15 Jahre Erfahrung mit OCT verfügt und all Ihre Fragen zu Altris AI beantworten kann.',
    },
    download_dicom: {
      title:
        'Erstellen Sie Ihren ersten Patienten und Ihre erste Untersuchung!',
      subtitle:
        'Verwenden Sie zum Erstellen einer Untersuchung eine DICOM-Datei oder ein aus Ihrem OCT exportiertes Bild.',
    },
    download_dicom_success: {
      title: 'DICOM-Download gestartet!',
      subtitle:
        'Warten Sie, bis der Download abgeschlossen ist... Klicken Sie anschließend auf "Weiter", um einen neuen Patienten anzulegen. Erstellen Sie anschließend eine neue Untersuchung und laden Sie die DICOM-Datei hoch, um sie von Altris AI analysieren zu lassen.',
    },
    change_single_scan_tab:
      'Vergessen Sie nicht, die Pathologieerkennung (KI-gestützt!) zu überprüfen.',
  },
  validation: {
    required: 'Erforderlich',
    data_invalid: 'Die angegebenen Daten sind ungültig',
    email_invalid: 'Die angegebene E-Mail-Adresse ist ungültig',
    letters_numbers_spaces_hyphens:
      'In diesem Feld sind nur Buchstaben (en, ua, ru), Zahlen, Leerzeichen, Punkte und Bindestriche ("-") zulässig.',
    min_chars: 'Muss mindestens {{number}} Zeichen enthalten',
    max_chars: 'Darf maximal {{number}} Zeichen enthalten',
    password_latin_lowercase:
      'Das Passwort muss einen lateinischen Kleinbuchstaben enthalten',
    password_latin_uppercase:
      'Das Passwort muss einen lateinischen Großbuchstaben enthalten',
    password_number: 'Das Passwort muss eine Zahl enthalten',
    password_match: 'Passwörter müssen übereinstimmen',
  },
  notifications: {
    you_can_add_only_2_dicom: 'Sie können nur 2 DICOM-Dateien hinzufügen',
    your_data_was_added_successfully:
      'Ihre Daten wurden erfolgreich hinzugefügt',
    you_cant_add_file_of_this_format:
      'Sie können keine Datei in diesem Format hinzufügen',
    patient_was_added_successfully: 'Patient erfolgreich hinzugefügt',
    characters_limit_exceeded: '{{number}} Zeichenlimit überschritten',
    download_will_started_automatically:
      'Der Download wird automatisch gestartet, sobald alle Daten bereit sind.',
    please_enter_your_card: 'Bitte geben Sie Ihre Karte ein',
    image_size_changed_successfully: 'Bildgröße erfolgreich geändert',
    description_copied: 'Beschreibung kopiert',
    description_not_copied: 'Beschreibung nicht kopiert',
    you_already_add_this_scan_form_segmentation_report:
      'Sie haben diesen Scan bereits aus dem Segmentierungsbericht hinzugefügt',
    scan_was_successfully_added_to_the_report:
      'Scan wurde erfolgreich zum Bericht hinzugefügt',
    image_was_not_uploaded:
      'Bild konnte nicht hochgeladen werden, da es zu groß ist. Bildgröße unter 3 MB hochladen',
    resent_confirmation_email: 'Bestätigungs-E-Mail erneut gesendet',
    something_went_wrong: 'Etwas ist schief gelaufen',
    examination_etdrs_calculation_finished:
      'Untersuchung: {{id}}. ETDRS-Berechnung abgeschlossen',
    examination_etdrs_calculation_failed:
      'Untersuchung: {{id}}. ETDRS-Berechnung fehlgeschlagen',
    segmentation_layers_calculation_finished:
      'Untersuchung: {{id}}. Berechnung der Segmentierungsschichten abgeschlossen',
    segmentation_layers_calculation_failed:
      'Untersuchung: {{id}}. Berechnung der Segmentierungsschichten fehlgeschlagen',
    measurements_calculation_finished:
      'Untersuchung: {{id}}. Berechnung der Messungen abgeschlossen',
    measurements_calculation_failed:
      'Untersuchung: {{id}}. Berechnung der Messungen fehlgeschlagen',
    measurements_manual_updated: 'Messungen manuell aktualisiert',
    link_is_copied: 'Link kopiert',
    link_not_copied: 'Link nicht kopiert',
    your_request_sent_successfully: 'Ihre Anfrage wurde erfolgreich gesendet',
    you_dont_have_available_pathology_classes:
      'Sie haben keine verfügbaren Pathologieklassen',
    examination_created_successfully: 'Untersuchung erfolgreich erstellt',
    examination_deleted_successfully: 'Untersuchung erfolgreich gelöscht',
    examination_failed: 'Ihre Untersuchung ist fehlgeschlagen',
    examination_available: 'Ihre Untersuchung ist verfügbar',
    examination_updated_successfully: 'Untersuchung erfolgreich aktualisiert',
    scan_added_to_favorites: 'Scan zu den Favoriten hinzugefügt',
    scan_removed_from_favorites: 'Scan aus den Favoriten entfernt',
    measurements_reset_successfully:
      'Messungen für diesen Scan wurden zurückgesetzt',
    measurements_updated: 'Messungen aktualisiert',
    you_tried_upload_same_eye: 'Sie haben versucht, dasselbe Auge hochzuladen',
    you_tried_upload_opposite_eye:
      'Sie haben versucht, das andere Auge hochzuladen',
    limit_examination_is_reached: 'Das Limit der Untersuchungen ist erreicht',
    examinations_have_not_enough_data:
      'Ihre Untersuchung enthält nicht genügend Daten',
    patient_deleted_successfully: 'Patient erfolgreich gelöscht',
    patient_updated_successfully:
      'Patienteninformationen erfolgreich aktualisiert',
    you_successfully_buy_additional_examination:
      'Sie haben erfolgreich eine zusätzliche Untersuchung gekauft',
    settings_saved: 'Ihre Einstellungen wurden gespeichert',
    subscription_plan_updated: 'Ihr Abonnementplan wurde aktualisiert',
    subscription_canceled: 'Ihr Abonnement wurde gekündigt',
    doctor_invited: 'Arzt wurde eingeladen',
    password_changed: 'Ihr Passwort wurde geändert',
    backup_request_created:
      'Anfrage erstellt, Sie erhalten eine E-Mail, wenn Ihre Daten bereit sind',
    data_deleted: 'Ihre Daten wurden gelöscht',
    attempts_over:
      'Ihre Versuche sind erschöpft, bitte versuchen Sie es später erneut',
    company_profile_updated: 'Ihr Firmenprofil wurde aktualisiert',
    profile_updated: 'Ihr Profil wurde erfolgreich aktualisiert',
    session_expired: 'Ihre Sitzung ist abgelaufen, bitte melden Sie sich an',
    site_under_maintenance:
      'Bitte beachten Sie, dass sich die Website derzeit in der regulären Wartung befindet und alle Dienste möglicherweise vorübergehend ungenau funktionieren',
    progression_settings_updated:
      'Die Progressionseinstellungen wurden erfolgreich aktualisiert.',
  },
  user_popup_menu_links: {
    subscription: 'Abonnement',
    contact_support: 'Support kontaktieren',
  },
  profile_links: {
    subscriptions: 'Abonnements',
    team_members: 'Teammitglieder',
    contact_us: 'Kontaktieren Sie uns',
    documentation: 'Dokumentation',
    join_altris_education: 'An Altris Education teilnehmen',
    settings: 'Einstellungen',
    onboarding: 'Einarbeitung',
    storybook: 'Storybook',
  },
  onboarding_links: {
    complete_onboarding_again: 'Einarbeitung erneut durchführen',
    download_test_dicom: 'Test-DICOM herunterladen',
    create_patient: 'Patient erstellen',
    create_examintaion: 'Untersuchung erstellen',
    header: 'Kopfzeile',
    single_scan: 'Einzelner Scan',
    pathologies_detection: 'Erkennung von Pathologien',
    measurements: 'Messungen',
    all_scans: 'Alle Scans',
    both_eyes: 'Beide Augen',
    comparison: 'Vergleich',
    progression: 'Verlauf',
    report: 'Bericht',
  },
  onboarding: {
    create_examination: {
      add_new_examination: 'Neue Untersuchung hinzufügen',
      next: 'Weiter',
      examination_list: 'Untersuchungsliste',
      step_1_text_1: 'Klicken Sie',
      step_1_text_2:
        'um Ihrem Patienten eine neue OCT-Untersuchung hinzuzufügen',
      step_1_text_3: 'Klicken Sie hier, um fortzufahren',
      step_2_text_1: 'Der erste Schritt wäre,',
      step_2_text_2: 'den Patienten auszuwählen',
      step_3_text:
        'Oder wählen Sie die Option, Untersuchung UND Patienten mithilfe von DICOM zu erstellen',
      step_4_text_1: 'Was ist DICOM?',
      step_4_text_2: 'Ich bin mit DICOM vertraut',
      step_4_text_3:
        'Sie können eine Untersuchung erstellen, indem Sie Folgendes hochladen:',
      step_4_text_4: 'DICOM (bevorzugt), DICOM-Ordner',
      step_4_text_5: 'oder Bilder',
      step_4_text_6:
        'Wichtig! Stellen Sie sicher, dass Sie wissen, was DICOM ist und wie Sie es von Ihrem OCT exportieren können.',
      step_5_text_1:
        'Ziehen Sie die DICOM-Datei per Drag & Drop in das Fenster, um sie zum Hochladen auszuwählen',
      step_5_text_2: 'DICOM hochladen, um fortzufahren',
      step_6_text_1:
        'Nachdem die DICOM-/Bilderdatei hinzugefügt wurde, können Sie auf',
      step_6_text_2: 'klicken, um fortzufahren',
      step_7_text:
        'Die Untersuchung wurde hinzugefügt und ist nach der Analyse durch das System zugänglich. Sie können die Liste aller Untersuchungen des Patienten einsehen, nachdem Sie darauf geklickt haben.',
      step_8_text_1: 'Sehen wir uns an, wie die Untersuchung aussieht',
      step_8_text_2: 'Klicken Sie hier, um fortzufahren',
      step_dicom_text_1: 'Ich habe zur Kenntnis genommen',
      step_dicom_text_2: 'Was ist eine DICOM/DCOM/.dcm-Datei?',
      step_dicom_text_3: 'DICOM/DCOM/.dcm',
      step_dicom_text_4: 'ist ein Dateiformat, das Sie zum',
      step_dicom_text_5: 'Exportieren von Untersuchungen',
      step_dicom_text_6: 'von Ihrem OCT auswählen können.',
      step_dicom_text_7: 'Es umfasst:',
      step_dicom_text_8: 'Alle Scans der OCT-Untersuchung',
      step_dicom_text_9:
        'An Ihrem OCT können Sie auswählen, wie viele Scans Sie in DICOM exportieren möchten.',
      step_dicom_text_10: 'Erforderliche Untersuchungsdaten',
      step_dicom_text_11:
        'Wir empfehlen die Verwendung von DICOM anstelle von Bildern. Da Bilder nicht alle notwendigen Untersuchungsdaten enthalten, können dadurch mehrere Hauptkomponenten des Systems keine aussagekräftigen Daten darstellen.',
      step_dicom_text_12: 'Was ist nicht verfügbar, wenn ich Bilder verwende?',
      step_dicom_text_13: 'Erforderliche Patientendaten',
      step_dicom_text_14:
        'Wenn Sie DICOM anstelle von Bildern verwenden, können wir alle notwendigen Patientendaten abrufen und automatisch erstellen.',
      step_dicom_text_15:
        'Wie kann ich einen Patienten mithilfe von DICOM erstellen?',
      step_dicom_text_16: 'DICOM-Ordner',
      step_dicom_text_17:
        'Wenn Ihr OCT Daten nur in einem Ordner exportieren kann (z. B. Heidelberg Engineering OCT), können Sie diesen Ordner mit Ihrem standardmäßigen Archivierungswerkzeug archivieren und beim Erstellen einer Untersuchung eine ZIP-Datei hochladen. Ändern Sie die Ordnerstruktur nicht und archivieren Sie keine separaten Ordner innerhalb des Hauptordners. Der Ordner sollte vollständig archiviert werden.',
      step_dicom_text_18:
        'Wie exportiere ich einen DICOM-Ordner aus Heidelberg Engineering OCT?',
      step_dicom_text_19:
        'Wie kann ich eine DICOM-Datei von meinem OCT-Gerät exportieren?',
      step_dicom_text_20: 'Wie exportiere ich DICOM aus Nidek OCT?',
      step_dicom_text_21: 'Wie exportiere ich DICOM aus Topcon OCT?',
      step_dicom_text_22: 'Wie exportiere ich DICOM aus Optopol OCT?',
      step_scans_text_1: 'Nur Scans hoher Qualität!',
      step_scans_text_2:
        'Scans mit geringer Qualität können die Analyse beeinträchtigen.',
    },
    create_patient: {
      add_new_patient: 'Neuen Patienten hinzufügen',
      next: 'Weiter',
      step_1_text_1: 'Beginnen wir mit dem Hinzufügen eines neuen Patienten!',
      step_1_text_2: 'um einen neuen Patienten anzulegen',
      step_1_text_3: 'Klicken Sie hier, um fortzufahren',
      step_2_text_1:
        'Sie können sofort einen Patienten und eine Untersuchung erstellen, indem Sie eine DICOM-Datei hochladen.',
      step_2_text_2: 'Aktivieren Sie einfach das Kontrollkästchen!',
      step_3_text_1:
        'Wenn Sie keinen Patienten aus DICOM hochladen möchten, müssen Sie alle Patienteninformationen eingeben.',
      step_3_text_2:
        'Nachdem alle Patientendaten ausgefüllt wurden, klicken Sie auf',
      step_4_text_1:
        'Nachdem DICOM hochgeladen oder alle Informationen zum Patienten ausgefüllt wurden, können Sie auf',
      step_4_text_2: 'klicken, um diese zu speichern.',
      step_4_text_3: 'Klicken Sie hier, um fortzufahren',
      step_5_text_1: 'Patientenliste',
      step_5_text_2:
        'Jetzt wurde Ihr Patient hinzugefügt, sodass Sie eine Untersuchung erstellen können.',
      step_5_text_3:
        'Hinweis: Wenn Sie den Patienten per DICOM hochgeladen haben, wird dieser Schritt automatisch übersprungen.',
    },
    create_report: {
      create_report: 'Befund erstellen',
      next: 'Weiter',
      step_1_text_1:
        'Nachdem die Untersuchung abgeschlossen ist, kann der Arzt einen',
      step_1_text_2: 'Befund',
      step_1_text_3: 'erstellen. Klicken Sie hier, um fortzufahren.',
      step_2_text_1: 'Schritt 1',
      step_2_text_2:
        'Wählen Sie den Scan aus, der als Hauptdarstellung angezeigt werden soll (standardmäßig zuletzt überprüft).',
      step_2_text_3: 'Schritt 2',
      step_2_text_4:
        'Wählen Sie aus, welche Komponenten in den Bericht aufgenommen werden sollen.',
      step_2_text_5: 'Schritt 3',
      step_2_text_6:
        'Wählen Sie einen oder mehrere Scans aus, um den Nachweis von Pathologien anzuzeigen.',
      step_3_text_1: 'Drucken oder PDF speichern',
      step_3_text_2: 'Schritt 4',
      step_3_text_3: 'Klicken Sie auf',
      step_3_text_4: 'Überprüfen',
      step_3_text_5:
        'um eine Vorschau des erstellten Berichts anzuzeigen und ihn zu drucken/als PDF zu speichern.',
      step_3_text_6: 'Klicken Sie hier, um fortzufahren',
      step_4_text_1: 'Glückwunsch!',
      step_4_text_2: 'Sie haben das Onboarding abgeschlossen!',
      step_4_text_3: 'Erstellen Sie jetzt Ihren ersten Patienten!',
      step_4_text_4: 'Sie können diese Anleitung jederzeit wieder in den',
      step_4_text_5: 'Einstellungen',
    },
    general: {
      skip_onboarding: 'Einleitung überspringen',
      are_you_sure_want_to_skip:
        'Sind Sie sicher, dass Sie die Einleitung überspringen möchten?',
      continue_onboarding: 'Einleitung fortsetzen',
    },
    show_examination: {
      tab_menu: 'Registerkartenmenü',
      next: 'Weiter',
      all_scans_text_1: "Weiter geht's mit",
      all_scans_text_2: 'Alle Scans',
      all_scans_text_3: 'Klicken Sie hier, um fortzufahren',
      all_scans_text_4: 'Alle Scans',
      all_scans_text_5:
        'Sie können alle Scans überprüfen und nach Schweregrad sortieren.',
      all_scans_text_6:
        'Außerdem können Sie wählen, nur die Scans anzuzeigen, die als',
      all_scans_text_7: 'grün',
      all_scans_text_8: 'gelb',
      all_scans_text_9: 'oder',
      all_scans_text_10: 'rot',
      all_scans_text_11: 'eingestuft wurden.',
      both_eyes_text_1: "Weiter geht's mit",
      both_eyes_text_2: 'Beide Augen',
      both_eyes_text_3: 'Klicken Sie hier, um fortzufahren',
      both_eyes_text_4: 'Beide Augen',
      both_eyes_text_5:
        'Sie können ein zweites Auge hinzufügen, um das vollständige Bild zu überprüfen.',
      comparison_text_1: "Weiter geht's mit",
      comparison_text_2: 'Vergleich',
      comparison_text_3: 'Klicken Sie hier, um fortzufahren',
      comparison_text_4: 'Vergleich',
      comparison_text_5:
        'Fügen Sie dasselbe zuvor diagnostizierte Auge hinzu, um es mit der aktuellen Untersuchung zu vergleichen.',
      measurements_text_1: "Weiter geht's mit",
      measurements_text_2: 'Messungen',
      measurements_text_3: 'Klicken Sie hier, um fortzufahren',
      measurements_text_4: 'Messungen',
      measurements_text_5: 'Mit den Schnittmessungen können Ärzte:',
      measurements_text_6: 'Überprüfen',
      measurements_text_7: 'Höhe',
      measurements_text_8: 'und',
      measurements_text_9: 'Länge',
      measurements_text_10: 'von automatisch gemessenen Segmentierungen',
      measurements_text_11: 'Zeichnen',
      measurements_text_12:
        'Linien zur Berechnung der interessierenden Bereiche',
      measurements_text_13: 'Überprüfen der Segmentierungsflächen',
      measurements_text_14: 'Überprüfen der Segmentierungsvolumina',
      measurements_text_15:
        'Sie können die Höhe und das Gewicht der erkannten Segmentierung überprüfen.',
      measurements_text_16: 'Automatische Messungen',
      measurements_text_17:
        'Messungen starten und Höhe und Länge der Segmentierung überprüfen',
      measurements_text_18: 'Markieren oder demarkieren Sie sie in Scans',
      measurements_text_19: 'Linie bearbeiten zum erneuten Berechnen',
      measurements_text_20: 'Löschen',
      measurements_text_21: "Weiter geht's mit",
      measurements_text_22: 'Automatische Messungen',
      measurements_text_23: "Weiter geht's mit",
      measurements_text_24: 'Manuelle Messung',
      measurements_text_25: 'Klicken Sie hier, um fortzufahren',
      measurements_text_26: "Weiter geht's mit",
      measurements_text_27: 'Segmentierungsfläche',
      measurements_text_28: 'Klicken Sie hier, um fortzufahren',
      measurements_text_29: 'Manuelle Messungen',
      measurements_text_30:
        'Sie können auch eine Linie zeichnen, um den Abstand zwischen zwei beliebigen Punkten auf dem Scan zu berechnen.',
      measurements_text_31: 'Segmentierungsfläche',
      measurements_text_32: 'Überprüfen Sie die segmentierten Bereiche:',
      measurements_text_33: 'B-Scan',
      measurements_text_34: 'En-face',
      measurements_text_35: "Weiter geht's mit",
      measurements_text_36: 'Segmentierungsvolumen',
      measurements_text_37: 'Klicken Sie hier, um fortzufahren',
      measurements_text_38: 'Messungen',
      measurements_text_39: 'Überprüfen Sie das Segmentierungsvolumen:',
      pathologies_detection_text_1: "Weiter geht's mit",
      pathologies_detection_text_2: 'Erkennung von Pathologien',
      pathologies_detection_text_3: 'Klicken Sie hier, um fortzufahren',
      pathologies_detection_text_4: 'Segmentierung und Klassifizierung',
      pathologies_detection_text_5:
        'Überprüfen Sie den zuletzt ausgewählten Scan und markieren oder demarkieren Sie die Segmentierung.',
      pathologies_detection_text_6: 'Segmentierung',
      pathologies_detection_text_7: 'Segmentierung und Klassifizierung',
      pathologies_detection_text_8:
        'Abhängig vom Schweregrad schlägt Ihnen Altris AI einen Wert für den',
      pathologies_detection_text_9: 'Dringlichkeit der Überweisung',
      pathologies_detection_text_10: 'Diese Option kann deaktiviert werden',
      pathologies_detection_text_11: 'Segmentierung',
      pathologies_detection_text_12:
        'Altris AI analysiert den Scan und schlägt alle',
      pathologies_detection_text_13: 'Segmentierungen',
      pathologies_detection_text_14:
        'vor, die im ausgewählten Scan erkannt wurden.',
      pathologies_detection_text_15:
        'Sie können sie im Scan überprüfen und markieren/demarkieren.',
      pathologies_detection_text_16: 'Andere Scans überprüfen',
      pathologies_detection_text_17: 'Sie können',
      pathologies_detection_text_18: 'andere Scans auswählen',
      pathologies_detection_text_19: ', die angezeigt werden sollen, und',
      pathologies_detection_text_20: 'zum Bericht hinzufügen',
      pathologies_detection_text_21: 'den aktuellen',
      pathologies_detection_text_22: 'Klassifizierung',
      pathologies_detection_text_23:
        'Altris AI analysiert den Scan und schlägt alle',
      pathologies_detection_text_24: 'Klassifizierungen',
      pathologies_detection_text_25:
        'vor, die im ausgewählten Scan erkannt wurden.',
      progression_text_1: "Weiter geht's mit",
      progression_text_2: 'Verlauf',
      progression_text_3: 'Klicken Sie hier, um fortzufahren',
      progression_text_4: 'Verlauf',
      progression_text_5:
        'Fügen Sie bis zu 8 Untersuchungen hinzu, um den Verlauf der Segmentierungen zu überprüfen.',
      single_scan_text_1: 'Informationen zu Patient/Untersuchung',
      single_scan_text_2:
        'Oben auf dem Bildschirm können Sie allgemeine Informationen zu Patient und Untersuchung einsehen.',
      single_scan_text_3:
        'Darunter sehen Sie das Registerkartenmenü mit allen Systemkomponenten.',
      single_scan_text_4: 'Einzelner Scan',
      single_scan_text_5: 'Auf der rechten Seite sehen Sie:',
      single_scan_text_6: 'Scan-Bild',
      single_scan_text_7: 'Scan-Qualität',
      single_scan_text_8: 'Scan-Nummer',
      single_scan_text_9: 'Schweregrad des Scans',
      single_scan_text_10: 'Skalierungsschaltfläche',
      single_scan_text_11: 'Schaltfläche zum Auswählen des mittleren Scans',
      single_scan_text_12:
        'Schaltfläche zum Markieren des Scans als "Ausgewählt"',
      single_scan_text_13: 'Einzelner Scan',
      single_scan_text_14: 'Auf der linken Seite sehen Sie:',
      single_scan_text_15: 'Scan-Typ',
      single_scan_text_16: 'Altris unterstützt nur horizontale B-Scans',
      single_scan_text_17: 'Auge',
      single_scan_text_18: 'Schweregrade und Navigation',
      single_scan_text_19:
        'Klicken Sie auf den ausgewählten Bereich, um den Scan zu überprüfen, oder ziehen Sie die Linie.',
      single_scan_text_20: 'Nach Schweregrad filtern',
      single_scan_text_21: 'Anzahl der Scans für jeden Schweregrad',
      single_scan_text_22: 'Schaltfläche zum Auswählen des mittleren Scans',
      single_scan_text_23: 'Einzelner Scan',
      single_scan_text_24: 'Sie können die',
      single_scan_text_25: 'Netzhautschichten',
      single_scan_text_26: '-Segmentierung',
      single_scan_text_27: 'für den ausgewählten Scan sehen.',
      single_scan_text_28: 'Sie können',
      single_scan_text_29: 'Hervorheben',
      single_scan_text_30: 'und jeden',
      single_scan_text_31: 'ETDRS- und Netzhautdickenkarte',
      single_scan_text_32:
        'Netzhautdickenkartenansicht oder Standardansicht auswählen',
      single_scan_text_33: 'Durchschnittsdicke 1/3/6 mm',
      single_scan_text_34: 'Einzelner Scan',
      single_scan_text_35: 'GCC-Dicke',
      single_scan_text_36: 'Schicht VON / BIS Dicke',
      single_scan_text_37: 'Dicke einer einzelnen Schicht',
      single_scan_text_38: 'Beginnen wir mit',
      single_scan_text_39: 'Einzelner Scan',
    },
  },
};

export default translation;
