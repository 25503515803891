import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { getSegmentationScans } from 'api/examination';
import {
  exportExamSegmentError,
  exportExamSegmentSuccess,
} from 'redux/actions/examExport';
import { ExportExamActions } from 'redux/constants/exportExam';
import { getExamData } from 'redux/selectors/examination/getExamData/getExamData';
import { getExamScans } from 'redux/selectors/examination/getExamScans/getExamScans';
import { IWorkerProps } from 'redux/types/examination';
import {
  ExamSegmentationExport,
  ExamSegmentationScan,
  ExaminationStateSchema,
  ScanDataType,
  SegmentationScanExport,
} from 'types/examination';
import { downloadObjectAsJson, selectState } from 'utils/helpers';
import notify from 'utils/toast';

function* segmentationWatcher() {
  yield takeEvery(ExportExamActions.EXAM_EXPORT_REQUEST, segmentationWorker);
}

interface ISegmentationWorkerProps extends IWorkerProps {
  type: ExportExamActions;
  payload: {
    exam_id: string;
  };
}

function* segmentationWorker({ payload, action }: ISegmentationWorkerProps) {
  try {
    const examScansData: ScanDataType[] = yield selectState<ScanDataType[]>(
      getExamScans
    );

    const examData: ExaminationStateSchema =
      yield selectState<ExaminationStateSchema>(getExamData);

    const scanIds: string[] = [];

    examScansData.map((scan) => {
      scanIds.push(scan.scan_id);
    });

    const { data: segmentations } = yield getSegmentationScans(
      payload.exam_id,
      scanIds
    );

    const initialData: ExamSegmentationScan = segmentations.data;

    const segmentationResult: ExamSegmentationExport = {
      examination_id: initialData.examination_id,
      segmentations: [],
    };

    if (initialData.segmentations.length > 0) {
      const filtredSegmentations: SegmentationScanExport[] = [];

      initialData.segmentations.map((scan) => {
        const raw = scan.response?.classes?.availableForCurrentPlan;
        const cleaned: string[] = [];

        if (raw) {
          Object.keys(raw)?.map((item) => {
            if (!raw[item].low_confidence) {
              cleaned.push(item);
            }
          });

          const rawInstances = scan.response.instances;
          const cleanedInstances = rawInstances.map(
            ({ className, points }) => ({
              className,
              points,
            })
          );

          filtredSegmentations.push({
            link: scan.link,
            scan_id: scan.scan_id,
            scan_severity: scan.scan_severity,
            urgency_score: scan.urgency_score,
            severity: String(scan.scan_severity),
            response: {
              metadata: {
                height: scan.response.metadata.height,
                width: scan.response.metadata.width,
              },
              instances: cleanedInstances,
              classes: {
                available: cleaned,
              },
            },
          });
        }
      });

      segmentationResult['segmentations'] = filtredSegmentations;

      if (filtredSegmentations.length === 0) {
        notify(
          'info',
          t('notifications.you_dont_have_available_pathology_classes')
        );
      }
    }

    const result = {
      patient: {
        patient_id: examData.data.patient_id,
        patient_pid: examData.data.pid,
      },
      segmentation: segmentationResult,
    };

    yield put(exportExamSegmentSuccess(segmentationResult));
    yield downloadObjectAsJson(
      result,
      `${segmentationResult.examination_id}-data`
    );
    action?.success?.();
  } catch (error) {
    action?.error?.();
    console.error(error);
    yield put(exportExamSegmentError());
    yield notify('error', t('notifications.something_went_wrong'));
  }
}

export default function* segmentationSaga() {
  yield fork(segmentationWatcher);
}
