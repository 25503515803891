import { memo } from 'react';

import cn from 'classnames';

import { IPictureProps } from './Picture.types';

import styles from './Picture.module.scss';

const Picture = memo(
  ({
    alt = 'image alt',
    src,
    webpSrc,
    avifSrc,
    className,
    ...props
  }: IPictureProps) => {
    return (
      <picture className={cn(styles.wrapper, className)}>
        {avifSrc && <source srcSet={avifSrc} type='image/avif' />}
        {webpSrc && <source srcSet={webpSrc} type='image/webp' />}
        <img src={src} alt={alt} {...props} title={alt} />
      </picture>
    );
  }
);

Picture.displayName = 'Picture';

export default Picture;
