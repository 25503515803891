import { useState } from 'react';

import cn from 'classnames';

import { ReactComponent as Eye } from 'assets/icons/icon-eye.svg';

import styles from '../FormField.module.scss';

const PasswordInput = (props) => {
  const {
    name,
    id,
    disabled,
    placeholder,
    onChange,
    onBlur,
    value,
    label,
    onFocus,
    labelType = 'float',
  } = props;

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      <div
        className={cn(
          styles['icon-right'],
          passwordShown ? styles.visiblePassword : ''
        )}
      >
        <Eye onClick={togglePasswordVisibility} />
      </div>
      <input
        className={styles.field}
        placeholder={placeholder}
        id={id}
        name={name}
        type={passwordShown ? 'text' : 'password'}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        onFocus={onFocus}
        autoComplete={name}
      />
      <label
        className={cn(styles.label, {
          [styles[labelType]]: labelType === labelType,
        })}
        htmlFor={id}
      >
        {label}
      </label>
    </>
  );
};

export default PasswordInput;
