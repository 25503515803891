import {
  OPEN_MODAL,
  CLOSE_MODAL,
  OPEN_BLUR_MODAL,
  CLOSE_BLUR_MODAL,
} from '../constants/modal';

export const openModal = (format, data) => ({
  type: OPEN_MODAL,
  payload: { format, data },
});
export const closeModal = () => ({ type: CLOSE_MODAL });

export const openBlurModal = () => ({ type: OPEN_BLUR_MODAL });

export const closeBlurModal = () => ({ type: CLOSE_BLUR_MODAL });
