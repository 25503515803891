import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { getMeasPointsDistance } from 'api/examination';
import {
  getExamMeasDistanceError,
  getExamMeasDistanceSuccess,
} from 'redux/actions/examination';
import { ExamActionTypes } from 'redux/constants/examination';
import { IWorkerProps } from 'redux/types/examination';
import { Points } from 'types/examination';
import notify from 'utils/toast';

function* getMeasManualDistanceWatcher() {
  yield takeEvery(
    ExamActionTypes.GET_EXAM_MEASUREMENTS_DISTANCE_REQUEST,
    measurementsWorker
  );
}

interface IMeasDistanceWorker extends IWorkerProps {
  type: ExamActionTypes;
  payload: { exam_id: string; points: Points };
  action?: {
    default?: () => void;
    success?: (distance?: number) => void;
    error?: () => void;
  };
}

function* measurementsWorker({ payload, action }: IMeasDistanceWorker) {
  action?.default?.();

  try {
    const { exam_id, points } = payload;

    const { data: distanceData } = yield getMeasPointsDistance({
      exam_id,
      points,
    });
    if (distanceData.status === 'Error') {
      throw new Error(distanceData.message);
    }
    yield put(
      getExamMeasDistanceSuccess({ distance: distanceData.data.distance })
    );
    action?.success?.(distanceData.data.distance);
  } catch (error: any) {
    action?.error?.();
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    yield notify('error', errorMessage);
    yield put(
      getExamMeasDistanceError({
        slug: 'something_went_wrong',
        message: 'Something went wrong',
      })
    );
  }
}

export default function* getMeasManualDistanceSaga() {
  yield fork(getMeasManualDistanceWatcher);
}
