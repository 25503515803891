import { SURVEY } from './endpoints';

import { IUnsubscribeSurveyData } from 'types/survey';
import fetchData from 'utils/fetchData';

export const sendSurvey = async (data: IUnsubscribeSurveyData) =>
  fetchData({
    method: 'post',
    to: SURVEY.UNSUBSCRIBE,
    data: data,
  });
