import { memo } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';

import Aside from 'components/Aside';
import ErrorFallback from 'components/ErrorFallback';
import Paper from 'components/Paper';
import { IMenuLinks } from 'utils/linksData';

import styles from './LayoutWithAside.module.scss';

interface LayoutWithAsideProps {
  links: IMenuLinks[];
}

const LayoutWithAside = memo(({ links }: LayoutWithAsideProps) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => window.location.reload()}
    >
      <Paper type={'empty'} className={styles.wrapper}>
        <Aside className={styles.sidebar} links={links} />
        <div className={styles.main}>
          <Outlet />
        </div>
      </Paper>
    </ErrorBoundary>
  );
});

LayoutWithAside.displayName = 'LayoutWithAside';

export default LayoutWithAside;
