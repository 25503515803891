import { helperShowStep } from 'services/onboardingTour/tourOptions';
import i18n from 'translation/i18n';

const STEP_ID = 'create-examination-step-2';

export const stepTwo = {
  id: STEP_ID,
  attachTo: { element: '.shepherd-choose-patient-id', on: 'left' },
  beforeShowPromise: () =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        resolve();
      }, 500);
    }),
  canClickTarget: false,
  buttons: [
    {
      classes: 'shepherd-button-primary',
      text: i18n.t('onboarding.create_examination.next'),
      type: 'next',
    },
  ],
  scrollTo: false,
  title: i18n.t('onboarding.create_examination.add_new_examination'),
  text: [
    `${i18n.t(
      'onboarding.create_examination.step_2_text_1'
    )} <span class='highlight'>${i18n.t(
      'onboarding.create_examination.step_2_text_2'
    )}</span>`,
  ],
  when: {
    show: () => {
      helperShowStep(STEP_ID);
    },
  },
};
