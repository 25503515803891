import { userConstants } from '../constants/userAuth';

import { userAuthActions } from 'types/userAuth';

const initialState = {
  requesting: false,
  errors: null,
};

const userAuthReducer = (state = initialState, action: userAuthActions) => {
  switch (action.type) {
    // LOGIN by mail
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
      };
    case userConstants.LOGIN_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };

    // LOGOUT
    case userConstants.LOGOUT_REQUEST:
      return {
        ...state,
        requesting: true,
      };
    case userConstants.LOGOUT_SUCCESS:
      return {
        ...state,
        requesting: false,
      };

    // REGISTER by mail
    case userConstants.REGISTER_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case userConstants.REGISTER_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
      };
    case userConstants.REGISTER_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };

    // REGISTER by google
    case userConstants.GOOGLE_LOGIN_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case userConstants.GOOGLE_LOGIN_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
      };
    case userConstants.GOOGLE_LOGIN_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };

    // CHECK_EMAIL
    case userConstants.CHECK_EMAIL_REQUEST:
      return {
        ...state,
        requesting: true,
      };
    case userConstants.CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        requesting: false,
      };
    case userConstants.CHECK_EMAIL_ERROR:
      return {
        ...state,
        requesting: false,
      };

    // FORGOT_PASSWORD_REQUEST
    case userConstants.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        errors: null,
        requesting: true,
      };
    case userConstants.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        errors: null,
        requesting: false,
      };
    case userConstants.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        errors: action.payload,
        requesting: false,
      };

    // RESET_PASSWORD_REQUEST
    case userConstants.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        requesting: true,
      };
    case userConstants.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        requesting: false,
      };
    case userConstants.RESET_PASSWORD_ERROR:
      return {
        ...state,
        errors: action.payload,
        requesting: false,
      };

    // REGISTER by INVITE
    case userConstants.REGISTER_INVITED_TEAM_MEMBER_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case userConstants.REGISTER_INVITED_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
      };
    case userConstants.REGISTER_INVITED_TEAM_MEMBER_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };

    // REGISTER by INVITE step 2
    case userConstants.UPDATE_INVITED_TEAM_MEMBER_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case userConstants.UPDATE_INVITED_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
      };
    case userConstants.UPDATE_INVITED_TEAM_MEMBER_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };

    // REGISTER by INVITE step 2
    case userConstants.SEND_OCUPLAN_FORM_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case userConstants.SEND_OCUPLAN_FORM_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
      };
    case userConstants.SEND_OCUPLAN_FORM_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };

    default:
      return state;
  }
};

export default userAuthReducer;
