import cn from 'classnames';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

import styles from '../FormField.module.scss';

interface TextInputProps extends React.HTMLProps<HTMLInputElement> {
  labelType?: string;
  tooltip?: string;
}

const TextInput = (props: TextInputProps) => {
  const {
    name,
    type,
    id,
    disabled,
    placeholder,
    onChange,
    onBlur,
    value,
    readOnly,
    autoComplete,
    label,
    labelType = 'float',
    tooltip,
  } = props;

  return (
    <>
      <input
        className={styles.field}
        placeholder={placeholder}
        id={id}
        name={name}
        type={type}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        readOnly={readOnly}
        autoComplete={autoComplete}
      />

      <label
        className={cn(styles.label, {
          [styles[labelType]]: labelType,
        })}
        htmlFor={id}
      >
        {label}
        {tooltip && (
          <div className={styles.trigger}>
            <div className={cn(styles['trigger-icon'])}>
              <InfoIcon />
            </div>
            <div className={cn(styles['tooltip-text'])}>{tooltip}</div>
          </div>
        )}
      </label>
    </>
  );
};

export default TextInput;
