import { t } from 'i18next';
import { fork, put, takeLatest } from 'redux-saga/effects';

import { getExamLayer } from 'api/examination';
import { getLayersError, getLayersSuccess } from 'redux/actions/examination';
import { ExamActionTypes } from 'redux/constants/examination';
import { IWorkerProps } from 'redux/types/examination';
import { SingleLayersPolygon } from 'types/examination';
import notify from 'utils/toast';

function* getLayersWatcher() {
  yield takeLatest(ExamActionTypes.SCAN_LAYERS_REQUEST, getLayersWorker);
}

interface ILayersWorkerProps extends IWorkerProps {
  type: ExamActionTypes;
  payload: {
    exam_id: string;
    scan_id: string;
  };
}

function* getLayersWorker({ payload, action }: ILayersWorkerProps) {
  try {
    if (payload.scan_id === undefined) return;
    const { data: layersData } = yield getExamLayer(
      payload.exam_id,
      payload.scan_id
    );
    if (layersData.status === 'Error') {
      throw new Error(layersData.message);
    }

    const sortedInstances = (
      (layersData.data?.instances as SingleLayersPolygon[]) || []
    ).sort((first, second) => first.order - second.order);

    yield put(
      getLayersSuccess({ ...layersData.data, instances: sortedInstances })
    );
    action?.success?.();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    action?.error?.();
    yield put(getLayersError(errorMessage));
    yield notify('error', errorMessage);
  }
}

export default function* getLayersSaga() {
  yield fork(getLayersWatcher);
}
