import Modal from './Modal';
import Window from './sections/Inner';
import Content from './sections/ModalContent';
import Footer from './sections/ModalFooter';
import Header from './sections/ModalHeader';
import { TModalProps } from './types';

export default {
  Wrapper: Modal,
  Window,
  Content,
  Header,
  Footer,
};

export type { TModalProps };
