import { memo, useEffect, useState } from 'react';

import qs from 'query-string';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import CurrentStep from './CurrentStep';
import StepOne from './StepOne';
import SteepThankYou from './StepThankYou';
import StepTwo from './StepTwo';

import {
  registerInvitedTeamMemberRequest,
  updateTeamMemberRequest,
} from 'redux/actions/userAuth';
import { ROUTES } from 'utils/constants';
import { ga } from 'utils/helpers';

export interface RegisterUserInfo {
  id: string;
  name: string;
  surname: string;
  email: string;
  institution_website: string;
  password: string;
  profession_id: string;
  country_id: string;
  subscription_coupon_id: string;
}

const RegisterUser = memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const { state } = useLocation();

  /**
   * When we have this @code param, it is mean
   * that we registered from the invited link
   *
   * @coupon param is need to give user discount;
   */
  const coupon = searchParam.get('coupon');

  const [currentStep, setCurrentStep] = useState(1);
  const [userInfo, setUserInfo] = useState<RegisterUserInfo>({
    id: '',
    name: '',
    surname: '',
    email: '',
    institution_website: '',
    password: '',
    profession_id: '',
    country_id: '',

    /**
     * This flag is needed to create user with special coupon for
     * discount price, backend team check this field and give back
     * new price by /plans api route
     */
    subscription_coupon_id: coupon ?? '',
  });

  useEffect(() => {
    const query = qs.parse(location.search);

    const code = query.code;

    if (!code) {
      navigate('/', { replace: true });
    } else {
      ga('open_page_registration');

      delete query.code;

      const search = qs.stringify(query);

      history.replaceState(
        null,
        '',
        `${search ? `?${qs.stringify(query)}` : ROUTES.AUTH.REGISTER}`
      );

      if (state) {
        setUserInfo(state.userData);
        setCurrentStep(state.currentStep);
      }

      dispatch(
        registerInvitedTeamMemberRequest(code, {
          currentStep,
          userInfo,
          setUserInfo,
        })
      );
    }
  }, []);

  const nextHandler = (value: RegisterUserInfo) => {
    switch (currentStep) {
      case 1:
        setUserInfo(value);
        setCurrentStep((prevState) => prevState + 1);

        break;

      case 2: {
        dispatch(
          updateTeamMemberRequest(
            { id: value.id, data: value },
            {
              currentStep,
              setCurrentStep,
            }
          )
        );
        break;
      }

      default:
        return;
    }
  };

  const prevHandler = () => setCurrentStep(currentStep - 1);
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <StepOne nextHandler={nextHandler} userInfo={userInfo} />;
      case 2:
        return (
          <StepTwo
            nextHandler={nextHandler}
            prevHandler={prevHandler}
            userInfo={userInfo}
          />
        );
      case 3:
        return <SteepThankYou />;
      default:
        return null;
    }
  };

  return (
    <>
      <CurrentStep currentStep={currentStep} allSteps={3} />

      {renderStep()}
    </>
  );
});

RegisterUser.displayName = 'RegisterUser';

export default RegisterUser;
