import { userBackupTypes } from 'redux/constants/userBackup';
import { IUserBackupData, IVerifyUser } from 'types/userBackup';

export const createUserBackupRequest = () => ({
  type: userBackupTypes.CREATE_BACKUP_REQUEST,
});

export const createUserBackupSuccess = (data: IUserBackupData) => ({
  type: userBackupTypes.CREATE_BACKUP_SUCCESS,
  payload: data,
});

export const createUserBackupError = (error: unknown | string) => ({
  type: userBackupTypes.CREATE_BACKUP_ERROR,
  payload: error,
});

export const confirmationBlockShow = () => ({
  type: userBackupTypes.CONFIRMATION_BY_CODE_SHOW,
});

export const confirmationBlockHide = () => ({
  type: userBackupTypes.CONFIRMATION_BY_CODE_HIDE,
});

export const verifyCodeRequest = (data: IVerifyUser, action: object) => ({
  type: userBackupTypes.VERIFY_CODE_REQUEST,
  payload: data,
  action: action,
});

export const verifyCodeSuccess = () => ({
  type: userBackupTypes.VERIFY_CODE_SUCCESS,
});

export const verifyCodeError = (error: string) => ({
  type: userBackupTypes.VERIFY_CODE_ERROR,
  payload: error,
});

export const getUserBackupRequest = () => ({
  type: userBackupTypes.GET_BACKUP_REQUEST,
});

export const getUserBackupSuccess = (data: IUserBackupData) => ({
  type: userBackupTypes.GET_BACKUP_SUCCESS,
  payload: data,
});

export const getUserBackupError = (error: string) => ({
  type: userBackupTypes.GET_BACKUP_ERROR,
  payload: error,
});

export const deleteUserBackupRequest = () => ({
  type: userBackupTypes.DELETE_BACKUP_REQUEST,
});

export const deleteUserBackupSuccess = (data: IUserBackupData) => ({
  type: userBackupTypes.DELETE_BACKUP_SUCCESS,
  payload: data,
});

export const deleteUserBackupError = (error: unknown | string) => ({
  type: userBackupTypes.DELETE_BACKUP_ERROR,
  payload: error,
});
