import Shepherd from 'shepherd.js';

import { helperShowStep } from 'services/onboardingTour/tourOptions';
import i18n from 'translation/i18n';

const STEP_ID = 'create-examination-step-3';

export const stepThree: Shepherd.Step.StepOptions = {
  id: STEP_ID,
  attachTo: { element: '.shepherd-patient_from_dicom', on: 'bottom' },
  beforeShowPromise: () =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        resolve();
      }, 500);
    }),
  buttons: [
    {
      classes: 'shepherd-button-primary',
      text: i18n.t('onboarding.create_examination.next'),
      action() {
        return this.next();
      },
    },
  ],
  canClickTarget: false,
  scrollTo: false,
  title: i18n.t('onboarding.create_examination.add_new_examination'),
  text: [i18n.t('onboarding.create_examination.step_3_text')],
  when: {
    show: () => {
      helperShowStep(STEP_ID);
    },
  },
};
