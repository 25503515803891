import { useEffect, useRef } from 'react';

import cn from 'classnames';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InfoIcon } from 'assets/icons/hexagon-info.svg';
import FormField from 'components/FormComponents';
import customStyles1 from 'components/FormComponents/SelectInput/customSelectStyles';
import SimpleMessage from 'components/SimpleMessage';
import {
  dayOptions,
  monthOptions,
  useDateSelectPicker,
  yearOptions,
} from 'hooks/useDateSelectPicker';
import {
  ACCEPT_FORMAT_FOR_UPLOAD,
  CREATE_EXAMINATION_FLOW,
  EYE_TYPES,
  SCAN_WIDTHS,
  eyeTypes,
} from 'utils/constants';

import formStyles from 'components/FormComponents/FormField.module.scss';

import styles from '../CreateExamination.module.scss';

interface ImageProps {
  eyeType?: eyeTypes[];
}

const Image = ({ eyeType }: ImageProps) => {
  const { setFieldValue, initialValues, values } = useFormikContext<any>();
  const initialPatient = useRef(initialValues);

  const { t } = useTranslation(undefined, {
    keyPrefix: 'components.create_examination',
  });

  const EYE_TYPE_OPTIONS = [
    { label: `${t('right')} (OD)`, value: EYE_TYPES.OD },
    { label: `${t('left')} (OS)`, value: EYE_TYPES.OS },
  ];

  const DateFields = useDateSelectPicker(
    {
      dayField: () => (
        <FormField
          component={'select'}
          name={'examination_day'}
          placeholder={'DD'}
          options={dayOptions(
            values['examination_month'],
            values['examination_year']
          )}
          customStyles={customStyles1}
          onChange={(item: any) => setFieldValue('examination_day', item.value)}
          labelType={'classic'}
        />
      ),
      monthField: () => (
        <FormField
          component={'select'}
          name={'examination_month'}
          placeholder={'MM'}
          options={monthOptions(values['examination_year'])}
          customStyles={customStyles1}
          onChange={(item: any) =>
            setFieldValue('examination_month', item.value)
          }
          labelType={'classic'}
        />
      ),
      yearField: () => (
        <FormField
          component={'select'}
          name={'examination_year'}
          placeholder={'YY'}
          options={yearOptions()}
          customStyles={customStyles1}
          onChange={(item: any) =>
            setFieldValue('examination_year', item.value)
          }
          labelType={'classic'}
        />
      ),
    },
    [
      values['examination_day'],
      values['examination_month'],
      values['examination_year'],
    ]
  );

  useEffect(() => {
    setFieldValue('flow', CREATE_EXAMINATION_FLOW.IMAGES_IN_PATIENT);
    // @ts-ignore
    setFieldValue('pid', initialPatient.current['pid']);
    // @ts-ignore
    setFieldValue('patient_id', initialPatient.current['patient_id']);
    setFieldValue('file', null);

    /** Reset these fields when you change the tab to DICOM  */
    return () => {
      setFieldValue('width', '');
      setFieldValue('eye', '');
      setFieldValue('examination_day', '');
      setFieldValue('examination_month', '');
      setFieldValue('examination_year', '');
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.grid}>
        <FormField
          component={'select'}
          name={'width'}
          placeholder={t('scan_width_placeholder')}
          label={t('scan_width_label')}
          options={SCAN_WIDTHS}
          customStyles={customStyles1}
          onChange={(item: any) => {
            setFieldValue('width', item.value);
          }}
          labelType={'classic'}
        />
      </div>
      <SimpleMessage Icon={InfoIcon} text={t('scan_width_message')} />
      <FormField
        component={'select'}
        name={'eye'}
        placeholder={t('eye_placeholder')}
        label={t('eye_label')}
        options={eyeType ?? EYE_TYPE_OPTIONS}
        customStyles={customStyles1}
        onChange={(item: any) => setFieldValue('eye', item.value)}
        labelType={'classic'}
      />
      <p className={styles['date-label']}>{t('examination_date')}</p>
      <div className={styles.date}>
        <DateFields />
      </div>

      <FormField
        rows={1}
        component={'textarea'}
        name={'note'}
        placeholder={t('notes_placeholder')}
        label={t('notes_label')}
        labelType={'classic'}
      />
      <FormField
        component={'file-upload'}
        name={'file'}
        label={t('upload_images_to_complete')}
        className={cn(formStyles.classic, formStyles['static-errors'])}
        accept={ACCEPT_FORMAT_FOR_UPLOAD.IMAGE}
        multiple
        labelType={'classic'}
        customClass={styles['file-upload']}
      />
    </div>
  );
};

Image.displayName = 'Image';

export default Image;
