import { memo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { IAsideProps } from './Aside.types';
import MenuItem from './MenuItem';

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import HistoryLink from 'components/HistoryLink';
import { profileRoleSelector } from 'redux/selectors/userProfile';
import { BASE_URL } from 'utils/constants';
import { rolePermissionsCheck } from 'utils/helpers';
import { IMenuLinks } from 'utils/linksData';

import styles from './Aside.module.scss';

/** Temporary solution DELETE WHEN STEP 2 will be ready */
const Aside = memo(({ className, links }: IAsideProps) => {
  const userRole = useSelector(profileRoleSelector);
  const filteredProfileLinks = rolePermissionsCheck(links, userRole.model);

  const { t } = useTranslation(undefined, { keyPrefix: 'components.aside' });

  return (
    <aside className={className}>
      <div className={styles.wrapper}>
        <div className={styles.back}>
          <HistoryLink to={BASE_URL} className={styles.href}>
            <ArrowLeftIcon />
            {t('back')}
          </HistoryLink>
        </div>
        <nav className={styles.links}>
          {/** Temporary solution DELETE WHEN STEP 2 will be ready */}
          {filteredProfileLinks.map(
            ({ link = '', icon, title, target, action }: IMenuLinks) => {
              return (
                <MenuItem
                  link={link}
                  key={title}
                  title={title}
                  className={styles.link}
                  target={target}
                  action={action}
                >
                  {icon}
                </MenuItem>
              );
            }
          )}
        </nav>
      </div>
    </aside>
  );
});

Aside.displayName = 'Aside';

export default Aside;
