import React from 'react';

import cn from 'classnames';

import s from './ModalFooter.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
}

/**
 *  ModalFooter
 *  Created by westprophet on 03.07.2024
 *  @param className
 * @param children
 */

export default function ModalFooter({ className = '', children }: Props) {
  return <div className={cn(s.ModalFooter, className)}>{children}</div>;
}
