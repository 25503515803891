import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { getSegmentationScans } from 'api/examination';
import {
  getExaminationSegmentationError,
  getExaminationSegmentationSuccess,
} from 'redux/actions/examination';
import { ExamActionTypes } from 'redux/constants/examination';
import { IWorkerProps } from 'redux/types/examination';
import notify from 'utils/toast';

function* segmentationExamWatcher() {
  yield takeEvery(
    ExamActionTypes.SCAN_SEGMENTATION_REQUEST,
    segmentationExamWorker
  );
}

interface ISegmentationWorker extends IWorkerProps {
  type: ExamActionTypes;
  payload: {
    exam_id: string;
    scans_id: string[];
  };
}

function* segmentationExamWorker({ payload, action }: ISegmentationWorker) {
  try {
    const { data: segmentations } = yield getSegmentationScans(
      payload.exam_id,
      payload.scans_id
    );

    if (segmentations.status === 'Error') {
      throw new Error(segmentations.message);
    }

    yield put(getExaminationSegmentationSuccess(segmentations.data));
    action?.success?.();
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    action?.error?.();
    console.error(error);
    yield put(getExaminationSegmentationError('server error'));
    yield notify('error', errorMessage);
  }
}

export default function* segmentationExaminationSaga() {
  yield fork(segmentationExamWatcher);
}
