import { surveyTypes } from 'redux/constants/survey';
import { ISurveyData, IUnsubscribeSurveyData } from 'types/survey';

/** For future update, when client want to create several surveys */
export const getSurveyRequest = () => ({
  type: surveyTypes.GET_SURVEY_REQUEST,
});

export const getSurveySuccess = (data: ISurveyData) => ({
  type: surveyTypes.GET_SURVEY_SUCCESS,
  payload: data,
});

export const getSurveyError = (error: unknown | string) => ({
  type: surveyTypes.GET_SURVEY_ERROR,
  payload: error,
});

export const sendSurveyRequest = (data: IUnsubscribeSurveyData) => ({
  type: surveyTypes.SEND_SURVEY_REQUEST,
  payload: data,
});

export const sendSurveySuccess = () => ({
  type: surveyTypes.SEND_SURVEY_SUCCESS,
});

export const sendSurveyError = (error: unknown | string) => ({
  type: surveyTypes.SEND_SURVEY_ERROR,
  payload: error,
});
