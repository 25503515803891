import { memo, useCallback, useEffect, useRef } from 'react';

import cn from 'classnames';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import Button from 'components/Button';
import Dicom from 'components/CreateExamination/Dicom';
import CustomTooltip from 'components/CustomTooltip';
import FormField from 'components/FormComponents';
import customStyles1 from 'components/FormComponents/SelectInput/customSelectStyles';
import Text from 'components/Text';
import {
  dayOptions,
  monthOptions,
  useDateSelectPicker,
  yearOptions,
} from 'hooks/useDateSelectPicker';
import useIsUserTrialExpired from 'hooks/useIsUserTrialExpired';
import useLocalStorage from 'hooks/useLocalStorage';
import {
  profileRoleSelector,
  userProfileSelector,
} from 'redux/selectors/userProfile';
import { CREATE_EXAMINATION_FLOW, USER_ROLES } from 'utils/constants';
import { generatePatientId } from 'utils/helpers';
import { addExaminationModal, noObjectsLeftModal } from 'utils/openModals';
import { validateCreatePatient } from 'validation';

import formStyles from 'components/FormComponents/FormField.module.scss';

import styles from './CreatePatient.module.scss';

const CreatePatient = memo(({ title, subtitle, confirm, reject }) => {
  const form = useRef();
  const { data: userData } = useSelector(userProfileSelector);
  const userRole = useSelector(profileRoleSelector);
  const { localStorageKey: onboardingStep } = useLocalStorage('onboarding');

  const { t, i18n } = useTranslation(undefined, {
    keyPrefix: 'components.modal.create_patient',
  });

  const isUserTrialExpired = useIsUserTrialExpired();

  const DateFields = useDateSelectPicker(
    {
      dayField: ({ values, setFieldValue }) => (
        <FormField
          component={'select'}
          name={'birth_day'}
          placeholder={'DD'}
          labelType={'classic'}
          options={dayOptions(values['birth_month'], values['birth_year'])}
          customStyles={customStyles1}
          onChange={(item) => setFieldValue('birth_day', item.value)}
        />
      ),
      monthField: ({ values, setFieldValue }) => (
        <FormField
          component={'select'}
          name={'birth_month'}
          placeholder={'MM'}
          labelType={'classic'}
          options={monthOptions(values['birth_year'])}
          customStyles={customStyles1}
          onChange={(item) => setFieldValue('birth_month', item.value)}
        />
      ),
      yearField: ({ setFieldValue }) => (
        <FormField
          component={'select'}
          name={'birth_year'}
          placeholder={'YY'}
          labelType={'classic'}
          options={yearOptions(120)}
          customStyles={customStyles1}
          onChange={(item) => setFieldValue('birth_year', item.value)}
        />
      ),
    },
    []
  );

  const GENDER_TYPE = [
    { label: t('male'), value: 'Male' },
    { label: t('female'), value: 'Female' },
    { label: t('other'), value: 'Other' },
  ];

  const initialValues = (onboardingStep) => {
    if (onboardingStep) {
      return {
        pid: generatePatientId(),
        name: 'Alex',
        surname: 'Cooper',
        birth_day: '13',
        birth_month: '10',
        birth_year: '1990',
        gender: 'Male',
        description: 'This is you description',
        flow: CREATE_EXAMINATION_FLOW.DICOM_IN_PATIENT,
        patient_from_dicom: false,
      };
    } else {
      return {
        pid: generatePatientId(),
        name: '',
        surname: '',
        birth_day: '',
        birth_month: '',
        birth_year: '',
        gender: '',
        description: '',
        flow: CREATE_EXAMINATION_FLOW.DICOM_IN_PATIENT,
        patient_from_dicom: false,
      };
    }
  };

  // Open createExaminationModal after click createExamination button in the
  // createPatientModal
  const submitHandler = (values, openExamModal) => {
    values.birth_date =
      new Date(
        `${values.birth_year}-${values.birth_month}-${values.birth_day}`
      ).getTime() / 1000;
    delete values.birth_day;
    delete values.birth_month;
    delete values.birth_year;

    // action for saga
    if (openExamModal) {
      confirm(values, { addExaminationModal });
      return;
    }

    /**
     * @param onBoarding - if code is true, then we need to prefill data
     * in the modal for onboarding
     */
    confirm(values, { ...(onboardingStep && { onBoarding: true }) });
  };

  const buttonsHandler = useCallback(
    (event) => {
      /**
       * Prevent default behavior for ESC and Enter buttons if onboarding
       */
      if (onboardingStep) return;
      if (event.keyCode === 27) {
        reject();
      }
      if (event.keyCode === 13) {
        form.current.handleSubmit();
      }
    },
    [onboardingStep]
  );

  useEffect(() => {
    document.addEventListener('keydown', buttonsHandler);

    return () => {
      document.removeEventListener('keydown', buttonsHandler);
    };
  }, [buttonsHandler]);

  const createExaminationHandler = () => {
    if (
      userRole.model === USER_ROLES.B2B ||
      userRole.model === USER_ROLES.BRANCH_USER
    ) {
      noObjectsLeftModal(userData.plan, 'examinations', userRole.model);
    } else {
      noObjectsLeftModal(userData.plan, 'examinations', userRole.model);
    }
  };

  return (
    <div className={styles.wrapper}>
      {title && (
        <Text tag='h2' color='dark' align='center' className={styles.title}>
          {title}
        </Text>
      )}
      {subtitle && (
        <Text color='gray' align='center' className={styles.subtitle}>
          {subtitle}
        </Text>
      )}
      <Formik
        initialValues={initialValues(onboardingStep)}
        validationSchema={() => validateCreatePatient(i18n.t)}
        onSubmit={(values) => submitHandler(values, null)}
        innerRef={form}
        enableReinitialize={true}
      >
        {({ setFieldValue, isValid, dirty, values }) => (
          <Form className={styles.form}>
            <div
              className={cn(
                styles['checkbox-wrapper'],
                'shepherd-create-patient-from-dicom'
              )}
            >
              {userData.examinations_left === 0 && !onboardingStep && (
                <div
                  className={cn({
                    [styles['overlay']]: userData.examinations_left === 0,
                  })}
                >
                  <Text
                    className={styles['warning']}
                    color='danger'
                    align={'center'}
                  >
                    {t('no_exam_left1')}
                    <span onClick={createExaminationHandler}>
                      {t('no_exam_left2')}
                    </span>{' '}
                    {t('no_exam_left3')}
                  </Text>
                </div>
              )}
              <FormField
                component={'checkbox'}
                name={'patient_from_dicom'}
                id={'patient_from_dicom'}
                disabled={isUserTrialExpired}
                className={cn(formStyles.classic, styles.checkbox)}
              />

              <div className={styles['text-wrapper']}>
                <Text className={styles.text}>
                  {t('create_new_patient_from_dicom')}
                </Text>
                <CustomTooltip
                  position={'top-left'}
                  icon={<InfoIcon />}
                  tooltipText={t('create_new_patient_from_dicom_description')}
                />
              </div>
            </div>

            {values['patient_from_dicom'] ? (
              <div className={styles['create-examination']}>
                <Dicom multiple />
              </div>
            ) : (
              <div className='shepherd-patient-info'>
                <FormField
                  type={'text'}
                  component={'text'}
                  name={'pid'}
                  label={t('patient_id_label')}
                  labelType={'classic'}
                />

                <div className={styles.grid}>
                  <FormField
                    type={'text'}
                    component={'text'}
                    name={'name'}
                    placeholder={`${t('example_short')}.: Alex`}
                    label={t('first_name_label')}
                    labelType={'classic'}
                  />

                  <FormField
                    type={'text'}
                    name={'surname'}
                    placeholder={`${t('example_short')}.: Cooper`}
                    labelType={'classic'}
                    label={t('last_name_label')}
                  />
                </div>

                <p className={styles['date-label']}>{t('birth_date_label')}</p>
                <div className={styles.birthday}>
                  <DateFields values={values} setFieldValue={setFieldValue} />
                </div>

                <FormField
                  component={'select'}
                  name={'gender'}
                  label={t('gender_label')}
                  placeholder={t('gender_placeholder')}
                  labelType={'classic'}
                  options={GENDER_TYPE}
                  customStyles={customStyles1}
                  onChange={(item) => setFieldValue('gender', item.value)}
                />
                <FormField
                  rows={3}
                  component={'textarea'}
                  name={'description'}
                  placeholder={`${t('example_short')}.: My note`}
                  label={t('notes_label')}
                  labelType={'classic'}
                />

                <Button
                  appearance='simple'
                  className={styles.button}
                  onClick={() => submitHandler(values, true)}
                  disabled={
                    isUserTrialExpired ||
                    !(dirty && isValid && userData.examinations_left !== 0)
                  }
                >
                  + {t('add_examination')}
                </Button>
              </div>
            )}

            <div className={styles.sticky}>
              <Button
                type='submit'
                className={cn('shepherd-create-patient-btn', {
                  'click-button': onboardingStep === 'create-patient-step-4',
                  [styles.block]: onboardingStep === 'create-patient-step-3',
                })}
                disabled={!isValid}
              >
                {values['patient_from_dicom']
                  ? t('add_patient_and_examination')
                  : t('add_patient')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
});

CreatePatient.displayName = 'CreatePatient';

export default CreatePatient;
