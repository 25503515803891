import { DateFormatter } from 'utils/DateFormatter.class';

/**
 * @param {*} date in milliseconds
 * @returns: number, days count
 */
export const getDayCount = (date) => {
  if (typeof date !== 'number') return;
  return date / 1000 / 60 / 60 / 24;
};

/**
 * @param {*} upcomingPlan: object
 * @returns boolean
 */
export const isHaveUpcomingPlan = (upcomingPlan) => {
  return upcomingPlan === null;
};

/**
 * @param {*} currentPlan object;
 * @param {*} upcomingPlan object | null;
 * @returns boolean;
 * If the end date of the plan or the end date of the current plan is < 0,
 * and the future plan is empty - our subscription has expired
 * BE give to us data in UTC (Timezone 0)
 */
export const isSubscribeFinished = (currentPlan, upcomingPlan) => {
  const currentUtcTime = DateFormatter.getUTCtimestamp();

  const planEnd =
    getDayCount(Date.parse(currentPlan?.ends_at + 'GMT') - currentUtcTime) < 0;
  const periodEnd =
    getDayCount(
      Date.parse(currentPlan?.current_period_end + 'GMT') - currentUtcTime
    ) < 0;

  return (planEnd || periodEnd) && isHaveUpcomingPlan(upcomingPlan);
};
