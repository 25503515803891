import { t } from 'i18next';
import { fork, put, select, takeEvery } from 'redux-saga/effects';

import { getMainETDRS } from 'api/examination';
import {
  updateProgressionDataError,
  updateProgressionScansDataSuccess,
} from 'redux/actions/examination';
import { ExamActionTypes } from 'redux/constants/examination';
import { getExamData } from 'redux/selectors/examination/getExamData/getExamData';
import { IWorkerProps } from 'redux/types/examination';
import { ExtendedExamDataType, IHeatMapProps } from 'types/examination';
import notify from 'utils/toast';

function* updateProgressionWatcher() {
  yield takeEvery(
    ExamActionTypes.UPDATE_PROGRESSION_DATA_REQUEST,
    updateProgressionWorker
  );
}

interface IEtdrs extends IWorkerProps {
  type: ExamActionTypes;
  payload: {
    exam_id: string;
    layers?: IHeatMapProps;
  };
}

function* updateProgressionWorker({ payload, action }: IEtdrs) {
  action?.default?.();

  try {
    const { exam_id } = payload;

    const { data: mainData } = yield getMainETDRS(exam_id);
    if (mainData.status === 'Error') {
      throw new Error(mainData.message);
    }

    if (!mainData.data[0]) return;

    const { compareProgress } = yield select(getExamData);

    const updatedIndex = compareProgress.examsData.findIndex(
      (item: ExtendedExamDataType) => item.id === exam_id
    );

    const result = [...compareProgress.examsData];

    result[updatedIndex] = {
      ...result[updatedIndex],
      etdrs: {
        main: { data: mainData.data[0] },
      },
    };

    yield put(updateProgressionScansDataSuccess(result));

    action?.success?.();
  } catch (error: any) {
    action?.error?.();
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    notify('error', errorMessage);
    yield put(updateProgressionDataError());
  }
}

export default function* updateProgressionSaga() {
  yield fork(updateProgressionWatcher);
}
