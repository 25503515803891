import { memo, Suspense } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, Outlet, useLocation, useSearchParams } from 'react-router-dom';

import { ReactComponent as HelpIcon } from 'assets/icons/help-circle.svg';
import { ReactComponent as LogoBigIcon } from 'assets/icons/logo-big.svg';
import OpticalIcon from 'assets/images/100percentwth.png';
import OcuPlanLogo from 'assets/images/ocuplan.png';
// import { ReactComponent as OcuplanLogo } from 'assets/images/ocuplan.svg';
import Picture from 'components/Picture';
import Spinner from 'components/Spinner';
import SpinnerSVG from 'components/SpinnerSVG';
import { selectProfessionsAndCountries } from 'redux/selectors/professionAndCountry';
import { selectUserAuth } from 'redux/selectors/userAuth';
import { ROUTES } from 'utils/constants';

import styles from './FormWrap.module.scss';

const FormWrap = memo(() => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'components.form_wrap',
  });
  const [searchParam] = useSearchParams();
  const { pathname } = useLocation();

  const { requesting: userAuthRequesting } = useSelector(selectUserAuth);
  const { requesting: professionsAndCountriesRequesting } = useSelector(
    selectProfessionsAndCountries
  );

  const coupon = searchParam.get('coupon');

  return (
    <div className={styles.wrapper}>
      <Suspense fallback={<Spinner />}>
        <div className={styles.header}>
          <LogoBigIcon className={styles.logo} />
          {coupon === '100percentoptical' && (
            <Picture className={styles.logo2} src={OpticalIcon} />
          )}
          {pathname.indexOf(ROUTES.LAW.FILL_FORM) === 0 && (
            <Picture className={styles.logo3} src={OcuPlanLogo} />
          )}
        </div>
        <SpinnerSVG
          active={userAuthRequesting || professionsAndCountriesRequesting}
          className={styles.spinner}
          fillColor={'var(--theme-color)'}
        />
        <Outlet />
        <Link
          onClick={(e) => {
            e.preventDefault();
            // @ts-ignore
            window.location = `mailto:support@altris.ai?subject=Altris web > Support request`;
          }}
          to='#'
          className={styles.help}
        >
          <HelpIcon />
          {t('help')}
        </Link>
      </Suspense>
    </div>
  );
});

FormWrap.displayName = 'FormWrap';

export default FormWrap;
