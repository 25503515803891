import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { saveSettings } from 'api/settings';
import { saveSettingsError, saveSettingsSuccess } from 'redux/actions/settings';
import { settingsTypes } from 'redux/constants/settings';
import { ISettingsDataAction } from 'types/settings';
import notify from 'utils/toast';

function* saveSettingsWatcher() {
  yield takeEvery(settingsTypes.SAVE_SETTINGS_REQUEST, saveSettingsWorker);
}

function* saveSettingsWorker({ payload, action }: ISettingsDataAction) {
  try {
    const { data: settingsData } = yield saveSettings(payload);

    yield put(saveSettingsSuccess(settingsData.data));
    yield action?.resetForm();
    if (settingsData.status === 'Error') {
      throw new Error(settingsData.message);
    }
    yield notify('success', t('notifications.settings_saved'));
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    yield put(saveSettingsError(error));
    yield notify('error', errorMessage);
  }
}

export default function* saveSettingsSaga() {
  yield fork(saveSettingsWatcher);
}
