import { MOCK_EXAM_ID } from './mockData';
import { mockETDRS } from './mockETDRS';
import { mockScans } from './mockScans';

import { CompareProgressData } from 'types/examination';

export const mockCompareProgress = (): CompareProgressData => ({
  isRequestingProgressionState: false,
  examsData: [
    {
      etdrs: mockETDRS(),
      id: MOCK_EXAM_ID,
      eye: 'OS',
      width: '5mm',
      examination_date: 1642370400000,
      examination_status: 'finished',
      segmentations_status: 'not_required',
      segmentation_layers_status: 'finished',
      measurements_status: 'not_required',
      patient_id: 1313,
      pid: 'AUTO1677745919057',
      patient_name: 'Alex',
      patient_surname: 'Cooper',
      patient_birth_date: 1641074400000,
      patient_gender: 'Female',
      medic_id: 109,
      medic_name: 'Artem Rudenko',
      total_scans: 12,
      created_at: 1641074400000,
      direction: 'Horizontal',
      note: null,
      no_pathology: false,
      is_dicom: true,
      able_to_get_statistics: true,
      has_default_etdrs_statistics: true,
      scans: mockScans(),
    },
    {
      etdrs: mockETDRS(),
      id: '6414e189323bfaf42e0a52w3',
      eye: 'OS',
      width: '5mm',
      examination_date: 1642370400000,
      examination_status: 'finished',
      segmentations_status: 'not_required',
      segmentation_layers_status: 'finished',
      measurements_status: 'not_required',
      patient_id: 1313,
      pid: 'AUTO1677745919057',
      patient_name: 'Alex',
      patient_surname: 'Cooper',
      patient_birth_date: 1641074400000,
      patient_gender: 'Female',
      medic_id: 109,
      medic_name: 'Artem Rudenko',
      total_scans: 12,
      created_at: 1641074400000,
      direction: 'Horizontal',
      note: null,
      no_pathology: false,
      is_dicom: true,
      able_to_get_statistics: true,
      has_default_etdrs_statistics: true,
      scans: mockScans(),
    },
    {
      etdrs: mockETDRS(),
      id: '6414e189323bfaf42e0a52g3',
      eye: 'OS',
      width: '5mm',
      examination_date: 1642370400000,
      examination_status: 'finished',
      segmentations_status: 'not_required',
      segmentation_layers_status: 'finished',
      measurements_status: 'not_required',
      patient_id: 1313,
      pid: 'AUTO1677745919057',
      patient_name: 'Alex',
      patient_surname: 'Cooper',
      patient_birth_date: 1641074400000,
      patient_gender: 'Female',
      medic_id: 109,
      medic_name: 'Artem Rudenko',
      total_scans: 12,
      created_at: 1641074400000,
      direction: 'Horizontal',
      note: null,
      no_pathology: false,
      is_dicom: true,
      able_to_get_statistics: true,
      has_default_etdrs_statistics: true,
      scans: mockScans(),
    },
  ],
  isRequestingCommonPathologiesData: false,
  commonPathologiesData: [],
  isRequestingCommonHeatmapsData: false,
  commonHeatmapsData: {},
  isRequestingPathologyScansData: false,
  pathologyScansData: {},
});
