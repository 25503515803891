import { helperShowStep } from 'services/onboardingTour/tourOptions';
import i18n from 'translation/i18n';

const STEP_ID = 'single-scan-examination-step-2';

export const stepTwo = {
  id: STEP_ID,
  attachTo: { element: '.shepherd-examination-tabs > div', on: 'bottom' },
  beforeShowPromise: () =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        resolve();
      }, 1000);
    }),
  canClickTarget: false,
  buttons: [
    {
      classes: 'shepherd-button-primary',
      text: i18n.t('onboarding.show_examination.next'),
      type: 'next',
    },
  ],
  scrollTo: false,
  title: i18n.t('onboarding.show_examination.tab_menu'),
  text: [i18n.t('onboarding.show_examination.single_scan_text_3')],
  when: {
    show: () => {
      helperShowStep(STEP_ID);
    },
  },
};
