import { userAuthenticatedConstants } from 'redux/constants/userAuthenticated';
import {
  IUserAuthenticatedState,
  userAuthenticatedActions,
} from 'types/userAuthenticated';

const initialState: IUserAuthenticatedState = {
  authenticated: !!localStorage.getItem('token') || false,
  method: localStorage.getItem('method') || null,
};

const userAuthenticatedReducer = (
  state = initialState,
  action: userAuthenticatedActions
): IUserAuthenticatedState => {
  switch (action.type) {
    case userAuthenticatedConstants.USER_AUTHENTICATED_TRUE:
      return {
        ...state,
        authenticated: true,
        method: action.payload,
      };
    case userAuthenticatedConstants.USER_AUTHENTICATED_FALSE:
      return {
        ...state,
        authenticated: false,
        method: null,
      };
    default:
      return state;
  }
};

export default userAuthenticatedReducer;
