import { memo, useCallback, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as NotesIco } from 'assets/icons/notes.svg';
import Button from 'components/Button';
import Dicom from 'components/CreateExamination/Dicom';
import Image from 'components/CreateExamination/Image';
import FormField from 'components/FormComponents';
import customStyles1 from 'components/FormComponents/SelectInput/customSelectStyles';
import SimpleMessage from 'components/SimpleMessage';
import TabComponent from 'components/TabComponent';
import Text from 'components/Text';
import useLocalStorage from 'hooks/useLocalStorage';
import { useSettings } from 'hooks/useSettings';
import { checkExistedData } from 'pages/Examination/helpers';
import {
  getPatientExamRequest,
  getPatientRequest,
} from 'redux/actions/patient';
import { getExamData } from 'redux/selectors/examination/getExamData/getExamData';
import { patientsSelector } from 'redux/selectors/patients';
import { userProfileSelector } from 'redux/selectors/userProfile';
import { SettingsEnum } from 'types/settings';
import {
  ACCEPT_FORMAT_FOR_UPLOAD,
  COMPARE_TYPE,
  CREATE_EXAMINATION_FLOW,
  EYE_TYPES,
} from 'utils/constants';
import { DateFormatter } from 'utils/DateFormatter.class';
import { validateCreateExamination } from 'validation';

import styles from './CreateExamination.module.scss';

const CreateExamination = memo(
  ({
    title,
    subtitle,

    /**
     * @param patient is retrieved from the saga or dashboard.context
     * if we click "create exam" in the modal createPatient
     */
    patient,
    confirm,
    reject,
    choseExam,
    compareType,
    scanId,
  }) => {
    const { t, i18n } = useTranslation(undefined, {
      keyPrefix: 'components.modal.create_examination',
    });

    const form = useRef();
    const dispatch = useDispatch();
    const { data: userData } = useSelector(userProfileSelector);
    const { data: patientData, examinations } = useSelector(patientsSelector);
    const { data: examData, compareProgress } = useSelector(getExamData);
    const { localStorageKey: onboardingStep } = useLocalStorage('onboarding');
    const [tabs, setTabs] = useState([
      {
        id: 1,
        title: 'DICOM',

        //** @dashboard need for hide create patient form dicom on the examination page */
        component: (
          <Dicom
            dashboard
            multiple
            key={1}
            accept={ACCEPT_FORMAT_FOR_UPLOAD.DICOM}
          />
        ),
      },
      {
        id: 2,
        title: t('images'),
        component: <Image key={2} />,
      },
      {
        id: 3,
        title: `DICOM ${t('folder')}`,
        component: (
          <Dicom
            dashboard
            dicomFolder
            accept={ACCEPT_FORMAT_FOR_UPLOAD.ARCHIVE}
            key={3}
          />
        ),
      },
    ]);

    const timeFormat = useSettings(SettingsEnum.TIME_FORMAT);

    useEffect(() => {
      if (!patientData.patients.length) {
        dispatch(getPatientRequest());
      }
      if (choseExam) {
        if (examData) {
          dispatch(getPatientExamRequest(examData.patient_id));
        }
      }
      if (compareType) {
        const EYE_TYPE_OPTIONS = [
          { label: `${t('right')} (OD)`, value: EYE_TYPES.OD },
          { label: `${t('left')} (OS)`, value: EYE_TYPES.OS },
        ];

        let filteredType = EYE_TYPE_OPTIONS;

        if (compareType === COMPARE_TYPE.BOTH) {
          filteredType = EYE_TYPE_OPTIONS.filter(
            (type) => type.value !== examData.eye
          );
        }
        if (
          compareType === COMPARE_TYPE.COMPARISON ||
          compareType === COMPARE_TYPE.PROGRESSION
        ) {
          filteredType = EYE_TYPE_OPTIONS.filter(
            (type) => type.value === examData.eye
          );
        }

        setTabs([
          {
            id: 1,
            title: 'DICOM',
            component: <Dicom key={1} />,
          },
          {
            id: 2,
            title: t('images'),
            component: <Image eyeType={filteredType} key={2} />,
          },
        ]);
      }
    }, []);

    const buttonsHandler = useCallback(
      (event) => {
        /**
         * Prevent default behavior for ESC and Enter buttons if onboarding
         */
        if (onboardingStep) return;
        if (event.keyCode === 27) {
          reject();
        }
        if (event.keyCode === 13) {
          form.current.handleSubmit();
        }
      },
      [onboardingStep]
    );

    useEffect(() => {
      document.addEventListener('keydown', buttonsHandler);

      return () => {
        document.removeEventListener('keydown', buttonsHandler);
      };
    }, [buttonsHandler]);

    const transformDataForSelect = (data) => {
      return data?.map((item) => ({
        id: item.id,
        pid: item.pid,

        /** show all data in select for search by this data */
        label: `${item.name} ${item.surname} | ${item.pid}`,
        value: item.id,
      }));
    };

    const submitHandler = (values) => {
      values.examination_date =
        new Date(
          `${values?.examination_year}-${values?.examination_month}-${values?.examination_day}`
        ).getTime() / 1000;
      delete values.examination_day;
      delete values.examination_month;
      delete values.examination_year;

      confirm(values, { ...(onboardingStep && { onBoarding: true }) });
    };

    const initialValues = (onboardingStep) => {
      /**
       * we get created patient from saga or dashboard.context,
       * set this data by default
       */
      const initialData = {
        pid: choseExam ? examData?.pid : patient?.pid || '',
        patient_id: choseExam ? examData?.patient_id : patient?.id || '',
        scan: '',
        flow: CREATE_EXAMINATION_FLOW.DICOM_IN_PATIENT,
        width: '',
        eye: '',
        height: '',
        examination_day: '',
        examination_month: '',
        examination_year: '',
        note: '',
        patient_from_dicom: false,
        file: null,
      };

      if (onboardingStep) {
        return {
          ...initialData,
          file: [],
        };
      } else {
        return initialData;
      }
    };

    const generateOptions = () => {
      let result = [];
      examinations?.map((examination) => {
        if (examination.id === scanId) return; // remove current exam from adding
        if (
          compareType === COMPARE_TYPE.PROGRESSION &&
          compareProgress.examsData.find((card) => card.id === examination.id)
        )
          return;

        const createdDate = examination?.examination_date;
        const currentEyeLabel = [
          { label: `${t('right')} (OD)`, value: EYE_TYPES.OD },
          { label: `${t('left')} (OS)`, value: EYE_TYPES.OS },
        ].find((item) => item.value === examination?.eye)?.label;

        if (
          compareType === COMPARE_TYPE.BOTH &&
          examination.eye === examData.eye
        )
          return;

        if (
          compareType === COMPARE_TYPE.COMPARISON &&
          examination.eye !== examData.eye
        )
          return;

        if (
          compareType === COMPARE_TYPE.PROGRESSION &&
          examination.eye !== examData.eye
        )
          return;

        if (checkExistedData(examination)) {
          result?.push({
            ...examination,
            label: t('label', {
              name: examination?.patient_name || 'No name',
              data: DateFormatter.getDate(timeFormat, createdDate),
              eye: currentEyeLabel || 'N/A',
              scan: examination?.total_scans || 'N/A',
            }),
          });
        }
      });

      result = result.filter((item) => item.examination_status === 'finished');

      return result;
    };

    return (
      <div className={styles.wrapper}>
        {title && (
          <Text tag='h2' className={styles.title}>
            {title}
          </Text>
        )}
        {subtitle && (
          <Text align='center' color='gray' className={styles.subtitle}>
            {subtitle}
          </Text>
        )}
        <Text align='center' color='gray' className={styles['sub-text']}>
          {t('title1')}
          <strong>{t('title2')}</strong>
          {t('title3')}
          <strong>{t('title4')}</strong>
          {t('title5')}
        </Text>
        <Formik
          initialValues={initialValues(onboardingStep)}
          validationSchema={() => validateCreateExamination(i18n.t)}
          onSubmit={(values) => submitHandler(values)}
          innerRef={form}
          enableReinitialize
        >
          {({ setFieldValue, isValid, dirty, resetForm, values }) => (
            <Form className={styles.form}>
              {choseExam ? (
                <FormField
                  component={'select'}
                  name={'scan'}
                  label={t('select_exam_label')}
                  placeholder={t('select_exam_label')}
                  options={generateOptions()}
                  customStyles={customStyles1}
                  getOptionValue={(option) => option?.id}
                  onChange={(value) => {
                    resetForm();
                    setFieldValue('scan', value);
                  }}
                  labelType={'classic'}
                  withSeverities
                />
              ) : (
                <>
                  <FormField
                    component={'select'}
                    name={'patient_id'}
                    placeholder={t('patient_id_placeholder')}
                    label={t('patient_id_label')}
                    className={cn('shepherd-choose-patient-id')}
                    options={transformDataForSelect(patientData.patients)}
                    customStyles={customStyles1}
                    isSearchable
                    disabled={values.patient_from_dicom}
                    onChange={(item) => {
                      setFieldValue('pid', item.pid);
                      setFieldValue('patient_id', item.id);
                    }}
                    labelType={'classic'}
                  />
                  <SimpleMessage
                    className={styles.remaining}
                    Icon={NotesIco}
                    text={t('remaining_examinations', {
                      number:
                        userData.examinations_left === -1
                          ? t('unlimited')
                          : userData.examinations_left,
                    })}
                  />
                </>
              )}

              <TabComponent
                className={'shepherd-create-examination-tabs'}
                tabKey={'createTab'}
                data={tabs}
              />

              <div className={styles.buttons}>
                <Button
                  type='submit'
                  className={cn(
                    styles.button,
                    'shepherd-create-examination-btn',
                    {
                      'click-button':
                        onboardingStep === 'create-examination-step-6',
                      [styles.block]:
                        onboardingStep === 'create-examination-step-5',
                    }
                  )}
                  disabled={!(dirty && isValid) && !onboardingStep}
                >
                  {t('add_examination')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
);

CreateExamination.displayName = 'CreateExamination';

export default CreateExamination;
