import { settingsTypes } from 'redux/constants/settings';
import {
  ISettingsActions,
  ISettingsData,
  ISettingsSaveData,
} from 'types/settings';

export const getSettingsRequest = () => ({
  type: settingsTypes.GET_SETTINGS_REQUEST,
});

export const getSettingsSuccess = (data: ISettingsData[]) => ({
  type: settingsTypes.GET_SETTINGS_SUCCESS,
  payload: data,
});

export const getSettingsError = (error: unknown | string) => ({
  type: settingsTypes.GET_SETTINGS_ERROR,
  payload: error,
});

export const saveSettingsRequest = (
  data: ISettingsSaveData,
  action?: ISettingsActions
) => ({
  type: settingsTypes.SAVE_SETTINGS_REQUEST,
  payload: data,
  action: action,
});

export const saveSettingsSuccess = (data: ISettingsData[]) => ({
  type: settingsTypes.SAVE_SETTINGS_SUCCESS,
  payload: data,
});

export const saveSettingsError = (error: unknown | string) => ({
  type: settingsTypes.SAVE_SETTINGS_ERROR,
  payload: error,
});
