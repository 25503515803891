/**
 * @module redux/sagas/purchaseItem/getAllPurchaseType
 * @desc Redux saga to get all purchase types
 * Earlier we had several purchase type (exam and patients).
 * Now we have only one purchase type - exam.
 * * Temporary we don't use this saga.
 */
import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { getAllPurchaseType } from 'api/purchase';
import {
  getAllPurchaseTypeError,
  getAllPurchaseTypeSuccess,
} from 'redux/actions/purchaseItem';
import { purchaseConstants } from 'redux/constants/purchase';
import notify from 'utils/toast';

function* getAllPurchaseTypeWatcher() {
  yield takeEvery(
    purchaseConstants.GET_ALL_PURCHASE_TYPE_REQUEST,
    getAllPurchaseTypeWorker
  );
}

function* getAllPurchaseTypeWorker() {
  try {
    const { data: types } = yield getAllPurchaseType();

    if (types.status === 'Error') {
      throw new Error(types.message);
    }

    yield put(getAllPurchaseTypeSuccess(types.data));
  } catch (error) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    yield put(getAllPurchaseTypeError(error));
    yield notify('error', errorMessage);
  }
}

export default function* getAllPurchaseTypeWatcherSaga() {
  yield fork(getAllPurchaseTypeWatcher);
}
