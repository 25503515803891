import { put, fork, takeEvery } from 'redux-saga/effects';

import { getExamLayersStatus } from 'api/examination';
import {
  getLayersStatusError,
  getLayersStatusSuccess,
} from 'redux/actions/examination';
import { ExamActionTypes } from 'redux/constants/examination';
import { IWorkerProps } from 'redux/types/examination';
import { ScanLayersStatus } from 'types/examination';

function* getLayersStatusWatcher() {
  yield takeEvery(
    ExamActionTypes.GET_LAYERS_STATUS_REQUEST,
    getLayersStatusWorker
  );
}

interface ILayersStatusWorkerProps extends IWorkerProps {
  type: ExamActionTypes;
  abortSignal: AbortSignal;
  action: {
    default: () => void;
    success: (status?: ScanLayersStatus) => void;
    error: () => void;
  };
}

function* getLayersStatusWorker({
  payload,
  action,
  abortSignal,
}: ILayersStatusWorkerProps) {
  try {
    const { data: layersData } = yield getExamLayersStatus(
      payload,
      abortSignal
    );
    yield put(getLayersStatusSuccess(layersData.data));
    action?.success?.(layersData.data);
  } catch (error) {
    action?.error?.();
    console.error(error);
    yield put(getLayersStatusError('error'));
  }
}

export default function* getLayersStatusSaga() {
  yield fork(getLayersStatusWatcher);
}
