import { Link } from 'react-router-dom';

import { HistoryLinkProps } from './HistoryLink.types';

const HistoryLink = ({
  children,
  className,
  to,
  state,
  ...props
}: HistoryLinkProps): JSX.Element => {
  if (to.includes('http')) {
    return (
      <a
        href={to}
        className={className}
        target={'_blank'}
        rel='noopener noreferrer'
        {...props}
      >
        {children}
      </a>
    );
  }

  return (
    <Link to={to} state={state} className={className} {...props}>
      {children}
    </Link>
  );
};

export default HistoryLink;
