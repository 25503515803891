import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { getMeasManualId } from 'api/examination';
import {
  IExamManualIdActions,
  getExamMeasManualIdError,
  getExamMeasManualIdSuccess,
} from 'redux/actions/examination';
import { ExamActionTypes } from 'redux/constants/examination';
import notify from 'utils/toast';

function* getMeasManualIDWatcher() {
  yield takeEvery(
    ExamActionTypes.GET_EXAM_MEASUREMENTS_MANUAL_ID_REQUEST,
    measurementsWorker
  );
}

interface IMeasGetManualId {
  type: ExamActionTypes;
  action?: IExamManualIdActions;
  payload: {
    exam_id: string;
  };
  abortSignal?: AbortSignal;
}

function* measurementsWorker({
  payload,
  action,
  abortSignal,
}: IMeasGetManualId) {
  action?.default?.();

  try {
    const { exam_id } = payload;

    const { data: distanceData } = yield getMeasManualId(
      {
        exam_id,
      },
      abortSignal
    );

    if (distanceData.status === 'Error') {
      throw new Error(distanceData.message);
    }
    const result: string[] = Object.values(distanceData.data);
    yield put(getExamMeasManualIdSuccess(result));
    action?.success?.(result);
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    action?.error?.();
    yield notify('error', errorMessage);
    yield put(
      getExamMeasManualIdError({
        slug: 'something_went_wrong',
        message: 'Something went wrong',
      })
    );
  }
}

export default function* getMeasManualIdSaga() {
  yield fork(getMeasManualIDWatcher);
}
