import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { getSegmentationScans } from 'api/examination';
import {
  getSegmentationReportRequestError,
  getSegmentationReportRequestSuccess,
} from 'redux/actions/examination';
import { ExamActionTypes } from 'redux/constants/examination';
import { IWorkerProps } from 'redux/types/examination';
import notify from 'utils/toast';

function* segmentationReportWatcher() {
  yield takeEvery(
    ExamActionTypes.SCAN_SEGMENTATION_REPORT_REQUEST,
    segmentationReportWorker
  );
}

interface ISegmentationWorkerProps extends IWorkerProps {
  type: ExamActionTypes;
  payload: {
    exam_id: string;
    scans_id: string[];
  };
  abortSignal?: AbortSignal;
}

function* segmentationReportWorker({
  payload,
  action,
  abortSignal,
}: ISegmentationWorkerProps) {
  try {
    const { data: segmentations } = yield getSegmentationScans(
      payload.exam_id,
      payload.scans_id,
      abortSignal
    );
    if (segmentations.status === 'Error') {
      throw new Error(segmentations.message);
    }
    yield put(getSegmentationReportRequestSuccess(segmentations.data));
    action?.success?.();
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    if (error !== 'canceled') {
      yield notify('error', errorMessage);
    }

    action?.error?.();
    yield put(getSegmentationReportRequestError('error'));
  }
}

export default function* segmentationReportSaga() {
  yield fork(segmentationReportWatcher);
}
