import getAllPurchaseType from './getAllPurchaseType';
import getPurchaseTypeBySlug from './getPurchaseTypeBySlug';
import purchaseForExistingCard from './purchaseForExistingCard';
import purchaseForNewCard from './purchaseForNewCard';

export default [
  getAllPurchaseType,
  getPurchaseTypeBySlug,
  purchaseForExistingCard,
  purchaseForNewCard,
];
