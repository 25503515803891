import React from 'react';

import cn from 'classnames';

import useModalUI from '../../hooks/useModalUI';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import s from './Inner.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
}

/**
 *  Inner
 *  @param className
 *  Created by westprophet on 03.07.2024
 * @param children
 */

export default function Inner({ className = '', children }: Props) {
  const { onClose } = useModalUI();
  return (
    <div className={cn(s.Inner, className)}>
      {children}
      {onClose && (
        <button className={s.close} onClick={onClose}>
          <CloseIcon />
        </button>
      )}
    </div>
  );
}
