import { memo } from 'react';

interface RadioInputProps extends React.HTMLProps<HTMLInputElement> {}

const RadioButton = memo(({ ...props }: RadioInputProps) => {
  const {
    type,
    name,
    value,
    disabled,
    id,
    onChange,
    onBlur,
    label,
    checked,
    readOnly,
  } = props;

  return (
    <>
      <input
        readOnly={readOnly}
        onChange={onChange}
        id={id}
        type={type}
        value={value}
        checked={checked}
        name={name}
        disabled={disabled}
        onBlur={onBlur}
      />
      <label htmlFor={id}>{label}</label>
    </>
  );
});

RadioButton.displayName = 'RadioButton';

export default RadioButton;
