import { createContext } from 'react';

import { TModalProps } from './types';

export const ModalUIContext = createContext<
  Omit<TModalProps, 'children' | 'className'>
>({
  open: false,
  onClose: () => {},
});
