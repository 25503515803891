import Shepherd from 'shepherd.js';

import { helperShowStep } from 'services/onboardingTour/tourOptions';
import i18n from 'translation/i18n';

const STEP_ID = 'create-patient-step-5';

export const stepFive: Shepherd.Step.StepOptions = {
  id: STEP_ID,
  attachTo: { element: '.shepherd-patient-list', on: 'left' },
  beforeShowPromise: () =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        resolve();
      }, 500);
    }),
  canClickTarget: false,
  buttons: [
    {
      classes: 'shepherd-button-primary',
      text: i18n.t('onboarding.create_patient.next'),
      action() {
        this.show('create-examination-step-1');
      },
    },
  ],
  scrollTo: false,
  title: i18n.t('onboarding.create_patient.step_5_text_1'),
  text: [
    `${i18n.t('onboarding.create_patient.step_5_text_2')}</br>
    <p class="next-step">${i18n.t(
      'onboarding.create_patient.step_5_text_3'
    )}</p>
  `,
  ],
  when: {
    show: () => {
      helperShowStep(STEP_ID);
    },
  },
};
