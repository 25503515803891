import { contactUsTypes } from 'redux/constants/contactUs';
import { IContactUsState, contactUsActions } from 'types/contactUs';

const initialState: IContactUsState = {
  requesting: false,
  errors: null,
};

const contactUsReducer = (
  state = initialState,
  action: contactUsActions
): IContactUsState => {
  switch (action.type) {
    case contactUsTypes.SEND_CONTACT_US_REQUEST:
      return {
        ...state,
        requesting: true,
      };
    case contactUsTypes.SEND_CONTACT_US_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
      };
    case contactUsTypes.SEND_CONTACT_US_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default contactUsReducer;
