import {
  ChangeEvent,
  ElementRef,
  KeyboardEvent,
  Ref,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';

import styles from './VerificationCodeInput.module.scss';

export interface VerificationCodeInputControls {
  reset: () => void;
}

interface VerificationCodeInputProps extends React.HTMLProps<HTMLInputElement> {
  inputLength: number;
  controlsRef?: Ref<VerificationCodeInputControls>;
}

const VerificationCodeInput = memo(
  ({
    inputLength,
    name,
    id,
    onChange,
    autoFocus,
    controlsRef,
  }: VerificationCodeInputProps) => {
    const [localValue, setLocalValue] = useState('');
    const [isInputActive, setInputActiveActive] = useState(false);

    const inputRef = useRef<ElementRef<'input'>>(null);

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        const newInputValue = event.target.value
          .replace(/[^\da-zA-Z]/g, '')
          .slice(0, inputLength);

        event.target.value = newInputValue;

        onChange?.(event);
        setLocalValue(newInputValue);
      },
      [onChange, inputLength]
    );

    const handleKeyDown = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        // do not allow to change cursor position
        if (
          ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(
            event.key
          )
        ) {
          event.preventDefault();
        }
      },
      []
    );

    const focusOnInput = useCallback(() => {
      setInputActiveActive(true);
      inputRef.current?.focus();
    }, []);

    const handleBlur = useCallback(() => {
      setInputActiveActive(false);
    }, []);

    useImperativeHandle(controlsRef, () => ({
      reset() {
        setLocalValue('');
      },
    }));

    useEffect(() => {
      if (autoFocus) {
        focusOnInput();
      }
    }, [autoFocus]);

    return (
      <div className={styles['field-wrapper']}>
        <input
          className={styles.field}
          id={id}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          value={localValue}
          onKeyDown={handleKeyDown}
          autoComplete={'off'}
          type='text'
          ref={inputRef}
        />

        {Array.from({ length: inputLength }, (_, index) => {
          const isActive = isInputActive && localValue.length === index;
          const isEmpty = !localValue[index];

          let content = <></>;

          if (isActive) {
            content = (
              <>
                {localValue[index] ?? ''}
                <span className={styles.cursor}>|</span>
              </>
            );
          } else if (isEmpty) {
            content = <>-</>;
          } else {
            content = <>{localValue[index]}</>;
          }

          return (
            <button
              onClick={focusOnInput}
              className={classNames(styles['input-button'], {
                [styles.active]: isActive,
                [styles.empty]: isEmpty,
              })}
              key={index}
              tabIndex={-1}
              type={'button'}
            >
              {content}
            </button>
          );
        })}
      </div>
    );
  }
);

VerificationCodeInput.displayName = 'VerificationCodeInput';

export default VerificationCodeInput;
