import { useTranslation } from 'react-i18next';

import Button from 'components/Button';

import styles from './GlaucomaDisclaimer.module.scss';

interface GlaucomaDisclaimerProps {
  title: string;
  confirm: () => void;
}

const GlaucomaDisclaimer = ({ title, confirm }: GlaucomaDisclaimerProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'components.modal.glaucoma_disclaimer',
  });

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>{t('title')}</p>

      <p className={styles.description}>{title}</p>

      <Button onClick={confirm} className={styles.button}>
        {t('i_acknowledged')}
      </Button>
    </div>
  );
};

export default GlaucomaDisclaimer;
