import { lazy, Suspense } from 'react';

import cn from 'classnames';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';

import BookingMeeting from './BookingMeeting';
import CreateExamination from './CreateExamination';
import CreatePatient from './CreatePatient';
import EditScanDescription from './EditScanDescription';
import GlaucomaDisclaimer from './GlaucomaDisclaimer';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import ErrorFallback from 'components/ErrorFallback';
import Spinner from 'components/Spinner';
import SpinnerSVG from 'components/SpinnerSVG';
import { closeModal } from 'redux/actions/modal';
import { selectAppSettings } from 'redux/selectors/appSettings';
import { getExamData } from 'redux/selectors/examination/getExamData/getExamData';
import { patientsSelector } from 'redux/selectors/patients';
import { purchaseSelector } from 'redux/selectors/purchaseItem';
import { stripeSelector } from 'redux/selectors/stripe';
import { subscriptionSelector } from 'redux/selectors/subscription';
import { teamMemberSelector } from 'redux/selectors/teamMember';
import {
  ADDITIONAL_ITEM_MODAL_TYPE,
  EXAMINATION_MODAL_TYPE,
  ONBOARDING_MODAL_TYPE,
  PATIENT_MODAL_TYPE,
  SUBSCRIPTION_MODAL_TYPE,
  SURVEY_MODAL_TYPE,
  TEAM_MEMBER_MODAL_TYPE,
  USER_PROFILE_MODAL_TYPE,
} from 'utils/constants';

import styles from './Modal.module.scss';

const UpdatePatient = lazy(() => import('./UpdatePatient'));
const DeletePatient = lazy(() => import('./DeletePatient'));
const UpdateExam = lazy(() => import('./UpdateExam'));
const DeleteExam = lazy(() => import('./DeleteExam'));
const AnalyseScans = lazy(() => import('./AnalyseScans'));
const SelectLayers = lazy(() => import('./SelectLayers'));
const ExportJson = lazy(() => import('./ExportJson'));
const CurrentPlan = lazy(() => import('./CurrentPlan'));
const SubscribePlan = lazy(() => import('./SubscribePlan'));
const RenewSubscribeWarning = lazy(() => import('./RenewSubscribeWarning'));
const RenewSubscribeReject = lazy(() => import('./RenewSubscribeReject'));
const NoObjectsLeft = lazy(() => import('./NoObjectsLeft'));
const BuyAdditionalItems = lazy(() => import('./BuyAdditionalItems'));
const InviteTeamMember = lazy(() => import('./InviteTeamMember'));
const DeleteTeamMember = lazy(() => import('./DeleteTeamMember'));
const ResendTeamMember = lazy(() => import('./ResendTeamMember'));
const ImageCropper = lazy(() => import('./ImageCropper'));
const OnboardingTour = lazy(() => import('./OnboardingTour'));
const SurveyUnsubscribe = lazy(() => import('./SurveyUnsubscribe'));
const DownloadDCM = lazy(() => import('./DownloadDCM'));
const DownloadDCMSuccess = lazy(
  () => import('./DownloadDCM/DownloadDCMSuccess')
);
const ChangeSingleScanTab = lazy(() => import('./Tabs/ChangeSingleScanTab'));
const TrialPeriodEnd = lazy(() => import('./TrialPeriodEnd'));

const Modal = (props: any) => {
  const {
    type,
    className,
    cantClose = false,
    extraFunctional,
    waitForData = true,
  } = props;

  const { requesting: appSettingRequesting } = useSelector(selectAppSettings);
  const { requesting: examinationRequesting } = useSelector(getExamData);
  const { requesting: patientRequesting } = useSelector(patientsSelector);
  const { requesting: stripeRequesting } = useSelector(stripeSelector);
  const { requesting: teamMemberRequesting } = useSelector(teamMemberSelector);
  const { requesting: purchaseRequesting } = useSelector(purchaseSelector);
  const { requesting: subscriptionRequesting } =
    useSelector(subscriptionSelector);
  const dispatch = useDispatch();

  const closeModalHandler = () => {
    dispatch(closeModal());

    /**
     * extraFunctional is a function that is passed to the modal component
     * and is called when the modal is closed.
     * For example we download the DCM file and after that we close the modal.
     * in the OnboardingTour component
     */
    extraFunctional?.();
  };

  const renderModal = (type: string) => {
    switch (type) {
      case PATIENT_MODAL_TYPE.CREATE_PATIENT:
        return <CreatePatient {...props} />;
      case PATIENT_MODAL_TYPE.UPDATE_PATIENT:
        return <UpdatePatient {...props} />;
      case PATIENT_MODAL_TYPE.DELETE_PATIENT:
        return <DeletePatient {...props} />;
      case EXAMINATION_MODAL_TYPE.CREATE_EXAMINATION:
        return <CreateExamination {...props} />;
      case EXAMINATION_MODAL_TYPE.UPDATE_EXAMINATION:
        return <UpdateExam {...props} />;
      case EXAMINATION_MODAL_TYPE.DELETE_EXAMINATION:
        return <DeleteExam {...props} />;
      case EXAMINATION_MODAL_TYPE.ADD_EYE:
        return <CreateExamination choseExam {...props} />;
      case EXAMINATION_MODAL_TYPE.ANALYSE_SCANS:
        return <AnalyseScans {...props} />;
      case EXAMINATION_MODAL_TYPE.SELECT_LAYERS:
        return <SelectLayers {...props} />;
      case EXAMINATION_MODAL_TYPE.EXPORT_JSON:
        return <ExportJson {...props} />;
      case EXAMINATION_MODAL_TYPE.EDIT_SCAN_DESCRIPTION:
        return <EditScanDescription {...props} />;
      case SUBSCRIPTION_MODAL_TYPE.CURRENT_PLAN:
        return <CurrentPlan {...props} />;
      case SUBSCRIPTION_MODAL_TYPE.SUBSCRIBE_PLAN:
        return <SubscribePlan {...props} />;
      case SUBSCRIPTION_MODAL_TYPE.RENEW_WARNING:
        return <RenewSubscribeWarning {...props} />;
      case SUBSCRIPTION_MODAL_TYPE.RENEW_REJECT:
        return <RenewSubscribeReject {...props} />;
      case SUBSCRIPTION_MODAL_TYPE.NO_OBJECTS_LEFT:
        return <NoObjectsLeft {...props} />;
      case SUBSCRIPTION_MODAL_TYPE.TRIAL_PERIOD_END:
        return <TrialPeriodEnd {...props} />;
      case ADDITIONAL_ITEM_MODAL_TYPE.EXAMINATIONS:
        return <BuyAdditionalItems {...props} />;
      case TEAM_MEMBER_MODAL_TYPE.INVITE_TEAM_MEMBER:
        return <InviteTeamMember {...props} />;
      case TEAM_MEMBER_MODAL_TYPE.DELETE_TEAM_MEMBER:
        return <DeleteTeamMember {...props} />;
      case TEAM_MEMBER_MODAL_TYPE.REINVITE_TEAM_MEMBER:
        return <ResendTeamMember {...props} />;
      case USER_PROFILE_MODAL_TYPE.IMAGE_CROPPER:
        return <ImageCropper {...props} />;
      case ONBOARDING_MODAL_TYPE.ONBOARDING_TOUR:
        return <OnboardingTour {...props} />;
      case SURVEY_MODAL_TYPE.UNSUBSCRIBE:
        return <SurveyUnsubscribe {...props} />;
      case SURVEY_MODAL_TYPE.BOOKING_MEETING:
        return <BookingMeeting {...props} />;
      case ONBOARDING_MODAL_TYPE.DOWNLOAD_DCM:
        return <DownloadDCM {...props} />;
      case ONBOARDING_MODAL_TYPE.DOWNLOAD_SUCCESS:
        return <DownloadDCMSuccess {...props} />;
      case ONBOARDING_MODAL_TYPE.CHANGE_SINGLE_SCAN_TAB:
        return <ChangeSingleScanTab {...props} />;
      case EXAMINATION_MODAL_TYPE.GLAUCOMA_DISCLAIMER:
        return <GlaucomaDisclaimer {...props} />;
      default:
        break;
    }
  };

  return (
    <div className={cn(styles.body, className)}>
      <SpinnerSVG
        className={styles.spinner}
        active={
          // ? Should we separate each requesting by components?
          waitForData &&
          (examinationRequesting ||
            patientRequesting ||
            subscriptionRequesting ||
            stripeRequesting ||
            teamMemberRequesting ||
            purchaseRequesting ||
            appSettingRequesting)
        }
        fillColor={'var(--theme-color)'}
        progressBar
      />
      {/**
       * @param cantClose - if true, then the modal window cannot be closed
       */}
      {!cantClose && (
        <button className={styles.close} onClick={closeModalHandler}>
          <CloseIcon />
        </button>
      )}
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => window.location.reload()}
      >
        <Suspense
          fallback={
            <div className={styles.loader}>
              <Spinner />
            </div>
          }
        >
          {renderModal(type)}
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};

export default Modal;
