import { memo } from 'react';

import { IHeaderWithTabsProps } from './Header.types';

import TabComponent from 'components/TabComponent';

const HeaderWithTab = memo(
  ({ className, tabData }: IHeaderWithTabsProps): JSX.Element => {
    return (
      <div className={className}>
        <TabComponent data={tabData} className={className} />
      </div>
    );
  }
);

HeaderWithTab.displayName = 'HeaderWithTab';

export default HeaderWithTab;
