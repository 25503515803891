import { appSettingsTypes } from 'redux/constants/appSettings';

export const onGlobalLoader = () => ({
  type: appSettingsTypes.ON_GLOBAL_LOADER,
});

export const offGlobalLoader = () => ({
  type: appSettingsTypes.OFF_GLOBAL_LOADER,
});

export const onMaintenanceMode = () => ({
  type: appSettingsTypes.ACTIVE_MAINTENANCE_MODE,
});
