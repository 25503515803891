import { contactUsTypes } from 'redux/constants/contactUs';
import { IContactUsActions, IContactUsData } from 'types/contactUs';

export const sendContactUsRequest = (
  data: IContactUsData,
  action: IContactUsActions
) => ({
  type: contactUsTypes.SEND_CONTACT_US_REQUEST,
  payload: data,
  action: action,
});

export const sendContactUsSuccess = () => ({
  type: contactUsTypes.SEND_CONTACT_US_SUCCESS,
});

export const sendContactUsError = (error: unknown | string) => ({
  type: contactUsTypes.SEND_CONTACT_US_ERROR,
  payload: error,
});
