export const userConstants = {
  REGISTER_REQUEST: 'USER_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
  REGISTER_ERROR: 'USER_REGISTER_ERROR',

  LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  LOGIN_ERROR: 'USER_LOGIN_ERROR',

  LOGOUT_REQUEST: 'USER_LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'USER_LOGOUT_SUCCESS',
  LOGOUT_ERROR: 'USER_LOGOUT_ERROR',

  RESET_APP: 'RESET_APP',

  CHECK_EMAIL_REQUEST: 'USER_CHECK_EMAIL_REQUEST',
  CHECK_EMAIL_SUCCESS: 'USER_CHECK_EMAIL_SUCCESS',
  CHECK_EMAIL_ERROR: 'USER_CHECK_EMAIL_ERROR',

  GOOGLE_LOGIN_REQUEST: 'GOOGLE_LOGIN_REQUEST',
  GOOGLE_LOGIN_SUCCESS: 'GOOGLE_LOGIN_SUCCESS',
  GOOGLE_LOGIN_ERROR: 'GOOGLE_LOGIN_ERROR',

  FORGOT_PASSWORD_REQUEST: 'USER_FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'USER_FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR: 'USER_FORGOT_PASSWORD_ERROR',

  RESET_PASSWORD_REQUEST: 'USER_RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'USER_RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR: 'USER_RESET_PASSWORD_ERROR',

  REGISTER_INVITED_TEAM_MEMBER_REQUEST: 'REGISTER_INVITED_TEAM_MEMBER_REQUEST',
  REGISTER_INVITED_TEAM_MEMBER_SUCCESS: 'REGISTER_INVITED_TEAM_MEMBER_SUCCESS',
  REGISTER_INVITED_TEAM_MEMBER_ERROR: 'REGISTER_INVITED_TEAM_MEMBER_ERROR',

  UPDATE_INVITED_TEAM_MEMBER_REQUEST: 'UPDATE_INVITED_TEAM_MEMBER_REQUEST',
  UPDATE_INVITED_TEAM_MEMBER_SUCCESS: 'UPDATE_INVITED_TEAM_MEMBER_SUCCESS',
  UPDATE_INVITED_TEAM_MEMBER_ERROR: 'UPDATE_INVITED_TEAM_MEMBER_ERROR',

  SEND_OCUPLAN_FORM_REQUEST: 'SEND_OCUPLAN_FORM_REQUEST',
  SEND_OCUPLAN_FORM_SUCCESS: 'SEND_OCUPLAN_FORM_SUCCESS',
  SEND_OCUPLAN_FORM_ERROR: 'SEND_OCUPLAN_FORM_ERROR',
} as const;
