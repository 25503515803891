import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { getSurveyError, getSurveySuccess } from 'redux/actions/survey';
import { surveyTypes } from 'redux/constants/survey';
import notify from 'utils/toast';

function* getSurveyWatcher() {
  yield takeEvery(surveyTypes.GET_SURVEY_REQUEST, getSurveyWorker);
}

// Imitating API call for future update
const getSurvey = () => ({
  data: {
    data: [
      {
        label: 'Subscriptions are too expensive',
        value: 'Subscriptions are too expensive',
      },
      {
        label: 'I do not use Altris AI too often',
        value: 'I do not use Altris AI too often',
      },
      {
        label: 'I do not understand how to use it properly',
        value: 'I do not understand how to use it properly',
      },
      {
        label: 'There are no perfect plans for me',
        value: 'There are no perfect plans for me',
      },
      {
        label: 'Wanted to try, but understood that it’s not useful',
        value: 'Wanted to try, but understood that it’s not useful',
      },
      {
        label: 'There is no functionality, which I need',
        value: 'There is no functionality, which I need',
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ],
  },
});

function* getSurveyWorker() {
  try {
    const { data: surveyData } = getSurvey();

    yield put(getSurveySuccess({ unsubscribe: { reason: surveyData.data } }));
  } catch (error: unknown | string) {
    yield put(getSurveyError(error));
    yield notify('error', t('notifications.something_went_wrong'));
  }
}

export default function* getSettingsSaga() {
  yield fork(getSurveyWatcher);
}
