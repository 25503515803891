import { useCallback, useState } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';

import { ReactComponent as LogoBigIcon } from 'assets/icons/logo-big.svg';
import DemoPreviewImage2 from 'assets/images/finish-min.webp';
import DemoPreviewImage1 from 'assets/images/start-min.webp';

import styles from './DemoUserRegistration.module.scss';

const DemoUserRegistration = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');

  const { t } = useTranslation(undefined, {
    keyPrefix: 'demo_user_registration',
  });

  const onNextStep = useCallback(() => {
    setStep((prev) => prev + 1);
  }, []);

  const onPrevStep = useCallback(() => {
    setStep((prev) => prev - 1);
  }, []);

  const renderStep = () => {
    switch (step) {
      case 1:
        return <FirstStep onNextStep={onNextStep} setEmail={setEmail} />;

      case 2:
        return <SecondStep onNextStep={onNextStep} email={email} />;

      case 3:
        return <ThirdStep email={email} />;

      default:
        return null;
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.wrapper}>
        {step <= 2 && (
          <div className={cn(styles.banner, styles['first-banner'])}>
            <p className={styles.desktop}>{t('banner_text_1')}</p>

            <img src={DemoPreviewImage1} alt='image' title='image'></img>
          </div>
        )}
        <div
          className={cn(styles['form-wrapper'], {
            [styles['with-banner']]: step <= 2,
          })}
        >
          <LogoBigIcon className={styles.logo} />
          <div className={styles.buttonWrapper}>
            <div className={styles.form}>{renderStep()}</div>
            {step === 2 && (
              <button onClick={onPrevStep} className={styles.back}>
                {t('back')}
              </button>
            )}
          </div>
        </div>
        {step <= 2 && (
          <div className={cn(styles.banner, styles['second-banner'])}>
            <p className={styles.mobile}>{t('banner_text_2')}</p>
            <img src={DemoPreviewImage2} alt='image' title='image'></img>
            <p className={styles.pDesktop}>{t('banner_text_3')}</p>
          </div>
        )}{' '}
      </div>
    </div>
  );
};

export default DemoUserRegistration;
