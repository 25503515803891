import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { getUserBackup } from 'api/userBackup';
import {
  getUserBackupError,
  getUserBackupSuccess,
} from 'redux/actions/userBackup';
import { userBackupTypes } from 'redux/constants/userBackup';
import notify from 'utils/toast';

function* getUserBackupWatcher() {
  yield takeEvery(userBackupTypes.GET_BACKUP_REQUEST, getUserBackupWorker);
}

export function* getUserBackupWorker() {
  try {
    const { data: userBackup } = yield getUserBackup();

    if (userBackup.status === 'Error') {
      throw new Error(userBackup.message);
    }
    yield put(getUserBackupSuccess({ ...userBackup.data }));
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    yield put(getUserBackupError(error as string));

    yield notify('error', errorMessage);
  }
}

export default function* getUserBackupSaga() {
  yield fork(getUserBackupWatcher);
}
