import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { deleteTeamMember, getAllTeamMembers } from 'api/teamMember';
import { closeModal } from 'redux/actions/modal';
import {
  deleteTeamMemberError,
  deleteTeamMemberSuccess,
  getAllTeamMembersSuccess,
} from 'redux/actions/teamMember';
import { teamMemberConstants } from 'redux/constants/teamMember';
import notify from 'utils/toast';

function* deleteTeamMemberWatcher() {
  yield takeEvery(
    teamMemberConstants.DELETE_TEAM_MEMBER_REQUEST,
    deleteTeamMemberWorker
  );
}

function* deleteTeamMemberWorker({
  payload: id,
}: {
  payload: number;
  type: teamMemberConstants.DELETE_TEAM_MEMBER_REQUEST;
}) {
  try {
    yield deleteTeamMember(id);
    const { data: allTeamMembers } = yield getAllTeamMembers();
    if (allTeamMembers.status === 'Error') {
      throw new Error(allTeamMembers.message);
    }
    yield put(getAllTeamMembersSuccess(allTeamMembers.data));
    yield put(deleteTeamMemberSuccess());
    yield put(closeModal());
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    yield put(deleteTeamMemberError(error));
    yield notify('error', errorMessage);
    yield put(closeModal());
  }
}

export default function* deleteTeamMemberSaga() {
  yield fork(deleteTeamMemberWatcher);
}
