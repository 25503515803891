import cn from 'classnames';

import styles from '../FormField.module.scss';

interface TextAreaProps extends React.HTMLProps<HTMLTextAreaElement> {
  labelType?: string;
  tooltip?: string;
}

const TextArea = (props: TextAreaProps) => {
  const {
    name,
    id,
    disabled,
    placeholder,
    onChange,
    onBlur,
    value,
    rows = 10,
    label,
    labelType = 'float',
  } = props;

  return (
    <>
      <textarea
        className={cn(styles.field)}
        placeholder={placeholder}
        id={id}
        name={name}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        rows={rows}
      />
      <label
        className={cn({
          [styles[labelType]]: labelType,
        })}
        htmlFor={id}
      >
        {label}
      </label>
    </>
  );
};

export default TextArea;
