import { memo, useEffect, useState } from 'react';

import cn from 'classnames';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import customStyles from '../customSelectStyles';

import { IMainCardProps } from './MainCard.types';

import Button from 'components/Button';
import FormField from 'components/FormComponents';
import Text from 'components/Text';
import useDynamicViewportSize from 'hooks/useDynamicViewportSize';
import { useSettings } from 'hooks/useSettings';
import {
  currentPlanSelector,
  upcomingPlanSelector,
  userProfileSelector,
} from 'redux/selectors/userProfile';
import { SettingsEnum } from 'types/settings';
import {
  ISubscriptionPlan,
  ISubscriptionPlanOptions,
  ISubscriptionPrice,
} from 'types/subscription';
import { BOOKING_URL, MOBILE_RESOLUTION } from 'utils/constants';
import { DateFormatter } from 'utils/DateFormatter.class';
import { ga } from 'utils/helpers';
import { showCurrentPlanModal, subscribeNewPlanModal } from 'utils/openModals';

import styles from './MainCard.module.scss';

const MainCard = memo((props: IMainCardProps) => {
  const { plan, pricingType, currentPlans, monthlyPlans, yearlyPlans } = props;

  const { vw } = useDynamicViewportSize();
  const userData = useSelector(userProfileSelector);
  const userCurrentPlan = useSelector(currentPlanSelector);
  const upcomingPlan = useSelector(upcomingPlanSelector);
  const couponName = userData.data.coupon?.name;

  const timeFormat = useSettings<string>(SettingsEnum.TIME_FORMAT);

  const { t } = useTranslation(undefined, {
    keyPrefix: 'components.subscription_plan_card',
  });

  const [temporarySelectedPlan, setTemporarySelectedPlan] = useState<
    ISubscriptionPrice | any
  >({});

  const matchCurrentPlan = currentPlans.find(
    (item: ISubscriptionPrice) => item?.id === userCurrentPlan?.id
  );

  const matchUpcomingPlan = currentPlans.find(
    (item: ISubscriptionPrice) => item?.id === upcomingPlan?.id
  );

  const selectedMonthlyPlan =
    monthlyPlans.find(
      (item: ISubscriptionPrice) =>
        item.description === temporarySelectedPlan.description
    ) || null;

  const selectedYearlyPlan =
    yearlyPlans.find(
      (item: ISubscriptionPrice) =>
        item.description === temporarySelectedPlan.description
    ) || null;

  useEffect(() => {
    /** When we have one of the plan to show on the subscription page */
    if (selectedYearlyPlan || matchCurrentPlan || matchUpcomingPlan) {
      if (pricingType === 'year') {
        setTemporarySelectedPlan(
          (selectedYearlyPlan || matchCurrentPlan || matchUpcomingPlan) ??
            /** for fallback */
            yearlyPlans[1]
        );
      } else {
        setTemporarySelectedPlan(
          (selectedMonthlyPlan || matchCurrentPlan || matchUpcomingPlan) ??
            /** for fallback */
            monthlyPlans[1]
        );
      }
    } else {
      /** When we have not any subscribe we should to show different plan in select on mobile and pc:
       * - for the mobile we should to show first plan in the list
       * - for the pc we should to show second plan in the list
       * client requirement
       */

      if (pricingType === 'year') {
        setTemporarySelectedPlan(
          vw <= MOBILE_RESOLUTION ? yearlyPlans[0] : yearlyPlans[1]
        );
      } else {
        setTemporarySelectedPlan(
          vw <= MOBILE_RESOLUTION ? monthlyPlans[0] : monthlyPlans[1]
        );
      }
    }
  }, [pricingType]);

  if (plan.slug === 'enterprise') {
    return (
      <>
        <Text tag='h3' className={styles.title}>
          {plan.name}
        </Text>
        <div className={styles.cost}>
          <div className={cn(styles.price, styles.enterprise)}>
            {t('need_better_solution')}
          </div>
        </div>
        <div className={styles.texts}>
          <Text className={styles.text}>{t('personal_assistant')}</Text>
          <Text className={styles.text}>{t('unlimited_examinations')}</Text>
          <Text className={styles.text}>{t('unlimited_patients')}</Text>
          <Text className={styles.text}>
            {t('separate_accounts_for_doctors')}
          </Text>
          <Text className={styles.text}>
            {t('onboarding_trainings_for_the_whole_team')}
          </Text>
          <Text className={styles.text}>{t('platform_customization')}</Text>
        </div>
        <Button withShadow className={styles['subscribe-btn']} to={BOOKING_URL}>
          {t('contact')}
        </Button>
      </>
    );
  }

  const checkPlanForGa = (type: ISubscriptionPlan, isTrial?: boolean) => {
    if (couponName) {
      ga(
        `Subscription_${type.name.replaceAll(' ', '_')}${
          isTrial ? '_Trial' : ''
        }_${couponName}`
      );
    } else {
      ga(
        `Subscription_${type.name.replaceAll(' ', '_')}${
          isTrial ? '_Trial' : ''
        }`
      );
    }
  };

  const transformDataForSelect = (data: ISubscriptionPlanOptions[]) => {
    return data.map((item: ISubscriptionPlanOptions) => {
      const plan = item.prices.find(
        (item: ISubscriptionPrice) => item.recurring.interval === pricingType
      );

      return {
        label: `${item.examinations} / ${t('month')}`,
        value: plan?.stripe_id,
      };
    });
  };

  const initialValues = {
    plan_id: temporarySelectedPlan?.stripe_id,
  };

  return (
    <>
      <Text tag='h3' className={styles.title}>
        {plan.name}
        {plan.is_recommended && (
          <Text tag='span' className={styles.mark}>
            {t('best_choice')}
          </Text>
        )}
      </Text>
      <div
        className={cn(styles.cost, {
          [styles.discount]: matchCurrentPlan?.discount_price,
        })}
      >
        {plan.slug === 'free' ? (
          <></>
        ) : /** Check what price we should show on the page */
        matchCurrentPlan?.discount_price ? (
          <>
            {/* For the discount feature */}
            {/* <div className={styles.price}>
              <div className={styles.divider}></div>
              {`${matchCurrentPlan?.currency_symbol}${Math.trunc(
                Number(matchCurrentPlan?.price)
              )}`}
              <span>{`/${pricingType}`}</span>
            </div>

            <div className={styles.savings}>
              {pricingType === 'month'
                ? `*The discount is valid only for the six ${pricingType} of
                subscription`
                : ` *The discount is valid only for the first ${pricingType} of
                subscription`}
            </div>
      <div className={cn(styles.price, styles.sale)}>
              {`${matchCurrentPlan?.currency_symbol}${
                matchCurrentPlan?.discount_price
                  ? matchCurrentPlan?.discount_price
                  : pricingType === 'month'
                  ? `${currentPlan?.price / 10} /month`
                  : `${currentPlan?.price / 2} /year`
              }*`}
              {matchCurrentPlan?.discount_price && (
                <span>{`/${pricingType}`}</span>
              )}
            </div> */}
          </>
        ) : (
          <>
            <div className={styles.price}>
              {`${temporarySelectedPlan?.currency_symbol}${Math.trunc(
                Number(temporarySelectedPlan?.price)
              )}`}

              <span>{`/${pricingType}`}</span>
            </div>

            {pricingType === 'year' &&
              selectedMonthlyPlan &&
              selectedYearlyPlan && (
                <div className={styles.savings}>
                  {`${t('you_saving')}: ${
                    temporarySelectedPlan?.currency_symbol
                  }${
                    parseInt(selectedMonthlyPlan.price, 10) * 12 -
                    parseInt(selectedYearlyPlan.price, 10)
                  }`}
                </div>
              )}
          </>
        )}
      </div>
      <div className={styles['bold-content']}>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={() => {}}
        >
          {({ setFieldValue }) => {
            return (
              <Form className={styles.form}>
                <FormField
                  options={transformDataForSelect(plan.options)}
                  customStyles={customStyles}
                  component={'select'}
                  name={'plan_id'}
                  id={'plan_id'}
                  className={styles.field}
                  onChange={(item: any) => {
                    setFieldValue('plan_id', item.value);
                    setTemporarySelectedPlan(
                      currentPlans.find(
                        (plan: ISubscriptionPrice) =>
                          plan.stripe_id === item.value
                      ) || monthlyPlans[1]
                    );
                  }}
                />
              </Form>
            );
          }}
        </Formik>
      </div>

      {plan.slug === 'free' ? (
        userCurrentPlan?.slug === 'free' ? (
          <></>
        ) : upcomingPlan?.slug === 'free' ? (
          <Text className={styles['upcoming-description']} align='center'>
            {t('starting_from')} <br></br>
            {DateFormatter.getDate(timeFormat, upcomingPlan?.event_at)}
          </Text>
        ) : (
          <></>
        )
      ) : /**
       * Change Subscribe button to Details button when current
       * plan matches with ID plan
       */
      userCurrentPlan?.id === temporarySelectedPlan?.id ? (
        <>
          <Button
            appearance={'light'}
            className={styles['subscribe-btn']}
            onClick={() => showCurrentPlanModal(temporarySelectedPlan)}
          >
            {t('details')}
          </Button>
          <Text className={styles['current-description']} align='center'>
            {userCurrentPlan?.ends_at === null || upcomingPlan !== null
              ? t('current_plan')
              : `${t('active_till')} ${DateFormatter.getDate(
                  timeFormat,
                  userCurrentPlan?.ends_at
                )}`}
          </Text>
        </>
      ) : upcomingPlan?.id === temporarySelectedPlan?.id ? (
        <Text className={styles['upcoming-description']} align='center'>
          {t('starting_from')}
          <br></br>
          {DateFormatter.getDate(timeFormat, upcomingPlan?.event_at)}
        </Text>
      ) : (
        <>
          <Button
            withShadow
            className={styles['subscribe-btn']}
            onClick={() => {
              checkPlanForGa(plan);
              subscribeNewPlanModal(temporarySelectedPlan, {
                redirect: () => (window.location.href = '/'),
              });
            }}
          >
            {t('subscribe')}
          </Button>
          {/** Hide 'Try to free' button after use grace period */}
          {!userData.data?.is_trial_plan_used &&
            /** Disable trial plan for users who registered with coupon */
            !userData.data?.coupon && (
              <Button
                appearance={'simple'}
                className={styles.description}
                /**
                 * Second param it is an action object with @grace_period
                 */
                onClick={() => {
                  checkPlanForGa(plan, true);
                  subscribeNewPlanModal(temporarySelectedPlan, {
                    grace_period: true,
                  });
                }}
              >
                {t('days_free_trial', { number: '5' })}
              </Button>
            )}
        </>
      )}
    </>
  );
});

MainCard.displayName = 'MainCard';

export default MainCard;
