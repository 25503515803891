import Shepherd from 'shepherd.js';

import fakeDICOMFile from 'assets/images/dicom.png';
import { helperShowStep } from 'services/onboardingTour/tourOptions';
import i18n from 'translation/i18n';

const STEP_ID = 'create-examination-step-5';

export const stepFive: Shepherd.Step.StepOptions = {
  id: STEP_ID,
  attachTo: { element: '.shepherd-upload-dicom', on: 'right' },
  beforeShowPromise: () =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        resolve();
      }, 500);
    }),
  buttons: [],
  advanceOn: { selector: '.img-dicom', event: 'click' },
  scrollTo: { behavior: 'smooth', block: 'center' },
  title: i18n.t('onboarding.create_examination.add_new_examination'),
  text: [
    `${i18n.t('onboarding.create_examination.step_5_text_1')}
    <div class="img-wrapper drag-and-drop-button"><img class="img-dicom" draggable="true" src="${fakeDICOMFile}" alt="dicom file" title="dicom file"></div>
    <p class="next-step">${i18n.t(
      'onboarding.create_examination.step_5_text_2'
    )}</p>`,
  ],
  when: {
    show() {
      helperShowStep(STEP_ID);
    },
    hide() {
      this.getTour().removeStep('step-scans');
    },
  },
};
