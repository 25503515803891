import { MOCK_EXAM_ID } from './mockData';

import scan5 from 'assets/images/mockExamination/file.004.jpg';
import { ExaminationStateSchema } from 'types/examination';

export const mockSegmentation =
  (): ExaminationStateSchema['scanSegmentation'] => ({
    examination_id: MOCK_EXAM_ID,
    no_pathology: false,
    segmentations_left: -1,
    segmentations: [
      {
        scan_id: '6414e18e37dd936f800a77a9',
        link: scan5,
        thumbnail_link: scan5,
        scan_severity: 2,
        urgency_score: 2,
        description: '',
        response: {
          instances: [
            {
              list_position: 1,
              type: 'polygon',
              classId: 18,
              className: 'fibrovascular_rpe_detachment',
              probability: 0.7463520169258118,
              points: [
                723, 253, 722, 254, 718, 254, 716, 256, 678, 256, 677, 257, 674,
                257, 673, 258, 665, 258, 665, 260, 664, 261, 662, 261, 661, 262,
                660, 262, 659, 263, 658, 263, 657, 264, 656, 264, 655, 265, 654,
                265, 653, 266, 649, 266, 648, 267, 647, 267, 646, 268, 645, 268,
                644, 269, 643, 269, 642, 270, 640, 270, 639, 271, 638, 271, 637,
                272, 636, 272, 635, 273, 634, 273, 633, 274, 632, 274, 631, 275,
                630, 275, 629, 276, 628, 276, 627, 277, 626, 277, 625, 278, 624,
                278, 623, 279, 619, 279, 618, 280, 617, 280, 616, 279, 615, 279,
                614, 278, 614, 276, 615, 275, 616, 275, 615, 274, 614, 274, 613,
                273, 612, 273, 611, 274, 595, 274, 594, 273, 588, 273, 587, 272,
                586, 272, 585, 271, 582, 271, 581, 270, 580, 270, 579, 269, 558,
                269, 557, 268, 550, 268, 549, 269, 546, 269, 545, 270, 541, 270,
                540, 271, 539, 271, 538, 272, 537, 272, 536, 273, 531, 273, 530,
                272, 529, 272, 528, 271, 527, 271, 526, 270, 526, 268, 525, 269,
                516, 269, 515, 268, 515, 263, 514, 262, 513, 262, 511, 260, 509,
                260, 507, 262, 499, 262, 498, 263, 472, 263, 471, 262, 467, 262,
                466, 263, 459, 263, 458, 264, 454, 264, 453, 265, 450, 265, 448,
                267, 442, 267, 441, 268, 437, 268, 436, 269, 425, 269, 424, 270,
                418, 270, 416, 272, 390, 272, 389, 273, 386, 273, 385, 272, 349,
                272, 347, 270, 346, 271, 345, 271, 344, 272, 336, 272, 335, 273,
                331, 273, 330, 274, 329, 274, 328, 275, 326, 275, 325, 276, 324,
                276, 324, 278, 328, 278, 329, 279, 333, 279, 334, 280, 340, 280,
                341, 281, 352, 281, 354, 283, 365, 283, 366, 284, 385, 284, 386,
                285, 409, 285, 410, 286, 425, 286, 426, 287, 427, 287, 428, 288,
                441, 288, 442, 289, 453, 289, 454, 290, 471, 290, 472, 291, 500,
                291, 501, 292, 513, 292, 514, 291, 522, 291, 523, 292, 526, 292,
                527, 293, 528, 293, 529, 294, 530, 294, 531, 295, 549, 295, 550,
                296, 559, 296, 560, 295, 579, 295, 580, 294, 583, 294, 584, 293,
                585, 293, 586, 292, 587, 292, 588, 291, 589, 291, 590, 290, 594,
                290, 595, 289, 601, 289, 602, 288, 605, 288, 606, 287, 607, 287,
                608, 286, 609, 286, 610, 285, 621, 285, 622, 284, 631, 284, 632,
                285, 641, 285, 642, 284, 680, 284, 681, 283, 704, 283, 706, 281,
                719, 281, 696, 281, 695, 280, 695, 279, 696, 278, 705, 278, 706,
                277, 708, 277, 709, 276, 710, 276, 711, 275, 714, 275, 716, 273,
                717, 273, 717, 263, 718, 262, 719, 262, 720, 261, 721, 261, 722,
                260, 723, 260, 724, 259, 725, 259, 726, 258, 727, 258, 727, 255,
                726, 254, 725, 254, 724, 253,
              ],
              color: '#6200EA',
              opacity: '0.60',
              label: 'Fibrovascular RPE detachment',
              score: 10,
            },
            {
              list_position: 1,
              type: 'polygon',
              classId: 27,
              className: 'intraretinal_hyperreflective_foci',
              probability: 0.7949619293212891,
              points: [
                930, 230, 929, 231, 928, 231, 928, 233, 933, 233, 934, 232, 935,
                232, 936, 231, 937, 231, 936, 230,
              ],
              color: '#00695C',
              opacity: '0.80',
              label: 'Intraretinal hyperreflective foci',
              score: 4,
            },
            {
              list_position: 1,
              type: 'polygon',
              classId: 27,
              className: 'intraretinal_hyperreflective_foci',
              probability: 0.8741661310195923,
              points: [
                760, 228, 758, 230, 758, 232, 759, 232, 760, 233, 764, 233, 765,
                232, 765, 228,
              ],
              color: '#00695C',
              opacity: '0.80',
              label: 'Intraretinal hyperreflective foci',
              score: 4,
            },
            {
              list_position: 1,
              type: 'polygon',
              classId: 30,
              className: 'neurosensory_retina_atrophy',
              probability: 0.7505954504013062,
              points: [
                679, 228, 678, 229, 677, 229, 676, 230, 669, 230, 668, 231, 664,
                231, 663, 232, 659, 232, 658, 233, 654, 233, 652, 235, 646, 235,
                645, 236, 644, 236, 643, 237, 642, 237, 641, 238, 640, 238, 640,
                241, 643, 241, 644, 242, 645, 242, 646, 243, 650, 243, 651, 244,
                682, 244, 683, 243, 685, 243, 686, 242, 687, 242, 688, 241, 689,
                241, 689, 233, 688, 233, 686, 231, 686, 228,
              ],
              color: '#FF8602',
              opacity: '0.60',
              label: 'Neurosensory retina atrophy',
              score: 6,
            },
            {
              list_position: 1,
              type: 'polygon',
              classId: 46,
              className: 'rpe_disruption',
              probability: 0.7503722906112671,
              points: [
                534, 266, 533, 267, 532, 267, 531, 268, 527, 268, 527, 270, 528,
                270, 529, 271, 530, 271, 531, 272, 536, 272, 537, 271, 538, 271,
                539, 270, 540, 270, 541, 269, 545, 269, 546, 268, 549, 268, 541,
                268, 539, 266,
              ],
              color: '#6BFF06',
              opacity: '1.00',
              label: 'RPE disruption',
              score: 4,
            },
            {
              list_position: 1,
              type: 'polygon',
              classId: 46,
              className: 'rpe_disruption',
              probability: 0.7381840348243713,
              points: [
                718, 249, 716, 251, 688, 251, 687, 252, 678, 252, 677, 253, 671,
                253, 670, 254, 664, 254, 663, 255, 665, 257, 673, 257, 674, 256,
                677, 256, 678, 255, 716, 255, 718, 253, 722, 253, 722, 252, 721,
                252, 720, 251, 720, 249,
              ],
              color: '#6BFF06',
              opacity: '1.00',
              label: 'RPE disruption',
              score: 4,
            },
            {
              list_position: 1,
              type: 'polygon',
              classId: 46,
              className: 'rpe_disruption',
              probability: 0.7718448638916016,
              points: [
                20, 244, 20, 247, 19, 248, 12, 248, 18, 248, 19, 249, 22, 249,
                24, 251, 33, 251, 34, 252, 42, 252, 43, 253, 60, 253, 61, 254,
                76, 254, 78, 256, 94, 256, 95, 257, 111, 257, 112, 258, 121,
                258, 122, 259, 136, 259, 137, 260, 145, 260, 147, 262, 156, 262,
                157, 263, 165, 263, 166, 264, 173, 264, 174, 265, 182, 265, 184,
                267, 193, 267, 194, 268, 202, 268, 203, 269, 217, 269, 218, 270,
                243, 270, 244, 271, 245, 271, 246, 272, 261, 272, 262, 273, 271,
                273, 272, 274, 301, 274, 302, 275, 313, 275, 314, 276, 323, 276,
                324, 275, 325, 275, 326, 274, 328, 274, 329, 273, 330, 273, 331,
                272, 335, 272, 336, 271, 344, 271, 345, 270, 346, 270, 347, 269,
                349, 271, 385, 271, 386, 272, 389, 272, 390, 271, 416, 271, 418,
                269, 424, 269, 425, 268, 436, 268, 437, 267, 441, 267, 442, 266,
                448, 266, 450, 264, 453, 264, 454, 263, 458, 263, 459, 262, 466,
                262, 467, 261, 471, 261, 472, 262, 498, 262, 499, 261, 507, 261,
                507, 260, 504, 260, 503, 259, 501, 259, 500, 260, 482, 260, 481,
                259, 462, 259, 461, 260, 457, 260, 455, 262, 452, 262, 451, 263,
                445, 263, 444, 264, 440, 264, 439, 265, 433, 265, 432, 266, 431,
                266, 430, 267, 425, 267, 424, 268, 403, 268, 402, 269, 336, 269,
                335, 270, 319, 270, 318, 269, 297, 269, 296, 268, 272, 268, 271,
                267, 236, 267, 235, 266, 234, 266, 233, 265, 213, 265, 212, 264,
                198, 264, 197, 263, 189, 263, 188, 262, 177, 262, 176, 261, 175,
                261, 174, 260, 169, 260, 168, 259, 157, 259, 156, 258, 149, 258,
                148, 257, 137, 257, 136, 256, 128, 256, 127, 255, 126, 255, 125,
                254, 115, 254, 114, 253, 102, 253, 101, 252, 85, 252, 84, 251,
                71, 251, 70, 250, 69, 250, 68, 249, 58, 249, 57, 248, 46, 248,
                45, 247, 34, 247, 33, 246, 27, 246, 26, 245, 25, 245, 24, 244,
              ],
              color: '#6BFF06',
              opacity: '1.00',
              label: 'RPE disruption',
              score: 4,
            },
            {
              list_position: 1,
              type: 'polygon',
              classId: 46,
              className: 'rpe_disruption',
              probability: 0.7730625867843628,
              points: [
                1019, 222, 1018, 223, 1017, 223, 1016, 224, 1015, 224, 1014,
                225, 1013, 225, 1012, 226, 1011, 226, 1010, 227, 1006, 227,
                1005, 228, 1001, 228, 999, 230, 996, 230, 992, 234, 989, 234,
                987, 236, 984, 236, 983, 237, 979, 237, 978, 238, 967, 238, 966,
                239, 965, 239, 964, 240, 959, 240, 958, 241, 952, 241, 951, 242,
                947, 242, 946, 243, 939, 243, 938, 244, 928, 244, 927, 245, 926,
                245, 925, 246, 915, 246, 914, 247, 900, 247, 899, 248, 893, 248,
                892, 249, 886, 249, 885, 250, 884, 250, 883, 251, 875, 251, 879,
                251, 880, 252, 881, 252, 882, 253, 880, 255, 879, 255, 878, 256,
                877, 256, 876, 257, 870, 257, 869, 258, 865, 258, 864, 259, 863,
                259, 862, 260, 874, 260, 875, 259, 879, 259, 880, 258, 887, 258,
                888, 257, 892, 257, 893, 256, 898, 256, 900, 254, 906, 254, 907,
                253, 914, 253, 915, 252, 921, 252, 922, 251, 928, 251, 930, 249,
                938, 249, 939, 248, 946, 248, 947, 247, 953, 247, 954, 246, 957,
                246, 958, 245, 959, 245, 960, 244, 963, 244, 964, 243, 970, 243,
                971, 242, 972, 242, 973, 241, 977, 241, 978, 240, 979, 240, 980,
                239, 981, 239, 982, 238, 985, 238, 986, 237, 990, 237, 991, 236,
                997, 236, 998, 235, 1001, 235, 1002, 234, 1003, 234, 1004, 233,
                1007, 233, 1008, 232, 1012, 232, 1013, 231, 1017, 231, 1018,
                230, 1021, 230, 1021, 222,
              ],
              color: '#6BFF06',
              opacity: '1.00',
              label: 'RPE disruption',
              score: 4,
            },
            {
              list_position: 1,
              type: 'polygon',
              classId: 9,
              className: 'hard_drusen',
              probability: 0.8253487944602966,
              points: [
                977.2307692307693, 228.92307692307693, 974.7692307692307,
                230.15384615384616, 974.7692307692307, 231.3846153846154,
                969.8461538461538, 233.84615384615384, 969.8461538461538,
                236.30769230769232, 972.3076923076923, 237.53846153846155,
                977.2307692307693, 237.53846153846155, 979.6923076923077,
                236.30769230769232, 982.1538461538462, 236.30769230769232,
                984.6153846153846, 235.07692307692307, 987.0769230769231,
                235.07692307692307, 989.5384615384615, 233.84615384615384, 992,
                233.84615384615384, 992, 232.6153846153846, 994.4615384615385,
                231.3846153846154, 989.5384615384615, 231.3846153846154,
                987.0769230769231, 230.15384615384616, 984.6153846153846,
                230.15384615384616, 982.1538461538462, 228.92307692307693,
              ],
              color: '#B72F0F',
              opacity: '0.60',
              label: 'Hard drusen',
              score: 3,
            },
            {
              list_position: 1,
              type: 'polygon',
              classId: 10,
              className: 'soft_drusen',
              probability: 0.8297533392906189,
              points: [
                4.923076923076923, 233.84615384615384, 4.923076923076923,
                235.07692307692307, 2.4615384615384617, 236.30769230769232,
                2.4615384615384617, 240, 0, 241.23076923076923, 0,
                244.92307692307693, 4.923076923076923, 247.3846153846154,
                19.692307692307693, 247.3846153846154, 19.692307692307693,
                238.76923076923077, 17.23076923076923, 237.53846153846155,
                17.23076923076923, 236.30769230769232, 14.76923076923077,
                235.07692307692307, 12.307692307692308, 235.07692307692307,
                9.846153846153847, 233.84615384615384,
              ],
              color: '#E24018',
              opacity: '0.60',
              label: 'Soft drusen',
              score: 5,
            },
            {
              list_position: 1,
              type: 'polygon',
              classId: 40,
              className: 'reticular_pseudodrusen',
              probability: 0.7691393494606018,
              points: [
                930.4615384615385, 241.23076923076923, 928, 242.46153846153845,
                932.9230769230769, 242.46153846153845, 935.3846153846154,
                241.23076923076923,
              ],
              color: '#D50000',
              opacity: '0.60',
              label: 'Reticular pseudodrusen',
              score: 4,
            },
            {
              list_position: 1,
              type: 'polygon',
              classId: 40,
              className: 'reticular_pseudodrusen',
              probability: 0.7137115001678467,
              points: [
                1014.1538461538462, 221.53846153846155, 1014.1538461538462, 224,
              ],
              color: '#D50000',
              opacity: '0.60',
              label: 'Reticular pseudodrusen',
              score: 4,
            },
          ],
          classes: {
            availableForCurrentPlan: {
              wet_amd___choroidal_neovascularization: {
                list_position: 1,
                class_name: 'Choroidal neovascularization',
                low_confidence: false,
              },
              asteroid_hyalosis: {
                list_position: 1,
                class_name: 'Asteroid hyalosis',
                low_confidence: true,
              },
              central_retinal_artery_occlusion: {
                list_position: 1,
                class_name: 'Central retinal artery occlusion',
                low_confidence: true,
              },
              central_retinal_vein_occlusion: {
                list_position: 1,
                class_name: 'Central retinal vein occlusion',
                low_confidence: true,
              },
              central_serous_chorioretinopathy: {
                list_position: 1,
                class_name: 'Central serous chorioretinopathy',
                low_confidence: true,
              },
              chorioretinal_scar: {
                list_position: 1,
                class_name: 'Chorioretinal scar',
                low_confidence: true,
              },
              chorioretinitis: {
                list_position: 1,
                class_name: 'Chorioretinitis',
                low_confidence: true,
              },
              choroidal_melanoma: {
                list_position: 1,
                class_name: 'Choroidal melanoma',
                low_confidence: true,
              },
              choroidal_nevus: {
                list_position: 1,
                class_name: 'Choroidal nevus',
                low_confidence: true,
              },
              choroidal_rupture: {
                list_position: 1,
                class_name: 'Choroidal rupture',
                low_confidence: true,
              },
              cone_rod_dystrophy: {
                list_position: 1,
                class_name: 'Cone/rod dystrophy',
                low_confidence: true,
              },
            },
            unavailableForCurrentPlan: {},
          },
          metadata: {
            height: 512,
            width: 1024,
            isPredicted: true,
            do_filtering: true,
          },
          presentButUnavailableForCurrentPlan: [
            {
              list_position: 1,
              name: 'Is os disruption',
              slug: 'is_os_disruption',
              color: '#5E35B1',
              opacity: '1.00',
              score: 0,
            },
          ],
        },
      },
    ],
  });
