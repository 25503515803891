import createPatient from './createPatient';
import deletePatient from './deletePatient';
import filterPatient from './filterAllPatients';
import getAllPatientExam from './getAllPatientExam';
import getAllPatients from './getAllPatients';
import updatePatient from './updatePatient';

export default [
  createPatient,
  deletePatient,
  getAllPatientExam,
  getAllPatients,
  filterPatient,
  updatePatient,
];
