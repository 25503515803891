import {
  OPEN_MODAL,
  CLOSE_MODAL,
  OPEN_BLUR_MODAL,
  CLOSE_BLUR_MODAL,
} from '../constants/modal';

const initialState = {
  open: false,
  isBlurOpen: false,
};

const modalReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_MODAL:
      return {
        ...state,
        open: true,
        format: payload.format,
        data: payload.data,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        open: false,
        data: null,
        format: null,
      };
    case OPEN_BLUR_MODAL:
      return {
        ...state,
        isBlurOpen: true,
      };
    case CLOSE_BLUR_MODAL:
      return {
        ...state,
        isBlurOpen: false,
      };
    default:
      return state;
  }
};

export default modalReducer;
