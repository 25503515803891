import { useEffect } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ErrorFallback from 'components/ErrorFallback';
import MetaInfo from 'components/MetaInfo';
import SpinnerSVG from 'components/SpinnerSVG';
import SubscriptionPlans from 'components/SubscriptionPlans';
import Banner from 'components/TeamMemberList/Banner';
import Text from 'components/Text/Text';
import { useSettings } from 'hooks/useSettings';
import { getPaymentMethodRequest } from 'redux/actions/stripe';
import { getSubscriptionPlanRequest } from 'redux/actions/subscription';
import { subscriptionSelector } from 'redux/selectors/subscription';
import {
  currentPlanSelector,
  upcomingPlanSelector,
} from 'redux/selectors/userProfile';
import { SettingsEnum } from 'types/settings';
import { ROUTES } from 'utils/constants';
import { DateFormatter } from 'utils/DateFormatter.class';
import { unsubscribeModal } from 'utils/openModals';

import styles from './Plans.module.scss';

const Plans = () => {
  const dispatch = useDispatch();
  const subscriptionData = useSelector(subscriptionSelector);
  const userCurrentPlan = useSelector(currentPlanSelector);
  const userUpcomingPlan = useSelector(upcomingPlanSelector);

  const { t } = useTranslation(undefined, {
    keyPrefix: 'subscription_page.b2c',
  });

  const timeFormat = useSettings<string>(SettingsEnum.TIME_FORMAT);

  const getSubscriptionPlanAndMethod = () => {
    dispatch(getPaymentMethodRequest());
    dispatch(getSubscriptionPlanRequest());
  };

  useEffect(() => {
    getSubscriptionPlanAndMethod();
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={getSubscriptionPlanAndMethod}
    >
      <div className={styles.page}>
        <MetaInfo title='Subscription' />

        <SpinnerSVG
          className={styles.spinner}
          active={subscriptionData.requesting}
          fillColor={'var(--theme-color)'}
        />

        <div className={styles.banners}>
          {userCurrentPlan?.is_legacy && userUpcomingPlan?.slug !== 'free' && (
            <Banner
              className={styles.banner}
              appearance={'danger'}
              text={
                <Text>
                  {t('subscription_expired')}{' '}
                  <Link
                    to={ROUTES.PROFILE.SUBSCRIPTION}
                    className={styles.link}
                  >
                    {t('subscription_page')}
                  </Link>
                </Text>
              }
              action={{
                text: t('unsubscribe'),
                func: () => unsubscribeModal(),
              }}
            />
          )}
          {userCurrentPlan?.is_legacy && userUpcomingPlan?.slug === 'free' && (
            <Banner
              className={styles.banner}
              appearance={'info'}
              text={t('already_unsubscribed', {
                date: DateFormatter.getDate(
                  timeFormat,
                  userUpcomingPlan?.event_at
                ),
              })}
            />
          )}
        </div>

        <div className={styles.wrapper}>
          <SubscriptionPlans
            switcher
            title={t('subscription')}
            subtitle={t('choose_plan')}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
};

Plans.displayName = 'Plans';

export default Plans;
