import { helperShowStep } from 'services/onboardingTour/tourOptions';
import i18n from 'translation/i18n';

const STEP_ID = 'pathologies-detection-examination-step-5';

export const stepFive = {
  id: STEP_ID,
  attachTo: {
    element: '.shepherd-segmentation-btn',
    on: 'top',
  },
  beforeShowPromise: () =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, 500);
    }),
  buttons: [
    {
      classes: 'shepherd-button-primary',
      text: i18n.t('onboarding.show_examination.next'),
      type: 'next',
    },
  ],
  canClickTarget: false,
  scrollTo: false,
  title: i18n.t('onboarding.show_examination.pathologies_detection_text_16'),
  text: [
    `${i18n.t(
      'onboarding.show_examination.pathologies_detection_text_17'
    )} <span class='highlight'>${i18n.t(
      'onboarding.show_examination.pathologies_detection_text_18'
    )}</span> ${i18n.t(
      'onboarding.show_examination.pathologies_detection_text_19'
    )} <span class='highlight'>${i18n.t(
      'onboarding.show_examination.pathologies_detection_text_20'
    )}</span> ${i18n.t(
      'onboarding.show_examination.pathologies_detection_text_21'
    )}`,
  ],
  when: {
    show: () => {
      helperShowStep(STEP_ID);
    },
  },
};
