/**
 * Simple Layout
 * This layout has a default styles, but you can drop the class
 * from the page you want to edit and change the layout.
 * Please see the example on src/pages/PrivacyPolicy
 */

import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ISimpleLayoutProps } from './SimpleLayout.types';

import { ReactComponent as LogoIcon } from 'assets/icons/logo-light.svg';
import Button from 'components/Button';
import { userAuthenticatedSelector } from 'redux/selectors/userAuthenticated';
import { BASE_URL } from 'utils/constants';
import { signOutModal } from 'utils/openModals';

import styles from './SimpleLayout.module.scss';

const SimpleLayout = ({
  children,
  className,
}: ISimpleLayoutProps): JSX.Element => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'components.layout.simple_layout',
  });
  const { authenticated: isAuthenticated } = useSelector(
    userAuthenticatedSelector
  );

  return (
    <div className={styles.layout}>
      <div className={styles.header}>
        <Link to={BASE_URL}>
          <LogoIcon />
        </Link>
        {isAuthenticated && (
          <Button
            className={styles.logout}
            appearance='simple'
            onClick={signOutModal}
          >
            {t('sign_out')}
          </Button>
        )}
      </div>
      <div className={cn(styles.main, className)}>{children}</div>
    </div>
  );
};
SimpleLayout.displayName = 'SimpleLayout';

export default SimpleLayout;
