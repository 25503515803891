import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { reinviteTeamMember } from 'api/teamMember';
import { closeModal } from 'redux/actions/modal';
import {
  reinviteTeamMemberError,
  reinviteTeamMemberSuccess,
} from 'redux/actions/teamMember';
import { teamMemberConstants } from 'redux/constants/teamMember';
import notify from 'utils/toast';

function* reinviteTeamMemberWatcher() {
  yield takeEvery(
    teamMemberConstants.REINVITE_TEAM_MEMBER_REQUEST,
    reinviteTeamMemberWorker
  );
}

function* reinviteTeamMemberWorker({
  payload: id,
}: {
  payload: number;
  type: teamMemberConstants.REINVITE_TEAM_MEMBER_REQUEST;
}) {
  try {
    const { data } = yield reinviteTeamMember(id);

    if (data.status === 'Error') {
      throw new Error(data.message);
    }
    yield put(reinviteTeamMemberSuccess());
    yield notify('success', data.data);
    yield put(closeModal());
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    yield put(reinviteTeamMemberError('error'));
    yield notify('error', errorMessage);
    yield put(closeModal());
  }
}

export default function* reinviteTeamMemberSaga() {
  yield fork(reinviteTeamMemberWatcher);
}
