export enum userBackupTypes {
  CREATE_BACKUP_REQUEST = 'CREATE_BACKUP_REQUEST',
  CREATE_BACKUP_SUCCESS = 'CREATE_BACKUP_SUCCESS',
  CREATE_BACKUP_ERROR = 'CREATE_BACKUP_ERROR',

  CONFIRMATION_BY_CODE_SHOW = 'CONFIRMATION_BY_CODE_SHOW',
  CONFIRMATION_BY_CODE_HIDE = 'CONFIRMATION_BY_CODE_HIDE',

  VERIFY_CODE_REQUEST = 'VERIFY_CODE_REQUEST',
  VERIFY_CODE_SUCCESS = 'VERIFY_CODE_SUCCESS',
  VERIFY_CODE_ERROR = 'VERIFY_CODE_ERROR',

  GET_BACKUP_REQUEST = 'GET_BACKUP_REQUEST',
  GET_BACKUP_SUCCESS = 'GET_BACKUP_SUCCESS',
  GET_BACKUP_ERROR = 'GET_BACKUP_ERROR',

  DELETE_BACKUP_REQUEST = 'DELETE_BACKUP_REQUEST',
  DELETE_BACKUP_SUCCESS = 'DELETE_BACKUP_SUCCESS',
  DELETE_BACKUP_ERROR = 'DELETE_BACKUP_ERROR',
}
