import { t } from 'i18next';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { createUserBackupWorker } from './createUserBackup';

import { verifyUser } from 'api/userBackup';
import {
  confirmationBlockHide,
  verifyCodeError,
  verifyCodeSuccess,
} from 'redux/actions/userBackup';
import { userBackupTypes } from 'redux/constants/userBackup';
import notify from 'utils/toast';

function* verifyCodeWatcher() {
  yield takeEvery(userBackupTypes.VERIFY_CODE_REQUEST, verifyCodeWorker);
}

function* verifyCodeWorker({ payload, action }) {
  try {
    const { data: verifyUser } = yield verifyUser(payload);

    if (verifyUser.status === 'Error') {
      throw new Error(verifyUser.message);
    }

    yield call(createUserBackupWorker);

    yield put(verifyCodeSuccess());
    yield action.resetForm();
  } catch (error) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    if (error === 'The password is incorrect.') {
      if (action.attempt > 1) {
        yield action.setAttempt(action.attempt - 1);
        yield put(verifyCodeError(error));
        return;
      }
      yield action.setAttempt(3);
      yield put(verifyCodeError(error));
      yield put(confirmationBlockHide());
      yield action.resetForm();
      yield notify('error', t('notifications.attempts_over'));
      return;
    }

    yield put(verifyCodeError(error));
    yield notify('error', errorMessage);
  }
}

export default function* verifyCodeSaga() {
  yield fork(verifyCodeWatcher);
}
