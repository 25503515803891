import { settingsTypes } from 'redux/constants/settings';

/**
 * @description This all the settings options we have in the site.
 * @enum {string} - Slug of the option.
 * @property {string} DEVELOPER_MODE - Slug of the option for test new features (only for local and staging).
 * @property {string} SHOW_HINT - Slug of the option for show or hide the hints in the examinations tab.
 * @property {string} REFERRAL_SCORE - Slug of the option for show or hide the referral score in the pathologies tab.
 * @property {string} TIME_FORMAT - Slug of the option for time format on website.
 */
export enum SettingsEnum {
  DEVELOPER_MODE = 'developer_mode',
  SHOW_HINT = 'show_hint',
  REFERRAL_SCORE = 'referral_score',
  TIME_FORMAT = 'time_format',
  SHOW_PATHOLOGY_DESCRIPTION = 'show_pathology_description',
  SHOW_ALL_TEAM_PATIENTS = 'show_all_team_patients',
}

export interface ISettingsState {
  requesting: boolean;
  errors: null | string;
  data: ISettingsData[];
}

type SelectSetting = {
  field: 'select';
  options: {
    label: string;
    value: string;
  }[];
  status: string;
};

type CheckboxSetting = {
  field?: 'checkbox';
  status: boolean;
};

export type ISettingsData = {
  name: string;
  slug: SettingsEnum;
  description: string;
} & (SelectSetting | CheckboxSetting);
export interface ISettingsSaveData {
  [key: string]: ISettingsData['status'];
}

interface getSettingsRequestAction {
  type: settingsTypes.GET_SETTINGS_REQUEST;
}

interface getSettingsSuccessAction {
  type: settingsTypes.GET_SETTINGS_SUCCESS;
  payload: ISettingsData[];
}

interface getSettingsErrorAction {
  type: settingsTypes.GET_SETTINGS_ERROR;
  payload: string;
}

export type getSettingsActions =
  | getSettingsRequestAction
  | getSettingsSuccessAction
  | getSettingsErrorAction;

interface saveSettingsRequestAction {
  type: settingsTypes.SAVE_SETTINGS_REQUEST;
  payload: ISettingsData[];
}

interface saveSettingsSuccessAction {
  type: settingsTypes.SAVE_SETTINGS_SUCCESS;
  payload: ISettingsData[];
}

interface saveSettingsErrorAction {
  type: settingsTypes.SAVE_SETTINGS_ERROR;
  payload: string;
}

export type saveSettingsActions =
  | saveSettingsRequestAction
  | saveSettingsSuccessAction
  | saveSettingsErrorAction;

export type settingsActions = getSettingsActions | saveSettingsActions;

export interface ISettingsActions {
  resetForm: () => void;
}

export interface ISettingsDataAction {
  type: typeof settingsTypes.SAVE_SETTINGS_REQUEST;
  payload: ISettingsSaveData;
  action?: ISettingsActions;
}
