import Shepherd from 'shepherd.js';

import completeImg from 'assets/images/complete.png';
import { helperShowStep } from 'services/onboardingTour/tourOptions';
import i18n from 'translation/i18n';

const STEP_ID = 'create-report-step-4';

export const stepFour: Shepherd.Step.StepOptions = {
  id: STEP_ID,
  classes: STEP_ID,
  attachTo: { element: '', on: 'right' },
  beforeShowPromise: () =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        resolve();
      }, 500);
    }),
  buttons: [
    {
      classes: 'shepherd-button-primary',
      text: 'Start Using',
      action() {
        this.complete();
        localStorage.removeItem('onboarding');

        /**
         * Redirect to the dashboard page with additional query params
         * for opening downloadDMC modal
         */
        window.location.href = '/?firstExamination=true';
      },
    },
  ],
  scrollTo: false,
  title: i18n.t('onboarding.create_report.step_4_text_1'),
  text: [
    `${i18n.t('onboarding.create_report.step_4_text_2')}</br>
    ${i18n.t('onboarding.create_report.step_4_text_3')}
    <img class="ing" src="${completeImg}" alt="image" title="image">
    <p class="next-step">${i18n.t(
      'onboarding.create_report.step_4_text_4'
    )} <strong>${i18n.t(
      'onboarding.create_report.step_4_text_5'
    )}</strong></p>`,
  ],
  when: {
    show: () => {
      helperShowStep(STEP_ID);
    },
  },
};
