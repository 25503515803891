import { stepOne } from './step-1';
import { stepTwo } from './step-2';
import { stepThree } from './step-3';
import { stepThreeOne } from './step-3-1';
import { stepFour } from './step-4';
import { stepFive } from './step-5';
import { stepFiveOne } from './step-5-1';
import { stepSix } from './step-6';
import { stepSeven } from './step-7';
import { stepEight } from './step-8';

export default [
  stepOne,
  stepTwo,
  stepThree,
  stepThreeOne,
  stepFour,
  stepFive,
  stepFiveOne,
  stepSix,
  stepSeven,
  stepEight,
];
