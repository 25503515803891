import { offset } from '@floating-ui/dom';
import Shepherd from 'shepherd.js';

import { helperShowStep } from 'services/onboardingTour/tourOptions';
import i18n from 'translation/i18n';

const STEP_ID = 'single-scan-examination-step-3';

export const stepThree: Shepherd.Step.StepOptions = {
  id: STEP_ID,
  attachTo: {
    element: '.shepherd-single-scan',
    on: 'left',
  },
  modalOverlayOpeningPadding: 25,
  floatingUIOptions: {
    middleware: [offset(35)],
  },
  canClickTarget: false,
  beforeShowPromise: () =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        resolve();
      }, 500);
    }),
  buttons: [
    {
      classes: 'shepherd-button-primary',
      text: i18n.t('onboarding.show_examination.next'),
      action() {
        return this.next();
      },
    },
  ],
  scrollTo: { behavior: 'smooth', block: 'center' },
  title: i18n.t('onboarding.show_examination.single_scan_text_4'),
  text: [
    `${i18n.t('onboarding.show_examination.single_scan_text_5')}
    <ul class='count'>
      <li class='count-item'><span class='number'>1</span> ${i18n.t(
        'onboarding.show_examination.single_scan_text_6'
      )}</li>
      <li class='count-item'><span class='number'>2</span> ${i18n.t(
        'onboarding.show_examination.single_scan_text_7'
      )}</li>
      <li class='count-item'><span class='number'>3</span> ${i18n.t(
        'onboarding.show_examination.single_scan_text_8'
      )}</li>
      <li class='count-item'><span class='number'>4</span> ${i18n.t(
        'onboarding.show_examination.single_scan_text_9'
      )}</li>
      <li class='count-item'><span class='number'>5</span> ${i18n.t(
        'onboarding.show_examination.single_scan_text_10'
      )}</li>
      <li class='count-item'><span class='number'>6</span> ${i18n.t(
        'onboarding.show_examination.single_scan_text_11'
      )}</li>
      <li class='count-item'><span class='number'>7</span> ${i18n.t(
        'onboarding.show_examination.single_scan_text_12'
      )}</li>
    </ul>`,
  ],
  when: {
    show: () => {
      helperShowStep(STEP_ID);
    },
  },
};
