import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { getUnsubscribePlan } from 'api/subscription';
import {
  unsubscribePlanError,
  unsubscribePlanSuccess,
} from 'redux/actions/subscription';
import { getUserProfileRequest } from 'redux/actions/userProfile';
import { subscriptionConstants } from 'redux/constants/subscription';
import { unsubscribeSurveyModal } from 'utils/openModals';
import notify from 'utils/toast';

function* unsubscribePlanWatcher() {
  yield takeEvery(
    subscriptionConstants.UNSUBSCRIBE_PLAN_REQUEST,
    unsubscribePlanWorker
  );
}

function* unsubscribePlanWorker() {
  try {
    yield getUnsubscribePlan();
    yield put(getUserProfileRequest());
    yield put(unsubscribePlanSuccess());
    // yield put(closeModal());
    yield notify('success', 'notifications.subscription_canceled');

    /**
     * Unsubscribe survey modal instead of the close modal
     * * change this modal to the unsubscribe survey modal
     */
    yield unsubscribeSurveyModal();
  } catch (error) {
    yield put(unsubscribePlanError(error));
    yield notify('error', t('notifications.something_went_wrong'));
  }
}

export default function* unsubscribePlanSaga() {
  yield fork(unsubscribePlanWatcher);
}
