import { professionsAndCountriesTypes } from '../constants/professionsAndCountries';

import {
  IProfessionsCountriesAndProducersState,
  professionsAndCountriesActions,
} from 'types/professionAndCountry';

const initialState: IProfessionsCountriesAndProducersState = {
  requesting: false,
  errors: null,
  professions: [],
  countries: [],
  producers: [],
};

const professionsAndCountriesReducer = (
  state = initialState,
  action: professionsAndCountriesActions
): IProfessionsCountriesAndProducersState => {
  switch (action.type) {
    case professionsAndCountriesTypes.GET_PROFESSIONS_COUNTRIES_AND_PRODUCERS_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case professionsAndCountriesTypes.GET_PROFESSIONS_COUNTRIES_AND_PRODUCERS_SUCCESS:
      return {
        ...state,
        professions: action.payload.professions,
        countries: action.payload.countries,
        producers: action.payload.producers,
        requesting: false,
        errors: null,
      };
    case professionsAndCountriesTypes.GET_PROFESSIONS_COUNTRIES_AND_PRODUCERS_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
        professions: [],
        countries: [],
        producers: [],
      };
    default:
      return state;
  }
};

export default professionsAndCountriesReducer;
