import { t } from 'i18next';
import { fork, put, select, takeEvery } from 'redux-saga/effects';

import { getProgressCommonPathologiesData } from 'api/examination';
import {
  getProgressionCommonPathologiesDataAction,
  getProgressionCommonPathologiesDataError,
  getProgressionCommonPathologiesDataSuccess,
} from 'redux/actions/examination';
import { ExamActionTypes } from 'redux/constants/examination';
import { getExamData } from 'redux/selectors/examination/getExamData/getExamData';
import { CompareProgressData } from 'types/examination';
import notify from 'utils/toast';

function* getProgressionCommonPathologiesDataWatcher() {
  yield takeEvery(
    ExamActionTypes.GET_PROGRESSION_COMMON_PATHOLOGIES_DATA_REQUEST,
    getProgressionCommonPathologiesDataWorker
  );
}

interface IProgressionCommonPathologiesData {
  type: ExamActionTypes;
  payload: { examination_ids: string[] };
  action?: getProgressionCommonPathologiesDataAction;
  abortSignal?: AbortSignal;
}

function* getProgressionCommonPathologiesDataWorker({
  payload,
  action,
  abortSignal,
}: IProgressionCommonPathologiesData) {
  action?.default?.();

  try {
    // @ts-ignore
    const data = yield getProgressCommonPathologiesData(
      payload.examination_ids,
      abortSignal
    );

    (data.data.data as CompareProgressData['commonPathologiesData']).sort(
      (first, second) =>
        (first.segmentation?.list_position ?? 0) -
        (second.segmentation?.list_position ?? 0)
    );

    yield put(getProgressionCommonPathologiesDataSuccess(data.data.data));

    const { compareProgress } = yield select(getExamData);
    if (compareProgress.status === 'Error') {
      throw new Error(compareProgress.message);
    }

    const isSameScansAmount =
      compareProgress.examsData.length !== 0 &&
      compareProgress.examsData.every(
        // @ts-ignore
        (exam) =>
          exam.scans.length === compareProgress.examsData[0].scans.length
      );

    action?.success?.(
      data.data.data,
      isSameScansAmount
        ? compareProgress.examsData.map(
            // @ts-ignore
            (data) => data.scans[Math.ceil(data.scans.length / 2) - 1].scan_id
          )
        : []
    );
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    if (error !== 'canceled') {
      notify('error', errorMessage);
    }

    action?.error?.();
    yield put(getProgressionCommonPathologiesDataError());
  }
}

export default function* getProgressionCommonPathologiesDataSaga() {
  yield fork(getProgressionCommonPathologiesDataWatcher);
}
