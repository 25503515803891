import * as dayjs from 'dayjs';

import useProfile from 'hooks/useProfile';

export default function useIsUserTrialExpired(): boolean {
  const { is_trial_plan_used, plan } = useProfile();
  // If there is no trial period or the trial period was prepaid
  if (!plan.trial_ends_at || !is_trial_plan_used) return false;

  return dayjs.unix(plan.trial_ends_at).isBefore();
}
