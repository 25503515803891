import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { filterPatients } from 'api/patient';
import {
  filterPatientError,
  filterPatientSuccess,
} from 'redux/actions/patient';
import { patientConstants } from 'redux/constants/patient';
import { IFilterPatient, PatientsTypes } from 'types/patients';
import notify from 'utils/toast';

interface IFilterPatientWorker {
  type: PatientsTypes;
  payload: IFilterPatient;
}

function* filterPatientWatcher() {
  yield takeEvery(patientConstants.FILTER_PATIENT_REQUEST, filterPatientWorker);
}

function* filterPatientWorker({ payload }: IFilterPatientWorker) {
  try {
    const { data: patients } = yield filterPatients(payload);
    if (patients.status === 'Error') {
      throw new Error(patients.message);
    }

    yield put(filterPatientSuccess(patients.data));
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    yield put(filterPatientError(error));
    yield notify('error', errorMessage);
  }
}

export default function* filterPatientSaga() {
  yield fork(filterPatientWatcher);
}
