import { subscriptionConstants } from 'redux/constants/subscription';
import {
  IPaymentStatistics,
  ISubscriptionDetails,
  ISubscriptionPlan,
  ISubscriptionServices,
} from 'types/subscription';

// GET ALL SUBSCRIPTION_PLAN
export const getSubscriptionPlanRequest = () => ({
  type: subscriptionConstants.GET_SUBSCRIPTION_PLAN_REQUEST,
});

export const getSubscriptionPlanSuccess = (data: ISubscriptionPlan) => ({
  type: subscriptionConstants.GET_SUBSCRIPTION_PLAN_SUCCESS,
  payload: data,
});

export const getSubscriptionPlanError = (error: unknown | string) => ({
  type: subscriptionConstants.GET_SUBSCRIPTION_PLAN_ERROR,
  payload: error,
});

// SUBSCRIBE_PLAN
export const subscribePlanRequest = (
  data: ISubscriptionPlan,
  action: object
) => ({
  type: subscriptionConstants.SUBSCRIBE_PLAN_REQUEST,
  payload: data,
  action: action,
});

export const subscribePlanSuccess = (data: ISubscriptionPlan) => ({
  type: subscriptionConstants.SUBSCRIBE_PLAN_SUCCESS,
  payload: data,
});

export const subscribePlanError = (error: unknown | string) => ({
  type: subscriptionConstants.SUBSCRIBE_PLAN_ERROR,
  payload: error,
});

// UNSUBSCRIBE_PLAN
export const unsubscribePlanRequest = () => ({
  type: subscriptionConstants.UNSUBSCRIBE_PLAN_REQUEST,
});

export const unsubscribePlanSuccess = () => ({
  type: subscriptionConstants.UNSUBSCRIBE_PLAN_SUCCESS,
});

export const unsubscribePlanError = (error: unknown | string) => ({
  type: subscriptionConstants.UNSUBSCRIBE_PLAN_ERROR,
  payload: error,
});

// GET_SUBSCRIPTION_DETAILS
export const getSubscriptionDetailsRequest = () => ({
  type: subscriptionConstants.GET_SUBSCRIPTION_DETAILS_REQUEST,
});

export const getSubscriptionDetailsSuccess = (data: ISubscriptionDetails) => ({
  type: subscriptionConstants.GET_SUBSCRIPTION_DETAILS_SUCCESS,
  payload: data,
});

export const getSubscriptionDetailsError = (error: unknown | string) => ({
  type: subscriptionConstants.GET_SUBSCRIPTION_DETAILS_ERROR,
  payload: error,
});

// GET_SUBSCRIPTION_SERVICES
export const getSubscriptionServicesRequest = () => ({
  type: subscriptionConstants.GET_SUBSCRIPTION_SERVICES_REQUEST,
});

export const getSubscriptionServicesSuccess = (
  data: ISubscriptionServices[]
) => ({
  type: subscriptionConstants.GET_SUBSCRIPTION_SERVICES_SUCCESS,
  payload: data,
});

export const getSubscriptionServicesError = (error: unknown | string) => ({
  type: subscriptionConstants.GET_SUBSCRIPTION_SERVICES_ERROR,
  payload: error,
});

// GET_SUBSCRIPTION_SERVICES
export const getPaymentStatisticsRequest = () => ({
  type: subscriptionConstants.GET_PAYMENT_STATISTICS_REQUEST,
});

export const getPaymentStatisticsSuccess = (data: IPaymentStatistics[]) => ({
  type: subscriptionConstants.GET_PAYMENT_STATISTICS_SUCCESS,
  payload: data,
});

export const getPaymentStatisticsError = (error: unknown | string) => ({
  type: subscriptionConstants.GET_PAYMENT_STATISTICS_ERROR,
  payload: error,
});
