import Shepherd from 'shepherd.js';

import { helperShowStep } from 'services/onboardingTour/tourOptions';
import i18n from 'translation/i18n';

const STEP_ID = 'create-examination-step-7';

export const stepSeven: Shepherd.Step.StepOptions = {
  id: STEP_ID,
  attachTo: { element: '.shepherd-examination-list', on: 'left' },
  beforeShowPromise: () =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        resolve();
      }, 500);
    }),
  buttons: [
    {
      classes: 'shepherd-button-primary',
      text: i18n.t('onboarding.create_examination.next'),
      action() {
        return this.next();
      },
    },
  ],
  canClickTarget: false,
  scrollTo: false,
  title: i18n.t('onboarding.create_examination.examination_list'),
  text: [i18n.t('onboarding.create_examination.step_7_text')],
  when: {
    show: () => {
      helperShowStep(STEP_ID);
    },
  },
};
