import { all, fork } from 'redux-saga/effects';

import contactUsSaga from './contactUs';
import exportExamSaga from './examExport';
import examinationSaga from './examination';
import patientSaga from './patient';
import purchaseItemSaga from './purchaseItem';
import settingsSage from './settings';
import stripeSaga from './stripe';
import subscriptionSaga from './subscription';
import surveySaga from './survey';
import teamMemberSaga from './teamMember';
import userAuthSaga from './userAuth';
import userBackupSaga from './userBackup';
import userProfileSaga from './userProfile';

const sagas = [
  ...contactUsSaga,
  ...examinationSaga,
  ...exportExamSaga,
  ...patientSaga,
  ...purchaseItemSaga,
  ...stripeSaga,
  ...subscriptionSaga,
  ...teamMemberSaga,
  ...userAuthSaga,
  ...userBackupSaga,
  ...userProfileSaga,
  ...settingsSage,
  ...surveySaga,
];

export default function* rootSaga(services = {}) {
  yield all(sagas.map((saga) => fork(saga, services)));
}
