import { ReportActionTypesEnum } from 'redux/constants/report';
import { IReportState, ReportActions } from 'types/report';

const initialState: IReportState = {
  imagesReadyStatus: null,
  heatmapsReadyStatus: null,
  componentsReadyStatus: {},
  loadingPercent: undefined,
};

const reportReducer = (
  state = initialState,
  action: ReportActions
): IReportState => {
  switch (action.type) {
    case ReportActionTypesEnum.SET_IMAGES_TO_LOAD: {
      if (!state.imagesReadyStatus) {
        const totalItemsToLoad =
          Object.values(state.componentsReadyStatus).flat().length +
          (state.heatmapsReadyStatus?.total ?? 0) +
          action.payload;
        const loadedItems =
          Object.values(state.componentsReadyStatus).flat().filter(Boolean)
            .length + (state.heatmapsReadyStatus?.loaded ?? 0);

        return {
          ...state,
          imagesReadyStatus: {
            loaded: 0,
            total: action.payload,
          },
          loadingPercent: Math.floor((loadedItems / totalItemsToLoad) * 100),
        };
      }

      const totalItemsToLoad =
        state.imagesReadyStatus.total +
        action.payload +
        Object.values(state.componentsReadyStatus).flat().length +
        (state.heatmapsReadyStatus?.total ?? 0);
      const loadedItems =
        state.imagesReadyStatus.loaded +
        Object.values(state.componentsReadyStatus).flat().filter(Boolean)
          .length +
        (state.heatmapsReadyStatus?.loaded ?? 0);

      const newLoadingPercent = Math.floor(
        (loadedItems / totalItemsToLoad) * 100
      );

      return {
        ...state,
        imagesReadyStatus: {
          ...state.imagesReadyStatus,
          total: state.imagesReadyStatus.total + action.payload,
        },
        loadingPercent: newLoadingPercent,
      };
    }

    case ReportActionTypesEnum.LOAD_IMAGE: {
      if (!state.imagesReadyStatus) {
        return state;
      }

      const totalItemsToLoad =
        state.imagesReadyStatus.total +
        Object.values(state.componentsReadyStatus).flat().length +
        (state.heatmapsReadyStatus?.total ?? 0);
      const loadedItems =
        state.imagesReadyStatus.loaded +
        1 +
        Object.values(state.componentsReadyStatus).flat().filter(Boolean)
          .length +
        (state.heatmapsReadyStatus?.loaded ?? 0);

      const newLoadingPercent = Math.floor(
        (loadedItems / totalItemsToLoad) * 100
      );

      return {
        ...state,
        imagesReadyStatus: {
          ...state.imagesReadyStatus,
          loaded: state.imagesReadyStatus.loaded + 1,
        },
        loadingPercent: newLoadingPercent,
      };
    }

    case ReportActionTypesEnum.SET_COMPONENTS_READY: {
      const totalItemsToLoad =
        (state.imagesReadyStatus?.total ?? 0) +
        Object.values(action.payload).flat().length +
        (state.heatmapsReadyStatus?.total ?? 0);
      const loadedItems =
        (state.imagesReadyStatus?.loaded ?? 0) +
        Object.values(action.payload).flat().filter(Boolean).length +
        (state.heatmapsReadyStatus?.loaded ?? 0);

      const newLoadingPercent = Math.floor(
        (loadedItems / totalItemsToLoad) * 100
      );

      return {
        ...state,
        componentsReadyStatus: action.payload,
        loadingPercent: newLoadingPercent,
      };
    }

    case ReportActionTypesEnum.SET_HEATMAPS_TO_LOAD: {
      if (!state.heatmapsReadyStatus) {
        const totalItemsToLoad =
          Object.values(state.componentsReadyStatus).flat().length +
          (state.imagesReadyStatus?.total ?? 0) +
          action.payload;
        const loadedItems =
          Object.values(state.componentsReadyStatus).flat().filter(Boolean)
            .length + (state.imagesReadyStatus?.loaded ?? 0);

        return {
          ...state,
          heatmapsReadyStatus: {
            loaded: 0,
            total: action.payload,
          },
          loadingPercent: Math.floor((loadedItems / totalItemsToLoad) * 100),
        };
      }

      const totalItemsToLoad =
        state.heatmapsReadyStatus.total +
        action.payload +
        Object.values(state.componentsReadyStatus).flat().length +
        (state.imagesReadyStatus?.total ?? 0);
      const loadedItems =
        state.heatmapsReadyStatus.loaded +
        Object.values(state.componentsReadyStatus).flat().filter(Boolean)
          .length +
        (state.imagesReadyStatus?.loaded ?? 0);

      const newLoadingPercent = Math.floor(
        (loadedItems / totalItemsToLoad) * 100
      );

      return {
        ...state,
        heatmapsReadyStatus: {
          ...state.heatmapsReadyStatus,
          total: state.heatmapsReadyStatus.total + action.payload,
        },
        loadingPercent: newLoadingPercent,
      };
    }

    case ReportActionTypesEnum.LOAD_HEATMAP: {
      if (!state.heatmapsReadyStatus) {
        return state;
      }

      const totalItemsToLoad =
        state.heatmapsReadyStatus.total +
        Object.values(state.componentsReadyStatus).flat().length +
        (state.imagesReadyStatus?.total ?? 0);
      const loadedItems =
        state.heatmapsReadyStatus.loaded +
        1 +
        Object.values(state.componentsReadyStatus).flat().filter(Boolean)
          .length +
        (state.imagesReadyStatus?.loaded ?? 0);

      const newLoadingPercent = Math.floor(
        (loadedItems / totalItemsToLoad) * 100
      );

      return {
        ...state,
        heatmapsReadyStatus: {
          ...state.heatmapsReadyStatus,
          loaded: state.heatmapsReadyStatus.loaded + 1,
        },
        loadingPercent: newLoadingPercent,
      };
    }

    case ReportActionTypesEnum.RESET_REPORT_DATA:
      return initialState;

    default:
      return state;
  }
};

export default reportReducer;
