import React from 'react';

import cn from 'classnames';

import s from './ModalHeader.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
  onClose?: () => void;
}

/**
 *  ModalHeader
 *  @param className
 *  Created by westprophet on 03.07.2024
 * @param children
 * @param onClose
 */

export default function ModalHeader({ className = '', children }: Props) {
  return <div className={cn(s.ModalHeader, className)}>{children}</div>;
}
