export const scrollbarStyle = {
  '::-webkit-scrollbar': {
    width: '4px',
    height: '4px',
  },
  '::-webkit-scrollbar-corner': {
    background: 'transparent',
  },
  '::-webkit-scrollbar-track': {
    border: '0 none var(--light-color)',
    borderRadius: 4,

    '&:hover': {
      background: 'var(--background-color)',
    },

    '&:active': {
      background: 'var(--background-color)',
    },
  },
  '::-webkit-scrollbar-thumb': {
    border: '0 none var(--light-color)',
    borderRadius: 4,
    background: 'var(--theme-color-light)',

    '&:hover': {
      background: 'var(--theme-color-light)',
    },

    '&:active': {
      background: 'var(--theme-color-light)',
    },
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: 'var(--theme-color-dark)',
  },
};
