import Shepherd from 'shepherd.js';

import { helperShowStep } from 'services/onboardingTour/tourOptions';
import i18n from 'translation/i18n';

const STEP_ID = 'single-scan-examination-step-7';

export const stepSeven: Shepherd.Step.StepOptions = {
  id: STEP_ID,
  attachTo: { element: '.shepherd-etdrs', on: 'top' },
  buttons: [
    {
      classes: 'shepherd-button-primary',
      text: i18n.t('onboarding.show_examination.next'),
      action() {
        return this.next();
      },
    },
  ],
  canClickTarget: false,
  scrollTo: true,
  title: i18n.t('onboarding.show_examination.single_scan_text_34'),
  text: [
    `<ul class='count'>
      <li class='count-item'><span class='number'>1</span> ${i18n.t(
        'onboarding.show_examination.single_scan_text_35'
      )}</li>
      <li class='count-item'><span class='number'>2</span> ${i18n.t(
        'onboarding.show_examination.single_scan_text_36'
      )}</li>
      <li class='count-item'><span class='number'>3</span> ${i18n.t(
        'onboarding.show_examination.single_scan_text_37'
      )}</li>
    </ul>`,
  ],
  when: {
    show: () => {
      helperShowStep(STEP_ID);
    },
  },
};
