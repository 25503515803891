import { put, fork, takeEvery } from 'redux-saga/effects';

import { confirmPurchase } from 'api/purchase';
import {
  confirmPurchaseError,
  confirmPurchaseSuccess,
} from 'redux/actions/purchaseItem';
import { purchaseConstants } from 'redux/constants/purchase';

function* confirmPurchaseWatcher() {
  yield takeEvery(
    purchaseConstants.PURCHASE_FOR_NEW_CARD_REQUEST,
    confirmPurchaseWorker
  );
}

export function* confirmPurchaseWorker({ payload }) {
  try {
    yield confirmPurchase({ payment_intent_id: payload });
    yield put(confirmPurchaseSuccess());
  } catch (error) {
    yield put(confirmPurchaseError(error));
  }
}

export default function* confirmPurchaseSaga() {
  yield fork(confirmPurchaseWatcher);
}
