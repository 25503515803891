import { memo, useLayoutEffect, useState } from 'react';

import cn from 'classnames';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import customStyles from '../customSelectStyles';

import FormField from 'components/FormComponents';
import Text from 'components/Text';

import styles from './SimpleCard.module.scss';

const SimpleCard = memo(
  ({ plan, pricingType, currentPlan, monthlyPlan, yearlyPlan }) => {
    const [temporarySelectedPlan, setTemporarySelectedPlan] = useState({});

    const { t } = useTranslation(undefined, {
      keyPrefix: 'components.subscription_plan_card',
    });

    const selectedMonthlyPlan = monthlyPlan.find(
      (item) => item.description === temporarySelectedPlan.description
    );

    const selectedYearlyPlan = yearlyPlan.find(
      (item) => item.description === temporarySelectedPlan.description
    );

    useLayoutEffect(() => {
      if (pricingType === 'year') {
        setTemporarySelectedPlan(selectedYearlyPlan ?? yearlyPlan[0]);
      } else {
        setTemporarySelectedPlan(selectedMonthlyPlan ?? monthlyPlan[0]);
      }
    }, [pricingType]);

    const transformDataForSelect = (data) => {
      return data.map((item) => {
        const plan = item.prices.find(
          (item) => item.recurring.interval === pricingType
        );

        return {
          label: `${item.examinations} / ${t('month')}`,
          value: plan.stripe_id,
        };
      });
    };

    const initialValues = {
      plan_id: temporarySelectedPlan.stripe_id,
    };

    return (
      <div className={styles.card}>
        <div
          className={cn(styles.cost, {
            [styles.discount]: currentPlan?.discount_price,
          })}
        >
          {plan.slug === 'free' ? (
            <div className={styles.price}>
              {`${plan.prices[0]?.currency_symbol}0`}
            </div>
          ) : currentPlan?.discount_price ? (
            <>
              <div className={styles.price}>
                <div className={styles.divider}></div>
                {`${currentPlan?.currency_symbol}${Math.trunc(
                  currentPlan?.price
                )}`}
                <span>{`/${pricingType}`}</span>
              </div>

              <div className={cn(styles.price, styles.sale)}>
                {`${currentPlan?.currency_symbol}${
                  currentPlan?.discount_price
                    ? currentPlan?.discount_price
                    : pricingType === 'month'
                    ? `${currentPlan?.price / 10} / ${t('month_small')}`
                    : `${currentPlan?.price / 2} / ${t('year_small')}`
                }*`}
                {currentPlan?.discount_price && (
                  <span>{`/${pricingType}`}</span>
                )}
              </div>

              <Text align={'center'} className={styles.savings}>
                {pricingType === 'month'
                  ? t('monthly_description', { pricingType })
                  : t('daily_description', { pricingType })}
              </Text>
            </>
          ) : (
            <>
              <div className={styles.price}>
                {`${temporarySelectedPlan?.currency_symbol}${Math.trunc(
                  temporarySelectedPlan?.price
                )}`}

                <span>{`/${pricingType}`}</span>
                {pricingType === 'year' && (
                  <div className={styles.savings}>
                    {`${t('you_saving')}: ${
                      temporarySelectedPlan?.currency_symbol
                    }${
                      parseInt(selectedMonthlyPlan?.price, 10) * 12 -
                      parseInt(selectedYearlyPlan?.price, 10)
                    }`}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <Formik initialValues={initialValues} enableReinitialize>
          {({ setFieldValue }) => {
            return (
              <Form className={styles.form}>
                <FormField
                  options={transformDataForSelect(plan.options)}
                  customStyles={customStyles}
                  component={'select'}
                  name={'plan_id'}
                  id={'plan_id'}
                  className={styles.field}
                  onChange={(item) => {
                    setFieldValue('plan_id', item.value);
                    setTemporarySelectedPlan(
                      currentPlan?.find((plan) => plan.stripe_id === item.value)
                    );
                  }}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
);

SimpleCard.displayName = 'SimpleCard';

export default SimpleCard;
