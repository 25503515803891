import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { getSubscriptionServices } from 'api/subscription';
import {
  getSubscriptionServicesError,
  getSubscriptionServicesSuccess,
} from 'redux/actions/subscription';
import { subscriptionConstants } from 'redux/constants/subscription';
import notify from 'utils/toast';

function* getSubscriptionServicesWatcher() {
  yield takeEvery(
    subscriptionConstants.GET_SUBSCRIPTION_SERVICES_REQUEST,
    getSubscriptionServicesWorker
  );
}

function* getSubscriptionServicesWorker() {
  try {
    const { data: subscriptionServices } = yield getSubscriptionServices();

    yield put(getSubscriptionServicesSuccess(subscriptionServices.data));
    if (subscriptionServices.status === 'Error') {
      throw new Error(subscriptionServices.message);
    }
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    yield put(getSubscriptionServicesError(error));
    yield notify('error', errorMessage);
  }
}

export default function* getSubscriptionServicesSaga() {
  yield fork(getSubscriptionServicesWatcher);
}
