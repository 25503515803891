import { helperShowStep } from 'services/onboardingTour/tourOptions';
import i18n from 'translation/i18n';

const STEP_ID = 'measurements-examination-step-5';

export const stepFive = {
  id: STEP_ID,
  attachTo: {
    element: '.shepherd-measurements',
    on: 'top',
  },
  beforeShowPromise: () =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        resolve();
      }, 500);
    }),
  buttons: [
    {
      classes: 'shepherd-button-primary',
      text: i18n.t('onboarding.show_examination.next'),
      type: 'next',
    },
  ],
  canClickTarget: false,
  scrollTo: false,

  title: i18n.t('onboarding.show_examination.measurements_text_29'),
  text: [i18n.t('onboarding.show_examination.measurements_text_30')],
  when: {
    show: () => {
      helperShowStep(STEP_ID);
    },
  },
};
