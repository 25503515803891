import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';
import createSagaMiddleware from '@redux-saga/core';
import { applyMiddleware, legacy_createStore as createStore } from 'redux';

import { rootReducer } from '../reducers';
import rootSaga from '../sagas';

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  rootReducer,
  composeWithDevToolsDevelopmentOnly(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);
