import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { getSubscribePlan } from 'api/subscription';
import { closeBlurModal, closeModal } from 'redux/actions/modal';
import { getPatientRequest } from 'redux/actions/patient';
import { getPaymentMethodRequest } from 'redux/actions/stripe';
import {
  subscribePlanError,
  subscribePlanSuccess,
} from 'redux/actions/subscription';
import { getUserProfileRequest } from 'redux/actions/userProfile';
import { subscriptionConstants } from 'redux/constants/subscription';
import notify from 'utils/toast';

function* subscribePlanWatcher() {
  yield takeEvery(
    subscriptionConstants.SUBSCRIBE_PLAN_REQUEST,
    subscribePlanWorker
  );
}

export function* subscribePlanWorker({ payload, action }) {
  try {
    const { data, status } = yield getSubscribePlan(payload);
    /**
     * Status can be 202 for additional confirmation your person
     * you will be redirected to the stripe page for pay
     */
    if (status === 202) {
      window.location.replace(data.data.confirm_payment_url);
      return;
    }

    /**
     * Updating user info after subscribing to a new plan
     * for change subscribe button with the new plan
     */
    yield put(getUserProfileRequest());

    /**
     * Updating payment methods after subscribing to the new plan when
     * you add a new payment card and show them when you want to change
     * the plan without reloading the page
     */
    yield put(getPaymentMethodRequest());

    /**
     * Updating patient list after subscribing to a new plan
     * for update patients_left if necessary, enable
     * the "Add a new patient" button.
     */
    if (action?.needPatients) {
      yield put(getPatientRequest());
      yield put(closeBlurModal());
    }

    if (action?.redirect) {
      action.redirect();
    }

    yield put(subscribePlanSuccess());
    yield notify('success', t('notifications.subscription_plan_updated'));
    yield put(closeModal());
  } catch (error) {
    yield put(subscribePlanError(error));
    yield notify('error', t('notifications.something_went_wrong'));
  }
}

export default function* subscribePlanSaga() {
  yield fork(subscribePlanWatcher);
}
