import cn from 'classnames';

import Button from 'components/Button';
import Text from 'components/Text';

import styles from '../Modal.module.scss';

const DefaultModal = ({ data: { title, subtitle, buttons, className } }) => {
  return (
    <div className={cn(styles['modal_content'], styles[className])}>
      <div className={styles['text-wrapper']}>
        {title && (
          <Text
            color='dark-color'
            align='center'
            className={styles['modal-title']}
          >
            {title}
          </Text>
        )}
        {subtitle && (
          <Text
            align='center'
            color='gray'
            className={styles['modal-subtitle']}
          >
            {subtitle}
          </Text>
        )}
      </div>
      {buttons && (
        <div className={styles['modal-buttons']}>
          {buttons.map((item, index) => {
            return (
              <Button
                key={index}
                appearance={item.appearance}
                onClick={item.event}
              >
                {item.title}
              </Button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DefaultModal;
