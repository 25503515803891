import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { getAllTeamMembers, inviteTeamMember } from 'api/teamMember';
import { closeModal } from 'redux/actions/modal';
import {
  getAllTeamMembersSuccess,
  inviteTeamMemberError,
  inviteTeamMemberSuccess,
} from 'redux/actions/teamMember';
import { teamMemberConstants } from 'redux/constants/teamMember';
import { ITeamMemberInvite, ITeamMemberInviteActions } from 'types/teamMember';
import notify from 'utils/toast';

export interface ITeamMemberInviteWorker {
  type: teamMemberConstants.INVITE_TEAM_MEMBER_REQUEST;
  payload: ITeamMemberInvite;
  action: ITeamMemberInviteActions;
}

function* inviteTeamMemberWatcher() {
  yield takeEvery(
    teamMemberConstants.INVITE_TEAM_MEMBER_REQUEST,
    inviteTeamMemberWorker
  );
}

function* inviteTeamMemberWorker({ payload, action }: ITeamMemberInviteWorker) {
  try {
    yield inviteTeamMember(payload);
    const { data: allTeamMembers } = yield getAllTeamMembers();

    if (allTeamMembers.status === 'Error') {
      throw new Error(allTeamMembers.message);
    }

    yield put(getAllTeamMembersSuccess(allTeamMembers.data));
    yield put(inviteTeamMemberSuccess());
    yield notify('success', t('notifications.doctor_invited'));
    yield put(closeModal());
  } catch (error: any) {
    /**
     * If error is a string, then it is a server error
     */

    const errorMessage =
      error.message || t('notifications.something_went_wrong');

    yield notify('error', errorMessage);
    yield put(inviteTeamMemberError(error));
    yield put(closeModal());
    return;

    /**
     * If error is an object, then it is a validation error
     */
    yield action.error(error);
    yield put(
      inviteTeamMemberError(
        'Something wrong with the server, please try a little bit later'
      )
    );
  }
}

export default function* inviteTeamMemberSaga() {
  yield fork(inviteTeamMemberWatcher);
}
