import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { getThicknessMap } from 'api/examination';
import {
  getExamThicknessMapError,
  getExamThicknessMapSuccess,
} from 'redux/actions/examination';
import { ExamActionTypes } from 'redux/constants/examination';
import { IWorkerProps } from 'redux/types/examination';

function* thicknessWatcher() {
  yield takeEvery(
    ExamActionTypes.GET_EXAM_THICKNESS_MAP_REQUEST,
    thicknessWorker
  );
}

interface IThicknessWorkerProps extends IWorkerProps {
  type: ExamActionTypes;
  payload: {
    exam_id: string;
    layers?: string[];
    type?: string;
  };
}

function* thicknessWorker({ payload, action }: IThicknessWorkerProps) {
  action?.default?.();

  try {
    const { exam_id, layers, type } = payload;

    if (layers) {
      const { data: mainData } = yield getThicknessMap(
        exam_id,
        JSON.stringify(layers)
      );
      if (mainData.status === 'Error') {
        throw new Error(mainData.message);
      }
      if (!mainData.data) return;

      yield put(getExamThicknessMapSuccess(mainData.data, type));
    } else {
      const { data: mainData } = yield getThicknessMap(exam_id);

      if (!mainData.data) return;
      if (mainData.status === 'Error') {
        throw new Error(mainData.message);
      }
      yield put(getExamThicknessMapSuccess(mainData.data));
    }

    action?.success?.();
  } catch (error: any) {
    action?.error?.();
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    yield put(getExamThicknessMapError(errorMessage));
  }
}

export default function* thicknessSaga() {
  yield fork(thicknessWatcher);
}
