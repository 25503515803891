import { memo, useLayoutEffect, useState } from 'react';

import cn from 'classnames';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import customStyles from '../customSelectStyles';

import { ReactComponent as CheckIcon } from 'assets/icons/check-mark-rounded.svg';
import Button from 'components/Button';
import FormField from 'components/FormComponents';
import Text from 'components/Text';
import useDynamicViewportSize from 'hooks/useDynamicViewportSize';
import { userProfileSelector } from 'redux/selectors/userProfile';
import {
  ISubscriptionPlan,
  ISubscriptionPlanOptions,
  ISubscriptionPrice,
} from 'types/subscription';
import { MOBILE_RESOLUTION } from 'utils/constants';
import { ga } from 'utils/helpers';
import { subscribeNewPlanModal } from 'utils/openModals';

import styles from './SecondaryCard.module.scss';

interface SecondaryCardProps {
  plan: ISubscriptionPlan;
  pricingType: 'month' | 'year';
  currentPlan: ISubscriptionPrice[];
  monthlyPlan: ISubscriptionPrice[];
  yearlyPlan: ISubscriptionPrice[];
}

const SecondaryCard = memo(
  ({
    plan,
    pricingType,
    currentPlan,
    monthlyPlan,
    yearlyPlan,
  }: SecondaryCardProps) => {
    const { vw } = useDynamicViewportSize();
    const userData = useSelector(userProfileSelector);
    const couponName = userData.data.coupon?.name;
    const [temporarySelectedPlan, setTemporarySelectedPlan] =
      useState<ISubscriptionPrice>();

    const { t } = useTranslation(undefined, {
      keyPrefix: 'components.subscription_plan_card',
    });

    const selectedMonthlyPlan = monthlyPlan.find(
      (item) => item.description === temporarySelectedPlan?.description
    );

    const selectedYearlyPlan = yearlyPlan.find(
      (item) => item.description === temporarySelectedPlan?.description
    );

    useLayoutEffect(() => {
      if (pricingType === 'year') {
        setTemporarySelectedPlan(selectedYearlyPlan ?? yearlyPlan[0]);
      } else {
        setTemporarySelectedPlan(selectedMonthlyPlan ?? monthlyPlan[0]);
      }
    }, [pricingType]);

    const checkPlanForGa = (type: ISubscriptionPlan, isTrial?: boolean) => {
      if (couponName) {
        ga(
          `Subscription_${type.name.replaceAll(' ', '_')}${
            isTrial ? '_Trial' : ''
          }_${couponName}`
        );
      } else {
        ga(
          `Subscription_${type.name.replaceAll(' ', '_')}${
            isTrial ? '_Trial' : ''
          }`
        );
      }
    };

    const regularSubscription = () => {
      checkPlanForGa(plan);

      /**
       * Second param it is an action object @needPatient for
       * update examination_left
       */

      subscribeNewPlanModal(temporarySelectedPlan, {
        needPatients: true,
      });
    };

    const transformDataForSelect = (data: ISubscriptionPlanOptions[]) => {
      return data.map((item) => {
        const plan = item.prices.find(
          (item) => item.recurring.interval === pricingType
        );

        return {
          label: `${item.examinations} / ${t('month')}`,
          value: plan?.stripe_id,
        };
      });
    };

    const initialValues = {
      plan_id: temporarySelectedPlan?.stripe_id,
    };

    return (
      <div
        className={cn(styles.wrapper, {
          [styles.recommended]: plan.is_recommended,
        })}
      >
        <Text className={styles.title}>
          {plan.name}
          {plan.is_recommended && (
            <span className={styles.mark}>{t('best_choice')}</span>
          )}
        </Text>

        <div
          className={cn(styles.cost, {
            // @ts-ignore
            [styles.discount]: currentPlan?.discount_price,
          })}
        >
          {plan.slug === 'free' ? (
            <div className={styles.price}>
              {/* @ts-ignore */}
              {`${plan.prices[0]?.currency_symbol}0`}
              <span>{`/${pricingType}`}</span>
            </div>
          ) : /** Check what price we should show on the page */
          // @ts-ignore
          currentPlan?.discount_price ? (
            <>
              <div className={styles.price}>
                <div className={styles.divider}></div>
                {/* @ts-ignore */}
                {`${currentPlan?.currency_symbol}${Math.trunc(
                  // @ts-ignore
                  currentPlan?.price
                )}`}
                <span>{`/${pricingType}`}</span>
              </div>

              <div className={styles.price}>
                {/* @ts-ignore */}
                {`${currentPlan?.currency_symbol}${
                  // @ts-ignore
                  currentPlan?.discount_price
                    ? // @ts-ignore
                      currentPlan?.discount_price
                    : pricingType === 'month'
                    ? // @ts-ignore
                      `${currentPlan?.price / 10} /month`
                    : // @ts-ignore
                      `${currentPlan?.price / 2} /year`
                }*`}
                {/* @ts-ignore */}
                {currentPlan?.discount_price && (
                  <span>{`/${pricingType}`}</span>
                )}
              </div>

              <Text align={'center'} className={styles.savings}>
                {pricingType === 'month'
                  ? t('monthly_description', { pricingType })
                  : t('daily_description', { pricingType })}
              </Text>
            </>
          ) : (
            <>
              <div className={styles.price}>
                {`${temporarySelectedPlan?.currency_symbol}${Math.trunc(
                  +(temporarySelectedPlan?.price ?? 0)
                )}`}
                <span>{`/${pricingType}`}</span>
              </div>
              {pricingType === 'year' && (
                <div className={styles.savings}>
                  {`${t('you_saving')}: ${
                    temporarySelectedPlan?.currency_symbol
                  }${
                    parseInt(selectedMonthlyPlan?.price ?? '', 10) * 12 -
                    parseInt(selectedYearlyPlan?.price ?? '', 10)
                  }`}
                </div>
              )}
            </>
          )}
        </div>
        <div className={cn(styles.good, styles.select)}>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={() => {}}
          >
            {({ setFieldValue }) => {
              return (
                <Form className={styles.form}>
                  <FormField
                    options={transformDataForSelect(plan.options)}
                    customStyles={customStyles}
                    component={'select'}
                    name={'plan_id'}
                    id={'plan_id'}
                    className={styles.field}
                    onChange={(item: any) => {
                      setFieldValue('plan_id', item.value);
                      setTemporarySelectedPlan(
                        currentPlan?.find(
                          (plan) => plan.stripe_id === item.value
                        )
                      );
                    }}
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
        <div className={styles.good}>
          <div className={styles.svg}>
            <CheckIcon />
          </div>
          <Text className={styles.text}>
            <span>
              {+plan.services_with_values['examinations'] === -1
                ? t('unlimited')
                : plan.services_with_values['examinations']}
            </span>{' '}
            {t('examinations_for_ai_screening')}
          </Text>
        </div>
        {plan.slug !== 'free' && (
          <div className={styles.good}>
            <div className={styles.svg}>
              <CheckIcon />
            </div>
            <Text className={styles.text}>
              {t('pathological_signs_detection')}
            </Text>
          </div>
        )}
        <div className={styles.good}></div>

        <>
          {vw <= MOBILE_RESOLUTION && plan.slug === 'free' ? (
            <Text className={styles['current-description']} align='center'>
              {t('current_plan')}
            </Text>
          ) : (
            <Button
              className={styles['subscribe-btn']}
              onClick={regularSubscription}
            >
              {t('subscribe')}
            </Button>
          )}
          {!userData.data?.is_trial_plan_used &&
            plan.slug !== 'free' &&
            /** Disable trial plan for users who registered with coupon */
            !userData.data?.coupon && (
              <Button
                appearance={'simple'}
                className={styles.description}
                onClick={() => {
                  checkPlanForGa(plan, true);

                  /**
                   * Second param it is an action object with @grace_period ,
                   * for 7 day free.
                   * Param @needPatient for update examinations_left
                   */
                  // @ts-ignore
                  subscribeNewPlanModal(currentPlan, {
                    grace_period: true,
                    needPatients: true,
                  });
                }}
              >
                {t('try_for_free')}
              </Button>
            )}
        </>
      </div>
    );
  }
);

SecondaryCard.displayName = 'SecondaryCard';

export default SecondaryCard;
