import { USER_BACKUP, VERIFY } from './endpoints';

import { IVerifyUser } from 'types/userBackup';
import fetchData from 'utils/fetchData';

export const createUserBackup = async () =>
  fetchData({ method: 'post', to: USER_BACKUP.GET_DATA, data: {} });
export const getUserBackup = async () =>
  fetchData({ method: 'get', to: USER_BACKUP.GET_DATA, data: {} });
export const deleteUserBackup = async () =>
  fetchData({ method: 'delete', to: USER_BACKUP.GET_DATA, data: {} });
export const verifyUser = async (data: IVerifyUser) =>
  fetchData({ method: 'post', to: VERIFY, data: data });
