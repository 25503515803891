import { Dispatch, lazy, Suspense } from 'react';

import Pusher from 'pusher-js';
import { useNavigate } from 'react-router-dom';

import { IExamStore } from 'contexts/Examination';
import {
  getExaminationRequest,
  getExamMeasLayersError,
  getExamThicknessMapRequest,
  getGccETDRS,
  getLayerETDRS,
  getLayersETDRS,
  getLayersStatusRequest,
  getMainETDRS,
} from 'redux/actions/examination';
import { store } from 'redux/store';
import i18n from 'translation/i18n';
import {
  EColorToSeverity,
  ExamDataType,
  IMeasurementsPolygonScan,
  OneScanMaskTypes,
  ScanDataType,
  SegmentationTypes,
  StorageScans,
  TPriorityColor,
} from 'types/examination';
import { ACTIVE_SCANS_KEY, DEFAULT_ETDRS, ROUTES } from 'utils/constants';
import { getToken } from 'utils/helpers';
import notify from 'utils/toast';

export const generateLabel = (className: string): string => {
  const raw = className?.replaceAll('_', ' ');
  let result = raw.charAt(0).toUpperCase() + raw.slice(1);

  if (result.includes('Wet amd')) {
    result = result.replace('Wet amd', 'Wet AMD');
  }

  return result;
};

export const UpdatePlanModal = ({
  subtitle = 'To start analysis upgrade your plan',
}: {
  subtitle?: string;
}): JSX.Element => {
  const navigate = useNavigate();
  const BlurModal = lazy(() => import('components/BlurModal'));

  return (
    <Suspense fallback={''}>
      <BlurModal
        title={'Update your plan'}
        subtitle={subtitle}
        buttonText={'Upgrade my plan'}
        onClick={() => {
          navigate(ROUTES.PROFILE.SUBSCRIPTION);
        }}
      />
    </Suspense>
  );
};

/**
 * @param options
 * @param clean
 * @description Replace saved scans for tabs, helps us to understand what scan_id should be selected as initial inside tabs + PDF Report
 */
export const rememberScans = (options: StorageScans, clean = false) => {
  const oldScans = localStorage.getItem(ACTIVE_SCANS_KEY);
  let parsedScans = {};

  if (oldScans && !clean) {
    parsedScans = JSON.parse(oldScans);
  }

  const scans = {
    ...parsedScans,
    ...options,
  };

  localStorage.setItem(ACTIVE_SCANS_KEY, JSON.stringify(scans));
};

/**
 *
 * @param examData
 * @description If we have all data for correct examination view we have boolean true value
 */
export const checkExistedData = (examData: ExamDataType): boolean => {
  return examData?.eye !== null;
};

export const checkFormHandle = ({ values }: { values: any }) => {
  const errors: Record<string, string> = {};

  if (values.examination_date === '') {
    errors['examination_date'] = 'Required';
  }
  if (values.gender === '') {
    errors['gender'] = 'Required';
  }
  if (values.patient_name === '') {
    errors['patient_name'] = 'Required';
  }
  if (values.patient_surname === '') {
    errors['patient_surname'] = 'Required';
  }
  if (values.birth_date === '') {
    errors['birth_date'] = 'Required';
  }
  if (values.pid === '') {
    errors['pid'] = 'Required';
  }

  return errors;
};

type getSeverityStatResult = {
  [k in TPriorityColor]: number;
};
export const getSeverityStats = (
  options: ExamDataType
): getSeverityStatResult => {
  const result: getSeverityStatResult = {
    green: 0,
    yellow: 0,
    red: 0,
  };
  options.scans?.forEach((item) => {
    const color = EColorToSeverity[item.scan_severity] as TPriorityColor;
    result[color]++;
  });

  return result;
};

/**
 * @param item
 * @description Helps us to understand what color border should be for each scan
 */
export const getExamScanPriority = (
  item: ScanDataType | SegmentationTypes | null
): TPriorityColor | '' => {
  if (!item) return '';
  return EColorToSeverity[item.scan_severity] as TPriorityColor;
};

export const setupExamSocket = () => {
  const token = getToken()?.trim();

  const currentEnv: any = process.env;

  const pusher = new Pusher(currentEnv.REACT_APP_ECHO_KEY, {
    cluster: currentEnv.REACT_APP_ECHO_CLUSTER,
    forceTLS: true,
    authEndpoint: currentEnv.REACT_APP_ECHO_URL,
    auth: {
      headers: {
        Authorization: token,
      },
    },
  });

  window.Pusher = pusher;

  return pusher;
};

export const getCurrentExamETDRS = ({ exam_id }: { exam_id: string }) => {
  store.dispatch(
    getMainETDRS(
      { exam_id: exam_id },
      {
        success: () => {
          setTimeout(() => {
            store.dispatch(
              getLayerETDRS({ exam_id: exam_id, layers: DEFAULT_ETDRS.SINGLE })
            );
            store.dispatch(
              getLayersETDRS({
                exam_id: exam_id,
                layers: DEFAULT_ETDRS.RANGE,
              })
            );
            store.dispatch(
              getGccETDRS({
                exam_id: exam_id,
                layers: DEFAULT_ETDRS.GCC,
              })
            );
            store.dispatch(
              getExamThicknessMapRequest({
                exam_id: exam_id,
                layers: DEFAULT_ETDRS.GCC,
                type: 'gcc',
              })
            );
          }, 1000);
        },
      }
    )
  );
};

/**
 * @param scan
 * @return number
 * @description Return you number from 0 to 100 for using ColorProgress
 */
export const getScanScore = (scan: OneScanMaskTypes[]): number => {
  if (!scan) return 0;

  const score = 0;

  const getMaxScore = (array: OneScanMaskTypes[]) => {
    const scores = array.map((object) => {
      return object.score;
    });

    const maxScore = Math.max(...scores);

    return maxScore;
  };

  // 7+ score Red
  const redScore = scan?.filter((item) => item.score >= 7);

  if (redScore.length > 0) {
    const max = getMaxScore(redScore);

    return max * 10;
  }

  // 4-6 score Yellow
  const yellowScore = scan?.filter(
    (item) => item.score >= 4 && item.score <= 6
  );

  if (yellowScore.length > 0) {
    const max = getMaxScore(yellowScore);

    return max * 10;
  }

  // 1-3 score Green
  const greenScore = scan?.filter((item) => item.score >= 0 && item.score <= 3);

  if (greenScore.length > 0) {
    const max = getMaxScore(greenScore);

    return max * 10;
  }

  return score;
};

/**
 * @param array
 * @param key
 * @description Remove duplicates from Array of segmentation mask instances.
 */
export function removeDuplicatePolygons(
  array: OneScanMaskTypes[],
  key: string
): OneScanMaskTypes[] {
  const uniqueIds: OneScanMaskTypes[] = [];

  const unique = array.filter((element: any) => {
    const isDuplicate = uniqueIds.includes(element[key]);

    if (!isDuplicate) {
      uniqueIds.push(element[key]);

      return true;
    }

    return false;
  });

  return unique;
}

/**
 * @param number
 * @param numbersAfterPoint
 * @description Convert measurements mm to correct view. >0.00mm <100.00mm
 * @return string
 */
export function transformMmView(
  number: number,
  numbersAfterPoint?: number,
  lowest?: string
): string {
  let result = '';

  result = Number(number).toFixed(numbersAfterPoint ? numbersAfterPoint : 0);

  // If number lower than 0.0
  if (Number(result) === 0.0) {
    result = `<${lowest ? lowest : result}`;
  }
  // If number bigger than max expected number
  if (Number(result) >= 99) {
    result = `${result}`;
  }

  return result;
}

export const checkPolygonPosition = ({
  polygon,
  masks,
}: {
  polygon: IMeasurementsPolygonScan;
  masks: IMeasurementsPolygonScan[];
}): number | 'single' => {
  const cleanedPolygons = masks.filter(
    (item) => item.className === polygon.className
  );

  if (cleanedPolygons.length === 1) {
    return 'single';
  }

  const currentPosition = cleanedPolygons.findIndex((item) => item === polygon);

  return currentPosition + 1 || 0;
};

export const loadEtdrs = ({ exam_id }: { exam_id: string }) => {
  getCurrentExamETDRS({ exam_id });
  store.dispatch(getExamThicknessMapRequest({ exam_id }));
};

export const setupExamSocketListeners = ({
  exam_id,
  examStore,
  examDispatch,
}: {
  exam_id: string;
  examStore: IExamStore;
  examDispatch: Dispatch<IExamStore>;
}): void => {
  const pusher = window.Pusher;

  if (!pusher.subscribe) {
    const message = 'Pusher not initialized';

    console.error(message);
    throw Error(message);
  }

  const channel = `private-examination.${exam_id}`;
  const subscribe = pusher.subscribe(channel);

  pusher.connection.bind('connected', function () {
    console.info('Connected WS!');
  });

  subscribe.bind('getDefaultETDRS.finished', () => {
    notify(
      'success',
      i18n.t('notifications.examination_etdrs_calculation_finished', {
        id: exam_id,
      })
    );

    store.dispatch(
      getExaminationRequest(
        { exam_id },
        {
          success: (loadedData) => {
            if (loadedData.able_to_get_statistics) {
              loadEtdrs({ exam_id: loadedData.id });
            }
          },
        }
      )
    );

    subscribe?.disconnect();
  });

  subscribe.bind('getDefaultETDRS.failed', () => {
    notify(
      'error',
      i18n.t('notifications.examination_etdrs_calculation_failed', {
        id: exam_id,
      })
    );

    subscribe?.disconnect();
  });

  subscribe.bind('getSegmentationLayers.finished', () => {
    notify(
      'success',
      i18n.t('notifications.segmentation_layers_calculation_finished', {
        id: exam_id,
      })
    );

    store.dispatch(
      getLayersStatusRequest(
        { exam_id },
        {
          success: () => {
            examDispatch({
              ...examStore,
              layers: {
                status: 'finished',
              },
            });
          },
        }
      )
    );
    subscribe?.disconnect();
  });

  subscribe.bind('getSegmentationLayers.failed', () => {
    notify(
      'error',
      i18n.t('notifications.segmentation_layers_calculation_failed', {
        id: exam_id,
      })
    );

    examDispatch({
      ...examStore,
      layers: {
        status: 'failed',
      },
    });
    subscribe?.disconnect();
  });

  subscribe.bind('getMeasurements.finished', () => {
    notify(
      'success',
      i18n.t('notifications.measurements_calculation_finished', {
        id: exam_id,
      })
    );
    store.dispatch(getExaminationRequest({ exam_id }));
    subscribe?.disconnect();
  });

  subscribe.bind('getMeasurements.failed', () => {
    notify(
      'error',
      i18n.t('notifications.measurements_calculation_failed', {
        id: exam_id,
      })
    );
    store.dispatch(
      getExamMeasLayersError({
        slug: 'get_measurements_failed',
        message: 'Get measurements failed',
      })
    );
    subscribe?.disconnect();
  });

  pusher.connection.bind('disconnected', function () {
    console.info('Disconnected WS!');
  });
};

export const getActiveExamScan = ({
  exam_data,
}: {
  exam_data: ExamDataType;
}): {
  activeSlide: ScanDataType;
  activeIndex: number;
} => {
  // Default | Middle scan index
  let activeIndex = Number((exam_data.scans?.length / 2).toFixed(0)) - 1;

  const storedScans = localStorage.getItem(ACTIVE_SCANS_KEY);

  if (storedScans) {
    const parsedScans: StorageScans = JSON.parse(storedScans);

    // If we found saved scan from store, show it
    const found = exam_data.scans?.findIndex(
      (item: ScanDataType) => item.scan_id === parsedScans?.current?.scan_id
    );

    if (found !== -1) {
      activeIndex = found;
    }
  }

  const activeSlide = exam_data.scans?.[activeIndex];

  return { activeSlide, activeIndex };
};
