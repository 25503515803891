import { memo, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import UserPanel from './UserPanel';

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import DefaultAvatarIcon from 'assets/icons/avatar.svg';
import LanguageDropdown from 'components/LanguageDropdown';
import Text from 'components/Text';
import useDynamicViewportSize from 'hooks/useDynamicViewportSize';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { userProfileSelector } from 'redux/selectors/userProfile';

import 'styles/animation.scss';

import styles from './UserPopUp.module.scss';

const UserPopUp = memo(() => {
  const userData = useSelector(userProfileSelector);
  const [open, setOpen] = useState(false);
  const userProfilePopUp = useRef();

  const { vw } = useDynamicViewportSize();

  const popUpHandler = () => {
    setOpen(!open);

    if (!open && vw <= 576) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  };

  const closeHandler = () => {
    setOpen(false);
    document.body.style.overflow = '';
  };

  useOnClickOutside(userProfilePopUp, closeHandler);

  useEffect(() => {
    if (vw <= 576 && open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [vw]);

  return vw <= 1023 ? (
    <LanguageDropdown small className={styles.select} />
  ) : (
    <div ref={userProfilePopUp}>
      <div className={styles.profile} onClick={popUpHandler}>
        <div className={styles.avatar}>
          <img
            src={userData.data?.avatar || DefaultAvatarIcon}
            alt='user avatar'
            title='user avatar'
            height={48}
            width={48}
          />
        </div>
        <Text className={styles.title}>
          {userData.data?.name
            ? `${userData.data.name} ${userData.data.surname}`
            : ''}
        </Text>
        <ArrowLeftIcon
          className={cn(styles.arrow, {
            [styles.opened]: open,
          })}
        />
      </div>
      <CSSTransition in={open} timeout={300} classNames={'alert'} unmountOnExit>
        <UserPanel
          closeHandler={closeHandler}
          profileData={userData}
          isOpen={open}
        />
      </CSSTransition>
    </div>
  );
});

UserPopUp.displayName = 'UserPopUp';

export default UserPopUp;
