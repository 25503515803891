export enum userProfileConstants {
  GET_PROFILE_REQUEST = 'USER_GET_PROFILE_REQUEST',
  GET_PROFILE_SUCCESS = 'USER_GET_PROFILE_SUCCESS',
  GET_PROFILE_ERROR = 'USER_GET_PROFILE_ERROR',

  UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_ERROR = 'USER_UPDATE_PROFILE_ERROR',

  UPDATE_USER_GET_CODE_FOR_PASSWORD_REQUEST = 'UPDATE_USER_GET_CODE_FOR_PASSWORD_REQUEST',
  UPDATE_USER_GET_CODE_FOR_PASSWORD_SUCCESS = 'UPDATE_USER_GET_CODE_FOR_PASSWORD_SUCCESS',
  UPDATE_USER_GET_CODE_FOR_PASSWORD_ERROR = 'UPDATE_USER_GET_CODE_FOR_PASSWORD_ERROR',
  UPDATE_USER_GET_CODE_FOR_PASSWORD_RESET = 'UPDATE_USER_GET_CODE_FOR_PASSWORD_RESET',

  UPDATE_USER_PASSWORD_REQUEST = 'UPDATE_USER_PASSWORD_REQUEST',
  UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS',
  UPDATE_USER_PASSWORD_ERROR = 'UPDATE_USER_PASSWORD_ERROR',

  UPDATE_REPORT_SETTINGS_REQUEST = 'UPDATE_REPORT_SETTINGS_REQUEST',
  UPDATE_REPORT_SETTINGS_SUCCESS = 'UPDATE_REPORT_SETTINGS_SUCCESS',
  UPDATE_REPORT_SETTINGS_ERROR = 'UPDATE_REPORT_SETTINGS_ERROR',
}
