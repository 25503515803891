import { helperShowStep } from 'services/onboardingTour/tourOptions';
import i18n from 'translation/i18n';

const STEP_ID = 'comparison-examination-step-1';

export const stepOne = {
  id: STEP_ID,
  attachTo: {
    element: '.comparison',
    on: 'top',
  },
  advanceOn: { selector: '.comparison', event: 'click' },
  beforeShowPromise: () =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        resolve();
      }, 500);
    }),
  buttons: [],
  scrollTo: false,
  title: i18n.t('onboarding.show_examination.tab_menu'),
  text: [
    `${i18n.t(
      'onboarding.show_examination.comparison_text_1'
    )} <span class='highlight'>${i18n.t(
      'onboarding.show_examination.comparison_text_2'
    )}</span>
    <p class="next-step">${i18n.t(
      'onboarding.show_examination.comparison_text_3'
    )}</p>`,
  ],
  when: {
    show: () => {
      helperShowStep(STEP_ID);
    },
  },
};
