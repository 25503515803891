import { ElementRef, memo, useLayoutEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg';
import { ReactComponent as DotsIcon } from 'assets/icons/dots.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import Button from 'components/Button';
import { ExaminationList } from 'components/ExaminationList';
import SeverityLevel from 'components/SeverityLevel';
import Text from 'components/Text';
import { useDashboardContext } from 'contexts/Dashboard';
import { useIsDemo } from 'hooks/useIsDemo';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { useSettings } from 'hooks/useSettings';
import { IPatient } from 'types/patients';
import { SettingsEnum } from 'types/settings';
import { DateFormatter } from 'utils/DateFormatter.class';
import { deletePatientModal, updatePatientModal } from 'utils/openModals';

import styles from './PatientItem.module.scss';

interface PatientItemProps {
  patient: IPatient;
  isActive: boolean;
}

const PatientItem = memo(({ patient, isActive }: PatientItemProps) => {
  const {
    id,
    pid,
    name,
    surname,
    birth_date,
    gender,
    last_examination_date,
    severity_level = '',
    description,
    examinations,
  } = patient;
  const { setActivePatient, setSelectedExam } = useDashboardContext();

  const [open, setOpen] = useState(false);
  const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
  const [isExaminationModalOpen, setIsExaminationModalOpen] = useState(false);

  const contextMenuExamination = useRef<ElementRef<'div'>>(null);
  const descriptionRef = useRef<ElementRef<'p'>>(null);

  const isDemo = useIsDemo();

  const { t } = useTranslation(undefined, {
    keyPrefix: 'dashboard.patient_list.item',
  });

  const timeFormat = useSettings<string>(SettingsEnum.TIME_FORMAT);

  useOnClickOutside(contextMenuExamination, () => setOpen(false));
  useOnClickOutside(descriptionRef, () => setIsDescriptionModalOpen(false));

  useLayoutEffect(
    () => () => {
      document.body.style.overflow = '';
    },
    []
  );

  return (
    <div
      className={cn(styles.item, {
        [styles.active]: isActive,
      })}
      ref={contextMenuExamination}
      onClick={() => {
        setSelectedExam(examinations ?? []);
        setActivePatient(patient);
      }}
      /** This data att need for scroll to active patient */
      data-patient-id={id}
    >
      {/*
       ** Edit options for patient can be undefined.
       ** If it is undefined, we set "edit" text with event
       ** handler for open edit modal window,
       ** where we can update patient
       */}
      <div className={styles['title-wrapper']}>
        <Text className={styles['title-label']}>{t('patient_id')}</Text>

        <Text color='dark' className={styles.title}>
          {pid ? (
            pid
          ) : (
            <Text
              tag={'span'}
              className={styles.error}
              onClick={(e) => {
                e.stopPropagation();
                updatePatientModal(id, patient);
              }}
            >
              {t('edit')}...
            </Text>
          )}
        </Text>
      </div>

      <div className={styles['title-wrapper']}>
        <Text className={styles['title-label']}>{t('patient_name')}</Text>

        <Text color='dark' className={styles.title}>
          {name && surname ? (
            `${name} ${surname}`.trim()
          ) : (
            <Text
              tag={'span'}
              className={styles.error}
              onClick={(e) => {
                e.stopPropagation();
                updatePatientModal(id, patient);
              }}
            >
              {t('edit')}...
            </Text>
          )}
        </Text>
      </div>

      <div className={styles['title-wrapper']}>
        <Text className={styles['title-label']}>{t('birth_date')}</Text>

        <Text color='dark' className={styles.title}>
          {birth_date ? (
            DateFormatter.getDate(timeFormat, birth_date)
          ) : (
            <Text
              tag={'span'}
              className={styles.error}
              onClick={(e) => {
                e.stopPropagation();
                updatePatientModal(id, patient);
              }}
            >
              {t('edit')}...
            </Text>
          )}
        </Text>
      </div>

      <div className={styles['title-wrapper']}>
        <Text className={styles['title-label']}>{t('gender')}</Text>

        <Text color='dark' className={styles.title}>
          {gender ? (
            gender
          ) : (
            <Text
              tag={'span'}
              className={styles.error}
              onClick={(e) => {
                e.stopPropagation();
                updatePatientModal(id, patient);
              }}
            >
              {t('edit')}...
            </Text>
          )}
        </Text>
      </div>

      <div className={styles['title-wrapper']}>
        <Text className={styles['title-label']}>{t('last_upload')}</Text>

        <Text color='dark' className={styles.title}>
          {DateFormatter.getDate(
            timeFormat,
            last_examination_date ?? undefined
          )}
        </Text>
      </div>

      <div className={cn(styles['title-wrapper'], styles.severity)}>
        <Text className={styles['title-label']}>{t('severity_level')}</Text>

        {severity_level && (
          <SeverityLevel level={severity_level} type='patient' />
        )}
      </div>

      <div
        className={cn(styles['title-wrapper'], styles.comment, {
          [styles['open-description-modal']]: isDescriptionModalOpen,
        })}
      >
        <Text className={styles['title-label']}>{t('description')}</Text>

        <Text
          color='dark'
          onClick={() => {
            setIsDescriptionModalOpen(true);
          }}
          className={styles.short}
        >
          {description}
        </Text>

        <div className={styles.full}>
          <div>
            <Text
              color='dark'
              className={styles['full-text']}
              elementRef={descriptionRef}
            >
              {description}
            </Text>

            <CancelIcon />
          </div>
        </div>
      </div>

      {isDemo ? (
        <>
          <Button
            size={'small'}
            className={styles['show-examinations-button']}
            appearance={'ghost'}
            onClick={() => {
              setIsExaminationModalOpen(true);
              document.body.style.overflow = 'hidden';
              // @ts-ignore
              window.scroll({ top: 0, behavior: 'instant' });
            }}
          >
            {t('show_examinations')}
          </Button>

          <div
            className={cn(styles['patient-examinations'], {
              [styles.active]: isExaminationModalOpen,
            })}
          >
            <button
              className={styles.back}
              onClick={() => {
                setIsExaminationModalOpen(false);
                document.body.style.overflow = '';
              }}
            >
              <ArrowLeftIcon />

              <Text>{t('back')}</Text>
            </button>

            <div>
              <ExaminationList examinations={examinations ?? []} />
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={styles.dots}
            onClick={(e) => {
              e.stopPropagation();
              setOpen((state) => !state);
            }}
          >
            <DotsIcon />
          </div>

          <CSSTransition
            in={open}
            timeout={300}
            classNames={'alert'}
            unmountOnExit
          >
            <div className={styles.submenu}>
              <ul className={styles.list}>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    updatePatientModal(id, patient);
                  }}
                >
                  <EditIcon /> <span>{t('edit')}</span>
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    deletePatientModal(id, pid);
                  }}
                >
                  <TrashIcon /> <span>{t('delete')}</span>
                </li>
              </ul>
            </div>
          </CSSTransition>
        </>
      )}
    </div>
  );
});

PatientItem.displayName = 'PatientItem';

export default PatientItem;
