import styles from './SwitchBox.module.scss';

const SwitchBox = ({ name, value, id, onChange, onBlur, checked }) => {
  return (
    <>
      <input
        type='checkbox'
        hidden='hidden'
        id={id}
        onChange={onChange}
        name={name}
        onBlur={onBlur}
        checked={checked ? checked : value}
      />
      <label className={styles.switch} htmlFor={id} />
    </>
  );
};

export default SwitchBox;
