/* eslint-disable react/display-name */
import { ReactNode, useMemo } from 'react';

import { useSettings } from './useSettings';

import { SettingsEnum } from 'types/settings';
import { TIME_FORMAT } from 'utils/DateFormatter.class';

interface DateSelectPickerProps<T> {
  dayField: (props: T) => ReactNode;
  monthField: (props: T) => ReactNode;
  yearField: (props: T) => ReactNode;
}

export const useDateSelectPicker = <T,>(
  { dayField, monthField, yearField }: DateSelectPickerProps<T>,
  deps: readonly unknown[]
) => {
  const timeFormat = useSettings<TIME_FORMAT>(SettingsEnum.TIME_FORMAT);

  return useMemo(() => {
    switch (timeFormat) {
      case TIME_FORMAT.DD_MM_YYYY:
        return (props: T) => (
          <>
            {dayField(props)}
            {monthField(props)}
            {yearField(props)}
          </>
        );
      case TIME_FORMAT.MM_DD_YYYY:
        return (props: T) => (
          <>
            {monthField(props)}
            {dayField(props)}
            {yearField(props)}
          </>
        );

      case TIME_FORMAT.YYYY_MM_DD:
        return (props: T) => (
          <>
            {yearField(props)}
            {monthField(props)}
            {dayField(props)}
          </>
        );

      default:
        return (props: T) => (
          <>
            {dayField(props)}
            {monthField(props)}
            {yearField(props)}
          </>
        );
    }
  }, [...deps, timeFormat]);
};

export const dayOptions = (
  month?: string, // '01' | '02' etc.
  year?: string, // '2021' | '2022' etc.
  currentDate = new Date() // Default to current date
): { label: string; value: string }[] => {
  const passedDate = new Date(Number(year), Number(month), 0);

  const isCurrentMonth =
    currentDate.getFullYear() === passedDate.getFullYear() &&
    currentDate.getMonth() === passedDate.getMonth();

  return Array.from(
    {
      length: isCurrentMonth ? currentDate.getDate() : passedDate.getDate(),
    },
    (_, index) => {
      const dayNumber = index + 1;
      const value = String(dayNumber).padStart(2, '0');

      return { label: value, value };
    }
  );
};

export const monthOptions = (
  year?: string, // '2021' | '2022' etc.
  currentDate = new Date() // Default to current date
) => {
  const passedDate = new Date(Number(year), 0);

  const isCurrentYear = currentDate.getFullYear() === passedDate.getFullYear();

  return Array.from(
    {
      length: isCurrentYear ? currentDate.getMonth() + 1 : 12,
    },
    (_, index) => {
      const monthNumber = index + 1;
      const value = String(monthNumber).padStart(2, '0');

      return { label: value, value };
    }
  );
};

export const yearOptions = (yearCount = 90) => {
  const now = new Date().getFullYear();

  const years = Array(now - (now - yearCount))
    .fill('')
    .map((v, idx) => now - idx);

  return years.map((item) => {
    return { label: item.toString(), value: item.toString() };
  });
};
