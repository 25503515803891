/**
 * @description This hook is used to get the settings data from the redux store.
 * Only for staging environment. Switcher is in the /edit-profile/settings page.
 * @param {SettingsEnum} slugOption - Slug of the option to get the status.
 * @returns {boolean} - Returns the status of the option.
 */

import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { selectSettings } from 'redux/selectors/settings';
import { ISettingsData, SettingsEnum } from 'types/settings';

export const useSettings = <T extends ISettingsData['status']>(
  slugOption: SettingsEnum
) => {
  const { data: settingsData } = useSelector(selectSettings);

  return useMemo(
    () => settingsData.find((item) => item.slug === slugOption)?.status,
    [settingsData]
  ) as T;
};
