import { userConstants } from 'redux/constants/userAuth';

export const userLoginRequest = (data, action) => ({
  type: userConstants.LOGIN_REQUEST,
  payload: data,
  action: action,
});

export const userLoginSuccess = () => ({
  type: userConstants.LOGIN_SUCCESS,
});

export const userLoginError = (error) => ({
  type: userConstants.LOGIN_ERROR,
  payload: error,
});

export const checkUserEmailRequest = (data, action) => ({
  type: userConstants.CHECK_EMAIL_REQUEST,
  payload: data,

  // the action parameter {} is needed to change the form
  // step after confirming the account registration, we
  // works with them in the saga
  action: action,
});

export const checkUserEmailSuccess = () => ({
  type: userConstants.CHECK_EMAIL_SUCCESS,
});

export const checkUserEmailError = () => ({
  type: userConstants.CHECK_EMAIL_ERROR,
});

export const userLogoutRequest = () => ({
  type: userConstants.LOGOUT_REQUEST,
});

export const userLogoutSuccess = () => ({
  type: userConstants.LOGOUT_SUCCESS,
});

export const userLogoutError = (error) => ({
  type: userConstants.LOGOUT_ERROR,
  payload: error,
});

export const resetApp = () => ({
  type: userConstants.RESET_APP,
});

export const userRegisterRequest = (data, action) => ({
  type: userConstants.REGISTER_REQUEST,
  payload: data,

  // the action parameter {} is needed to change the form
  // step after confirming the account registration, we
  // works with them in the saga
  action: action,
});

export const userRegisterSuccess = () => ({
  type: userConstants.REGISTER_SUCCESS,
});

export const userRegisterError = (error) => ({
  type: userConstants.REGISTER_ERROR,
  payload: error,
});

export const userAuthByGoogleRequest = (data, action) => ({
  type: userConstants.GOOGLE_LOGIN_REQUEST,
  payload: data,

  // the action parameter {} is needed to change the form
  // step after confirming the account registration, we
  // works with them in the saga
  action: action,
});

export const userAuthByGoogleSuccess = () => ({
  type: userConstants.GOOGLE_LOGIN_SUCCESS,
});

export const userAuthByGoogleError = (error) => ({
  type: userConstants.GOOGLE_LOGIN_ERROR,
  payload: error,
});

export const forgotPasswordForEmailRequest = (data, action) => ({
  type: userConstants.FORGOT_PASSWORD_REQUEST,
  payload: data,

  // the action parameter {} is needed to change the form
  // step after confirming the account registration, we
  // works with them in the saga
  action: action,
});

export const forgotPasswordForEmailSuccess = () => ({
  type: userConstants.FORGOT_PASSWORD_SUCCESS,
});

export const forgotPasswordForEmailError = (error) => ({
  type: userConstants.FORGOT_PASSWORD_ERROR,
  data: error,
});

export const resetPasswordForEmailRequest = (data, action) => ({
  type: userConstants.RESET_PASSWORD_REQUEST,
  payload: data,

  // the action parameter {} is needed to change the form
  // step after confirming the account registration, we
  // works with them in the saga
  action: action,
});

export const resetPasswordForEmailSuccess = () => ({
  type: userConstants.RESET_PASSWORD_SUCCESS,
});

export const resetPasswordForEmailError = (error) => ({
  type: userConstants.RESET_PASSWORD_ERROR,
  payload: error,
});

export const registerInvitedTeamMemberRequest = (data, action) => ({
  type: userConstants.REGISTER_INVITED_TEAM_MEMBER_REQUEST,
  payload: data,
  action: action,
});

export const registerInvitedTeamMemberSuccess = () => ({
  type: userConstants.REGISTER_INVITED_TEAM_MEMBER_SUCCESS,
});

export const registerInvitedTeamMemberError = (error) => ({
  type: userConstants.REGISTER_INVITED_TEAM_MEMBER_ERROR,
  payload: error,
});

export const updateTeamMemberRequest = (data, action) => ({
  type: userConstants.UPDATE_INVITED_TEAM_MEMBER_REQUEST,
  payload: data,
  action: action,
});

export const updateTeamMemberSuccess = () => ({
  type: userConstants.UPDATE_INVITED_TEAM_MEMBER_SUCCESS,
});

export const updateTeamMemberError = (error) => ({
  type: userConstants.UPDATE_INVITED_TEAM_MEMBER_ERROR,
  payload: error,
});

export const sendOcuPlanFormRequest = (data, action) => ({
  type: userConstants.SEND_OCUPLAN_FORM_REQUEST,
  payload: data,
  action: action,
});

export const sendOcuPlanFormSuccess = () => ({
  type: userConstants.SEND_OCUPLAN_FORM_SUCCESS,
});

export const sendOcuPlanFormError = (error) => ({
  type: userConstants.SEND_OCUPLAN_FORM_ERROR,
  payload: error,
});
