import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export let SentryService: typeof Sentry | undefined = undefined;

export function initSentryService() {
  Sentry.init({
    environment: process.env.REACT_APP_ENV,
    dsn: 'https://0f829cf923354b5c81682621ccfc68c5@o4503942733627392.ingest.sentry.io/4503942739066880',
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  });

  SentryService = Sentry;
}
