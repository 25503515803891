import { userProfileConstants } from 'redux/constants/userProfile';
import { IUserProfilesState, userProfileActions } from 'types/editProfile';
import { SUBSCRIPTION_STATUS } from 'utils/constants';

const initialState: IUserProfilesState = {
  requesting: false,
  errors: null,
  data: {
    report_settings: {
      company_email: '',
      company_name: '',
      logo: '',
    },
    plan: {
      current_period_end: 640641600000,
      current_period_start: 640641600000,
      ends_at: 640641600000,
      id: 0,
      is_legacy: false,
      name: '',
      recurring: '',
      services: {},
      amount: '',
      slug: '',
      subscription_status: SUBSCRIPTION_STATUS.ACTIVE,
      classification_items: [],
      segmentation_classes: [],
    },
    coupon: {
      id: '',
      stripe_id: '',
      amount_off: null,
      currency: null,
      duration: '',
      duration_in_months: null,
      max_redemptions: null,
      name: '',
      percent_off: null,
      redeem_by: '',
      times_redeemed: '',
      valid: false,
      applies_to: {
        identifier: '',
        stripe_id: '',
      },
      discount_start: '',
      discount_end: '',
    },
    severity_level: null,
    source_type: null,
    role: {
      status: undefined,
      model: undefined,
    },
    upcoming_plan: {
      event_at: 0,
      id: 0,
      name: '',
      recurring: '',
      slug: '',
    },
    access_level: 'User',
    avatar: '',
    institution_website: '',
    country_id: 0,
    email: '',
    examinations_left: 0,
    id: 0,
    invitations_left: 0,
    is_able_to_invite_users: false,
    is_able_to_make_examinations: false,
    is_able_to_make_segmentations: false,
    is_invited: false,
    is_trial_plan_used: false,
    name: '',
    profession_id: 0,
    segmentations_left: 0,
    surname: '',
  },
  codeRequesting: false,
};

const userProfileReducer = (
  state = initialState,
  action: userProfileActions
): IUserProfilesState => {
  switch (action.type) {
    // GET PROFILE INFO
    case userProfileConstants.GET_PROFILE_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case userProfileConstants.GET_PROFILE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        requesting: false,
        errors: null,
      };
    case userProfileConstants.GET_PROFILE_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };

    //UPDATE PROFILE INFO
    case userProfileConstants.UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case userProfileConstants.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        requesting: false,
        errors: null,
      };
    case userProfileConstants.UPDATE_PROFILE_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };

    // GET CODE FOR UPDATE USER PASSWORD
    case userProfileConstants.UPDATE_USER_GET_CODE_FOR_PASSWORD_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case userProfileConstants.UPDATE_USER_GET_CODE_FOR_PASSWORD_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
        codeRequesting: true,
      };
    case userProfileConstants.UPDATE_USER_GET_CODE_FOR_PASSWORD_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
        codeRequesting: false,
      };
    case userProfileConstants.UPDATE_USER_GET_CODE_FOR_PASSWORD_RESET:
      return {
        ...state,
        codeRequesting: false,
        errors: null,
      };

    //UPDATE USER PASSWORD
    case userProfileConstants.UPDATE_USER_PASSWORD_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case userProfileConstants.UPDATE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
        codeRequesting: false,
      };
    case userProfileConstants.UPDATE_USER_PASSWORD_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };

    //UPDATE REPORT SETTINGS
    case userProfileConstants.UPDATE_REPORT_SETTINGS_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case userProfileConstants.UPDATE_REPORT_SETTINGS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        requesting: false,
        errors: null,
      };
    case userProfileConstants.UPDATE_REPORT_SETTINGS_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default userProfileReducer;
