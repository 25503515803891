import { t } from 'i18next';
import { fork, put, select, takeLatest } from 'redux-saga/effects';

import {
  getGccETDRS,
  getLayerETDRS,
  getMainETDRS,
  getThicknessMap,
} from 'api/examination';
import {
  updateComparisonDataError,
  updateComparisonDataSuccess,
} from 'redux/actions/examination';
import { ExamActionTypes } from 'redux/constants/examination';
import { getExamData } from 'redux/selectors/examination/getExamData/getExamData';
import { IWorkerProps } from 'redux/types/examination';
import { ExtendedExamDataType, IHeatMapProps } from 'types/examination';
import { DEFAULT_ETDRS } from 'utils/constants';
import notify from 'utils/toast';

function* updateComparisonWatcher() {
  yield takeLatest(
    ExamActionTypes.UPDATE_COMPARISON_DATA_REQUEST,
    updateComparisonWorker
  );
}

interface IUpdateComparisonWorker extends IWorkerProps {
  type: ExamActionTypes;
  payload: {
    exam_id: string;
    layers?: IHeatMapProps;
  };
}

function* updateComparisonWorker({ payload, action }: IUpdateComparisonWorker) {
  action?.default?.();

  try {
    const { exam_id, layers } = payload;

    // If ETDRS changed we check what target was changed and rewrite data in state
    if (layers) {
      let data;
      let gccHeatmap;

      if (layers.single) {
        const { data: singleData } = yield getLayerETDRS(
          exam_id,
          JSON.stringify(layers.single)
        );
        data = { single: { layers: layers.single, data: singleData.data[0] } };
      }

      if (layers.range) {
        const { data: rangeData } = yield getLayerETDRS(
          exam_id,
          JSON.stringify(layers.range)
        );

        data = { range: { layers: layers.range, data: rangeData.data[0] } };
      }

      if (layers.gcc) {
        const { data: gccData } = yield getGccETDRS(
          exam_id,
          JSON.stringify(layers.gcc)
        );
        const { data: heatmapData } = yield getThicknessMap(
          exam_id,
          JSON.stringify(layers.gcc)
        );

        data = { gcc: { layers: layers.gcc, data: gccData.data[0] } };
        gccHeatmap = heatmapData;
      }

      const { compareComparison } = yield select(getExamData);

      const updatedIndex = compareComparison.findIndex(
        (item: ExtendedExamDataType) => item.id === exam_id
      );

      const result = [...compareComparison];

      result[updatedIndex] = {
        ...compareComparison[updatedIndex],
        etdrs: {
          ...compareComparison[updatedIndex].etdrs,
          ...data,
        },
        heatmap: {
          ...compareComparison[updatedIndex].heatmap,
          gcc: gccHeatmap
            ? gccHeatmap.data
            : compareComparison[updatedIndex].heatmap.gcc,
        },
      };

      yield put(updateComparisonDataSuccess(result));
    } else {
      const { data: mainData } = yield getMainETDRS(exam_id);

      const { data: mainHeatmapData } = yield getThicknessMap(exam_id);

      const { data: singleData } = yield getLayerETDRS(
        exam_id,
        JSON.stringify(DEFAULT_ETDRS.SINGLE)
      );
      const { data: rangeData } = yield getLayerETDRS(
        exam_id,
        JSON.stringify(DEFAULT_ETDRS.RANGE)
      );
      const { data: gccData } = yield getGccETDRS(
        exam_id,
        JSON.stringify(DEFAULT_ETDRS.GCC)
      );
      const { data: heatmapData } = yield getThicknessMap(
        exam_id,
        JSON.stringify(DEFAULT_ETDRS.GCC)
      );

      if (
        !mainHeatmapData.data ||
        !mainData.data[0] ||
        !singleData.data[0] ||
        !rangeData.data[0] ||
        !gccData.data[0] ||
        !heatmapData.data
      )
        return;

      const { compareComparison } = yield select(getExamData);
      if (compareComparison.status === 'Error') {
        throw new Error(compareComparison.message);
      }

      const updatedIndex = compareComparison.findIndex(
        (item: ExtendedExamDataType) => item.id === exam_id
      );

      const result = [...compareComparison];

      result[updatedIndex] = {
        ...compareComparison[updatedIndex],
        etdrs: {
          main: { data: mainData.data[0] },
          single: { layers: DEFAULT_ETDRS.SINGLE, data: singleData.data[0] },
          range: { layers: DEFAULT_ETDRS.RANGE, data: rangeData.data[0] },
          gcc: { layers: DEFAULT_ETDRS.GCC, data: gccData.data[0] },
        },
        heatmap: {
          main: mainHeatmapData?.data,
          gcc: heatmapData.data,
        },
      };

      yield put(updateComparisonDataSuccess(result));
    }

    action?.success?.();
  } catch (error: any) {
    action?.error?.();
    console.error(error, 'err');
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    notify('error', errorMessage);
    yield put(updateComparisonDataError());
  }
}

export default function* updateComparisonSaga() {
  yield fork(updateComparisonWatcher);
}
