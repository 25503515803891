import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { getCountries, getOctProducers, getProfessions } from 'api/auth';
import {
  getProfessionsCountriesAndProducersError,
  getProfessionsCountriesAndProducersSuccess,
} from 'redux/actions/professionsCountriesAndProducers';
import { professionsAndCountriesTypes } from 'redux/constants/professionsAndCountries';
import notify from 'utils/toast';

function* getProfessionsAndCountriesWatcher() {
  yield takeEvery(
    professionsAndCountriesTypes.GET_PROFESSIONS_COUNTRIES_AND_PRODUCERS_REQUEST,
    getProfessionsAndCountriesWorker
  );
}

function* getProfessionsAndCountriesWorker() {
  try {
    const { data: professions } = yield getProfessions();
    const { data: countries } = yield getCountries();
    const { data: producers } = yield getOctProducers();
    if (professions.status === 'Error') {
      throw new Error(professions.message);
    }
    if (countries.status === 'Error') {
      throw new Error(countries.message);
    }
    if (producers.status === 'Error') {
      throw new Error(producers.message);
    }

    yield put(
      getProfessionsCountriesAndProducersSuccess({
        professions: professions.data,
        countries: countries.data,
        producers: producers.data,
      })
    );
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    yield put(getProfessionsCountriesAndProducersError(error));
    yield notify('error', errorMessage);
  }
}

export default function* getProfessionAndCountrySaga() {
  yield fork(getProfessionsAndCountriesWatcher);
}
