import { SETTINGS } from './endpoints';

import { ISettingsSaveData } from 'types/settings';
import fetchData from 'utils/fetchData';

export const getSettings = async () =>
  fetchData({
    method: 'get',
    to: SETTINGS.GET_SETTINGS,
  });
export const saveSettings = async (data: ISettingsSaveData) =>
  fetchData({
    method: 'put',
    to: SETTINGS.SAVE_SETTINGS,
    data: { platform_settings: data },
  });
