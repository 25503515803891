import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { getSubscription, getSubscriptionServices } from 'api/subscription';
import { offGlobalLoader, onGlobalLoader } from 'redux/actions/appSettings';
import {
  getSubscriptionPlanError,
  getSubscriptionPlanSuccess,
  getSubscriptionServicesSuccess,
} from 'redux/actions/subscription';
import { subscriptionConstants } from 'redux/constants/subscription';
import notify from 'utils/toast';

function* getSubscriptionPlansWatcher() {
  yield takeEvery(
    subscriptionConstants.GET_SUBSCRIPTION_PLAN_REQUEST,
    getSubscriptionPlansWorker
  );
}

export function* getSubscriptionPlansWorker() {
  try {
    /**
     * !REFACTOR!!!! TEMPORARY SOLUTION
     * Global loader we use only for subscription page /edit-profile/subscription
     * because we have conflict with loader on the subscriptionReduce.requesting
     * UI changes when we try to subscribe to a plan
     */
    yield put(onGlobalLoader());
    const { data: subscription } = yield getSubscription();

    //!REFACTOR!!!! TEMPORARY SOLUTION
    const { data: subscriptionServices } = yield getSubscriptionServices();

    if (subscription.status === 'Error') {
      throw new Error(subscription.message);
    }
    if (subscriptionServices.status === 'Error') {
      throw new Error(subscriptionServices.message);
    }

    //!REFACTOR!!!! TEMPORARY SOLUTION
    yield put(getSubscriptionServicesSuccess(subscriptionServices.data));
    /**
     * * !IMPORTANT!
     * Delete first element from array because it's a FREE plan
     * and we use it ONLY detect if user has a subscription or not
     * and we don't need to show it in the list of subscription plans
     * On the platform we have only 2 plans: STANDARD, PRO
     */
    yield put(getSubscriptionPlanSuccess(subscription.data.slice(1)));
    yield put(offGlobalLoader());
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    yield put(getSubscriptionPlanError(error));
    yield notify('error', errorMessage);
  }
}

export default function* getSubscriptionPlansSaga() {
  yield fork(getSubscriptionPlansWatcher);
}
