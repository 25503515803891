import getPaymentStatistic from './getPaymentStatistic';
import getSubscriptionDetails from './getSubscriptionDetails';
import getSubscriptionPlans from './getSubscriptionPlans';
import getSubscriptionServices from './getSubscriptionServices';
import subscribePlan from './subscribePlan';
import unsubscribePlan from './unsubscribePlan';

export default [
  getSubscriptionPlans,
  getSubscriptionDetails,
  subscribePlan,
  unsubscribePlan,
  getSubscriptionServices,
  getPaymentStatistic,
];
