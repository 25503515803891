import React from 'react';

// import cn from 'classnames';
import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import Button from 'components/Button';
import ModalTemplate, { TModalProps } from 'ui/Modal';

import s from './TrialModal.module.scss';

interface Props extends TModalProps {
  date: string;
}

/**
 *  TrialModal
 *  @param className
 *  Created by westprophet on 08.07.2024
 */

export default function TrialModal({ date, ...p }: Props) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'components.modal.trial_period_end',
  });

  const mailto =
    "mailto:mbraddon@altris.ai,sales@altris.ai?subject=Altris AI - Trial has expired&body=Hi Altris Sales team! My Trial has expired. Let me know when we'll be able to discuss further steps!";
  return (
    <ModalTemplate.Wrapper {...p} className={s.TrialModal}>
      <ModalTemplate.Window>
        <ModalTemplate.Header>
          <h2 className={cn(s.title)}>{t('title')}</h2>
        </ModalTemplate.Header>
        <ModalTemplate.Content className={cn(s.content)}>
          <Trans
            t={t}
            defaults={t('subtitle', { date })}
            components={[<b key='b' />, <a key='mailto' href={mailto} />]}
          />
        </ModalTemplate.Content>
        <ModalTemplate.Footer className={cn(s.footer)}>
          <Button onClick={p.onClose}>{t('button')}</Button>
        </ModalTemplate.Footer>
      </ModalTemplate.Window>
    </ModalTemplate.Wrapper>
  );
}
