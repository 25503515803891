import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { getUserInfo } from 'api/userProfile';
import {
  getUserProfileError,
  getUserProfileSuccess,
} from 'redux/actions/userProfile';
import { userProfileConstants } from 'redux/constants/userProfile';
import notify from 'utils/toast';

function* getUserProfileWatcher() {
  yield takeEvery(
    userProfileConstants.GET_PROFILE_REQUEST,
    getUserProfileWorker
  );
}

function* getUserProfileWorker() {
  try {
    const { data: newUser } = yield getUserInfo();
    if (newUser.status === 'Error') {
      throw new Error(newUser.message);
    }

    yield put(getUserProfileSuccess(newUser.data));
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    yield put(getUserProfileError(error));
    yield notify('error', errorMessage);
  }
}

export default function* userSaga() {
  yield fork(getUserProfileWatcher);
}
