import { stepOne } from './step-1';
import { stepTwo } from './step-2';
import { stepThree } from './step-3';
import { stepFour } from './step-4';
import { stepFive } from './step-5';
import { stepSix } from './step-6';
import { stepSeven } from './step-7';
import { stepTab } from './step-tab';

export default [
  stepOne,
  stepTwo,
  stepTab,
  stepThree,
  stepFour,
  stepFive,
  stepSix,
  stepSeven,
];
