import cn from 'classnames';

import { TabTypes } from '../TabComponent.types';

import Badge from './components/Badge/Badge';

import useLocalStorage from 'hooks/useLocalStorage';
import { addPointerToTab } from 'services/onboardingTour/tourOptions';

import styles from '../TabComponent.module.scss';

const TabItem = ({
  title = '',
  onItemClicked = () => console.error('You passed no action to the component'),
  isActive = false,
  icon,
  badge,
  className,
  slug = '',
  disabled = false,
  iconAnimated = false,
}: TabTypes) => {
  const { localStorageKey: onboardingStep } = useLocalStorage('onboarding');

  const generalClasses = {
    [styles['tabitem--inactive']]: isActive,
  };

  return (
    <button
      className={cn(styles.tabitem, generalClasses, className, slug, {
        [styles.colored]: icon,
        'click-button': onboardingStep && addPointerToTab(onboardingStep, slug),
        [styles['icon-animation']]: iconAnimated,
      })}
      onClick={() => onItemClicked()}
      type='button'
      disabled={disabled}
    >
      {icon && icon}
      {badge && <Badge className={styles.badge} />}
      <p
        className={styles['title']}
        dangerouslySetInnerHTML={{ __html: title }}
      />
    </button>
  );
};

export default TabItem;
