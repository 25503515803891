import { memo, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import SteepThankYou from './SteepThankYou';
import StepOne from './StepOne';

import { forgotPasswordForEmailRequest } from 'redux/actions/userAuth';
import { ga } from 'utils/helpers';

const ForgotPassword = memo(() => {
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(1);
  const [userInfo, setUserInfo] = useState({
    email: '',
  });

  useEffect(() => {
    ga('open_page_forgot_password');
  }, []);

  const nextHandler = (value, actions) => {
    setUserInfo(value);
    // the second parameter {} is needed to change the form
    // step after confirming the account registration, we
    // works with them in the saga
    dispatch(
      forgotPasswordForEmailRequest(value.email, {
        currentStep,
        setCurrentStep,
        error(error) {
          // we show the error only in this field and only the abstract value for the security case
          actions.setErrors({
            email: error.message,
            password: error.message,
          });
        },
      })
    );
  };

  const prevHandler = () => setCurrentStep(currentStep - 1);
  const renderStep = (step) => {
    switch (step) {
      case 1:
        return (
          <StepOne
            nextHandler={nextHandler}
            prevHandler={prevHandler}
            userInfo={userInfo}
            setCurrentStep={setCurrentStep}
            setUserInfo={setUserInfo}
          />
        );
      case 2:
        return <SteepThankYou />;
      default:
        return null;
    }
  };

  return <>{renderStep(currentStep)}</>;
});

ForgotPassword.displayName = 'ForgotPassword';

export default ForgotPassword;
