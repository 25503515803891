import deleteTeamMember from './deleteTeamMember';
import getAllTeamMembers from './getAllTeamMembers';
import inviteTeamMember from './inviteTeamMember';
import reinviteTeamMember from './reinviteTeamMember';

export default [
  deleteTeamMember,
  getAllTeamMembers,
  inviteTeamMember,
  reinviteTeamMember,
];
