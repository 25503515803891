import { put, fork, takeEvery } from 'redux-saga/effects';

import { getPurchaseTypeBySlug } from 'api/purchase';
import {
  getPurchaseTypeBySlugError,
  getPurchaseTypeBySlugSuccess,
} from 'redux/actions/purchaseItem';
import { purchaseConstants } from 'redux/constants/purchase';
import notify from 'utils/toast';

function* getPurchaseTypeBySlugWatcher() {
  yield takeEvery(
    purchaseConstants.GET_PURCHASE_TYPE_BY_SLUG_REQUEST,
    getPurchaseTypeBySlugWorker
  );
}

function* getPurchaseTypeBySlugWorker({ payload }) {
  try {
    const { data: purchaseType } = yield getPurchaseTypeBySlug(payload);

    yield put(getPurchaseTypeBySlugSuccess(purchaseType.data));
  } catch (error) {
    yield put(getPurchaseTypeBySlugError(error));
    yield notify('error', error);
  }
}

export default function* getPurchaseTypeBySlugSaga() {
  yield fork(getPurchaseTypeBySlugWatcher);
}
