import { memo } from 'react';

import cn from 'classnames';

import SkeletonExaminationItem from './ExaminationItem/Skeleton';

import Text from 'components/Text';
import { getComponentCount } from 'utils/helpers';

import styles from './ExaminationList.module.scss';

const Skeleton = memo(() => {
  return (
    <>
      <div className={styles.header}>
        <Text color='dark' className={styles.heading}>
          Examinations
        </Text>
      </div>
      <div className={cn(styles.list)}>
        {getComponentCount(<SkeletonExaminationItem />)}
      </div>
    </>
  );
});

Skeleton.displayName = 'Skeleton';

export default Skeleton;
