import Shepherd from 'shepherd.js';

import { helperShowStep } from 'services/onboardingTour/tourOptions';
import i18n from 'translation/i18n';

const STEP_ID = 'single-scan-examination-step-5';

export const stepFive: Shepherd.Step.StepOptions = {
  id: STEP_ID,
  attachTo: { element: '.shepherd-single-scan-right', on: 'left' },
  buttons: [
    {
      classes: 'shepherd-button-primary',
      text: i18n.t('onboarding.show_examination.next'),
      action() {
        return this.next();
      },
    },
  ],
  canClickTarget: false,
  scrollTo: { behavior: 'smooth', block: 'center' },
  title: i18n.t('onboarding.show_examination.single_scan_text_23'),
  text: [
    `${i18n.t(
      'onboarding.show_examination.single_scan_text_24'
    )} <span class='highlight'>${i18n.t(
      'onboarding.show_examination.single_scan_text_25'
    )}</span> ${i18n.t(
      'onboarding.show_examination.single_scan_text_26'
    )}</br> ${i18n.t('onboarding.show_examination.single_scan_text_27')}
    ${i18n.t(
      'onboarding.show_examination.single_scan_text_28'
    )} <span class='highlight'>${i18n.t(
      'onboarding.show_examination.single_scan_text_29'
    )}</span> ${i18n.t('onboarding.show_examination.single_scan_text_30')}`,
  ],
  when: {
    show: () => {
      helperShowStep(STEP_ID);
    },
  },
};
