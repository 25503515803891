export const purchaseConstants = {
  GET_ALL_PURCHASE_TYPE_REQUEST: 'GET_ALL_PURCHASE_TYPE_REQUEST',
  GET_ALL_PURCHASE_TYPE_SUCCESS: 'GET_ALL_PURCHASE_TYPE_SUCCESS',
  GET_ALL_PURCHASE_TYPE_ERROR: 'GET_ALL_PURCHASE_TYPE_ERROR',

  GET_PURCHASE_TYPE_BY_SLUG_REQUEST: 'GET_PURCHASE_TYPE_BY_SLUG_REQUEST',
  GET_PURCHASE_TYPE_BY_SLUG_SUCCESS: 'GET_PURCHASE_TYPE_BY_SLUG_SUCCESS',
  GET_PURCHASE_TYPE_BY_SLUG_ERROR: 'GET_PURCHASE_TYPE_BY_SLUG_ERROR',

  PURCHASE_FOR_EXISTING_CARD_REQUEST: 'PURCHASE_FOR_EXISTING_CARD_REQUEST',
  PURCHASE_FOR_EXISTING_CARD_SUCCESS: 'PURCHASE_FOR_EXISTING_CARD_SUCCESS',
  PURCHASE_FOR_EXISTING_CARD_ERROR: 'PURCHASE_FOR_EXISTING_CARD_ERROR',

  PURCHASE_FOR_NEW_CARD_REQUEST: 'PURCHASE_FOR_NEW_CARD_REQUEST',
  PURCHASE_FOR_NEW_CARD_SUCCESS: 'PURCHASE_FOR_NEW_CARD_SUCCESS',
  PURCHASE_FOR_NEW_CARD_ERROR: 'PURCHASE_FOR_NEW_CARD_ERROR',

  CONFIRM_PURCHASE_REQUEST: 'CONFIRM_PURCHASE_REQUEST',
  CONFIRM_PURCHASE_SUCCESS: 'CONFIRM_PURCHASE_SUCCESS',
  CONFIRM_PURCHASE_ERROR: 'CONFIRM_PURCHASE_ERROR',
};
