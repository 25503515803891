import { helperShowStep } from 'services/onboardingTour/tourOptions';
import i18n from 'translation/i18n';
import { ga } from 'utils/helpers';

const STEP_ID = 'create-report-step-3';

export const stepThree = {
  id: STEP_ID,
  attachTo: { element: '.shepherd-print-report', on: 'right' },
  advanceOn: { selector: '.shepherd-print-report', event: 'click' },
  beforeShowPromise: () =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        resolve();
      }, 500);
    }),
  buttons: [],
  scrollTo: false,
  title: i18n.t('onboarding.create_report.step_3_text_1'),
  text: [
    `<ul class='steps'>
      <li class='step-item'><span class='number'>${i18n.t(
        'onboarding.create_report.step_3_text_2'
      )}</span> <p>${i18n.t(
      'onboarding.create_report.step_3_text_3'
    )} <span class='highlight'>${i18n.t(
      'onboarding.create_report.step_3_text_4'
    )}</span> ${i18n.t('onboarding.create_report.step_3_text_5')}</p></li>
    </ul>
    <p class="next-step">${i18n.t(
      'onboarding.create_report.step_3_text_6'
    )}</p>`,
  ],
  when: {
    show: () => {
      ga('Wizard_Completed');
      helperShowStep(STEP_ID);
    },
  },
};
