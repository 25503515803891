import { ROUTES } from 'utils/constants';

export enum AuthRoutes {
  SIGN_IN = 'SIGN_IN',
  REGISTER_USER = 'REGISTER_USER',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  RESET_PASSWORD = 'RESET_PASSWORD',
  FILL_FORM = 'FILL_FORM',
}

export const RoutePath: Record<AuthRoutes, string> = {
  [AuthRoutes.SIGN_IN]: ROUTES.AUTH.LOGIN,
  [AuthRoutes.REGISTER_USER]: ROUTES.AUTH.REGISTER,
  [AuthRoutes.FORGOT_PASSWORD]: ROUTES.AUTH.FORGOT,
  [AuthRoutes.RESET_PASSWORD]: ROUTES.AUTH.RESET,
  [AuthRoutes.FILL_FORM]: ROUTES.LAW.FILL_FORM,
};
