import { helperShowStep } from 'services/onboardingTour/tourOptions';
import i18n from 'translation/i18n';

const STEP_ID = 'create-patient-step-4';

export const stepFour = {
  id: STEP_ID,
  attachTo: { element: '.shepherd-create-patient-btn', on: 'bottom' },
  advanceOn: { selector: '.shepherd-create-patient-btn', event: 'click' },
  beforeShowPromise: () =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        resolve();
      }, 500);
    }),
  buttons: [],
  scrollTo: { behavior: 'smooth', block: 'center' },
  title: i18n.t('onboarding.create_patient.add_new_patient'),
  text: [
    `${i18n.t(
      'onboarding.create_patient.step_4_text_1'
    )} <span class='highlight'>“${i18n.t(
      'onboarding.create_patient.add_new_patient'
    )}”</span> ${i18n.t('onboarding.create_patient.step_4_text_2')}
    <p class="next-step">${i18n.t(
      'onboarding.create_patient.step_4_text_3'
    )}</p>`,
  ],
  when: {
    show: () => {
      helperShowStep(STEP_ID);
    },
  },
};
