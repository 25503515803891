import { memo } from 'react';

import cn from 'classnames';

import { ReactComponent as DotsIcon } from 'assets/icons/dots.svg';
import Text from 'components/Text';
import { getRandomNumber, getRandomString } from 'utils/helpers';

import styles from './PatientItem.module.scss';

const Skeleton = memo(() => {
  return (
    <div className={cn(styles.item)}>
      <Text color='dark' className={styles.title}>
        {`AUTO${getRandomString()}`}
      </Text>
      <Text color='dark' className={styles.title}>
        {`${getRandomString()}
        ${getRandomString()}`}
      </Text>
      <Text color='dark' className={styles.title}>
        {getRandomNumber(28)}/{getRandomNumber(28)}/{getRandomNumber(2022)}
      </Text>
      <Text color='dark' className={styles.title}>
        {getRandomNumber(24) % 2 === 0 ? 'Male' : 'Female'}
      </Text>
      <Text color='dark' className={styles.title}>
        {getRandomNumber(28)}/{getRandomNumber(28)}/{getRandomNumber(2022)}
      </Text>
      <div className={styles.comment}>
        <Text color='dark' className={styles.short}>
          {`Description ${getRandomString()}`}
        </Text>
      </div>

      <div className={styles.dots}>
        <DotsIcon />
      </div>
    </div>
  );
});

Skeleton.displayName = 'Skeleton';

export default Skeleton;
