import { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import ComponentModal from './ComponentModal';
import DefaultModal from './DefaultModal';

import { closeModal } from 'redux/actions/modal';
import { COMPONENT_MODAL, DEFAULT_MODAL } from 'redux/constants/modal';
import { modalSelector } from 'redux/selectors/modal';

import styles from './Modal.module.scss';

const ModalRedux = () => {
  const { open, format, data } = useSelector(modalSelector);
  const dispatch = useDispatch();

  const renderModal = () => {
    switch (format) {
      case COMPONENT_MODAL:
        return <ComponentModal data={data} />;

      case DEFAULT_MODAL:
        return <DefaultModal data={data} />;

      default:
        return;
    }
  };

  /**
   * @param cantClose - if true, then the modal window cannot be closed
   * "disable" closing modal window by clicking on the substrate
   * Not very good solution, try to fix it later
   */
  const onCloseHandler = useCallback(() => {
    if (data?.component?.props?.cantClose) {
      return undefined;
    }

    dispatch(closeModal());
  }, [data]);

  useEffect(() => {
    open
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = '');
  }, [open]);

  return (
    <CSSTransition in={open} timeout={300} classNames={'alert'} unmountOnExit>
      <div className={styles['modal-wrapper']}>
        <div className={styles['modal-box']}>{renderModal()}</div>
        <div onClick={onCloseHandler} className={styles['modal-substrate']} />
      </div>
    </CSSTransition>
  );
};

export default ModalRedux;
