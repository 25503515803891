import { memo } from 'react';

import { ReactComponent as ThankYouIcon } from 'assets/icons/thankyou.svg';
import Text from 'components/Text';

import styles from './SteepThankYou.module.scss';

const SteepThankYou = memo(() => {
  return (
    <>
      <Text className={styles.title} color='light' tag='h1'>
        Thank you!
      </Text>
      <Text className={styles.subtitle} color='light'>
        Your consent has been collected!
      </Text>
      <ThankYouIcon className={styles.thankyou} />
    </>
  );
});

SteepThankYou.displayName = 'SteepThankYou';

export default SteepThankYou;
