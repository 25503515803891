import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { deletePatient } from 'api/patient';
import { closeModal } from 'redux/actions/modal';
import {
  deletePatientError,
  deletePatientSuccess,
  getPatientRequest,
} from 'redux/actions/patient';
import { patientConstants } from 'redux/constants/patient';
import { PatientsTypes } from 'types/patients';
import notify from 'utils/toast';

interface IDeletePatientWorker {
  type: PatientsTypes;
  payload: number;
}

function* deletePatientWatcher() {
  yield takeEvery(patientConstants.DELETE_PATIENT_REQUEST, deletePatientWorker);
}

function* deletePatientWorker({ payload }: IDeletePatientWorker) {
  try {
    yield deletePatient(payload);
    yield put(getPatientRequest());
    yield put(deletePatientSuccess());
    yield notify('success', t('notifications.patient_deleted_successfully'));
    yield put(closeModal());
  } catch (error) {
    yield put(deletePatientError(error));
    yield notify('error', t('notifications.something_went_wrong'));
    yield put(closeModal());
  }
}

export default function* deletePatientSaga() {
  yield fork(deletePatientWatcher);
}
