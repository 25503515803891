import { lazy } from 'react';

import { type RouteProps } from 'react-router-dom';

import { ROUTES } from 'utils/constants';

const CreatePatient = lazy(() => import('pages/Onboarding/CreatePatient'));
const CreateExamination = lazy(
  () => import('pages/Onboarding/CreateExamination')
);
const Header = lazy(() => import('pages/Onboarding/Header'));
const SingleScan = lazy(() => import('pages/Onboarding/SingleScan'));
const AllScans = lazy(() => import('pages/Onboarding/AllScans'));
const BothEyes = lazy(() => import('pages/Onboarding/BothEyes'));
const Comparison = lazy(() => import('pages/Onboarding/Comparison'));
const Progression = lazy(() => import('pages/Onboarding/Progression'));
const PathologiesDetection = lazy(
  () => import('pages/Onboarding/PathologiesDetection')
);
const Measurements = lazy(() => import('pages/Onboarding/Measurements'));
const Report = lazy(() => import('pages/Onboarding/Report'));

export enum OnBoardingRoutes {
  CREATE_PATIENT = 'CREATE_PATIENT',
  CREATE_EXAMINATION = 'CREATE_EXAMINATION',
  EXAMINATION_HEADER = 'EXAMINATION_HEADER',
  SINGLE_SCAN_TAB = 'SINGLE_SCAN_TAB',
  PATHOLOGIES_DETECTION_TAB = 'PATHOLOGIES_DETECTION_TAB',
  ALL_SCANS_TAB = 'ALL_SCANS_TAB',
  BOTH_EYES_TAB = 'BOTH_EYES_TAB',
  COMPARISON_TAB = 'COMPARISON_TAB',
  PROGRESSION_TAB = 'PROGRESSION_TAB',
  MEASURMENTS_TAB = 'MEASURMENTS_TAB',
  REPORT = 'REPORT',
}

export const RoutePath: Record<OnBoardingRoutes, string> = {
  [OnBoardingRoutes.CREATE_PATIENT]: ROUTES.ONBOARDING.MAIN,
  [OnBoardingRoutes.CREATE_EXAMINATION]: ROUTES.ONBOARDING.CREATE_EXAMINATION,
  [OnBoardingRoutes.EXAMINATION_HEADER]: ROUTES.ONBOARDING.EXAMINATION_HEADER,
  [OnBoardingRoutes.SINGLE_SCAN_TAB]: ROUTES.ONBOARDING.SINGLE_SCAN_TAB,
  [OnBoardingRoutes.PATHOLOGIES_DETECTION_TAB]:
    ROUTES.ONBOARDING.PATHOLOGIES_DETECTION_TAB,
  [OnBoardingRoutes.ALL_SCANS_TAB]: ROUTES.ONBOARDING.ALL_SCANS_TAB,
  [OnBoardingRoutes.BOTH_EYES_TAB]: ROUTES.ONBOARDING.BOTH_EYES_TAB,
  [OnBoardingRoutes.COMPARISON_TAB]: ROUTES.ONBOARDING.COMPARISON_TAB,
  [OnBoardingRoutes.PROGRESSION_TAB]: ROUTES.ONBOARDING.PROGRESSION_TAB,
  [OnBoardingRoutes.MEASURMENTS_TAB]: ROUTES.ONBOARDING.MEASURMENTS_TAB,
  [OnBoardingRoutes.REPORT]: ROUTES.ONBOARDING.REPORT,
};

export const onBoardingRouteConfig: Record<OnBoardingRoutes, RouteProps> = {
  [OnBoardingRoutes.CREATE_PATIENT]: {
    path: RoutePath.CREATE_PATIENT,
    element: <CreatePatient />,
  },
  [OnBoardingRoutes.CREATE_EXAMINATION]: {
    path: RoutePath.CREATE_EXAMINATION,
    element: <CreateExamination />,
  },
  [OnBoardingRoutes.EXAMINATION_HEADER]: {
    path: RoutePath.EXAMINATION_HEADER,
    element: <Header />,
  },
  [OnBoardingRoutes.SINGLE_SCAN_TAB]: {
    path: RoutePath.SINGLE_SCAN_TAB,
    element: <SingleScan />,
  },
  [OnBoardingRoutes.PATHOLOGIES_DETECTION_TAB]: {
    path: RoutePath.PATHOLOGIES_DETECTION_TAB,
    element: <PathologiesDetection />,
  },
  [OnBoardingRoutes.MEASURMENTS_TAB]: {
    path: RoutePath.MEASURMENTS_TAB,
    element: <Measurements />,
  },
  [OnBoardingRoutes.ALL_SCANS_TAB]: {
    path: RoutePath.ALL_SCANS_TAB,
    element: <AllScans />,
  },
  [OnBoardingRoutes.BOTH_EYES_TAB]: {
    path: RoutePath.BOTH_EYES_TAB,
    element: <BothEyes />,
  },
  [OnBoardingRoutes.COMPARISON_TAB]: {
    path: RoutePath.COMPARISON_TAB,
    element: <Comparison />,
  },
  [OnBoardingRoutes.PROGRESSION_TAB]: {
    path: RoutePath.PROGRESSION_TAB,
    element: <Progression />,
  },
  [OnBoardingRoutes.REPORT]: {
    path: RoutePath.REPORT,
    element: <Report />,
  },
};
