import deletePaymentCard from './deletePaymentCard';
import getPaymentIntent from './getPaymentIntent';
import getPaymentMethod from './getPaymentMethod';
import payStripe from './payStripe';

export default [
  deletePaymentCard,
  payStripe,
  getPaymentMethod,
  getPaymentIntent,
];
