import { offset } from '@floating-ui/dom';
import Shepherd from 'shepherd.js';

import { closeModal } from 'redux/actions/modal';
import { store } from 'redux/store';

export const helperRemoveStep = () => {
  localStorage.removeItem('onboarding');
  store.dispatch(closeModal());

  /**
   * Redirect to the dashboard page with additional query params
   * for opening downloadDMC modal
   */
  window.location.href = '/?firstExamination=true';
};

export const helperShowStep = (step: string) => {
  localStorage.setItem('onboarding', step);

  /**
   * !!! Dispatching event to update the state of the onboarding tour
   * Trigger use in the useLocalStorage hook for update the step
   * Without this event, we can't return the correct step when Skip button is clicked
   */
  dispatchEvent(new Event('storage'));
};

/**
 * Adds class for animated pointer to the item where it is needed
 */
export const addPointerToTab = (onboardingStep: string, slug: string) => {
  return (
    (onboardingStep === 'all-scans-examination-step-1' &&
      slug === 'all-scans') ||
    (onboardingStep === 'both-eyes-examination-step-1' &&
      slug === 'both-eyes') ||
    (onboardingStep === 'comparison-examination-step-1' &&
      slug === 'comparison') ||
    (onboardingStep === 'progression-examination-step-1' &&
      slug === 'progression') ||
    (onboardingStep === 'segmentation-examination-step-1' &&
      slug === 'segmentation') ||
    (onboardingStep === 'measurements-examination-step-1' &&
      slug === 'measurements')
  );
};

const tourOptions: Shepherd.Tour.TourOptions = {
  defaultStepOptions: {
    modalOverlayOpeningRadius: 8,
    modalOverlayOpeningPadding: 8,
    floatingUIOptions: {
      middleware: [offset(18)],
    },
  },
  /**
   * Enable keyboard navigation for the tour only in development(local, staging env)
   * For easier debugging in the development for QA, you don't need to click one target item
   * But in some cases, you need to click on the target item to open the modal window
   * and go to the next step
   */
  keyboardNavigation: process.env.REACT_APP_ENV !== 'production',
  modalContainer: document.getElementById('root') as HTMLElement,
  exitOnEsc: false,
  useModalOverlay: true,
};

export default tourOptions;
