import { ReactNode } from 'react';

import dicom from 'assets/files/AltrisAI_Onboarding_Dicom.dcm';
import { ReactComponent as AllScansTabIcon } from 'assets/icons/all-scans.svg';
import { ReactComponent as BothEyesTabIcon } from 'assets/icons/both-eyes.svg';
import { ReactComponent as ComparisonTabIcon } from 'assets/icons/comparison-tab.svg';
import { ReactComponent as ContactUsIcon } from 'assets/icons/contact-us.svg';
import { ReactComponent as CreateExaminationIcon } from 'assets/icons/create-examination.svg';
import { ReactComponent as CreatePatientIcon } from 'assets/icons/create-patient.svg';
import { ReactComponent as DocumentationIcon } from 'assets/icons/documentation.svg';
import { ReactComponent as HeaderIcon } from 'assets/icons/header.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg';
import { ReactComponent as JoinAltrisIcon } from 'assets/icons/join-altris.svg';
import { ReactComponent as MeasurementsTabIcon } from 'assets/icons/measurements-tab.svg';
import { ReactComponent as OnboardingIcon } from 'assets/icons/onboarding.svg';
import { ReactComponent as PictureDicomIcon } from 'assets/icons/picture.svg';
import { ReactComponent as ProgressionTabIcon } from 'assets/icons/progression-tab.svg';
import { ReactComponent as ReportIcon } from 'assets/icons/report.svg';
import { ReactComponent as SegmentationAndClassificationTabIcon } from 'assets/icons/s&c-tab.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/setting.svg';
import { ReactComponent as SingleScanTabIcon } from 'assets/icons/single-scan.svg';
import { ReactComponent as SubscriptionIcon } from 'assets/icons/subscription.svg';
import { generateRoles } from 'router/helpers';
import i18n from 'translation/i18n';
import { ROUTES, USER_ROLES } from 'utils/constants';

export interface IMenuLinks {
  /** Temporary solution DELETE WHEN STEP 2 will be ready */
  link?: string;
  icon: ReactNode;
  title: string;
  allowedRole: USER_ROLES[];
  target?: string;
  /** Temporary solution DELETE WHEN STEP 2 will be ready */
  action?: () => void;
}

export const menuLinks = (): IMenuLinks[] => [
  {
    link: ROUTES.DASHBOARD.MAIN,
    icon: <HomeIcon />,
    title: i18n.t('components.header.menuItems.patients'),
    allowedRole: generateRoles(),
  },
  {
    link: ROUTES.PROFILE.MAIN,
    icon: <SettingsIcon />,
    title: i18n.t('components.header.menuItems.settings'),
    allowedRole: generateRoles(),
  },
  {
    link: ROUTES.PROFILE.SUBSCRIPTION,
    icon: <SubscriptionIcon />,
    title: i18n.t('components.header.menuItems.subscription'),
    allowedRole: generateRoles(),
  },
  {
    link: ROUTES.PROFILE.TEAM_MEMBERS,
    icon: <CreatePatientIcon />,
    title: i18n.t('components.header.menuItems.team_members'),
    allowedRole: generateRoles([USER_ROLES.B2C]),
  },
  {
    link: ROUTES.PROFILE.CONTACT_US,
    icon: <ContactUsIcon />,
    title: i18n.t('components.header.menuItems.contact_us'),
    allowedRole: generateRoles(),
  },
  {
    /** Temporary solution DELETE WHEN STEP 2 will be ready */
    link: ROUTES.ONBOARDING.MAIN,
    icon: <OnboardingIcon />,
    title: i18n.t('components.header.menuItems.onboarding'),
    allowedRole: generateRoles(),
  },
];

/**
  example for link with target _blank
  {
    link: `https://google.com.ua`,
    icon: <DocumentationIcon />,
    title: 'Documentation',
    target?: '_blank',
  }
 */

interface ProfileLink {
  link: string;
  icon: JSX.Element;
  title: string;
  allowedRole: USER_ROLES[];
  target?: string;
}

export const profileLinks = () => {
  const links: ProfileLink[] = [
    {
      link: ROUTES.PROFILE.SETTINGS,
      icon: <SettingsIcon />,
      title: i18n.t('profile_links.settings'),
      allowedRole: generateRoles(),
    },
    {
      link: ROUTES.PROFILE.SUBSCRIPTION,
      icon: <SubscriptionIcon />,
      title: i18n.t('profile_links.subscriptions'),
      allowedRole: generateRoles(),
    },
    {
      link: ROUTES.PROFILE.TEAM_MEMBERS,
      icon: <CreatePatientIcon />,
      title: i18n.t('profile_links.team_members'),
      allowedRole: generateRoles([USER_ROLES.B2C]),
    },
    {
      link: ROUTES.PROFILE.CONTACT_US,
      icon: <ContactUsIcon />,
      title: i18n.t('profile_links.contact_us'),
      allowedRole: generateRoles(),
    },
    {
      link: `https://docs.altris.ai/docs/general-software-description`,
      icon: <DocumentationIcon />,
      title: i18n.t('profile_links.documentation'),
      target: '_blank',
      allowedRole: generateRoles(),
    },
    {
      link: ROUTES.PROFILE.EDUCATION,
      icon: <JoinAltrisIcon />,
      title: i18n.t('profile_links.join_altris_education'),
      allowedRole: generateRoles(),
    },
    {
      link: ROUTES.ONBOARDING.MAIN,
      icon: <OnboardingIcon />,
      title: i18n.t('profile_links.onboarding'),
      allowedRole: generateRoles(),
    },
  ];

  if (
    process.env.REACT_APP_ENV === 'local' ||
    process.env.REACT_APP_ENV === 'development'
  ) {
    links.push({
      link: ROUTES.PROFILE.STORY_BOOK,
      icon: <SettingsIcon />,
      title: i18n.t('profile_links.storybook'),
      allowedRole: generateRoles(),
    });
  }

  return links;
};

/**
 * Temporary these links don't shown
 * in the UserPanel.jsx component
 */

export const userPopupMenuLinks = [
  {
    link: ROUTES.PROFILE.SUBSCRIPTION,
    icon: <SubscriptionIcon />,
    title: i18n.t('user_popup_menu_links.subscription'),
  },
  {
    link: ROUTES.PROFILE.CONTACT_US,
    icon: <ContactUsIcon />,
    title: i18n.t('user_popup_menu_links.contact_support'),
  },
];

export const onboardingLinks = () => [
  {
    action: () => {
      window.location.href = '/?onboarding=true';
    },
    icon: <OnboardingIcon />,
    title: i18n.t('onboarding_links.complete_onboarding_again'),
    allowedRole: generateRoles(),
  },
  {
    action: () => {
      window.location.href = dicom;
    },
    icon: <PictureDicomIcon />,
    title: i18n.t('onboarding_links.download_test_dicom'),
    allowedRole: generateRoles(),
  },
  {
    link: ``,
    icon: <CreatePatientIcon />,
    title: i18n.t('onboarding_links.create_patient'),
    allowedRole: generateRoles(),
  },
  {
    link: ROUTES.ONBOARDING.CREATE_EXAMINATION,
    icon: <CreateExaminationIcon />,
    title: i18n.t('onboarding_links.create_examintaion'),
    allowedRole: generateRoles(),
  },
  {
    link: ROUTES.ONBOARDING.EXAMINATION_HEADER,
    icon: <HeaderIcon />,
    title: i18n.t('onboarding_links.header'),
    allowedRole: generateRoles(),
  },
  {
    link: ROUTES.ONBOARDING.SINGLE_SCAN_TAB,
    icon: <SingleScanTabIcon />,
    title: i18n.t('onboarding_links.single_scan'),
    allowedRole: generateRoles(),
  },
  {
    link: ROUTES.ONBOARDING.PATHOLOGIES_DETECTION_TAB,
    icon: <SegmentationAndClassificationTabIcon />,
    title: i18n.t('onboarding_links.pathologies_detection'),
    allowedRole: generateRoles(),
  },
  {
    link: ROUTES.ONBOARDING.MEASURMENTS_TAB,
    icon: <MeasurementsTabIcon />,
    title: i18n.t('onboarding_links.measurements'),
    allowedRole: generateRoles(),
  },
  {
    link: ROUTES.ONBOARDING.ALL_SCANS_TAB,
    icon: <AllScansTabIcon />,
    title: i18n.t('onboarding_links.all_scans'),
    allowedRole: generateRoles(),
  },
  {
    link: ROUTES.ONBOARDING.BOTH_EYES_TAB,
    icon: <BothEyesTabIcon />,
    title: i18n.t('onboarding_links.both_eyes'),
    allowedRole: generateRoles(),
  },
  {
    link: ROUTES.ONBOARDING.COMPARISON_TAB,
    icon: <ComparisonTabIcon />,
    title: i18n.t('onboarding_links.comparison'),
    allowedRole: generateRoles(),
  },
  {
    link: ROUTES.ONBOARDING.PROGRESSION_TAB,
    icon: <ProgressionTabIcon />,
    title: i18n.t('onboarding_links.progression'),
    allowedRole: generateRoles(),
  },
  {
    link: ROUTES.ONBOARDING.REPORT,
    icon: <ReportIcon />,
    title: i18n.t('onboarding_links.report'),
    allowedRole: generateRoles(),
  },
];
