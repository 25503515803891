import { memo } from 'react';

import { ReactComponent as DotsIcon } from 'assets/icons/dots.svg';
import { getRandomNumber } from 'utils/helpers';

import styles from './ExaminationItem.module.scss';

const Skeleton = memo(() => {
  return (
    <div className={styles.item}>
      <p className={styles.title}>
        Eye: {getRandomNumber(24) % 2 === 0 ? 'Right (OD)' : 'Left (OS)'},{' '}
        {getRandomNumber(12)}mm/{getRandomNumber(512)}
      </p>
      <div className={styles.date}>
        <span>
          {getRandomNumber(28)}/{getRandomNumber(28)}/{getRandomNumber(2022)}
        </span>
      </div>
      <div className={styles.dots}>
        <DotsIcon />
      </div>
    </div>
  );
});

Skeleton.displayName = 'Skeleton';

export default Skeleton;
