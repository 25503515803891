import { stripeConstants } from 'redux/constants/stripe';

const initialState = {
  requesting: false,
  errors: null,
  data: {
    payment_method: [],
    client_secret: '',
  },
};

const stripeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    //PAY BY CARD
    case stripeConstants.PAY_BY_CARD_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case stripeConstants.PAY_BY_CARD_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
      };
    case stripeConstants.PAY_BY_CARD_ERROR:
      return {
        ...state,
        requesting: false,
        errors: payload,
      };

    // DELETE PAYMENT CARD
    case stripeConstants.DELETE_PAYMENT_CARD_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case stripeConstants.DELETE_PAYMENT_CARD_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
      };
    case stripeConstants.DELETE_PAYMENT_CARD_ERROR:
      return {
        ...state,
        requesting: false,
        errors: payload,
      };

    // GET SUBSCRIPTION_PAYMENT_METHOD
    case stripeConstants.GET_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case stripeConstants.GET_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          payment_method: payload,
        },
        requesting: false,
        errors: null,
      };
    case stripeConstants.GET_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        requesting: false,
        errors: payload,
      };

    // GET_PAYMENT_INTENT
    case stripeConstants.GET_PAYMENT_INTENT_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case stripeConstants.GET_PAYMENT_INTENT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          client_secret: payload,
        },
        requesting: false,
        errors: null,
      };
    case stripeConstants.GET_PAYMENT_INTENT_ERROR:
      return {
        ...state,
        requesting: false,
        errors: payload,
      };

    default:
      return state;
  }
};

export default stripeReducer;
