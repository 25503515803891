import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { getPaymentIntent } from 'api/stripe';
import {
  getPaymentIntentError,
  getPaymentIntentSuccess,
} from 'redux/actions/stripe';
import { stripeConstants } from 'redux/constants/stripe';
import notify from 'utils/toast';

function* getPaymentIntentWatcher() {
  yield takeEvery(
    stripeConstants.GET_PAYMENT_INTENT_REQUEST,
    getPaymentIntentWorker
  );
}

function* getPaymentIntentWorker() {
  try {
    const { data: payment_intent } = yield getPaymentIntent();
    if (payment_intent.status === 'Error') {
      throw new Error(payment_intent.message);
    }

    yield put(getPaymentIntentSuccess(payment_intent.data.client_secret));
  } catch (error) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    yield put(getPaymentIntentError(error));
    yield notify('error', errorMessage);
  }
}

export default function* getPaymentIntentSaga() {
  yield fork(getPaymentIntentWatcher);
}
