import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { updateProgressionState } from 'api/examination';
import { updateProgressionStateFinally } from 'redux/actions/examination';
import { ExamActionTypes } from 'redux/constants/examination';
import { IReduxAction } from 'redux/types/examination';
import notify from 'utils/toast';

function* updateProgressionStateWatcher() {
  yield takeEvery(
    ExamActionTypes.UPDATE_PROGRESSION_STATE_REQUEST,
    updateProgressionStateWorker
  );
}

interface IUpdateProgressionState {
  type: ExamActionTypes;
  payload: string[];
  action?: IReduxAction;
}

function* updateProgressionStateWorker({
  payload,
  action,
}: IUpdateProgressionState) {
  action?.default?.();

  try {
    yield updateProgressionState(payload);

    action?.success?.();
  } catch (error) {
    action?.error?.();
    notify('error', t('notifications.something_went_wrong'));
  } finally {
    yield put(updateProgressionStateFinally());
  }
}

export default function* updateProgressionStateSaga() {
  yield fork(updateProgressionStateWatcher);
}
