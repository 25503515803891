import { memo, useRef, useState } from 'react';

import cn from 'classnames';
import { useFormikContext } from 'formik';
import { t } from 'i18next';
import { DayPicker } from 'react-day-picker';

import useOnClickOutside from 'hooks/useOnClickOutside';
import { useSettings } from 'hooks/useSettings';
import { SettingsEnum } from 'types/settings';
import { DateFormatter } from 'utils/DateFormatter.class';

import 'react-day-picker/dist/style.css';

import styles from '../DatePicker.module.scss';

const css = `
      .rdp {
        --rdp-cell-size: 40px;
        --rdp-accent-color: red;
        --rdp-background-color: transparent;
        --rdp-outline: 2px solid transparent;
        --rdp-outline-selected: 2px solid transparent;
      }
      .rdp-caption_label {
        font-size: 15px;
        font-weight: 500 !important;
      }
      .rdp-dropdown {
        width: 200px;
        font-size: 14px;
        font-weight: 400;
      }
      .rdp-day_outside {
        color: #747474;
      }
      .rdp-caption_label {
        font-weight: 700;
      }
      .rdp-head_cell {
        font-weight: 500;
        font-size: 12px;
      }
      .rdp-nav_button {
        border-color: transparent !important;
      }
      .rdp-button:focus {
        background: transparent;
      }
      .rdp-nav_button svg {
        transition: 0.5s;
      }
      .rdp-nav_button:hover {
        background-color: transparent !important;
      }
      .rdp-nav_button:hover svg {
        color: var(--theme-color);
      }
      .rdp-cell {
        border: 2px solid transparent;
      }
      .rdp-button {
        border: 1px solid #DFDFDF;
        border-radius: 4px;
      }
      .rdp-button:focus:not(.rdp-nav_button), .rdp-button:hover:not(.rdp-nav_button), .rdp-button:active:not(.rdp-nav_button) {
        color: #fff;
        font-weight: 600;
        outline: none;
        border: 1px solid #DFDFDF;
        background-color: var(--theme-color) !important;
      }
      .rdp-day_selected:focus:not([aria-disabled='true']) {
        border: 1px solid #DFDFDF;
      }
      .rdp-day_selected {
        background-color: var(--theme-color) !important;
        font-weight: 600;
      }
      .rdp-day_today, .rdp-day_today:not(.rdp-day_outside) {
        font-weight: 400;
      }

      @media (max-width: 1023px) {
        .rdp {
          --rdp-cell-size: 30px;
        }
      }
    `;

const DatePicker = memo(
  ({
    name,
    placeholder,
    disabled,
    disabledChange,
    onChange,
    id,
    label,
    innerClassName,
    popupLeft = false,
  }) => {
    const wrapperRef = useRef();
    const { values, setFieldValue } = useFormikContext();
    const [show, setShow] = useState(false);

    const timeFormat = useSettings(SettingsEnum.TIME_FORMAT);

    useOnClickOutside(wrapperRef, () => show && setShow(false));

    return (
      <>
        <div
          ref={wrapperRef}
          className={cn(styles['date-picker'], innerClassName)}
        >
          <style>{css}</style>
          <input
            readOnly
            type='text'
            placeholder={placeholder ? placeholder : t('select date')}
            className={styles['placeholder-input']}
            name={name}
            value={DateFormatter.getDate(timeFormat, values?.[name])}
            onClick={() => setShow(!show)}
            disabled={disabledChange}
          />
          {show && (
            <div
              className={cn(styles['picker-wrapper'], {
                [styles.left]: popupLeft,
              })}
            >
              <DayPicker
                mode='single'
                showOutsideDays
                fromYear={new Date().getUTCFullYear() - 100}
                toYear={new Date().getUTCFullYear()}
                captionLayout='dropdown'
                selected={DateFormatter.getDate(timeFormat, values?.[name])}
                onSelect={(date) => {
                  setFieldValue(
                    name,
                    date
                      ? Math.round(DateFormatter.getUTCtimestamp(date) / 1000)
                      : undefined
                  );
                  onChange && onChange();
                  setShow((state) => !state);
                }}
                disabled={disabled}
              />
            </div>
          )}
        </div>
        <label htmlFor={id}>{label}</label>
      </>
    );
  }
);

DatePicker.displayName = 'DatePicker';

export default DatePicker;
