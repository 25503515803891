import { t } from 'i18next';
import { fork, put, select, takeEvery } from 'redux-saga/effects';

import { updateUserInfo } from 'api/userProfile';
import {
  updateProfileError,
  updateProfileSuccess,
} from 'redux/actions/userProfile';
import { userProfileConstants } from 'redux/constants/userProfile';
import { userProfileSelector } from 'redux/selectors/userProfile';
import { IUpdateUserData } from 'types/editProfile';
import notify from 'utils/toast';

function* updateUserProfileWatcher() {
  yield takeEvery(
    userProfileConstants.UPDATE_PROFILE_REQUEST,
    updateUserProfileWorker
  );
}

function* updateUserProfileWorker({
  payload: { avatar, name, surname, profession_id, country_id, email },
}: IUpdateUserData) {
  try {
    const avatarFileUploaded = avatar;

    const formData = new FormData();

    if (typeof avatarFileUploaded === 'object') {
      formData.append('avatar', avatarFileUploaded);
    }

    formData.append('name', name);
    formData.append('surname', surname);
    formData.append('profession_id', JSON.stringify(profession_id));
    formData.append('country_id', JSON.stringify(country_id));
    formData.append('email', email);

    const { data: userInfoUpdate } = yield updateUserInfo(formData);

    if (userInfoUpdate.status === 'Error') {
      throw new Error(userInfoUpdate.message);
    }

    const { data: userInfo } = yield select(userProfileSelector);

    /**
     * Updating data for user in the state without sending
     * request to the backend
     */
    yield put(
      updateProfileSuccess({
        ...userInfo,
        name: name,
        surname: surname,
        profession_id: profession_id,
        country_id: country_id,
        avatar:
          typeof avatarFileUploaded === 'object'
            ? URL.createObjectURL(avatarFileUploaded)
            : avatar,
      })
    );

    yield notify('success', t('notifications.profile_updated'));
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    yield put(updateProfileError(error));
    yield notify('error', errorMessage);
  }
}

export default function* userSaga() {
  yield fork(updateUserProfileWatcher);
}
