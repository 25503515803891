import { ExportExamActions } from '../constants/exportExam';

import { IReduxAction } from 'redux/types/examination';
import { ExamSegmentationExport } from 'types/examination';

export const resetExportExamState = () => ({
  type: ExportExamActions.EXAM_EXPORT_RESET,
});

export const exportExamSegmentRequest = (
  data: { exam_id: string },
  action?: IReduxAction
) => ({
  type: ExportExamActions.EXAM_EXPORT_REQUEST,
  payload: data,
  action: action,
});

export const exportExamSegmentSuccess = (
  data: ExamSegmentationExport,
  action?: IReduxAction
) => ({
  type: ExportExamActions.EXAM_EXPORT_SUCCESS,
  payload: data,
  action: action,
});

export const exportExamSegmentError = (
  data?: { message: string },
  action?: IReduxAction
) => ({
  type: ExportExamActions.EXAM_EXPORT_ERROR,
  payload: data,
  action: action,
});
