import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { deleteMeasurementsScan } from 'api/examination';
import {
  deleteExamMeasLayersError,
  deleteExamMeasLayersSuccess,
} from 'redux/actions/examination';
import { ExamActionTypes } from 'redux/constants/examination';
import { IWorkerProps } from 'redux/types/examination';
import { IMeasurementsScan } from 'types/examination';
import { removeInvalidPolygons } from 'utils/helpers';
import notify from 'utils/toast';

function* deleteMeasurementsLayerWatcher() {
  yield takeEvery(
    ExamActionTypes.DELETE_EXAM_MEASUREMENTS_LAYER_REQUEST,
    deleteMeasurementsWorker
  );
}

interface IDeleteMeasWorker extends IWorkerProps {
  type: ExamActionTypes;
  payload: {
    exam_id: string;
    scan_id: string;
  };
  action?: {
    default?: () => void;
    success?: (newMeasData?: IMeasurementsScan) => void;
    error?: () => void;
  };
}

function* deleteMeasurementsWorker({ payload, action }: IDeleteMeasWorker) {
  action?.default?.();

  try {
    const { exam_id, scan_id } = payload;

    const { data: layersData } = yield deleteMeasurementsScan({
      exam_id,
      scan_id,
    });
    if (layersData.status === 'Error') {
      throw new Error(layersData.message);
    }
    // Remove invalid polygons that we have from ML
    const cleanData = removeInvalidPolygons(layersData.data);

    yield put(deleteExamMeasLayersSuccess(cleanData));

    yield notify('success', t('notifications.measurements_reset_successfully'));
    action?.success?.(layersData.data);
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    action?.error?.();
    yield notify('error', errorMessage);
    yield put(
      deleteExamMeasLayersError({
        slug: 'something_went_wrong',
        message: 'Something went wrong',
      })
    );
  }
}

export default function* deleteMeasurementsLayerSaga() {
  yield fork(deleteMeasurementsLayerWatcher);
}
