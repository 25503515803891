import { purchaseConstants } from 'redux/constants/purchase';

// GET ALL PURCHASE_TYPE
export const getAllPurchaseTypeRequest = () => ({
  type: purchaseConstants.GET_ALL_PURCHASE_TYPE_REQUEST,
});

export const getAllPurchaseTypeSuccess = (data) => ({
  type: purchaseConstants.GET_ALL_PURCHASE_TYPE_SUCCESS,
  payload: data,
});

export const getAllPurchaseTypeError = (error) => ({
  type: purchaseConstants.GET_ALL_PURCHASE_TYPE_ERROR,
  payload: error,
});

// GET PURCHASE TYPE BY SLUG
export const getPurchaseTypeBySlugRequest = (type) => ({
  type: purchaseConstants.GET_PURCHASE_TYPE_BY_SLUG_REQUEST,
  payload: type,
});

export const getPurchaseTypeBySlugSuccess = (data) => ({
  type: purchaseConstants.GET_PURCHASE_TYPE_BY_SLUG_SUCCESS,
  payload: data,
});

export const getPurchaseTypeBySlugError = (error) => ({
  type: purchaseConstants.GET_PURCHASE_TYPE_BY_SLUG_ERROR,
  payload: error,
});

// PURCHASE WITH EXISTING CARD
export const purchaseForExistingCardRequest = (data, action) => ({
  type: purchaseConstants.PURCHASE_FOR_EXISTING_CARD_REQUEST,
  payload: data,
  action: action,
});

export const purchaseForExistingCardSuccess = () => ({
  type: purchaseConstants.PURCHASE_FOR_EXISTING_CARD_SUCCESS,
});

export const purchaseForExistingCardError = (error) => ({
  type: purchaseConstants.PURCHASE_FOR_EXISTING_CARD_ERROR,
  payload: error,
});

// PURCHASE WITH NEW CARD
export const purchaseForNewCardRequest = (data, action) => ({
  type: purchaseConstants.PURCHASE_FOR_NEW_CARD_REQUEST,
  payload: data,
  action: action,
});

export const purchaseForNewCardSuccess = () => ({
  type: purchaseConstants.PURCHASE_FOR_NEW_CARD_SUCCESS,
});

export const purchaseForNewCardError = (error) => ({
  type: purchaseConstants.PURCHASE_FOR_NEW_CARD_ERROR,
  payload: error,
});

// CONFIRM PURCHASE WITH NEW CARD
export const confirmPurchaseRequest = (data) => ({
  type: purchaseConstants.CONFIRM_PURCHASE_REQUEST,
  payload: data,
});

export const confirmPurchaseSuccess = () => ({
  type: purchaseConstants.CONFIRM_PURCHASE_SUCCESS,
});

export const confirmPurchaseError = (error) => ({
  type: purchaseConstants.CONFIRM_PURCHASE_ERROR,
  payload: error,
});
