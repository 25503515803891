import { professionsAndCountriesTypes } from 'redux/constants/professionsAndCountries';
import {
  ICountry,
  IOCTProducers,
  IProfession,
} from 'types/professionAndCountry';

export const getProfessionsCountriesAndProducersRequest = () => ({
  type: professionsAndCountriesTypes.GET_PROFESSIONS_COUNTRIES_AND_PRODUCERS_REQUEST,
});

export const getProfessionsCountriesAndProducersSuccess = (data: {
  professions: IProfession[];
  countries: ICountry[];
  producers: IOCTProducers[];
}) => ({
  type: professionsAndCountriesTypes.GET_PROFESSIONS_COUNTRIES_AND_PRODUCERS_SUCCESS,
  payload: data,
});

export const getProfessionsCountriesAndProducersError = (
  error: unknown | string
) => ({
  type: professionsAndCountriesTypes.GET_PROFESSIONS_COUNTRIES_AND_PRODUCERS_ERROR,
  payload: error,
});
