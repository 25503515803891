import { stripeConstants } from 'redux/constants/stripe';
// GET PAY BY CARD
export const payStripeRequest = (data, action) => ({
  type: stripeConstants.PAY_BY_CARD_REQUEST,
  payload: data,
  action: action,
});

export const payStripeSuccess = (data) => ({
  type: stripeConstants.PAY_BY_CARD_SUCCESS,
  payload: data,
});

export const payStripeError = (error) => ({
  type: stripeConstants.PAY_BY_CARD_ERROR,
  payload: error,
});

// DELETE PAYMENT METHOD
export const deletePaymentCardRequest = (data) => ({
  type: stripeConstants.DELETE_PAYMENT_CARD_REQUEST,
  payload: data,
});

export const deletePaymentCardSuccess = (data) => ({
  type: stripeConstants.DELETE_PAYMENT_CARD_SUCCESS,
  payload: data,
});

export const deletePaymentCardError = (error) => ({
  type: stripeConstants.DELETE_PAYMENT_CARD_ERROR,
  payload: error,
});

// GET PAYMENT_METHOD
export const getPaymentMethodRequest = () => ({
  type: stripeConstants.GET_PAYMENT_METHOD_REQUEST,
});

export const getPaymentMethodSuccess = (data) => ({
  type: stripeConstants.GET_PAYMENT_METHOD_SUCCESS,
  payload: data,
});

export const getPaymentMethodError = (error) => ({
  type: stripeConstants.GET_PAYMENT_METHOD_ERROR,
  payload: error,
});

// GET SUBSCRIPTION_PAYMENT_INTENT
export const getPaymentIntentRequest = () => ({
  type: stripeConstants.GET_PAYMENT_INTENT_REQUEST,
});

export const getPaymentIntentSuccess = (data) => ({
  type: stripeConstants.GET_PAYMENT_INTENT_SUCCESS,
  payload: data,
});

export const getPaymentIntentError = (error) => ({
  type: stripeConstants.GET_PAYMENT_INTENT_ERROR,
  payload: error,
});
