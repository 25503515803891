import { surveyTypes } from 'redux/constants/survey';
import { ISurveyState, surveyActions } from 'types/survey';

const initialState: ISurveyState = {
  requesting: false,
  errors: null,
  data: {
    unsubscribe: {
      reason: [{ value: '', label: '' }],
    },
  },
};

const surveyReducer = (
  state = initialState,
  action: surveyActions
): ISurveyState => {
  switch (action.type) {
    /** For future update, when client want to create several surveys */
    case surveyTypes.GET_SURVEY_REQUEST:
      return {
        ...state,
        requesting: true,
      };
    case surveyTypes.GET_SURVEY_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
        data: action.payload,
      };
    case surveyTypes.GET_SURVEY_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };
    case surveyTypes.SEND_SURVEY_REQUEST:
      return {
        ...state,
        requesting: true,
      };
    case surveyTypes.SEND_SURVEY_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
      };
    case surveyTypes.SEND_SURVEY_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default surveyReducer;
