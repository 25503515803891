export const stripeConstants = {
  PAY_BY_CARD_REQUEST: 'PAY_BY_CARD_REQUEST',
  PAY_BY_CARD_SUCCESS: 'PAY_BY_CARD_SUCCESS',
  PAY_BY_CARD_ERROR: 'PAY_BY_CARD_ERROR',

  GET_PAYMENT_METHOD_REQUEST: 'GET_PAYMENT_METHOD_REQUEST',
  GET_PAYMENT_METHOD_SUCCESS: 'GET_PAYMENT_METHOD_SUCCESS',
  GET_PAYMENT_METHOD_ERROR: 'GET_PAYMENT_METHOD_ERROR',

  DELETE_PAYMENT_CARD_REQUEST: 'DELETE_PAYMENT_CARD_REQUEST',
  DELETE_PAYMENT_CARD_SUCCESS: 'DELETE_PAYMENT_CARD_SUCCESS',
  DELETE_PAYMENT_CARD_ERROR: 'DELETE_PAYMENT_CARD_ERROR',

  GET_PAYMENT_INTENT_REQUEST: 'GET_PAYMENT_INTENT_REQUEST',
  GET_PAYMENT_INTENT_SUCCESS: 'GET_PAYMENT_INTENT_SUCCESS',
  GET_PAYMENT_INTENT_ERROR: 'GET_PAYMENT_INTENT_ERROR',
};
