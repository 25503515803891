import { helperShowStep } from 'services/onboardingTour/tourOptions';
import i18n from 'translation/i18n';

const STEP_ID = 'single-scan-examination-step-tab';

export const stepTab = {
  id: STEP_ID,
  attachTo: { element: '.single-scan', on: 'bottom' },
  highlightClass: 'click-button',
  advanceOn: { selector: '.single-scan', event: 'click' },
  beforeShowPromise: () =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        resolve();
      }, 500);
    }),
  canClickTarget: true,
  buttons: [],
  scrollTo: false,
  title: i18n.t('onboarding.show_examination.tab_menu'),
  text: [
    `${i18n.t(
      'onboarding.show_examination.single_scan_text_38'
    )} <span class='highlight'>${i18n.t(
      'onboarding.show_examination.single_scan_text_39'
    )}</span>`,
  ],
  when: {
    show: () => {
      helperShowStep(STEP_ID);
    },
  },
};
