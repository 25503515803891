import { t } from 'i18next';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { loginUser, logout } from 'api/auth';
import {
  resetApp,
  userLoginError,
  userLoginSuccess,
  userLogoutError,
  userLogoutSuccess,
} from 'redux/actions/userAuth';
import {
  getAuthenticatedFalse,
  getAuthenticatedTrue,
} from 'redux/actions/userAuthenticated';
import { userConstants } from 'redux/constants/userAuth';
import { userAuthenticatedConstants } from 'redux/constants/userAuthenticated';
import { cleanCache } from 'utils/apiCache';
import {
  LOCAL_STORAGE_LANGUAGE_KEY,
  LOCALLY_DISABLED_PATHOLOGIES_KEY,
} from 'utils/constants';
import notify from 'utils/toast';

function* userLoginWatcher() {
  yield takeEvery(userConstants.LOGIN_REQUEST, userLoginWorker);
}

function* userLoginWorker({ payload, action }) {
  try {
    const { data } = yield loginUser({
      ...payload,
      email: payload.email.toLowerCase(),
    });
    yield call([localStorage, localStorage.setItem], 'token', data.data.token);
    yield call(
      [localStorage, localStorage.setItem],
      'method',
      userAuthenticatedConstants.LOGGED_BY_MAIL
    );

    yield put(userLoginSuccess());
    yield put(getAuthenticatedTrue(userAuthenticatedConstants.LOGGED_BY_MAIL));
    yield action?.success();
  } catch (error) {
    if (typeof error === 'string') {
      yield notify('error', t('notifications.something_went_wrong'));
      yield put(userLoginError(error));
      return;
    }
    yield put(userLoginError(error));
    yield action.error(error);
  }
}

function* userLogoutWatcher() {
  yield takeEvery(userConstants.LOGOUT_REQUEST, userLogoutWorker);
}

function* userLogoutWorker() {
  try {
    yield logout();

    const apiUrl = localStorage.getItem('api_url');

    //TODO: rewrite all localStorage (on the Project) to sessionStorage and remove this code
    Object.keys(localStorage).forEach((key) => {
      if (
        key !== LOCAL_STORAGE_LANGUAGE_KEY &&
        key !== LOCALLY_DISABLED_PATHOLOGIES_KEY
      ) {
        localStorage.removeItem(key);
      }
    });

    if (process.env.REACT_APP_ENV !== 'production' && apiUrl) {
      localStorage.setItem('api_url', apiUrl);
    }

    yield cleanCache();

    yield put(userLogoutSuccess());

    yield put(getAuthenticatedFalse());

    yield put(resetApp());
  } catch (error) {
    yield put(userLogoutError(error));
    yield notify('error', t('notifications.something_went_wrong'));
  }
}

export default function* authByMailSaga() {
  yield fork(userLoginWatcher);
  yield fork(userLogoutWatcher);
}
