import authByGoogle from './authByGoogle';
import authByInvite from './authByInvite';
import authByMail from './authByMail';
import checkEmail from './checkEmail';
import forgotPassword from './forgotPassword';
import getProfessionAndCountry from './getProfessionsCountriesAndProducers';
import ocuPlanSaga from './sentOcuPlanForm';

export default [
  authByGoogle,
  authByInvite,
  authByMail,
  checkEmail,
  forgotPassword,
  getProfessionAndCountry,
  ocuPlanSaga,
];
