import { useEffect, useRef } from 'react';

import cn from 'classnames';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { ReactComponent as FileIcon } from 'assets/icons/file.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import CustomTooltip from 'components/CustomTooltip';
import FormField from 'components/FormComponents';
import SimpleMessage from 'components/SimpleMessage';
import Text from 'components/Text';
import {
  ACCEPT_FORMAT_FOR_UPLOAD,
  CREATE_EXAMINATION_FLOW,
} from 'utils/constants';
import { generatePatientId } from 'utils/helpers';

import formStyles from 'components/FormComponents/FormField.module.scss';

import styles from '../CreateExamination.module.scss';

/**
 * @param dashboard need for detect from what page we open this modal
 * @param multiple need for detect how many files we can upload
 * @param dicomFolder need for detect what dicom we want to load
 * from dashboard we can upload only two dicom files
 */

const Dicom = ({ dashboard, multiple, accept, dicomFolder }) => {
  const { setFieldValue, values, initialValues } = useFormikContext();

  /**
   * Create local state for saving initial patient when you open modal.
   */
  const initialPatient = useRef(initialValues);

  const { t } = useTranslation(undefined, {
    keyPrefix: 'components.create_examination',
  });

  useEffect(() => {
    setFieldValue('file', null);

    if (dicomFolder) {
      setFieldValue('flow', CREATE_EXAMINATION_FLOW.ZIPPED_DICOM_IN_PATIENT);
    } else {
      setFieldValue('flow', CREATE_EXAMINATION_FLOW.DICOM_IN_PATIENT);
    }

    return () => {
      setFieldValue('patient_from_dicom', false);
    };
  }, [dicomFolder]);

  /**
   * We check what we should to set in "choose patient" select,
   * Placeholder we set when patient_from_dicom checkbox is active
   */
  useEffect(() => {
    setFieldValue(
      'pid',
      values['patient_from_dicom']
        ? generatePatientId()
        : initialPatient.current['pid']
    );
    setFieldValue(
      'patient_id',
      values['patient_from_dicom'] ? '' : initialPatient.current['patient_id']
    );
  }, [values['patient_from_dicom']]);

  return (
    <div className={styles.wrapper}>
      {dashboard && (
        <>
          {dicomFolder && (
            <SimpleMessage Icon={FileIcon} text={t('upload_archived_file')} />
          )}
          <div
            className={cn(
              styles['checkbox-wrapper'],
              'shepherd-patient_from_dicom'
            )}
          >
            <FormField
              component={'checkbox'}
              name={'patient_from_dicom'}
              id={'patient_from_dicom'}
              className={cn(formStyles.classic, styles.checkbox)}
            />

            <div className={styles['text-wrapper']}>
              <Text className={styles.text}>
                {t('create_patient_from_dicom')}
              </Text>
              <CustomTooltip
                position={'top'}
                icon={<InfoIcon />}
                tooltipText={t('create_patient_from_dicom_description')}
              />
            </div>
          </div>
        </>
      )}
      <FormField
        rows={1}
        component={'textarea'}
        name={'note'}
        placeholder={t('notes_placeholder')}
        label={t('notes_label')}
        className={cn(styles.note)}
        labelType={'classic'}
      />
      <FormField
        component={'file-upload'}
        name={'file'}
        label={multiple ? t('multiple_file_label') : t('file_label')}
        tooltip={multiple ? t('multiple_file_description') : null}
        className={'shepherd-upload-dicom'}
        accept={accept || ACCEPT_FORMAT_FOR_UPLOAD.DICOM}
        dicom
        multiple={multiple}
        labelType={'classic'}
        customClass={styles['file-upload']}
      />
    </div>
  );
};

Dicom.displayName = 'Dicom';

export default Dicom;
