import { useEffect, useState } from 'react';

const useLocalStorage = (key: string) => {
  const [localStorageKey, setLocalStorageKey] = useState<string | null>('');

  const checkLocalStorageKey = () => {
    setLocalStorageKey(localStorage.getItem(key));
  };

  useEffect(() => {
    checkLocalStorageKey();

    window.addEventListener('storage', checkLocalStorageKey);

    return () => {
      window.removeEventListener('storage', checkLocalStorageKey);
    };
  }, []);

  return { localStorageKey };
};

export default useLocalStorage;
