import { helperShowStep } from 'services/onboardingTour/tourOptions';
import i18n from 'translation/i18n';

const STEP_ID = 'create-report-step-2';

export const stepTwo = {
  id: STEP_ID,
  attachTo: { element: '.shepherd-smart-report', on: 'right' },
  beforeShowPromise: () =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        resolve();
      }, 500);
    }),
  buttons: [
    {
      classes: 'shepherd-button-primary',
      text: i18n.t('onboarding.create_report.next'),
      type: 'next',
    },
  ],
  canClickTarget: false,
  scrollTo: false,
  title: i18n.t('onboarding.create_report.create_report'),
  text: [
    `<ul class='steps'>
      <li class='step-item'><span class='number'>${i18n.t(
        'onboarding.create_report.step_2_text_1'
      )}</span> ${i18n.t('onboarding.create_report.step_2_text_2')}</li>
      <li class='step-item'><span class='number'>${i18n.t(
        'onboarding.create_report.step_2_text_3'
      )}</span> ${i18n.t('onboarding.create_report.step_2_text_4')}</li>
      <li class='step-item'><span class='number'>${i18n.t(
        'onboarding.create_report.step_2_text_5'
      )}</span> ${i18n.t('onboarding.create_report.step_2_text_6')}</li>
    </ul>`,
  ],
  when: {
    show: () => {
      helperShowStep(STEP_ID);
    },
  },
};
