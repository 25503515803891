import i18n from 'i18next';

import de from './de/translation';
import en from './en/translation';
import es from './es/translation';
import fr from './fr/translation';
import it from './it/translation';
import pt from './pt/translation';

export type Language = typeof en;

const resources = {
  en: {
    translations: en,
  },
  de: {
    translations: de,
  },
  es: {
    translations: es,
  },
  it: {
    translations: it,
  },
  pt: {
    translations: pt,
  },
  fr: {
    translations: fr,
  },
};

// eslint-disable-next-line import/no-named-as-default-member
i18n.init({
  // we init with resources
  resources,
  fallbackLng: 'en',
  ns: ['translations'],
  defaultNS: 'translations',
  // debug: false,
  returnObjects: true,
  keySeparator: '.',
  detection: {
    order: ['localStorage'],
    caches: ['localStorage'],
    lookupLocalStorage: 'lng',
  },
  interpolation: {
    escapeValue: false,
    skipOnVariables: false,
  },
  appendNamespaceToMissingKey: true,
});

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
    nsSeparator: '';
    resources: Record<string, Language>;
  }
}

export default i18n;
