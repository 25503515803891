import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

import { useSelector } from 'react-redux';

import { patientsSelector } from 'redux/selectors/patients';
import { IPatient, IPatientExam } from 'types/patients';

const initialState = {
  searchTerm: '',
  setSearchTerm: () => {},
  selectedExam: [],
  setSelectedExam: () => {},
  filteredPatients: [],
  setFilteredPatients: () => {},
  activePatient: undefined,
  setActivePatient: () => {},
  lastActivePatient: '',
};

interface DashboardState {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  selectedExam: IPatientExam[];
  setSelectedExam: (exams: IPatientExam[]) => void;
  filteredPatients: IPatient[];
  activePatient?: IPatient;
  setActivePatient: (patient: IPatient) => void;
  lastActivePatient: string;
}

const DashboardContext = createContext<DashboardState>(initialState);

export function useDashboardContext() {
  return useContext(DashboardContext);
}

export const DashboardContextProvider = ({ children }: PropsWithChildren) => {
  const {
    data: { patients },
  } = useSelector(patientsSelector);

  /**
   * @params searchTerm, setSearchTerm we use in the src/Header
   * component for live search
   */
  const [searchTerm, setSearchTerm] = useState<string>('');
  /**
   * @params selectedExam, setSelectedExam we use in the
   * src/Dashboard for ScansList component
   */
  const [selectedExam, setSelectedExam] = useState<Array<IPatientExam>>([]);

  /**
   * @params activePatient, setActivePatient we use in the
   * addExaminationModal for set active patient as a default
   */
  const [activePatient, setActivePatient] = useState<IPatient>();
  /**
   * @params lastActivePatient , which we use in PatientList to set
   * the default last active patient whose exam we were looking at
   */
  const lastActivePatient = localStorage.getItem('last_patient') || '';

  /**
   * @params filteredPatients, setFilteredPatients we use in the src/Header
   * component for live search
   */
  const filteredPatients = useMemo(() => {
    const normalizedQuery = searchTerm.trim().toLowerCase();

    return patients.filter((item) => {
      const fullName = `${item.name} ${item.surname}`.toLowerCase();

      return (
        item.name?.toLowerCase().includes(normalizedQuery) ||
        item.surname?.toLowerCase().includes(normalizedQuery) ||
        fullName.includes(normalizedQuery) ||
        item.pid?.toLowerCase().includes(normalizedQuery)
      );
    });
  }, [patients, searchTerm]);

  const contextValue = {
    searchTerm,
    setSearchTerm,
    selectedExam,
    setSelectedExam,
    filteredPatients,
    activePatient,
    setActivePatient,
    lastActivePatient,
  };

  return (
    <DashboardContext.Provider value={contextValue}>
      {children}
    </DashboardContext.Provider>
  );
};

DashboardContext.displayName = 'DashboardContext';
