import { t } from 'i18next';
import { fork, put, select, takeEvery } from 'redux-saga/effects';

import { getPaymentMethod } from 'api/stripe';
import {
  getPaymentMethodError,
  getPaymentMethodSuccess,
} from 'redux/actions/stripe';
import { stripeConstants } from 'redux/constants/stripe';
import { userProfileSelector } from 'redux/selectors/userProfile';
import { USER_ROLES } from 'utils/constants';
import notify from 'utils/toast';

function* getPaymentMethodWatcher() {
  yield takeEvery(
    stripeConstants.GET_PAYMENT_METHOD_REQUEST,
    getPaymentMethodWorker
  );
}

function* getPaymentMethodWorker() {
  const { data } = yield select(userProfileSelector);

  if (data?.role.model === USER_ROLES.B2C) return;

  try {
    const { data: payment_method } = yield getPaymentMethod();

    if (payment_method.status === 'Error') {
      throw new Error(payment_method.message);
    }

    yield put(getPaymentMethodSuccess(payment_method.data));
  } catch (error) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    yield put(getPaymentMethodError(error));
    yield notify('error', errorMessage);
  }
}

export default function* getPaymentMethodSaga() {
  yield fork(getPaymentMethodWatcher);
}
