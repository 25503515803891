import { GoogleOAuthProvider } from '@react-oauth/google';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ShepherdTour } from 'react-shepherd';
import { ToastContainer } from 'react-toastify';

import App from './App';
import i18n from './translation/i18n';

import ErrorFallback from 'components/ErrorFallback';
import ModalRedux from 'components/ModalRedux';
import { store } from 'redux/store';
import { steps, tourOptions } from 'services/onboardingTour';
import { initSentryService } from 'services/sentry';
import { cleanOldCache } from 'utils/apiCache';
import { LOCAL_STORAGE_LANGUAGE_KEY } from 'utils/constants';

import 'react-toastify/dist/ReactToastify.css';

import './assets/fonts/Poppins/Poppins.css';
import './assets/fonts/Rubic/Rubic.css';
import './index.scss';

function initApp(): void {
  cleanOldCache();

  if (
    process.env.REACT_APP_ENV === 'production' ||
    process.env.REACT_APP_ENV === 'development'
  ) {
    initSentryService();
  }

  if (
    process.env.REACT_APP_ENV === 'production' &&
    !location.hostname.startsWith('demo.')
  ) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'ze-snippet';
    script.async = true;
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.REACT_APP_ZENDESK_KEY}`;
    document.getElementsByTagName('body')[0].appendChild(script);
  }

  i18n.changeLanguage(localStorage.getItem(LOCAL_STORAGE_LANGUAGE_KEY) ?? 'en');

  if (location.hostname.startsWith('demo.')) {
    const script = document.createElement('script');
    const noScript = document.createElement('noscript');

    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-PJTQ2723');`;
    noScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PJTQ2723" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

    document.getElementsByTagName('head')[0].appendChild(script);
    document.getElementsByTagName('body')[0].appendChild(noScript);
  }

  const container = document.getElementById('root');

  if (!container) {
    return;
  }

  if (
    process.env.REACT_APP_ENV !== 'production' &&
    localStorage.getItem('api_url') == null
  ) {
    localStorage.setItem('api_url', '');
  }

  const root = createRoot(container);

  root.render(
    <Provider store={store}>
      <Router>
        <I18nextProvider i18n={i18n}>
          <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_API}`}>
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onReset={() => window.location.reload()}
            >
              {/* @ts-ignore */}
              <ShepherdTour steps={steps} tourOptions={tourOptions}>
                <App />
                <ModalRedux />
                <ToastContainer />
              </ShepherdTour>
            </ErrorBoundary>
          </GoogleOAuthProvider>
        </I18nextProvider>
      </Router>
    </Provider>
  );
}

initApp();
