import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

const useTabsHeading = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'dashboard.patient_list',
  });
  const tabsHeading = useMemo(
    () => [
      { id: 1, name: 'pid', title: t('header.patient_id') },
      { id: 2, name: 'name', title: t('header.patient_name') },
      { id: 3, name: 'birth_date', title: t('header.birth_date') },
      { id: 4, name: 'gender', title: t('header.gender') },
      { id: 5, name: 'last_examination_date', title: t('header.last_upload') },
      { id: 6, name: 'severity_level', title: t('header.severity_level') },
      { id: 7, name: 'description', title: t('header.description') },
    ],
    []
  );

  return tabsHeading;
};

export default useTabsHeading;
