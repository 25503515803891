import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { sendSurvey } from 'api/survey';
import { sendSurveyError, sendSurveySuccess } from 'redux/actions/survey';
import { surveyTypes } from 'redux/constants/survey';
import { IUnsubscribeSurveyData } from 'types/survey';
import { bookingMeetingModal } from 'utils/openModals';
import notify from 'utils/toast';

function* sendSurveyWatcher() {
  yield takeEvery(surveyTypes.SEND_SURVEY_REQUEST, sendSurveyWorker);
}

function* sendSurveyWorker({
  payload,
}: {
  payload: IUnsubscribeSurveyData;
  type: surveyTypes.SEND_SURVEY_REQUEST;
}) {
  try {
    const { data: survey } = yield sendSurvey(payload);

    if (survey.status === 'Error') {
      throw new Error(survey.message);
    }

    yield put(sendSurveySuccess());
    yield bookingMeetingModal();

    yield notify('success', t('notifications.settings_saved'));
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    yield put(sendSurveyError(error));
    yield notify('error', errorMessage);
  }
}

export default function* sendSurveySaga() {
  yield fork(sendSurveyWatcher);
}
