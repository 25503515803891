import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { updatePatient } from 'api/patient';
import { closeModal } from 'redux/actions/modal';
import {
  getPatientRequest,
  updatePatientError,
  updatePatientSuccess,
} from 'redux/actions/patient';
import { patientConstants } from 'redux/constants/patient';
import {
  IActionUpdatePatient,
  IUpdatePatient,
  PatientsTypes,
} from 'types/patients';
import notify from 'utils/toast';

interface IUpdatePatientWorker {
  type: PatientsTypes;
  payload: {
    id: number;
    data: IUpdatePatient;
  };
  action: IActionUpdatePatient;
}

function* updatePatientWatcher() {
  yield takeEvery(patientConstants.UPDATE_PATIENT_REQUEST, updatePatientWorker);
}

function* updatePatientWorker({ payload, action }: IUpdatePatientWorker) {
  try {
    const { id, data } = payload;

    const { data: createdPatient } = yield updatePatient(id, { ...data });

    yield put(getPatientRequest());
    yield put(updatePatientSuccess());
    yield notify('success', t('notifications.patient_updated_successfully'));

    /**
     * Setting a new patient as a default in the examinationModal
     * form, after clicking Add examination in the createPatientModal
     */
    if (action) {
      yield action?.addExaminationModal(createdPatient.data);
      return;
    }
    if (createdPatient.status === 'Error') {
      throw new Error(createdPatient.message);
    }

    yield put(closeModal());
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    yield put(updatePatientError(error));
    yield notify('error', errorMessage);
    yield put(closeModal());
  }
}

export default function* updatePatientSaga() {
  yield fork(updatePatientWatcher);
}
