import { patientConstants } from 'redux/constants/patient';
import { PatientExamDataType } from 'types/examination';
import {
  IActionCreatePatient,
  IActionUpdatePatient,
  ICreatePatient,
  IFilterPatient,
  IPatientData,
  IUpdatePatient,
} from 'types/patients';
// CREATE PATIENT
export const createPatientRequest = (
  data: ICreatePatient,
  action?: IActionCreatePatient
) => ({
  type: patientConstants.CREATE_PATIENT_REQUEST,
  payload: data,
  action: action,
});

export const createPatientSuccess = () => ({
  type: patientConstants.CREATE_PATIENT_SUCCESS,
});

export const createPatientError = (error: unknown | string) => ({
  type: patientConstants.CREATE_PATIENT_ERROR,
  payload: error,
});

// GET PATIENT
export const getPatientRequest = () => ({
  type: patientConstants.GET_PATIENT_REQUEST,
});

export const getPatientSuccess = (data: IPatientData) => ({
  type: patientConstants.GET_PATIENT_SUCCESS,
  payload: data,
});

export const getPatientError = (error: unknown | string) => ({
  type: patientConstants.GET_PATIENT_ERROR,
  payload: error,
});

// UPDATE PATIENT
export const updatePatientRequest = (
  data: { id: number; data: IUpdatePatient },
  action?: IActionUpdatePatient
) => ({
  type: patientConstants.UPDATE_PATIENT_REQUEST,
  payload: data,
  action: action,
});

export const updatePatientSuccess = () => ({
  type: patientConstants.UPDATE_PATIENT_SUCCESS,
});

export const updatePatientError = (error: unknown | string) => ({
  type: patientConstants.UPDATE_PATIENT_ERROR,
  payload: error,
});

// DELETE PATIENT
export const deletePatientRequest = (data: number) => ({
  type: patientConstants.DELETE_PATIENT_REQUEST,
  payload: data,
});

export const deletePatientSuccess = () => ({
  type: patientConstants.DELETE_PATIENT_SUCCESS,
});

export const deletePatientError = (error: unknown | string) => ({
  type: patientConstants.DELETE_PATIENT_ERROR,
  payload: error,
});

// GET EXAMINATIONS OF CURRENT PATIENT
export const getPatientExamRequest = (data: number) => ({
  type: patientConstants.GET_PATIENT_EXAMINATIONS_REQUEST,
  payload: data,
});

export const getPatientExamSuccess = (data: PatientExamDataType) => ({
  type: patientConstants.GET_PATIENT_EXAMINATIONS_SUCCESS,
  payload: data,
});

export const getPatientExamError = (error: unknown | string) => ({
  type: patientConstants.GET_PATIENT_EXAMINATIONS_ERROR,
  payload: error,
});

// FILTER PATIENT
export const filterPatientRequest = (data: IFilterPatient) => ({
  type: patientConstants.FILTER_PATIENT_REQUEST,
  payload: data,
});

export const filterPatientSuccess = (data: IPatientData) => ({
  type: patientConstants.FILTER_PATIENT_SUCCESS,
  payload: data,
});

export const filterPatientError = (error: unknown | string) => ({
  type: patientConstants.FILTER_PATIENT_ERROR,
  payload: error,
});
