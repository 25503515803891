import createUserBackupSaga from './createUserBackup';
import deleteUserBackupSaga from './deleteUserBackup';
import getUserBackupSaga from './getUserBackup';
import verifyCodeSaga from './verifyCode';

export default [
  createUserBackupSaga,
  getUserBackupSaga,
  deleteUserBackupSaga,
  verifyCodeSaga,
];
