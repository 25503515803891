import { scrollbarStyle } from 'components/FormComponents/SelectInput/scrollbarStyle';

const customStyles = {
  control: (base, state) => ({
    ...base,
    background: '#EAEAEF',
    borderRadius: '8px',
    border: 'none',
    minHeight: window.innerWidth <= 1439 ? '48px' : '56px',
    textAlign: 'initial',
    fontSize: 12,
    paddingLeft: state.isSelected ? '35px' : '35px',
    boxShadow: '0 0 0 1px var(--theme-color-dark)',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: (base) => ({
    ...base,
    background: '#EAEAEF',
    padding: 10,
    margin: 0,
    fontSize: 12,
  }),
  option: (base, state) => ({
    ...base,
    background: state.isSelected ? 'var(--white-ice-color)' : 'transparent',
    color: '#000',
    textAlign: 'initial',
    padding: 13,
    '&:hover': {
      background: 'var(--white-ice-color)',
    },
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: 230,
    fontSize: 12,
    ...scrollbarStyle,
  }),
  singleValue: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? 0.5 : 1,
    transition: 'opacity 300ms',
    fontSize: 12,
  }),
};

export default customStyles;
