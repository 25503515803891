/**
 * @param
 *
 *
 */

import { memo } from 'react';

import cn from 'classnames';

import { IButtonProps } from './Button.types';

import HistoryLink from 'components/HistoryLink';

import styles from './Button.module.scss';

const Button = memo(
  ({
    appearance = 'primary',
    children,
    className,
    to,
    color = 'white',
    withShadow,
    round,
    size,
    type = 'submit',
    innerRef,
    ...props
  }: IButtonProps): JSX.Element => {
    const generalClasses = {
      [styles[appearance]]: appearance,
      [styles['light-font']]: color == 'light',
      [styles['dark-font']]: color == 'dark',
      [styles.small]: size == 'small',
      [styles.round]: round,
      [styles.shadow]: withShadow,
    };
    return (
      <>
        {to ? (
          <HistoryLink
            className={cn(styles.button, generalClasses, className)}
            to={to}
            title={children}
            {...props}
          >
            {children}
          </HistoryLink>
        ) : (
          <button
            ref={innerRef}
            type={type}
            className={cn(styles.button, generalClasses, className)}
            {...props}
          >
            {children}
          </button>
        )}
      </>
    );
  }
);

Button.displayName = 'Button';

export default Button;
