import { helperShowStep } from 'services/onboardingTour/tourOptions';
import i18n from 'translation/i18n';

const STEP_ID = 'measurements-examination-step-7';

export const stepSeven = {
  id: STEP_ID,
  attachTo: {
    element: '.segmentation-volume',
    on: 'top',
  },
  advanceOn: { selector: '.segmentation-volume', event: 'click' },
  beforeShowPromise: () =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, 500);
    }),
  highlightClass: 'click-button',
  buttons: [],
  scrollTo: false,
  title: i18n.t('onboarding.show_examination.tab_menu'),
  text: [
    `${i18n.t(
      'onboarding.show_examination.measurements_text_35'
    )} <span class='highlight'>${i18n.t(
      'onboarding.show_examination.measurements_text_36'
    )}</span>
    <p class="next-step">${i18n.t(
      'onboarding.show_examination.measurements_text_37'
    )}</p>`,
  ],
  when: {
    show: () => {
      helperShowStep(STEP_ID);
    },
  },
};
