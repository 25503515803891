import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { checkUserEmail } from 'api/userProfile';
import {
  checkUserEmailError,
  checkUserEmailSuccess,
} from 'redux/actions/userAuth';
import { userConstants } from 'redux/constants/userAuth';
import notify from 'utils/toast';

function* userCheckEmailWatcher() {
  yield takeEvery(userConstants.CHECK_EMAIL_REQUEST, userCheckEmailWorker);
}

function* userCheckEmailWorker({ payload, action }) {
  try {
    const { data: checkEmail } = yield checkUserEmail({
      email: payload.toLowerCase(),
    });
    yield put(checkUserEmailSuccess());

    if (checkEmail.status === 'Error') {
      throw new Error(checkEmail.message);
    }

    /**
     * the action parameter will change the form
     * step after confirming the account registration
     * in registerUser component
     */
    yield action.setCurrentStep(action.currentStep + 1);
  } catch (error) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    if (typeof error === 'string') {
      yield notify('error', errorMessage);
      yield put(checkUserEmailError());

      return;
    }

    yield action.error(error);
    yield put(checkUserEmailError());
  }
}

export default function* checkEmailSaga() {
  yield fork(userCheckEmailWatcher);
}
