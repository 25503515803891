import { useCallback, useRef, useState } from 'react';

import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { demoReSendEmail, demoSendVerificationCode } from 'api/demo';
import Button from 'components/Button';
import FormField from 'components/FormComponents';
import { VerificationCodeInputControls } from 'components/FormComponents/VerificationCodeInput/VerificationCodeInput';
import SpinnerSVG from 'components/SpinnerSVG';
import Text from 'components/Text';
import { getAuthenticatedTrue } from 'redux/actions/userAuthenticated';
import { DEMO_ACCOUNT_GOOGLE_ANALYTICS } from 'utils/constants';
import { ga } from 'utils/helpers';
import notify from 'utils/toast';

import styles from './SecondStep.module.scss';

interface SecondStepProps {
  email: string;
  onNextStep: () => void;
}

const SecondStep = ({ email, onNextStep }: SecondStepProps) => {
  const { t, i18n } = useTranslation(undefined, {
    keyPrefix: 'demo_user_registration.second_step',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabledResendCode, setIsDisabledResendCode] = useState(false);
  const [resendCodeSecondsLeft, setResendCodeSecondsLeft] = useState(0);

  const resetCodeIntervalIdRef = useRef<NodeJS.Timer>();
  const verificationCodeInputControlsRef =
    useRef<VerificationCodeInputControls>(null);

  const dispatch = useDispatch();

  const onFormSubmit = useCallback(
    async (code: string) => {
      setIsSubmitting(true);

      try {
        const data = await demoSendVerificationCode({ code, email });

        // @ts-ignore
        if (data?.data?.data?.[0] != null) {
          // @ts-ignore
          localStorage.setItem('token', data.data.data[0]);

          dispatch(getAuthenticatedTrue('demo'));

          ga(DEMO_ACCOUNT_GOOGLE_ANALYTICS.REGISTRATION_S1);
        } else {
          onNextStep();
          ga(DEMO_ACCOUNT_GOOGLE_ANALYTICS.REGISTRATION_S1_FIRST_TIME);
        }
      } catch (error) {
        notify('error', String(error));
        verificationCodeInputControlsRef.current?.reset();
      } finally {
        setIsSubmitting(false);
      }
    },
    [email, onNextStep]
  );

  const handleResendCode = useCallback(async () => {
    setIsSubmitting(true);

    try {
      await demoReSendEmail(email);

      notify('success', i18n.t('notifications.resent_confirmation_email'));
    } catch (error) {
      notify('error', String(error));
    } finally {
      setIsSubmitting(false);

      setIsDisabledResendCode(true);
      verificationCodeInputControlsRef.current?.reset();

      if (!resetCodeIntervalIdRef.current) {
        setResendCodeSecondsLeft(30);

        resetCodeIntervalIdRef.current = setInterval(() => {
          setResendCodeSecondsLeft((prev) => {
            if (prev === 0) {
              clearInterval(resetCodeIntervalIdRef.current);
              setIsDisabledResendCode(false);
              resetCodeIntervalIdRef.current = undefined;

              return 0;
            }

            return prev - 1;
          });
        }, 1000);
      }
    }
  }, [email]);

  return (
    <>
      <div className={styles['header-wrapper']}>
        <Text className={styles.title} color='light' tag='h1' align='center'>
          {t('enter_security_code')}
        </Text>

        <Text className={styles.subtitle} color='light' align='center'>
          {t('send_to')}
          <br />
          <strong>{email}</strong>
        </Text>
      </div>

      <Formik
        initialValues={{ verificationCode: '' }}
        onSubmit={({ verificationCode }) => {
          onFormSubmit(verificationCode);
        }}
      >
        {({ values }) => (
          <Form className={styles.form}>
            <SpinnerSVG
              className={styles.spinner}
              active={isSubmitting}
              fillColor={'var(--theme-color)'}
            />

            <FormField
              component={'verification-code-input'}
              name={'verificationCode'}
              id={'verificationCode'}
              inputLength={4}
              autoFocus
              className={styles['verification-number']}
              controlsRef={verificationCodeInputControlsRef}
            />

            <Button
              type={'submit'}
              disabled={values.verificationCode.length !== 4}
              className={styles.button}
            >
              {t('submit')}
            </Button>

            <button
              onClick={handleResendCode}
              type={'button'}
              className={styles.resend}
              disabled={isDisabledResendCode}
            >
              {t('resend_code')}{' '}
              {isDisabledResendCode
                ? `(${resendCodeSecondsLeft} ${t('seconds_short')})`
                : ''}
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SecondStep;
