import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { sendContactUsForm } from 'api/contactUs';
import {
  sendContactUsError,
  sendContactUsSuccess,
} from 'redux/actions/contactUs';
import { contactUsTypes } from 'redux/constants/contactUs';
import { IContactUsDataAction } from 'types/contactUs';
import notify from 'utils/toast';

function* sentContactUsFormWatcher() {
  yield takeEvery(
    contactUsTypes.SEND_CONTACT_US_REQUEST,
    sentContactUsFormWorker
  );
}

function* sentContactUsFormWorker({
  payload: { first_name, email, message, file },
  action,
}: IContactUsDataAction) {
  try {
    const formData = new FormData();
    formData.append('first_name', first_name);
    formData.append('email', email);
    formData.append('message', message);

    if (file) {
      formData.append('file', file);
    }

    yield sendContactUsForm(formData);
    yield put(sendContactUsSuccess());
    yield action.resetForm();

    yield notify('success', t('notifications.your_request_sent_successfully'));
  } catch (error: unknown | string) {
    yield put(sendContactUsError(error));
    yield notify('error', t('notifications.something_went_wrong'));
  }
}

export default function* sentContactUsFormSaga() {
  yield fork(sentContactUsFormWatcher);
}
