import { teamMemberConstants } from 'redux/constants/teamMember';
import {
  ITeamMemberData,
  ITeamMemberInvite,
  ITeamMemberInviteActions,
} from 'types/teamMember';
// CREATE TEAM_MEMBER
export const inviteTeamMemberRequest = (
  data: ITeamMemberInvite,
  action: ITeamMemberInviteActions
) => ({
  type: teamMemberConstants.INVITE_TEAM_MEMBER_REQUEST,
  payload: data,
  action: action,
});

export const inviteTeamMemberSuccess = () => ({
  type: teamMemberConstants.INVITE_TEAM_MEMBER_SUCCESS,
});

export const inviteTeamMemberError = (error: unknown | string) => ({
  type: teamMemberConstants.INVITE_TEAM_MEMBER_ERROR,
  payload: error,
});

// GET ALL TEAM_MEMBERS
export const getAllTeamMembersRequest = () => ({
  type: teamMemberConstants.GET_ALL_TEAM_MEMBERS_REQUEST,
});

export const getAllTeamMembersSuccess = (data: ITeamMemberData) => ({
  type: teamMemberConstants.GET_ALL_TEAM_MEMBERS_SUCCESS,
  payload: data,
});

export const getAllTeamMembersError = (error: unknown | string) => ({
  type: teamMemberConstants.GET_ALL_TEAM_MEMBERS_ERROR,
  payload: error,
});

// DELETE TEAM_MEMBER
export const deleteTeamMemberRequest = (data: number) => ({
  type: teamMemberConstants.DELETE_TEAM_MEMBER_REQUEST,
  payload: data,
});

export const deleteTeamMemberSuccess = () => ({
  type: teamMemberConstants.DELETE_TEAM_MEMBER_SUCCESS,
});

export const deleteTeamMemberError = (error: unknown | string) => ({
  type: teamMemberConstants.DELETE_TEAM_MEMBER_ERROR,
  payload: error,
});

// REINVITE TEAM_MEMBER
export const reinviteTeamMemberRequest = (data: number) => ({
  type: teamMemberConstants.REINVITE_TEAM_MEMBER_REQUEST,
  payload: data,
});

export const reinviteTeamMemberSuccess = () => ({
  type: teamMemberConstants.REINVITE_TEAM_MEMBER_SUCCESS,
});

export const reinviteTeamMemberError = (error: unknown | string) => ({
  type: teamMemberConstants.REINVITE_TEAM_MEMBER_ERROR,
  payload: error,
});
