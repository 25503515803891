import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { updateMeasManualScan } from 'api/examination';
import { OverlayLine } from 'pages/Examination/Examination.types';
import {
  updateExamMeasManualError,
  updateExamMeasManualSuccess,
} from 'redux/actions/examination';
import { ExamActionTypes } from 'redux/constants/examination';
import { IWorkerProps } from 'redux/types/examination';
import notify from 'utils/toast';

function* updateMeasManualLayerWatcher() {
  yield takeEvery(
    ExamActionTypes.UPDATE_EXAM_MEASUREMENTS_MANUAL_REQUEST,
    measurementsWorker
  );
}

interface IUpdateMeasManualWorker extends IWorkerProps {
  type: ExamActionTypes;
  payload: {
    exam_id: string;
    scan_id: string;
    lines: OverlayLine[];
  };
}

function* measurementsWorker({ payload, action }: IUpdateMeasManualWorker) {
  action?.default?.();

  try {
    const { exam_id, scan_id, lines } = payload;

    yield updateMeasManualScan({
      exam_id,
      scan_id,
      lines,
    });

    yield put(updateExamMeasManualSuccess(lines));

    action?.success?.();
  } catch (error) {
    action?.error?.();
    yield notify('error', t('notifications.something_went_wrong'));
    yield put(
      updateExamMeasManualError({
        slug: 'something_went_wrong',
        message: 'Something went wrong',
      })
    );
  }
}

export default function* updateMeasManualLayerSaga() {
  yield fork(updateMeasManualLayerWatcher);
}
