import { DEMO } from './endpoints';

import fetchData from 'utils/fetchData';

export const demoSendEmail = async (email: string) =>
  fetchData({
    method: 'post',
    to: DEMO.SEND_EMAIL,
    data: { email },
  });

export const demoReSendEmail = async (email: string) =>
  fetchData({
    method: 'post',
    to: DEMO.RE_SEND_CODE,
    data: { email },
  });

export const demoSendVerificationCode = async (data: {
  email: string;
  code: string;
}) =>
  fetchData({
    method: 'post',
    to: DEMO.VERIFICATION_CODE,
    data,
  });

export const demoSignUp = async (data: {
  email: string;
  name: string;
  profession_id: number;
  country_id: number;
  oct_producer_id: string;
  is_decision_maker: number;
  promo_code: string;
}) =>
  fetchData({
    method: 'post',
    to: DEMO.SIGN_UP,
    data,
  });
