import { useRef } from 'react';

import cn from 'classnames';
import { useFormikContext } from 'formik';
import Select, { components } from 'react-select';

import { scrollbarStyle } from './scrollbarStyle';

import { ReactComponent as CheckMarkIcon } from 'assets/icons/check-mark.svg';
import SeverityLevel from 'components/SeverityLevel/SeverityLevel';

import styles from '../FormField.module.scss';
import stylesIcon from './Select.module.scss';

const Option = (props) => (
  <components.Option
    {...props}
    className={cn(stylesIcon.option, {
      [stylesIcon['options-with-check-mark']]: !!props.withCheckMark,
    })}
  >
    <div
      className={cn(stylesIcon['option-wrapper'], {
        [stylesIcon.small]: props.small,
      })}
    >
      <div className={cn(stylesIcon.icon, { [stylesIcon.small]: props.small })}>
        {props.data.icon && props.data.icon}
        {props.data.severity_level && (
          <SeverityLevel level={props.data.severity_level} />
        )}
      </div>

      {props.data.label}
    </div>

    {props.isSelected && !props.small && (
      <div className={stylesIcon['check-mark']}>
        <CheckMarkIcon />
      </div>
    )}
  </components.Option>
);

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <div className={stylesIcon.option}>
      <div className={stylesIcon['option-wrapper']}>
        <div
          className={cn(stylesIcon.icon, { [stylesIcon.small]: props.small })}
        >
          {props.data.icon && props.data.icon}
          {props.data.severity_level && (
            <SeverityLevel
              level={props.data.severity_level}
              type='examination'
            />
          )}
        </div>

        {!props.small && children}
      </div>
    </div>
  </components.SingleValue>
);

const defaultStyles = {
  control: (base) => ({
    ...base,
    background: 'white',
    borderRadius: '6px',
    border: 'none',
    minHeight: 38,
    minWidth: 200,
    textAlign: 'initial',
    paddingLeft: 8,
    boxShadow: 'none',
    flexDirection: 'row !important',

    '&:hover': {
      cursor: 'pointer',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: (base) => ({
    ...base,
    background: '#fff',
    padding: 0,
    margin: 0,
    zIndex: 999,
    maxHeight: 300,
  }),
  option: (base, state) => ({
    ...base,
    background: state.isSelected ? 'var(--white-ice-color)' : 'transparent',
    color: '#000',
    borderBottom: '1px solid #FFF',
    textAlign: 'initial',
    padding: '9.5px 13px',
    paddingLeft: 16,
    fontSize: 13,

    '&:hover': {
      cursor: 'pointer',
      background: 'var(--white-ice-color)',
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    color: '#3f4254',
    fontSize: 14,
    fontWeight: 400,
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: 300,
    ...scrollbarStyle,
  }),
  singleValue: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? 0.5 : 1,
    transition: 'opacity 300ms',
    fontSize: 14,
  }),
};

const SelectInput = (props) => {
  let {
    name,
    options,
    value,
    customStyles,
    placeholder,
    onChange,
    disabled,
    label,
    labelType,
    id,
    getOptionLabel,
    getOptionValue,
    isSearchable = false,
    isMulti = false,
    withSeverities = false,
    withIcons = false,
    withCheckMarkOnSelectedOption = false,
    inputClassName,
    small = false,
  } = props;
  const { values } = useFormikContext();
  const selectRef = useRef();
  const defaultValue = (options, value) => {
    return options ? options.find((item) => item.value === value) : '';
  };

  return (
    <>
      <Select
        ref={selectRef}
        name={name}
        isMulti={isMulti}
        isDisabled={disabled}
        isSearchable={isSearchable}
        getOptionLabel={
          getOptionLabel ? getOptionLabel : (option) => option.label
        }
        {...((withSeverities || withIcons) && {
          components: {
            Option: (props) => {
              return (
                <Option
                  withCheckMark={withCheckMarkOnSelectedOption}
                  small={small}
                  {...props}
                />
              );
            },
            SingleValue: (props) => <SingleValue small={small} {...props} />,
          },
        })}
        getOptionValue={
          getOptionValue ? getOptionValue : (option) => option.value
        }
        options={options}
        styles={customStyles ? customStyles : defaultStyles}
        value={
          value === ''
            ? null
            : value
            ? defaultValue(options, value)
            : values?.[value]
        }
        onChange={(value) => {
          if (value) {
            onChange(value);
          }
        }}
        placeholder={placeholder}
        classNamePrefix={'react-select'}
        className={cn(styles.select, inputClassName)}
      />
      <label
        className={cn(styles.label, {
          [styles[labelType]]: labelType,
        })}
        htmlFor={id}
      >
        {label}
      </label>
    </>
  );
};

export default SelectInput;
