import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { getAllTeamMembers } from 'api/teamMember';
import {
  getAllTeamMembersError,
  getAllTeamMembersSuccess,
} from 'redux/actions/teamMember';
import { teamMemberConstants } from 'redux/constants/teamMember';
import notify from 'utils/toast';

function* getAllTeamMembersWatcher() {
  yield takeEvery(
    teamMemberConstants.GET_ALL_TEAM_MEMBERS_REQUEST,
    getAllTeamMembersWorker
  );
}

function* getAllTeamMembersWorker() {
  try {
    const { data: allTeamMembers } = yield getAllTeamMembers();
    if (allTeamMembers.status === 'Error') {
      throw new Error(allTeamMembers.message);
    }
    yield put(getAllTeamMembersSuccess(allTeamMembers.data));
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    yield put(getAllTeamMembersError(error));
    yield notify('error', errorMessage);
  }
}

export default function* getAllTeamMembersSaga() {
  yield fork(getAllTeamMembersWatcher);
}
