import { userBackupTypes } from 'redux/constants/userBackup';
import { IUserBackupState, userBackupActions } from 'types/userBackup';
import { USER_BACKUP_STATUS } from 'utils/constants';

const initialState: IUserBackupState = {
  requesting: true,
  errors: null,
  data: {
    status: USER_BACKUP_STATUS.INCOMPLETE,
    link: null,
    progress: 0,
    expire_at: null,
  },
  /** State for confirmation block */
  codeRequesting: false,
};

const userBackupDataReducer = (
  state = initialState,
  action: userBackupActions
): IUserBackupState => {
  switch (action.type) {
    case userBackupTypes.CREATE_BACKUP_REQUEST:
      return {
        ...state,
        errors: null,
      };
    case userBackupTypes.CREATE_BACKUP_SUCCESS:
      return {
        ...state,
        requesting: false,
        data: action.payload,
        errors: null,
      };
    case userBackupTypes.CREATE_BACKUP_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };

    case userBackupTypes.CONFIRMATION_BY_CODE_SHOW:
      return {
        ...state,
        codeRequesting: true,
      };
    case userBackupTypes.CONFIRMATION_BY_CODE_HIDE:
      return {
        ...state,
        codeRequesting: false,
      };

    case userBackupTypes.VERIFY_CODE_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case userBackupTypes.VERIFY_CODE_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
        codeRequesting: false,
      };
    case userBackupTypes.VERIFY_CODE_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };

    case userBackupTypes.GET_BACKUP_REQUEST:
      return {
        ...state,
        errors: null,
      };
    case userBackupTypes.GET_BACKUP_SUCCESS:
      return {
        ...state,
        requesting: false,
        data: action.payload,
        errors: null,
      };
    case userBackupTypes.GET_BACKUP_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };

    case userBackupTypes.DELETE_BACKUP_REQUEST:
      return {
        ...state,
        requesting: true,
      };
    case userBackupTypes.DELETE_BACKUP_SUCCESS:
      return {
        ...state,
        requesting: false,
        data: action.payload,
        errors: null,
      };
    case userBackupTypes.DELETE_BACKUP_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default userBackupDataReducer;
