import { useLayoutEffect, useState } from 'react';

import { singletonHook } from 'react-singleton-hook';

const useDynamicViewportSize = singletonHook(
  { vh: window.innerHeight, vw: window.innerWidth },
  () => {
    const [sizes, setSizes] = useState({
      vh: window.innerHeight,
      vw: window.innerWidth,
    });

    useLayoutEffect(() => {
      const updateSize = () => {
        setSizes({ vh: window.innerHeight, vw: window.innerWidth });
      };

      updateSize();

      window.addEventListener('resize', updateSize);

      return () => {
        window.removeEventListener('resize', updateSize);
      };
    }, []);

    return sizes;
  }
);

export default useDynamicViewportSize;
