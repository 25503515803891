/**
 *
 * @description DateFormatter - date formatter that can help you convert date.
 */

export enum TIME_FORMAT {
  DD_MM_YYYY = 'dd/mm/yyyy',
  MM_DD_YYYY = 'mm/dd/yyyy',
  YYYY_MM_DD = 'yyyy/mm/dd',
}

export class DateFormatter {
  private static getUTCTimestampFromLocalDate(date?: Date) {
    return new Date(
      (date ? date : new Date())
        .toUTCString()
        .split(' ')
        .slice(0, -1)
        .toString()
    ).getTime();
  }

  /**
   * @description Method that return you timestamp in UTC (Timezone 0)
   */
  static getUTCtimestamp(date?: Date): number {
    const localDate = (date ? date : new Date()).getTime();
    const utcDate = this.getUTCTimestampFromLocalDate(date);

    return localDate + Math.round(localDate - utcDate);
  }

  static getCurrentUTCtimestamp() {
    return this.getUTCtimestamp();
  }

  static getTodayUTCtimestamp() {
    const today = new Date();

    today.setHours(0, 0, 0, 0);

    return this.getUTCtimestamp(today);
  }

  /**
   * Gets the formatted date based on the specified format and separator.
   *
   * @param {string} format - the format of the date
   * @param {number | Date} date - the date to format (timestamp in seconds!!!!)
   * @param {string} separator - the separator to use in the formatted date
   * @return {string} the formatted date or 'N/A' for invalid date
   */
  static getDate(format: string, date?: number | Date | null, separator = '/') {
    if (!date) return 'N/A';

    const newDate = new Date(date instanceof Date ? date : +date * 1000);

    if (isNaN(+newDate)) return 'N/A';

    const day = newDate.getUTCDate().toString().padStart(2, '0');
    const month = (newDate.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = newDate.getUTCFullYear().toString();

    switch (format as TIME_FORMAT) {
      case TIME_FORMAT.MM_DD_YYYY:
        return `${month}${separator}${day}${separator}${year}`;

      case TIME_FORMAT.YYYY_MM_DD:
        return `${year}${separator}${month}${separator}${day}`;

      case TIME_FORMAT.DD_MM_YYYY:
      default:
        return `${day}${separator}${month}${separator}${year}`;
    }
  }
}
