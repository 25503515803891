import { mockCompareBoth } from './mockCompareBoth';
import { mockCompareComparison } from './mockCompareComparison';
import { mockCompareProgress } from './mockCompareProgress';
import { mockETDRS } from './mockETDRS';
import { mockMeasurements } from './mockMeasurements';
import { mockScans } from './mockScans';
import { mockSegmentation } from './mockSegmentation';
import { mockSingleLayers } from './mockSingleLayers';

import { SeverityLevelType } from 'types/editProfile';
import { ExaminationStateSchema } from 'types/examination';
import { ICreatePatient } from 'types/patients';

export const MOCK_EXAM_ID = 'examination_id';

export const createMockPatient = (patient: ICreatePatient) => ({
  examinations_left: 2,
  patients: [
    {
      birth_date: patient.birth_date,
      description: patient.description,
      examinations: [],
      gender: patient.gender,
      id: 1408,
      last_examination_date: null,
      medic_id: 109,
      name: patient.name,
      severity_level: '',
      pid: patient.pid,
      related_medic: 'Test Medic',
      surname: patient.surname,
    },
  ],
});

export const createMockExamination = () => ({
  examinations_left: 2,
  patients: [
    {
      birth_date: 1174893945,
      description: 'this is you description',
      examinations: [
        {
          created_at: 1174893945,
          creator_name: 'Artem Rudenko',
          direction: 'Horizontal',
          examination_date: 1174893945,
          examination_status: 'finished',
          examinations_left: 182,
          eye: 'OS',
          id: MOCK_EXAM_ID,
          measurements_status: 'finished',
          medic_id: 109,
          medic_name: 'Artem Rudenko',
          note: null,
          patient_birth_date: 1174893945,
          patient_id: 992,
          patient_gender: 'Male',
          patient_name: 'Alex Cooper',
          pid: 'AUTO1675424112879',
          segmentation_layers_status: 'finished',
          segmentations_status: 'finished',
          total_scans: 128,
          source_type: 'dicom',
          severity_level: SeverityLevelType.GREEN,
          width: '12mm',
        },
      ],
      gender: 'Male',
      id: 1408,
      last_examination_date: null,
      medic_id: 109,
      name: 'Alex',
      pid: 'AUTO1678814175976',
      severity_level: 'green',
      related_medic: 'Artem Rudenko',
      surname: 'Cooper',
    },
  ],
});

export const createMockFullExaminationData = (): ExaminationStateSchema => ({
  requesting: false,
  errors: null,
  showHeatmap: false,
  data: {
    etdrs: mockETDRS(),
    heatmap: undefined,
    id: MOCK_EXAM_ID,
    eye: 'OS',
    width: '5mm',
    examination_date: 1174893945,
    examination_status: 'finished',
    segmentations_status: 'finished',
    segmentation_layers_status: 'finished',
    measurements_status: 'finished',
    patient_id: 1313,
    pid: 'AUTO1677745919057',
    patient_name: 'Alex',
    patient_surname: 'Cooper',
    patient_birth_date: 1174893945,
    patient_gender: 'Male',
    medic_id: 109,
    medic_name: 'Artem Rudenko',
    total_scans: 12,
    created_at: 1174893945,
    direction: 'Horizontal',
    note: null,
    no_pathology: false,
    is_dicom: true,
    able_to_get_statistics: true,
    has_default_etdrs_statistics: true,
    scans: mockScans(),
  },
  singleLayers: mockSingleLayers(),
  measurements: mockMeasurements(),
  compareBoth: mockCompareBoth(),
  compareComparison: mockCompareComparison(),
  compareProgress: mockCompareProgress(),
  scanSegmentation: mockSegmentation(),
  segmentationReport: undefined,
  glaucoma: {
    isRequesting: false,
    isUnavailable: false,
  },
});
