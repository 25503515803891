import { CONTACT_US } from './endpoints';

import fetchData from 'utils/fetchData';

export const sendContactUsForm = async (data: FormData) =>
  fetchData({
    method: 'post',
    to: CONTACT_US.SEND_FORM,
    data: data,
    progressBar: true,
  });
