import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { deleteUserBackup } from 'api/userBackup';
import {
  deleteUserBackupError,
  deleteUserBackupSuccess,
} from 'redux/actions/userBackup';
import { userBackupTypes } from 'redux/constants/userBackup';
import { USER_BACKUP_STATUS } from 'utils/constants';
import notify from 'utils/toast';

function* deleteUserBackupWatcher() {
  yield takeEvery(
    userBackupTypes.DELETE_BACKUP_REQUEST,
    deleteUserBackupWorker
  );
}

function* deleteUserBackupWorker() {
  try {
    const { data: deleteBackup } = yield deleteUserBackup();

    if (deleteBackup.status === 'Error') {
      throw new Error(deleteBackup.message);
    }

    yield put(
      deleteUserBackupSuccess({
        expire_at: null,
        link: null,
        progress: 0,
        status: USER_BACKUP_STATUS.INCOMPLETE,
      })
    );

    yield notify('success', t('notifications.data_deleted'));
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    yield put(deleteUserBackupError(error));
    yield notify('error', errorMessage);
  }
}

export default function* deleteUserBackupSaga() {
  yield fork(deleteUserBackupWatcher);
}
