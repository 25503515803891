import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { checkEmailForgotPassword, resetConfirmation } from 'api/auth';
import {
  forgotPasswordForEmailError,
  forgotPasswordForEmailSuccess,
  resetPasswordForEmailError,
  resetPasswordForEmailSuccess,
} from 'redux/actions/userAuth';
import { userConstants } from 'redux/constants/userAuth';
import notify from 'utils/toast';

function* userForgotPasswordWatcher() {
  yield takeEvery(
    userConstants.FORGOT_PASSWORD_REQUEST,
    userForgotPasswordWorker
  );
}

function* userForgotPasswordWorker({ payload, action }) {
  try {
    const { data: checkPass } = yield checkEmailForgotPassword({
      email: payload.toLowerCase(),
    });
    yield put(forgotPasswordForEmailSuccess());

    /**
     * the action parameter will change the form
     * step after confirming reset password
     * in registerUser component
     */

    if (checkPass.status === 'Error') {
      throw new Error(checkPass.message);
    }

    yield action.setCurrentStep(action.currentStep + 1);
  } catch (error) {
    // if we needed in future
    // const errorMessage =
    //   error.message || t('notifications.something_went_wrong');

    yield put(forgotPasswordForEmailError(error));

    // yield notify('error', errorMessage);

    yield action.error(error);
  }
}

function* userResetPasswordWatcher() {
  yield takeEvery(
    userConstants.RESET_PASSWORD_REQUEST,
    userResetPasswordWorker
  );
}

function* userResetPasswordWorker({ payload, action }) {
  try {
    const { data: resetData } = yield resetConfirmation(payload);

    if (resetData.status === 'Error') {
      throw new Error(resetData.message);
    }

    yield put(resetPasswordForEmailSuccess());
    yield action.navigate('/');
    yield notify('success', t('notifications.password_changed'));
  } catch (error) {
    // if we needed in future
    // const errorMessage =
    //   error.message || t('notifications.something_went_wrong');
    yield put(resetPasswordForEmailError(error));

    // yield notify('error', errorMessage);
  }
}

export default function* forgotPasswordSaga() {
  yield fork(userForgotPasswordWatcher);
  yield fork(userResetPasswordWatcher);
}
