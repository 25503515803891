import Button from 'components/Button';
import Text from 'components/Text';

import styles from './ErrorFallback.module.scss';

interface IErrorFallbackProps {
  resetErrorBoundary: () => void;
}

function ErrorFallback({ resetErrorBoundary }: IErrorFallbackProps) {
  return (
    <div role='alert' className={styles.error}>
      <div className={styles['error-wrapper']}>
        <Text className={styles.text} align='center'>
          Something went wrong
        </Text>
        <Button className={styles.button} onClick={resetErrorBoundary}>
          Fix problem
        </Button>
      </div>
    </div>
  );
}

ErrorFallback.displayName = 'ErrorFallback';

export default ErrorFallback;
