/**
 * For B2B client on the subscription page we display CurrentPlan component
 * which we also show to the B2C client on the subscription page by click on
 * the button "Current Plan", if your have a subscription.
 */

import { lazy, Suspense } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';

import ErrorFallback from 'components/ErrorFallback';
import MetaInfo from 'components/MetaInfo';
import SpinnerSVG from 'components/SpinnerSVG';
import { subscriptionSelector } from 'redux/selectors/subscription';

import styles from './Subscription.module.scss';

const CurrentPlan = lazy(() => import('components/Modal/CurrentPlan'));

const SubscriptionB2B = () => {
  const subscriptionData = useSelector(subscriptionSelector);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => window.location.reload()}
    >
      <div className={styles.page}>
        <MetaInfo title='Subscription' />

        <SpinnerSVG
          className={styles.spinner}
          active={subscriptionData.requesting}
          fillColor={'var(--theme-color)'}
        />

        <div className={styles.wrapper}>
          <Suspense fallback={''}>
            <CurrentPlan />
          </Suspense>
        </div>
      </div>
    </ErrorBoundary>
  );
};

SubscriptionB2B.displayName = 'SubscriptionB2B';

export default SubscriptionB2B;
