import getOppositeEyesSaga from './bothEyes/getOppositeEyes';
import updateBothSaga from './bothEyes/updateBoth';
import etdrsSaga from './etdrs/etdrs';
import etdrsGccSaga from './etdrs/etdrsGcc';
import etdrsLayerSaga from './etdrs/etdrsLayer';
import createExamination from './examCrud/createExamination';
import deleteExamination from './examCrud/deleteExamination';
import getExamination from './examCrud/getExamination';
import getExaminationStatus from './examCrud/getExaminationStatus';
import updateExamination from './examCrud/updateExamination';
import favoriteScansSaga from './favoriteScans/favoriteScans';
import glaucomaDataSaga from './glaucoma/getGlaucomaData';
import thicknessSaga from './heatmap/getThicknessMap';
import deleteMeasurements from './measurements/deleteMeasurements';
import getMeasDistanceSaga from './measurements/getMeasDistance';
import getMeasManualIdSaga from './measurements/getMeasManualId';
import measurementsPathologiesLayerSaga from './measurements/getMeasPathologiesCalculationZone';
import measManual from './measurements/measManual';
import measurementsLayerSaga from './measurements/measurements';
import updateMeasManual from './measurements/updateMeasManual';
import updateMeasurements from './measurements/updateMeasurements';
import getProgressionCommonPathologiesDataSaga from './progression/getProgressionCommonPathologiesData';
import getProgressionHeatmapDataSaga from './progression/getProgressionHeatmapData';
import getProgressionPathologyScansDataSaga from './progression/getProgressionPathologyScansData';
import getProgressionStateSaga from './progression/getProgressionState';
import updateProgressionSaga from './progression/updateProgression';
import updateProgressionStateSaga from './progression/updateProgressionState';
import getLayersReport from './report/getLayers';
import segmentationReportSaga from './report/reportSegmentation';
import segmentationExamination from './segmentation/segmentationExamination';
import getLayers from './segmentationLayers/getLayers';
import getLayersStatus from './segmentationLayers/getLayersStatus';
import compareExamination from './tabsExtraFeatures/compareExamination';
import updateCompareSaga from './tabsExtraFeatures/updateCompareExam';
import updateComparisonSaga from './tabsExtraFeatures/updateComparison';

export default [
  compareExamination,
  createExamination,
  deleteExamination,
  getExamination,
  getExaminationStatus,
  segmentationExamination,
  segmentationReportSaga,
  updateExamination,
  getLayers,
  getLayersStatus,
  favoriteScansSaga,
  etdrsSaga,
  etdrsLayerSaga,
  etdrsGccSaga,
  updateComparisonSaga,
  updateBothSaga,
  getOppositeEyesSaga,
  updateProgressionSaga,
  thicknessSaga,
  measurementsLayerSaga,
  updateCompareSaga,
  updateMeasurements,
  deleteMeasurements,
  measManual,
  updateMeasManual,
  getMeasDistanceSaga,
  getMeasManualIdSaga,
  getLayersReport,
  measurementsPathologiesLayerSaga,
  glaucomaDataSaga,
  getProgressionCommonPathologiesDataSaga,
  getProgressionHeatmapDataSaga,
  getProgressionPathologyScansDataSaga,
  getProgressionStateSaga,
  updateProgressionStateSaga,
];
