import { lazy } from 'react';

import { useTranslation } from 'react-i18next';

import Plans from './Plans';

const BillingInfo = lazy(() => import('./BillingInfo'));

import MetaInfo from 'components/MetaInfo';
import TabComponent from 'components/TabComponent';
import { TabsTypes } from 'components/TabComponent/TabComponent.types';

const SubscriptionB2C = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'subscription_page.b2c',
  });

  const tabsData: TabsTypes[] = [
    {
      id: 1,
      title: t('subscription'),
      component: <Plans key={1} />,
    },
    {
      id: 2,
      title: t('billing'),
      component: <BillingInfo key={2} />,
    },
  ];

  return (
    <>
      <MetaInfo title='Subscription' />

      <TabComponent data={tabsData} />
    </>
  );
};

SubscriptionB2C.displayName = 'SubscriptionB2C';

export default SubscriptionB2C;
