import { memo, useEffect, useMemo, useRef, useState } from 'react';

import cn from 'classnames';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import FilterExamination from './FilterExamination';
import { IHeaderProps } from './Header.types';

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import Button from 'components/Button';
import FormField from 'components/FormComponents';
import HistoryLink from 'components/HistoryLink';
import {
  addExaminationHandler,
  addPatientHandler,
} from 'components/Modal/helpers';
import { useDashboardContext } from 'contexts/Dashboard';
import useDynamicViewportSize from 'hooks/useDynamicViewportSize';
import { useIsDemo } from 'hooks/useIsDemo';
import useIsUserTrialExpired from 'hooks/useIsUserTrialExpired';
import useLocalStorage from 'hooks/useLocalStorage';
import { patientsSelector } from 'redux/selectors/patients';
import {
  currentPlanSelector,
  profileRoleSelector,
  userProfileSelector,
} from 'redux/selectors/userProfile';
import { MOBILE_RESOLUTION, USER_ROLES } from 'utils/constants';

import styles from './Header.module.scss';

const Header = memo(({ className, type }: IHeaderProps): JSX.Element => {
  const [isFormOpen, setIsFormOpen] = useState(false);

  const { state } = useLocation();
  const { searchTerm, setSearchTerm, activePatient } = useDashboardContext();
  const { data: userData } = useSelector(userProfileSelector);
  const userRole = useSelector(profileRoleSelector);
  const {
    data: { patients },
  } = useSelector(patientsSelector);
  const { localStorageKey: onboardingStep } = useLocalStorage('onboarding');
  const { vw } = useDynamicViewportSize();

  const createPatientRef = useRef<HTMLButtonElement>(null);
  const createExaminationRef = useRef<HTMLButtonElement>(null);

  const isDemo = useIsDemo();
  const isMobile = useMemo(() => vw <= MOBILE_RESOLUTION, [vw]);

  const { t } = useTranslation(undefined, { keyPrefix: 'dashboard.header' });

  const isTrialHasExpired = useIsUserTrialExpired();

  /**
   * Checking state for opening modals when you clicked
   * add new patient/examination outside dashboard page
   */
  useEffect(() => {
    if (state?.openPatientModal) {
      createPatientRef.current?.click();

      state.openPatientModal = false;
    }

    if (state?.openExaminationModal && activePatient?.id) {
      createExaminationRef.current?.click();

      state.openExaminationModal = false;
    }
  }, [state]);

  switch (type) {
    case 'back':
      return (
        <div className={styles.wrapper}>
          <HistoryLink to={'/'} className={styles.back}>
            <ArrowLeftIcon />
            {t('back')}
          </HistoryLink>
        </div>
      );

    case 'empty':
      return <></>;

    default:
      return (
        <div
          className={cn(styles.header, className, { [styles.demo]: isDemo })}
        >
          <Button
            onClick={() => setIsFormOpen((prev) => !prev)}
            className={styles['filter-toggle']}
            disabled={isDemo}
          >
            {isFormOpen ? t('close_filter') : t('open_filter')}
          </Button>

          <CSSTransition
            in={isFormOpen || !isMobile}
            timeout={300}
            classNames={'alert'}
            unmountOnExit
          >
            <div className={styles['form-wrapper']}>
              <Formik
                initialValues={{
                  search: searchTerm ?? '',
                }}
                onSubmit={() => {}}
              >
                {({ setFieldValue }) => {
                  return (
                    <Form className={styles.form}>
                      <FormField
                        type={'text'}
                        component={'text'}
                        name={'search'}
                        id={'search'}
                        label={t('search_placeholder')}
                        icon={<SearchIcon />}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const value = e.target.value;

                          if (value.length > 50) return;

                          setFieldValue('search', e.target.value);
                          setSearchTerm(e.target.value);
                        }}
                        className={styles.patient}
                        disabled={isDemo}
                      />
                    </Form>
                  );
                }}
              </Formik>

              {/* Filter option available only for b2b accounts */}
              {(userRole.model === USER_ROLES.B2B ||
                userRole.model === USER_ROLES.BRANCH_USER) && (
                <FilterExamination />
              )}
            </div>
          </CSSTransition>

          <div className={styles['buttons-wrapper']}>
            <Button
              innerRef={createPatientRef}
              onClick={() =>
                addPatientHandler(
                  userData.examinations_left,
                  userData,
                  onboardingStep
                )
              }
              className={cn(styles.button, 'shepherd-add-patient-btn')}
              disabled={isDemo}
            >
              + {t('add_new_patient')}
            </Button>

            <Button
              innerRef={createExaminationRef}
              onClick={() =>
                addExaminationHandler(
                  userData.examinations_left,
                  userData,
                  onboardingStep,
                  activePatient
                )
              }
              className={cn(styles.button, 'shepherd-add-examination-btn')}
              appearance={'ghost'}
              disabled={isDemo || patients.length === 0 || isTrialHasExpired}
            >
              + {t('add_new_examination')}
            </Button>
          </div>
        </div>
      );
  }
});

Header.displayName = 'Header';

export default Header;
