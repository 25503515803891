import { t } from 'i18next';
import { fork, put, select, takeEvery } from 'redux-saga/effects';

import { setExamFavoriteScan } from 'api/examination';
import {
  setFavoriteScanError,
  setFavoriteScanSuccess,
} from 'redux/actions/examination';
import { ExamActionTypes } from 'redux/constants/examination';
import { getExamData } from 'redux/selectors/examination/getExamData/getExamData';
import { IWorkerProps } from 'redux/types/examination';
import { ScanDataType } from 'types/examination';
import notify from 'utils/toast';

function* favoriteScansWatcher() {
  yield takeEvery(
    ExamActionTypes.SET_FAVORITE_SCAN_REQUEST,
    favoriteScansWorker
  );
}

interface IFavoriteScansWorker extends IWorkerProps {
  type: ExamActionTypes;
  payload: { exam_id: string; scan_id: string };
}

function* favoriteScansWorker({ payload, action }: IFavoriteScansWorker) {
  action?.default?.();

  try {
    const { exam_id, scan_id } = payload;

    const { data: starredData } = yield setExamFavoriteScan({
      exam_id,
      scan_id: { scan_id: scan_id },
    });

    const { data: examData } = yield select(getExamData);

    if (examData.status === 'Error') {
      throw new Error(examData.message);
    }

    const currentIndex = examData?.scans?.findIndex(
      (item: ScanDataType) => item.scan_id === scan_id
    );

    const newExam = {
      ...examData,
      scans: [],
    };

    examData.scans.map((item: ScanDataType, index: number) => {
      if (index === currentIndex) {
        newExam.scans.push({
          ...item,
          is_stared: starredData.data.stared,
        });
      } else {
        newExam.scans.push(item);
      }
    });

    yield put(setFavoriteScanSuccess(newExam));
    yield notify(
      'success',
      starredData.data.stared
        ? t('notifications.scan_added_to_favorites')
        : t('notifications.scan_removed_from_favorites')
    );
    action?.success?.();
  } catch (error: any) {
    action?.error?.();
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    yield put(setFavoriteScanError(error));
    yield notify('error', errorMessage);
  }
}

export default function* favoriteScansSaga() {
  yield fork(favoriteScansWatcher);
}
