import { helperShowStep } from 'services/onboardingTour/tourOptions';
import i18n from 'translation/i18n';

const STEP_ID = 'create-patient-step-3';

export const stepThree = {
  id: STEP_ID,
  attachTo: { element: '.shepherd-patient-info', on: 'left' },
  beforeShowPromise: () =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        resolve();
      }, 500);
    }),
  buttons: [
    {
      classes: 'shepherd-button-primary',
      text: i18n.t('onboarding.create_patient.next'),
      type: 'next',
    },
  ],
  scrollTo: false,
  title: i18n.t('onboarding.create_patient.add_new_patient'),
  text: [
    `${i18n.t('onboarding.create_patient.step_3_text_1')}</br>
    ${i18n.t(
      'onboarding.create_patient.step_3_text_2'
    )} <span class='highlight'>"${i18n.t(
      'onboarding.create_patient.add_new_patient'
    )}"</span>`,
  ],
  when: {
    show: () => {
      helperShowStep(STEP_ID);
    },
  },
};
