import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { getBothEyesOppositeEye } from 'api/examination';
import { getBothEyesOppositeEyeSuccess } from 'redux/actions/examination';
import { ExamActionTypes } from 'redux/constants/examination';
import { IReduxAction } from 'redux/types/examination';
import notify from 'utils/toast';

function* updateBothWatcher() {
  yield takeEvery(
    ExamActionTypes.GET_BOTH_EYES_OPPOSITE_EYE_REQUEST,
    getOppositeEyesWorker
  );
}

interface IGetOppositeEyesWorker {
  type: ExamActionTypes;
  payload: string;
  action: IReduxAction;
  abortSignal?: AbortSignal;
}

function* getOppositeEyesWorker({
  payload,
  action,
  abortSignal,
}: IGetOppositeEyesWorker) {
  action?.default?.();

  try {
    const { data } = yield getBothEyesOppositeEye(payload, abortSignal);

    if (data.status === 'Error') {
      throw new Error(data.message);
    }

    if (data.data != null) {
      yield put(getBothEyesOppositeEyeSuccess(data.data));

      action?.success?.();
    } else {
      action?.error?.();
    }
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    if (error !== 'canceled') {
      notify('error', errorMessage);
    }

    action?.error?.();
  }
}

export default function* getOppositeEyesSaga() {
  yield fork(updateBothWatcher);
}
