import { memo } from 'react';

import cn from 'classnames';

import styles from './CurrentStep.module.scss';

interface CurrentStepProps {
  className?: string;
  currentStep: number;
  allSteps: number;
}

const CurrentStep = memo(
  ({ className, currentStep, allSteps }: CurrentStepProps) => {
    return (
      <div className={cn(styles.wrapper, className)}>
        <span className={styles.current}>{currentStep}</span>/ {allSteps}
      </div>
    );
  }
);

CurrentStep.displayName = 'CurrentStep';

export default CurrentStep;
