import { t } from 'i18next';
import { fork, put, takeLatest } from 'redux-saga/effects';

import { getMainETDRS } from 'api/examination';
import { getETDRSError, getMainETDRSSuccess } from 'redux/actions/examination';
import { ExamActionTypes } from 'redux/constants/examination';
import { IWorkerProps } from 'redux/types/examination';
import notify from 'utils/toast';

function* etdrsWatcher() {
  yield takeLatest(ExamActionTypes.GET_ETDRS_MAIN_REQUEST, etdrsWorker);
}

interface IEtdrsWorker extends IWorkerProps {
  type: ExamActionTypes;
  payload: { exam_id: string; layers?: string[] };
}

function* etdrsWorker({ payload, action }: IEtdrsWorker) {
  action?.default?.();

  try {
    const { exam_id } = payload;

    const { data: mainData } = yield getMainETDRS(exam_id);

    if (!mainData.data[0]) return;
    if (mainData.status === 'Error') {
      throw new Error(mainData.message);
    }

    yield put(getMainETDRSSuccess(mainData.data[0]));

    action?.success?.();
  } catch (error: any) {
    action?.error?.();
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    yield put(getETDRSError('error'));
    yield notify('error', errorMessage);
  }
}

export default function* etdrsSaga() {
  yield fork(etdrsWatcher);
}
