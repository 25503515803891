import { MOCK_EXAM_ID } from 'components/Modal/OnboardingTour/mockData/mockData';
import { helperShowStep } from 'services/onboardingTour/tourOptions';
import i18n from 'translation/i18n';

const STEP_ID = 'pathologies-detection-examination-step-1';

export const stepOne = {
  id: STEP_ID,
  attachTo: {
    element: '.pathologies-detection',
    on: 'top',
  },
  highlightClass: 'click-button',
  advanceOn: { selector: '.pathologies-detection', event: 'click' },
  beforeShowPromise: () =>
    new Promise<void>((resolve) => {
      localStorage.setItem(
        'active_scans',
        JSON.stringify({
          current: {
            id: MOCK_EXAM_ID,
            scan_id: '6414e18e37dd936f800a77a9',
          },
        })
      );
      setTimeout(() => {
        window.scrollTo(0, 0);
        resolve();
      }, 500);
    }),
  buttons: [],
  scrollTo: false,
  title: i18n.t('onboarding.show_examination.tab_menu'),
  text: [
    `${i18n.t(
      'onboarding.show_examination.pathologies_detection_text_1'
    )} <span class='highlight'>${i18n.t(
      'onboarding.show_examination.pathologies_detection_text_2'
    )}</span>
    <p class="next-step">${i18n.t(
      'onboarding.show_examination.pathologies_detection_text_3'
    )}</p>`,
  ],
  when: {
    show: () => {
      helperShowStep(STEP_ID);
    },
  },
};
