import { lazy, memo, Suspense, useRef } from 'react';

import cn from 'classnames';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import pdfFile from 'assets/files/terms-of-service.pdf';
import { ReactComponent as Envelope } from 'assets/icons/icon-envelope.svg';
import { ReactComponent as WebsiteIcon } from 'assets/icons/website.svg';
import Button from 'components/Button';
import FormField from 'components/FormComponents';
import Text from 'components/Text';
import { validateOcuPlan } from 'validation';

import styles from './StepOne.module.scss';

const ReCAPTCHA = lazy(() => import('react-google-recaptcha'));

interface StepOneProps {
  nextHandler: (value: Record<string, string | boolean>, actions: any) => void;
}

const StepOne = memo(({ nextHandler }: StepOneProps) => {
  const recaptchaRef = useRef<any>(null);

  const { t } = useTranslation();

  const onSubmitWithReCAPTCHA = async (
    value: Record<string, string | boolean>,
    actions: any
  ) => {
    await recaptchaRef.current.executeAsync();
    nextHandler(value, actions);

    recaptchaRef.current.reset();
  };

  return (
    <>
      <Text className={styles.suptitle} align='center' color='light' tag='h2'>
        Please review and agree to the Terms of Service to gain access to the
        OcuPlan Altris AI account
      </Text>
      <Text className={styles.title} color='light' tag='h1'>
        Personal Details
      </Text>
      <Formik
        initialValues={{
          full_name: '',
          business_name: '',
          email: '',
          privacy: false,
          confirm: false,
        }}
        validationSchema={() => validateOcuPlan(t)}
        onSubmit={(value, actions) => {
          onSubmitWithReCAPTCHA(value, actions);
        }}
      >
        {({ isValid, dirty, values, setFieldValue }) => (
          <Form>
            <FormField
              type={'text'}
              component={'text'}
              name={'full_name'}
              id={'full_name'}
              label={'Full Name'}
              className={cn(styles.field, styles.capitalize)}
              errorType={'simple'}
            />
            <FormField
              type={'text'}
              component={'text'}
              name={'business_name'}
              id={'business_name'}
              label={'Business Name'}
              icon={<WebsiteIcon />}
              className={cn(styles.field, styles.capitalize)}
              errorType={'simple'}
            />
            <FormField
              type={'text'}
              component={'text'}
              name={'email'}
              id={'email'}
              value={values.email}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;

                if (value.length > 50) return;

                setFieldValue('email', value);
              }}
              label={'E-mail'}
              icon={<Envelope />}
              className={cn(styles.field, styles.lowercase)}
              errorType={'simple'}
            />
            <div className={styles['privacy-wrapper']}>
              <FormField
                component='checkbox'
                name={'confirm'}
                id={'confirm'}
                className={styles.checkbox}
                label={`I confirm that I'm the person, who has according right to agree on
              Terms of Service with Altris Ai on behalf of my business`}
              />
            </div>
            <div className={styles['privacy-wrapper']}>
              <FormField
                component='checkbox'
                name={'privacy'}
                id={'privacy'}
                className={styles.checkbox}
                label={'I have read and agree to the Altris Terms of Service'}
              />
            </div>

            <Button className={styles.confirm} disabled={!(dirty && isValid)}>
              Submit
            </Button>
            <Button
              type={'button'}
              onClick={() => {
                return window.open(pdfFile, '_blank');
              }}
              className={styles.creataccount}
              appearance='simple'
            >
              Download document
            </Button>
          </Form>
        )}
      </Formik>
      <Suspense fallback={''}>
        <ReCAPTCHA
          theme='dark'
          ref={recaptchaRef}
          size='invisible'
          sitekey={`${process.env.REACT_APP_CAPCHA_SITE_KEY}`}
        />
      </Suspense>
    </>
  );
});

StepOne.displayName = 'StepOne';

export default StepOne;
