import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { updateMeasurementsScan } from 'api/examination';
import {
  updateExamMeasLayersError,
  updateExamMeasLayersSuccess,
} from 'redux/actions/examination';
import { ExamActionTypes } from 'redux/constants/examination';
import { IWorkerProps } from 'redux/types/examination';
import { IMeasurementsPolygonScan } from 'types/examination';
import notify from 'utils/toast';

function* updateMeasurementsLayerWatcher() {
  yield takeEvery(
    ExamActionTypes.UPDATE_EXAM_MEASUREMENTS_LAYER_REQUEST,
    updateMeasurementsWorker
  );
}

interface IMeasurements extends IWorkerProps {
  type: ExamActionTypes;
  payload: {
    exam_id: string;
    scan_id: string;
    pathologies: IMeasurementsPolygonScan[];
  };
  action?: {
    default?: () => void;
    success?: (pathologies?: IMeasurementsPolygonScan[]) => void;
    error?: () => void;
  };
}

function* updateMeasurementsWorker({ payload, action }: IMeasurements) {
  action?.default?.();

  try {
    const { exam_id, scan_id, pathologies } = payload;

    yield updateMeasurementsScan({
      exam_id,
      scan_id,
      pathologies,
    });

    yield put(updateExamMeasLayersSuccess(pathologies));

    yield notify('success', t('notifications.measurements_updated'));
    action?.success?.(pathologies);
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    action?.error?.();
    yield notify('error', errorMessage);
    yield put(
      updateExamMeasLayersError({
        slug: 'something_went_wrong',
        message: 'Something went wrong',
      })
    );
  }
}

export default function* measurementsLayerSaga() {
  yield fork(updateMeasurementsLayerWatcher);
}
