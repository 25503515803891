import { memo, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { ISubscriptionPlansProps } from './SubscriptionPlans.types';
import SubscriptionTable from './Table/SubscriptionTable';

import Switcher from 'components/Switcher';
import Text from 'components/Text';
import { userProfileSelector } from 'redux/selectors/userProfile';

import styles from './SubscriptionPlans.module.scss';

const SubscriptionPlans = memo((props: ISubscriptionPlansProps) => {
  const { title, subtitle, switcher } = props;
  const { data: userData } = useSelector(userProfileSelector);

  /**
   * Set the type of plan as a default value in the switcher
   * and set right price on the column price if we have
   * this data in reducer
   */
  const [billingPlan, setBillingPlan] = useState(
    userData.plan?.recurring === 'year'
  );

  /**
   * Get and update default value in the switcher by
   * direct link
   */
  useEffect(() => {
    setBillingPlan(userData.plan?.recurring === 'year');
  }, [userData]);

  return (
    <>
      <div className={styles.heading}>
        <div>
          <Text tag='h2' className={styles.title}>
            {title}
          </Text>
          <Text tag='h3' className={styles.subtitle}>
            {subtitle}
          </Text>
        </div>

        {switcher && (
          <Switcher
            id='billing_plan'
            name='billing_plan'
            checked={billingPlan}
            onChange={setBillingPlan}
          />
        )}
      </div>
      <SubscriptionTable billingPlan={billingPlan} />
    </>
  );
});

SubscriptionPlans.displayName = 'SubscriptionPlans';

export default SubscriptionPlans;
