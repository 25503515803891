import { helperShowStep } from 'services/onboardingTour/tourOptions';
import i18n from 'translation/i18n';

const STEP_ID = 'pathologies-detection-examination-step-6';

export const stepSix = {
  id: STEP_ID,
  attachTo: {
    element: '.shepherd-segmentation-classification',
    on: 'top',
  },
  beforeShowPromise: () =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, 500);
    }),
  buttons: [
    {
      classes: 'shepherd-button-primary',
      text: i18n.t('onboarding.show_examination.next'),
      type: 'next',
    },
  ],
  canClickTarget: false,
  scrollTo: { behavior: 'smooth', block: 'center' },
  title: i18n.t('onboarding.show_examination.pathologies_detection_text_22'),
  text: [
    `${i18n.t(
      'onboarding.show_examination.pathologies_detection_text_23'
    )} <span class='highlight'>${i18n.t(
      'onboarding.show_examination.pathologies_detection_text_24'
    )}</span> ${i18n.t(
      'onboarding.show_examination.pathologies_detection_text_25'
    )}`,
  ],
  when: {
    show: () => {
      helperShowStep(STEP_ID);
      localStorage.removeItem('active_scans');
    },
  },
};
