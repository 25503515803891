import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { confirmPurchase, paymentForPurchase } from 'api/purchase';
import { closeModal } from 'redux/actions/modal';
import { getPatientRequest } from 'redux/actions/patient';
import {
  purchaseForNewCardError,
  purchaseForNewCardSuccess,
} from 'redux/actions/purchaseItem';
import { purchaseConstants } from 'redux/constants/purchase';
import notify from 'utils/toast';

function* purchaseForNewCardWatcher() {
  yield takeEvery(
    purchaseConstants.PURCHASE_FOR_NEW_CARD_REQUEST,
    purchaseForNewCardWorker
  );
}

function* purchaseForNewCardWorker({ payload, action }) {
  try {
    const { data: payment_intent } = yield paymentForPurchase({
      purchase_item_id: payload.purchase_item_id,
    });

    const data = yield payload.stripe.confirmCardPayment(
      payment_intent.data.client_secret,
      {
        payment_method: {
          card: payload.cardElement,
        },
      }
    );

    /**
     * Catch error with problem card, for example
     * Expired card decline, Stolen card decline
     * and etc.
     */
    if (data.error) {
      yield put(purchaseForNewCardError(data.error));
      yield notify('error', data.error.message);
      return;
    }

    /**
     * confirmPurchase need to update backend
     */
    yield confirmPurchase({ payment_intent_id: data.paymentIntent.id });

    if (action?.needPatients) {
      yield put(getPatientRequest());
    }

    yield put(purchaseForNewCardSuccess());
    yield put(closeModal());
    yield notify(
      'success',
      t('notifications.you_successfully_buy_additional_examination')
    );
  } catch (error) {
    yield put(purchaseForNewCardError(error));
    yield notify('error', t('notifications.something_went_wrong'));
  }
}

export default function* purchaseForNewCardSaga() {
  yield fork(purchaseForNewCardWatcher);
}
