import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { getAllPatients } from 'api/patient';
import { getPatientError, getPatientSuccess } from 'redux/actions/patient';
import { patientConstants } from 'redux/constants/patient';
import notify from 'utils/toast';

function* getPatientWatcher() {
  yield takeEvery(patientConstants.GET_PATIENT_REQUEST, getPatientWorker);
}

function* getPatientWorker() {
  try {
    const { data: patients } = yield getAllPatients();
    if (patients.status === 'Error') {
      throw new Error(patients.message);
    }
    yield put(getPatientSuccess(patients.data));
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    yield put(getPatientError(error));
    yield notify('error', errorMessage);
  }
}

export default function* getPatientSaga() {
  yield fork(getPatientWatcher);
}
