import { t } from 'i18next';
import { fork, put, takeLatest } from 'redux-saga/effects';

import { getMeasManualScan } from 'api/examination';
import { OverlayLine } from 'pages/Examination/Examination.types';
import {
  getExamMeasManualError,
  getExamMeasManualSuccess,
} from 'redux/actions/examination';
import { ExamActionTypes } from 'redux/constants/examination';
import { IWorkerProps } from 'redux/types/examination';
import notify from 'utils/toast';

function* measManualLayerWatcher() {
  yield takeLatest(
    ExamActionTypes.GET_EXAM_MEASUREMENTS_MANUAL_REQUEST,
    measurementsWorker
  );
}

interface IMeasurements extends IWorkerProps {
  type: ExamActionTypes;
  payload: {
    exam_id: string;
    scan_id: string;
    getWithoutSaving?: boolean;
  };
  action?: {
    default?: () => void;
    success?: (data?: OverlayLine[]) => void;
    error?: () => void;
  };
  abortSignal?: AbortSignal;
}

function* measurementsWorker({ payload, action, abortSignal }: IMeasurements) {
  action?.default?.();

  try {
    const { exam_id, scan_id, getWithoutSaving } = payload;

    const { data: layersData } = yield getMeasManualScan(
      { exam_id, scan_id },
      abortSignal
    );
    if (layersData.status === 'Error') {
      throw new Error(layersData.message);
    }

    if (getWithoutSaving) {
      yield put(getExamMeasManualSuccess());
    } else {
      yield put(getExamMeasManualSuccess(layersData.data));
    }

    action?.success?.(layersData.data);
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    if (error !== 'canceled') {
      yield notify('error', errorMessage);
    }

    action?.error?.();

    yield put(
      getExamMeasManualError({
        slug: 'something_went_wrong',
        message: 'Something went wrong',
      })
    );
  }
}

export default function* measManualLayerSaga() {
  yield fork(measManualLayerWatcher);
}
