import { ElementRef, memo, useRef, useState } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

import FilterForm from './FilterForm';

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import Text from 'components/Text';
import { useIsDemo } from 'hooks/useIsDemo';
import useOnClickOutside from 'hooks/useOnClickOutside';

import styles from './FilterExamination.module.scss';

const FilterExamination = memo(() => {
  const [open, setOpen] = useState(false);
  const examinationFilterPopUp = useRef<ElementRef<'div'>>(null);

  const isDemo = useIsDemo();

  const { t } = useTranslation(undefined, { keyPrefix: 'dashboard.header' });

  const examinationsFilterHandler = () => {
    if (isDemo) return;

    setOpen(!open);
  };

  const closeHandler = () => {
    setOpen(false);
  };

  useOnClickOutside(examinationFilterPopUp, closeHandler);

  return (
    <div ref={examinationFilterPopUp}>
      <div
        className={cn(styles.filter, { [styles.disabled]: isDemo })}
        onClick={examinationsFilterHandler}
      >
        <FilterIcon className={styles.icon} />
        <Text className={styles.title}>{t('examination_filter')}</Text>
        <ArrowLeftIcon
          className={cn(styles.arrow, {
            [styles.opened]: open,
          })}
        />
      </div>
      <CSSTransition in={open} timeout={300} classNames={'alert'} unmountOnExit>
        <FilterForm isOpen={open} />
      </CSSTransition>
    </div>
  );
});

FilterExamination.displayName = 'FilterExamination';

export default FilterExamination;
