import { memo } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Skeleton from './Skeleton';
import { ISubscriptionTableProps } from './SubscriptionTable.types';

import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check-mark-rounded.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import CustomTooltip from 'components/CustomTooltip';
import { MainCard } from 'components/SubscriptionPlanCard';
import Text from 'components/Text';
import {
  selectSubscriptionServices,
  subscriptionSelector,
} from 'redux/selectors/subscription';
import {
  ISubscriptionPlan,
  ISubscriptionPlanOptions,
  ISubscriptionPrice,
  ISubscriptionServices,
} from 'types/subscription';
import { ROUTES } from 'utils/constants';

import styles from './SubscriptionTable.module.scss';

const SubscriptionTable = memo(({ billingPlan }: ISubscriptionTableProps) => {
  const subscriptionData = useSelector(subscriptionSelector);
  const subscriptionServicesData = useSelector(selectSubscriptionServices);

  const { t } = useTranslation(undefined, {
    keyPrefix: 'components.subscription_plans',
  });

  const checkAvailability = (service: boolean) => {
    return service ? <CheckIcon /> : <CancelIcon />;
  };

  /**
   * @param plans array of plans
   * @returns columns with plans and services for each plan in the table
   */
  const createBodyColumn = (plans: ISubscriptionPlan[]) => {
    if (plans.length) {
      const plansWithEnterprise = [
        ...plans,
        /**
         * Add Enterprise plan to the list of plans
         * Free plan we delete from the list in the saga
         */
        {
          slug: 'enterprise',
          name: 'Enterprise',
          is_recommended: false,
          services_with_values: {},
          services: {},
          classification_items: [],
          segmentation_classes: [],
          options: [],
        },
      ];

      return plansWithEnterprise.map((plan: ISubscriptionPlan) => {
        const pricingType = billingPlan ? 'year' : 'month';

        const yearlyPlans: ISubscriptionPrice[] | any = plan.options.map(
          (item: ISubscriptionPlanOptions) =>
            item.prices.find(
              (plan: ISubscriptionPrice) => plan.recurring.interval == 'year'
            )
        );

        const monthlyPlans: ISubscriptionPrice[] | any = plan.options.map(
          (item) =>
            item.prices.find((plan) => plan.recurring.interval == 'month')
        );

        const currentPlans: ISubscriptionPrice[] | any = plan.options.map(
          (item) =>
            item.prices.find((plan) => plan.recurring.interval === pricingType)
        );

        if (plan.slug === 'enterprise') {
          return (
            <div
              key={plan.slug}
              className={cn(styles.borders, {
                [styles.highlight]: plan.is_recommended,
              })}
            >
              <div className={cn(styles.column)}>
                <div className={styles.plan}>
                  <MainCard
                    plan={plan}
                    pricingType={pricingType}
                    currentPlans={currentPlans}
                    yearlyPlans={yearlyPlans}
                    monthlyPlans={monthlyPlans}
                  />
                </div>
              </div>
            </div>
          );
        }

        return (
          <div
            key={plan.slug}
            className={cn(styles.borders, {
              [styles.highlight]: plan.is_recommended,
            })}
          >
            <div className={cn(styles.column)}>
              <div className={styles.plan}>
                <MainCard
                  plan={plan}
                  pricingType={pricingType}
                  currentPlans={currentPlans}
                  yearlyPlans={yearlyPlans}
                  monthlyPlans={monthlyPlans}
                />
              </div>
            </div>

            <div className={cn(styles.column, styles['table-border'])}>
              <div className={cn(styles.table)}>
                <div className={styles['table-title']}></div>
                {subscriptionServicesData.map(
                  (service: ISubscriptionServices) => {
                    return (
                      <div key={service.indicator} className={styles.status}>
                        {checkAvailability(plan.services[service.indicator])}
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        );
      });
    }
  };

  return subscriptionData.requesting ? (
    <Skeleton />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.body}>
        <div className={styles.borders}>
          <div className={cn(styles.column, styles.aside)}>
            <div className={styles.plan}>
              <Text className={styles.title}>{t('subscription')}</Text>
              <Text>{t('price')}</Text>
              <Text>{t('examinations_available')}</Text>
              <div className={styles.services}>
                <NavLink className={styles.link} to={ROUTES.SERVICES.MAIN}>
                  {t('detect')} 70+ <br /> {t('retina_conditions')}
                </NavLink>
              </div>
            </div>
          </div>
          <div
            className={cn(styles.column, styles.aside, styles['table-border'])}
          >
            <div className={styles.table}>
              <Text className={styles['table-title']}>{t('services')}</Text>
              {subscriptionServicesData.map(
                (
                  service: {
                    label: string;
                    indicator: string;
                    tooltip: string;
                  },
                  index: number
                ) => {
                  return (
                    <div key={index}>
                      <div className={styles['table-label']}>
                        <Text className={styles['label-text']}>
                          {service.label}
                        </Text>
                        {service?.tooltip && (
                          <CustomTooltip
                            icon={<InfoIcon />}
                            tooltipText={
                              service?.tooltip
                                ? service?.tooltip
                                : t('tooltip_text')
                            }
                          />
                        )}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
        {createBodyColumn(subscriptionData.data.plans)}
      </div>
    </div>
  );
});

SubscriptionTable.displayName = 'SubscriptionTableNew';

export default SubscriptionTable;
