import Shepherd from 'shepherd.js';

import { MOCK_EXAM_ID } from 'components/Modal/OnboardingTour/mockData/mockData';
import { helperShowStep } from 'services/onboardingTour/tourOptions';
import i18n from 'translation/i18n';

const STEP_ID = 'single-scan-examination-step-1';

export const stepOne: Shepherd.Step.StepOptions = {
  id: STEP_ID,
  attachTo: { element: '.shepherd-heading', on: 'bottom' },
  beforeShowPromise: () =>
    new Promise<void>((resolve) => {
      localStorage.setItem(
        'active_scans',
        JSON.stringify({
          current: {
            id: MOCK_EXAM_ID,
            scan_id: '6414e18e37dd936f800a77a9',
          },
        })
      );
      setTimeout(() => {
        window.scrollTo(0, 0);
        resolve();
      }, 500);
    }),
  buttons: [
    {
      classes: 'shepherd-button-primary',
      text: i18n.t('onboarding.show_examination.next'),
      action() {
        return this.next();
      },
    },
  ],
  canClickTarget: false,
  scrollTo: false,
  title: i18n.t('onboarding.show_examination.single_scan_text_1'),
  text: [i18n.t('onboarding.show_examination.single_scan_text_2')],
  when: {
    show: function () {
      helperShowStep(STEP_ID);
    },
  },
};
