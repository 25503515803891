import { type RouteProps, Route } from 'react-router-dom';

import { USER_ROLES } from 'utils/constants';

export const generateRoutes = (links: Record<string, RouteProps>) => {
  return Object.values(links).map(({ element, path }) => (
    <Route key={path} path={path} element={element} />
  ));
};

/**
 * Function for generating array of roles for menu links, by default it generates all roles
 *
 * @param excludingRole - array of roles that should be excluded from the list
 * @returns array of roles
 */
export const generateRoles = (excludingRole: USER_ROLES[] = []) => {
  const roles = Object.values(USER_ROLES);
  if (excludingRole.length === 0) {
    return roles;
  }

  return roles.filter((role) => !excludingRole.includes(role));
};
