import { SUBSCRIPTION } from './endpoints';

import fetchData from 'utils/fetchData';

export const getSubscription = async () =>
  fetchData({ method: 'get', to: SUBSCRIPTION.PLANS, data: {} });
export const getSubscribePlan = async (data: FormData) =>
  fetchData({ method: 'post', to: SUBSCRIPTION.SUBSCRIBE_PLAN, data: data });
export const getUnsubscribePlan = async () =>
  fetchData({
    method: 'delete',
    to: SUBSCRIPTION.UNSUBSCRIBE_PLAN,
    data: {},
  });
export const getSubscriptionDetails = async () =>
  fetchData({ method: 'get', to: SUBSCRIPTION.SUBSCRIPTION_DETAILS, data: {} });
export const getSubscriptionServices = async () =>
  fetchData({ method: 'get', to: SUBSCRIPTION.MAIN_SERVICES, data: {} });
export const getPaymentStatistics = async () =>
  fetchData({ method: 'get', to: SUBSCRIPTION.PAYMENT_STATISTICS, data: {} });
