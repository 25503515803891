import { memo } from 'react';

import cn from 'classnames';
import { Form, Formik } from 'formik';

import FormField from 'components/FormComponents';
import Text from 'components/Text';

import styles from './Switcher.module.scss';

const Switcher = memo(
  (props: {
    id: string;
    name: string;
    className?: string;
    textLeft?: string;
    textRight?: string;
    checked: boolean;
    onChange: (value: boolean) => void;
  }) => {
    return (
      // @param checked value from props set as default value but change field
      // inside form
      <Formik
        initialValues={{ [props.name]: props.checked }}
        // Reinitialize default value for first loading page by direct link
        enableReinitialize={true}
        onSubmit={() => {}}
      >
        {({ values, setFieldValue }) => (
          <Form className={cn(styles['plan-switcher'], props.className)}>
            <Text
              tag='span'
              className={cn({
                [styles.active]: !values[props.name],
              })}
            >
              {props.textLeft ? props.textLeft : 'Bill Monthly'}
            </Text>
            <FormField
              name={props.name}
              component='switchBox'
              id={props.id}
              setFieldValue={setFieldValue}
              className={styles['big-switcher']}
              onChange={() => {
                setFieldValue(props.name, !values[props.name]); // false = month, true = annual
                props.onChange(!values[props.name]);
              }}
              checked={values[props.name]}
            />
            <Text
              tag='span'
              className={cn({
                [styles.active]: values[props.name],
              })}
            >
              {props.textRight ? props.textRight : 'Bill Annual'}
            </Text>
          </Form>
        )}
      </Formik>
    );
  }
);

Switcher.displayName = 'Switcher';

export default Switcher;
