import { memo } from 'react';

import { Form, Formik } from 'formik';

import FormField from 'components/FormComponents';

import styles from './SimpleCard.module.scss';

const SimpleCardSkeleton = memo(() => {
  return (
    <div className={styles.card}>
      <div className={styles.cost}>
        <div className={styles.price}>
          {`$**`}
          <span>/month</span>
        </div>
      </div>
      <Formik initialValues={{ plan_id: 1 }}>
        {() => {
          return (
            <Form className={styles.form}>
              <FormField
                options={[{ value: 1, label: '25 / Month' }]}
                component={'select'}
                name={'plan_id'}
                id={'plan_id'}
                className={styles.field}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
});

SimpleCardSkeleton.displayName = 'SimpleCardSkeleton';

export default SimpleCardSkeleton;
