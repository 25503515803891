import {
  ElementRef,
  memo,
  Suspense,
  useCallback,
  useId,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import cn from 'classnames';
import qs from 'query-string';

import TabItem from './TabItem';

import Spinner from 'components/Spinner';
import { TabComponentTypes } from 'components/TabComponent/TabComponent.types';
import { useDebounceCallback } from 'hooks/useDebounce';
import { useIsDemo } from 'hooks/useIsDemo';

import styles from './TabComponent.module.scss';

const TabComponent = memo(
  ({
    data,
    className,
    tabKey = 'tab',
    disabled,
  }: TabComponentTypes): JSX.Element => {
    const [active, setActive] = useState<number>(1);
    const [underlinePosition, setUnderlinePosition] = useState({
      left: 0,
      width: 0,
    });

    const tabsWrapperRef = useRef<ElementRef<'div'>>(null);

    const id = useId();

    const isDemo = useIsDemo();

    const handleResize = useDebounceCallback(() => {
      requestAnimationFrame(() => {
        const tabItems = tabsWrapperRef.current?.querySelectorAll('button');

        setUnderlinePosition({
          left: tabItems?.[active - 1]?.offsetLeft ?? 0,
          width: tabItems?.[active - 1]?.offsetWidth ?? 0,
        });
      });
    }, 100);

    const navigateTo = useCallback(
      (tabNumber: number) => {
        const query = qs.parse(location.search);

        query[tabKey] = tabNumber.toString();

        const search = qs.stringify(query);

        setActive(tabNumber);

        requestAnimationFrame(() => {
          const tabItem =
            tabsWrapperRef.current?.querySelectorAll('button')?.[tabNumber - 1];

          if (!tabItem || !tabsWrapperRef.current) return;

          const tabLeft = tabItem.offsetLeft;
          const tabWidth = tabItem.offsetWidth;
          const containerWidth = tabsWrapperRef.current.offsetWidth;

          tabsWrapperRef.current.scrollTo({
            left: tabLeft + tabWidth / 2 - containerWidth / 2,
            behavior: 'smooth',
          });

          setUnderlinePosition({
            left: tabLeft,
            width: tabWidth,
          });
        });

        history.replaceState(null, '', `?${search}`);
      },
      [tabKey]
    );

    useLayoutEffect(() => {
      const query = qs.parse(location.search);

      const tabItems = tabsWrapperRef.current?.querySelectorAll('button');

      if (!(tabKey in query)) {
        query[tabKey] = '1';

        setActive(1);

        setUnderlinePosition({
          left: tabItems?.[0]?.offsetLeft ?? 0,
          width: tabItems?.[0]?.offsetWidth ?? 0,
        });
      } else {
        setActive(Number(query[tabKey]));

        setUnderlinePosition({
          left: tabItems?.[Number(query[tabKey]) - 1]?.offsetLeft ?? 0,
          width: tabItems?.[Number(query[tabKey]) - 1]?.offsetWidth ?? 0,
        });
      }

      history.replaceState(null, '', `?${qs.stringify(query)}`);

      return () => {
        const query = qs.parse(location.search);

        delete query[tabKey];

        const search = qs.stringify(query);

        history.replaceState(
          null,
          '',
          `${search ? `?${qs.stringify(query)}` : ''}`
        );
      };
    }, [data]);

    useLayoutEffect(() => {
      const observer = new ResizeObserver(handleResize);

      observer.observe(tabsWrapperRef.current as Element);

      return () => {
        observer.disconnect();
      };
    }, [active]);

    return (
      <div className={cn(styles.wrapper, className)}>
        <div className={styles.tabs} id={id} ref={tabsWrapperRef}>
          {data?.map(
            ({
              id,
              title,
              icon,
              slug,
              className,
              badge,
              demoDisabled,
              onItemClicked,
              iconAnimated,
            }) => (
              <TabItem
                key={id}
                title={title}
                icon={icon}
                iconAnimated={iconAnimated}
                slug={slug}
                badge={badge}
                disabled={(isDemo && demoDisabled) || disabled}
                className={cn(
                  {
                    [styles.disabled]: (isDemo && demoDisabled) || disabled,
                  },
                  className,
                  slug
                )}
                onItemClicked={() => {
                  if (disabled) return;

                  onItemClicked?.();
                  navigateTo(id);
                }}
                isActive={active === id}
              />
            )
          )}
          <span className={styles.line} style={underlinePosition} />
        </div>
        <Suspense
          fallback={
            <div className={styles.placeholder}>
              <Spinner />
            </div>
          }
        >
          <div className={styles.content}>
            {data?.map(({ id, component }) => {
              return active === id && component;
            })}
          </div>
        </Suspense>
      </div>
    );
  }
);

TabComponent.displayName = 'TabComponent';

export default TabComponent;
