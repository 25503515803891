import Shepherd from 'shepherd.js';

import { helperShowStep } from 'services/onboardingTour/tourOptions';
import i18n from 'translation/i18n';

const STEP_ID = 'single-scan-examination-step-4';

export const stepFour: Shepherd.Step.StepOptions = {
  id: STEP_ID,
  attachTo: { element: '.shepherd-single-range-filter', on: 'right' },

  buttons: [
    {
      classes: 'shepherd-button-primary',
      text: i18n.t('onboarding.show_examination.next'),
      action() {
        return this.next();
      },
    },
  ],
  canClickTarget: false,
  scrollTo: { behavior: 'smooth', block: 'center' },
  title: i18n.t('onboarding.show_examination.single_scan_text_13'),
  text: [
    `${i18n.t('onboarding.show_examination.single_scan_text_14')}
    <ul class='count'>
      <li class='count-item'><span class='number'>1</span> <p>${i18n.t(
        'onboarding.show_examination.single_scan_text_15'
      )} </br><span class='description'>${i18n.t(
      'onboarding.show_examination.single_scan_text_16'
    )}</span></p></li>
      <li class='count-item'><span class='number'>2</span> <p>${i18n.t(
        'onboarding.show_examination.single_scan_text_17'
      )} (OD/OS)</p></li>
      <li class='count-item'><span class='number'>3</span> <p>${i18n.t(
        'onboarding.show_examination.single_scan_text_18'
      )} </br><span class='description'>${i18n.t(
      'onboarding.show_examination.single_scan_text_19'
    )}</span></p></li>
      <li class='count-item'><span class='number'>4</span> <p>${i18n.t(
        'onboarding.show_examination.single_scan_text_20'
      )}</p></li>
      <li class='count-item'><span class='number'>5</span> <p>${i18n.t(
        'onboarding.show_examination.single_scan_text_21'
      )}</p></li>
      <li class='count-item'><span class='number'>6</span> <p>${i18n.t(
        'onboarding.show_examination.single_scan_text_22'
      )}</p></li>
    </ul>`,
  ],
  when: {
    show: () => {
      helperShowStep(STEP_ID);
    },
  },
};
