import Shepherd from 'shepherd.js';

import i18n from 'translation/i18n';

export const stepDicom: Shepherd.Step.StepOptions = {
  id: 'step-dicom',
  beforeShowPromise: () =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        resolve();
      }, 500);
    }),
  classes: 'dicom-modal',
  buttons: [
    {
      classes: 'shepherd-button-primary',
      text: i18n.t('onboarding.create_examination.step_dicom_text_1'),
      action() {
        this.show('create-examination-step-5');
        // added one moe step as a modal for description of scans
        this.show('step-scans');
      },
    },
  ],
  scrollTo: false,
  title: i18n.t('onboarding.create_examination.step_dicom_text_2'),
  text: [
    `<div class="content">
    <h2 class="title"><span class='highlight'>${i18n.t(
      'onboarding.create_examination.step_dicom_text_3'
    )}</span> ${i18n.t(
      'onboarding.create_examination.step_dicom_text_4'
    )} <span class='highlight'>${i18n.t(
      'onboarding.create_examination.step_dicom_text_5'
    )}</span> ${i18n.t('onboarding.create_examination.step_dicom_text_6')}</h2>
    <h2>${i18n.t('onboarding.create_examination.step_dicom_text_7')}</h2>
    <div class="content-item">
    <h3>${i18n.t('onboarding.create_examination.step_dicom_text_8')}</h3>
    <p>${i18n.t('onboarding.create_examination.step_dicom_text_9')}</p>
    </div>
        <div class="content-item">
    <h3>${i18n.t('onboarding.create_examination.step_dicom_text_10')}</h3>
    <p>${i18n.t('onboarding.create_examination.step_dicom_text_11')}</p>
    <a target="_blank" href="https://docs.altris.ai/docs/dicom-and-non-dicom-format-files">${i18n.t(
      'onboarding.create_examination.step_dicom_text_12'
    )}</a>
    </div>
    <div class="content-item">
    <h3>${i18n.t('onboarding.create_examination.step_dicom_text_13')}</h3>
    <p>${i18n.t('onboarding.create_examination.step_dicom_text_14')}</p>
    <a target="_blank" href="https://www.youtube.com/watch?v=_ThN7tOA55c">${i18n.t(
      'onboarding.create_examination.step_dicom_text_15'
    )}</a>
    </div>
    <div class="content-item">
    <h3>${i18n.t('onboarding.create_examination.step_dicom_text_16')}</h3>
    <p>${i18n.t('onboarding.create_examination.step_dicom_text_17')}</p>
    <a target="_blank" href="https://www.youtube.com/watch?v=_ThN7tOA55c">${i18n.t(
      'onboarding.create_examination.step_dicom_text_18'
    )}</a>
    </div>
    <h2 class="dicom-title">${i18n.t(
      'onboarding.create_examination.step_dicom_text_19'
    )}</h2>
    <div class="link-lists">
    <a target="_blank" href="https://docs.altris.ai/docs/nidek">${i18n.t(
      'onboarding.create_examination.step_dicom_text_20'
    )}</a>
    <a target="_blank" href="https://docs.altris.ai/docs/topcon">${i18n.t(
      'onboarding.create_examination.step_dicom_text_21'
    )}</a>
    <a target="_blank" href="https://docs.altris.ai/docs/optopol-export">${i18n.t(
      'onboarding.create_examination.step_dicom_text_22'
    )}</a>
    </div>
    </div>`,
  ],
};
