import { userProfileConstants } from 'redux/constants/userProfile';
import {
  IChangePassword,
  IGetCodeForPassword,
  IReportSetting,
  IUpdateUserProfile,
  IUserProfile,
} from 'types/editProfile';

// GET_USER_PROFILE
export const getUserProfileRequest = () => ({
  type: userProfileConstants.GET_PROFILE_REQUEST,
});

export const getUserProfileSuccess = (data: IUserProfile) => ({
  type: userProfileConstants.GET_PROFILE_SUCCESS,
  payload: data,
});

export const getUserProfileError = (error: unknown | string) => ({
  type: userProfileConstants.GET_PROFILE_ERROR,
  payload: error,
});

// UPDATE_USER_PROFILE
export const updateProfileRequest = (data: IUpdateUserProfile) => ({
  type: userProfileConstants.UPDATE_PROFILE_REQUEST,
  payload: data,
});

export const updateProfileSuccess = (data: IUserProfile) => ({
  type: userProfileConstants.UPDATE_PROFILE_SUCCESS,
  payload: data,
});

export const updateProfileError = (error: unknown | string) => ({
  type: userProfileConstants.UPDATE_PROFILE_ERROR,
  payload: error,
});

// GET_CODE_FOR_UPDATE_PASSWORD
export const getCodeUpdatePasswordRequest = (data: IGetCodeForPassword) => ({
  type: userProfileConstants.UPDATE_USER_GET_CODE_FOR_PASSWORD_REQUEST,
  payload: data,
});

export const getCodeUpdatePasswordSuccess = () => ({
  type: userProfileConstants.UPDATE_USER_GET_CODE_FOR_PASSWORD_SUCCESS,
});

export const getCodeUpdatePasswordError = (error: unknown | string) => ({
  type: userProfileConstants.UPDATE_USER_GET_CODE_FOR_PASSWORD_ERROR,
  payload: error,
});

export const getCodeUpdatePasswordReset = () => ({
  type: userProfileConstants.UPDATE_USER_GET_CODE_FOR_PASSWORD_RESET,
});

// UPDATE_USER_PASSWORD
export const updatePasswordRequest = (
  data: IChangePassword,
  action: object
) => ({
  type: userProfileConstants.UPDATE_USER_PASSWORD_REQUEST,
  payload: data,
  action: action,
});

export const updatePasswordSuccess = () => ({
  type: userProfileConstants.UPDATE_USER_PASSWORD_SUCCESS,
});

export const updatePasswordError = (error: unknown | string) => ({
  type: userProfileConstants.UPDATE_USER_PASSWORD_ERROR,
  payload: error,
});

// UPDATE_REPORT_SETTINGS
export const updateReportSettingsRequest = (data: IReportSetting) => ({
  type: userProfileConstants.UPDATE_REPORT_SETTINGS_REQUEST,
  payload: data,
});

export const updateReportSettingsSuccess = (data: IReportSetting) => ({
  type: userProfileConstants.UPDATE_REPORT_SETTINGS_SUCCESS,
  payload: data,
});

export const updateReportSettingsError = (error: unknown | string) => ({
  type: userProfileConstants.UPDATE_REPORT_SETTINGS_ERROR,
  payload: error,
});
