import { subscriptionConstants } from 'redux/constants/subscription';
import { ISubscriptionState, subscriptionActions } from 'types/subscription';
import { SUBSCRIPTION_STATUS } from 'utils/constants';

const initialState: ISubscriptionState = {
  requesting: false,
  errors: null,
  data: {
    plans: [],
    subscription_details: {
      name: '',
      recurring: 'month',
      subscription_status: SUBSCRIPTION_STATUS.ACTIVE,
      ends_at: null,
      trial_ends_at: null,
      current_period_end: 0,
      days_left: 0,
      amount: '',
      discount_amount: null,
      currency: '',
      currency_symbol: '',
      examinations_left: 0,
      upcoming_plan: null,
      coupon: null,
    },
    subscription_services: [],
    payment_statistics: [],
  },
};

const subscriptionReducer = (
  state = initialState,
  action: subscriptionActions
) => {
  switch (action.type) {
    /**
     * Get all subscriptions plan
     */
    case subscriptionConstants.GET_SUBSCRIPTION_PLAN_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case subscriptionConstants.GET_SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          plans: action.payload,
        },
        requesting: false,
        errors: null,
      };
    case subscriptionConstants.GET_SUBSCRIPTION_PLAN_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };
    /**
     * Subscribe to the plan from subscription component
     */
    case subscriptionConstants.SUBSCRIBE_PLAN_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case subscriptionConstants.SUBSCRIBE_PLAN_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
      };
    case subscriptionConstants.SUBSCRIBE_PLAN_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };
    /**
     * Unsubscribe from any paid plan
     */
    case subscriptionConstants.UNSUBSCRIBE_PLAN_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case subscriptionConstants.UNSUBSCRIBE_PLAN_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
      };
    case subscriptionConstants.UNSUBSCRIBE_PLAN_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };
    /**
     * Get subscription details for CurrentPlan component
     */
    case subscriptionConstants.GET_SUBSCRIPTION_DETAILS_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case subscriptionConstants.GET_SUBSCRIPTION_DETAILS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          subscription_details: action.payload,
        },
        requesting: false,
        errors: null,
      };
    case subscriptionConstants.GET_SUBSCRIPTION_DETAILS_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };
    /**
     * Get subscription services for subscription page
     */
    case subscriptionConstants.GET_SUBSCRIPTION_SERVICES_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case subscriptionConstants.GET_SUBSCRIPTION_SERVICES_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          subscription_services: action.payload,
        },
        requesting: false,
        errors: null,
      };
    case subscriptionConstants.GET_SUBSCRIPTION_SERVICES_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };
    /**
     * Get subscription payment statistics for subscription page
     */
    case subscriptionConstants.GET_PAYMENT_STATISTICS_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case subscriptionConstants.GET_PAYMENT_STATISTICS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          payment_statistics: action.payload,
        },
        requesting: false,
        errors: null,
      };
    case subscriptionConstants.GET_PAYMENT_STATISTICS_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default subscriptionReducer;
