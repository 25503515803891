import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { isSubscribeFinished } from 'components/Layout/PrivateLayout/helper';
import { modalSelector } from 'redux/selectors/modal';
import {
  currentPlanSelector,
  profileRoleSelector,
  upcomingPlanSelector,
} from 'redux/selectors/userProfile';
import { ROUTES, USER_ROLES } from 'utils/constants';

const useOnboarding = () => {
  const upcomingPlan = useSelector(upcomingPlanSelector);
  const userCurrentPlan = useSelector(currentPlanSelector);
  const userRole = useSelector(profileRoleSelector);
  const { pathname } = useLocation();
  const { isBlurOpen } = useSelector(modalSelector);

  return (
    /**
     * Case when we just registered, and log in to the dashboard
     * Only for b2c role
     */
    userRole.model === USER_ROLES.B2C &&
    ((isBlurOpen && pathname.indexOf(ROUTES.PROFILE.MAIN) < 0) ||
      /**
       * Show onboarding screen when our paid plan finished, and
       * we don't have upcoming plan
       */
      isSubscribeFinished(userCurrentPlan, upcomingPlan))
  );
};

export default useOnboarding;
