import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { getSettings } from 'api/settings';
import { getSettingsError, getSettingsSuccess } from 'redux/actions/settings';
import { settingsTypes } from 'redux/constants/settings';
import notify from 'utils/toast';

function* getSettingsWatcher() {
  yield takeEvery(settingsTypes.GET_SETTINGS_REQUEST, getSettingsWorker);
}

function* getSettingsWorker() {
  try {
    const { data: settingsData } = yield getSettings();
    if (settingsData.status === 'Error') {
      throw new Error(settingsData.message);
    }
    yield put(getSettingsSuccess(settingsData.data));
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    yield put(getSettingsError(error));
    yield notify('error', errorMessage);
  }
}

export default function* getSettingsSaga() {
  yield fork(getSettingsWatcher);
}
