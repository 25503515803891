import Shepherd from 'shepherd.js';

import scan1 from 'assets/icons/onboarding-tour/scans/scan-1.jpg';
import scan2 from 'assets/icons/onboarding-tour/scans/scan-2.jpg';
import scan3 from 'assets/icons/onboarding-tour/scans/scan-3.jpg';
import i18n from 'translation/i18n';

export const stepScans: Shepherd.Step.StepOptions = {
  id: 'step-scans',
  attachTo: { element: '.shepherd-upload-dicom', on: 'left' },
  beforeShowPromise: () =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        resolve();
      }, 500);
    }),
  classes: 'scans-modal',
  buttons: [],
  scrollTo: { behavior: 'smooth', block: 'center' },
  title: i18n.t('onboarding.create_examination.step_scans_text_1'),
  text: [
    `<div class="content">
      <p class="text">${i18n.t(
        'onboarding.create_examination.step_scans_text_2'
      )}
      <div class="scans">
      <img class="scan-1" src="${scan1}" alt="scan">
      <img class="scan-2" src="${scan2}" alt="scan">
      <img class="scan-3" src="${scan3}" alt="scan">
      </div>
    </div>`,
  ],
};
