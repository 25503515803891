/**
 * The component takes @params title, description, and
 * image with default data. These @params can be override
 * from any component
 */

import { memo } from 'react';

import { Helmet } from 'react-helmet';

import { IMetaInfoProps } from './MetaInfo.types';

import defaultMetaImage from 'assets/images/otc.jpg';

const defaultTitle = 'Altris AI Platform';
const defaultDescription =
  'Altris AI is an AI-powered, standalone, browser-based software for OCT scan interpretation created for the support of eye care specialists in diagnostic decision-making. It improves the diagnostic process for ophthalmologists and optometrists by automating the selection of pathological OCT scans and the detection of 54 pathological signs and 49 pathologies.';

const MetaInfo = memo((props: IMetaInfoProps): JSX.Element => {
  const {
    title = defaultTitle,
    description = defaultDescription,
    image = defaultMetaImage,
  } = props;

  return (
    <Helmet>
      {/* General tags */}
      <title>
        {title === defaultTitle ? defaultTitle : `${title} - Altris AI`}
      </title>

      {/* OpenGraph tags */}
      <meta
        property='og:title'
        content={title === defaultTitle ? defaultTitle : `${title} - Altris AI`}
      />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={image} />

      {/* Twitter Card tags */}
      <meta
        name='twitter:title'
        content={title === defaultTitle ? defaultTitle : `${title} - Altris AI`}
      />
      <meta property='twitter:description' content={description} />
      <meta property='twitter:image' content={image} />
    </Helmet>
  );
});

MetaInfo.displayName = 'MetaInfo';

export default MetaInfo;
