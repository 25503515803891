import { ReactNode } from 'react';

import cn from 'classnames';

import styles from './ProgressBar.module.scss';

interface ProgressBarProps {
  className?: string;
  percent: number;
  progressBarDescription?: ReactNode;
}

const ProgressBar = (props: ProgressBarProps) => {
  const { className, percent, progressBarDescription } = props;

  return (
    <div className={cn(className, styles['progress-outer'])}>
      <div className={styles.progress}>
        <div
          className={styles['progress-bar']}
          style={{ transform: `scaleX(${percent / 100})` }}
        >
          <div className={styles.runner}></div>
        </div>
      </div>

      {progressBarDescription && (
        <p className={styles.description}>{progressBarDescription}</p>
      )}
    </div>
  );
};

export default ProgressBar;
