import { userAuthenticatedConstants } from 'redux/constants/userAuthenticated';
import { methodType } from 'types/userAuthenticated';

export const getAuthenticatedTrue = (data: methodType) => ({
  type: userAuthenticatedConstants.USER_AUTHENTICATED_TRUE,
  payload: data,
});

export const getAuthenticatedFalse = () => ({
  type: userAuthenticatedConstants.USER_AUTHENTICATED_FALSE,
});
