import { ElementRef, memo, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import DOMPurify from 'dompurify';

import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg';
import { CustomTooltipTypes } from 'components/CustomTooltip/CustomTooltip.types';
import useDynamicViewportSize from 'hooks/useDynamicViewportSize';
import { MOBILE_RESOLUTION } from 'utils/constants';

import styles from './CustomTooltip.module.scss';

const CustomTooltip = memo(
  ({
    position = 'top',
    text,
    icon,
    tooltipText,
    className,
  }: CustomTooltipTypes) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const descriptionRef = useRef<ElementRef<'div'>>(null);

    const { vw } = useDynamicViewportSize();

    useEffect(() => {
      if (vw >= MOBILE_RESOLUTION && isModalOpen) {
        setIsModalOpen(false);
      }
    }, [vw]);

    if (isModalOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = '';

    return (
      <div className={cn(styles['wrapper'], className)}>
        <div
          className={cn(styles.trigger, {
            [styles['open-modal']]: isModalOpen,
          })}
          onClick={() => {
            if (vw < 992) return;
            setIsModalOpen(false);
          }}
        >
          {text && (
            <div
              onClick={(event) => {
                event.stopPropagation();

                if (vw > MOBILE_RESOLUTION) return;

                setIsModalOpen(true);
              }}
              className={styles['trigger-text']}
            >
              {text}
            </div>
          )}
          {icon && (
            <div
              className={cn(styles['trigger-icon'], {
                [styles['text-margin']]: text,
              })}
              onClick={(event) => {
                event.stopPropagation();

                if (vw > MOBILE_RESOLUTION) return;

                setIsModalOpen(true);
              }}
            >
              {icon}
            </div>
          )}

          {tooltipText && (
            <div className={cn(styles['tooltip-text'], styles[position])}>
              <div>
                <div
                  className={styles['tooltip-markup']}
                  ref={descriptionRef}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(tooltipText),
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                />

                <CancelIcon
                  onClick={() => {
                    setIsModalOpen(false);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);

CustomTooltip.displayName = 'CustomTooltip';

export default CustomTooltip;
