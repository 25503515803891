import { PURCHASE } from './endpoints';

import fetchData from 'utils/fetchData';

export const getAllPurchaseType = async () =>
  fetchData({ method: 'get', to: PURCHASE.GET_ALL_PURCHASE_TYPES, data: {} });
export const getPurchaseTypeBySlug = async (type) =>
  fetchData({
    method: 'get',
    to: PURCHASE.GET_PURCHASE_TYPE_BY_SLUG(type),
    data: {},
  });
export const paymentForPurchase = async (data) =>
  fetchData({
    method: 'post',
    to: PURCHASE.CREATE_PAYMENT_INTENT_FOR_PURCHASE,
    data: data,
  });
export const confirmPurchase = async (data) =>
  fetchData({
    method: 'post',
    to: PURCHASE.CONFIRM_PURCHASE,
    data: data,
  });
