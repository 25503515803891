import { fork, put, takeEvery } from 'redux-saga/effects';

import { EXAMINATION } from 'api/endpoints';
import { getPathologiesCalculationZoneRequest } from 'api/examination';
import { getExamMeasLayersRequest } from 'redux/actions/examination';
import { ExamActionTypes } from 'redux/constants/examination';
import { IWorkerProps } from 'redux/types/examination';
import { IMeasurementsScan } from 'types/examination';
import { revalidatePaths } from 'utils/apiCache';

function* measurementsLayerWatcher() {
  yield takeEvery<string, any>(
    ExamActionTypes.GET_PATHOLOGIES_CALCULATION_ZONE,
    measurementsPathologiesWorker
  );
}

interface IMeasurements extends IWorkerProps {
  type: ExamActionTypes;
  payload: {
    exam_id: string;
    scan_id: string;
  };
  action?: {
    default?: () => void;
    success?: (cleanData?: IMeasurementsScan) => void;
    error?: () => void;
  };
  abortSignal?: AbortSignal;
}

function* measurementsPathologiesWorker({
  payload,
  action,
  abortSignal,
}: IMeasurements) {
  action?.default?.();

  try {
    const { exam_id } = payload;

    const { data } = yield getPathologiesCalculationZoneRequest(
      exam_id,
      abortSignal
    );

    if (data.data === 'Successfully processed') {
      yield revalidatePaths([
        EXAMINATION.MEASUREMENTS_SCAN(payload.exam_id, payload.scan_id),
      ]);

      yield put(getExamMeasLayersRequest(payload));
    }
  } catch (error) {
    action?.error?.();
  }
}

export default function* measurementsPathologiesLayerSaga() {
  yield fork(measurementsLayerWatcher);
}
