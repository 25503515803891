import cn from 'classnames';

import './Spinnes.scss';

interface SpinnerTypes {
  className?: string;
}

const Spinner = ({ className }: SpinnerTypes) => {
  return (
    <div className={cn('lds-roller', className)}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Spinner;
