import { t } from 'i18next';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { confirmPurchaseWorker } from './confirmPurchase';

import { paymentForPurchase } from 'api/purchase';
import { closeModal } from 'redux/actions/modal';
import { getPatientRequest } from 'redux/actions/patient';
import {
  purchaseForExistingCardError,
  purchaseForExistingCardSuccess,
} from 'redux/actions/purchaseItem';
import { purchaseConstants } from 'redux/constants/purchase';
import notify from 'utils/toast';

function* purchaseForExistingCardWatcher() {
  yield takeEvery(
    purchaseConstants.PURCHASE_FOR_EXISTING_CARD_REQUEST,
    purchaseForExistingCardWorker
  );
}

function* purchaseForExistingCardWorker({ payload, action }) {
  try {
    const { data: payment_intent } = yield paymentForPurchase({
      purchase_item_id: payload.purchase_item_id,
      payment_method_id: payload.payment_method_id,

      /**
       * get this @param save_card, only when we pay from
       * Apple/Google pay. We don't want to save this card
       * in the stripe. When we use Apple/Google pay for
       * payment several times we will duplicate payment
       * methods in the stripe.
       */
      ...(!payload?.save_card && { save_card: false }),
    });

    const { paymentIntent } = yield payload.stripe.confirmCardPayment(
      payment_intent.data.client_secret
    );

    yield call(confirmPurchaseWorker, { payload: paymentIntent.id });

    if (action?.needPatients) {
      yield put(getPatientRequest());
    }

    yield put(purchaseForExistingCardSuccess());
    yield put(closeModal());
    yield notify(
      'success',
      t('notifications.you_successfully_buy_additional_examination')
    );
  } catch (error) {
    yield put(purchaseForExistingCardError(error));
    yield notify('error', t('notifications.something_went_wrong'));
  }
}

export default function* purchaseForExistingCardSaga() {
  yield fork(purchaseForExistingCardWatcher);
}
