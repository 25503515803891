import { memo } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CheckIcon } from 'assets/icons/check-mark-rounded.svg';
import { MainCardSkeleton } from 'components/SubscriptionPlanCard';
import Text from 'components/Text';
import { getComponentCount, getRandomString } from 'utils/helpers';

import styles from './SubscriptionTable.module.scss';

const Skeleton = memo(() => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'components.subscription_plans',
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.body}>
        <div className={styles.borders}>
          <div className={cn(styles.column, styles.aside)}>
            <div className={styles.plan}>
              <Text className={styles.title}>{t('subscription')}</Text>
              <Text>{t('price')}</Text>
              <Text>{t('examinations_available')}</Text>
            </div>
          </div>
          <div
            className={cn(styles.column, styles.aside, styles['table-border'])}
          >
            <div className={styles.table}>
              <Text className={styles['table-title']}>{t('services')}</Text>
              {getComponentCount(
                <div>
                  <div className={styles['table-label']}>
                    <Text className={styles['label-text']}>
                      {t('service')} {getRandomString()}
                    </Text>
                  </div>
                </div>,
                10
              )}
            </div>
          </div>
        </div>
        {getComponentCount(
          <div className={cn(styles.borders)}>
            <div className={cn(styles.column)}>
              <div className={styles.plan}>
                <MainCardSkeleton />
              </div>
            </div>
            <div className={cn(styles.column, styles['table-border'])}>
              <div className={cn(styles.table)}>
                <div className={styles['table-title']}></div>
                {getComponentCount(
                  <div className={styles.status}>
                    <CheckIcon />
                  </div>,
                  10
                )}
              </div>
            </div>
          </div>,
          3
        )}
      </div>
    </div>
  );
});

Skeleton.displayName = 'Skeleton';

export default Skeleton;
