import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { deleteExamination } from 'api/examination';
import {
  deleteExaminationError,
  deleteExaminationSuccess,
} from 'redux/actions/examination';
import { closeModal } from 'redux/actions/modal';
import { getPatientRequest } from 'redux/actions/patient';
import { ExamActionTypes } from 'redux/constants/examination';
import { IWorkerProps } from 'redux/types/examination';
import notify from 'utils/toast';

function* deleteExaminationWatcher() {
  yield takeEvery(
    ExamActionTypes.DELETE_EXAMINATION_REQUEST,
    deleteExaminationWorker
  );
}

interface IDeleteExamWorker extends IWorkerProps {
  type: ExamActionTypes.DELETE_EXAMINATION_REQUEST;
}

function* deleteExaminationWorker({ payload }: IDeleteExamWorker) {
  const { exam_id } = payload;

  try {
    const { data: deletePatient } = yield deleteExamination({ exam_id });
    yield put(getPatientRequest());
    if (deletePatient.status === 'Error') {
      throw new Error(deletePatient.message);
    }

    yield put(deleteExaminationSuccess());
    yield notify(
      'success',
      t('notifications.examination_deleted_successfully')
    );
    yield put(closeModal());
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    console.error(error);
    yield put(deleteExaminationError(errorMessage));
    yield notify('error', errorMessage);
    yield put(closeModal());
  }
}

export default function* deleteExaminationSaga() {
  yield fork(deleteExaminationWatcher);
}
