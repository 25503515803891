import { ElementRef, memo, useLayoutEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import { BOOKING_URL, DEMO_ACCOUNT_GOOGLE_ANALYTICS } from 'utils/constants';
import { ga } from 'utils/helpers';

import styles from './DemoBookBanner.module.scss';

const DemoBookBanner = memo(() => {
  const bannerRef = useRef<ElementRef<'div'>>(null);

  const { t } = useTranslation(undefined, {
    keyPrefix: 'components.layout.private_layout',
  });

  useLayoutEffect(() => {
    const handleResize = () => {
      if (!bannerRef.current) return;

      document.documentElement.style.setProperty(
        '--demo-banner-height',
        `${bannerRef.current.offsetHeight}px`
      );
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={styles.wrapper} ref={bannerRef}>
      <p className={styles.title}>{t('demo_banner_title')}</p>

      <Button
        to={BOOKING_URL}
        onClick={() => {
          ga(DEMO_ACCOUNT_GOOGLE_ANALYTICS.OPENED_DEMO_BOOK);
        }}
        className={styles.button}
      >
        {t('book_a_demo')}
      </Button>
    </div>
  );
});

DemoBookBanner.displayName = 'DemoBookBanner';

export default DemoBookBanner;
