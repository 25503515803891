import { AUTH } from './endpoints';

import fetchData from 'utils/fetchData';

export const loginUser = async (data) =>
  fetchData({ method: 'post', to: AUTH.SIGN_IN, data: data });
export const logout = async () =>
  fetchData({ method: 'post', to: AUTH.LOGOUT, data: {} });
export const resetConfirmation = async (data) =>
  fetchData({ method: 'post', to: AUTH.RESET_CONFIRMATION, data: data });
export const checkEmailForgotPassword = async (data) =>
  fetchData({ method: 'post', to: AUTH.RESET_PASSWORD, data: data });
export const loginUserByGoogle = async (data) =>
  fetchData({ method: 'post', to: AUTH.GOOGLE_SIGN_IN, data: data });
export const getProfessions = async () =>
  fetchData({
    method: 'get',
    to: AUTH.GET_PROFESSIONS,
    data: {},
    isCached: true,
  });
export const getCountries = async () =>
  fetchData({
    method: 'get',
    to: AUTH.GET_COUNTRIES,
    data: {},
    isCached: true,
  });
export const getOctProducers = async () =>
  fetchData({ method: 'get', to: AUTH.GET_OCT_PRODUCERS, isCached: true });
export const registerUser = async (data) =>
  fetchData({ method: 'post', to: AUTH.SIGN_UP, data: data });
export const getInvitedUserData = async (data) =>
  fetchData({ method: 'get', to: AUTH.GET_INVITED_USER_DATA(data), data: {} });
export const updateInvitedUserData = async (id, data) =>
  fetchData({
    method: 'put',
    to: AUTH.UPDATE_INVITED_USER_DATA(id),
    data: data,
  });
export const sendOcuPlanForm = async (data) =>
  fetchData({
    method: 'post',
    to: AUTH.OCUPLAN_FORM,
    data: data,
  });
