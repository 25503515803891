import { memo } from 'react';

import classNames from 'classnames';
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';

import styles from './MenuItem.module.scss';

const MenuItem = memo(
  ({ action, link, children, title, className, ...props }) => {
    let resolved = useResolvedPath(link);
    let match = useMatch({ path: resolved.pathname, end: true });

    /** Temporary solution DELETE WHEN STEP 2 will be ready */
    if (action) {
      return (
        <a
          onClick={action}
          className={classNames(className, {
            [styles.active]: match,
          })}
        >
          {children}
          <span>{title}</span>
        </a>
      );
    }
    /** Temporary solution DELETE WHEN STEP 2 will be ready */

    return (
      <>
        <NavLink
          to={link}
          {...props}
          className={classNames(className, {
            [styles.active]: match,
          })}
        >
          {children}
          <span>{title}</span>
        </NavLink>
      </>
    );
  }
);

MenuItem.displayName = 'Item';

export default MenuItem;
