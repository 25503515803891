import { memo, useEffect } from 'react';

import cn from 'classnames';
import { Form, Formik } from 'formik';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { RegisterUserInfo } from '../RegisterUser';

import customStyles from './customSelectStyles';

import { ReactComponent as GlassesIcon } from 'assets/icons/glasses.svg';
import { ReactComponent as MapPinIcon } from 'assets/icons/map-pin.svg';
import Button from 'components/Button';
import FormField from 'components/FormComponents';
import HistoryLink from 'components/HistoryLink';
import Text from 'components/Text';
import { getProfessionsCountriesAndProducersRequest } from 'redux/actions/professionsCountriesAndProducers';
import { selectProfessionsAndCountries } from 'redux/selectors/professionAndCountry';
import { ICountry, IProfession } from 'types/professionAndCountry';
import { ROUTES } from 'utils/constants';
import { ga } from 'utils/helpers';
import { validateRegisterStepTwo } from 'validation';

import styles from './StepTwo.module.scss';

interface StepTwoProps {
  nextHandler: (value: RegisterUserInfo) => void;
  prevHandler: () => void;
  userInfo: RegisterUserInfo;
}

const StepTwo = memo(({ nextHandler, prevHandler, userInfo }: StepTwoProps) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(undefined, {
    keyPrefix: 'register_user_page.step_three',
  });

  const analyticsHandler = () => {
    const query = qs.parse(location.search);

    if (query.teamMember) {
      ga('Registration_s3_Team_member');
      return;
    }

    if (query.coupon) {
      ga(`Registration_s3_${query.coupon}`);
      return;
    }

    ga('Registration_s3');
  };

  const { professions, countries } = useSelector(selectProfessionsAndCountries);

  useEffect(() => {
    if (!professions.length || !countries.length) {
      dispatch(getProfessionsCountriesAndProducersRequest());
    }
  }, []);

  const transformDataForSelect = (data: (IProfession | ICountry)[]) => {
    return data?.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  };

  const initialValues = {
    profession_id: userInfo.profession_id,
    country_id: userInfo.country_id,
    privacy: false,
  };

  return (
    <>
      <Text className={styles.title} color='light' tag='h1' align='center'>
        {t('create_an_account')}
      </Text>
      <Formik
        initialValues={initialValues}
        validationSchema={() => validateRegisterStepTwo(i18n.t)}
        onSubmit={(value) => {
          analyticsHandler();
          nextHandler({
            ...userInfo,
            profession_id: value.profession_id,
            country_id: value.country_id,
          });
        }}
      >
        {({ setFieldValue }) => {
          return (
            <Form className={styles.form}>
              <FormField
                options={transformDataForSelect(professions)}
                customStyles={customStyles}
                component={'select'}
                name={'profession_id'}
                id={'profession_id'}
                placeholder={t('profession_placeholder')}
                className={styles.field}
                isSearchable
                onChange={(
                  item: ReturnType<typeof transformDataForSelect>[number]
                ) => setFieldValue('profession_id', item.value)}
                icon={<GlassesIcon />}
                errorType={'simple'}
              />

              <FormField
                options={transformDataForSelect(countries)}
                customStyles={customStyles}
                component={'select'}
                name={'country_id'}
                id={'country_id'}
                placeholder={t('country_placeholder')}
                className={cn(styles.field, styles.country)}
                isSearchable
                onChange={(
                  item: ReturnType<typeof transformDataForSelect>[number]
                ) => setFieldValue('country_id', item.value)}
                icon={<MapPinIcon />}
                errorType={'simple'}
              />
              <Button
                type='submit'
                className={cn(styles.signin, styles.button)}
              >
                {t('create_an_account')}
              </Button>
              <div className={styles['privacy-wrapper']}>
                <FormField
                  component='checkbox'
                  name={'privacy'}
                  id={'privacy'}
                  className={styles.checkbox}
                />
                <Text className={styles['checkbox-text']} color='light'>
                  {t('i_agree_with')}{' '}
                  <HistoryLink className={styles.link} to={ROUTES.LAW.PRIVACY}>
                    {t('privacy_policy')}
                  </HistoryLink>{' '}
                  {t('and')}
                  <HistoryLink
                    className={styles.link}
                    to={ROUTES.LAW.TERMS_AND_CONDITIONS}
                  >
                    {' '}
                    {t('terms_and_conditions')}
                  </HistoryLink>
                </Text>
              </div>
            </Form>
          );
        }}
      </Formik>
      <Button
        onClick={() => prevHandler()}
        className={cn(styles.signin, styles.button)}
        appearance='simple'
      >
        {t('back')}
      </Button>
    </>
  );
});

StepTwo.displayName = 'StepTwo';

export default StepTwo;
