import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as NotesIco } from 'assets/icons/notes.svg';
import { userProfileSelector } from 'redux/selectors/userProfile';

interface ExamCounterProps {
  className?: string;
}

import styles from './ExamCounter.module.scss';

export const ExamCounter = (props: ExamCounterProps) => {
  const { className } = props;

  const profile = useSelector(userProfileSelector);
  const examinationCount = profile.data.examinations_left;

  const { t } = useTranslation(undefined, { keyPrefix: 'components.header' });

  return (
    <div className={cn(styles.wrapper, className)}>
      <div className={styles['icon-wrapper']}>
        <NotesIco />
      </div>
      <p className={styles.text}>{t('examinations_left')}</p>
      <p className={styles.count}>
        : {examinationCount === -1 ? t('unlimited') : examinationCount}
      </p>
    </div>
  );
};
