import { EXAMINATION } from './endpoints';

import {
  ICreateExamination,
  IExamScanRequest,
  IMeasDistanceRequest,
  IUpdateExamData,
  IUpdateMeasData,
  IUpdateMeasManualData,
} from 'types/examination';
import fetchData from 'utils/fetchData';

export const createExamination = async (data: ICreateExamination) =>
  fetchData({
    method: 'post',
    to: EXAMINATION.EXAMINATION,
    data: data,
    progressBar: true,
  });

export const getExamination = async (
  data: { exam_id: string },
  abortSignal?: AbortSignal
) =>
  fetchData({
    method: 'get',
    to: EXAMINATION.EXAMINATION_ID(data.exam_id),
    data: {},
    abortSignal,
  });
export const updateExamination = async (id: string, data: IUpdateExamData) =>
  fetchData({
    method: 'put',
    to: EXAMINATION.EXAMINATION_ID(id),
    data: data,
  });
export const deleteExamination = async (data: { exam_id: string }) =>
  fetchData({
    method: 'delete',
    to: EXAMINATION.EXAMINATION_ID(data.exam_id),
    data: {},
  });

export const getExaminationStatus = async (data: { exam_id: string }) =>
  fetchData({
    method: 'get',
    to: EXAMINATION.EXAMINATION_STATUS(data.exam_id),
    data: {},
  });

export const getSegmentationScans = async (
  id: string,
  scansId: string[],
  abortSignal?: AbortSignal
) =>
  fetchData({
    method: 'post',
    to: EXAMINATION.EXAMINATION_SCANS(id),
    data: { scans_ids: scansId },
    isCached: true,
    abortSignal,
  });

export const getExamLayer = async (id: string, scan_id: string) =>
  fetchData({
    method: 'get',
    to: EXAMINATION.LAYER(id, scan_id),
    data: {},
    isCached: true,
  });

export const getExamLayersStatus = async (
  data: { exam_id: string },
  abortSignal?: AbortSignal
) =>
  fetchData({
    method: 'get',
    to: EXAMINATION.LAYERS_STATUS(data.exam_id),
    data: {},
    abortSignal,
  });

export const setExamFavoriteScan = async ({
  exam_id,
  scan_id,
}: {
  exam_id: string;
  scan_id: { scan_id: string };
}) =>
  fetchData({
    method: 'put',
    to: EXAMINATION.STARRED(exam_id),
    data: scan_id,
  });

export const getMainETDRS = async (id: string) =>
  fetchData({
    method: 'get',
    to: EXAMINATION.ETDRS_MAIN(id),
    data: {},
    isCached: true,
  });
export const getLayerETDRS = async (exam_id: string, layers_slug: string) =>
  fetchData({
    method: 'get',
    to: EXAMINATION.ETDRS_LAYER(exam_id, layers_slug),
    data: {},
    isCached: true,
  });
export const getGccETDRS = async (exam_id: string, layers_slug: string) =>
  fetchData({
    method: 'get',
    to: EXAMINATION.ETDRS_GCC(exam_id, layers_slug),
    data: {},
    isCached: true,
  });
export const getThicknessMap = async (exam_id: string, layers_slug?: string) =>
  fetchData({
    method: 'get',
    to: EXAMINATION.THICKNESS_MAP(exam_id, layers_slug),
    data: {},
    isCached: true,
  });

export const getMeasurementsScan = async (
  data: {
    exam_id: string;
    scan_id: string;
  },
  abortSignal?: AbortSignal
) =>
  fetchData({
    method: 'get',
    to: EXAMINATION.MEASUREMENTS_SCAN(data.exam_id, data.scan_id),
    data: {},
    abortSignal,
  });
export const updateMeasurementsScan = async (data: IUpdateMeasData) =>
  fetchData({
    method: 'put',
    to: EXAMINATION.MEASUREMENTS_SCAN(data.exam_id, data.scan_id),
    data: { measurements: data.pathologies },
  });
export const deleteMeasurementsScan = async (data: IExamScanRequest) =>
  fetchData({
    method: 'delete',
    to: EXAMINATION.MEASUREMENTS_SCAN(data.exam_id, data.scan_id),
    data: {},
  });
export const getMeasManualScan = async (
  data: {
    exam_id: string;
    scan_id: string;
  },
  abortSignal?: AbortSignal
) =>
  fetchData({
    method: 'get',
    to: EXAMINATION.MEASUREMENTS_MANUAL_SCAN(data.exam_id, data.scan_id),
    data: {},
    abortSignal,
  });

export const updateMeasManualScan = async (data: IUpdateMeasManualData) =>
  fetchData({
    method: 'put',
    to: EXAMINATION.MEASUREMENTS_MANUAL_SCAN(data.exam_id, data.scan_id),
    data: { measurements: data.lines },
  });
export const getMeasPointsDistance = async (data: IMeasDistanceRequest) =>
  fetchData({
    method: 'get',
    to: EXAMINATION.MEASUREMENTS_DISTANCE(data.exam_id, data.points),
    data: {},
  });
export const getMeasManualId = async (
  data: { exam_id: string },
  abortSignal?: AbortSignal
) =>
  fetchData({
    method: 'get',
    to: EXAMINATION.MEASUREMENTS_MANUAL_ID(data.exam_id),
    data: {},
    abortSignal,
  });

export const getPathologiesCalculationZoneRequest = async (
  exam_id: string,
  abortSignal?: AbortSignal
) =>
  fetchData({
    method: 'put',
    to: EXAMINATION.GET_PATHOLOGIES_CALCULATION_ZONE(exam_id),
    abortSignal,
  });

export const getGlaucomaData = async (
  exam_id: string,
  abortSignal?: AbortSignal
) => {
  return fetchData({
    method: 'get',
    to: EXAMINATION.GET_GLAUCOMA(exam_id),
    abortSignal,
    isCached: true,
  });
};

export const getProgressCommonPathologiesData = async (
  examination_ids: string[],
  abortSignal?: AbortSignal
) =>
  fetchData({
    method: 'get',
    to: EXAMINATION.GET_PROGRESS_COMMON_EXAMS_DATA,
    params: {
      examination_ids,
    },
    abortSignal,
  });

export const getProgressCommonHeatmapData = async (
  data: {
    examination_ids: string[];
    pathology_id: string;
  },
  abortSignal?: AbortSignal
) =>
  fetchData({
    method: 'get',
    to: EXAMINATION.GET_PROGRESS_COMMON_HEATMAP_DATA,
    params: data,
    abortSignal,
    isCached: true,
  });

export const getProgressPathologyScansData = async (
  data: {
    scan_ids: string[];
    pathology_id: string;
  },
  abortSignal?: AbortSignal
) =>
  fetchData({
    method: 'get',
    to: EXAMINATION.GET_PROGRESS_PATHOLOGY_SCANS_DATA,
    params: data,
    abortSignal,
    isCached: true,
  });

export const getProgressionState = async (
  exam_id: string,
  abortSignal?: AbortSignal
) =>
  fetchData({
    method: 'get',
    to: EXAMINATION.GET_PROGRESSION_STATE(exam_id),
    abortSignal,
  });

export const updateProgressionState = async (examination_ids: string[]) =>
  fetchData({
    method: 'put',
    to: EXAMINATION.UPDATE_PROGRESSION_STATE,
    data: {
      examination_ids,
    },
  });

export const getBothEyesOppositeEye = async (
  exam_id: string,
  abortSignal?: AbortSignal
) =>
  fetchData({
    method: 'get',
    to: EXAMINATION.BOTH_EYES_GET_OPPOSITE_EYE(exam_id),
    abortSignal,
  });

export const getProgressionReportMostSevereScans = async (
  data: {
    examination_ids: string[];
    pathology_id: string;
  },
  abortSignal?: AbortSignal
) =>
  fetchData({
    method: 'get',
    to: EXAMINATION.GET_PROGRESS_MOST_SEVERE_SCANS,
    params: data,
    abortSignal,
  });
