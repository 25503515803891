import { memo } from 'react';

import cn from 'classnames';

import { Header, HeaderWithTab } from './Header';
import { IPaperProps } from './Paper.types';

import styles from './Paper.module.scss';

const Paper = memo(({ children, type, tabData, className }: IPaperProps) => {
  if (tabData) {
    return (
      <div className={styles.paper}>
        <HeaderWithTab tabData={tabData} className={styles.header} />
      </div>
    );
  }

  return (
    <div className={styles.paper}>
      {type !== 'empty' && <Header type={type} className={styles.header} />}

      <div className={cn(styles.body, className)}>{children}</div>
    </div>
  );
});

Paper.displayName = 'Paper';

export default Paper;
