import { Points } from 'types/examination';

export const AUTH = {
  SIGN_IN: '/auth/sign-in',
  SIGN_UP: '/auth/sign-up',
  GOOGLE_SIGN_IN: '/auth/google/callback',
  GOOGLE_SIGN_UP: '/auth/google/sign-up',
  RESET_PASSWORD: '/auth/reset-password',
  RESET_CONFIRMATION: '/auth/reset-confirmation',
  LOGOUT: '/auth/logout',
  GET_PROFESSIONS: '/professions',
  GET_COUNTRIES: '/location/countries',
  GET_OCT_PRODUCERS: '/get-oct-producers',
  GET_INVITED_USER_DATA: (invited_token: string) =>
    `/auth/invited-user-data/${invited_token}`,
  UPDATE_INVITED_USER_DATA: (id: number) => `/auth/invited-user-data/${id}`,
  OCUPLAN_FORM: '/consent-form-record',
};

export const USER = {
  CHECK_EMAIL: '/user/check-email',
  USER_INFO: '/user',
  USER_UPDATE_INFO: '/user/update',
  USER_UPDATE_PASSWORD: '/user/update-password',
  USER_GET_CODE_FOR_UPDATE_PASSWORD: '/user/update-password/code',
  USER_UPDATE_REPORT_SETTINGS: '/user/update/report-settings',
  PROGRESSION_SETTINGS: '/user/progression-settings',
};

export const EXAMINATION = {
  EXAMINATION: '/examination',
  // EXAMINATION_SEGMENTATION: (id: string) => `/examination/${id}/segmentation`,
  EXAMINATION_ID: (id: string) => `/examination/${id}`,
  EXAMINATION_STATUS: (id: string) => `/examination/${id}/status`,
  EXAMINATION_SCANS: (id: string) =>
    `/examination/${id}/segmentation/specified-scans`,
  EXAMINATION_SCAN_DESCRIPTION: (id: string) =>
    `/examination/${id}/update-scan-description`,
  LAYER: (id: string, scan_id: string) =>
    `/examination/${id}/segmentation-layers/${scan_id}`,
  LAYERS_STATUS: (id: string) =>
    `/examination/${id}/segmentation-layers/status`,
  STARRED: (exam_id: string) => `/examination/${exam_id}/star-scan`,
  ETDRS_MAIN: (exam_id: string) => `/examination/${exam_id}/default-etdrs`,
  ETDRS_LAYER: (exam_id: string, layers_slug: string) =>
    `/examination/${exam_id}/etdrs/${layers_slug}`,
  ETDRS_GCC: (exam_id: string, layers_slug: string) =>
    `/examination/${exam_id}/gcc/${layers_slug}`,
  THICKNESS_MAP: (exam_id: string, layers_slug?: string) =>
    `/examination/${exam_id}/heat-map/${layers_slug ? layers_slug : ''}`,
  MEASUREMENTS_SCAN: (exam_id: string, scan_id?: string) =>
    `/examination/${exam_id}/measurements/${scan_id}`,
  MEASUREMENTS_MANUAL_SCAN: (exam_id: string, scan_id?: string) =>
    `/examination/${exam_id}/measurements/${scan_id}/manual`,
  MEASUREMENTS_DISTANCE: (exam_id: string, points: Points) =>
    `/examination/${exam_id}/measurements/get-distance-between-two-points/{"x":${points.p1.x.toFixed(
      0
    )},"y":${points.p1.y.toFixed(0)}}/{"x":${points.p2.x.toFixed(
      0
    )},"y":${points.p2.y.toFixed(0)}}`,
  MEASUREMENTS_MANUAL_ID: (exam_id: string) =>
    `/examination/${exam_id}/measurements/get-scans-with-manual-measurements`,
  GET_PATHOLOGIES_CALCULATION_ZONE: (exam_id: string) =>
    `/examination/${exam_id}/measurements/recalculate-pathologies-zones`,
  GET_GLAUCOMA: (exam_id: string) => `/examination/${exam_id}/get-glaucoma`,
  GET_PROGRESS_COMMON_EXAMS_DATA: '/examination/progression/get-pathologies',
  GET_PROGRESS_COMMON_HEATMAP_DATA:
    '/examination/progression/get-pathology-data',
  GET_PROGRESS_PATHOLOGY_SCANS_DATA: '/examination/progression/get-scans-data',
  GET_PROGRESSION_STATE: (exam_id: string) =>
    `/examination/progression/get-state/${exam_id}`,
  UPDATE_PROGRESSION_STATE: '/examination/progression/update-state',
  BOTH_EYES_GET_OPPOSITE_EYE: (exam_id: string) =>
    `/examination/${exam_id}/get-opposite-eye`,
  GET_PROGRESS_MOST_SEVERE_SCANS:
    '/examination/progression/get-most-severity-scans',
};

export const PATIENT = {
  PATIENT: '/patient',
  PATIENT_ID: (id: number) => `/patient/${id}`,
  GET_ALL_PATIENTS: '/user/patients',
  FILTER_PATIENTS: (
    medic_ids: number[],
    examinations_dates_range: number[]
  ) => {
    return `/user/patients?medic_ids=${JSON.stringify(
      medic_ids
    )}&examinations_dates_range=${JSON.stringify(examinations_dates_range)}`;
  },
};

export const SUBSCRIPTION = {
  SUBSCRIPTION_DETAILS: '/subscriptions',
  UNSUBSCRIBE_PLAN: '/subscriptions',
  PLANS: '/subscriptions/plans',
  SUBSCRIBE_PLAN: '/subscriptions/subscribe',
  MAIN_SERVICES: '/subscriptions/main-services',
  PAYMENT_STATISTICS: '/user/payment-statistics',
};

export const PURCHASE = {
  GET_ALL_PURCHASE_TYPES: '/purchase/purchase-types',
  GET_PURCHASE_TYPE_BY_SLUG: (type: string) =>
    `/purchase/purchase-types/${type}/purchase-items`,
  CREATE_PAYMENT_INTENT_FOR_PURCHASE:
    '/purchase/create-payment-intent-for-purchase',
  CONFIRM_PURCHASE: '/purchase/confirm-purchase',
};

export const CONTACT_US = {
  SEND_FORM: '/user/contact-us',
};

export const SURVEY = {
  UNSUBSCRIBE: '/poll/handle-result',
};

export const TEAM_MEMBER = {
  GET_ALL_TEAM_MEMBERS: '/user/team',
  INVITE_TEAM_MEMBER: '/user/invite',
  DELETE_TEAM_MEMBER: (id: number) => `/user/${id}`,
  REINVITE_TEAM_MEMBER: (id: number) => `/user/reinvite/${id}`,
};

export const STRIPE = {
  DELETE_PAYMENT_METHOD: '/user/payment-method',
  GET_PAYMENT_METHODS: '/subscriptions/payment-methods',
  PAYMENT_INTENT: '/subscriptions/payment-intent',
};

export const USER_BACKUP = {
  GET_DATA: '/examination/download',
};

export const SETTINGS = {
  GET_SETTINGS: '/user/platform-settings',
  SAVE_SETTINGS: '/user/platform-settings',
};

export const VERIFY = '/verify';

export const DEMO = {
  SEND_EMAIL: '/demo/send-email/',
  RE_SEND_CODE: '/demo/re-send-code',
  VERIFICATION_CODE: '/demo/verification-code',
  SIGN_UP: '/demo/complete-sign-up/',
};
