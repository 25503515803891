import { memo, useEffect, useMemo, useState, useCallback } from 'react';

import cn from 'classnames';
import { useSelector } from 'react-redux';

import useTabsHeading from './hooks/useTabsHeading';
import { PatientItem } from './PatientItem';

import { ReactComponent as TriangleIcon } from 'assets/icons/triangle.svg';
import Text from 'components/Text';
import { useDashboardContext } from 'contexts/Dashboard';
import { patientsSelector } from 'redux/selectors/patients';
import { EColorToSeverity } from 'types/examination';
import { IPatient } from 'types/patients';

import styles from './PatientList.module.scss';

type SortingSetting = {
  sortBy: keyof IPatient | string;
  order: 'asc' | 'desc';
};

const PatientList = memo(() => {
  const {
    activePatient,
    setActivePatient,
    setSelectedExam,
    lastActivePatient,
    filteredPatients,
  } = useDashboardContext();

  const {
    data: { patients },
  } = useSelector(patientsSelector);

  const tabsHeading = useTabsHeading();

  const [sortSettings, setSortSettings] = useState<SortingSetting>({
    sortBy: '',
    order: 'asc',
  });

  const handleSort = useCallback(
    (field: string) => {
      const newSortOrder =
        sortSettings.sortBy === field && sortSettings.order === 'asc'
          ? 'desc'
          : 'asc';

      const sortBy =
        sortSettings.sortBy === field && sortSettings.order === 'desc'
          ? ''
          : field;

      setSortSettings({ sortBy, order: newSortOrder });
    },
    [sortSettings, setSortSettings]
  );

  useEffect(() => {
    if (patients.length) {
      let selectedPatient;

      if (lastActivePatient) {
        selectedPatient = patients.find(
          (item) => item.id === Number(lastActivePatient)
        );
        document
          .querySelector(`[data-patient-id='${lastActivePatient}']`)
          ?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });

        localStorage.removeItem('last_patient');
      } else {
        selectedPatient = patients.find(
          (item) => item.id === activePatient?.id
        );
        document
          .querySelector(`[data-patient-id='${activePatient?.id}']`)
          ?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
      }

      setActivePatient(selectedPatient || patients[0]);
      setSelectedExam(
        (selectedPatient?.examinations || patients[0].examinations) ?? []
      );
    }
  }, [patients, lastActivePatient]);

  const sortedPatients = () => {
    const { order, sortBy } = sortSettings;

    const sortOrderMultiplier = order === 'asc' ? -1 : 1;

    switch (sortBy) {
      case 'name':
      case 'surname':
      case 'pid':
      case 'description':
      case 'gender':
        return filteredPatients.sort((first, second) => {
          const firstValue = first[sortBy] ?? '';
          const secondValue = second[sortBy] ?? '';

          return firstValue.localeCompare(secondValue) * sortOrderMultiplier;
        });

      case 'birth_date':
      case 'last_examination_date':
        return filteredPatients.sort((first, second) => {
          const firstValue = new Date(first[sortBy] ?? '0').getTime();
          const secondValue = new Date(second[sortBy] ?? '0').getTime();

          return (firstValue - secondValue) * sortOrderMultiplier;
        });

      case 'severity_level':
        return filteredPatients.sort((first, second) => {
          const fs = first.severity_level;
          const ss = second.severity_level;

          if (fs && ss)
            return (
              (EColorToSeverity[fs] - EColorToSeverity[ss]) *
              sortOrderMultiplier
            );
          else if (!fs && !ss) return 0;
          else return (fs ? -1 : 1) * sortOrderMultiplier;
        });

      default:
        return filteredPatients.sort((first, second) => {
          const firstValue = new Date(
            first['last_examination_date'] ?? '0'
          ).getTime();
          const secondValue = new Date(
            second['last_examination_date'] ?? '0'
          ).getTime();

          return (firstValue - secondValue) * sortOrderMultiplier;
        });
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        {tabsHeading.map((tabHead) => (
          <button
            className={styles.button}
            onClick={() => handleSort(tabHead.name)}
            key={tabHead.id}
          >
            <div className={styles.arrowWrapper}>
              <TriangleIcon
                className={cn(styles.arrow, styles.top, {
                  [styles.grey]:
                    sortSettings.order === 'desc' ||
                    tabHead.name !== sortSettings.sortBy,
                })}
              />
              <TriangleIcon
                className={cn(styles.arrow, styles.bottom, {
                  [styles.grey]:
                    sortSettings.order === 'asc' ||
                    tabHead.name !== sortSettings.sortBy,
                })}
              />
            </div>
            <Text color='dark' className={styles.heading}>
              {tabHead.title}
            </Text>
          </button>
        ))}
      </div>
      <div className={cn(styles.list)}>
        {sortedPatients().map((item: IPatient, index) => (
          <PatientItem
            key={`${item.id}-${index}`}
            patient={item}
            isActive={item.id === activePatient?.id}
          />
        ))}
      </div>
    </div>
  );
});

PatientList.displayName = 'PatientList';

export default PatientList;
