import allScansSteps from './AllScans';
import bothEyesSteps from './BothEyes';
import comparisonSteps from './Comparison';
import measurementsSteps from './Measurements';
import pathologiesDetectionSteps from './PathologiesDetection';
import progressionSteps from './Progression';
import singleScanSteps from './SingleScan';

export default [
  ...singleScanSteps,
  ...pathologiesDetectionSteps,
  ...measurementsSteps,
  ...allScansSteps,
  ...bothEyesSteps,
  ...comparisonSteps,
  ...progressionSteps,
];
