import getCodeUpdateUserPassword from './getCodeUpdateUserPassword';
import getUserProfile from './getUserProfile';
import updateReportSettings from './updateReportSettings';
import updateUserPassword from './updateUserPassword';
import updateUserProfile from './updateUserProfile';

export default [
  updateReportSettings,
  getCodeUpdateUserPassword,
  updateUserPassword,
  updateUserProfile,
  getUserProfile,
];
