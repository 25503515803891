import { t } from 'i18next';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { subscribePlanWorker } from '../subscription/subscribePlan';

import { closeBlurModal, closeModal } from 'redux/actions/modal';
import { getPatientRequest } from 'redux/actions/patient';
import { payStripeError, payStripeSuccess } from 'redux/actions/stripe';
import { stripeConstants } from 'redux/constants/stripe';
import notify from 'utils/toast';

function* payStripeWatcher() {
  yield takeEvery(stripeConstants.PAY_BY_CARD_REQUEST, payStripeWorker);
}

function* payStripeWorker({ payload, action }) {
  try {
    const result = yield payload.stripe.confirmCardSetup(payload.clientSecret, {
      payment_method: {
        card: payload.cardElement,
      },
    });

    const paymentData = {
      payment_method_id: result.setupIntent.payment_method,
      plan_price_id: payload.purchaseID,

      /**
       * get this @param grace_period, only when click on the
       * "Try for free" button on the Subscription page or
       * first login and payment by new card
       */
      ...(action?.grace_period && { is_trial: true }),
    };

    /**
     * We use call method to wait for this task to complete
     */
    yield call(subscribePlanWorker, { payload: paymentData });

    /**
     * Updating patient list after subscribing to a new plan
     * for update examinations_left
     */
    if (action?.needPatients) {
      yield put(getPatientRequest());
      yield put(closeBlurModal());
    }

    yield put(payStripeSuccess());
    yield put(closeModal());

    /**
     * Redirect to the main page after successful payment
     */
    if (action?.redirect) {
      action.redirect();
    }
  } catch (error) {
    yield put(payStripeError(error));
    yield notify('error', t('notifications.something_went_wrong'));
  }
}

export default function* payStripeSaga() {
  yield fork(payStripeWatcher);
}
