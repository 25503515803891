import { STRIPE } from './endpoints';

import fetchData from 'utils/fetchData';

export const deletePaymentMethod = async (data) =>
  fetchData({ method: 'delete', to: STRIPE.DELETE_PAYMENT_METHOD, data: data });
export const getPaymentMethod = async () =>
  fetchData({ method: 'get', to: STRIPE.GET_PAYMENT_METHODS, data: {} });
export const getPaymentIntent = async () =>
  fetchData({ method: 'post', to: STRIPE.PAYMENT_INTENT, data: {} });
