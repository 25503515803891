import scan1 from 'assets/images/mockExamination/file.000.jpg';
import scan2 from 'assets/images/mockExamination/file.001.jpg';
import scan3 from 'assets/images/mockExamination/file.002.jpg';
import scan4 from 'assets/images/mockExamination/file.003.jpg';
import scan5 from 'assets/images/mockExamination/file.004.jpg';
import scan6 from 'assets/images/mockExamination/file.005.jpg';
import scan7 from 'assets/images/mockExamination/file.006.jpg';
import scan8 from 'assets/images/mockExamination/file.007.jpg';
import scan9 from 'assets/images/mockExamination/file.008.jpg';
import scan10 from 'assets/images/mockExamination/file.009.jpg';
import scan11 from 'assets/images/mockExamination/file.010.jpg';
import scan12 from 'assets/images/mockExamination/file.011.jpg';
import { ScanDataType } from 'types/examination';

export const mockScans = (): ScanDataType[] => [
  {
    sequence_number: 1,
    link: scan1,
    thumbnail_link: scan1,
    scan_id: '6414e18d37dd936f800a77a4',
    scan_severity: 2,
    urgency_score: 2,

    is_segmented: false,
    is_stared: false,
  },
  {
    sequence_number: 2,
    link: scan2,
    thumbnail_link: scan2,
    scan_id: '6414e18d37dd936f800a77a5',
    scan_severity: 2,
    urgency_score: 2,

    is_segmented: false,
    is_stared: false,
  },
  {
    sequence_number: 3,
    link: scan3,
    thumbnail_link: scan3,
    scan_id: '6414e18d37dd936f800a77a6',
    scan_severity: 2,
    urgency_score: 2,

    is_segmented: false,
    is_stared: false,
  },
  {
    sequence_number: 4,
    link: scan4,
    thumbnail_link: scan4,
    scan_id: '6414e18d37dd936f800a77a7',
    scan_severity: 2,
    urgency_score: 2,

    is_segmented: false,
    is_stared: false,
  },
  {
    sequence_number: 5,
    link: scan5,
    thumbnail_link: scan5,
    scan_id: '6414e18d37dd936f800a77a8',
    scan_severity: 2,
    urgency_score: 2,

    is_segmented: false,
    is_stared: false,
  },
  {
    sequence_number: 6,
    link: scan6,
    thumbnail_link: scan6,
    scan_id: '6414e18e37dd936f800a77a9',
    scan_severity: 2,
    urgency_score: 2,

    is_segmented: false,
    is_stared: false,
  },
  {
    sequence_number: 7,
    link: scan7,
    thumbnail_link: scan7,
    scan_id: '6414e18e37dd936f800a77aa',
    scan_severity: 2,
    urgency_score: 2,

    is_segmented: false,
    is_stared: false,
  },
  {
    sequence_number: 8,
    link: scan8,
    thumbnail_link: scan8,
    scan_id: '6414e18e37dd936f800a77ab',
    scan_severity: 2,
    urgency_score: 2,

    is_segmented: false,
    is_stared: false,
  },
  {
    sequence_number: 9,
    link: scan9,
    thumbnail_link: scan9,
    scan_id: '6414e18e37dd936f800a77ac',
    scan_severity: 2,
    urgency_score: 2,
    is_segmented: false,
    is_stared: false,
  },
  {
    sequence_number: 10,
    link: scan10,
    thumbnail_link: scan10,
    scan_id: '6414e18e37dd936f800a77ad',
    scan_severity: 2,
    urgency_score: 2,
    is_segmented: false,
    is_stared: false,
  },
  {
    sequence_number: 11,
    link: scan11,
    thumbnail_link: scan11,
    scan_id: '6414e18e37dd936f800a77ae',
    scan_severity: 2,
    urgency_score: 2,
    is_segmented: false,
    is_stared: false,
  },
  {
    sequence_number: 12,
    link: scan12,
    thumbnail_link: scan12,
    scan_id: '6414e18e37dd936f800a77af',
    scan_severity: 2,
    urgency_score: 2,
    is_segmented: false,
    is_stared: false,
  },
];
