import { memo } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ThankYouIcon } from 'assets/icons/thankyou.svg';
import Button from 'components/Button';
import Text from 'components/Text';

import styles from './SteepThankYou.module.scss';

const SteepThankYou = memo(() => {
  const navigate = useNavigate();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'forgot_password_page.second_step',
  });

  return (
    <>
      <Text className={styles.title} color='light' tag='h1'>
        {t('congratulations')}
      </Text>
      <Text className={styles.subtitle} color='light'>
        {t('check_email')}
      </Text>
      <ThankYouIcon className={styles.thankyou} />
      <Button
        onClick={() => navigate(`/`)}
        className={cn(styles.signin, styles.button)}
      >
        {t('back_to_sign_in')}
      </Button>
    </>
  );
});

SteepThankYou.displayName = 'SteepThankYou';

export default SteepThankYou;
