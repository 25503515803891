import { ChangeEvent, memo } from 'react';

import cn from 'classnames';
import { Form, Formik } from 'formik';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';

import { RegisterUserInfo } from '../RegisterUser';

import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import Button from 'components/Button';
import FormField from 'components/FormComponents';
import Text from 'components/Text';
import { ga } from 'utils/helpers';
import { validatePasswordConfirmation } from 'validation';

import styles from './StepOne.module.scss';

interface StepOneProps {
  nextHandler: (value: RegisterUserInfo) => void;
  userInfo: RegisterUserInfo;
}

const StepOne = memo(({ nextHandler, userInfo }: StepOneProps) => {
  const { t, i18n } = useTranslation(undefined, {
    keyPrefix: 'register_user_page.step_two',
  });

  const analyticsHandler = () => {
    const query = qs.parse(location.search);

    if (query.teamMember) {
      ga('Registration_s2_Team_member');
      return;
    }

    if (query.coupon) {
      ga(`Registration_s2_${query.coupon}`);
      return;
    }

    ga('Registration_s2');
  };

  return (
    <>
      <Text className={styles.title} color='light' tag='h1' align='center'>
        {t('create_an_account')}
      </Text>
      <Formik
        initialValues={{
          password: userInfo.password,
          passwordConfirmation: '',
        }}
        validationSchema={() => validatePasswordConfirmation(i18n.t)}
        onSubmit={(value) => {
          analyticsHandler();
          nextHandler({ ...userInfo, password: value.password });
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <FormField
              type={'password'}
              component={'password'}
              name={'password'}
              id={'password'}
              value={values.password}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;

                if (value.length > 50) return;

                setFieldValue('password', value);
              }}
              label={t('password_label')}
              icon={<LockIcon />}
              className={styles.field}
              errorType={'simple'}
            />
            <FormField
              type={'password'}
              component={'password'}
              name={'passwordConfirmation'}
              id={'passwordConfirmation'}
              value={values.passwordConfirmation}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;

                if (value.length > 50) return;

                setFieldValue('passwordConfirmation', value);
              }}
              label={t('repeat_password_label')}
              icon={<LockIcon />}
              className={styles.field}
              errorType={'simple'}
            />
            <Button
              type={'submit'}
              className={cn(styles.signin, styles.button)}
            >
              {t('next')}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
});

StepOne.displayName = 'StepOne';

export default StepOne;
