import { settingsTypes } from 'redux/constants/settings';
import { ISettingsState, settingsActions } from 'types/settings';

const initialState: ISettingsState = {
  requesting: false,
  errors: null,
  data: [],
};

const contactUsReducer = (
  state = initialState,
  action: settingsActions
): ISettingsState => {
  switch (action.type) {
    /** Get settings */
    case settingsTypes.GET_SETTINGS_REQUEST:
      return {
        ...state,
        errors: null,
        requesting: true,
      };
    case settingsTypes.GET_SETTINGS_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
        data: action.payload,
      };
    case settingsTypes.GET_SETTINGS_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };

    /** Save settings */
    case settingsTypes.SAVE_SETTINGS_REQUEST:
      return {
        ...state,
        errors: null,
        requesting: true,
      };
    case settingsTypes.SAVE_SETTINGS_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
        data: action.payload,
      };
    case settingsTypes.SAVE_SETTINGS_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default contactUsReducer;
