import { teamMemberConstants } from '../constants/teamMember';

import { ITeamMemberState, teamMemberActions } from 'types/teamMember';

const initialState: ITeamMemberState = {
  requesting: false,
  errors: null,
  data: {
    invites_left: 0,
    members: [],
  },
};

const teamMemberReducer = (
  state = initialState,
  action: teamMemberActions
): ITeamMemberState => {
  switch (action.type) {
    /** INVITE TEAM MEMBER */
    case teamMemberConstants.INVITE_TEAM_MEMBER_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case teamMemberConstants.INVITE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
      };
    case teamMemberConstants.INVITE_TEAM_MEMBER_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };

    /**  GET ALL TEAM MEMBERS */
    case teamMemberConstants.GET_ALL_TEAM_MEMBERS_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case teamMemberConstants.GET_ALL_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        requesting: false,
        errors: null,
      };
    case teamMemberConstants.GET_ALL_TEAM_MEMBERS_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };

    /** DELETE TEAM MEMBER */
    case teamMemberConstants.DELETE_TEAM_MEMBER_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case teamMemberConstants.DELETE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
      };
    case teamMemberConstants.DELETE_TEAM_MEMBER_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };

    /** REINVITE TEAM MEMBER */
    case teamMemberConstants.REINVITE_TEAM_MEMBER_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case teamMemberConstants.REINVITE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
      };
    case teamMemberConstants.REINVITE_TEAM_MEMBER_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default teamMemberReducer;
