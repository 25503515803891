import { memo, useEffect, useState } from 'react';

import cn from 'classnames';
import { Form, Formik } from 'formik';
import { decode } from 'js-base64';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import Button from 'components/Button';
import FormField from 'components/FormComponents';
import Text from 'components/Text';
import { resetPasswordForEmailRequest } from 'redux/actions/userAuth';
import { ga } from 'utils/helpers';
import { validatePasswordConfirmation } from 'validation';

import styles from './ResetPassword.module.scss';

const ResetPassword = memo(() => {
  const urlSearchParams = new URLSearchParams(useLocation().search);
  const email = urlSearchParams.get('email');
  const token = urlSearchParams.get('token');
  const { t, i18n } = useTranslation(undefined, {
    keyPrefix: 'reset_password_page',
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userInfo, setUserInfo] = useState({
    password: '',
    token: '',
    email: '',
  });

  useEffect(() => {
    ga('open_page_reset_password');

    if (email && token) {
      setUserInfo({ ...userInfo, email: decode(email), token: token });
    } else {
      navigate('/');
    }
  }, []);

  return (
    <>
      <Text className={styles.title} color='light' tag='h1'>
        {t('enter_new_password')}
      </Text>
      <Formik
        initialValues={{
          password: userInfo.password,
          passwordConfirmation: '',
        }}
        validationSchema={() => validatePasswordConfirmation(i18n.t)}
        onSubmit={(value) => {
          const newUserPasswordInfo = { ...userInfo, password: value.password };
          dispatch(
            resetPasswordForEmailRequest(newUserPasswordInfo, {
              navigate,
            })
          );
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <FormField
              type={'password'}
              component={'password'}
              name={'password'}
              id={'password'}
              value={values.password}
              onChange={(event) => {
                const value = event.target.value;

                if (value.length > 50) return;

                setFieldValue('password', value);
              }}
              label={t('password_label')}
              icon={<LockIcon />}
              className={styles.field}
            />
            <FormField
              type={'password'}
              component={'password'}
              name={'passwordConfirmation'}
              id={'passwordConfirmation'}
              value={values.passwordConfirmation}
              onChange={(event) => {
                const value = event.target.value;

                if (value.length > 50) return;

                setFieldValue('passwordConfirmation', value);
              }}
              label={t('repeat_password_label')}
              icon={<LockIcon />}
              className={styles.field}
            />
            <Button
              type={'submit'}
              className={cn(styles.signin, styles.button)}
            >
              {t('next')}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
});

ResetPassword.displayName = 'ResetPassword';

export default ResetPassword;
