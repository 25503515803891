import { lazy } from 'react';

import ContactUs from 'pages/ContactUs';
import { SubscriptionB2B, SubscriptionB2C } from 'pages/Subscription';
import { ROUTES, USER_ROLES } from 'utils/constants';

const StoryBook = lazy(() => import('pages/StoryBook'));
const Settings = lazy(() => import('pages/Settings'));
const Education = lazy(() => import('pages/Education'));
const TeamMember = lazy(() => import('pages/TeamMember'));

export enum ProfileRoutes {
  EDIT_PROFILE = 'EDIT_PROFILE',
  SUBSCRIPTION = 'SUBSCRIPTION',
  TEAM_MEMBERS = 'TEAM_MEMBERS',
  CONTACT_US = 'CONTACT_US',
  EDUCATION = 'EDUCATION',
  SETTINGS = 'SETTINGS',
  BILLING_INFO = 'BILLING_INFO',
  STORY_BOOK = 'STORY_BOOK',
}

export const RoutePath: Record<ProfileRoutes, string> = {
  [ProfileRoutes.EDIT_PROFILE]: ROUTES.PROFILE.MAIN,
  [ProfileRoutes.SUBSCRIPTION]: ROUTES.PROFILE.SUBSCRIPTION,
  [ProfileRoutes.TEAM_MEMBERS]: ROUTES.PROFILE.TEAM_MEMBERS,
  [ProfileRoutes.CONTACT_US]: ROUTES.PROFILE.CONTACT_US,
  [ProfileRoutes.EDUCATION]: ROUTES.PROFILE.EDUCATION,
  [ProfileRoutes.SETTINGS]: ROUTES.PROFILE.SETTINGS,
  [ProfileRoutes.BILLING_INFO]: ROUTES.PROFILE.BILLING_INFO,
  [ProfileRoutes.STORY_BOOK]: ROUTES.PROFILE.STORY_BOOK,
};

export const profileRouterConfig = (userRole: string) => {
  /** Default routes for all user's role  */
  let routes = {
    [ProfileRoutes.CONTACT_US]: {
      path: RoutePath.CONTACT_US,
      element: <ContactUs />,
    },
    [ProfileRoutes.EDUCATION]: {
      path: RoutePath.EDUCATION,
      element: <Education />,
    },
    [ProfileRoutes.SETTINGS]: {
      path: RoutePath.SETTINGS,
      element: <Settings />,
    },
    [ProfileRoutes.STORY_BOOK]: {
      path: RoutePath.STORY_BOOK,
      element: <StoryBook />,
    },
  };

  /** Add routes for B2C user's role  */
  if (userRole === USER_ROLES.B2C) {
    routes = Object.assign(routes, {
      [ProfileRoutes.SUBSCRIPTION]: {
        path: RoutePath.SUBSCRIPTION,
        element: <SubscriptionB2C />,
      },
    });
  }

  /** Add routes for B2B || BRANCH_USER user's role
   * if we need to add new routes for BRANCH_USER user's role
   * you should to uncomment this code
   */

  // if (userRole === USER_ROLES.BRANCH_USER) {
  //   routes = Object.assign(routes, {
  //     [ProfileRoutes.SUBSCRIPTION]: {
  //       path: RoutePath.SUBSCRIPTION,
  //       element: <SubscriptionB2B />,
  //     },
  //     [ProfileRoutes.TEAM_MEMBERS]: {
  //       path: RoutePath.TEAM_MEMBERS,
  //       element: <TeamMember />,
  //     },
  //   });

  if (userRole === USER_ROLES.B2B || userRole === USER_ROLES.BRANCH_USER) {
    routes = Object.assign(routes, {
      [ProfileRoutes.SUBSCRIPTION]: {
        path: RoutePath.SUBSCRIPTION,
        element: <SubscriptionB2B />,
      },
      [ProfileRoutes.TEAM_MEMBERS]: {
        path: RoutePath.TEAM_MEMBERS,
        element: <TeamMember />,
      },
    });
  }

  return routes;
};
