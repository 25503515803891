import Shepherd from 'shepherd.js';

import { helperShowStep } from 'services/onboardingTour/tourOptions';
import i18n from 'translation/i18n';

const STEP_ID = 'create-examination-step-4';

export const stepFour: Shepherd.Step.StepOptions = {
  id: STEP_ID,
  attachTo: {
    element: '.shepherd-create-examination-tabs > div',
    on: 'left',
  },
  canClickTarget: false,
  beforeShowPromise: () =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        resolve();
      }, 500);
    }),
  buttons: [
    {
      classes: 'shepherd-button-primary',
      text: i18n.t('onboarding.create_examination.step_4_text_1'),
      action() {
        return this.show('step-dicom');
      },
    },
    {
      classes: 'shepherd-button-light',
      text: i18n.t('onboarding.create_examination.step_4_text_2'),
      action() {
        this.show('step-scans');
        // added one more step as a modal for description of scans
        this.show('create-examination-step-5');
      },
    },
  ],
  scrollTo: false,
  title: i18n.t('onboarding.create_examination.add_new_examination'),
  text: [
    `${i18n.t(
      'onboarding.create_examination.step_4_text_3'
    )} <span class='highlight'>${i18n.t(
      'onboarding.create_examination.step_4_text_4'
    )}</span> ${i18n.t('onboarding.create_examination.step_4_text_5')}
    <p class="warning-step">${i18n.t(
      'onboarding.create_examination.step_4_text_6'
    )}</p>`,
  ],
  when: {
    show: () => {
      helperShowStep(STEP_ID);
    },
  },
};
