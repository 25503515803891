import { combineReducers } from 'redux';

import appSettingsReducer from './appSettingsReducer';
import contactUsReducer from './contactUsReducer';
import examinationReducer from './examinationReducer';
import exportExamReducer from './exportExamReducer';
import modalReducer from './modalReducer';
import patientReducer from './patientReducer';
import professionsAndCountriesReducer from './professionsCountriesAndProducersReducer';
import purchaseItemReducer from './purchaseItemReducer';
import reportReducer from './reportReducer';
import settingsReducer from './settingsReducer';
import stripeReducer from './stripeReducer';
import subscriptionReducer from './subscriptionReducer';
import surveyReducer from './surveyReducer';
import teamMemberReducer from './teamMemberReducer';
import userAuthenticatedReducer from './userAuthenticatedReducer';
import userAuthReducer from './userAuthReducer';
import userBackupReducer from './userBackupReducer';
import userProfileReducer from './userProfileReducer';

import { userConstants } from 'redux/constants/userAuth';

const allReducers = combineReducers({
  modal: modalReducer,
  userAuth: userAuthReducer,
  userAuthenticated: userAuthenticatedReducer,
  userProfile: userProfileReducer,
  userBackup: userBackupReducer,
  professionsAndCountries: professionsAndCountriesReducer,
  examination: examinationReducer,
  examExport: exportExamReducer,
  patients: patientReducer,
  contactUs: contactUsReducer,
  subscription: subscriptionReducer,
  stripe: stripeReducer,
  teamMember: teamMemberReducer,
  purchaseItem: purchaseItemReducer,
  settings: settingsReducer,
  appSettings: appSettingsReducer,
  survey: surveyReducer,
  report: reportReducer,
});

export const rootReducer = (state: any, action: any) => {
  /**
   * In order to reset all reducers back to their initial states when user logout,
   * rewrite rootReducer to assign 'undefined' to state when logout
   *
   * If state passed to reducer is 'undefined', then the next state reducer returns
   * will be its initial state instead; since we have assigned it as the default value
   * of reducer's state parameter
   *   ex: const Reducer = (state = InitialState, action) => { ... }
   *
   * See: https://goo.gl/GSJ98M and combineReducers() source codes for details
   */
  if (action.type === userConstants.RESET_APP) {
    const { userAuthenticated } = state;
    state = { userAuthenticated };
  }

  return allReducers(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;
