import { t } from 'i18next';
import { fork, put, select, takeEvery } from 'redux-saga/effects';

import { getExamination } from 'api/examination';
import { checkExistedData } from 'pages/Examination/helpers';
import {
  setCompareDataError,
  updateExamTabSuccess,
} from 'redux/actions/examination';
import { ExamActionTypes } from 'redux/constants/examination';
import { getExamData } from 'redux/selectors/examination/getExamData/getExamData';
import { IWorkerProps } from 'redux/types/examination';
import { ExtendedExamDataType } from 'types/examination';
import { COMPARE_TYPE } from 'utils/constants';
import notify from 'utils/toast';

function* updateCompareExamWatcher() {
  yield takeEvery(
    ExamActionTypes.UPDATE_EXAM_TAB_REQUEST,
    updateCompareExamWorker
  );
}

interface IUpdateCompareExamWorker extends IWorkerProps {
  type: ExamActionTypes;
  payload: {
    exam_id: string;
    updateTo: 'BOTH' | 'COMPARISON' | 'PROGRESSION';
    currentExam: boolean;
  };
}
function* updateCompareExamWorker({
  payload,
  action,
}: IUpdateCompareExamWorker) {
  action?.default?.();
  try {
    const { exam_id, updateTo, currentExam } = payload;

    const { data: examination } = yield getExamination({ exam_id });

    if (!checkExistedData(examination.data)) {
      throw 'No data';
    }

    // @ts-ignore
    const oldData = yield select(getExamData);

    switch (updateTo) {
      case COMPARE_TYPE.BOTH: {
        // Get new data, and save etdrs if it was loaded
        const updatedExam = {
          ...oldData.compareBoth?.[currentExam ? 0 : 1],
          ...examination.data,
        };

        if (currentExam) {
          // If we have added second eye, we save him in the state
          yield put(
            updateExamTabSuccess({
              updateTo: 'BOTH',
              data:
                oldData.compareBoth.length > 1
                  ? [updatedExam, oldData.compareBoth[1]]
                  : [updatedExam],
            })
          );
        } else {
          yield put(
            updateExamTabSuccess({
              updateTo: 'BOTH',
              data: [oldData.compareBoth[0], updatedExam],
            })
          );
        }

        break;
      }

      case COMPARE_TYPE.COMPARISON: {
        const updatedExam = {
          ...oldData.compareComparison?.[currentExam ? 0 : 1],
          ...examination.data,
        };

        if (currentExam) {
          yield put(
            updateExamTabSuccess({
              updateTo: 'COMPARISON',
              data:
                oldData.compareComparison.length > 1
                  ? [updatedExam, oldData.compareBoth[1]]
                  : [updatedExam],
            })
          );
        } else {
          yield put(
            updateExamTabSuccess({
              updateTo: 'COMPARISON',
              data: [oldData.compareBoth[0], updatedExam],
            })
          );
        }

        break;
      }

      case COMPARE_TYPE.PROGRESSION: {
        const updatedExamIndex = oldData.compareProgress.examsData.findIndex(
          (exam: ExtendedExamDataType) => exam.id === exam_id
        );

        const updatedExam = {
          ...oldData.compareProgress.examsData[updatedExamIndex],
          ...examination.data,
        };

        // Current examination
        if (currentExam) {
          const filtered = oldData.compareProgress.examsData.filter(
            (exam: ExtendedExamDataType) => exam.id !== exam_id
          );

          yield put(
            updateExamTabSuccess({
              updateTo: 'PROGRESSION',
              data: [updatedExam, ...filtered],
            })
          );
        } else {
          const filtered = oldData.compareProgress.examsData.filter(
            (exam: ExtendedExamDataType) => exam.id !== exam_id
          );

          yield put(
            updateExamTabSuccess({
              updateTo: 'PROGRESSION',
              data: [...filtered, updatedExam],
            })
          );
        }

        break;
      }
    }
    action?.success?.();
  } catch (error) {
    console.error(error);
    yield put(setCompareDataError('Something go wrong'));
    action?.error?.();
    if (error === 'Examination failed') {
      yield notify('error', t('notifications.examination_failed'));
      return;
    }
    if (error === 'No data') {
      yield notify(
        'error',
        t('notifications.examinations_have_not_enough_data')
      );
      return;
    }

    yield notify('error', t('notifications.something_went_wrong'));
  }
}

export default function* updateCompareSaga() {
  yield fork(updateCompareExamWatcher);
}
