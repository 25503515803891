import createExaminationSteps from './createExamination';
import createPatientSteps from './createPatient';
import createReportSteps from './createReport';
import generalSteps from './general';
import showExaminationSteps from './showExamination';

const steps = [
  ...generalSteps,
  ...createPatientSteps,
  ...createExaminationSteps,
  ...showExaminationSteps,
  ...createReportSteps,
];

export default steps;
