import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { getPaymentStatistics } from 'api/subscription';
import {
  getPaymentStatisticsError,
  getPaymentStatisticsSuccess,
} from 'redux/actions/subscription';
import { subscriptionConstants } from 'redux/constants/subscription';
import notify from 'utils/toast';

function* getPaymentStatisticWatcher() {
  yield takeEvery(
    subscriptionConstants.GET_PAYMENT_STATISTICS_REQUEST,
    getPaymentStatisticWorker
  );
}

function* getPaymentStatisticWorker() {
  try {
    const { data: paymentStatistics } = yield getPaymentStatistics();
    if (paymentStatistics.status === 'Error') {
      throw new Error(paymentStatistics.message);
    }

    yield put(getPaymentStatisticsSuccess(paymentStatistics.data));
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    yield put(getPaymentStatisticsError(error));
    yield notify('error', errorMessage);
  }
}

export default function* getPaymentStatisticSaga() {
  yield fork(getPaymentStatisticWatcher);
}
