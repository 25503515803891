import { memo } from 'react';

import { IPictureWithProps } from './PictureWithText.types';

import Picture from 'components/Picture';

const PictureWithText = memo(
  ({ src, description, className, children }: IPictureWithProps) => {
    return (
      <>
        <Picture src={src} alt={description} className={className} />
        {children}
      </>
    );
  }
);

PictureWithText.displayName = 'PictureWithText';

export default PictureWithText;
