import { FC } from 'react';

import cn from 'classnames';

import styles from './badge.module.scss';

const Badge: FC<{ className: string }> = ({ className }) => {
  return <div className={cn(styles.badge, className)}>Powered By AI</div>;
};

export default Badge;
