export const cleanOldCache = async () => {
  const date = Number(new Date());

  const allCaches = await caches.keys();

  await Promise.all(
    allCaches.map(async (cacheName) => {
      const cache = await caches.open(cacheName);

      const urls = (await cache.keys()).map(({ url }) => url);

      await Promise.all(
        urls.map(async (url) => {
          const response = await cache.match(url);

          if (!response) return Promise.resolve();

          const cacheData = await response.json();

          if (cacheData.cacheExpirationDate < date) {
            return cache.delete(url);
          }
        })
      );
    })
  );
};

export const revalidatePaths = async (paths: string[]) => {
  const allCaches = await caches.keys();

  return await Promise.all(
    paths.map((path) => {
      return Promise.all(
        allCaches.map(async (cacheName) => {
          const cache = await caches.open(cacheName);

          return cache.delete(path);
        })
      );
    })
  );
};

export const cleanCache = async () => {
  const allCaches = await caches.keys();

  return await Promise.all(
    allCaches.map((cacheName) => caches.delete(cacheName))
  );
};
