import { EtdrsMainData } from 'types/examination';

export const mockETDRS = (): EtdrsMainData => ({
  main: {
    data: {
      statistics: [
        [
          {
            mean: 292.2142574257426,
          },
        ],
        [
          {
            mean: 328.47280080889783,
          },
          {
            mean: 286.4631578947368,
          },
          {
            mean: 261.8507583417594,
          },
          {
            mean: 281.3078947368421,
          },
        ],
        [
          {
            mean: 310.7964890668309,
          },
          {
            mean: 273.5396530359356,
          },
          {
            mean: 259.4279198767334,
          },
          {
            mean: 279.1423791821561,
          },
        ],
      ],
      distance_method_slug: 'axial',
    },
  },
  single: {
    layers: ['rnfl'],
    data: {
      statistics: [
        [
          {
            mean: 44.32356435643564,
          },
        ],
        [
          {
            mean: 32.62224469160768,
          },
          {
            mean: 49.73157894736842,
          },
          {
            mean: 67.04529828109202,
          },
          {
            mean: 50.92368421052631,
          },
        ],
        [
          {
            mean: 26.553615461512827,
          },
          {
            mean: 69.79888475836431,
          },
          {
            mean: 155.0962095531587,
          },
          {
            mean: 63.73543990086741,
          },
        ],
      ],
      distance_method_slug: 'axial',
    },
  },
  range: {
    layers: ['rnfl', 'rpe'],
    data: {
      statistics: [
        [
          {
            mean: 292.2142574257426,
          },
        ],
        [
          {
            mean: 328.47280080889783,
          },
          {
            mean: 286.4631578947368,
          },
          {
            mean: 261.8507583417594,
          },
          {
            mean: 281.3078947368421,
          },
        ],
        [
          {
            mean: 310.7964890668309,
          },
          {
            mean: 273.5396530359356,
          },
          {
            mean: 259.4279198767334,
          },
          {
            mean: 279.1423791821561,
          },
        ],
      ],
      distance_method_slug: 'axial',
    },
  },
  gcc: {
    layers: ['rnfl', 'gcl', 'ipl'],
    data: {
      statistics: [
        [
          {
            mean: 114.80673267326732,
          },
        ],
        [
          {
            mean: 112.90063604240282,
          },
          {
            mean: 109.00263720598717,
          },
          {
            mean: 115.65306694707324,
          },
          {
            mean: 179.75634051571882,
          },
          {
            mean: 158.01242937853107,
          },
          {
            mean: 112.63568173852086,
          },
        ],
      ],
      distance_method_slug: 'axial',
    },
  },
});
