import { scrollbarStyle } from 'components/FormComponents/SelectInput/scrollbarStyle';

const customStyles = {
  control: (base, { isFocused, isSelected }) => ({
    ...base,
    background: 'var(--white-ice-color)',
    borderRadius: '8px',
    border:
      isFocused || isSelected
        ? '1px solid var(--theme-color)'
        : '1px solid transparent',
    minHeight: 48,
    minWidth: 100,
    textAlign: 'initial',
    paddingLeft: 8,
    boxShadow: 'none',

    '&:hover': {
      cursor: 'pointer',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: (base) => ({
    ...base,
    background: '#fff',
    padding: 0,
    margin: 0,
    zIndex: 999,
  }),
  option: (base, state) => ({
    ...base,
    background: state.isSelected ? 'var(--white-ice-color)' : 'transparent',
    color: '#000',
    borderBottom: '1px solid #FFF',
    textAlign: 'initial',
    padding: '9.5px 13px',
    paddingLeft: 16,
    fontSize: 13,

    '&:hover': {
      cursor: 'pointer',
      background: 'var(--white-ice-color)',
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    color: 'var(--light-gray)',
    fontSize: 14,
    fontWeight: 400,
  }),
  menuList: (base) => ({
    ...base,
    margin: 5,
    maxHeight: 150,
    ...scrollbarStyle,
  }),
  dropdownIndicator: (base, { isFocused, isSelected }) => ({
    ...base,
    color: isFocused || isSelected ? 'var(--theme-color)' : 'var(--light-gray)',
  }),
  singleValue: (base, state) => ({
    ...base,
    textAlign: 'center',
    opacity: state.isDisabled ? 0.5 : 1,
    transition: 'opacity 300ms',
    fontSize: 14,
  }),
};

export default customStyles;
