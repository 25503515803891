import { scrollbarStyle } from 'components/FormComponents/SelectInput/scrollbarStyle';

export const customSelect: any = {
  control: (base: any, { isFocused, isSelected }: any) => ({
    ...base,
    textAlign: 'initial',
    boxShadow: 'none',
    border: 'none',
    cursor: 'pointer',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: (base: any) => ({
    ...base,
    background: '#fff',
    padding: 0,
    margin: 0,
    right: 0,
    zIndex: 999,
    top: 'auto',
    width: 212,
    border: 'none',
    boxShadow: 'none',
    borderRadius: 12,
  }),
  option: (base: any, state: any) => ({
    ...base,
    background: 'none !important',
    color: state.isSelected
      ? 'var(--theme-color) !important'
      : 'var(--dark-color) !important',
    fontWeight: state.isSelected ? 600 : 400,
    textAlign: 'initial',
    fontSize: 'var(--m-font-size)',
    cursor: 'pointer',
    padding: '16px 0',
    borderBottom: 'thin solid #E5E9EC',
    '&:last-child': {
      border: 'none',
    },
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: 'var(--light-gray)',
    fontSize: 14,
    fontWeight: 400,
  }),
  menuList: (base: any) => ({
    ...base,
    paddingBlock: 4,
    paddingInline: 16,
    ...scrollbarStyle,
  }),
  dropdownIndicator: (base: any, { isFocused, isSelected }: any) => ({
    ...base,
    color: isFocused || isSelected ? 'var(--theme-color)' : 'var(--light-gray)',
  }),
  singleValue: (base: any, state: any) => ({
    ...base,
    fontSize: 'var(--l-font-size)',
    paddingBlock: 25,
    paddingInline: 16,
    color: 'var(--dark-color)',
    margin: 0,
  }),
  valueContainer: (base: any, state: any) => ({
    ...base,
    padding: 0,
  }),
};
