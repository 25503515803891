import { IImageLineType } from 'pages/Examination/Examination.types';
import {
  EColorToSeverity,
  ISeriesTypes,
  TPriorityColor,
} from 'types/examination';

export const BASE_URL = '/';

export const ROUTES = {
  AUTH: {
    LOGIN: '/',
    REGISTER: '/register',
    FORGOT: '/forgot-password',
    RESET: '/password-recovery',
  },
  LAW: {
    PRIVACY: '/privacy-policy',
    TERMS_AND_CONDITIONS: '/terms-and-conditions',
    DATA_PROTECTION: '/data-protection-policy',
    FILL_FORM: '/ocuplan_clinics_tos',
  },
  DASHBOARD: {
    MAIN: '/',
  },
  EXAMINATION: {
    MAIN: '/examination/:examId',
  },
  SERVICES: {
    MAIN: '/services',
  },
  PROFILE: {
    MAIN: '/edit-profile',
    MY_INFORMATION: '/edit-profile?tab=1',
    REPORT_SETTINGS: '/edit-profile?tab=2',
    CHANGE_PASSWORD: '/edit-profile?tab=3',
    ACCOUNTS_DATA: '/edit-profile?tab=4',
    SUBSCRIPTION: '/edit-profile/subscription',
    SUBSCRIPTION_PLANS: '/edit-profile/subscription?tab=1',
    BILLING_INFO: '/edit-profile/subscription?tab=2',
    TEAM_MEMBERS: '/edit-profile/team-members',
    CONTACT_US: '/edit-profile/contact-us',
    EDUCATION: '/edit-profile/education',
    SETTINGS: '/edit-profile/settings',
    STORY_BOOK: '/edit-profile/story-book',
  },
  ONBOARDING: {
    MAIN: '/onboarding',
    CREATE_PATIENT: '/onboarding/create-patient',
    CREATE_EXAMINATION: '/onboarding/create-examination',
    EXAMINATION_HEADER: '/onboarding/examination-header',
    SINGLE_SCAN_TAB: '/onboarding/single-scan-tab',
    MEASURMENTS_TAB: '/onboarding/measurments-tab',
    ALL_SCANS_TAB: '/onboarding/all-scans-tab',
    BOTH_EYES_TAB: '/onboarding/both-eyes-tab',
    COMPARISON_TAB: '/onboarding/comparison-tab',
    PROGRESSION_TAB: '/onboarding/progression-tab',
    PATHOLOGIES_DETECTION_TAB: '/onboarding/pathologies-detection-tab',
    REPORT: '/onboarding/report',
  },
} as const;

export const SERIES_TYPES: ISeriesTypes = {
  HIGH_LOW: 'high-low',
  LOW_HIGH: 'low-high',
  IN_SERIES: 'in-series',
} as const;

export const EXAMINATION_MODAL_TYPE = {
  CREATE_EXAMINATION: 'CREATE_EXAMINATION',
  UPDATE_EXAMINATION: 'UPDATE_EXAMINATION',
  DELETE_EXAMINATION: 'DELETE_EXAMINATION',
  ADD_EYE: 'ADD_EYE',
  ANALYSE_SCANS: 'ANALYSE_SCANS',
  SELECT_LAYERS: 'SELECT_LAYERS',
  EXPORT_JSON: 'EXPORT_JSON',
  EDIT_SCAN_DESCRIPTION: 'EDIT_SCAN_DESCRIPTION',
  GLAUCOMA_DISCLAIMER: 'GLAUCOMA_DISCLAIMER',
} as const;

export const PATIENT_MODAL_TYPE = {
  CREATE_PATIENT: 'CREATE_PATIENT',
  DELETE_PATIENT: 'DELETE_PATIENT',
  UPDATE_PATIENT: 'UPDATE_PATIENT',
} as const;

export const TEAM_MEMBER_MODAL_TYPE = {
  INVITE_TEAM_MEMBER: 'INVITE_TEAM_MEMBER',
  DELETE_TEAM_MEMBER: 'DELETE_TEAM_MEMBER',
  REINVITE_TEAM_MEMBER: 'REINVITE_TEAM_MEMBER',
} as const;

export const USER_PROFILE_MODAL_TYPE = {
  IMAGE_CROPPER: 'IMAGE_CROPPER',
} as const;

export const SUBSCRIPTION_MODAL_TYPE = {
  CURRENT_PLAN: 'CURRENT_PLAN',
  SUBSCRIBE_PLAN: 'SUBSCRIBE_PLAN',
  RENEW_WARNING: 'RENEW_WARNING',
  RENEW_REJECT: 'RENEW_REJECT',
  NO_OBJECTS_LEFT: 'NO_OBJECTS_LEFT',
  TRIAL_PERIOD_END: 'TRIAL_PERIOD_END',
} as const;

export const ADDITIONAL_ITEM_MODAL_TYPE = {
  EXAMINATIONS: 'EXAMINATIONS',
} as const;

export const ONBOARDING_MODAL_TYPE = {
  ONBOARDING_TOUR: 'ONBOARDING_TOUR',
  DOWNLOAD_DCM: 'DOWNLOAD_DCM',
  DOWNLOAD_SUCCESS: 'DOWNLOAD_SUCCESS',
  CHANGE_SINGLE_SCAN_TAB: 'CHANGE_SINGLE_SCAN_TAB',
} as const;

export const SURVEY_MODAL_TYPE = {
  UNSUBSCRIBE: 'UNSUBSCRIBE',
  BOOKING_MEETING: 'BOOKING_MEETING',
} as const;

export enum B2B_ROLES {
  ADMIN = 'Admin',
  USER = 'User',
  INVITED = 'invited',
  ACCOUNT_HOLDER = 'account_holder',
}

export enum B2B_TYPES {
  INSTANT = 'instant',
  MONTH = 'recurring_month',
  YEAR = 'recurring_year',
}
export enum USER_ROLES {
  B2B = 'b2b',
  B2C = 'b2c',
  BRANCH_USER = 'branch_user',
}

export const EXAMINATION_STATUS = {
  PROCESSING: 'processing',
  FAILED: 'failed',
  FINISHED: 'finished',
} as const;

export enum SUBSCRIPTION_STATUS {
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
}

export enum USER_BACKUP_STATUS {
  INCOMPLETE = 'incomplete',
  IN_PROGRESS = 'in_progress',
  FINISHED = 'finished',
}

export const TWO_HOURS = 2 * 60 * 60 * 1000;

export const MOBILE_RESOLUTION = 992;

export enum EYE_TYPES {
  'OD' = 'OD',
  'OS' = 'OS',
}

export interface eyeTypes {
  label: string;
  value: EYE_TYPES;
}

export const SCAN_WIDTHS = [
  { label: '5mm', value: '5mm' },
  { label: '6mm', value: '6mm' },
  { label: '7mm', value: '7mm' },
  { label: '8mm', value: '8mm' },
  { label: '9mm', value: '9mm' },
  { label: '10mm', value: '10mm' },
  { label: '11mm', value: '11mm' },
  { label: '12mm', value: '12mm' },
];

export const THICKNESS_OPTIONS = [
  { label: 'RNFL', value: 'rnfl' },
  { label: 'GCL', value: 'gcl' },
  { label: 'IPL', value: 'ipl' },
  { label: 'INL', value: 'inl' },
  { label: 'OPL', value: 'opl' },
  { label: 'ONL', value: 'onl' },
  { label: 'ELM', value: 'elm' },
  { label: 'MZ', value: 'mz' },
  { label: 'EZ', value: 'ez' },
  { label: 'OS', value: 'os' },
  { label: 'RPE', value: 'rpe' },
];

export const GCC_OPTIONS = [
  { label: 'GCC+RNFL (RNFL+GCL+IPL)', value: ['rnfl', 'gcl', 'ipl'] },
  { label: 'GCC (GCL+IPL)', value: ['gcl', 'ipl'] },
];

export const COMPARE_TYPE = {
  BOTH: 'BOTH',
  COMPARISON: 'COMPARISON',
  PROGRESSION: 'PROGRESSION',
} as const;

export enum COMPARE_TYPE_ENUM {
  BOTH = 'BOTH',
  COMPARISON = 'COMPARISON',
  PROGRESSION = 'PROGRESSION',
}

export const EXAM_CONFIDENCE = {
  SCAN_QUALITY: 4,
  CLASSIFICATION_LOW: 0.01,
  CLASSIFICATION: 0.6,
  PROBABILITY: 0.5,
};

/** Format of files what you can load to platform */
export const ACCEPT_FORMAT_FOR_UPLOAD = {
  IMAGE: ['.png', '.jpg', '.jpeg', '.bmp', '.tiff', '.tif', 'svg'],
  DICOM: ['.dcom', '.dicom', '.dcm'],
  ARCHIVE: ['.zip'],
};

export const DEFAULT_ETDRS = {
  SINGLE: ['rnfl'],
  RANGE: ['rnfl', 'rpe'],
  GCC: ['rnfl', 'gcl', 'ipl'],
};

export enum CREATE_EXAMINATION_FLOW {
  /** Create examination from .dcom file for new patient data from this file. */
  DICOM_OUT_OF_PATIENT = 'dicomOutOfPatientContext',

  /** Create examination from .dcom to existing patient */
  DICOM_IN_PATIENT = 'dicomInPatientContext',

  /** Create examination from images to existing patient */
  IMAGES_IN_PATIENT = 'imagesInPatientContext',

  ZIPPED_DICOM_OUT_OF_PATIENT = 'zippedDicomOutOfPatientContext',

  ZIPPED_DICOM_IN_PATIENT = 'zippedDicomInPatientContext',
}

//** Temporary solution */
export const USER_COUPONS = ['100percentoptical'];

export const MEAS_KEY_SEPARATOR = '__M-Key__';

export const initialMasks = {
  active: [],
  available: [],
  unavailable: [],
};

export const initialPoints = {
  p1: { x: 0, y: 0 },
  p2: { x: 0, y: 0 },
};

export const defaultLine = {
  className: 'line',
  color: '#F00',
  label: 'New line',
  length: 0,
  ...initialPoints,
};

export const initialLines: IImageLineType = {
  active: [],
  available: [],
};

/**
 * @description ACTIVE_SCANS_KEY save to us examination id and scan id that should be active while we open tabs or create report
 */
export const ACTIVE_SCANS_KEY = 'active_scans';

export const BOOKING_URL = 'https://meetings-eu1.hubspot.com/eugene-shlonchak';

export const layersSortingTable = {
  rnfl: 11,
  gcl: 10,
  ipl: 9,
  inl: 8,
  opl: 7,
  onl: 6,
  elm: 5,
  mz: 4,
  ez: 3,
  os: 2,
  rpe: 1,
} as Record<string, number>;

export const LOCAL_STORAGE_LANGUAGE_KEY = 'language';

export enum DEMO_ACCOUNT_GOOGLE_ANALYTICS {
  OPEN_PAGE_DEMO_ACCOUNT = 'Open_Page_Demo_account',
  OPEN_PAGE_SINGLE_SCAN = 'Open_Page_Single_Scan_Demo',
  OPEN_PAGE_PATHOLOGIES_DETECTION = 'Open_Page_Pathologies_Detection_Demo',
  OPEN_PAGE_GLAUCOMA = 'Open_page_Glaucoma_risk_Analysis',

  REGISTRATION_S1 = 'Registration_Demo_S1',
  REGISTRATION_S1_FIRST_TIME = 'Registration_Demo_S1_First_Time',
  REGISTRATION_S2 = 'Registration_Demo_S2_Success',

  OPENED_DEMO_BOOK = 'Opened_Demo_Booking',
}

export const LOCALLY_DISABLED_PATHOLOGIES_KEY =
  'locally-disabled-pathologies-key';
