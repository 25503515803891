import Shepherd from 'shepherd.js';

import { helperRemoveStep } from 'services/onboardingTour/tourOptions';
import i18n from 'translation/i18n';
import { ga } from 'utils/helpers';

const STEP_ID = 'general-step-1';

export const stepOne: Shepherd.Step.StepOptions = {
  id: STEP_ID,
  attachTo: { element: '', on: 'bottom' },
  beforeShowPromise: () =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        resolve();
      }, 500);
    }),
  buttons: [
    {
      classes: 'shepherd-button-primary',
      text: i18n.t('onboarding.general.continue_onboarding'),
      action() {
        const step = localStorage.getItem('onboarding') as string;
        this.show(step);
      },
    },
    {
      classes: 'shepherd-button-light',
      text: i18n.t('onboarding.general.skip_onboarding'),
      action() {
        const step = localStorage.getItem('onboarding') as string;
        ga(step);
        this.complete();
        helperRemoveStep();
      },
    },
  ],
  classes: STEP_ID,
  scrollTo: false,
  title: i18n.t('onboarding.general.skip_onboarding'),
  text: [i18n.t('onboarding.general.are_you_sure_want_to_skip')],
};
