import { memo } from 'react';

import { SkeletonPatientItem } from './PatientItem';

import Text from 'components/Text';
import { getComponentCount } from 'utils/helpers';

import styles from './PatientList.module.scss';

const Skeleton = memo(() => {
  const titleData = [
    'Patient ID',
    'Patient Name',
    'Birth date',
    'Gender',
    'Last upload',
    'Description',
  ];

  return (
    <>
      <div className={styles.header}>
        {titleData.map((item) => {
          return (
            <Text key={item} color='dark' className={styles.heading}>
              {item}
            </Text>
          );
        })}
      </div>
      <div className={styles.list}>
        {getComponentCount(<SkeletonPatientItem />)}
      </div>
    </>
  );
});

Skeleton.displayName = 'Skeleton';

export default Skeleton;
