import { TEAM_MEMBER } from './endpoints';

import { ITeamMemberInvite } from 'types/teamMember';
import fetchData from 'utils/fetchData';

export const inviteTeamMember = async (data: ITeamMemberInvite) =>
  fetchData({ method: 'post', to: TEAM_MEMBER.INVITE_TEAM_MEMBER, data: data });
export const getAllTeamMembers = async () =>
  fetchData({ method: 'get', to: TEAM_MEMBER.GET_ALL_TEAM_MEMBERS, data: {} });
export const deleteTeamMember = async (id: number) =>
  fetchData({
    method: 'delete',
    to: TEAM_MEMBER.DELETE_TEAM_MEMBER(id),
    data: {},
  });
export const reinviteTeamMember = async (id: number) =>
  fetchData({
    method: 'put',
    to: TEAM_MEMBER.REINVITE_TEAM_MEMBER(id),
  });
