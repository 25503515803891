import { t } from 'i18next';
import { fork, put, select, takeEvery } from 'redux-saga/effects';

import { deletePaymentMethod } from 'api/stripe';
import {
  deletePaymentCardError,
  deletePaymentCardSuccess,
  getPaymentMethodSuccess,
} from 'redux/actions/stripe';
import { stripeConstants } from 'redux/constants/stripe';
import { selectAllPaymentMethod } from 'redux/selectors/stripe';
import notify from 'utils/toast';

function* deletePaymentCardWatcher() {
  yield takeEvery(
    stripeConstants.DELETE_PAYMENT_CARD_REQUEST,
    deletePaymentCardWorker
  );
}
//key
function* deletePaymentCardWorker({ payload }) {
  try {
    yield deletePaymentMethod(payload);

    const allPaymentMethod = yield select(selectAllPaymentMethod);

    /**
     * After successfully deleting the requesting payment method and
     * filtering all payment methods setting the rest methods
     * to state without API request.
     */
    const filteredPaymentsData = yield allPaymentMethod.filter(
      (item) => item.id !== payload.payment_method_id
    );

    yield put(getPaymentMethodSuccess(filteredPaymentsData));
    yield put(deletePaymentCardSuccess());
  } catch (error) {
    yield put(deletePaymentCardError(error));
    yield notify('error', t('notifications.something_went_wrong'));
  }
}

export default function* deletePaymentCardSaga() {
  yield fork(deletePaymentCardWatcher);
}
