import { t } from 'i18next';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { loginUserByGoogle } from 'api/auth';
import {
  userAuthByGoogleError,
  userAuthByGoogleSuccess,
} from 'redux/actions/userAuth';
import { getAuthenticatedTrue } from 'redux/actions/userAuthenticated';
import { userConstants } from 'redux/constants/userAuth';
import { userAuthenticatedConstants } from 'redux/constants/userAuthenticated';
import { ga } from 'utils/helpers';
import notify from 'utils/toast';

function* userGoogleAuthWatcher() {
  yield takeEvery(userConstants.GOOGLE_LOGIN_REQUEST, userGoogleAuthWorker);
}

function* userGoogleAuthWorker({ payload }) {
  try {
    const { data: key } = yield loginUserByGoogle({ access_token: payload });

    if (key.status === 'Error') {
      throw new Error(key.message);
    }

    if (key.status === 'Not_finished') {
      yield notify('error', t('notifications.something_went_wrong'));
      yield put(userAuthByGoogleError());

      return;
    }

    yield call([localStorage, localStorage.setItem], 'token', key.data.token);
    yield call(
      [localStorage, localStorage.setItem],
      'method',
      userAuthenticatedConstants.LOGGED_BY_GOOGLE
    );
    yield put(
      getAuthenticatedTrue(userAuthenticatedConstants.LOGGED_BY_GOOGLE)
    );

    yield put(userAuthByGoogleSuccess());
    ga('Login_google');
  } catch (error) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    console.error('error ', error);
    yield notify('error', errorMessage);
  }
}

export default function* authByGoogleSaga() {
  yield fork(userGoogleAuthWatcher);
}
