import { memo } from 'react';

import cn from 'classnames';
import { connect, useField } from 'formik';

import CheckBox from './CheckBox';
import DatePickerInput from './DatePicker';
import FileUpload from './FileUpload';
import PasswordInput from './PasswordInput';
import RadioButton from './RadioButton';
import SelectInput from './SelectInput';
import SwitchBox from './SwitchBox';
import TextArea from './TextArea';
import TextInput from './TextInput';
import VerificationCodeInput from './VerificationCodeInput';

import { ReactComponent as SeverityLevelRedIcon } from 'assets/icons/concern_level_red.svg';

import checkboxStyles from './CheckBox.module.scss';
import styles from './FormField.module.scss';
import radioStyles from './RadioButton.module.scss';

const FormField = memo((props: any) => {
  const {
    component,
    name,
    icon,
    className,
    labelType,
    errorType,
    inputTooltip,
  } = props;
  const { status } = props.formik;
  const [field, meta] = useField(props);

  const renderFields = () => {
    switch (component) {
      case 'checkbox':
        return <CheckBox {...field} {...props} />;
      case 'radio':
        return <RadioButton {...field} {...props} />;
      case 'switchBox':
        return <SwitchBox {...field} {...props} />;
      case 'password':
        return <PasswordInput {...field} {...props} />;
      case 'select':
        return <SelectInput {...field} {...props} />;
      case 'textarea':
        return <TextArea {...field} {...props} />;
      case 'date-picker-input':
        return <DatePickerInput {...field} {...props} />;
      case 'file-upload':
        return <FileUpload {...field} {...props} />;
      case 'verification-code-input':
        return <VerificationCodeInput {...field} {...props} />;

      default:
        //type text, email, number
        return <TextInput {...field} {...props} />;
    }
  };

  return (
    <div
      className={cn(
        styles['field-wrapper'],
        (meta.touched && meta.error) || status?.[name] ? styles.error : '',
        className,
        styles[component]
      )}
    >
      <div
        className={cn(styles['input-field'], {
          [styles.withIcon]: !!icon,
          // labelType can be float, classic, leftSide
          [styles[labelType]]: labelType,
          [checkboxStyles['checkbox']]: component === 'checkbox',
          [radioStyles['radio']]: component === 'radio',
          [styles['input-tooltip']]: inputTooltip,
        })}
      >
        {renderFields()}
        {inputTooltip && (
          <div className={styles['input-tooltip-text']}>{inputTooltip}</div>
        )}
        {icon && <div className={styles.icon}>{icon}</div>}
      </div>

      {/* FIXME: if we have two errors need to show only one error*/}
      {meta.touched && meta.error && (
        <div
          className={cn(styles['error-message'], {
            [styles[errorType]]: errorType,
          })}
        >
          <span className={styles['error-icon']}>
            <SeverityLevelRedIcon />
          </span>
          <p>{meta.error}</p>
        </div>
      )}

      {meta.touched && status?.[name] && !meta.error && (
        <div
          className={cn(styles['error-message'], {
            [styles[errorType]]: errorType,
          })}
        >
          <span className={styles['error-icon']}>
            <SeverityLevelRedIcon />
          </span>
          <p>{status[name]}</p>
        </div>
      )}
    </div>
  );
});

FormField.displayName = 'FormField';

export default connect(FormField);
