import { t } from 'i18next';
import { fork, put, select, takeEvery } from 'redux-saga/effects';

import { getProgressionState } from 'api/examination';
import {
  getProgressionStateAction,
  getProgressionStateError,
  getProgressionStateSuccess,
  updateProgressionDataRequest,
} from 'redux/actions/examination';
import { ExamActionTypes } from 'redux/constants/examination';
import { getExamData } from 'redux/selectors/examination/getExamData/getExamData';
import notify from 'utils/toast';

function* getProgressionStateWatcher() {
  yield takeEvery(
    ExamActionTypes.GET_PROGRESSION_STATE_REQUEST,
    getProgressionStateWorker
  );
}

interface IGetProgressionState {
  type: ExamActionTypes;
  payload: string;
  action?: getProgressionStateAction;
  abortSignal?: AbortSignal;
}

function* getProgressionStateWorker({
  payload,
  action,
  abortSignal,
}: IGetProgressionState) {
  action?.default?.();

  try {
    const { data } = yield getProgressionState(payload, abortSignal);

    const { data: oldData } = yield select(getExamData);
    if (oldData.status === 'Error') {
      throw new Error(oldData.message);
    }

    // @ts-ignore
    const newExams = data.data.sort((first, second) => {
      if (first.id === oldData?.id) return -1;
      if (second.id === oldData?.id) return 1;

      return 0;
    });

    for (const exam of newExams) {
      if (
        !exam?.etdrs &&
        exam.able_to_get_statistics &&
        exam.has_default_etdrs_statistics
      ) {
        yield put(updateProgressionDataRequest({ exam_id: exam.id }));
      }
    }

    yield put(getProgressionStateSuccess(newExams));

    action?.success?.(newExams.map((exam: any) => exam.id));
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    if (error !== 'canceled') {
      notify('error', errorMessage);
    }

    action?.error?.();
    yield put(getProgressionStateError());
  }
}

export default function* getProgressionStateSaga() {
  yield fork(getProgressionStateWatcher);
}
