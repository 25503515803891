import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { getSubscriptionDetails } from 'api/subscription';
import {
  getSubscriptionDetailsError,
  getSubscriptionDetailsSuccess,
} from 'redux/actions/subscription';
import { subscriptionConstants } from 'redux/constants/subscription';
import notify from 'utils/toast';

function* getSubscriptionDetailsWatcher() {
  yield takeEvery(
    subscriptionConstants.GET_SUBSCRIPTION_DETAILS_REQUEST,
    getSubscriptionDetailsWorker
  );
}

function* getSubscriptionDetailsWorker() {
  try {
    const { data: subscriptionDetails } = yield getSubscriptionDetails();

    yield put(getSubscriptionDetailsSuccess(subscriptionDetails.data));
  } catch (error) {
    yield put(getSubscriptionDetailsError(error));
    yield notify('error', t('notifications.something_went_wrong'));
  }
}

export default function* getSubscriptionDetailsSaga() {
  yield fork(getSubscriptionDetailsWatcher);
}
