import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { createUserBackup } from 'api/userBackup';
import {
  confirmationBlockShow,
  createUserBackupError,
  createUserBackupSuccess,
} from 'redux/actions/userBackup';
import { userBackupTypes } from 'redux/constants/userBackup';
import notify from 'utils/toast';

function* createUserBackupWatcher() {
  yield takeEvery(
    userBackupTypes.CREATE_BACKUP_REQUEST,
    createUserBackupWorker
  );
}

export function* createUserBackupWorker() {
  try {
    const { data: userBackup } = yield createUserBackup();
    if (userBackup.status === 'Error') {
      throw new Error(userBackup.message);
    }
    /** For download your data you should to enter confirmation
     * code from mail in confirmation block on the page.
     * Request for confirmation code will be one time in 20 min.
     */
    if (userBackup.data?.code === 'two_step_verification_required') {
      yield put(confirmationBlockShow());
      return;
    }

    yield put(createUserBackupSuccess(userBackup.data));

    yield notify('success', t('notifications.backup_request_created'));
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    yield put(createUserBackupError(error));
    yield notify('error', errorMessage);
  }
}

export default function* createUserBackupSaga() {
  yield fork(createUserBackupWatcher);
}
