import { useSettings } from 'hooks/useSettings';
import { SettingsEnum } from 'types/settings';
import { TIME_FORMAT } from 'utils/DateFormatter.class';

// get user data format
export default function useUserTimeFormat(): string & TIME_FORMAT {
  return (
    useSettings<TIME_FORMAT>(SettingsEnum.TIME_FORMAT) ??
    String(process.env.REACT_APP_DEFAULT_TIME_FORMAT)
  );
}
