import Shepherd from 'shepherd.js';

import { helperShowStep } from 'services/onboardingTour/tourOptions';
import i18n from 'translation/i18n';

const STEP_ID = 'single-scan-examination-step-6';

export const stepSix: Shepherd.Step.StepOptions = {
  id: STEP_ID,
  attachTo: { element: '.shepherd-heat-map', on: 'right' },
  buttons: [
    {
      classes: 'shepherd-button-primary',
      text: i18n.t('onboarding.show_examination.next'),
      action() {
        return this.next();
      },
    },
  ],
  canClickTarget: false,
  scrollTo: { behavior: 'smooth', block: 'center' },
  title: i18n.t('onboarding.show_examination.single_scan_text_31'),
  text: [
    `<ul class='count'>
      <li class='count-item'><span class='number'>1</span> ${i18n.t(
        'onboarding.show_examination.single_scan_text_32'
      )}</li>
      <li class='count-item'><span class='number'>2</span> ${i18n.t(
        'onboarding.show_examination.single_scan_text_33'
      )}</li>
    </ul>`,
  ],
  when: {
    show: () => {
      helperShowStep(STEP_ID);
    },
  },
};
