import { memo } from 'react';

import { ReactComponent as CheckIcon } from 'assets/icons/check-mark-rounded.svg';
import Button from 'components/Button';
import Text from 'components/Text';

import styles from './SecondaryCard.module.scss';

const SecondaryCardSkeleton = memo(({ type = 'plan' }) => {
  switch (type) {
    case 'plan':
      return (
        <div className={styles.wrapper}>
          <Text className={styles.title}>Plan</Text>
          <div className={styles.cost}>
            <div className={styles.price}>
              {`$**`}
              <span>/month</span>
            </div>
          </div>
          <div className={styles.good}>
            <div className={styles.svg}>
              <CheckIcon />
            </div>
            <Text className={styles.text}>
              <span>**</span> Examinations for AI SCREENING, AI LAYERS
              SEGMENTATION
            </Text>
          </div>
          <div className={styles.good}>
            <div className={styles.svg}>
              <CheckIcon />
            </div>
            <Text className={styles.text}>
              <span>**</span> Examinations available
            </Text>
          </div>
          <div className={styles.good}></div>

          <>
            <Button className={styles['subscribe-btn']}>Subscribe</Button>
            <Button appearance={'simple'} className={styles.description}>
              Try for free
            </Button>
          </>
        </div>
      );

    default:
      break;
  }
});

SecondaryCardSkeleton.displayName = 'SecondaryCardSkeleton';

export default SecondaryCardSkeleton;
