import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { getMeasurementsScan } from 'api/examination';
import {
  getExamMeasLayersError,
  getExamMeasLayersSuccess,
  getPathologiesCalculationZone,
} from 'redux/actions/examination';
import { ExamActionTypes } from 'redux/constants/examination';
import { IGetExamMeasSaving, IWorkerProps } from 'redux/types/examination';
import { IMeasurementsScan } from 'types/examination';
import { removeInvalidPolygons } from 'utils/helpers';
import notify from 'utils/toast';

function* measurementsLayerWatcher() {
  yield takeEvery<string, any>(
    ExamActionTypes.GET_EXAM_MEASUREMENTS_LAYER_REQUEST,
    measurementsWorker
  );
}

interface IMeasurements extends IWorkerProps {
  type: ExamActionTypes;
  payload: IGetExamMeasSaving;
  action?: {
    default?: () => void;
    success?: (cleanData?: IMeasurementsScan) => void;
    error?: () => void;
  };
  abortSignal?: AbortSignal;
}

function* measurementsWorker({ payload, action, abortSignal }: IMeasurements) {
  action?.default?.();

  try {
    const { exam_id, scan_id, getWithoutSaving } = payload;

    const { data: layersData } = yield getMeasurementsScan(
      {
        exam_id,
        scan_id,
      },
      abortSignal
    );
    if (layersData.status === 'Error') {
      throw new Error(layersData.message);
    }
    if (layersData.data.etdrs_calculation_zone == null) {
      yield put(getPathologiesCalculationZone(payload, action, abortSignal));
    }

    // Remove invalid polygons that we have from ML
    const cleanData = removeInvalidPolygons(layersData.data);

    if (getWithoutSaving) {
      yield put(getExamMeasLayersSuccess());
    } else {
      yield put(getExamMeasLayersSuccess(cleanData));
    }

    action?.success?.(cleanData);
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    if (error !== 'canceled') {
      yield notify('error', errorMessage);
    }

    action?.error?.();
    yield put(
      getExamMeasLayersError({
        slug: 'something_went_wrong',
        message: 'Something went wrong',
      })
    );
  }
}

export default function* measurementsLayerSaga() {
  yield fork(measurementsLayerWatcher);
}
