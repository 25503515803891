import { memo, useRef, useState } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import MenuItem from './MenuItem';

import { ReactComponent as MenuIcon } from 'assets/icons/squares.svg';
import Button from 'components/Button';
import { useIsDemo } from 'hooks/useIsDemo';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { profileRoleSelector } from 'redux/selectors/userProfile';
import { ROUTES } from 'utils/constants';
import { ga, rolePermissionsCheck } from 'utils/helpers';
import { menuLinks } from 'utils/linksData';

import 'styles/animation.scss';

import styles from './MenuDropdown.module.scss';

const MenuDropdown = memo(() => {
  const navigate = useNavigate();
  const menuDropdown = useRef();
  const userRole = useSelector(profileRoleSelector);
  const [open, setOpen] = useState(false);
  const filteredMenuLinks = rolePermissionsCheck(menuLinks(), userRole.model);

  const isDemo = useIsDemo();

  const { t } = useTranslation(undefined, { keyPrefix: 'components.header' });

  const popUpHandler = () => {
    setOpen(!open);
  };

  const closeHandler = () => {
    setOpen(false);
  };

  useOnClickOutside(menuDropdown, closeHandler);

  return (
    <div ref={menuDropdown}>
      <div
        className={cn(styles.menu, {
          [styles.active]: open,
        })}
      >
        <MenuIcon
          onClick={isDemo ? undefined : popUpHandler}
          className={styles.icon}
        />

        <CSSTransition
          in={open}
          timeout={300}
          classNames={'alert'}
          unmountOnExit
        >
          <div className={styles.dropmenu}>
            <nav className={styles.nav}>
              {/** Temporary solution DELETE WHEN STEP 2 will be ready */}
              {filteredMenuLinks.map(({ link, icon, title, action }) => (
                <MenuItem
                  link={link}
                  key={link}
                  title={title}
                  /** Temporary solution DELETE WHEN STEP 2 will be ready */
                  action={action}
                  className={styles.link}
                  onClick={() => {
                    ga(`Open_page_${title.replaceAll(' ', '_')}_Header`);
                    closeHandler();
                  }}
                >
                  <div className={styles['icon-wrapper']}>{icon}</div>
                </MenuItem>
              ))}
            </nav>
          </div>
        </CSSTransition>
      </div>
    </div>
  );
});

MenuDropdown.displayName = 'MenuDropdown';

export default MenuDropdown;
