import { t } from 'i18next';
import { fork, put, select, takeEvery } from 'redux-saga/effects';

import {
  getGccETDRS,
  getLayerETDRS,
  getMainETDRS,
  getThicknessMap,
} from 'api/examination';
import {
  IUpdateBothAction,
  updateBothDataError,
  updateBothDataSuccess,
} from 'redux/actions/examination';
import { ExamActionTypes } from 'redux/constants/examination';
import { getExamData } from 'redux/selectors/examination/getExamData/getExamData';
import { ExtendedExamDataType, IHeatMapProps } from 'types/examination';
import { DEFAULT_ETDRS } from 'utils/constants';
import notify from 'utils/toast';

function* updateBothWatcher() {
  yield takeEvery(ExamActionTypes.UPDATE_BOTH_DATA_REQUEST, updateBothWorker);
}

interface IUpdateBothWorker {
  type: ExamActionTypes;
  payload: {
    exam_id: string;
    layers?: IHeatMapProps;
    main?: boolean;
    withoutSave?: boolean;
  };
  action?: IUpdateBothAction;
}

function* updateBothWorker({ payload, action }: IUpdateBothWorker) {
  action?.default?.();

  try {
    const { exam_id, layers, main, withoutSave } = payload;

    // If ETDRS changed we check what target was changed and rewrite data in state
    if (layers) {
      let data;
      let gccHeatmap;

      if (layers.single) {
        const { data: singleData } = yield getLayerETDRS(
          exam_id,
          JSON.stringify(layers.single)
        );
        data = { single: { layers: layers, data: singleData.data[0] } };
      }

      if (layers.range) {
        const { data: rangeData } = yield getLayerETDRS(
          exam_id,
          JSON.stringify(layers.range)
        );

        data = { range: { layers: layers, data: rangeData.data[0] } };
      }

      if (layers.gcc) {
        const { data: gccData } = yield getGccETDRS(
          exam_id,
          JSON.stringify(layers.gcc)
        );
        const { data: heatmapData } = yield getThicknessMap(
          exam_id,
          JSON.stringify(layers.gcc)
        );
        data = {
          gcc: { layers: layers.gcc, data: gccData.data[0] },
        };
        gccHeatmap = heatmapData;
      }

      if (main) {
        const { data: mainData } = yield getMainETDRS(exam_id);

        data = {
          ...data,
          main: { data: mainData.data[0] },
        };
      }

      const { compareBoth } = yield select(getExamData);

      const updatedIndex = compareBoth.findIndex(
        (item: ExtendedExamDataType) => item.id === exam_id
      );

      const result = [...compareBoth];

      result[updatedIndex] = {
        ...compareBoth[updatedIndex],
        etdrs: {
          ...compareBoth[updatedIndex].etdrs,
          ...data,
        },
        heatmap: {
          ...compareBoth[updatedIndex].heatmap,
          gcc: gccHeatmap
            ? gccHeatmap.data
            : compareBoth[updatedIndex].heatmap.gcc,
        },
      };

      if (withoutSave) {
        yield put(updateBothDataSuccess());
      } else {
        yield put(updateBothDataSuccess(result));
      }

      action?.success?.(result[updatedIndex]);
    } else {
      const { data: mainData } = yield getMainETDRS(exam_id);

      const { data: mainHeatmapData } = yield getThicknessMap(exam_id);

      const { data: singleData } = yield getLayerETDRS(
        exam_id,
        JSON.stringify(DEFAULT_ETDRS.SINGLE)
      );
      const { data: rangeData } = yield getLayerETDRS(
        exam_id,
        JSON.stringify(DEFAULT_ETDRS.RANGE)
      );
      const { data: gccData } = yield getGccETDRS(
        exam_id,
        JSON.stringify(DEFAULT_ETDRS.GCC)
      );
      const { data: heatmapData } = yield getThicknessMap(
        exam_id,
        JSON.stringify(DEFAULT_ETDRS.GCC)
      );

      [
        mainData,
        mainHeatmapData,
        singleData,
        rangeData,
        gccData,
        heatmapData,
      ].map((item) => {
        if (item.status === 'Error') {
          throw new Error(item.message);
        }
        return item;
      });

      if (
        !mainHeatmapData.data ||
        !mainData.data[0] ||
        !singleData.data[0] ||
        !rangeData.data[0] ||
        !gccData.data[0] ||
        !heatmapData.data
      ) {
        yield put(updateBothDataError());

        return;
      }

      const { compareBoth } = yield select(getExamData);

      const updatedIndex = compareBoth.findIndex(
        (item: ExtendedExamDataType) => item.id === exam_id
      );

      const result = [...compareBoth];

      result[updatedIndex] = {
        ...compareBoth[updatedIndex],
        etdrs: {
          main: { data: mainData.data[0] },
          single: {
            layers: DEFAULT_ETDRS.SINGLE,
            data: singleData.data[0],
          },
          range: { layers: DEFAULT_ETDRS.RANGE, data: rangeData.data[0] },
          gcc: { layers: DEFAULT_ETDRS.GCC, data: gccData.data[0] },
        },
        heatmap: {
          main: mainHeatmapData?.data,
          gcc: heatmapData.data,
        },
      };

      if (withoutSave) {
        yield put(updateBothDataSuccess());
      } else {
        yield put(updateBothDataSuccess(result));
      }

      action?.success?.(result[updatedIndex]);
    }
  } catch (error: any) {
    action?.error?.();
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    notify('error', errorMessage);
    yield put(updateBothDataError());
  }
}

export default function* updateBothSaga() {
  yield fork(updateBothWatcher);
}
