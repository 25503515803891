import { fork, put, takeEvery } from 'redux-saga/effects';

import { getProgressPathologyScansData } from 'api/examination';
import {
  getProgressionPathologyScansDataAction,
  getProgressionPathologyScansDataError,
  getProgressionPathologyScansDataSuccess,
} from 'redux/actions/examination';
import { ExamActionTypes } from 'redux/constants/examination';

function* getProgressionPathologyScansDataWatcher() {
  yield takeEvery(
    ExamActionTypes.GET_PROGRESSION_PATHOLOGY_SCANS_DATA_REQUEST,
    getProgressionPathologyScansDataWorker
  );
}

interface IProgressionPathologyScansData {
  type: ExamActionTypes;
  payload: { scan_ids: string[]; pathology_id: string; withoutSave?: boolean };
  action?: getProgressionPathologyScansDataAction;
  abortSignal?: AbortSignal;
}

function* getProgressionPathologyScansDataWorker({
  payload,
  action,
  abortSignal,
}: IProgressionPathologyScansData) {
  action?.default?.();

  const { scan_ids, pathology_id, withoutSave } = payload;

  try {
    // @ts-ignore
    const data = yield getProgressPathologyScansData(
      { scan_ids, pathology_id },
      abortSignal
    );

    yield put(
      getProgressionPathologyScansDataSuccess(
        !withoutSave ? data.data.data : undefined
      )
    );

    action?.success?.(data.data.data);
  } catch (error) {
    action?.error?.();
    yield put(getProgressionPathologyScansDataError());
  }
}

export default function* getProgressionPathologyScansDataSaga() {
  yield fork(getProgressionPathologyScansDataWatcher);
}
