import { memo } from 'react';

import classNames from 'classnames';
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';

import { IMenuItemProps } from './MenuItem.types';

import { ga } from 'utils/helpers';

import styles from './MenuItem.module.scss';

const MenuItem = memo(
  ({
    link,
    children,
    title,
    className,
    target,
    action,
  }: IMenuItemProps): JSX.Element => {
    const resolved = useResolvedPath(link);
    const match = useMatch({ path: resolved.pathname, end: true });

    /**
     * gaHandler for detecting which page you clicked
     * only for internal links
     */
    const gaHandler = (title: string) => {
      ga(`Open_page_${title}`);
    };

    /**
     * @param action, for adding an external functionality.
     * For example, we can add functionality for opening a modal window
     * or redirecting to another page
     */
    if (action) {
      return (
        <a
          onClick={action}
          title={title}
          className={classNames(className, styles.link)}
        >
          <div className={styles.icon}>{children}</div>
          <span>{title}</span>
        </a>
      );
    }

    /**
     * @param target, for adding an external link, e.g 'google.com'.
     * If we add target='_blank' in the NavLink component, our link
     * will be calculated from the parent outlet component
     */
    if (target) {
      return (
        <a
          title={title}
          href={link}
          target={target}
          className={classNames(className, styles.link)}
        >
          <div className={styles.icon}>{children}</div>
          <span>{title}</span>
        </a>
      );
    }

    return (
      <NavLink
        title={title}
        to={link}
        onClick={() => gaHandler(title)}
        className={classNames(className, styles.link, {
          [styles.active]: match,
        })}
      >
        <div className={styles.icon}>{children}</div>
        <span>{title}</span>
      </NavLink>
    );
  }
);

MenuItem.displayName = 'Item';

export default MenuItem;
