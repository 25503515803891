import { userProfileConstants } from 'redux/constants/userProfile';
import { TeamMemberAccessLevelType } from 'types/teamMember';
import {
  B2B_ROLES,
  B2B_TYPES,
  SUBSCRIPTION_STATUS,
  USER_ROLES,
} from 'utils/constants';

export enum SeverityLevelType {
  GREEN = 'green',
  YELLOW = 'yellow',
  RED = 'red',
}

export enum sourceType {
  DICOM = 'dicom',
  IMAGES = 'images',
}

export interface IUserRole {
  status?: B2B_ROLES;
  model?: USER_ROLES;

  // only for b2b
  b2b_accrual_type_slug?: B2B_TYPES;
}

export interface IReportSetting {
  logo: null | string;
  company_name: string;
  company_email: string;
}

export interface ICurrentPlan {
  current_period_end: number;
  current_period_start: number;
  ends_at: number;

  // only for b2b
  b2b_accrual_type?: string;
  b2b_past_due_from?: string;
  id: number;
  amount: string;
  name: string;
  is_legacy: boolean;
  recurring: string;
  services: Record<string, boolean>;
  slug: string;
  classification_items: Record<string, string>[];
  segmentation_classes: Record<string, string>[];
  subscription_status: SUBSCRIPTION_STATUS;
  trial_ends_at?: number;
}
export interface IUserCoupon {
  id: string;
  stripe_id: string;
  amount_off: number | null;
  currency: string | null;
  duration: string;
  duration_in_months: string | null;
  max_redemptions: number | null;
  name: string;
  percent_off: number | null;
  redeem_by: string;
  times_redeemed: string;
  valid: boolean;
  applies_to: {
    identifier: string;
    stripe_id: string;
  };
  discount_start: string;
  discount_end: string;
}

export interface IUpcomingPlan {
  event_at: number;
  id: number;
  name: string;
  recurring: string;
  slug: string;
}

export interface IUserProfile {
  access_level: TeamMemberAccessLevelType;
  avatar: null | string;
  institution_website: null | string;
  country_id: number;
  email: string;
  examinations_left: number;
  id: number;
  severity_level: SeverityLevelType | null;
  source_type: sourceType | null;
  invitations_left: number;
  is_able_to_invite_users: boolean;
  is_able_to_make_examinations: boolean;
  is_able_to_make_segmentations: boolean;
  is_invited: boolean;
  coupon: IUserCoupon | null;
  is_trial_plan_used: boolean;
  name: string;
  plan: ICurrentPlan;
  profession_id: number;
  report_settings: IReportSetting | null;
  role: IUserRole;
  segmentations_left: number;
  surname: string;
  upcoming_plan: IUpcomingPlan | null;
}
export interface IUpdateUserProfile {
  avatar?: Blob | string;
  name: string;
  surname: string;
  profession_id: number;
  country_id: number;
  email: string;
}

export interface IGetCodeForPassword {
  old_password: string;
  new_password: string;
}

export interface IChangePassword {
  old_password: string;
  new_password: string;
  code: string;
}

export interface IUserPassword {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
  confirmationCode: string;
}

export interface IUserProfilesState {
  requesting: boolean;
  errors: unknown | null | string;
  data: IUserProfile;
  codeRequesting: boolean;
}

interface IGetProfileUserRequest {
  type: typeof userProfileConstants.GET_PROFILE_REQUEST;
}
interface IGetProfileUserSuccess {
  type: typeof userProfileConstants.GET_PROFILE_SUCCESS;
  payload: IUserProfile;
}
interface IGetProfileUserError {
  type: typeof userProfileConstants.GET_PROFILE_ERROR;
  payload: string;
}

type getUserProfileActionType =
  | IGetProfileUserRequest
  | IGetProfileUserSuccess
  | IGetProfileUserError;

export interface IUpdateUserData {
  type: userProfileConstants.UPDATE_PROFILE_REQUEST;
  payload: IUpdateUserProfile;
}
interface IUpdateProfileUserRequest {
  type: typeof userProfileConstants.UPDATE_PROFILE_REQUEST;
}
interface IUpdateProfileUserSuccess {
  type: typeof userProfileConstants.UPDATE_PROFILE_SUCCESS;
  payload: IUserProfile;
}
interface IUpdateProfileUserError {
  type: typeof userProfileConstants.UPDATE_PROFILE_ERROR;
  payload: string;
}

type updateUserProfileActionType =
  | IUpdateProfileUserRequest
  | IUpdateProfileUserSuccess
  | IUpdateProfileUserError;

export interface IGetCodeForPasswordData {
  type: userProfileConstants.UPDATE_USER_GET_CODE_FOR_PASSWORD_REQUEST;
  payload: IGetCodeForPassword;
}

interface IGetCodeForPasswordRequest {
  type: typeof userProfileConstants.UPDATE_USER_GET_CODE_FOR_PASSWORD_REQUEST;
}
interface IGetCodeForPasswordSuccess {
  type: typeof userProfileConstants.UPDATE_USER_GET_CODE_FOR_PASSWORD_SUCCESS;
}
interface IGetCodeForPasswordError {
  type: typeof userProfileConstants.UPDATE_USER_GET_CODE_FOR_PASSWORD_ERROR;
  payload: string;
}

interface IGetCodeForPasswordReset {
  type: typeof userProfileConstants.UPDATE_USER_GET_CODE_FOR_PASSWORD_RESET;
}

type getCodeForPasswordActionType =
  | IGetCodeForPasswordRequest
  | IGetCodeForPasswordSuccess
  | IGetCodeForPasswordError
  | IGetCodeForPasswordReset;

export interface IUpdatePasswordData {
  type: userProfileConstants.UPDATE_USER_PASSWORD_REQUEST;
  payload: IChangePassword;
  action: any;
}

interface IUpdatePasswordRequest {
  type: typeof userProfileConstants.UPDATE_USER_PASSWORD_REQUEST;
}
interface IUpdatePasswordSuccess {
  type: typeof userProfileConstants.UPDATE_USER_PASSWORD_SUCCESS;
}
interface IUpdatePasswordError {
  type: typeof userProfileConstants.UPDATE_USER_PASSWORD_ERROR;
  payload: string;
}

type updateUserPasswordActionType =
  | IUpdatePasswordRequest
  | IUpdatePasswordSuccess
  | IUpdatePasswordError;

export interface IUpdateReportSettingData {
  type: userProfileConstants.UPDATE_REPORT_SETTINGS_REQUEST;
  payload: {
    logo: Blob | string;
    company_email: string;
    company_name: string;
  };
}

interface IUpdateReportSettingRequest {
  type: typeof userProfileConstants.UPDATE_REPORT_SETTINGS_REQUEST;
}
interface IUpdateReportSettingSuccess {
  type: typeof userProfileConstants.UPDATE_REPORT_SETTINGS_SUCCESS;
  payload: IUserProfile;
}
interface IUpdateReportSettingError {
  type: typeof userProfileConstants.UPDATE_REPORT_SETTINGS_ERROR;
  payload: string;
}

type updateReportSettingActionType =
  | IUpdateReportSettingRequest
  | IUpdateReportSettingSuccess
  | IUpdateReportSettingError;

export type userProfileActions =
  | getUserProfileActionType
  | updateUserProfileActionType
  | getCodeForPasswordActionType
  | updateUserPasswordActionType
  | updateReportSettingActionType;
