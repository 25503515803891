import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SeverityLevelGreenIcon } from 'assets/icons/concern_level_green.svg';
import { ReactComponent as SeverityLevelRedIcon } from 'assets/icons/concern_level_red.svg';
import { ReactComponent as SeverityLevelYellowIcon } from 'assets/icons/concern_level_yellow.svg';
import Text from 'components/Text';

import styles from './SeverityLevel.module.scss';

enum SeverityLevelType {
  GREEN = 'green',
  YELLOW = 'yellow',
  RED = 'red',
}

type ModelType = 'patient' | 'examination';

interface SeverityLevelProps {
  level: SeverityLevelType;
  type: ModelType;
}

const SeverityLevel = ({ level, type }: SeverityLevelProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'components.severity_level',
  });

  const getSeverity = (
    level: SeverityLevelType,
    icon: React.ReactNode,
    text: string
  ) => (
    <>
      {icon}
      <Text
        className={cn(styles.tooltip, {
          [styles[level]]: level,
        })}
      >
        {text}
      </Text>
    </>
  );

  const getPatientSeverityLevel = (level: SeverityLevelType) => {
    switch (level) {
      case SeverityLevelType.GREEN:
        return getSeverity(
          level,
          <SeverityLevelGreenIcon />,
          t('no_severity_plural')
        );
      case SeverityLevelType.YELLOW:
        return getSeverity(
          level,
          <SeverityLevelYellowIcon />,
          t('yellow_severity_scan_plural')
        );
      case SeverityLevelType.RED:
        return getSeverity(
          level,
          <SeverityLevelRedIcon />,
          t('red_severity_scan_plural')
        );
      default:
        break;
    }
  };

  const getExaminationSeverityLevel = (level: SeverityLevelType) => {
    switch (level) {
      case SeverityLevelType.GREEN:
        return getSeverity(level, <SeverityLevelGreenIcon />, t('no_severity'));
      case SeverityLevelType.YELLOW:
        return getSeverity(
          level,
          <SeverityLevelYellowIcon />,
          t('yellow_severity_scan')
        );
      case SeverityLevelType.RED:
        return getSeverity(
          level,
          <SeverityLevelRedIcon />,
          t('red_severity_scan')
        );
      default:
        break;
    }
  };

  return (
    <div className={styles['icon-wrapper']}>
      <div className={cn(styles.icon)}>
        {type === 'patient'
          ? getPatientSeverityLevel(level)
          : getExaminationSeverityLevel(level)}
      </div>
    </div>
  );
};

export default SeverityLevel;
