export enum ExamActionTypes {
  RESET_EXAMINATION_STATE = 'RESET_EXAMINATION_STATE',
  CREATE_EXAMINATION_REQUEST = 'CREATE_EXAMINATION_REQUEST',
  CREATE_EXAMINATION_SUCCESS = 'CREATE_EXAMINATION_SUCCESS',
  CREATE_EXAMINATION_ERROR = 'CREATE_EXAMINATION_ERROR',

  GET_EXAMINATION_REQUEST = 'GET_EXAMINATION_REQUEST',
  GET_EXAMINATION_SUCCESS = 'GET_EXAMINATION_SUCCESS',
  GET_EXAMINATION_ERROR = 'GET_EXAMINATION_ERROR',

  UPDATE_EXAMINATION_REQUEST = 'UPDATE_EXAMINATION_REQUEST',
  UPDATE_EXAMINATION_SUCCESS = 'UPDATE_EXAMINATION_SUCCESS',
  UPDATE_EXAMINATION_ERROR = 'UPDATE_EXAMINATION_ERROR',

  DELETE_EXAMINATION_REQUEST = 'DELETE_EXAMINATION_REQUEST',
  DELETE_EXAMINATION_SUCCESS = 'DELETE_EXAMINATION_SUCCESS',
  DELETE_EXAMINATION_ERROR = 'DELETE_EXAMINATION_ERROR',

  GET_EXAMINATION_STATUS_REQUEST = 'GET_EXAMINATION_STATUS_REQUEST',
  GET_EXAMINATION_STATUS_ERROR = 'GET_EXAMINATION_STATUS_ERROR',

  COMPARE_EXAMINATION_REQUEST = 'COMPARE_EXAMINATION_REQUEST',
  COMPARE_EXAMINATION_SUCCESS = 'COMPARE_EXAMINATION_SUCCESS',

  UPDATE_EXAM_TAB_REQUEST = 'UPDATE_EXAM_TAB_REQUEST',
  UPDATE_EXAM_TAB_SUCCESS = 'UPDATE_EXAM_TAB_SUCCESS',

  COMPARE_EXAMINATION_BOTH_SUCCESS = 'COMPARE_EXAMINATION_BOTH_SUCCESS',
  COMPARE_EXAMINATION_COMPARISON_SUCCESS = 'COMPARE_EXAMINATION_COMPARISON_SUCCESS',
  COMPARE_EXAMINATION_PROGRESS_SCANS_DATA_SUCCESS = 'COMPARE_EXAMINATION_PROGRESS_SCANS_DATA_SUCCESS',
  COMPARE_EXAMINATION_ERROR = 'COMPARE_EXAMINATION_ERROR',

  FILTER_EXAMINATION_PROGRESS = 'FILTER_EXAMINATION_PROGRESS',

  SCAN_SEGMENTATION_REQUEST = 'SCAN_SEGMENTATION_REQUEST',
  SCAN_SEGMENTATION_SUCCESS = 'SCAN_SEGMENTATION_SUCCESS',
  SCAN_SEGMENTATION_ERROR = 'SCAN_SEGMENTATION_ERROR',

  SCAN_SEGMENTATION_REPORT_REQUEST = 'SCAN_SEGMENTATION_REPORT_REQUEST',
  SCAN_SEGMENTATION_REPORT_SUCCESS = 'SCAN_SEGMENTATION_REPORT_SUCCESS',
  SCAN_SEGMENTATION_REPORT_ERROR = 'SCAN_SEGMENTATION_REPORT_ERROR',

  SCAN_LAYERS_REQUEST = 'SCAN_LAYERS_REQUEST',
  SCAN_LAYERS_SUCCESS = 'SCAN_LAYERS_SUCCESS',
  SCAN_LAYERS_ERROR = 'SCAN_LAYERS_ERROR',

  GET_LAYERS_STATUS_REQUEST = 'GET_LAYERS_STATUS_REQUEST',
  GET_LAYERS_STATUS_SUCCESS = 'GET_LAYERS_STATUS_SUCCESS',
  GET_LAYERS_STATUS_ERROR = 'GET_LAYERS_STATUS_ERROR',

  SET_FAVORITE_SCAN_REQUEST = 'SET_FAVORITE_SCAN_REQUEST',
  SET_FAVORITE_SCAN_SUCCESS = 'SET_FAVORITE_SCAN_SUCCESS',
  SET_FAVORITE_SCAN_ERROR = 'SET_FAVORITE_SCAN_ERROR',

  GET_ETDRS_MAIN_REQUEST = 'GET_ETDRS_MAIN_REQUEST',
  GET_ETDRS_MAIN_SUCCESS = 'GET_ETDRS_MAIN_SUCCESS',
  GET_ETDRS_SINGLE_REQUEST = 'GET_ETDRS_SINGLE_REQUEST',
  GET_ETDRS_SINGLE_SUCCESS = 'GET_ETDRS_SINGLE_SUCCESS',
  GET_ETDRS_RANGE_REQUEST = 'GET_ETDRS_RANGE_REQUEST',
  GET_ETDRS_RANGE_SUCCESS = 'GET_ETDRS_RANGE_SUCCESS',
  GET_ETDRS_GCC_REQUEST = 'GET_ETDRS_GCC_REQUEST',
  GET_ETDRS_GCC_SUCCESS = 'GET_ETDRS_GCC_SUCCESS',
  GET_ETDRS_ERROR = 'GET_ETDRS_ERROR',

  UPDATE_COMPARISON_DATA_REQUEST = 'UPDATE_COMPARISON_DATA_REQUEST',
  UPDATE_COMPARISON_DATA_SUCCESS = 'UPDATE_COMPARISON_DATA_SUCCESS',
  UPDATE_COMPARISON_DATA_ERROR = 'UPDATE_COMPARISON_DATA_ERROR',

  UPDATE_BOTH_DATA_REQUEST = 'UPDATE_BOTH_DATA_REQUEST',
  UPDATE_BOTH_DATA_SUCCESS = 'UPDATE_BOTH_DATA_SUCCESS',
  UPDATE_BOTH_DATA_ERROR = 'UPDATE_BOTH_DATA_ERROR',

  GET_BOTH_EYES_OPPOSITE_EYE_REQUEST = 'GET_BOTH_EYES_OPPOSITE_EYE_REQUEST',
  GET_BOTH_EYES_OPPOSITE_EYE_SUCCESS = 'GET_BOTH_EYES_OPPOSITE_EYE_SUCCESS',

  UPDATE_PROGRESSION_DATA_REQUEST = 'UPDATE_PROGRESSION_DATA_REQUEST',
  UPDATE_PROGRESSION_SCANS_DATA_SUCCESS = 'UPDATE_PROGRESSION_SCANS_DATA_SUCCESS',
  UPDATE_PROGRESSION_DATA_ERROR = 'UPDATE_PROGRESSION_DATA_ERROR',

  GET_EXAM_THICKNESS_MAP_REQUEST = 'GET_EXAM_THICKNESS_MAP_REQUEST',
  GET_EXAM_THICKNESS_MAP_SUCCESS = 'GET_EXAM_THICKNESS_MAP_SUCCESS',
  GET_EXAM_THICKNESS_MAP_ERROR = 'GET_EXAM_THICKNESS_MAP_ERROR',

  SET_EXAM_HEATMAP_VISIBILITY = 'SET_EXAM_HEATMAP_VISIBILITY',

  GET_EXAM_MEASUREMENTS_LAYER_REQUEST = 'GET_EXAM_MEASUREMENTS_LAYER_REQUEST',
  GET_EXAM_MEASUREMENTS_LAYER_SUCCESS = 'GET_EXAM_MEASUREMENTS_LAYER_SUCCESS',
  GET_EXAM_MEASUREMENTS_LAYER_ERROR = 'GET_EXAM_MEASUREMENTS_LAYER_ERROR',

  UPDATE_EXAM_MEASUREMENTS_LAYER_REQUEST = 'UPDATE_EXAM_MEASUREMENTS_LAYER_REQUEST',
  UPDATE_EXAM_MEASUREMENTS_LAYER_SUCCESS = 'UPDATE_EXAM_MEASUREMENTS_LAYER_SUCCESS',
  UPDATE_EXAM_MEASUREMENTS_LAYER_ERROR = 'UPDATE_EXAM_MEASUREMENTS_LAYER_ERROR',

  DELETE_EXAM_MEASUREMENTS_LAYER_REQUEST = 'DELETE_EXAM_MEASUREMENTS_LAYER_REQUEST',
  DELETE_EXAM_MEASUREMENTS_LAYER_SUCCESS = 'DELETE_EXAM_MEASUREMENTS_LAYER_SUCCESS',
  DELETE_EXAM_MEASUREMENTS_LAYER_ERROR = 'DELETE_EXAM_MEASUREMENTS_LAYER_ERROR',

  GET_EXAM_MEASUREMENTS_MANUAL_REQUEST = 'GET_EXAM_MEASUREMENTS_MANUAL_REQUEST',
  GET_EXAM_MEASUREMENTS_MANUAL_SUCCESS = 'GET_EXAM_MEASUREMENTS_MANUAL_SUCCESS',
  GET_EXAM_MEASUREMENTS_MANUAL_ERROR = 'GET_EXAM_MEASUREMENTS_MANUAL_ERROR',

  GET_EXAM_MEASUREMENTS_MANUAL_ID_REQUEST = 'GET_EXAM_MEASUREMENTS_MANUAL_ID_REQUEST',
  GET_EXAM_MEASUREMENTS_MANUAL_ID_SUCCESS = 'GET_EXAM_MEASUREMENTS_MANUAL_ID_SUCCESS',
  GET_EXAM_MEASUREMENTS_MANUAL_ID_ERROR = 'GET_EXAM_MEASUREMENTS_MANUAL_ID_ERROR',

  UPDATE_EXAM_MEASUREMENTS_MANUAL_REQUEST = 'UPDATE_EXAM_MEASUREMENTS_MANUAL_REQUEST',
  UPDATE_EXAM_MEASUREMENTS_MANUAL_SUCCESS = 'UPDATE_EXAM_MEASUREMENTS_MANUAL_SUCCESS',
  UPDATE_EXAM_MEASUREMENTS_MANUAL_ERROR = 'UPDATE_EXAM_MEASUREMENTS_MANUAL_ERROR',

  GET_EXAM_MEASUREMENTS_DISTANCE_REQUEST = 'GET_EXAM_MEASUREMENTS_DISTANCE_REQUEST',
  GET_EXAM_MEASUREMENTS_DISTANCE_SUCCESS = 'GET_EXAM_MEASUREMENTS_DISTANCE_SUCCESS',
  GET_EXAM_MEASUREMENTS_DISTANCE_ERROR = 'GET_EXAM_MEASUREMENTS_DISTANCE_ERROR',

  GET_DATA_FOR_ONBOARDING_TOUR = 'GET_DATA_FOR_ONBOARDING_TOUR',

  SCAN_LAYERS_REPORT_REQUEST = 'SCAN_LAYERS_REPORT_REQUEST',
  SCAN_LAYERS_REPORT_SUCCESS = 'SCAN_LAYERS_REPORT_SUCCESS',
  SCAN_LAYERS_REPORT_ERROR = 'SCAN_LAYERS_REPORT_ERROR',

  SET_EXPANDED_GROUPS = 'SET_EXPANDED_GROUPS',
  SET_EXAM_TABLE_MEASUREMENT_VALUE = 'SET_EXAM_TABLE_MEASUREMENT_VALUE',

  GET_PATHOLOGIES_CALCULATION_ZONE = 'GET_PATHOLOGIES_CALCULATION_ZONE',

  GET_GLAUCOMA_DATA_REQUEST = 'GET_GLAUCOMA_DATA_REQUEST',
  GET_GLAUCOMA_DATA_SUCCESS = 'GET_GLAUCOMA_DATA_SUCCESS',
  GET_GLAUCOMA_DATA_ERROR = 'GET_GLAUCOMA_DATA_ERROR',

  GET_PROGRESSION_COMMON_PATHOLOGIES_DATA_REQUEST = 'GET_PROGRESSION_COMMON_PATHOLOGIES_DATA_REQUEST',
  GET_PROGRESSION_COMMON_PATHOLOGIES_DATA_SUCCESS = 'GET_PROGRESSION_COMMON_PATHOLOGIES_DATA_SUCCESS',
  GET_PROGRESSION_COMMON_PATHOLOGIES_DATA_ERROR = 'GET_PROGRESSION_COMMON_PATHOLOGIES_DATA_ERROR',

  GET_PROGRESSION_HEATMAP_DATA_REQUEST = 'GET_PROGRESSION_HEATMAP_DATA_REQUEST',
  GET_PROGRESSION_HEATMAP_DATA_SUCCESS = 'GET_PROGRESSION_HEATMAP_DATA_SUCCESS',
  GET_PROGRESSION_HEATMAP_DATA_ERROR = 'GET_PROGRESSION_HEATMAP_DATA_ERROR',

  GET_PROGRESSION_PATHOLOGY_SCANS_DATA_REQUEST = 'GET_PROGRESSION_PATHOLOGY_SCANS_DATA_REQUEST',
  GET_PROGRESSION_PATHOLOGY_SCANS_DATA_SUCCESS = 'GET_PROGRESSION_PATHOLOGY_SCANS_DATA_SUCCESS',
  GET_PROGRESSION_PATHOLOGY_SCANS_DATA_ERROR = 'GET_PROGRESSION_PATHOLOGY_SCANS_DATA_ERROR',

  GET_PROGRESSION_STATE_REQUEST = 'GET_PROGRESSION_STATE_REQUEST',
  GET_PROGRESSION_STATE_SUCCESS = 'GET_PROGRESSION_STATE_SUCCESS',
  GET_PROGRESSION_STATE_ERROR = 'GET_PROGRESSION_STATE_ERROR',

  UPDATE_PROGRESSION_STATE_REQUEST = 'UPDATE_PROGRESSION_STATE_REQUEST',
  UPDATE_PROGRESSION_STATE_FINALLY = 'UPDATE_PROGRESSION_STATE_FINALLY',
}
